import cn from 'classnames';
import React, { createContext, useContext, useMemo, useState } from 'react';
import styles from './FieldWrapper.module.scss';
const FieldWrapperContext = createContext({
    setWrapperDisabled: () => undefined
});
const FieldWrapper = ({ className, children, disabled, disableWrapperHover = false, editing, simple, error, onClick, style, onMouseDown, tabIndex, onKeyDown, errorPrepend = true }) => {
    const [wrapperDisabled, setWrapperDisabled] = useState(undefined);
    const basicClasses = `${style?.color ? 'with-color' : ''} ${style?.backgroundColor ? 'with-background-color' : ''} ${disableWrapperHover ? 'disable-wrapper-hover' : ''}`;
    const simpleFieldClasses = `${styles.wrapperSimple} ${className || ''} FieldWrapperOuterSimple ${basicClasses}`;
    const multipleFieldClasses = cn(styles.wrapper, 'FieldWrapperOuter', className, {
        disabled: disabled || wrapperDisabled,
        editing: editing,
        'with-error': error,
        'error-prepend': errorPrepend && error
    }, 'px-3 d-flex align-items-center flex-wrap', basicClasses);
    const contextValue = useMemo(() => ({ setWrapperDisabled }), []);
    return (React.createElement(FieldWrapperContext.Provider, { value: contextValue },
        React.createElement("div", { onKeyDown: onKeyDown, tabIndex: tabIndex, className: simple ? simpleFieldClasses : multipleFieldClasses, onClick: simple ? undefined : onClick, onMouseDown: simple ? undefined : onMouseDown, style: style }, children)));
};
export const useFieldWrapper = () => useContext(FieldWrapperContext);
export default FieldWrapper;
