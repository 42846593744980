import { PlusOutlined } from '@ant-design/icons';
import { BasicInput } from '@hypercharge/hyper-react-base/lib/form/components/BasicInput';
import { LabelText } from '@hypercharge/hyper-react-base/lib/form/components/utils';
import { t, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Select, Tooltip } from 'antd';
import { Field } from 'formik';
import { findIndex, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BsExclamationCircle } from 'react-icons/bs';
import * as Yup from 'yup';
import { ComputedInputInline } from '~app/common/components/ComputedExpressionEditor';
import { useEntityDisplayData } from '../../../../cms/common/components/withEntityDisplayData';
import useDisplayItemMetaList, { invalidateDisplayItemMetaListCache } from '../../../../cms/hooks/useDisplayItemMetaList';
import FormikError from '../../../../common/components/formik/FormikError';
import FormikField from '../../../../common/components/formik/FormikField';
import { FieldsContainer } from '../../../../common/utils/formUtils';
import { XDMS_CONTACT_MODULE_ENTITY_ID, XDMS_CUSTOMER_MODULE_ENTITY_ID, XDMS_ECOMM_ORDER_MODULE_ENTITY_ID, XDMS_LANGUAGE_MODULE_ENTITY_ID, XDMS_LEAD_MODULE_ENTITY_ID, XDMS_LOCATION_MODULE_ENTITY_ID, XDMS_OFFER_MODULE_ENTITY_ID, XDMS_ORDER_MODULE_ENTITY_ID, XDMS_SALES_DOSSIER_MODULE_ENTITY_ID, XDMS_SALES_PERSON_MODULE_ENTITY_ID, XDMS_VEHICLE_MODULE_ENTITY_ID } from '../constants';
import styles from './XDMSModuleForm.module.scss';
import XDMSModuleFromXdmsForm from './XDMSModuleFromXdmsForm';
import XDMSModuleToXdmsForm from './XDMSModuleToXdmsForm';
import { createDefinitionByModuleId, fetchXdmsSchemaByModuleId } from './actions';
const NEW_CUSTOM_ENTITY = 'NEW_CUSTOM_ENTITY';
const createNewEntityOptionStyle = { pointerEvents: 'none' };
const SUPPORT_SYNC_TYPE = {
    [XDMS_OFFER_MODULE_ENTITY_ID]: ['fromXDMS'],
    [XDMS_SALES_DOSSIER_MODULE_ENTITY_ID]: ['fromXDMS'],
    [XDMS_SALES_PERSON_MODULE_ENTITY_ID]: ['fromXDMS'],
    [XDMS_LEAD_MODULE_ENTITY_ID]: ['fromXDMS', 'toXDMS'],
    [XDMS_ORDER_MODULE_ENTITY_ID]: ['toXDMS'],
    [XDMS_CONTACT_MODULE_ENTITY_ID]: ['toXDMS'],
    [XDMS_CUSTOMER_MODULE_ENTITY_ID]: ['toXDMS'],
    [XDMS_VEHICLE_MODULE_ENTITY_ID]: ['toXDMS'],
    [XDMS_LANGUAGE_MODULE_ENTITY_ID]: ['fromXDMS'],
    [XDMS_LOCATION_MODULE_ENTITY_ID]: ['fromXDMS'],
    [XDMS_ECOMM_ORDER_MODULE_ENTITY_ID]: ['toXDMS']
};
export const getFormSchema = () => {
    return Yup.object().shape({
        exportFolderPath: Yup.string().required(t('VALIDATIONS__REQUIRED')),
        definitionId: Yup.string().required(t('VALIDATIONS__REQUIRED')),
        mappings: Yup.object().required(t('VALIDATIONS__REQUIRED'))
    });
};
export const defaultValues = {
    [XDMS_OFFER_MODULE_ENTITY_ID]: {
        definitionId: 'xdmsOffer'
    },
    [XDMS_SALES_DOSSIER_MODULE_ENTITY_ID]: {
        definitionId: 'salesDossier'
    },
    [XDMS_LEAD_MODULE_ENTITY_ID]: {
        definitionId: 'lead'
    },
    [XDMS_SALES_PERSON_MODULE_ENTITY_ID]: {
        definitionId: 'salesPersonXdms'
    }
};
const XDMSModuleForm = ({ dispatch, moduleId, disabled, configuration: { exportFolderPath = '', definitionId: selectedEntityDefinitionId = '', sendToXdmsCondition = '', mappings = { toXdms: {}, fromXdms: {} } } }) => {
    const { t, language } = useI18n();
    const [loading, setLoading] = useState(false);
    const [xdmsSchema, setXdmsSchema] = useState({});
    const [syncType, setSyncType] = useState(SUPPORT_SYNC_TYPE[moduleId]?.[0]);
    const { data: entities } = useDisplayItemMetaList();
    const { displayData } = useEntityDisplayData(selectedEntityDefinitionId);
    const createXdmsDefinition = useCallback(async (field, setFieldValue) => {
        if (defaultValues[moduleId]?.definitionId) {
            setLoading(true);
            await dispatch(createDefinitionByModuleId(moduleId));
            await invalidateDisplayItemMetaListCache();
            setFieldValue(field, defaultValues[moduleId].definitionId);
            setLoading(false);
        }
    }, [dispatch, moduleId]);
    const entityProperties = useMemo(() => {
        const result = [];
        if (!displayData?.data) {
            return result;
        }
        for (const section of displayData.data) {
            for (const property of section.values) {
                result.push({
                    propertyId: property.propertyId,
                    title: property.labels[language]
                });
            }
        }
        return result;
    }, [displayData, language]);
    useEffect(() => {
        if (!SUPPORT_SYNC_TYPE[moduleId].includes('toXDMS')) {
            return;
        }
        void Promise.resolve()
            .then(() => dispatch(fetchXdmsSchemaByModuleId(moduleId)))
            .then((xdmsProperties) => {
            setXdmsSchema(xdmsProperties);
        })
            .finally(() => setLoading(false));
    }, [dispatch, language, moduleId]);
    return (React.createElement(FieldsContainer, { className: `${styles.wrapper} cp-c-padding-2` },
        React.createElement("div", null,
            React.createElement(LabelText, { htmlFor: "syncType" }, t('INTEGRATIONS_XDMS_INTEGRATION_TYPE')),
            React.createElement(Select, { id: "syncType", defaultValue: syncType, className: "w-100", placeholder: t('INTEGRATIONS_XDMS_INTEGRATION_TYPE'), disabled: disabled || loading, onChange: (val) => setSyncType(val) }, SUPPORT_SYNC_TYPE[moduleId] &&
                SUPPORT_SYNC_TYPE[moduleId].map((type) => (React.createElement(Select.Option, { key: type }, t(`INTEGRATIONS_XDMS_INTEGRATION_TYPE_${type}`)))))),
        React.createElement("div", null,
            React.createElement(LabelText, { htmlFor: "definitionId" }, t('INTEGRATIONS_XDMS_MODULE_SELECT_ENTITY')),
            React.createElement(Field, { name: "definitionId" }, ({ field, form }) => (React.createElement(Select, { ...field, id: "definitionId", showSearch: true, filterOption: (input, option) => {
                    const children = option?.props?.children || option?.children;
                    return typeof children === 'string'
                        ? children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        : false;
                }, className: "w-100", placeholder: t('INTEGRATIONS_XDMS_MODULE_SELECT_ENTITY'), disabled: disabled || loading, onChange: (value) => {
                    if (value !== NEW_CUSTOM_ENTITY) {
                        form.setFieldValue('definitionId', value);
                    }
                    else {
                        form.setFieldValue('definitionId', '');
                        void createXdmsDefinition('definitionId', form.setFieldValue);
                    }
                } },
                entities.map((entity) => (React.createElement(Select.Option, { key: entity.definitionId }, entity.title))),
                defaultValues[moduleId] &&
                    defaultValues[moduleId].definitionId &&
                    findIndex(entities, ['definitionId', defaultValues[moduleId].definitionId]) ===
                        -1 && (React.createElement(Select.Option, { id: NEW_CUSTOM_ENTITY, key: NEW_CUSTOM_ENTITY },
                    React.createElement("div", { style: createNewEntityOptionStyle, className: "cp-c-row cp-c-align-spacebetween-center" },
                        `${t('CREATE_NEW')}: ${defaultValues[moduleId].definitionId}`,
                        React.createElement(PlusOutlined, null))))))),
            React.createElement(FormikError, { name: "definitionId" })),
        React.createElement(FormikField, { name: "exportFolderPath", id: "exportFolderPath", label: t('INTEGRATIONS_XDMS_EXPORT_FOLDER_PATH'), value: exportFolderPath, component: BasicInput, disabled: disabled || loading }),
        syncType === 'toXDMS' && selectedEntityDefinitionId && !isEmpty(xdmsSchema) ? (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement(LabelText, { className: "cp-c-row cp-c-align-start-center" },
                    t('INTEGRATIONS_XDMS_SEND_TO_XDMS_CONDITION'),
                    React.createElement(Tooltip, { title: t('INTEGRATIONS_XDMS_SEND_TO_XDMS_CONDITION_TOOLTIP'), placement: "top" },
                        React.createElement(BsExclamationCircle, { className: "ms-1" }))),
                React.createElement(FormikField, { name: "sendToXdmsCondition", id: "sendToXdmsCondition", component: ComputedInputInline, value: sendToXdmsCondition, disabled: disabled || loading })),
            React.createElement("div", null,
                React.createElement(LabelText, { className: "field-label" }, t('INTEGRATIONS_XDMS_MAPPINGS')),
                React.createElement(XDMSModuleToXdmsForm, { entityProperties: entityProperties, xdmsSchema: xdmsSchema, selectedEntityDefinitionId: selectedEntityDefinitionId, mappings: mappings.toXdms, dispatch: dispatch, moduleId: moduleId, disabled: disabled || loading })))) : (syncType === 'fromXDMS' &&
            selectedEntityDefinitionId &&
            entityProperties.length > 0 && (React.createElement("div", null,
            React.createElement(LabelText, { className: "field-label" }, t('INTEGRATIONS_XDMS_MAPPINGS')),
            React.createElement(XDMSModuleFromXdmsForm, { entityProperties: entityProperties, xdmsSchema: xdmsSchema, mappings: mappings.fromXdms, dispatch: dispatch, moduleId: moduleId, disabled: disabled || loading }))))));
};
export default XDMSModuleForm;
