import { error, warning } from '@hypercharge/hyper-react-base/lib/notifications';
import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Button } from 'antd';
import { find, flatMap, get, isBoolean, isNull, isNumber, isString, isUndefined, toString } from 'lodash';
import React from 'react';
import { isComputedExpression } from '~app/utils/computedExpression';
import { ENTITY_ID_PROPERTY_ID } from '../../../cms';
import { PropertyTypes } from '../../types';
import BasicFilter from './BasicFilter';
import CheckboxFilter from './CheckboxFilter';
import DateFilter from './DateFilter';
import EntityFilter from './EntityFilter';
import MultiTextFilter from './MultiTextFilter';
import NumberFilter from './NumberFilter';
import SelectFilter from './SelectFilter';
import TextFilter from './TextFilter';
import { MAX_COUNT_FILTERS, QuickFilterId } from './constants';
export const getFilterComponentByType = (type) => {
    switch (type) {
        case PropertyTypes.number:
            return NumberFilter;
        case PropertyTypes.date:
            return DateFilter;
        case PropertyTypes.select: // TODO: remove but check usage in db first
            return SelectFilter;
        case PropertyTypes.multitext:
        case PropertyTypes.richtext:
            return MultiTextFilter;
        case PropertyTypes.checkbox:
            return CheckboxFilter;
        case PropertyTypes.text:
        case PropertyTypes.email:
        case PropertyTypes.phoneNumber:
        case PropertyTypes.link:
            return TextFilter;
        case PropertyTypes.entity:
            return EntityFilter;
        default:
            return BasicFilter;
    }
};
export function getDefaultOperatorByType(type, isList, quick) {
    switch (type) {
        case PropertyTypes.number:
        case PropertyTypes.date:
        case PropertyTypes.checkbox:
            return FilterOperatorTypes.equals;
        case PropertyTypes.select: // TODO: remove but check usage in db first
        case PropertyTypes.entity:
            return isList ? FilterOperatorTypes.contains : FilterOperatorTypes.is;
        case PropertyTypes.text:
        case PropertyTypes.multitext:
        case PropertyTypes.link:
        case PropertyTypes.email:
        case PropertyTypes.phoneNumber:
        case PropertyTypes.richtext:
            return FilterOperatorTypes.contains;
        default:
            return quick ? undefined : FilterOperatorTypes.notEmpty;
    }
}
const isSupportEqualOrNotEquals = (operator) => [FilterOperatorTypes.equals, FilterOperatorTypes.notEquals].includes(operator);
export function buildFilter(field, operator, data) {
    switch (operator) {
        case FilterOperatorTypes.empty:
        case FilterOperatorTypes.notEmpty:
            return { field, operator };
        case FilterOperatorTypes.is:
        case FilterOperatorTypes.isNot:
        case FilterOperatorTypes.contains:
        case FilterOperatorTypes.notContains:
        case FilterOperatorTypes.startsWith:
        case FilterOperatorTypes.endsWith:
            return { field, operator, data: toString(data) };
        case FilterOperatorTypes.isIn:
        case FilterOperatorTypes.isNotIn:
            if (isString(data) && data.startsWith('=')) {
                return { field, operator, data };
            }
            return { field, operator, data: Array.isArray(data) ? data : [data].filter(isString) };
        case FilterOperatorTypes.equals:
        case FilterOperatorTypes.notEquals:
        case FilterOperatorTypes.greaterThanOrEqual:
        case FilterOperatorTypes.greaterThan:
        case FilterOperatorTypes.lessThanEqual:
        case FilterOperatorTypes.lessThan:
            if (isString(data) && data.startsWith('=')) {
                return { field, operator, data };
            }
            else if (isBoolean(data) && isSupportEqualOrNotEquals(operator)) {
                return { field, operator, data: data };
            }
            else if (isNumber(data) ||
                !isNaN(parseFloat(toString(data))) || // isNumeric
                isBoolean(data) ||
                isUndefined(data) ||
                isString(data)) {
                return {
                    field,
                    operator,
                    data: isBoolean(data) ? toString(data) : isUndefined(data) || isNull(data) ? '' : data
                };
            }
            throw new Error('Data must be boolean or number or string started with "=" (computed expression)');
        default:
            if (data) {
                return { field, operator: undefined, data: data };
            }
            else {
                return { field, operator: undefined };
            }
    }
}
export function isFilterType(filter) {
    if (filter.operator === undefined) {
        return false;
    }
    return true;
}
export function buildStartQuickFilter(field, type, meta) {
    let isList = false;
    if (meta && 'list' in meta) {
        isList = !!meta.list;
    }
    return buildFilter(field, getDefaultOperatorByType(type, isList, true), undefined);
}
export function buildStartFilter(field, type, meta) {
    let isList = false;
    if (meta && 'list' in meta) {
        isList = !!meta.list;
    }
    let value;
    if (type === PropertyTypes.date) {
        value = Date.now();
    }
    else if (type === PropertyTypes.checkbox) {
        value = false;
    }
    return buildFilter(field, getDefaultOperatorByType(type, isList), value);
}
export const isConditionQuery = (data) => 'condition' in data;
export const isFilter = (data) => 'field' in data;
export const getFilterByPropertyId = (filters, propertyId, type) => {
    const field = type === PropertyTypes.entity ? `${propertyId}.${ENTITY_ID_PROPERTY_ID}` : propertyId;
    return find(filters, { field });
};
export const getQuickFilters = (filterRequest) => {
    return flatMap(filterRequest.query.filters
        .filter(isConditionQuery)
        .filter((filter) => filter.id === QuickFilterId)
        .map((filter) => filter.filters)).filter(isFilter);
};
export function isOperatorWithoutData(operator) {
    switch (operator) {
        case FilterOperatorTypes.empty:
        case FilterOperatorTypes.notEmpty:
            return true;
        default:
            return false;
    }
}
export const getFieldRootLevel = (field, type) => {
    let newField = field.replace(`.${ENTITY_ID_PROPERTY_ID}`, '');
    if (type === PropertyTypes.entity && newField.split('.').length === 1) {
        // For root-level entity property filters, we need to append `.entityId` so that the expanded filtering mechanism can match the value (data)
        // This is not needed for nested entity properties since those are not expanded into objects and therefore represent the entityId value
        newField += `.${ENTITY_ID_PROPERTY_ID}`;
    }
    return newField;
};
export const replaceConditionFromContextDataInFilters = (query, context) => {
    if (!context) {
        return query;
    }
    if (isConditionQuery(query)) {
        return {
            ...query,
            filters: query.filters.map((filter) => replaceConditionFromContextDataInFilters(filter, context))
        };
    }
    if ('data' in query && isString(query.data) && query.data.startsWith('=')) {
        const condition = query.data.replace('=', '');
        const valueFromContext = get(context, condition);
        return {
            ...query,
            ...((isString(valueFromContext) && { data: valueFromContext }) || {})
        };
    }
    return query;
};
export const removeFilterById = (query, id) => {
    if (isConditionQuery(query)) {
        return {
            ...query,
            filters: query.filters
                .filter((filter) => (isConditionQuery(filter) ? filter.id !== id : true))
                .map((filter) => removeFilterById(filter, id))
        };
    }
    return query;
};
export const getFilterData = (filter) => {
    if (!('data' in filter)) {
        return;
    }
    if (!isComputedExpression(filter.data) &&
        filter.operator &&
        [FilterOperatorTypes.isIn, FilterOperatorTypes.isNotIn].includes(filter.operator)) {
        return Array.isArray(filter.data) ? filter.data : [];
    }
    return toString(filter.data);
};
export const parseTags = (pastedText) => pastedText.split(/[\r\n]+/).filter(Boolean);
export const copyRemainingTags = async (remainingTags, notificationDispatch, t, isError) => {
    if (remainingTags.length > 0) {
        const remainingTagsText = remainingTags.join('\n');
        const action = isError ? error : warning;
        try {
            await navigator.clipboard.writeText(remainingTagsText);
            notificationDispatch(action({
                title: isError ? t('ERROR') : t('WARNING'),
                message: isError
                    ? t('LIMIT_COUNT_FOR_FILTER_VALUE')
                    : t('REMAINING_TAGS_COPIED_TO_CLIPBOARD', { maxCount: MAX_COUNT_FILTERS })
            }));
        }
        catch (error) {
            notificationDispatch(action({
                title: isError ? t('ERROR') : t('WARNING'),
                message: isError
                    ? t('LIMIT_COUNT_FOR_FILTER_VALUE')
                    : t('REMAINING_TAGS_COPIED_TO_CLIPBOARD', { maxCount: MAX_COUNT_FILTERS }),
                children: (React.createElement(Button, { type: "primary", onClick: () => {
                        void navigator.clipboard.writeText(remainingTagsText);
                    } }, t('COPY'))),
                autoDismiss: 0
            }));
            console.error('Failed to copy remaining tags to clipboard:', error);
        }
    }
};
