import memoize from 'fast-memoize';
const getParsedValue = memoize((value) => JSON.parse(value));
export const addToLocalStorage = (storageKey, data) => {
    if (window.localStorage) {
        try {
            window.localStorage.setItem(storageKey, JSON.stringify(data));
        }
        catch (e) {
            // localstorage is not available
            // just ignore it
        }
    }
};
export const getFromLocalStorage = (storageKey, defaultData) => {
    let data = defaultData;
    if (window.localStorage) {
        try {
            data =
                getParsedValue(window.localStorage.getItem(storageKey)) || defaultData;
        }
        catch (e) {
            // localstorage is not available
            // just ignore it
        }
    }
    return data;
};
