import { WORKFLOWS_API_PATH } from '../common/utils/constants';
export const fetchDisplayProcess = (processMetaId, processRunId, languageCode) => ({
    type: 'WORKFLOWS__FETCH_DISPLAY_PROCESS',
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process/${processMetaId}/run/${processRunId}/display`,
            method: 'GET',
            params: { languageCode }
        }
    }
});
export const cancelProcess = (processMetaId, processRunId) => ({
    type: 'WORKFLOWS__CANCEL_PROCESS',
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process/${processMetaId}/run/${processRunId}/cancel`,
            method: 'POST',
            allowPromiseReject: true
        }
    }
});
export const deleteProcesses = (processMetaId, ids, filterRequest) => ({
    type: 'WORKFLOWS__DELETE_PROCESSES',
    payload: {
        ids,
        filterRequest
    },
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process/${processMetaId}/run`,
            allowPromiseReject: true,
            method: 'DELETE',
            actionContext: { processMetaId, processRunIds: ids, filterRequest }
        }
    }
});
// ////////////////////
// Task executors
// ////////////////////
export const searchTaskExecutors = (payload) => ({
    type: 'WORKFLOWS__SEARCH_TASK_EXECUTORS',
    payload,
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/task/search/executors`,
            method: 'POST',
            allowPromiseReject: true
        }
    }
});
export const updateTaskRunField = (processMetaId, processRunId, taskMetaId, taskRunId, value, languageCode) => ({
    type: 'WORKFLOWS__UPDATE_TASK_RUN_FIELD',
    payload: value,
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process/${processMetaId}/run/${processRunId}/task/${taskMetaId}/run/${taskRunId}/field`,
            method: 'PUT',
            params: { languageCode },
            allowPromiseReject: true
        }
    }
});
export const restartTaskRun = (processMetaId, processRunId, taskMetaId, taskRunId, runNextNode) => ({
    type: 'WORKFLOWS__RESTART_TASK_RUN',
    payload: { runNextNode },
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process/${processMetaId}/run/${processRunId}/task/${taskMetaId}/run/${taskRunId}`,
            method: 'POST',
            params: {},
            allowPromiseReject: true
        }
    }
});
export const bulkUpdateTaskRunField = (processMetaId, processRunId, taskMetaId, taskRunId, value, languageCode) => ({
    type: 'WORKFLOWS__BULK_UPDATE_TASK_RUN_FIELD',
    payload: value,
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process/${processMetaId}/run/${processRunId}/task/${taskMetaId}/run/${taskRunId}/bulk/field`,
            method: 'PUT',
            params: { languageCode },
            allowPromiseReject: true
        }
    }
});
