import { Dropdown, Input } from 'antd';
import cn from 'classnames';
import React, { isValidElement, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './DropdownMenu.module.scss';
const recursiveMenuItemsHandler = (items, search) => items.reduce((acc, item) => {
    if (item) {
        if ('children' in item) {
            const children = item.children?.length
                ? recursiveMenuItemsHandler(item.children, search)
                : [];
            if (children.length) {
                acc.push({ ...item, children });
            }
        }
        else if ('label' in item) {
            if (typeof item.label === 'string') {
                if (!search || item.label.toLowerCase().includes(search)) {
                    acc.push(item);
                }
            }
            else if (isValidElement(item.label)) {
                const labelProps = item.label.props;
                if (!search ||
                    (typeof labelProps?.title === 'string' &&
                        labelProps?.title.toLowerCase().includes(search))) {
                    acc.push(item);
                }
            }
        }
    }
    return acc;
}, []);
const trigger = ['click'];
const DropdownMenu = ({ searchPlaceholder, children, menuItems, onMenuItemClick, onMenuClick, defaultOpen = false, className = '' }) => {
    const [dropdownOpen, setDropdownOpen] = useState(defaultOpen);
    const [search, setSearch] = useState('');
    const handleSearchChange = useCallback((e) => {
        setSearch(e.target.value.toLowerCase());
    }, []);
    const menuItemsProcessed = useMemo(() => {
        if (!menuItems.length) {
            return [];
        }
        const searchUnified = search.replace(/\s+/gm, ' ').trim().toLowerCase();
        const generatedItems = recursiveMenuItemsHandler(menuItems, searchUnified);
        generatedItems.unshift({
            key: 'search',
            className: 'SearchMenuItem',
            label: React.createElement(Input, { value: search, placeholder: searchPlaceholder, onInput: handleSearchChange })
        });
        return generatedItems;
    }, [search, menuItems, searchPlaceholder, handleSearchChange]);
    const handleItemMenuClick = useCallback((item) => {
        if (item.key !== 'search') {
            setDropdownOpen(false);
            if (onMenuItemClick) {
                onMenuItemClick(item.key);
            }
        }
    }, [onMenuItemClick, setDropdownOpen]);
    const handleMenuOpenChange = useCallback((open) => {
        setDropdownOpen(open);
        if (onMenuClick) {
            onMenuClick(open);
        }
    }, [onMenuClick, setDropdownOpen]);
    useEffect(() => {
        if (dropdownOpen) {
            setSearch('');
        }
    }, [dropdownOpen]);
    return (React.createElement(Dropdown, { trigger: trigger, open: dropdownOpen, overlayClassName: cn(styles.overlay, className), onOpenChange: handleMenuOpenChange, menu: {
            onClick: handleItemMenuClick,
            items: menuItemsProcessed
        } }, children));
};
export default DropdownMenu;
