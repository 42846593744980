import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { compact, flatMap, get, isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { BiColorFill } from 'react-icons/bi';
import { ImTextColor } from 'react-icons/im';
import { SelectPropertyButton } from '../../../../../../cms/data/components/item-property/SelectProperty';
import { SelectColorButton } from '../../../../../../common/components/Color/SelectColorButton';
import { ConditionQueryLabel } from '../../../../../../common/components/ConditionQuery';
import { FaPlus } from 'react-icons/fa';
import { MAX_COUNT_FILTERS } from '../../../../../../common/components/ConditionQuery/constants';
import { buildFilter, getDefaultOperatorByType, getFilterComponentByType, isFilterType } from '../../../../../../common/components/ConditionQuery/utils';
import SortableList from '../../../../../../common/components/SortableList';
import { TooltipTruncate } from '../../../../../../common/components/TooltipTruncate';
import { PropertyTypes } from '../../../../../../common/types';
import { getMetaDefinitionFromFormValue } from '../../utils';
import styles from './ConditionalFormattingSelectorTab.module.scss';
const isNotHiddenProperty = ({ meta: { hidden } }) => !hidden;
const ConditionalFormattingSelectorTab = ({ value, values, metaDefinitionId, onChange, disabled }) => {
    const { t } = useI18n();
    const metaDefinition = useMemo(() => getMetaDefinitionFromFormValue(values), [values]);
    const allProperties = useMemo(() => compact(flatMap(get(metaDefinition, 'sections', []), 'properties')), [metaDefinition]);
    const displayDataMap = useMemo(() => allProperties.reduce((displayDataMap, displayData) => ({
        ...displayDataMap,
        [displayData.propertyId]: {
            ...displayData
        }
    }), {}), [allProperties]);
    const updateConditionalFormatting = useCallback((newConditionalFormatting) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                conditionalFormatting: newConditionalFormatting.length
                    ? newConditionalFormatting.filter((conditional) => displayDataMap[conditional.filter?.field])
                    : undefined
            }
        });
    }, [onChange, value, displayDataMap]);
    const addNewConditional = useCallback((field) => {
        if (!field) {
            return;
        }
        const displayData = displayDataMap[field];
        const { type, meta } = displayData;
        const isList = ('list' in meta && meta.list) ?? false;
        let valueFilter;
        switch (type) {
            case PropertyTypes.date:
                valueFilter = Date.now();
                break;
            case PropertyTypes.checkbox:
                valueFilter = false;
                break;
            default:
                valueFilter = undefined;
        }
        const newConditionalFilter = {
            id: generateId(),
            filter: buildFilter(field, getDefaultOperatorByType(type, isList), valueFilter),
            theme: {
                primary: '#fff',
                secondary: '#000'
            }
        };
        const { conditionalFormatting = [] } = value.meta;
        const newConditionalFormatting = [...conditionalFormatting, newConditionalFilter];
        updateConditionalFormatting(newConditionalFormatting);
    }, [displayDataMap, updateConditionalFormatting, value.meta]);
    const updateConditional = useCallback((newFilter) => {
        const conditionalFormatting = value.meta.conditionalFormatting || [];
        const newConditionalFormatting = conditionalFormatting.map((item) => item.id === newFilter.id ? newFilter : item);
        updateConditionalFormatting(newConditionalFormatting);
    }, [updateConditionalFormatting, value.meta.conditionalFormatting]);
    const handleOnChangeDrag = useCallback((newValue) => {
        updateConditionalFormatting(newValue.map(({ item }) => item));
    }, [updateConditionalFormatting]);
    const sortableJsx = useMemo(() => value.meta.conditionalFormatting &&
        !isEmpty(value.meta.conditionalFormatting) &&
        value.meta.conditionalFormatting
            .filter((conditional) => !!displayDataMap[conditional.filter?.field])
            .map((conditional) => {
            const filterDisplayData = displayDataMap[conditional.filter?.field];
            const FilterComponent = getFilterComponentByType(filterDisplayData.type);
            return {
                id: conditional.id,
                item: conditional,
                label: (React.createElement(React.Fragment, null,
                    React.createElement(ConditionQueryLabel, { displayData: filterDisplayData, className: "mb-2" }),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-md-7 col-xs-12" },
                            React.createElement(FilterComponent, { filter: conditional.filter, onChange: (filter) => {
                                    isFilterType(filter) &&
                                        updateConditional({
                                            ...conditional,
                                            filter
                                        });
                                }, meta: filterDisplayData.meta, disabled: disabled, availableCountForFilters: MAX_COUNT_FILTERS })),
                        React.createElement("div", { className: "col-md-5 col-xs-12 ps-md-0 d-flex" },
                            React.createElement(SelectColorButton, { icon: BiColorFill, value: conditional.theme?.primary, preview: false, onChange: (primary) => updateConditional({
                                    ...conditional,
                                    theme: {
                                        ...conditional.theme,
                                        primary
                                    }
                                }) }),
                            React.createElement(SelectColorButton, { icon: ImTextColor, value: conditional.theme?.secondary, preview: false, onChange: (secondary) => updateConditional({
                                    ...conditional,
                                    theme: {
                                        ...conditional.theme,
                                        secondary
                                    }
                                }), className: "ms-3" }),
                            React.createElement("div", { className: "ms-3 flex-fill overflow-hidden px-1 example-block d-flex align-items-center justify-content-center rounded fw-600 user-select-none", style: {
                                    backgroundColor: conditional.theme?.primary,
                                    color: conditional.theme?.secondary
                                } },
                                React.createElement(TooltipTruncate, null, t('EXAMPLE')))))))
            };
        }), [disabled, displayDataMap, t, updateConditional, value.meta.conditionalFormatting]);
    return (React.createElement("div", { className: `${styles.container}` },
        sortableJsx && (React.createElement(SortableList, { disabled: disabled, onChange: handleOnChangeDrag, className: `${styles.sortable} rounded mb-3 overflow-hidden`, value: sortableJsx })),
        React.createElement(SelectPropertyButton, { className: "select-property", disabled: disabled, definitionId: metaDefinitionId, includeNestedProperties: false, onChange: addNewConditional, includeProperty: isNotHiddenProperty, label: React.createElement(React.Fragment, null,
                React.createElement(FaPlus, { size: 18, className: "me-2" }),
                t('ADD_CONDITION')) })));
};
export default ConditionalFormattingSelectorTab;
