import { CUSTOMER_PORTAL_APP_ID, EMAIL_RECEIVING_APP_ID, HYPER_BUILDER_APP_ID, HYPER_DEALER_APP_ID, HYPER_EVENT_APP_ID, HYPER_INSPECTION_APP_ID, HYPER_LANDING_APP_ID } from './constants';
import CustomerPortalInstanceForm, { getFormSchema as customerPortalGetInstancesFormSchema, initialValues as customerPortalInstancesInitialValues } from './customer-portal/InstanceForm';
import DefaultAppInstancesForm, { getFormSchema as defaultAppGetInstancesFormSchema, initialValues as defaultAppInstancesInitialValues } from './default-app/InstanceForm';
import EmailReceivingInstancesForm, { getFormSchema as emailReceivingGetInstancesFormSchema, initialValues as emailReceivingInstancesInitialValues } from './email-receiving/InstanceForm';
import HyperBuilderInstancesForm, { getFormSchema as hyperBuilderGetInstancesFormSchema, initialValues as hyperBuilderInstancesInitialValues } from './hyper-builder/InstanceForm';
import HyperDealerInstancesForm, { getFormSchema as hyperDealerGetInstancesFormSchema, initialValues as hyperDealerInstancesInitialValues } from './hyper-dealer/InstancesForm';
import HyperEventInstancesForm, { getFormSchema as hyperEventGetInstancesFormSchema, initialValues as hyperEventInstancesInitialValues } from './hyper-event/InstanceForm';
import HyperInspectionInstancesForm, { getFormSchema as hyperInspectionGetInstancesFormSchema, initialValues as hyperInspectionInstancesInitialValues } from './hyper-inspection/InstanceForm';
import HyperLandingInstancesForm, { getFormSchema as hyperLandingGetInstancesFormSchema, initialValues as hyperLandingInstancesInitialValues } from './hyper-landing/InstanceForm';
// Put your custom forms for configurations here
const FORM_MAPPINGS = {
    [HYPER_DEALER_APP_ID]: {
        form: HyperDealerInstancesForm,
        schema: hyperDealerGetInstancesFormSchema,
        initialValues: hyperDealerInstancesInitialValues
    },
    [CUSTOMER_PORTAL_APP_ID]: {
        form: CustomerPortalInstanceForm,
        schema: customerPortalGetInstancesFormSchema,
        initialValues: customerPortalInstancesInitialValues
    },
    [HYPER_LANDING_APP_ID]: {
        form: HyperLandingInstancesForm,
        schema: hyperLandingGetInstancesFormSchema,
        initialValues: hyperLandingInstancesInitialValues
    },
    [HYPER_EVENT_APP_ID]: {
        form: HyperEventInstancesForm,
        schema: hyperEventGetInstancesFormSchema,
        initialValues: hyperEventInstancesInitialValues
    },
    [HYPER_BUILDER_APP_ID]: {
        form: HyperBuilderInstancesForm,
        schema: hyperBuilderGetInstancesFormSchema,
        initialValues: hyperBuilderInstancesInitialValues
    },
    [HYPER_INSPECTION_APP_ID]: {
        form: HyperInspectionInstancesForm,
        schema: hyperInspectionGetInstancesFormSchema,
        initialValues: hyperInspectionInstancesInitialValues
    },
    [EMAIL_RECEIVING_APP_ID]: {
        form: EmailReceivingInstancesForm,
        schema: emailReceivingGetInstancesFormSchema,
        initialValues: emailReceivingInstancesInitialValues
    }
};
export const getConfigurationFormFor = (appId) => {
    return FORM_MAPPINGS[appId]?.form ?? DefaultAppInstancesForm;
};
export const getFormInitialValuesFor = (appId) => {
    return FORM_MAPPINGS[appId]?.initialValues ?? defaultAppInstancesInitialValues;
};
export const getFormSchemaFor = (appId) => {
    return FORM_MAPPINGS[appId]?.schema ?? defaultAppGetInstancesFormSchema;
};
