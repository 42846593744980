import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider, Tooltip } from 'antd';
import { difference } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Field, Fields } from 'redux-form';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { SelectPropertyField, SortablePropertyListField } from '../../../../../../cms/data/components/item-property/SelectProperty';
import { RichTextEditorWithWrapper } from '../../../../../../cms/data/components/item-property/editors/RichTextEditor';
import { AntInput, AntInputField } from '../../../../../../common/components/AntInput';
import { ComputedInputField } from '../../../../../../common/components/ComputedExpressionEditor';
import MultiLanguageField from '../../../../../../common/components/MultiLanguageField/MultiLanguageField';
import Toggle from '../../../../../../common/components/Toggle';
import { PropertyTypes } from '../../../../../../common/types';
import { CONTACT_CMS_DEFINITION_ID } from '../../../../../../crm/constants';
import GroupsField from '../../../../../../groups';
import { AvailableSystemGroups } from '../../../../../../groups/constants';
import TaskPriorityField from '../../../../../common/components/TaskPriorityField/TaskPriorityField';
import { DeadlineField } from '../components/DeadlineField';
import DeadlinePermissionField from '../components/DeadlinePermissionField/DeadlinePermissionField';
import { isPermissionToggleDisabled } from '../utils';
import styles from './ActionForm.module.scss';
const ALLOWED_ROW_PERMISSIONS = ['view', 'write'];
const ActionForm = ({ disabled, metaDefinitionId, parentViewGroups, node, changeFieldValue }) => {
    const { t } = useI18n();
    const getRequiredExtraAction = useCallback(({ item }) => {
        return (React.createElement(Tooltip, { title: t('OVERRIDE_EDIT_PERMISSION') },
            React.createElement(Field, { name: `parameters.propertySettings.required['${item.id}'].canEdit`, component: Toggle, disabled: disabled })));
    }, [disabled, t]);
    const getOptionalExtraAction = useCallback(({ item }) => {
        return (React.createElement(Tooltip, { title: t('OVERRIDE_EDIT_PERMISSION') },
            React.createElement(Field, { name: `parameters.propertySettings.optional['${item.id}'].canEdit`, component: Toggle, disabled: disabled })));
    }, [disabled, t]);
    const onChangeProperties = (newValue = [], prevValue = [], paramName, type, originName) => {
        if (!originName) {
            return;
        }
        const added = difference(newValue, prevValue);
        const removed = difference(prevValue, newValue);
        for (const property of added) {
            changeFieldValue(originName.replace(paramName, `parameters.propertySettings.${type}['${property}'].canEdit`), true);
        }
        for (const property of removed) {
            changeFieldValue(originName.replace(paramName, `parameters.propertySettings.${type}['${property}'].canEdit`), undefined);
        }
    };
    const onChangeRowPermissions = useCallback((newRowPermissions) => {
        changeFieldValue(`activity.${node.id}.permissions`, newRowPermissions);
    }, [changeFieldValue, node.id]);
    const forceSelect = useMemo(() => {
        return {
            viewGroups: Object.keys(node?.permissions?.view || {}).reduce((acc, groupId) => {
                if (node?.permissions?.view?.[groupId]?.restrictions?.filters?.length) {
                    acc[groupId] = false;
                }
                return acc;
            }, {}),
            executeGroups: Object.keys(node?.permissions?.write || {}).reduce((acc, groupId) => {
                if (node?.permissions?.write?.[groupId]?.restrictions?.filters?.length) {
                    acc[groupId] = false;
                }
                return acc;
            }, {})
        };
    }, [node]);
    const isContactProperty = useCallback((displayData) => {
        if (displayData.type == PropertyTypes.entity &&
            displayData.meta.definitionId === CONTACT_CMS_DEFINITION_ID) {
            return true;
        }
        return false;
    }, []);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement("div", { className: "flex-row flex-wrap" },
            React.createElement(MultiLanguageField, { name: "titles", component: AntInput, disabled: disabled, maxLength: 60, placeholder: t('COMMON__TITLE') })),
        React.createElement(FieldLabel, { label: t('PROPERTY_DESCRIPTION'), className: "ps-0" }),
        React.createElement("div", { className: "flex-row flex-wrap" },
            React.createElement(MultiLanguageField, { name: "descriptions", placeholder: t('COMMON__DESCRIPTION'), component: RichTextEditorWithWrapper, disabled: disabled, extraFieldComponentProps: {
                    label: t('PROPERTY_DESCRIPTION')
                } })),
        React.createElement(FieldLabel, { label: t('DEADLINE'), className: "ps-0" }),
        React.createElement("div", { className: "d-flex align-items-center time-limit" },
            React.createElement(Field, { name: "timeLimit", className: "w-100", component: DeadlineField, disabled: disabled }),
            React.createElement("div", { className: "ms-2 permission-btn" },
                React.createElement(DeadlinePermissionField, { parentViewGroups: parentViewGroups, timeLimitPermissions: node?.timeLimitPermissions, disabled: disabled }))),
        React.createElement(FieldLabel, { label: t('PRIORITY'), className: "ps-0" }),
        React.createElement(TaskPriorityField, { disabled: disabled }),
        React.createElement(FieldLabel, { label: t('TASK_REQUIRED_PROPERTIES'), className: "ps-0" }),
        React.createElement("div", { className: "flex-row flex-wrap" },
            React.createElement(Field, { name: "requiredProperties", component: SortablePropertyListField, placeholder: t('SELECT_REQUIRED_PROPERTIES'), definitionId: metaDefinitionId, disabled: disabled, extraAction: getRequiredExtraAction, onChange: (_, newValue, prevValue, name) => {
                    onChangeProperties(newValue, prevValue, 'requiredProperties', 'required', name);
                } })),
        React.createElement(FieldLabel, { label: t('TASK_OPTIONAL_PROPERTIES'), className: "ps-0" }),
        React.createElement("div", { className: "flex-row flex-wrap" },
            React.createElement(Field, { name: "optionalProperties", component: SortablePropertyListField, definitionId: metaDefinitionId, disabled: disabled, extraAction: getOptionalExtraAction, onChange: (_, newValue, prevValue, name) => {
                    onChangeProperties(newValue, prevValue, 'optionalProperties', 'optional', name);
                } })),
        React.createElement("div", { className: "mb-2" },
            React.createElement(FieldLabel, { className: "ps-0", label: t('TASK_IFRAME_URL') }),
            React.createElement(Field, { name: "parameters.iframe.url", component: ComputedInputField, computedComponent: AntInput, disabled: disabled, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('TASK_IFRAME_URL')}` })),
        React.createElement("div", { className: "mb-2" },
            React.createElement(FieldLabel, { className: "ps-0", label: t('TASK_IFRAME_HEIGHT') }),
            React.createElement(Field, { placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('TASK_IFRAME_HEIGHT')}`, component: AntInputField, name: "parameters.iframe.height", type: "number" })),
        React.createElement(Divider, null, t('SETTINGS')),
        React.createElement(FieldLabel, { label: t('AUTOCOMLETE_TASK'), className: "ps-0" }),
        React.createElement("div", { className: "flex-row flex-wrap mb-2" },
            React.createElement(Field, { name: "autocompleteTask", component: Toggle, disabled: disabled })),
        React.createElement(FieldLabel, { label: t('AUTO_ASSIGN_TASK'), className: "ps-0" }),
        React.createElement("div", { className: "flex-row flex-wrap" },
            React.createElement(Field, { name: "parameters.autoAssign", component: ComputedInputField, computedComponent: SelectPropertyField, definitionId: metaDefinitionId, includeProperty: isContactProperty, includeNestedProperties: true, disabled: disabled, placeholder: t('AUTO_ASSIGN_TASK_PLACEHOLDER') })),
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups, AvailableSystemGroups.executeGroups], component: GroupsField, disabled: disabled, withValidation: true, parentViewGroups: parentViewGroups, itemMetaDefinitionId: metaDefinitionId, rowPermissions: node.permissions, allowedRowPermissions: ALLOWED_ROW_PERMISSIONS, onChangeRowPermissions: onChangeRowPermissions, forceSelect: forceSelect, isPermissionToggleDisabled: (groupId, permission) => {
                return isPermissionToggleDisabled(permission, groupId, node.permissions);
            }, isDynamicPermissionSettings: true })));
};
export default ActionForm;
