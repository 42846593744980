import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { WORKFLOWS_API_PATH } from '../common/utils/constants';
import { invalidateProcessDefinitionQuery } from './invalidateCache';
const updateProcessDefinition = (process) => ({
    type: 'PROCESS_DEFINITION__UPDATE',
    payload: process,
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process/${process.definitionId}`,
            method: 'PUT',
            allowPromiseReject: true
        }
    }
});
const useUpdateProcessDefinition = () => {
    const updateProcessDefinitionDispatch = useDispatch();
    return useMutation({
        mutationFn: async (definition) => {
            return updateProcessDefinitionDispatch(updateProcessDefinition(definition));
        },
        onSettled: async (result, _error, _definition) => {
            if (result) {
                await invalidateProcessDefinitionQuery(result.definitionId);
            }
        }
    });
};
export default useUpdateProcessDefinition;
