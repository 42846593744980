import { Select } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { AntInput } from '../../../../../../../common/components/AntInput';
import { ComputedInput } from '../../../../../../../common/components/ComputedExpressionEditor';
import styles from './ResourceLocatorField.module.scss';
const ResourceLocatorField = ({ disabled, value, onChange, parameter, propertiesTree, error }) => {
    const onChangeMode = useCallback((newMode) => {
        onChange?.({ ...value, mode: newMode });
    }, [onChange, value]);
    const onChangeModeValue = useCallback((newModeValue) => {
        onChange?.({ ...value, value: newModeValue });
    }, [onChange, value]);
    const options = useMemo(() => {
        return (parameter.modes?.map((mode) => {
            return {
                value: mode.name,
                label: mode.displayName
            };
        }) || []);
    }, [parameter.modes]);
    return (React.createElement("div", { className: `row ${styles.wrapper}` },
        React.createElement("div", { className: "col-auto options" },
            React.createElement(Select, { className: "w-100", popupMatchSelectWidth: false, options: options, onChange: onChangeMode, value: value.mode, disabled: !!disabled })),
        React.createElement("div", { className: "col" },
            React.createElement(ComputedInput, { component: AntInput, onChange: onChangeModeValue, value: value.value, disabled: !!disabled, propertiesTree: propertiesTree, error: error }))));
};
export default ResourceLocatorField;
