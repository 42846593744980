import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import NotFound from '@hypercharge/hyper-react-base/lib/common/not-found';
import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { Tabs } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { MdFormatListBulleted } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { HIGHLIGHTED_PROPERTIES_PATH, HISTORY_PATH, META_DATA_PATH, META_DEFINITION_PATH } from '../../../../cms/common/utils/constants';
import { HighlightedPropertiesForm } from '../../../../cms/settings/components/entity/HighlightedItems';
import { History } from '../../../../cms/settings/components/entity/History';
import { TooltipTruncate } from '../../../../common/components/TooltipTruncate';
import { BROWSE_PATH, SETTINGS_PATH } from '../../../../common/utils/constants';
import { prepareGroupPermissionsForSubmit } from '../../../../common/utils/formUtils';
import { ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP } from '../../../../groups/constants';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID, PROCESSES_PATH, WORKFLOWS_PATH } from '../../../common/utils/constants';
import { ActivityNodeTypes } from '../../../common/utils/types';
import useCreateProcessDefinition from '../../../hooks/useCreateProcessDefinition';
import useProcessDefinition from '../../../hooks/useProcessDefinition';
import useUpdateProcessDefinition from '../../../hooks/useUpdateProcessDefinition';
import { META_TASKS_PATH, QUICKSTART_PATH } from '../../constants';
import { isProcessActivity, prepareNodeGroupPermissionsForSubmit } from '../../utils';
import ActivityContainerWrapper from '../activity-settings/ActivityContainerWrapper/ActivityContainerWrapper';
import MetaDefinitionForm from '../meta-definition-form';
import ProcessMetaForm from '../process-meta-form/ProcessMetaForm';
import { formName } from '../process-meta-form/utils';
import QuickStartForm from '../quickstart-form/QuickStartForm';
import CenteredLoading from './CenteredLoading';
import ProcessMetaActions from './ProcessMetaActions';
import styles from './ProcessMetaRoutes.module.scss';
const DEFAULT_PROCESS = {
    definitionId: '',
    titles: {},
    custom: {
        idPrefix: '',
        color: '#4da0ff',
        activity: {
            // @ts-expect-error use types from backend
            Start: { id: 'Start', nextId: 'End', type: ActivityNodeTypes.START },
            End: { id: 'End', type: ActivityNodeTypes.END }
        }
    },
    viewGroups: [ALL_USERS_PERMISSION_GROUP, ADMINS_PERMISSION_GROUP],
    createGroups: [ALL_USERS_PERMISSION_GROUP, ADMINS_PERMISSION_GROUP],
    deleteGroups: [ADMINS_PERMISSION_GROUP],
    sections: []
};
const ProcessMetaRoutes = ({ processMetaId, contentHeight, baseUrl, url, setCurrentBreadcrumbText, setLinks }) => {
    const { t, language } = useI18n();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const { mutateAsync: createProcessDefinition } = useCreateProcessDefinition();
    const { mutateAsync: updateProcessDefinition } = useUpdateProcessDefinition();
    const { definition: fetchedProcess, isError } = useProcessDefinition({
        definitionId: processMetaId
    });
    const processDefinition = processMetaId ? fetchedProcess : DEFAULT_PROCESS;
    const isSystem = !!processDefinition?.system;
    useEffect(() => {
        if (!processMetaId) {
            setLinks([
                { text: t('WORKFLOWS_NAV_LINK'), to: WORKFLOWS_PATH },
                {
                    text: t('WORKFLOWS_SETTINGS_PROCESS_META_NAV_LINK'),
                    to: `${WORKFLOWS_PATH}${PROCESSES_PATH}/${ALL_PROCESSES_DEFINITION_ID}${BROWSE_PATH}`
                }
            ]);
            setCurrentBreadcrumbText(t('CREATE_PROCESS_META__TITLE'));
        }
        else if (processDefinition) {
            setLinks([
                { text: t('WORKFLOWS_NAV_LINK'), to: WORKFLOWS_PATH },
                {
                    text: t('WORKFLOWS_SETTINGS_PROCESS_META_NAV_LINK'),
                    to: `${WORKFLOWS_PATH}${PROCESSES_PATH}/${ALL_PROCESSES_DEFINITION_ID}${BROWSE_PATH}`
                },
                {
                    text: getTranslation(processDefinition.titles, language),
                    to: `${baseUrl}${BROWSE_PATH}`
                }
            ]);
            setCurrentBreadcrumbText(t('SETTINGS'));
        }
    }, [setLinks, setCurrentBreadcrumbText, baseUrl, t, processMetaId, processDefinition, language]);
    const navLinks = useMemo(() => {
        const navLinks = [{ to: url + META_DATA_PATH, text: t('BASIC_INFO') }];
        if (processMetaId) {
            navLinks.push({
                to: url + META_DEFINITION_PATH,
                text: t('SETTINGS_PROPERTIES_NAV_LINK')
            });
            if (![ALL_TASKS_DEFINITION_ID, ALL_PROCESSES_DEFINITION_ID].includes(processMetaId)) {
                navLinks.push({
                    to: url + META_TASKS_PATH,
                    text: t('WORKFLOWS_SETTINGS_TASK_META_NAV_LINK')
                }, {
                    to: url + HIGHLIGHTED_PROPERTIES_PATH,
                    text: t('HIGHLIGHTED_PROPERTIES_NAV_LINK')
                });
            }
            navLinks.push({
                to: url + QUICKSTART_PATH,
                text: t('QUICKSTART_PROCESS_NAV_LINK')
            });
            navLinks.push({
                to: url + HISTORY_PATH,
                text: t('HISTORY_NAV_LINK')
            });
        }
        return navLinks.map((link) => ({
            key: link.to,
            label: (React.createElement(Link, { className: "link", to: link.to }, link.text))
        }));
    }, [processMetaId, t, url]);
    const extraActionButtonsJsx = useMemo(() => {
        if (!processMetaId) {
            return;
        }
        return (React.createElement(Button, { inversed: true, to: `${baseUrl}${BROWSE_PATH}`, className: "ms-0 d-flex ProcessesButton" },
            React.createElement(MdFormatListBulleted, { className: "me-1" }),
            React.createElement(TooltipTruncate, null, t('WORKFLOWS_PROCESSES_NAV_LINK'))));
    }, [baseUrl, processMetaId, t]);
    const onSubmit = useCallback(async (value) => {
        let partialNewProcessDefinition = prepareGroupPermissionsForSubmit(value);
        if (partialNewProcessDefinition.custom?.activity &&
            isProcessActivity(partialNewProcessDefinition.custom?.activity)) {
            const newActivity = prepareNodeGroupPermissionsForSubmit(partialNewProcessDefinition.custom.activity);
            partialNewProcessDefinition = {
                ...partialNewProcessDefinition,
                custom: {
                    ...partialNewProcessDefinition.custom,
                    activity: newActivity
                }
            };
        }
        const newProcessDefinition = {
            ...partialNewProcessDefinition,
            definitionId: processMetaId ?? ''
        };
        try {
            const savedProcessDefinition = processMetaId
                ? await updateProcessDefinition(newProcessDefinition)
                : await createProcessDefinition(newProcessDefinition);
            dispatch(success({
                title: t('SUCCESS'),
                message: t('SAVE_SETTINGS_SUCCESS')
            }));
            if (!processMetaId) {
                dispatch(push(`${WORKFLOWS_PATH}${PROCESSES_PATH}/${savedProcessDefinition.definitionId}${SETTINGS_PATH}`));
            }
            return savedProcessDefinition;
        }
        catch (err) {
            dispatch(error({
                title: t('ERROR'),
                message: t('SAVE_SETTINGS_FAIL')
            }));
        }
        if (processDefinition) {
            return processDefinition;
        }
        return DEFAULT_PROCESS;
    }, [
        createProcessDefinition,
        dispatch,
        processDefinition,
        processMetaId,
        t,
        updateProcessDefinition
    ]);
    if (isError) {
        return React.createElement(NotFound, null);
    }
    if (!processDefinition) {
        return React.createElement(CenteredLoading, null);
    }
    const parentViewGroups = processDefinition.viewGroups ?? [];
    return (React.createElement(SectionCard, { withoutPadding: true, withoutMargin: true, className: styles.wrapper },
        React.createElement(Tabs, { animated: false, className: "nav-bar pt-md-0 pt-7", size: "middle", activeKey: pathname, items: navLinks }),
        React.createElement("div", { style: {
                height: contentHeight
            } },
            React.createElement(ErrorBoundary, null,
                React.createElement(Switch, null,
                    React.createElement(Route, { path: `${url}${META_DATA_PATH}`, render: (props) => (React.createElement(ProcessMetaForm, { processDefinition: processDefinition, extraActionButtons: extraActionButtonsJsx, isSystem: isSystem, onSubmit: onSubmit, ...props, 
                            // redux-form props
                            initialValues: processDefinition, form: processDefinition.definitionId
                                ? `${formName}_${processDefinition.definitionId}`
                                : formName })) }),
                    React.createElement(Route, { path: `${url}${META_DEFINITION_PATH}`, render: (props) => {
                            return (React.createElement(MetaDefinitionForm, { definition: processDefinition, parentViewGroups: parentViewGroups, mainActionButtonComponent: ({ metaDefinitionId, submitForm, submitting, disabled, dirty, formIsValid }) => (React.createElement(ProcessMetaActions, { definitionId: metaDefinitionId, save: submitForm, saving: submitting, disabled: disabled, formIsValid: formIsValid, canSave: dirty, canDelete: !isSystem })), onSubmit: onSubmit, extraActionButtons: extraActionButtonsJsx, ...props }));
                        } }),
                    React.createElement(Route, { exact: true, path: `${url}${META_TASKS_PATH}`, render: (props) => (React.createElement(ActivityContainerWrapper, { contentHeight: contentHeight, processDefinition: processDefinition, parentViewGroups: parentViewGroups, extraActionButtons: extraActionButtonsJsx, isSystem: isSystem, onSubmit: onSubmit, ...props })) }),
                    React.createElement(Route, { exact: true, path: `${url}${HIGHLIGHTED_PROPERTIES_PATH}` },
                        React.createElement(HighlightedPropertiesForm, { definition: processDefinition, extraActionButtons: extraActionButtonsJsx, isSystem: isSystem, onSubmit: onSubmit })),
                    React.createElement(Route, { exact: true, path: `${url}${HISTORY_PATH}` },
                        React.createElement(History, { definitionId: processDefinition.definitionId, extraActionButtons: extraActionButtonsJsx })),
                    React.createElement(Route, { exact: true, path: `${url}${QUICKSTART_PATH}` },
                        React.createElement(QuickStartForm, { processDefinition: processDefinition, isSystem: isSystem, extraActionButtons: extraActionButtonsJsx, onSubmit: onSubmit })),
                    React.createElement(Redirect, { to: `${url}${META_DATA_PATH}` }))))));
};
export default ProcessMetaRoutes;
