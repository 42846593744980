export const getI18nState = (s) => s.i18n;
export const getMetadataState = (s) => s.metadata;
export const getNotificationsState = (s) => s.notifications;
export const getBrowserState = (s) => s.browser;
export const getCmsState = (s) => s.cms;
export const getPermissionsState = (s) => s.permissions;
export const getConfigsState = (s) => s.configs;
export const getMessagesState = (s) => s.messages;
export const getUserNotificationsState = (s) => s.userNotifications;
export const getStorageState = (s) => s.storage;
export const getFormsState = (s) => s.forms;
