import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { invalidateTenantCache, useTenant } from '~app/tenant/useTenant';
const useLogs = () => {
    const enableLogsDispatch = useDispatch();
    const { tenant, isPending: isTenantPending } = useTenant();
    const { mutateAsync: enableLogs, isLoading: isEnableLogsLoading } = useMutation({
        mutationFn: async () => {
            return enableLogsDispatch({
                type: 'LOGS__ENABLE',
                meta: {
                    http: {
                        path: `/v2-logs/settings/enable`,
                        method: 'POST',
                        allowPromiseReject: true
                    }
                }
            });
        },
        onSuccess: () => {
            return invalidateTenantCache();
        }
    });
    return {
        enableLogs,
        isEnableLogsLoading,
        isLogsSettingsLoading: isTenantPending,
        isLogsEnabled: !!tenant?.logs
    };
};
export default useLogs;
