import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { RELEASE_NOTES_DEFINITION_ID } from '../../releaseNotes/constants';
import { VIEW_DEFINITION_ID } from '../../views';
import { initialFilterRequest } from '../../views/components/ViewContext';
import { CMS_API_PATH } from '../common/utils/constants';
import { REFERENCE_DEFINITION_ID_PROPERTY_ID } from '../constants';
import { invalidateDefinitionQuery } from './invalidateCache';
import useDeleteItems from './useDeleteItems';
const deleteMetaDefinition = (definitionId) => ({
    type: 'ENTITY_META_DEFINITION__DELETE',
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${definitionId}`,
            method: 'DELETE',
            allowPromiseReject: true
        }
    }
});
const useDeleteDefinition = () => {
    const dispatch = useDispatch();
    const { mutateAsync: deleteReleaseNote } = useDeleteItems({
        definitionId: RELEASE_NOTES_DEFINITION_ID
    });
    const { mutateAsync: deleteViews } = useDeleteItems({ definitionId: VIEW_DEFINITION_ID });
    return useMutation({
        mutationFn: async (definitionId) => {
            return dispatch(deleteMetaDefinition(definitionId));
        },
        onSettled: async (_response, _error, definitionId) => {
            const deleteFilterRequest = {
                ...initialFilterRequest,
                query: {
                    condition: ConditionType.and,
                    filters: [
                        {
                            field: REFERENCE_DEFINITION_ID_PROPERTY_ID,
                            operator: FilterOperatorTypes.is,
                            data: definitionId
                        }
                    ]
                }
            };
            await deleteReleaseNote({ filterRequest: deleteFilterRequest });
            await deleteViews({ filterRequest: deleteFilterRequest });
            await invalidateDefinitionQuery(definitionId);
        }
    });
};
export default useDeleteDefinition;
