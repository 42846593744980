import LoadingRectangles from "@hypercharge/hyper-react-base/lib/common/loading-rectangles";
import { useI18n } from "@hypercharge/hyper-react-base/lib/i18n";
import { ConditionType, FilterOperatorTypes, SortOrder } from "@hypercharge/portal-utils";
import { Button, Tooltip } from "antd";
import { DEFAULT_LANGUAGE } from "config";
import { flatten, head, uniq } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { MdOutlineNoteAlt } from "react-icons/md";
import { useInView } from "react-intersection-observer";
import { ENTITY_OWNER_PROPERTY_ID } from "../../../cms/common/utils/constants";
import { REFERENCE_DEFINITION_ID_PROPERTY_ID } from "../../../cms/constants";
import { readableDateTime } from "../../../cms/data/components/item-property/DataHistoryList/utils";
import { useInfiniteSearchItems, useSearchItems } from "../../../cms/hooks/useSearchItems";
import { Drawer, DrawerContent } from "../../../common/components/Drawer";
import { DrawerContentBlock } from "../../../common/components/Drawer/DrawerContentBlock";
import { initialFilterRequest } from "../../../views/components/ViewContext";
import { RELEASE_NOTES_DEFINITION_ID, RELEASE_NOTES_PROPERTY_MAP, VERSION_PROPERTY_ID } from "../../constants";
import { ReleaseNoteHistoryItem } from "./ReleaseNoteHistoryItem";
const ReleaseNoteHistory = ({ definitionId, createdAt }) => {
    const { t, language } = useI18n();
    const [openReleaseNoteHistory, setOpenReleaseNoteHistory] = useState(false);
    const { ref, inView } = useInView();
    const responseFields = useMemo(() => uniq([
        ENTITY_OWNER_PROPERTY_ID,
        VERSION_PROPERTY_ID,
        RELEASE_NOTES_PROPERTY_MAP[language],
        RELEASE_NOTES_PROPERTY_MAP[DEFAULT_LANGUAGE]
    ]), [language]);
    const baseFilterReferenceDefinitionId = useMemo(() => ({
        field: REFERENCE_DEFINITION_ID_PROPERTY_ID,
        operator: FilterOperatorTypes.is,
        data: definitionId
    }), [definitionId]);
    const { data: releaseNotes, isLoading: isLoadingReleaseNotes, isFetched: isFetchedReleaseNotes, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteSearchItems({
        definitionId: RELEASE_NOTES_DEFINITION_ID,
        filterRequest: {
            ...initialFilterRequest,
            query: {
                condition: ConditionType.and,
                filters: [
                    baseFilterReferenceDefinitionId,
                    {
                        field: VERSION_PROPERTY_ID,
                        operator: FilterOperatorTypes.greaterThanOrEqual,
                        data: createdAt
                    }
                ]
            },
            limit: 10,
            responseFields,
            sortBy: [
                {
                    field: VERSION_PROPERTY_ID,
                    order: SortOrder.asc
                }
            ]
        }
    });
    const { data: actualReleaseNotes, isPending: isLoadingActualReleaseNotes, isFetched: isFetchedActualReleaseNotes } = useSearchItems({
        definitionId: RELEASE_NOTES_DEFINITION_ID,
        filterRequest: {
            ...initialFilterRequest,
            query: {
                condition: ConditionType.and,
                filters: [
                    baseFilterReferenceDefinitionId,
                    {
                        field: VERSION_PROPERTY_ID,
                        operator: FilterOperatorTypes.lessThanEqual,
                        data: createdAt
                    }
                ]
            },
            limit: 1,
            responseFields,
            sortBy: [
                {
                    field: VERSION_PROPERTY_ID,
                    order: SortOrder.desc
                }
            ]
        }
    });
    const allReleaseNotes = useMemo(() => flatten(releaseNotes?.pages.map((page) => page?.results || [])), [releaseNotes?.pages]);
    const actualReleaseNote = useMemo(() => head(actualReleaseNotes?.results), [actualReleaseNotes?.results]);
    const releaseNotesGroups = useMemo(() => {
        const groups = {};
        if (actualReleaseNote) {
            const time = readableDateTime(actualReleaseNote.version);
            groups[time] = [];
            groups[time].push(actualReleaseNote);
        }
        allReleaseNotes.forEach((value) => {
            const time = readableDateTime(value.version);
            groups[time] = groups[time] || [];
            groups[time].push(value);
        });
        return groups;
    }, [actualReleaseNote, allReleaseNotes]);
    useEffect(() => {
        if (inView) {
            void fetchNextPage();
        }
    }, [fetchNextPage, inView]);
    if (isFetchedReleaseNotes &&
        !allReleaseNotes.length &&
        isFetchedActualReleaseNotes &&
        !actualReleaseNote) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: t('RELEASE_NOTES') },
            React.createElement(Button, { loading: isLoadingReleaseNotes || isLoadingActualReleaseNotes, type: "text", size: "large", onClick: () => setOpenReleaseNoteHistory(true), icon: React.createElement(MdOutlineNoteAlt, { size: 16 }), className: "action-button" })),
        React.createElement(Drawer, { title: t('RELEASE_NOTES'), onClose: () => setOpenReleaseNoteHistory(false), open: openReleaseNoteHistory, destroyOnClose: true, closable: true },
            React.createElement(DrawerContent, { loading: isLoadingReleaseNotes || isLoadingActualReleaseNotes },
                Object.entries(releaseNotesGroups).map(([date, valuesOfGroup]) => (React.createElement(DrawerContentBlock, { key: date, title: date }, valuesOfGroup.map((value) => (React.createElement(ReleaseNoteHistoryItem, { key: value.version, version: value.version, entityOwner: value.entityOwner, value: value[RELEASE_NOTES_PROPERTY_MAP[language]] ||
                        value[RELEASE_NOTES_PROPERTY_MAP[DEFAULT_LANGUAGE]], actual: actualReleaseNote?.version === value.version })))))),
                (hasNextPage || isFetchingNextPage) && (React.createElement(DrawerContentBlock, null,
                    React.createElement("div", { ref: ref, className: "d-flex align-items-center justify-content-center" },
                        React.createElement(LoadingRectangles, null))))))));
};
export default ReleaseNoteHistory;
