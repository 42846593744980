import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { DrawerContent, DrawerFooter } from '../../../../../common/components/Drawer';
import FormikField from '../../../../../common/components/formik/FormikField';
import { useEntityDisplayData } from '../../../../common/components/withEntityDisplayData';
import { validatePartialEntityItem } from '../../../../common/utils/validator';
import PropertyFieldEditor from '../../item-property/PropertyFieldEditor';
import { convertToEntityItem } from './utils';
import { DrawerContentBlock } from '../../../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../../../common/components/Drawer/DrawerFormFooter';
import Toggle from '../../../../../common/components/Toggle';
import styles from './BulkEditForm.module.scss';
const BulkEditForm = ({ definitionId, onCancel, onSubmit, permissionFieldsConfig }) => {
    const { language, t } = useI18n();
    const { displayData } = useEntityDisplayData(definitionId);
    const propertiesWithSections = useMemo(() => {
        if (!displayData) {
            return undefined;
        }
        return displayData.data
            .map(({ sectionId, titles, values }) => {
            const filteredValues = values.filter((property) => {
                return ((property.canEdit || permissionFieldsConfig?.isCanEditField(property)) &&
                    !property.computed);
            });
            if (!filteredValues.length) {
                return undefined;
            }
            return {
                sectionId,
                title: getTranslation(titles, language),
                properties: filteredValues
            };
        })
            .filter((item) => item);
    }, [displayData, language, permissionFieldsConfig]);
    const _onSubmit = useCallback(async (values) => {
        if (displayData) {
            const entityItem = convertToEntityItem(values, displayData);
            return onSubmit && onSubmit(entityItem);
        }
    }, [onSubmit, displayData]);
    const initialValue = useMemo(() => {
        if (!propertiesWithSections) {
            return {};
        }
        return propertiesWithSections.reduce((acc, section) => {
            section?.properties.forEach((property) => {
                acc[property.propertyId] = {
                    enabled: false,
                    value: null
                };
            });
            return acc;
        }, {});
    }, [propertiesWithSections]);
    const validateForm = useCallback((values) => {
        if (!displayData) {
            return {};
        }
        const entityItem = convertToEntityItem(values, displayData);
        return validatePartialEntityItem(entityItem, displayData, t) || {};
    }, [displayData, t]);
    return (React.createElement(Formik, { validate: validateForm, initialValues: initialValue, onSubmit: _onSubmit, validateOnBlur: true, validateOnChange: false }, ({ handleSubmit, isSubmitting, values, errors }) => {
        return (React.createElement(React.Fragment, null,
            React.createElement(DrawerContent, { className: styles.bulkEditForm }, propertiesWithSections &&
                propertiesWithSections.map((section) => {
                    return (React.createElement(DrawerContentBlock, { key: section?.sectionId, className: "section", title: section?.title },
                        React.createElement("div", { className: "properties container d-flex flex-column row-gap-2" }, section?.properties.map((property) => {
                            return (React.createElement("div", { className: "row flex-nowrap overflow-hidden", key: property.propertyId },
                                React.createElement("div", { className: "col-auto p-0 pe-2 d-flex align-items-center switcher-col" },
                                    React.createElement(FormikField, { name: `${property.propertyId}.enabled`, component: Toggle })),
                                React.createElement("div", { className: "col p-0 flex-truncate" },
                                    React.createElement(FormikField, { name: `${property.propertyId}.value`, component: PropertyFieldEditor, property: property, disabled: !values[property.propertyId]?.enabled || isSubmitting, error: errors[property.propertyId] }))));
                        }))));
                })),
            React.createElement(DrawerFooter, null,
                React.createElement(DrawerFormFooter, { onSubmit: () => handleSubmit(), onCancel: onCancel, submitting: isSubmitting }))));
    }));
};
export default BulkEditForm;
