import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { INTEGRATION_ADMIN_PERMISSION, MANAGE_FAVOURITES_PERMISSION } from '@hypercharge/portal-utils';
import { head } from 'lodash';
import React, { useMemo, useState } from 'react';
import { FaChartBar } from 'react-icons/fa';
import { GoDatabase, GoDeviceDesktop } from 'react-icons/go';
import { IoIosPerson, IoIosSettings, IoMdGlobe, IoMdKey } from 'react-icons/io';
import { MdAccountBalance, MdApps, MdBusiness, MdPayment } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { hasTenantPermission } from '../../auth';
import { Breadcrumbs } from '../../cms/common/breadcrumbs';
import { useStickyTabs } from '../../common/components/StickyTabs/StickyTabsProvider';
import HeightContext, { HeightContextProvider } from '../../common/components/height-context';
import { CollapsibleContainer } from '../../common/components/side-menu';
import { APP_ADMIN_PERMISSION, PLATFORM_ADMIN_PERMISSION } from '../../common/utils/constants';
import { HOME_PATH } from '../../home';
import { APIKEY_KEY, APIKEY_PATH, APPS_KEY, APPS_PATH, CMS_KEY, CMS_PATH, DOMAINS_KEY, DOMAINS_PATH, GDPR_KEY, GDPR_PATH, INTEGRATIONS_KEY, INTEGRATIONS_PATH, LOGS_KEY, LOGS_PATH, PAYMENT_KEY, PAYMENT_PATH, STATISTICS_KEY, STATISTICS_PATH, TENANT_KEY, TENANT_PATH, XDMS_KEY, XDMS_PATH } from '../constants';
import styles from './Routes.module.scss';
import ApiKeysContainer from './apiKeys/ApiKeysContainer';
import Apps from './apps/Apps';
import CmsSettingsFormContainer from './cms/CmsSettingsFormContainer';
import DomainsSettingsFormContainer from './domains/DomainsSettingsFormContainer';
import GdprSettingsFormContainer from './gdpr/GdprSettingsFormContainer';
import Integrations from './integrations/Integrations';
import useLogs from './logs/LogsPage/useLogs';
import LogsRoutes from './logs/Routes';
import Usage from './statistics/Usage';
import SubscriptionsContainer from './subscriptions/SubscriptionsContainer';
import { PAYMENT_SUBSCRIPTION_PERMISSION } from './subscriptions/constants';
import TenantSettingsFormContainer from './tenant/TenantSettingsFormContainer';
import XdmsSettingsFormContainer from './xdms/XdmsSettingsFormContainer';
const keyComponentMap = {
    [TENANT_KEY]: TenantSettingsFormContainer,
    [GDPR_KEY]: GdprSettingsFormContainer,
    [APIKEY_KEY]: ApiKeysContainer,
    [XDMS_KEY]: XdmsSettingsFormContainer,
    [CMS_KEY]: CmsSettingsFormContainer,
    [DOMAINS_KEY]: DomainsSettingsFormContainer,
    [STATISTICS_KEY]: Usage,
    [LOGS_KEY]: LogsRoutes,
    [INTEGRATIONS_KEY]: Integrations,
    [APPS_KEY]: Apps,
    [PAYMENT_KEY]: SubscriptionsContainer
};
const otherElementSelectors = [
    '#header',
    '#featured-entities',
    '#breadcrumbs',
    '#side-menu-title',
    '#footer'
];
const otherHeightInPx = 16 /* padding */ + 1; /* rounding */
const SettingsRoutes = ({ match: { url } }) => {
    const { t } = useI18n();
    const { isLogsEnabled } = useLogs();
    const [links, setLinks] = useState([]);
    const { isActiveStickySection } = useStickyTabs();
    const [currentBreadcrumbText, setCurrentBreadcrumbText] = useState('');
    const { hasAdminPermission, hasPlatformAdminPermission, hasIntegrationsAdminPermission, hasPaymentAdminPermission, hasManageFavouritesPermission } = useSelector((s) => ({
        hasAdminPermission: hasTenantPermission(s, APP_ADMIN_PERMISSION),
        hasPlatformAdminPermission: hasTenantPermission(s, PLATFORM_ADMIN_PERMISSION),
        hasIntegrationsAdminPermission: hasTenantPermission(s, INTEGRATION_ADMIN_PERMISSION),
        hasPaymentAdminPermission: hasTenantPermission(s, PAYMENT_SUBSCRIPTION_PERMISSION),
        hasManageFavouritesPermission: hasTenantPermission(s, MANAGE_FAVOURITES_PERMISSION)
    }));
    const items = useMemo(() => {
        const items = [];
        if (hasAdminPermission) {
            items.push({
                key: TENANT_KEY,
                exact: false,
                to: `${url}${TENANT_PATH}`,
                title: t('TENANT_CONFIG'),
                icon: MdBusiness
            });
        }
        if (hasManageFavouritesPermission) {
            items.push({
                key: CMS_KEY,
                exact: false,
                to: `${url}${CMS_PATH}`,
                title: t('FAVOURITES_SETTINGS'),
                icon: GoDatabase
            });
        }
        if (hasAdminPermission) {
            items.push({
                key: STATISTICS_KEY,
                exact: false,
                to: `${url}${STATISTICS_PATH}`,
                title: t('STATISTICS'),
                icon: FaChartBar
            });
        }
        if (hasPlatformAdminPermission || (isLogsEnabled && hasAdminPermission)) {
            items.push({
                key: LOGS_KEY,
                exact: false,
                to: `${url}${LOGS_PATH}`,
                title: t('LOGS_TITLE'),
                icon: FaChartBar
            });
        }
        if (hasPlatformAdminPermission) {
            items.push({
                key: GDPR_KEY,
                exact: false,
                to: `${url}${GDPR_PATH}`,
                title: t('GDPR_PORTAL'),
                icon: IoIosPerson
            }, {
                key: DOMAINS_KEY,
                exact: false,
                to: `${url}${DOMAINS_PATH}`,
                title: t('DOMAINS'),
                icon: IoMdGlobe
            }, {
                key: XDMS_KEY,
                exact: false,
                to: `${url}${XDMS_PATH}`,
                title: 'XDMS',
                icon: MdAccountBalance
            }, {
                key: APIKEY_KEY,
                exact: false,
                to: `${url}${APIKEY_PATH}`,
                title: t('APIKEY'),
                icon: IoMdKey
            });
        }
        if (hasIntegrationsAdminPermission) {
            items.push({
                key: INTEGRATIONS_KEY,
                exact: false,
                to: `${url}${INTEGRATIONS_PATH}`,
                title: t('INTEGRATIONS'),
                icon: GoDeviceDesktop
            });
        }
        if (hasPaymentAdminPermission) {
            items.push({
                key: PAYMENT_KEY,
                exact: false,
                to: `${url}${PAYMENT_PATH}`,
                title: t('PAYMENT_DETAILS'),
                icon: MdPayment
            });
        }
        if (hasAdminPermission) {
            items.push({
                key: APPS_KEY,
                exact: false,
                to: `${url}${APPS_PATH}`,
                title: t('APPS'),
                icon: MdApps
            });
        }
        return items;
    }, [
        hasAdminPermission,
        hasManageFavouritesPermission,
        hasPlatformAdminPermission,
        isLogsEnabled,
        hasIntegrationsAdminPermission,
        hasPaymentAdminPermission,
        url,
        t
    ]);
    return (React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: "BreadcrumbsContainer", id: "breadcrumbs" },
            React.createElement(Breadcrumbs, { links: links, currentBreadcrumb: currentBreadcrumbText })),
        React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: otherHeightInPx },
            React.createElement(HeightContext.Consumer, null, ({ contentHeight }) => (React.createElement(CollapsibleContainer, { sectionName: "settings", title: t('SETTINGS'), headerIcon: IoIosSettings, items: items, isFailed: false, isLoading: false, containerClassName: styles.container, className: styles.outer },
                React.createElement(Switch, null,
                    items.map(({ key, to }) => {
                        const Component = keyComponentMap[key];
                        return (React.createElement(Route, { key: key, path: to, render: (props) => (React.createElement("div", { className: `${isActiveStickySection ? 'pb-5' : ''} ${styles.wrapper}` },
                                React.createElement(Component, { contentHeight: contentHeight, setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, ...props }))) }));
                    }),
                    React.createElement(Redirect, { to: head(items)?.to || HOME_PATH }))))))));
};
export default SettingsRoutes;
