import { Tabs } from 'antd';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import GeneralOptionsForm, { getGeneralFormSchema } from './components/GeneralOptionsForm';
export const initialValues = {
    enabled: true,
    configuration: {
        live: {
            hosts: []
        },
        test: {
            hosts: []
        },
        draft: {
            hosts: []
        },
        customHosts: [],
        snippet: '',
        snippetHead: '',
        authorizationKey: '',
        frontendSettings: {
            logoUrl: '',
            faviconUrl: '',
            issueLink: '',
            feedbackLink: '',
            requestFileLink: '',
            passQueryThruLink: false,
            preserveQueryParams: ['retailerId']
        },
        blocksSets: [],
        enabledModules: {
            hyperDealer: false,
            hyperCommerce: false,
            hyperRecruiter: false,
            volvoRetailerWebsite: false
        },
        defaultBlocks: {
            header: '',
            footer: ''
        },
        propertyConfiguration: {},
        robotsObject: {}
    }
};
var AvailableTabs;
(function (AvailableTabs) {
    AvailableTabs["GENERAL"] = "GENERAL";
})(AvailableTabs || (AvailableTabs = {}));
const isTabAvailable = (tabName) => !!AvailableTabs[tabName];
export const getFormSchema = () => {
    return Yup.object().shape({
        enabled: Yup.boolean().default(true),
        title: Yup.string().default('Unnamed'),
        configuration: Yup.object()
            .shape({ ...getGeneralFormSchema() })
            .required()
    });
};
const Form = ({ instance, setFieldValue, disabled }) => {
    const [activeTab, setActiveTab] = useState();
    const onChangeTab = useCallback((activeTab) => {
        if (isTabAvailable(activeTab)) {
            setActiveTab(activeTab);
        }
    }, []);
    return (React.createElement(Tabs, { activeKey: activeTab, onChange: onChangeTab, items: [
            {
                key: AvailableTabs.GENERAL,
                label: 'General',
                children: (React.createElement(GeneralOptionsForm, { configuration: instance.configuration, setFieldValue: setFieldValue, disabled: disabled }))
            }
        ] }));
};
export default Form;
