import { DiffEditor } from '@monaco-editor/react';
import React from 'react';
import { useBrowserBreakpoints } from '../../../../../../../common/components/with-browser-breakpoints';
const DiffViewer = ({ modified, original, height, language = 'json' }) => {
    const { isTablet } = useBrowserBreakpoints();
    const handleEditorDidMount = (editor, monaco) => {
        scrollToFirstChange(editor, monaco);
    };
    const scrollToFirstChange = (editor, monaco, attempts = 10) => {
        const changes = editor.getLineChanges();
        if (changes && changes.length > 0) {
            const firstChange = changes[0];
            editor.revealLineInCenterIfOutsideViewport(firstChange.modifiedStartLineNumber, monaco.editor.ScrollType.Smooth);
        }
        else if (attempts > 0) {
            setTimeout(() => scrollToFirstChange(editor, monaco, attempts - 1), 100);
        }
        else {
            console.warn('Changes not detected after multiple attempts.');
        }
    };
    return (React.createElement(DiffEditor, { height: height, language: language, original: original, modified: modified, onMount: handleEditorDidMount, options: {
            readOnly: true,
            renderSideBySide: !isTablet,
            scrollBeyondLastLine: false
        } }));
};
export default DiffViewer;
