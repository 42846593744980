import { get, keyBy, map, reduce } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import { getFlattenedDisplayDataList } from '../../../cms';
import SortableList from './SortableList';
const generateDisplayDataMap = (displayDataList) => keyBy(getFlattenedDisplayDataList(displayDataList), 'propertyId');
const SortablePropertyList = ({ disabled, displayData, selection, propertyIdPath, handleChange, itemComponent, disabledItems, prioritySort, className }) => {
    const displayDataMap = useMemo(() => generateDisplayDataMap(displayData), [displayData]);
    const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
        handleChange(arrayMove(selection, oldIndex, newIndex));
    }, [selection, handleChange]);
    const sectionTitleByPropertyId = useMemo(() => reduce(get(displayData, 'data', []), (res, { titles, values }) => {
        let newMap = res;
        if (titles) {
            map(values, 'propertyId').forEach((propertyId) => {
                newMap = { ...newMap, [propertyId]: titles };
            });
        }
        return newMap;
    }, {}), [displayData]);
    return (React.createElement(SortableList, { useDragHandle: true, lockAxis: "y", lockToContainerEdges: true, lockOffset: ['0%', '100%'], onSortEnd: onSortEnd, disabled: !!disabled, sectionTitleByPropertyId: sectionTitleByPropertyId, displayDataMap: displayDataMap, selection: selection, disabledItems: disabledItems, propertyIdPath: propertyIdPath, handleChange: handleChange, prioritySort: prioritySort, itemComponent: itemComponent, className: className }));
};
export default SortablePropertyList;
