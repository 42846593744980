import { compact, debounce } from 'lodash';
import { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchFilesData } from './actions';
import { getFileData, isFileDataAvailable } from './selectors';
const filesToFetch = new Set();
const fetchFromQueue = (fetchFilesData) => {
    fetchFilesData([...filesToFetch]);
    filesToFetch.clear();
};
const debouncedFetchFilesData = debounce(fetchFromQueue);
const addToQueueAndFetch = (fileId, fetchFilesData) => {
    if (fileId) {
        filesToFetch.add(fileId);
        debouncedFetchFilesData(fetchFilesData);
    }
};
function useFiles(value) {
    const dispatch = useDispatch();
    const files = useSelector((s) => {
        if (Array.isArray(value)) {
            return compact(value.map((id) => getFileData(s, id)));
        }
        return getFileData(s, value);
    }, shallowEqual);
    const isFileDataAvailableSelector = useSelector((s) => (fileId) => isFileDataAvailable(s, fileId));
    const fetchFilesDataDispatch = useCallback((ids) => {
        return dispatch(fetchFilesData(ids));
    }, [dispatch]);
    useEffect(() => {
        if (value) {
            const fileIds = Array.isArray(value) ? value : [value];
            for (const fileId of fileIds) {
                if (isFileDataAvailableSelector(fileId)) {
                    continue;
                }
                addToQueueAndFetch(fileId, fetchFilesDataDispatch);
            }
        }
    }, [fetchFilesDataDispatch, isFileDataAvailableSelector, value]);
    return files;
}
export default useFiles;
