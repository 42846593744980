import { Button } from 'antd';
import React from 'react';
import { CgChevronDown } from 'react-icons/cg';
import styles from './ArrowButton.module.scss';
const ArrowButton = ({ injectable, className = '', onClick, open }) => {
    if (injectable) {
        return (React.createElement("span", { role: "button", className: `${styles.wrapper} ${open ? 'open' : ''} ${className} ArrowButtonOuter`, onClick: onClick },
            React.createElement(CgChevronDown, null)));
    }
    return (React.createElement(Button, { className: `${styles.wrapper} ${open ? 'open' : ''} ${className} ArrowButtonOuter`, onClick: onClick },
        React.createElement(CgChevronDown, null)));
};
export default ArrowButton;
