import { omit } from 'lodash';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { CMS_API_PATH } from '../common/utils/constants';
import { invalidateDefinitionQuery } from './invalidateCache';
const createMetaDefinition = (definition) => ({
    type: 'ENTITY_META_DEFINITION__CREATE',
    payload: omit(definition, ['definitionId']),
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition`,
            method: 'POST',
            allowPromiseReject: true
        }
    }
});
const useCreateDefinition = () => {
    const createDefinitionDispatch = useDispatch();
    return useMutation({
        mutationFn: async (definition) => {
            return createDefinitionDispatch(createMetaDefinition(definition));
        },
        onSettled: async (result, _error, _definition) => {
            if (result) {
                invalidateDefinitionQuery(result.definitionId);
            }
        }
    });
};
export default useCreateDefinition;
