import { Carousel } from 'antd';
import React, { useRef } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import EarmarkFileIcon from '../../../storage/components/FileCarousel/EarmarkFileIcon';
import documentFormats, * as FILE_TYPE from '../../../storage/components/constants';
import { getImageUrl } from '../../../utils/common';
import { SortableFile } from '../../SortableFileWrapper';
import styles from './CarouselPreview.module.scss';
const MAX_HEIGHT_SLIDE = 80;
const MAX_WIDTH_IMAGE = 350;
const ItemPreview = ({ image, imageCover }) => {
    if ((image.type && documentFormats.includes(image.type)) ||
        image.type?.includes(FILE_TYPE.AUDIO) ||
        image.type?.includes(FILE_TYPE.VIDEO)) {
        return (React.createElement("div", { style: { height: MAX_HEIGHT_SLIDE } },
            React.createElement(EarmarkFileIcon, { className: "cursor-pointer", text: image.extension, sizeIcon: 30, sizePrefix: 7 })));
    }
    return (React.createElement("img", { className: `${styles.carouselImage} object-fit-cover w-100`, role: "button", src: getImageUrl(image?.id, {
            height: MAX_HEIGHT_SLIDE,
            width: MAX_WIDTH_IMAGE,
            fit: imageCover ? 'cover' : 'contain',
            background: 'transparent'
        }), alt: image.name }));
};
const CarouselPreview = ({ images, onImageClick, imageCover }) => {
    const carouselApiRef = useRef(null);
    return (React.createElement("div", { className: `${styles.carouselContainer} w-100 h-100 position-relative` },
        images.length > 1 && (React.createElement("div", { className: `${styles.carouselArrowContainer} d-flex justify-content-between` },
            React.createElement("div", { className: `${styles.carouselArrow} d-flex justify-content-center align-items-center`, role: "button", onClick: () => carouselApiRef.current?.prev() },
                React.createElement(MdKeyboardArrowLeft, { size: 24, color: "var(--grayscaleColor-1)" })),
            React.createElement("div", { className: `${styles.carouselArrow} d-flex justify-content-center align-items-center`, role: "button", onClick: () => carouselApiRef.current?.next() },
                React.createElement(MdKeyboardArrowRight, { size: 24, color: "var(--grayscaleColor-1)" })))),
        React.createElement(Carousel, { prefixCls: `${styles.antCarouselFix} ant-carousel`, ref: carouselApiRef }, images.map((image, index) => (React.createElement(SortableFile, { key: image.id, index: index, className: "sortable" },
            React.createElement("div", { key: image.id, onClick: (e) => {
                    e.stopPropagation();
                    onImageClick(index, image);
                } },
                React.createElement(ItemPreview, { image: image, imageCover: imageCover }))))))));
};
export default CarouselPreview;
