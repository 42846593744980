import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Switch, Tooltip } from 'antd';
import React from 'react';
import { Field } from 'redux-form';
import { FieldsContainer, FieldsContainerTitle } from '../../../utils/formUtils';
import { AntInputField } from '../../AntInput';
import FormikField from '../../formik/FormikField';
import ColorField from '../ColorField/ColorField';
import { GroupName, Label, StyledExternalLinkAlt, SwitchContainer, Text } from './styles';
const LIGHT_COLOR = '#ffffff';
const DARK_COLOR = '#5a5a5a';
const ColorCustomization = ({ submitting, formik = false }) => {
    const { t } = useI18n();
    const Component = formik ? FormikField : Field;
    const componentProps = formik ? { fast: true } : {};
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldsContainerTitle, null, t('META_ITEM_STYLE_CUSTOM_ICON')),
        React.createElement(FieldsContainer, { className: "cp-c-padding-2" },
            React.createElement(Label, null,
                React.createElement("span", { className: "me-1" }, t('META_ITEM_STYLE_ICON_LABEL')),
                React.createElement(Tooltip, { placement: "right", title: t('META_ITEM_STYLE_ICON_TOOLTIP') },
                    React.createElement("span", null,
                        React.createElement("a", { href: "https://fontawesome.com/v5/search?m=free&s=solid%2Cregular", target: "_blank", rel: "noreferrer" },
                            t('META_ITEM_STYLE_ICON_LABEL_LINK'),
                            React.createElement(StyledExternalLinkAlt, null))))),
            React.createElement("div", { className: "cp-c-row" },
                React.createElement(Component, { ...componentProps, className: "cp-i-100 cp-i-sm-33", "data-test-id": "input-color-customization-icon-name", name: "custom.style.icon.name", type: "text", placeholder: t('META_ITEM_STYLE_ICON_PLACEHOLDER'), component: AntInputField, disabled: submitting }))),
        React.createElement(FieldsContainer, { className: "cp-c-padding-2" },
            React.createElement(Label, null, t('META_ITEM_STYLE_ICON_BACKGROUND_LABEL')),
            React.createElement("div", { className: "cp-c-row" },
                React.createElement(Component, { ...componentProps, component: (fieldProps) => (React.createElement(ColorField, { resetColor: true, disabled: submitting, ...fieldProps })), name: "custom.style.icon.backgroundColor" }))),
        React.createElement(FieldsContainer, { className: "cp-c-padding-2" },
            React.createElement(Label, null, t('META_ITEM_STYLE_ICON_COLOR_LABEL')),
            React.createElement(GroupName, { className: "cp-i-100 cp-i-sm-flex cp-c-row cp-c-align-start-center" },
                React.createElement(Text, { className: "cp-i-flex" }, t('META_ITEM_STYLE_ICON_DARK_LABEL')),
                React.createElement(SwitchContainer, { className: "cp-i-20 cp-i-sm-flex cp-c-row cp-c-align-start-start cp-c-sm-align-start-center" },
                    React.createElement(Component, { component: ({ input, onChange }) => (React.createElement(Switch, { "data-test-id": "switch-color-customization-icon-color", checked: input.value === DARK_COLOR, onChange: (value) => input
                                ? input.onChange(value ? DARK_COLOR : LIGHT_COLOR)
                                : onChange(value ? DARK_COLOR : LIGHT_COLOR), disabled: submitting })), name: "custom.style.icon.color" }))))));
};
export default ColorCustomization;
