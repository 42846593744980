export const IMPORT_DEFINITION_ID = 'import';
export const IMPORT_TYPE_PROPERTY_ID = 'type';
export const ORIGINAL_DATA_PROPERTY_ID = 'originalData';
export const ORIGINAL_URL_PROPERTY_ID = 'originalUrl';
export const CONFIGURATION_PROPERTY_ID = 'configuration';
export const IMPORT_STARTED_PROPERTY_ID = 'importStarted';
export const unsupportedPropertyTypes = ['richtext', 'json'];
export const thousandSeparatorByNumberFormat = {
    'space-comma': ' ',
    'space-dot': ' ',
    'comma-dot': ',',
    'dot-comma': '.'
};
export const decimalSeparatorByNumberFormat = {
    'space-comma': ',',
    'space-dot': '.',
    'comma-dot': '.',
    'dot-comma': ','
};
export const numberFormatOptions = [
    { id: 'space-comma', display: '1 234 567,89' },
    { id: 'space-dot', display: '1 234 567.89' },
    { id: 'comma-dot', display: '1,234,567.89' },
    { id: 'dot-comma', display: '1.234.567,89' }
];
export const defaultRegExpFlags = ['g', 'i', 'm'];
