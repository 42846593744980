import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { isEmpty, isFunction } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ActionsButton from '../../../../common/components/ActionsButton';
import { BROWSE_PATH } from '../../../../common/utils/constants';
import { ReleaseNoteForm } from '../../../../releaseNotes/components/ReleaseNoteForm';
import { ALL_PROCESSES_DEFINITION_ID, PROCESSES_PATH, WORKFLOWS_PATH } from '../../../common/utils/constants';
import useDeleteProcessDefinition from '../../../hooks/useDeleteProcessDefinition';
import useProcessDefinition from '../../../hooks/useProcessDefinition';
import styles from './ProcessMetaActions.module.scss';
const ProcessMetaActions = ({ className, canDelete = true, canSave = true, save, extraActions = [], saving, disabled, definitionId, formIsValid }) => {
    const { t } = useI18n();
    const { mutateAsync: deleteProcessDefinition } = useDeleteProcessDefinition();
    const { definition } = useProcessDefinition({ definitionId });
    const [openReleaseNoteModal, setOpenReleaseNoteModal] = useState(false);
    const _save = useCallback(async () => {
        if (isFunction(formIsValid) ? isEmpty(await formIsValid()) : formIsValid) {
            if (definition?.custom?.requiredReleaseNotes) {
                setOpenReleaseNoteModal(true);
                return;
            }
        }
        await save();
    }, [definition?.custom?.requiredReleaseNotes, formIsValid, save]);
    const dispatch = useDispatch();
    const deleteAction = useCallback(async () => {
        try {
            await deleteProcessDefinition(definitionId);
            dispatch(push(`${WORKFLOWS_PATH}${PROCESSES_PATH}/${ALL_PROCESSES_DEFINITION_ID}${BROWSE_PATH}`));
            dispatch(success({
                title: t('SUCCESS'),
                message: t('WORKFLOWS__PROCESS_DELETE_SUCCESS')
            }));
        }
        catch (err) {
            dispatch(error({
                title: t('ERROR'),
                message: t('WORKFLOWS__PROCESS_DELETE_FAIL')
            }));
            throw t('WORKFLOWS__PROCESS_DELETE_FAIL');
        }
    }, [deleteProcessDefinition, definitionId, dispatch, t]);
    const menuActions = useMemo(() => {
        const menu = [];
        if (!definition?.custom?.requiredReleaseNotes) {
            menu.push({
                action: () => {
                    if (isFunction(formIsValid)) {
                        void formIsValid().then((errors) => {
                            if (isEmpty(errors)) {
                                setOpenReleaseNoteModal(true);
                            }
                            else {
                                void save();
                            }
                        });
                    }
                    else {
                        if (formIsValid) {
                            setOpenReleaseNoteModal(true);
                        }
                        else {
                            void save();
                        }
                    }
                },
                canRun: canSave,
                buttonLabel: 'FORM__SAVE_WITH_RELEASE_NOTES'
            });
        }
        menu.push({
            action: deleteAction,
            canRun: canDelete,
            buttonLabel: 'WORKFLOWS__PROCESS_DELETE',
            confirmationLabel: 'WORKFLOWS__PROCESS_DELETE',
            delete: true
        }, ...extraActions);
        return menu;
    }, [
        canDelete,
        canSave,
        definition?.custom?.requiredReleaseNotes,
        deleteAction,
        extraActions,
        formIsValid,
        save
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ActionsButton, { className: `${styles.wrapper} d-inline-block ${className || ''} saveButton`, buttonAction: {
                action: _save,
                canRun: canSave,
                buttonLabel: 'FORM__SAVE'
            }, menuActions: menuActions, submitting: saving, disabled: disabled, inversed: false }),
        React.createElement(ReleaseNoteForm, { saving: saving, onSubmit: save, definitionId: definitionId, open: openReleaseNoteModal, onClose: () => setOpenReleaseNoteModal(false) })));
};
export default ProcessMetaActions;
