import { ConditionType, FilterOperatorTypes, isConditionPassed, PropertyTypes } from '@hypercharge/portal-utils';
import { find, flatten, pick } from 'lodash';
import React, { useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { ENTITY_ID_PROPERTY_ID, getFlattenedDisplayDataList, TITLE_PROPERTY_ID } from '~app/cms';
import { useEntityDisplayData } from '~app/cms/common/components/withEntityDisplayData';
import { KanbanCard } from '~app/cms/data/components/items/Kanban/KanbanCard';
import { KanbanList } from '~app/cms/data/components/items/Kanban/KanbanList';
import { getKanbanColumnCountQueryKey, getKanbanColumnQueryKey } from '~app/cms/data/components/items/utils';
import useDisplayItemMeta from '~app/cms/hooks/useDisplayItemMeta';
import { useInfiniteSearchItems, useSearchItems } from '~app/cms/hooks/useSearchItems';
import { removeFilterById, replaceConditionFromContextDataInFilters } from '~app/common/components/ConditionQuery/utils';
import { fiveMinuteInMs } from '~app/common/utils/react-query-client';
import { getSystemFieldsForKanban, KANBAN_KEY } from '~app/views';
import { useView } from '~app/views/components/ViewContext';
import { TASK_PROCESS_LINK_PROPERTY_ID } from '~app/workflows/common/utils/constants';
const getKanbanColumnInitialData = () => ({
    pages: [{ results: [], totalCount: 0 }],
    pageParams: []
});
const KanbanListWrapper = ({ baseUrl, columnId, contentHeight, definitionId, disabledColumns, fields, getCustomColumnCountFilters, getCustomRouteHandleCardClick, hiddenColumns, isDropDisabled, onToggleList, title, viewId }) => {
    const { filterRequestForSearch, kanban } = useView();
    const { displayData } = useEntityDisplayData(definitionId);
    const { data: displayItemMeta } = useDisplayItemMeta({
        definitionId
    });
    const systemFields = useMemo(() => getSystemFieldsForKanban(definitionId, displayItemMeta), [definitionId, displayItemMeta]);
    const displayFields = useMemo(() => fields.filter((field) => !systemFields.includes(field.id)), [fields, systemFields]);
    const isColumnHidden = useMemo(() => hiddenColumns.has(columnId), [hiddenColumns, columnId]);
    const groupByEntityProperty = useMemo(() => {
        const propertyGroupBy = find(getFlattenedDisplayDataList(displayData), {
            propertyId: kanban?.groupBy
        });
        return propertyGroupBy?.type === PropertyTypes.entity;
    }, [displayData, kanban?.groupBy]);
    const countFilterRequestQuery = useMemo(() => {
        if (kanban?.groupBy) {
            const field = groupByEntityProperty ? `${kanban.groupBy}.entityId` : kanban.groupBy;
            const emptyOrIsFilter = columnId === FilterOperatorTypes.empty
                ? {
                    field,
                    operator: FilterOperatorTypes.empty
                }
                : {
                    field,
                    operator: FilterOperatorTypes.is,
                    data: columnId
                };
            return {
                condition: ConditionType.and,
                filters: [
                    removeFilterById(filterRequestForSearch.query, KANBAN_KEY),
                    {
                        condition: ConditionType.and,
                        filters: getCustomColumnCountFilters?.(columnId) || [emptyOrIsFilter]
                    }
                ]
            };
        }
        return filterRequestForSearch.query;
    }, [
        filterRequestForSearch.query,
        getCustomColumnCountFilters,
        groupByEntityProperty,
        kanban?.groupBy,
        columnId
    ]);
    const filterRequestTotalCount = useMemo(() => ({
        query: countFilterRequestQuery,
        context: filterRequestForSearch.context,
        limit: 0,
        offset: 0,
        responseFields: [],
        fullTextFields: filterRequestForSearch.fullTextFields,
        fullText: filterRequestForSearch.fullText,
        sortBy: []
    }), [
        countFilterRequestQuery,
        filterRequestForSearch.context,
        filterRequestForSearch.fullText,
        filterRequestForSearch.fullTextFields
    ]);
    const { data: { totalCount: totalCountColumn = 0 } = {}, isFetching: columnCountIsFetching } = useSearchItems({
        definitionId,
        filterRequest: filterRequestTotalCount
    }, {
        queryKey: getKanbanColumnCountQueryKey(columnId),
        staleTime: fiveMinuteInMs
    });
    const filterRequestOpenInNewTab = useMemo(() => ({
        ...filterRequestTotalCount,
        query: replaceConditionFromContextDataInFilters(filterRequestTotalCount.query, filterRequestTotalCount.context)
    }), [filterRequestTotalCount]);
    const { data: columnCards, isLoading: isLoadingColumnCards, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteSearchItems({
        definitionId,
        filterRequest: {
            ...filterRequestForSearch,
            query: countFilterRequestQuery,
            limit: 10
        }
    }, {
        enabled: !isColumnHidden && !!totalCountColumn,
        queryKey: getKanbanColumnQueryKey(columnId),
        staleTime: fiveMinuteInMs,
        initialData: getKanbanColumnInitialData(),
        initialDataUpdatedAt: Date.now() - fiveMinuteInMs
    });
    const cards = useMemo(() => flatten(columnCards?.pages.map((page) => page?.results || [])).map((card) => ({
        ...(card[TASK_PROCESS_LINK_PROPERTY_ID]
            ? { [TASK_PROCESS_LINK_PROPERTY_ID]: card[TASK_PROCESS_LINK_PROPERTY_ID] }
            : {}),
        [ENTITY_ID_PROPERTY_ID]: card[ENTITY_ID_PROPERTY_ID],
        [TITLE_PROPERTY_ID]: card[TITLE_PROPERTY_ID],
        definitionId: card.definitionId,
        ...pick(card, [...fields.map(({ id }) => id)])
    })), [columnCards?.pages, fields]);
    const theme = useMemo(() => {
        const conditionalFormattingGroupBy = getFlattenedDisplayDataList(displayData).find((dd) => dd.propertyId === kanban?.groupBy)?.meta.conditionalFormatting;
        return conditionalFormattingGroupBy?.find((conditional) => cards.every((item) => isConditionPassed(item, conditional.filter)))?.theme;
    }, [cards, displayData, kanban?.groupBy]);
    return (React.createElement(Droppable, { key: columnId, droppableId: columnId, mode: "virtual", isDropDisabled: isDropDisabled || disabledColumns.has(columnId), renderClone: (provided, _, rubric) => (React.createElement(KanbanCard, { baseUrl: baseUrl, card: cards[rubric.source.index], definitionId: definitionId, fields: displayFields, provided: provided, viewId: viewId, cards: cards, totalCountColumn: totalCountColumn, getCustomRouteHandleCardClick: getCustomRouteHandleCardClick })) }, ({ innerRef }) => (React.createElement(KanbanList, { disabled: disabledColumns.has(columnId), isFetchingPage: isLoadingColumnCards || isFetchingNextPage, columnCountIsFetching: columnCountIsFetching, fetchNextPage: fetchNextPage, hasNextPage: hasNextPage, getCustomRouteHandleCardClick: getCustomRouteHandleCardClick, baseUrl: baseUrl, cards: cards, contentHeight: contentHeight, definitionId: definitionId, fields: displayFields, isDropDisabled: isDropDisabled, hidden: hiddenColumns.has(columnId), columnId: columnId, innerRef: innerRef, onToggleList: onToggleList, title: title, theme: theme, viewId: viewId, totalCountColumn: totalCountColumn, filterRequestOpenInNewTab: filterRequestOpenInNewTab }))));
};
export default KanbanListWrapper;
