import { DeleteConfirmationButton } from '@hypercharge/hyper-react-base/lib/common/table';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useMemo, useState } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import { PropertiesProvider } from '../../../../cms/common/context/PropertiesProvider';
import { getItemId } from '../../../../cms/settings/components/entity/HighlightedItems/utils';
import SortableList from '../../../../cms/settings/components/entity/SortableEntities/SortableList';
import { ConditionQueryField } from '../../../../common/components/ConditionQuery';
import { Drawer, DrawerContent, DrawerFooter } from '../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../../common/components/Drawer/DrawerFormFooter';
import FormikField from '../../../../common/components/formik/FormikField';
import ToggleDrawerButton from '../../../../groups/components/ToggleDrawerButton';
const QuickStartField = ({ input, dataById, input: { value } }) => {
    const [selectedGroupId, setSelectedGroupId] = useState();
    const { t } = useI18n();
    const closeRowBasedPermissionsDrawer = useCallback(() => {
        setSelectedGroupId(undefined);
    }, []);
    const entityIndexById = useMemo(() => {
        return value.findIndex(({ definitionId }) => definitionId === selectedGroupId);
    }, [selectedGroupId, value]);
    const ActionsSortableItem = useCallback(({ item, itemId }) => {
        const onRemove = (id) => input.onChange(input.value.filter((item) => getItemId(item) !== id));
        return (React.createElement(React.Fragment, null,
            React.createElement(ToggleDrawerButton, { toggleIconSize: 20, className: "toggle-btn me-1", active: true, onClick: () => {
                    setSelectedGroupId(itemId);
                } }),
            React.createElement(DeleteConfirmationButton, { deleteIconSize: 18, onClick: () => onRemove(getItemId(item)) })));
    }, [input]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PropertiesProvider, null,
            React.createElement(Drawer, { title: t('ENTITY_SETTINGS_LABEL'), onClose: closeRowBasedPermissionsDrawer, open: !!selectedGroupId }, !!selectedGroupId && (React.createElement(React.Fragment, null,
                React.createElement(DrawerContent, null,
                    React.createElement(DrawerContentBlock, null,
                        React.createElement(FormikField, { name: `custom.processRunFromEntities[${entityIndexById}].conditions`, component: ConditionQueryField, definitionId: selectedGroupId }))),
                React.createElement(DrawerFooter, null,
                    React.createElement(DrawerFormFooter, { submitText: t('GROUP_PERMISSIONS_VIEW_SUBMIT'), onSubmit: closeRowBasedPermissionsDrawer })))))),
        React.createElement(SortableList, { onSortEnd: ({ oldIndex, newIndex }) => input.onChange(arrayMove(input.value, oldIndex, newIndex)), useDragHandle: true, selectedItems: input.value, dataById: dataById, noItemsLabel: t('ENTITIES_NO_SELECTED_LABEL'), actionsSortableItemComponent: ActionsSortableItem, getItemId: getItemId })));
};
export default QuickStartField;
