export function isAbsoluteRangeValue(value) {
    if ('time' in value && Array.isArray(value.time)) {
        return true;
    }
    return false;
}
export function isRelativeRangeValue(value) {
    if ('offset' in value && typeof value.offset === 'string') {
        return true;
    }
    return false;
}
