import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { success } from '@hypercharge/hyper-react-base/lib/notifications';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { Modal } from 'antd';
import { DEFAULT_LANGUAGE } from 'config';
import { Formik } from 'formik';
import { capitalize, isEmpty } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { RichTextEditorWithWrapper } from '../../../cms/data/components/item-property/editors/RichTextEditor';
import useCreateItem from '../../../cms/hooks/useCreateItem';
import useDefinition from '../../../cms/hooks/useDefinition';
import useUpdateItem from '../../../cms/hooks/useUpdateItem';
import { invalidateDefinitionHistoryQuery } from '../../../cms/settings/components/entity/History/hooks/useDefinitionHistory';
import { MultiLanguageInput } from '../../../common/components/MultiLanguageField';
import FormikField from '../../../common/components/formik/FormikField';
import { CMS_ITEM_META_HANDLER } from '../../../common/utils/constants';
import { RELEASE_NOTES_DEFINITION_ID } from '../../constants';
const ReleaseNoteForm = ({ initialValues, onSubmit, open, saving, onClose, releaseNoteEntityId, existedVersion, definitionId }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const { definition } = useDefinition({ definitionId });
    const { mutateAsync: createReleaseNote } = useCreateItem({
        definitionId: RELEASE_NOTES_DEFINITION_ID
    });
    const { mutateAsync: updateReleaseNote } = useUpdateItem({
        definitionId: RELEASE_NOTES_DEFINITION_ID
    });
    const onFormSubmit = async (values, formikHelpers) => {
        const baseProperties = {
            title: values.releaseNoteMap[DEFAULT_LANGUAGE].ops
                .reduce((text, op) => {
                if (!op.insert) {
                    throw new Error('only `insert` operations can be transformed!');
                }
                if (typeof op.insert !== 'string') {
                    return text + ' ';
                }
                return text + op.insert;
            }, '')
                .replace(/\n/g, ' ')
                .substring(0, 60),
            ...Object.entries(values.releaseNoteMap).reduce((acc, [key, releaseNote]) => ({
                ...acc,
                [`notes${capitalize(key)}`]: releaseNote
            }), {})
        };
        if (onSubmit) {
            const savedDefinition = await onSubmit();
            await createReleaseNote({
                entityId: generateId(),
                referenceDefinitionId: definitionId,
                version: savedDefinition.modifiedAt,
                ...baseProperties
            });
            await invalidateDefinitionHistoryQuery(definitionId);
        }
        else if (releaseNoteEntityId) {
            await updateReleaseNote({
                entityId: releaseNoteEntityId,
                ...baseProperties
            });
            await invalidateDefinitionHistoryQuery(definitionId);
        }
        else if (existedVersion) {
            await createReleaseNote({
                entityId: generateId(),
                referenceDefinitionId: definitionId,
                version: existedVersion,
                ...baseProperties
            });
            await invalidateDefinitionHistoryQuery(definitionId);
        }
        else {
            throw new Error('No release notes entity id or existed version provided');
        }
        onClose();
        formikHelpers.resetForm();
        dispatch(success({
            title: t('SUCCESS'),
            message: t('RELEASE_NOTES_SAVED')
        }));
    };
    if (!definition) {
        return null;
    }
    return (React.createElement(Formik, { initialValues: initialValues || {
            releaseNoteMap: {}
        }, validate: (values) => {
            if (isEmpty(values.releaseNoteMap[DEFAULT_LANGUAGE]?.ops)) {
                return {
                    releaseNoteMap: {
                        [DEFAULT_LANGUAGE]: t('RELEASE_NOTE_IS_REQUIRED_ERROR_MESSAGE')
                    }
                };
            }
        }, onSubmit: onFormSubmit }, ({ handleSubmit, isValid, isSubmitting, touched }) => {
        return (React.createElement(Modal, { open: open, centered: true, onCancel: onClose, destroyOnClose: true, closable: false, okText: t('FORM__SAVE'), cancelText: t('COMMON_CANCEL'), title: definition.handler !== CMS_ITEM_META_HANDLER
                ? t('PROCESS_UPDATE')
                : t('ENTITY_UPDATE'), width: 600, onOk: () => handleSubmit(), okButtonProps: {
                loading: saving || isSubmitting,
                disabled: !isValid && isEmpty(touched)
            } },
            React.createElement(FormikField, { name: "releaseNoteMap", label: t('RELEASE_NOTE_FORM__DESCRIPTION'), component: MultiLanguageInput, fieldComponent: RichTextEditorWithWrapper, autoFocus: true, extraFieldComponentProps: {
                    label: t('RELEASE_NOTE_FORM__DESCRIPTION')
                } })));
    }));
};
export default ReleaseNoteForm;
