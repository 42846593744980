import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { Prompt } from 'react-router';
import { Option } from '../../../../../../common/components/Select';
import FormikField from '../../../../../../common/components/formik/FormikField';
import TabCloseConfirmation from '../../../../../../common/components/tab-close-confirmation/TabCloseConfirmation';
import { getPropertiesFromDefinition } from '../../../../../hooks/useDefinition';
import EntityActions from '../../entity-actions/EntityActions';
import styles from '../HighlightedItems.module.scss';
import HighlightedListField from '../components/HighlightedListField';
import HighlightedSelectField from '../components/HighlightedSelectField';
const mapDefinitionToFormValues = (definition) => {
    return {
        custom: {
            highlightedProperties: definition.custom?.highlightedProperties || []
        }
    };
};
const HighlightedPropertiesForm = ({ definition, extraActionButtons, isSystem, onSubmit }) => {
    const { t, language } = useI18n();
    const initialValues = useMemo(() => {
        return mapDefinitionToFormValues(definition);
    }, [definition]);
    const { properties, propertiesMap } = useMemo(() => {
        return getPropertiesFromDefinition(definition);
    }, [definition]);
    const handleSubmit = useCallback(async (values, actions) => {
        // mark property highlighted
        let newDefinition = {};
        newDefinition = {
            ...newDefinition,
            custom: {
                ...definition.custom,
                highlightedProperties: values.custom?.highlightedProperties
            },
            sections: definition.sections.map((section) => ({
                ...section,
                properties: section.properties.map((property) => {
                    const { propertyId } = property;
                    const found = values?.custom?.highlightedProperties?.find((highlighted) => highlighted.propertyId === propertyId);
                    return {
                        ...property,
                        meta: {
                            ...property.meta,
                            highlighted: !!found
                        }
                    };
                })
            }))
        };
        const savedDefinition = await onSubmit(newDefinition);
        // Reset the form after successful submission
        actions.resetForm({ values: mapDefinitionToFormValues(savedDefinition) });
        return savedDefinition;
    }, [definition, onSubmit]);
    return (React.createElement(Formik, { initialValues: initialValues, onSubmit: handleSubmit, enableReinitialize: true }, ({ dirty, isSubmitting, resetForm, setFieldValue, submitForm, values }) => (React.createElement("div", { className: `${styles.container} py-4 px-3` },
        React.createElement("div", { className: "form-buttons-container" },
            dirty && (React.createElement(Button, { inversed: true, onClick: () => resetForm(), disabled: isSubmitting }, t('FORM__RESET'))),
            React.createElement(EntityActions, { definitionId: definition.definitionId, save: submitForm, saving: isSubmitting, canSave: dirty, canDelete: !isSystem, formIsValid: true }),
            extraActionButtons),
        React.createElement("form", { noValidate: true },
            React.createElement("div", null,
                React.createElement("div", { className: "fw-500 mb-2" }, t('HIGHLIGHTED_PROPERTIES_SETTINGS_SELECT_LABEL')),
                React.createElement(FormikField, { name: "custom.highlightedProperties", component: HighlightedSelectField, data: properties, getValue: (value) => value.map(({ propertyId }) => propertyId), onSelectHandler: (selected) => {
                        void setFieldValue('custom.highlightedProperties', selected.map((propertyId) => {
                            const value = values?.custom?.highlightedProperties || [];
                            const existed = value.find((item) => item.propertyId === propertyId);
                            return {
                                propertyId,
                                active: existed ? existed.active : true
                            };
                        }));
                    }, getOptions: (data) => data
                        .filter(({ meta: { hidden } }) => !hidden)
                        .map(({ propertyId, labels }) => {
                        const label = getTranslation(labels, language);
                        return (React.createElement(Option, { key: propertyId, value: propertyId, disabled: false, label: label }, label));
                    }) }),
                React.createElement("div", { className: "fw-500 mb-2 mt-4" }, t('HIGHLIGHTED_PROPERTIES_SETTINGS_LIST_LABEL')),
                React.createElement(FormikField, { name: "custom.highlightedProperties", component: HighlightedListField, dataById: propertiesMap }))),
        React.createElement(Prompt, { when: dirty && !isSubmitting, message: t('UNSAVED_DATA_CONFIRM') }),
        React.createElement(TabCloseConfirmation, { dirty: dirty })))));
};
export default HighlightedPropertiesForm;
