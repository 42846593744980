import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Collapse, Tooltip } from 'antd';
import cn from 'classnames';
import { isEmpty, isString } from 'lodash';
import React, { useMemo } from 'react';
import { FaAngleRight, FaCodeBranch, FaEllipsisH, FaPlus, FaTable } from 'react-icons/fa';
import { IoMdCloud } from 'react-icons/io';
import { MdChevronLeft, MdOutlineStar } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFeaturedDefinitionIdsOneLevel } from '../../../cms/data/utils';
import { getConfig } from '../../../configs';
import { CMS_KEY, SETTINGS_KEY } from '../../../settings';
import { getConfigsState } from '../../reducers/rootSelector';
import { LoadingOverlay } from '../../utils/stylingUtils';
import HeightContext, { HeightContextProvider } from '../height-context';
import CollapsibleSearchInput from './CollapsibleSearchInput';
import styles from './DesktopSideMenu.module.scss';
import DesktopSideMenuItem from './DesktopSideMenuItem';
const otherElementSelectors = [
    '#header',
    '#featured-entities',
    '#breadcrumbs',
    '#side-menu-title',
    '#collapsible-search-input'
];
const otherHeightInPx = 12; /* sidebar bottom padding */
const iconsBySection = {
    'All processes': React.createElement(FaCodeBranch, { className: "collapse-panel-heading-icon" }),
    CRM: React.createElement(IoMdCloud, { className: "collapse-panel-heading-icon" }),
    Featured: React.createElement(MdOutlineStar, { className: "collapse-panel-heading-icon" }),
    Views: React.createElement(FaTable, { className: "collapse-panel-heading-icon" }),
    Other: React.createElement(FaEllipsisH, { className: "collapse-panel-heading-icon" })
};
const DesktopSideMenu = ({ withSearch, handleSearchChange, items, search, className, isLoading, title, headerIcon: HeaderIcon, createPath, openedSections, updateOpenedSections, itemsBySection, sectionTitles, withFeaturedEntitiesSwitch, collapsed = false, onCollapseChange, handleFeaturedChange }) => {
    const { t } = useI18n();
    const configsState = useSelector((s) => getConfigsState(s));
    const cmsConfig = getConfig(configsState, SETTINGS_KEY, CMS_KEY);
    const featuredDefinitionIds = useMemo(() => cmsConfig?.data?.featuredDefinitionIds || [], [cmsConfig]);
    const contentJsx = useMemo(() => {
        if (!items.length) {
            return isLoading ? (React.createElement(LoadingOverlay, null,
                React.createElement(LoadingRectangles, null))) : (!collapsed && (React.createElement("div", { className: "result-notification" },
                React.createElement("div", { className: "d-flex justify-content-center fw-500 text-center" }, t('NO_RESULTS_WERE_FOUND')))));
        }
        if (search !== '' || isEmpty(sectionTitles)) {
            return items.map((item) => (React.createElement(DesktopSideMenuItem, { withFeaturedEntitiesSwitch: withFeaturedEntitiesSwitch, isFeatured: !collapsed
                    ? getFeaturedDefinitionIdsOneLevel(featuredDefinitionIds).includes(item.key)
                    : undefined, item: item, key: item.key, tooltip: collapsed && isString(item.title) ? item.title : undefined, handleFeaturedChange: handleFeaturedChange })));
        }
        return (React.createElement(Collapse, { className: "DesktopSideMenu__collapse", activeKey: openedSections, onChange: updateOpenedSections, bordered: false, expandIconPosition: "start", expandIcon: FaAngleRight, items: sectionTitles.map((sectionTitle) => ({
                key: sectionTitle,
                label: collapsed ? (React.createElement(Tooltip, { title: sectionTitle, placement: "right" }, iconsBySection[sectionTitle])) : (sectionTitle),
                children: itemsBySection[sectionTitle].map((item) => (React.createElement(DesktopSideMenuItem, { withFeaturedEntitiesSwitch: withFeaturedEntitiesSwitch, isFeatured: !collapsed
                        ? getFeaturedDefinitionIdsOneLevel(featuredDefinitionIds).includes(item.key)
                        : undefined, item: item, key: item.key, tooltip: collapsed && isString(item.title) ? item.title : undefined, handleFeaturedChange: handleFeaturedChange })))
            })) }));
    }, [
        items,
        search,
        sectionTitles,
        openedSections,
        updateOpenedSections,
        isLoading,
        collapsed,
        t,
        withFeaturedEntitiesSwitch,
        handleFeaturedChange,
        itemsBySection,
        featuredDefinitionIds
    ]);
    const onCollapseToggle = () => {
        if (onCollapseChange) {
            onCollapseChange(!collapsed);
        }
    };
    return (React.createElement("div", { className: cn(styles.container, className, {
            'DesktopSideMenu--collapsed pe-2': collapsed,
            'DesktopSideMenu pe-3': !collapsed
        }) },
        React.createElement("div", { id: "side-menu-title", className: "DesktopSideMenu__title-container" },
            React.createElement(Button, { className: cn('DesktopSideMenu__collapsable-button', { 'm-0': collapsed }), onClick: onCollapseToggle },
                React.createElement(MdChevronLeft, { size: 16 })),
            !collapsed && React.createElement("div", { className: "DesktopSideMenu__title" }, title),
            createPath && !!HeaderIcon && (React.createElement(Link, { to: createPath, className: cn({ 'ms-auto': !collapsed }) },
                React.createElement(Tooltip, { placement: "top", title: t('ADD_DEFINITION_SUBTITLE') },
                    React.createElement(Button, { className: cn('DesktopSideMenu__add', {
                            DesktopSideMenu__addCollapsed: collapsed
                        }) },
                        React.createElement(FaPlus, null),
                        !collapsed && t('NEW')))))),
        withSearch && (React.createElement(CollapsibleSearchInput, { onChange: handleSearchChange, value: search, onCollapseToggle: onCollapseToggle, collapsed: collapsed })),
        React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: otherHeightInPx },
            React.createElement(HeightContext.Consumer, null, ({ contentHeight }) => (React.createElement("div", { className: cn('custom-scroll-styling position-relative overflow-auto', {
                    'pt-2': collapsed
                }), style: {
                    height: contentHeight
                } }, contentJsx))))));
};
export default DesktopSideMenu;
