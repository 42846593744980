import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { FieldError, FieldWrapper } from '../../FieldElements';
import styles from './MultiTextEditor.module.scss';
const preventEvent = (e) => {
    e.stopPropagation();
};
const MultiTextEditor = ({ className, onChange = () => null, value, disabled, placeholder, editing = true, errorMessage, error }) => {
    const { t } = useI18n();
    const refInput = useRef(null);
    useEffect(() => {
        const handler = (e) => {
            const inputRef = refInput.current?.inputRef?.current;
            if (!inputRef) {
                return;
            }
            const outer = inputRef.closest('.FieldWrapperOuterSimple') || inputRef.closest('.FieldWrapperOuter');
            if (outer && e.target && outer !== e.target && !outer.contains(e.target)) {
                refInput.current?.onInputKeyDown({
                    keyCode: 13,
                    preventDefault: () => undefined
                });
            }
        };
        document.body.addEventListener('click', handler);
        refInput.current?.inputRef?.current?.focus();
        return () => {
            document.body.removeEventListener('click', handler);
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldWrapper, { className: classNames(styles.editor, className), disabled: disabled, editing: editing, onClick: preventEvent, error: !!errorMessage || !!error?.length },
            React.createElement(ReactTagInput, { ref: refInput, tags: value || [], placeholder: placeholder || t('ADD_MULTIPLE_TEXT_VALUES_PLACEHOLDER'), editable: true, readOnly: disabled, removeOnBackspace: true, onChange: onChange })),
        React.createElement(FieldError, { error: errorMessage ?? error })));
};
export default MultiTextEditor;
