import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useMemo } from 'react';
import { DataListContainer } from '../../../../../cms';
import { TaskStatus } from '../../../../common/utils/types';
import AvailableTasks from '../AvailableTasks';
import { useRequiredFields } from '../RequiredFieldsProvider';
import { usePermissionFieldsConfig } from '../usePermissionFieldsConfig';
const ProcessMainContent = ({ tasks, processDetails, assignTask, completeTask, onChangeTaskPriority, onChangeTaskDeadline, dispatchWarning, isProcessPending, processMetaId, blacklistedPropertyIds, canEditData, processRunId, contactId }) => {
    const { t } = useI18n();
    const { requiredFieldsConfig, availableTasks, myAssignedTasks } = useRequiredFields();
    const permissionFieldsConfig = usePermissionFieldsConfig(myAssignedTasks);
    const notAvailableTasks = useMemo(() => {
        if (availableTasks.length) {
            return [];
        }
        return tasks.filter((task) => [TaskStatus.WAITING, TaskStatus.RUNNING].includes(task.status) && !task.canExecute);
    }, [availableTasks.length, tasks]);
    const type = !availableTasks.length && notAvailableTasks.length ? 'warning' : undefined;
    return (React.createElement("div", { className: "overflow-hidden w-100" },
        React.createElement(AvailableTasks, { title: t(type === 'warning' ? 'ONGOING_TASKS' : 'YOUR_TASKS'), type: type, isProcessPending: isProcessPending, tasks: availableTasks.length ? availableTasks : notAvailableTasks, processStatus: processDetails?.status, completeTask: completeTask, assignTask: assignTask, dispatchWarning: dispatchWarning, canEdit: canEditData, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, contactId: contactId, onChangePriority: onChangeTaskPriority, onChangeDeadline: onChangeTaskDeadline }),
        processMetaId && (React.createElement(DataListContainer, { definitionId: processMetaId, entityId: processRunId, blacklistedPropertyIds: blacklistedPropertyIds, canEdit: canEditData, requiredFieldsConfig: requiredFieldsConfig, showRequiredFieldsSwitcher: false, doubleCol: true, className: "process-container" }))));
};
export default ProcessMainContent;
