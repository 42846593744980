import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Radio } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { ViewTypes } from '../../types';
export const ToggleView = ({ className = '', value, onChange }) => {
    const { t } = useI18n();
    const buttons = useMemo(() => [
        {
            id: ViewTypes.table,
            text: t('VIEW__TABLE')
        },
        {
            id: ViewTypes.kanban,
            text: t('VIEW__KANBAN')
        }
    ], [t]);
    const changeView = useCallback((e) => onChange(e.target.value), [onChange]);
    return (React.createElement(Radio.Group, { className: `${className} d-flex`, size: "large", onChange: changeView, value: value, buttonStyle: "solid" }, buttons.map(({ id, text }) => (React.createElement(Radio.Button, { className: "flex-grow-1 text-center px-5", key: id, value: id }, text)))));
};
