import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { groupBy, identity, isEmpty, isString, keys, max, sortBy, union, values, without } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFeaturedDefinitionIdsOneLevel } from '../../../cms/data/utils';
import { fetchConfig, getConfig, saveConfig } from '../../../configs';
import { CMS_KEY, SETTINGS_KEY } from '../../../settings';
import { getConfigsState } from '../../reducers/rootSelector';
import { addToLocalStorage, getFromLocalStorage } from '../../utils/localStorageUtils';
import { useBrowserBreakpoints } from '../with-browser-breakpoints';
import DesktopSideMenu from './DesktopSideMenu';
import MobileSideMenu from './MobileSideMenu';
import { deepFilter } from './utils';
const SideMenu = ({ isFailed, items, sectionSortOrderIndexMap = {}, withSearch, isDesktopMenuCollapsed, onDesktopMenuCollapseChange, ...otherProps }) => {
    const { t } = useI18n();
    const { isDesktop } = useBrowserBreakpoints();
    const dispatchSaveConfig = useDispatch();
    const dispatchFetchConfig = useDispatch();
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const handleSearchChange = useCallback((newSearch) => {
        setSearch(newSearch);
    }, [setSearch]);
    const configsState = useSelector((s) => getConfigsState(s));
    const cmsConfig = getConfig(configsState, SETTINGS_KEY, CMS_KEY);
    const featuredDefinitionIds = useMemo(() => getFeaturedDefinitionIdsOneLevel(cmsConfig?.data?.featuredDefinitionIds || []), [cmsConfig]);
    const openedSectionsStorageKey = useMemo(() => `side-menu:opened-sections:${otherProps.title}`, [otherProps.title]);
    const [openedSections, setOpenedSections] = useState(() => getFromLocalStorage(openedSectionsStorageKey, []));
    const updateAndPersistOpenedSections = useCallback((newOpenedSections) => {
        setOpenedSections(newOpenedSections);
        addToLocalStorage(openedSectionsStorageKey, newOpenedSections);
    }, [openedSectionsStorageKey]);
    const maxSectionSortOrderIndex = useMemo(() => max(values(sectionSortOrderIndexMap)) || 0, [sectionSortOrderIndexMap]);
    const [itemsBySection, sectionTitles] = useMemo(() => {
        const otherSectionTitle = t('SIDE_MENU__OTHER_ITEMS');
        const featuredSectionTitle = t('SIDE_MENU__FEATURED_ITEMS');
        const processedItems = items.map((item) => ({
            ...item,
            sectionTitle: featuredDefinitionIds.includes(item.key)
                ? featuredSectionTitle
                : item.sectionTitle
        }));
        const itemsBySection = groupBy(processedItems, (item) => item.sectionTitle || otherSectionTitle);
        const sectionTitlesWithoutOtherSection = without(keys(itemsBySection), otherSectionTitle);
        let sectionTitles;
        if (isEmpty(sectionTitlesWithoutOtherSection)) {
            // In this case we don't want to show sections so return an empty sectionTitles array
            sectionTitles = [];
        }
        else {
            sectionTitles = itemsBySection[otherSectionTitle]
                ? [...sectionTitlesWithoutOtherSection, otherSectionTitle]
                : keys(itemsBySection);
        }
        const sortedSectionTitles = sortBy(sectionTitles, [
            (sectionTitle) => {
                if (sectionTitle === otherSectionTitle) {
                    return maxSectionSortOrderIndex + 2;
                }
                if (sectionSortOrderIndexMap[sectionTitle]) {
                    return sectionSortOrderIndexMap[sectionTitle];
                }
                return maxSectionSortOrderIndex + 1;
            },
            identity
        ]);
        return [itemsBySection, sortedSectionTitles];
    }, [items, maxSectionSortOrderIndex, sectionSortOrderIndexMap, t, featuredDefinitionIds]);
    const filteredItems = useMemo(() => {
        const upperSearchText = search.toUpperCase();
        return search
            ? items.filter(({ title, subTitle }) => (title && isString(title) && title.toUpperCase().indexOf(upperSearchText) > -1) ||
                (subTitle && subTitle.toUpperCase().indexOf(upperSearchText) > -1))
            : items || [];
    }, [search, items]);
    useEffect(() => {
        if (isEmpty(getFromLocalStorage(openedSectionsStorageKey, [])) && !isEmpty(sectionTitles)) {
            // This opens the sections when the sectionTitles are arriving later because of a request
            setOpenedSections(sectionTitles);
        }
    }, [openedSectionsStorageKey, sectionTitles]);
    const handleFeaturedChange = useCallback((key) => {
        const featuredDefinitionIdsOneLevel = getFeaturedDefinitionIdsOneLevel(featuredDefinitionIds);
        let ids = [];
        if (featuredDefinitionIdsOneLevel.includes(key)) {
            ids = deepFilter(cmsConfig?.data?.featuredDefinitionIds || [], key);
        }
        else {
            ids = union(cmsConfig?.data?.featuredDefinitionIds || [], [key]);
        }
        dispatchSaveConfig(saveConfig({
            ...cmsConfig,
            configType: SETTINGS_KEY,
            configId: CMS_KEY,
            data: { featuredDefinitionIds: ids }
        }))
            .then(() => {
            dispatch(success({
                title: t('SUCCESS'),
                message: t('SAVE_SETTINGS_SUCCESS')
            }));
            void dispatchFetchConfig(fetchConfig(SETTINGS_KEY, CMS_KEY));
        })
            .catch(() => dispatch(error({
            title: t('ERROR'),
            message: t('SAVE_SETTINGS_FAIL')
        })));
    }, [featuredDefinitionIds, dispatchSaveConfig, cmsConfig, dispatch, t, dispatchFetchConfig]);
    if (isFailed) {
        return null;
    }
    return isDesktop ? (React.createElement(DesktopSideMenu, { handleSearchChange: handleSearchChange, search: search, items: filteredItems, withSearch: withSearch, openedSections: openedSections, updateOpenedSections: updateAndPersistOpenedSections, itemsBySection: itemsBySection, sectionTitles: sectionTitles, collapsed: isDesktopMenuCollapsed, onCollapseChange: onDesktopMenuCollapseChange, handleFeaturedChange: handleFeaturedChange, ...otherProps })) : (React.createElement(MobileSideMenu, { handleSearchChange: handleSearchChange, search: search, items: filteredItems, openedSections: openedSections, updateOpenedSections: updateAndPersistOpenedSections, itemsBySection: itemsBySection, sectionTitles: sectionTitles, handleFeaturedChange: handleFeaturedChange, ...otherProps }));
};
export default SideMenu;
