import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React, { useCallback } from 'react';
import { useView } from '../../../views/components/ViewContext';
import styles from './SelectionRow.module.scss';
const SelectionRow = ({ withFirstDivider = true }) => {
    const { t, language } = useI18n();
    const { rowGrouping, isAllItemsSelected, setIsAllItemsSelected, selectedItems = [], setSelectedItems, canSelectAll, totalItemsCount, isAllItemsSelectedOnPage } = useView();
    const selectAllItemsOfDefinition = useCallback(() => {
        setIsAllItemsSelected(true);
        setSelectedItems([]);
    }, [setIsAllItemsSelected, setSelectedItems]);
    const clearSelection = useCallback(() => {
        setIsAllItemsSelected(false);
        setSelectedItems([]);
    }, [setIsAllItemsSelected, setSelectedItems]);
    if (!selectedItems?.length && !isAllItemsSelected) {
        return null;
    }
    return (React.createElement("div", { className: `${styles.wrapper} SelectionRowContainer cp-i-sm-flex cp-i-100` },
        withFirstDivider && React.createElement(Divider, { type: "vertical", className: styles.divider }),
        (isAllItemsSelected || selectedItems.length > 0) &&
            t('COMMON__SELECTED', {
                count: isAllItemsSelected
                    ? parseInt(totalItemsCount.toString())
                    : parseInt(selectedItems.length.toString())
            }),
        canSelectAll && !isAllItemsSelected && (rowGrouping ? true : isAllItemsSelectedOnPage) && (React.createElement(React.Fragment, null,
            React.createElement(Divider, { type: "vertical", className: styles.divider }),
            React.createElement("a", { className: "clickable-link", onClick: selectAllItemsOfDefinition }, t('DATA_TABLE__SELECT_ALL', {
                itemsCount: totalItemsCount
            })))),
        (selectedItems.length > 0 || isAllItemsSelected) && (React.createElement(React.Fragment, null,
            React.createElement(Divider, { type: "vertical", className: styles.divider }),
            React.createElement("a", { className: "clickable-link", onClick: clearSelection }, t('COMMON__CLEAR_SELECTION'))))));
};
export default SelectionRow;
