import { CMS_API_PATH } from '../../../cms';
import { HYPER_INTEGRATION, HYPER_INTEGRATION_MODULE } from './constants';
export const enableIntegrationById = (entityId) => toggleIntegration('INTEGRATIONS__ENABLE', HYPER_INTEGRATION, entityId, true);
export const disableIntegrationById = (entityId) => toggleIntegration('INTEGRATIONS__DISABLE', HYPER_INTEGRATION, entityId, false);
export const enableIntegrationModuleById = (entityId) => toggleIntegration('INTEGRATIONS__MODULE_ENABLE', HYPER_INTEGRATION_MODULE, entityId, true);
export const disableIntegrationModuleById = (entityId) => toggleIntegration('INTEGRATIONS__MODULE_DISABLE', HYPER_INTEGRATION_MODULE, entityId, false);
export const getDefinitionData = (definitionId) => ({
    type: 'INTEGRATIONS__GET_DEFINITION_DATA',
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${definitionId}/data`,
            method: 'GET',
            allowPromiseReject: true,
            actionContext: {}
        }
    }
});
export const saveIntegrationConfig = (integrationId, config) => saveConfig('INTEGRATIONS__SAVE_CONFIG', HYPER_INTEGRATION, integrationId, config);
export const getIntegrationConfig = (integrationId) => getConfig('INTEGRATIONS__GET_CONFIG', HYPER_INTEGRATION, integrationId);
export const getModuleConfig = (moduleId) => getConfig('INTEGRATIONS__GET_MODULE_CONFIG', HYPER_INTEGRATION_MODULE, moduleId);
export const saveModuleConfig = (moduleId, config) => saveConfig('INTEGRATIONS__SAVE_MODULE_CONFIG', HYPER_INTEGRATION_MODULE, moduleId, config);
const toggleIntegration = (type, definitionId, entityId, enable) => ({
    type,
    payload: {
        entityId: entityId,
        definitionId,
        propertyId: 'enabled',
        value: enable
    },
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${definitionId}/data/${entityId}`,
            method: 'PUT',
            allowPromiseReject: true,
            actionContext: {}
        }
    }
});
const getConfig = (type, definitionId, entityId) => ({
    type,
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${definitionId}/data/${entityId}`,
            method: 'GET',
            allowPromiseReject: true,
            actionContext: {}
        }
    }
});
const saveConfig = (type, definitionId, entityId, config) => ({
    type,
    payload: {
        definitionId,
        entityId: entityId,
        propertyId: 'configuration',
        value: config
    },
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${definitionId}/data/${entityId}`,
            method: 'PUT',
            allowPromiseReject: true,
            actionContext: {}
        }
    }
});
