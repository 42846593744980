export const NO_PERMISSIONS_LANDING_PAGE_PATH = '/no-permissions';
export const REQUIRED_PORTAL_PERMISSION = 'PORTAL';
export const APP_ADMIN_PERMISSION = 'APP_ADMIN';
export const PLATFORM_ADMIN_PERMISSION = 'PLATFORM_ADMIN';
export const REQUIRED_MAIL_TEMPLATES_PERMISSION = 'MAIL_TEMPLATES';
export const MAIL_TEMPLATES_PREVIEW_ON_CLIENTS_PERMISSION = 'MAIL_TEMPLATE_PREVIEW_ON_CLIENTS';
export const BULK_EDIT_PERMISSION = 'BULK_EDIT';
export const PROCESS_CANCELLATION_PERMISSION = 'PROCESS_CANCELLATION';
export const PROCESS_RESTART_TASKS_PERMISSION = 'PROCESS_RESTART_TASKS';
export const EDIT_DEFAULT_VIEW_PERMISSION = 'EDIT_DEFAULT_VIEW';
export const SHARE_VIEW_PERMISSION = 'SHARE_VIEW';
export const SHARE_DASHBOARD_PERMISSION = 'SHARE_DASHBOARD';
export const MULTI_LANGUAGE_PROPERTY_PERMISSION = 'MULTI_LANGUAGE_PROPERTY';
export const CMS_ITEM_META_HANDLER = 'cms';
export const PROCESS_META_HANDLER = 'workflows';
export const BROWSE_PATH = '/browse';
export const VIEW_PATH = '/view';
export const SETTINGS_PATH = '/settings';
export const MAX_DEFINITION_LABEL_LENGTH = 50;
export const MAX_ITEMS_COUNT_FOR_ENTITY_SELECTOR = 50;
export const TAB_STATE_LOCAL_STORAGE = 'tabState';
export const PORTAL_AVAILABLE_LANGUAGES = [
    {
        value: 'en',
        label: 'English'
    },
    {
        value: 'nl',
        label: 'Nederlands'
    },
    {
        value: 'fr',
        label: 'Français'
    },
    {
        value: 'it',
        label: 'Italiano'
    },
    {
        value: 'de',
        label: 'Deutsch'
    },
    {
        value: 'pl',
        label: 'Polski'
    },
    {
        value: 'cs',
        label: 'Češka'
    },
    {
        value: 'hu',
        label: 'Magyar'
    },
    {
        value: 'sk',
        label: 'Slovenský'
    },
    {
        value: 'es',
        label: 'Español'
    },
    {
        value: 'fi',
        label: 'Suomi'
    },
    {
        value: 'sv',
        label: 'Svenska'
    },
    {
        value: 'ja',
        label: '日本語'
    }
];
