export const SETTINGS_KEY = 'settings';
export const SETTINGS_PATH = `/${SETTINGS_KEY}`;
export const TENANT_KEY = 'tenant';
export const TENANT_PATH = `/${TENANT_KEY}`;
export const GDPR_KEY = 'gdpr';
export const GDPR_PATH = `/${GDPR_KEY}`;
export const XDMS_KEY = 'xdms';
export const XDMS_PATH = `/${XDMS_KEY}`;
export const CMS_KEY = 'cms';
export const CMS_PATH = `/_${CMS_KEY}`;
export const DOMAINS_KEY = 'domains';
export const DOMAINS_PATH = `/${DOMAINS_KEY}`;
export const STATISTICS_KEY = 'statistics';
export const STATISTICS_PATH = `/${STATISTICS_KEY}`;
export const LOGS_KEY = 'logs';
export const LOGS_PATH = `/${LOGS_KEY}`;
export const INTEGRATIONS_KEY = 'integrations';
export const INTEGRATIONS_PATH = `/${INTEGRATIONS_KEY}`;
export const APPS_KEY = 'apps';
export const APPS_PATH = `/${APPS_KEY}`;
export const APIKEY_KEY = 'apiKeys';
export const APIKEY_PATH = `/${APIKEY_KEY}`;
export const SUBSCRIPTIONS_KEY = 'subscriptions';
export const PAYMENT_KEY = 'paymentDetails';
export const PAYMENT_PATH = `/${PAYMENT_KEY}`;
