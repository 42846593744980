import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { APPS_KEY, SETTINGS_PATH } from '../../../constants';
import AppLogo from './AppLogo';
export const App = ({ app }) => {
    const { t, language } = useI18n();
    const description = app.configuration?.['description' + language.charAt(0).toUpperCase() + language.slice(1)] || '';
    const logo = app.configuration.logo || `/assets/images/apps/${app.entityId}.svg`;
    return (React.createElement("div", { className: "d-flex card-item h-100" },
        React.createElement("div", { className: "d-flex p-3 col-4 justify-content-center logo" },
            React.createElement(AppLogo, { alt: app.title, logoUrl: logo, loading: false })),
        React.createElement("div", { className: "d-flex flex-column col-8 p-3" },
            React.createElement("h5", { className: "fw-bold title" }, app.title),
            React.createElement("p", { className: "description fs-6" }, description),
            React.createElement("div", { className: "mt-auto" },
                React.createElement(Button, { to: [SETTINGS_PATH, APPS_KEY, app.entityId].join('/'), type: "button" }, t('SETTINGS'))))));
};
export default App;
