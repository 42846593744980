import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Select } from 'antd';
import cn from 'classnames';
import { isString } from 'lodash';
import React, { useCallback } from 'react';
import { MdExpandMore } from 'react-icons/md';
import styles from './BasicFilter.module.scss';
import { buildFilter, isOperatorWithoutData } from './utils';
const availableOperators = [
    { value: FilterOperatorTypes.notEmpty },
    { value: FilterOperatorTypes.empty }
];
const BaseFilter = ({ className, filter, onChange, operators, unavailableOperators, disabled, children }) => {
    const { t } = useI18n();
    operators = operators || availableOperators;
    const onChangeOperator = useCallback((newOperator) => {
        const getFilterData = () => {
            if (!('data' in filter)) {
                return;
            }
            if (newOperator === FilterOperatorTypes.isIn ||
                newOperator === FilterOperatorTypes.isNotIn) {
                if (Array.isArray(filter.data)) {
                    return filter.data;
                }
                return (isString(filter.data) && filter.data && [filter.data]) || [];
            }
            if ((filter.operator === FilterOperatorTypes.isIn ||
                filter.operator === FilterOperatorTypes.isNotIn) &&
                Array.isArray(filter.data)) {
                return filter.data[0];
            }
            return filter.data;
        };
        const newFilter = buildFilter(filter.field, newOperator, getFilterData());
        onChange(newFilter);
    }, [filter, onChange]);
    return (React.createElement("div", { className: cn('d-flex flex-row align-items-center flex-grow-1 w-100 flex-truncate', className, styles.wrapper) },
        React.createElement(Select, { className: "flex-basis-40 me-2 overflow-hidden", value: filter.operator, onSelect: onChangeOperator, suffixIcon: React.createElement(MdExpandMore, { color: "var(--neutralColor-6)" }), disabled: disabled, options: (unavailableOperators
                ? operators.filter((operator) => {
                    return !unavailableOperators.includes(operator.value);
                })
                : operators).map(({ value, label }) => ({
                value,
                label: t(label || value)
            })) }),
        !isOperatorWithoutData(filter.operator) && children && (React.createElement("div", { className: "flex-fill ms-2 flex-basis-60 overflow-hidden FilterWrapper" }, children))));
};
export default BaseFilter;
