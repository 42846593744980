import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { compact, isString } from 'lodash';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Breadcrumbs } from '../../../../../cms/common/breadcrumbs';
import { fetchItemReferrals } from '../../../../../cms/data/actions';
import { ACTIVE_TAB_RIGHT_PANEL_KEY } from '../../../../../cms/data/components/item/InfoPanel/InfoPanel';
import { Drawer, DrawerContent } from '../../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../../common/components/Drawer/DrawerContentBlock';
import { useBrowserBreakpoints } from '../../../../../common/components/with-browser-breakpoints';
import useElementSize from '../../../../../common/hooks/useElementSize';
import { BROWSE_PATH } from '../../../../../common/utils/constants';
import { ArrowIcon } from '../../../../../icons';
import { useViews } from '../../../../../views';
import { ALL_PROCESSES_DEFINITION_ID, PROCESSES_PATH, WORKFLOWS_PATH } from '../../../../common/utils/constants';
import ProcessHeader from '../ProcessHeader';
import ProcessMainContent from '../ProcessMainContent';
import ProcessOverview from '../ProcessOverview';
import ProcessSideContentWrapper, { ProcessSideContent } from '../ProcessSideContent';
import styles from './Process.module.scss';
const LOCAL_STORAGE_KEY = `desktop-right-panel-collapsed-process`;
const Process = ({ baseUrl, viewId, definitionId, processRunId, contactId, processDetails, processActivity, completeTask, assignTask, onChangeTaskPriority, onChangeTaskDeadline, tasks, isProcessPending, dispatchWarning, canEditData, blacklistedPropertyIds, titleProperty, processTitle, updateProcessTitle, onCancelProcess, onDeleteProcess }) => {
    const { ref: contentRef, size: { width: parentWidth } } = useElementSize();
    const { t, language } = useI18n();
    const dispatch = useDispatch();
    const { isDesktop } = useBrowserBreakpoints();
    const [processOverviewVisible, setProcessOverviewVisible] = useState(false);
    const location = useLocation();
    const hasActivityTabFromSearch = isString(queryString.parse(location.search)[ACTIVE_TAB_RIGHT_PANEL_KEY]);
    const [isSidebarActive, setIsSidebarActive] = useState(isDesktop && (!(localStorage.getItem(LOCAL_STORAGE_KEY) === '0') || hasActivityTabFromSearch));
    const { getViewBreadcrumbLink } = useViews();
    const toggleSideContentBar = useCallback(() => setIsSidebarActive((prev) => !prev), []);
    const onChangeSidebarActive = useCallback((active) => {
        setIsSidebarActive(active);
        if (isDesktop) {
            localStorage.setItem(LOCAL_STORAGE_KEY, active ? '1' : '0');
        }
    }, [isDesktop]);
    useEffect(() => {
        if (definitionId) {
            dispatch(fetchItemReferrals(definitionId, processRunId, language));
        }
    }, [dispatch, definitionId, processRunId, language]);
    const links = useMemo(() => compact([
        { text: t('WORKFLOWS_NAV_LINK'), to: WORKFLOWS_PATH },
        {
            text: t('WORKFLOWS_PROCESSES_NAV_LINK'),
            to: `${WORKFLOWS_PATH}${PROCESSES_PATH}/${ALL_PROCESSES_DEFINITION_ID}${BROWSE_PATH}`
        },
        {
            text: processTitle,
            to: `${baseUrl}${BROWSE_PATH}`
        },
        getViewBreadcrumbLink(baseUrl, processTitle, viewId)
    ]), [baseUrl, getViewBreadcrumbLink, processTitle, t, viewId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Breadcrumbs, { links: links, currentBreadcrumb: processRunId }),
        React.createElement("button", { type: "button", onClick: toggleSideContentBar, className: `${styles.mobileToggleButton} d-flex d-lg-none align-items-center justify-content-center mb-2` },
            React.createElement(ArrowIcon, null)),
        React.createElement(ProcessHeader, { definitionId: definitionId, processDetails: processDetails, processRunId: processRunId, updateProcessTitle: updateProcessTitle, titleProperty: titleProperty, isProcessPending: isProcessPending, onCancelProcess: onCancelProcess, onDeleteProcess: onDeleteProcess }),
        React.createElement("div", { className: `${styles.wrapper} px-3 pb-3 ${isSidebarActive ? '' : styles.sidebarActive} ` },
            React.createElement("div", { ref: contentRef, className: "d-flex overflow-hidden gap-3 flex-nowrap" },
                React.createElement(ProcessMainContent, { tasks: tasks, assignTask: assignTask, completeTask: completeTask, dispatchWarning: dispatchWarning, isProcessPending: isProcessPending, processDetails: processDetails, processMetaId: definitionId, processRunId: processRunId, blacklistedPropertyIds: blacklistedPropertyIds, canEditData: canEditData, contactId: contactId, onChangeTaskPriority: onChangeTaskPriority, onChangeTaskDeadline: onChangeTaskDeadline }),
                React.createElement(ProcessSideContentWrapper, { processRunId: processRunId, tasks: tasks, loading: isProcessPending, processDetails: processDetails, processActivity: processActivity, setProcessOverviewVisible: setProcessOverviewVisible, processMetaId: definitionId, isSidebarActive: isSidebarActive, setIsSidebarActive: onChangeSidebarActive, parentWidth: parentWidth })),
            processActivity && processDetails && processOverviewVisible && (React.createElement(ProcessOverview, { processDetails: processDetails, processActivity: processActivity, tasks: tasks, closePreview: () => setProcessOverviewVisible(false) }))),
        processDetails && window.innerWidth < 1024 && (React.createElement(Drawer, { className: styles.processSideDrawer, title: `${t('PROCESS')}: ${processDetails.processRunId}`, onClose: toggleSideContentBar, open: isSidebarActive, destroyOnClose: true, closable: true },
            React.createElement(DrawerContent, null,
                React.createElement(DrawerContentBlock, null,
                    React.createElement(ProcessSideContent, { setProcessOverviewVisible: setProcessOverviewVisible, processRunId: processRunId, tasks: tasks, loading: isProcessPending, processDetails: processDetails, processMetaId: definitionId, isSidebarActive: isSidebarActive, setIsSidebarActive: onChangeSidebarActive, showToggleButton: false })))))));
};
export default Process;
