const basePath = {
    apps: 'v2-apps'
};
export const saveInstanceConfig = (appId, instanceId, config) => {
    let path = `/${basePath.apps}/${appId}/`;
    if (instanceId != 'new') {
        path += instanceId;
    }
    return {
        type: 'APPS__SAVE_INSTANCE_CONFIG',
        payload: config,
        meta: {
            http: {
                path: path,
                method: instanceId == 'new' ? 'POST' : 'PUT',
                allowPromiseReject: true,
                actionContext: {}
            }
        }
    };
};
