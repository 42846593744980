import { AggregationType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { find, keyBy, map, max, size, toNumber } from 'lodash';
import moment from 'moment';
import { getAggregationId } from '../../../../cms/hooks/useAggregation';
const INTERVALS = [
    {
        name: '1h',
        value: 1 * 60 * 60 * 1000,
        d3TimeFormat: '%d/%m/%Y %H',
        momentTimeFormat: 'DD/MM/YYYY HH:mm:ss'
    },
    {
        name: '3h',
        value: 3 * 60 * 60 * 1000,
        d3TimeFormat: '%d/%m/%Y %H',
        momentTimeFormat: 'DD/MM/YYYY HH:mm:ss'
    },
    {
        name: '12h',
        value: 12 * 60 * 60 * 1000,
        d3TimeFormat: '%d/%m/%Y %H',
        momentTimeFormat: 'DD/MM/YYYY HH:mm:ss'
    },
    {
        name: '1d',
        value: 24 * 60 * 60 * 1000,
        d3TimeFormat: '%d/%m/%Y',
        momentTimeFormat: 'DD/MM/YYYY'
    },
    {
        name: '3d',
        value: 3 * 24 * 60 * 60 * 1000,
        d3TimeFormat: '%d/%m/%Y',
        momentTimeFormat: 'DD/MM/YYYY'
    },
    {
        name: '1w',
        value: 1 * 7 * 24 * 60 * 60 * 1000,
        d3TimeFormat: '%d/%m/%Y',
        momentTimeFormat: 'DD/MM/YYYY'
    },
    {
        name: '14d',
        value: 2 * 7 * 24 * 60 * 60 * 1000,
        d3TimeFormat: '%d/%m/%Y',
        momentTimeFormat: 'DD/MM/YYYY'
    },
    {
        name: '1M',
        value: 30 * 24 * 60 * 60 * 1000,
        d3TimeFormat: '%d/%m/%Y',
        momentTimeFormat: 'DD/MM/YYYY'
    },
    {
        name: '1q',
        value: 3 * 30 * 24 * 60 * 60 * 1000,
        d3TimeFormat: '%d/%m/%Y',
        momentTimeFormat: 'DD/MM/YYYY'
    },
    {
        name: '1y',
        value: 365 * 24 * 60 * 60 * 1000,
        d3TimeFormat: '%Y',
        momentTimeFormat: 'YYYY'
    }
];
export function getMetricValuePath(metricSettings) {
    if (metricSettings.type !== AggregationType.count) {
        const metricId = getAggregationId(metricSettings);
        return `${metricId}.value`;
    }
    return 'count';
}
export function getAllSubAggregationsValuesMap(aggregationResult, subAggregationId) {
    const termsValueMap = aggregationResult.reduce((acc, aggregationResultItem) => {
        const subAggregationResult = aggregationResultItem[subAggregationId];
        if (Array.isArray(subAggregationResult)) {
            return { ...acc, ...getAggregationValuesMap(subAggregationResult) };
        }
        return acc;
    }, {});
    return termsValueMap;
}
export function getAggregationValuesMap(aggregationResult) {
    return keyBy(aggregationResult, 'value');
}
export function getLegendWidth(legend) {
    const legendItemMaxWidth = max([(max(map(legend, size)) || 0) * 7, 110]) || 110;
    return legendItemMaxWidth;
}
export function aggregationToFilters(aggregation, value) {
    const filters = [];
    if (aggregation.type === AggregationType.term && typeof value === 'string') {
        filters.push({
            field: aggregation.field,
            operator: FilterOperatorTypes.is,
            data: value
        });
    }
    if (aggregation.type === AggregationType.term && typeof value === 'number') {
        filters.push({
            field: aggregation.field,
            operator: FilterOperatorTypes.equals,
            data: value
        });
    }
    else if (aggregation.type === AggregationType.dateHistogram) {
        let endRange = 0;
        const intervalSettings = find(INTERVALS, { name: aggregation.interval });
        if (intervalSettings) {
            endRange = intervalSettings.value;
            if (typeof value === 'string') {
                value = moment(value, intervalSettings.momentTimeFormat).valueOf();
            }
        }
        const valueTimestamp = toNumber(value);
        if (isNaN(valueTimestamp)) {
            return filters;
        }
        filters.push({
            field: aggregation.field,
            operator: FilterOperatorTypes.greaterThanOrEqual,
            data: valueTimestamp
        }, {
            field: aggregation.field,
            operator: FilterOperatorTypes.lessThanEqual,
            data: valueTimestamp + endRange
        });
    }
    return filters;
}
export function getDateHistogramTimeFormat(aggregation) {
    let format = '%d/%m/%Y';
    const intervalSettings = find(INTERVALS, { name: aggregation.interval });
    if (intervalSettings) {
        format = intervalSettings.d3TimeFormat;
    }
    return format;
}
export function getDateHistogramTimeMomentFormat(aggregation) {
    let format = 'DD/MM/YYYY';
    const intervalSettings = find(INTERVALS, { name: aggregation.interval });
    if (intervalSettings) {
        format = intervalSettings.momentTimeFormat;
    }
    return format;
}
export function getAutoInterval(startDate, endDate, buckets = 9) {
    if (!startDate) {
        return '1M';
    }
    endDate = endDate || Date.now();
    const interval = (endDate - startDate) / buckets;
    let min;
    for (const step of INTERVALS) {
        const stepDiff = Math.abs(step.value - interval);
        if (!min || min.stepDiff > stepDiff) {
            min = {
                interval: step.name,
                stepDiff
            };
        }
    }
    return min?.interval || '1M';
}
