import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { FaQuestion } from 'react-icons/fa';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { SortableContainer as asSortableContainer } from 'react-sortable-hoc';
import { getPropertyTypeComponent } from '../../../../../workflows/meta/components/meta-definition-form/utils';
import { getLabel, isProperty } from '../HighlightedItems/utils';
import SortableHandle from './SortableHandle';
import SortableItem from './SortableItem';
const SortableList = asSortableContainer((props) => (React.createElement(EntityList, { ...props })));
function EntityList({ selectedItems, dataById, noItemsLabel, actionsSortableItemComponent: ActionsSortableItem, getItemId }) {
    const { language } = useI18n();
    return (React.createElement("div", null, !selectedItems.length
        ? noItemsLabel
        : selectedItems
            .filter((selectedItem) => dataById[getItemId(selectedItem)])
            .map((selectedItem, index) => {
            const item = dataById[getItemId(selectedItem)];
            const Icon = isProperty(item)
                ? getPropertyTypeComponent(item.type) || FaQuestion
                : null;
            const HiddenIcon = isProperty(item) ? (item.meta.hidden ? IoEyeOff : IoEye) : null;
            const itemId = getItemId(selectedItem);
            return (React.createElement(SortableItem, { key: itemId, index: index },
                React.createElement(SortableHandle, null),
                HiddenIcon && React.createElement(HiddenIcon, { size: 18, className: "ms-1 me-2" }),
                Icon && React.createElement(Icon, null),
                React.createElement("div", { className: "fw-600 ms-2 title" }, getLabel(item, language)),
                React.createElement(ActionsSortableItem, { item: item, itemId: itemId, selectedItem: selectedItem })));
        })));
}
export default SortableList;
