import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Formik } from 'formik';
import { get } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Prompt } from 'react-router';
import useDisplayItemMetaList from '../../../../cms/hooks/useDisplayItemMetaList';
import { HighlightedSelectField } from '../../../../cms/settings/components/entity/HighlightedItems';
import { Option } from '../../../../common/components/Select';
import FormikField from '../../../../common/components/formik/FormikField';
import TabCloseConfirmation from '../../../../common/components/tab-close-confirmation/TabCloseConfirmation';
import { CMS_ITEM_META_HANDLER } from '../../../../common/utils/constants';
import ProcessMetaActions from '../common/ProcessMetaActions';
import QuickStartField from './QuickStartField';
import styles from './QuickStartForm.module.scss';
const mapDefinitionToFormValues = (definition) => {
    return {
        custom: {
            ...definition.custom,
            processRunFromEntities: definition.custom?.processRunFromEntities ?? []
        }
    };
};
const QuickStartForm = ({ extraActionButtons, processDefinition, isSystem, onSubmit }) => {
    const { t } = useI18n();
    const { data: entities, isLoading: isPending } = useDisplayItemMetaList();
    const initialValues = useMemo(() => {
        return mapDefinitionToFormValues(processDefinition);
    }, [processDefinition]);
    const onFormSubmit = useCallback(async (values, actions) => {
        const savedDefinition = await onSubmit(values);
        // Reset the form after successful submission
        actions.resetForm({ values: mapDefinitionToFormValues(savedDefinition) });
        return savedDefinition;
    }, [onSubmit]);
    const cmsEntities = useMemo(() => entities.filter((entity) => entity.handler === CMS_ITEM_META_HANDLER), [entities]);
    const cmsEntitiesById = useMemo(() => cmsEntities.reduce((acc, item) => ({ ...acc, [item.definitionId]: item }), {}), [cmsEntities]);
    if (!initialValues) {
        return null;
    }
    return (React.createElement(Formik, { initialValues: initialValues, onSubmit: onFormSubmit, enableReinitialize: true }, ({ dirty, isSubmitting, resetForm, setFieldValue, submitForm, values, isValid }) => (React.createElement("div", { className: `${styles.container} py-4 px-3` },
        isPending ? (React.createElement(LoadingRectangles, null)) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "form-buttons-container" },
                dirty && (React.createElement(Button, { inversed: true, onClick: () => resetForm(), disabled: isSubmitting }, t('FORM__RESET'))),
                React.createElement(ProcessMetaActions, { formIsValid: isValid, definitionId: processDefinition.definitionId, save: submitForm, saving: isSubmitting, disabled: isSubmitting, canSave: dirty, canDelete: !isSystem }),
                extraActionButtons),
            React.createElement("form", { noValidate: true },
                React.createElement("div", null,
                    React.createElement("div", { className: "fw-500 mb-2" }, t('QUICKSTART_AVAILABLE_ENTITIES')),
                    React.createElement("div", null,
                        React.createElement(FormikField, { name: "custom.processRunFromEntities", component: HighlightedSelectField, data: cmsEntities, getValue: (value) => value.map((referral) => referral.definitionId), onSelectHandler: (selected) => {
                                void setFieldValue('custom.processRunFromEntities', selected.map((definitionId) => {
                                    const value = get(values, 'custom.processRunFromEntities', []);
                                    const existed = value.find((item) => item.definitionId === definitionId);
                                    return {
                                        definitionId,
                                        ...(existed?.conditions ? { conditions: existed?.conditions } : {}),
                                        active: !!existed?.active || true
                                    };
                                }));
                            }, getOptions: (data) => data.map(({ definitionId, title }) => (React.createElement(Option, { key: definitionId, value: definitionId, disabled: false, label: title }, title))) }),
                        React.createElement("div", { className: "fw-500 mb-2 mt-4" }, t('ENTITIES_LABEL')),
                        React.createElement(FormikField, { name: "custom.processRunFromEntities", component: QuickStartField, dataById: cmsEntitiesById })))))),
        React.createElement(Prompt, { when: dirty && !isSubmitting, message: t('UNSAVED_DATA_CONFIRM') }),
        React.createElement(TabCloseConfirmation, { dirty: dirty })))));
};
export default QuickStartForm;
