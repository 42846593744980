import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useState } from 'react';
import { FaRegTrashAlt as DeleteAction } from 'react-icons/fa';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import ItemLink from '../../../cms/data/components/item/ItemLink/ItemLink';
import ConfirmationButtonModal from '../../../common/components/ConfirmationButtonModal';
import Toggle from '../../../common/components/Toggle';
import { CONTACT_CMS_DEFINITION_ID } from '../../../crm';
const ApiKeyItem = ({ apiKey, loading, onChange, onDelete }) => {
    const { t } = useI18n();
    const [showApiKey, setShowApiKey] = useState(false);
    const toggleShowApiKey = useCallback(() => {
        setShowApiKey((showApiKey) => !showApiKey);
    }, []);
    const toggleApiKey = useCallback((enabled) => {
        return onChange({ enabled });
    }, [onChange]);
    return (React.createElement("div", { key: apiKey.id, className: `m-4 container` },
        React.createElement("div", { className: "row align-items-center" },
            React.createElement("div", { className: "col-lg-3 d-inline-block" },
                React.createElement(ItemLink, { definitionId: CONTACT_CMS_DEFINITION_ID, entityId: apiKey.contactId })),
            React.createElement("div", { className: "col-lg-5" },
                React.createElement(BasicInput, { className: "p-0", type: showApiKey ? 'text' : 'password', disabled: true, value: apiKey.value })),
            React.createElement("div", { className: "col-lg-1 text-center", role: "button", onClick: toggleShowApiKey }, showApiKey ? React.createElement(IoEye, { size: 24 }) : React.createElement(IoEyeOff, { size: 24 })),
            React.createElement("div", { className: "col-lg-1 text-center" },
                React.createElement(Toggle, { onChange: toggleApiKey, checked: apiKey.enabled, disabled: loading })),
            React.createElement("div", { className: "col-lg-1 text-center" },
                React.createElement(ConfirmationButtonModal, { className: "action-button delete-action", title: t('COMMON__DELETE'), disabled: loading, message: t('APIKEY_DELETE_MESSAGE'), onClick: onDelete, confirmButtonText: t('COMMON__DELETE'), cancelButtonText: t('COMMON_CANCEL'), danger: true },
                    React.createElement(DeleteAction, null))))));
};
export default ApiKeyItem;
