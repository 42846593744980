import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { arrayMoveImmutable } from 'array-move';
import { find, isEqual, map } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { SortableContainer as sortableContainer, SortableElement as sortableElement } from 'react-sortable-hoc';
import { getAggregationId, useAggregation } from '../../../../../cms/hooks/useAggregation';
import { DrawerContent } from '../../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../../common/components/Drawer/DrawerContentBlock';
import Toggle from '../../../../../common/components/Toggle';
import styles from './CustomOrderTerms.module.scss';
const SortableItem = sortableElement(({ children }) => children);
const SortableList = sortableContainer(({ items, className }) => {
    return (React.createElement("ul", { className: className }, items.map((item, index) => (React.createElement(SortableItem, { key: `item-${index}`, index: index },
        React.createElement("li", { className: "sortableItem list-group-item" }, item.label || item.value))))));
});
const CustomOrderTerms = ({ value, definitionId, aggregation, onChange }) => {
    const { t } = useI18n();
    const aggregations = useMemo(() => {
        return [{ ...aggregation, size: 50 }];
    }, [aggregation]);
    const { isLoading, data } = useAggregation({ definitionId, aggregations });
    const items = useMemo(() => {
        const items = (value?.values || []).reduce((acc, item) => {
            acc.push({
                value: item
            });
            return acc;
        }, []);
        const aggregationId = getAggregationId(aggregation);
        if (data) {
            const aggregationResult = data.aggregations[aggregationId];
            if (aggregationResult && Array.isArray(aggregationResult)) {
                aggregationResult.reduce((acc, item) => {
                    const el = find(acc, { value: item.value });
                    if (el) {
                        el.label = item.label;
                    }
                    else {
                        acc.push({
                            label: item.label,
                            value: item.value
                        });
                    }
                    return acc;
                }, items);
            }
        }
        return items.filter((item) => !!item.label);
    }, [aggregation, data, value?.values]);
    const onSortEnd = useCallback((sort) => {
        if (items) {
            const newValue = {
                ...value,
                values: arrayMoveImmutable(map(items, 'value'), sort.oldIndex, sort.newIndex)
            };
            if (!isEqual(newValue, value)) {
                onChange?.(newValue);
            }
        }
    }, [items, onChange, value]);
    const onEnabledToggle = useCallback((enabled) => {
        const newValue = {
            ...value,
            enabled: enabled
        };
        onChange?.(newValue);
    }, [onChange, value]);
    if (!items || !items.length) {
        return null;
    }
    return (React.createElement(DrawerContent, null,
        React.createElement(DrawerContentBlock, { title: t('HYPER_BI_ENABLE') },
            React.createElement(Toggle, { onChange: onEnabledToggle, checked: value?.enabled })),
        React.createElement(DrawerContentBlock, { title: t('HYPER_BI_VALUES') }, isLoading && !data ? (React.createElement(LoadingRectangles, null)) : (React.createElement(SortableList, { helperClass: `${styles.sortableHelper}`, className: `${styles.wrapper} list-group list-group-flush`, useDragHandle: false, items: items, onSortEnd: onSortEnd })))));
};
export default CustomOrderTerms;
