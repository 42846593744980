import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { find, flatMap, get, isEmpty, sortBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { FaPlus } from 'react-icons/fa';
import { TITLE_PROPERTY_ID } from '../../cms';
import { SelectPropertyButton } from '../../cms/data/components/item-property/SelectProperty';
import { DESIGN_FIELDS_FOR_KANBAN } from '../../cms/data/components/items/Kanban/KanbanContainer';
import useDisplayItemMeta from '../../cms/hooks/useDisplayItemMeta';
import { isWorkflowDefinition } from '../../common/utils/url';
import { ALL_TASKS_DEFINITION_ID } from '../../workflows';
import { DEFAULT_COLUMNS_VIEW } from '../constants';
import { ViewTypes } from '../types';
import SortablePropertyList from './SortablePropertyList';
export const supportedColumnPropertyTypes = [
    'checkbox',
    'date',
    'email',
    'entity',
    'file',
    'json',
    'link',
    'multitext',
    'number',
    'phoneNumber',
    'richtext',
    'select',
    'text'
];
const ColumnsField = ({ className, disabled, input, viewType, displayData }) => {
    const { t } = useI18n();
    const { data: displayItemMeta } = useDisplayItemMeta({
        definitionId: displayData.definitionId
    });
    const isProcess = useMemo(() => isWorkflowDefinition(displayItemMeta), [displayItemMeta]);
    const isTask = displayData.definitionId === ALL_TASKS_DEFINITION_ID;
    const selectedPropertyIds = useMemo(() => {
        if (isEmpty(input.value) || !Array.isArray(input.value)) {
            return DEFAULT_COLUMNS_VIEW;
        }
        // Strip out the deleted properties
        return input.value.filter((propertyId) => !!find(flatMap(displayData.data, 'values'), { propertyId }));
    }, [displayData.data, input]);
    const disabledItems = useMemo(() => {
        if (viewType !== ViewTypes.kanban) {
            return [];
        }
        return [TITLE_PROPERTY_ID];
    }, [viewType]);
    const prioritySort = useMemo(() => {
        if (viewType !== ViewTypes.kanban) {
            return;
        }
        if (isProcess) {
            if (isTask) {
                return DESIGN_FIELDS_FOR_KANBAN.workflow.task;
            }
            else {
                return DESIGN_FIELDS_FOR_KANBAN.workflow.process;
            }
        }
        return get(DESIGN_FIELDS_FOR_KANBAN.cms, displayData.definitionId, DESIGN_FIELDS_FOR_KANBAN.cms.default);
    }, [displayData.definitionId, isProcess, isTask, viewType]);
    const sortByPriority = useCallback((value) => {
        return sortBy(value, (item) => {
            const priorityIndex = item && prioritySort?.indexOf(item);
            return priorityIndex === -1 ? Infinity : priorityIndex;
        });
    }, [prioritySort]);
    const addColumn = useCallback((propertyId) => {
        input.onChange(sortByPriority([...selectedPropertyIds, propertyId]));
    }, [selectedPropertyIds, input, sortByPriority]);
    return (React.createElement("div", { className: className },
        !isEmpty(selectedPropertyIds) && (React.createElement(SortablePropertyList, { disabled: disabled, displayData: displayData, selection: selectedPropertyIds, disabledItems: disabledItems, handleChange: (value) => input.onChange(sortByPriority(value)), prioritySort: prioritySort })),
        React.createElement(SelectPropertyButton, { className: "my-3 w-50", disabled: disabled, label: React.createElement(React.Fragment, null,
                React.createElement(FaPlus, { size: 18, className: "me-2" }),
                t('ADD_COLUMN')), onChange: addColumn, definitionId: displayData.definitionId, isFieldDisabled: (propertyId) => selectedPropertyIds.includes(propertyId), includeNestedProperties: false })));
};
export default ColumnsField;
