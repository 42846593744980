import React, { useMemo } from 'react';
import { TooltipTruncate } from '../../../../../common/components/TooltipTruncate';
import { useEntityItem } from '../../../../hooks/useEntityItem';
import { getItemRepresentation } from '../../../utils';
const ItemLabel = ({ definitionId, entityId, className }) => {
    const { data: item } = useEntityItem(definitionId, entityId);
    const itemName = useMemo(() => {
        return item ? getItemRepresentation(item) : entityId;
    }, [entityId, item]);
    return React.createElement(TooltipTruncate, { className: className }, itemName);
};
export default ItemLabel;
