import { flatMap, reduce } from 'lodash';
import { ImportModes } from '../types';
import { getNumberFormatName } from '../utils';
export const fillDefaultValue = (settings, definition) => {
    const newPropertyMapping = reduce(flatMap(definition.data, 'values'), (result, { propertyId, type }) => {
        const newPropertyMapping = (settings.propertyMappings &&
            settings.propertyMappings[propertyId]) || {
            jsonPath: null
        };
        const mappingExists = settings.propertyMappings && settings.propertyMappings[propertyId];
        switch (type) {
            case 'date':
                newPropertyMapping.dateFormat = mappingExists
                    ? // @ts-expect-error
                        settings.propertyMappings[propertyId].dateFormat
                    : 'YYYY/MM/DD GMT';
                newPropertyMapping.timezone =
                    settings.propertyMappings && settings.propertyMappings[propertyId]
                        ? // @ts-expect-error
                            settings.propertyMappings[propertyId].timezone
                        : 'Europe/London';
                break;
            case 'number':
                newPropertyMapping.numberFormat = mappingExists
                    ? getNumberFormatName(settings.propertyMappings[propertyId])
                    : 'dot-comma';
                break;
        }
        return { ...result, [propertyId]: newPropertyMapping };
    }, {});
    return {
        changeAllMatchingItems: true,
        // @ts-expect-error
        importMode: ImportModes.CREATE_UPDATE,
        ...settings,
        propertyMappings: newPropertyMapping
    };
};
