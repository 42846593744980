import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
const StorageContext = createContext(undefined);
const safeJsonParse = (value) => {
    try {
        return JSON.parse(value);
    }
    catch (err) {
        return value;
    }
};
const getAllDataFromStorage = (storage) => Object.keys(storage).reduce((acc, cur) => {
    acc[cur] = safeJsonParse(storage.getItem(cur));
    return acc;
}, {});
export const StorageContextProvider = ({ children }) => {
    const [appLocalStorage, setAppLocalStorage] = useState(() => getAllDataFromStorage(localStorage));
    const [appSessionStorage, setAppSessionStorage] = useState(() => getAllDataFromStorage(sessionStorage));
    useEffect(() => {
        const syncWithLocalStorage = ({ key, newValue }) => {
            if (key) {
                setAppLocalStorage((prevStore) => ({
                    ...prevStore,
                    [key]: safeJsonParse(newValue)
                }));
            }
        };
        window.addEventListener('storage', syncWithLocalStorage);
        return () => {
            window.removeEventListener('storage', syncWithLocalStorage);
        };
    }, []);
    const value = useMemo(() => ({
        appLocalStorage,
        setAppLocalStorage,
        appSessionStorage,
        setAppSessionStorage
    }), [appLocalStorage, appSessionStorage, setAppLocalStorage, setAppSessionStorage]);
    return React.createElement(StorageContext.Provider, { value: value }, children);
};
export const useStorage = ({ key, defaultValue, storeInStorage = true, storageType = 'local', debounceForSaving = true }) => {
    const context = useContext(StorageContext);
    if (!context) {
        throw new Error('useStorage must be used within an StorageProvider');
    }
    const { appSessionStorage, setAppLocalStorage, setAppSessionStorage, appLocalStorage } = context;
    const { storage, appStorage, setAppStorage } = useMemo(() => ({
        storage: storageType === 'local' ? localStorage : sessionStorage,
        appStorage: storageType === 'local' ? appLocalStorage : appSessionStorage,
        setAppStorage: storageType === 'local' ? setAppLocalStorage : setAppSessionStorage
    }), [appLocalStorage, appSessionStorage, setAppLocalStorage, setAppSessionStorage, storageType]);
    const [localValue, setLocalValue] = useState(() => {
        if (storeInStorage && typeof window !== 'undefined') {
            const storedValue = storage.getItem(key);
            return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
        }
        return defaultValue || null;
    });
    const setStorageDebounced = useDebouncedCallback((key, value) => {
        storage.setItem(key, JSON.stringify(value));
    }, 300);
    const setValue = useCallback((value) => {
        setLocalValue(value);
        setAppStorage((prevStore) => ({
            ...prevStore,
            [key]: value
        }));
        if (storeInStorage) {
            if (debounceForSaving) {
                setStorageDebounced(key, value);
            }
            else {
                storage.setItem(key, JSON.stringify(value));
            }
        }
    }, [setAppStorage, storeInStorage, key, debounceForSaving, setStorageDebounced, storage]);
    return [appStorage[key] !== undefined ? appStorage[key] : localValue, setValue];
};
