import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { Tooltip } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { FaCopy, FaEye, FaEyeSlash, FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import ConfirmationButtonModal from '../../../../common/components/ConfirmationButtonModal';
import { RelatedProcessesSelector } from '../../../../common/components/RelatedProcesses';
import { BROWSE_PATH } from '../../../../common/utils/constants';
import { useSystemSectionsVisibility } from '../../../common/context/SystemSectionsVisibilityProvider';
import useDeleteItems from '../../../hooks/useDeleteItems';
import useDisplayItemMeta from '../../../hooks/useDisplayItemMeta';
import BackForwardButton from './BackForwardButton';
import styles from './ItemActionsButton.module.scss';
import { QuickAddItemButton } from './QuickAddItemButton';
const ItemActionsButton = ({ baseUrl, canDeleteItem, definitionId, entityId, entityTitle }) => {
    const { t } = useI18n();
    const { hiddenSections, isSystemSectionsVisible, flipIsSystemSectionsVisible } = useSystemSectionsVisibility();
    const { mutateAsync: asyncDeleteItems } = useDeleteItems({
        definitionId
    });
    const dispatch = useDispatch();
    const { data: displayItemMeta, isLoading: displayItemMetaIsPending } = useDisplayItemMeta({
        definitionId
    });
    const canClone = useMemo(() => !!displayItemMeta?.canCreate, [displayItemMeta]);
    const canDelete = useMemo(() => {
        if (canDeleteItem !== undefined) {
            return canDeleteItem;
        }
        return !!displayItemMeta?.canDelete;
    }, [canDeleteItem, displayItemMeta?.canDelete]);
    const handleCloneSuccess = useCallback((createdEntityId) => {
        dispatch(success({
            title: t('COMMON__SUCCESS'),
            message: t('CMS__CREATE_ITEM_SUCCESS')
        }));
        dispatch(push(`${baseUrl}/${definitionId}${BROWSE_PATH}/${createdEntityId}`));
        return;
    }, [dispatch, t, definitionId, baseUrl]);
    const deleteItem = useCallback(async () => {
        try {
            await asyncDeleteItems({ ids: [entityId] });
            dispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('GENERIC_ASYNC_CHANGES_WARNING')
            }));
            dispatch(push(`${baseUrl}/${definitionId}${BROWSE_PATH}`));
        }
        catch (e) {
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: t('ENTITY_DELETE_FAIL')
            }));
        }
    }, [asyncDeleteItems, entityId, dispatch, t, baseUrl, definitionId]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${styles.buttonsOuter} d-flex align-items-stretch flex-wrap gap-2` },
            React.createElement(RelatedProcessesSelector, { metaDefinitionId: definitionId, selectedIds: [entityId] }),
            React.createElement(BackForwardButton, { entityId: entityId, definitionId: definitionId }),
            !!hiddenSections.length && (React.createElement(Tooltip, { title: isSystemSectionsVisible
                    ? t('CMS_HIDE_SYSTEM_INFORMATION')
                    : t('CMS_SHOW_SYSTEM_INFORMATION') },
                React.createElement(Button, { inversed: true, onClick: flipIsSystemSectionsVisible, className: "action-button" }, isSystemSectionsVisible ? React.createElement(FaEye, { size: 16 }) : React.createElement(FaEyeSlash, { size: 16 })))),
            canDelete && (React.createElement(ConfirmationButtonModal, { danger: true, className: "action-button", tooltip: t('DELETE_ENTITY'), disabled: displayItemMetaIsPending, onClick: deleteItem, title: t('DELETE_ENTITY'), message: t(`DELETE_ENTITY_CONFIRMATION`), warningMessage: t('DELETE_WARNING_CONCERNING_REFERRALS'), confirmButtonText: t('COMMON__DELETE'), cancelButtonText: t('COMMON_CANCEL') },
                React.createElement(FaTrash, { size: 16 }))),
            canClone && (React.createElement(QuickAddItemButton, { definitionId: definitionId, entityTitle: entityTitle, clonedItemEntityId: entityId, onClick: handleCloneSuccess, className: "action-button--copy" },
                React.createElement(FaCopy, { size: 16 }),
                React.createElement("span", { className: 'd-none d-md-inline' }, t('CMS_CLONE')))))));
};
export default ItemActionsButton;
