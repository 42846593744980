import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { find } from 'lodash';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getIsUiAuthenticated } from '../../auth';
import useDisplayItemMetaList from '../../cms/hooks/useDisplayItemMetaList';
import { useSearchItems } from '../../cms/hooks/useSearchItems';
import { VIEW_PATH } from '../../common/utils/constants';
import { useMyContact } from '../../crm/components/use-my-contact';
import { VIEW_DEFINITION_ID, VIEW_RESPONSE_FIELDS } from '../constants';
export const VIEW_FILTER_REQUEST = {
    limit: 500,
    responseFields: VIEW_RESPONSE_FIELDS
};
const ViewsContext = createContext(undefined);
export const ViewsProvider = (props) => {
    const { t } = useI18n();
    const { contactId } = useMyContact();
    const { data: entities } = useDisplayItemMetaList();
    const isAuthenticated = useSelector(getIsUiAuthenticated);
    const { data: { results: views = [] } = {}, isPending: loadingViews } = useSearchItems({
        definitionId: VIEW_DEFINITION_ID,
        filterRequest: VIEW_FILTER_REQUEST
    }, {
        enabled: isAuthenticated && !!contactId
    });
    const updatedViews = useMemo(() => views.map((view) => {
        if (!view.isDefault) {
            const metaItem = find(entities, { definitionId: view?.referenceDefinitionId });
            return { ...view, style: metaItem?.style };
        }
        return view;
    }), [views, entities]);
    const getViewBreadcrumbLink = useCallback((baseUrl, entityTitle, viewId) => {
        if (!viewId) {
            return;
        }
        const to = `${baseUrl}${VIEW_PATH}/${viewId}`;
        const contextualView = find(updatedViews, { entityId: viewId });
        if (loadingViews) {
            return { text: '...', to };
        }
        else if (contextualView != null) {
            return {
                text: contextualView.title ||
                    t('VIEWS__CREATE_PERSONAL_VIEW_TITLE_PLACEHOLDER', { entityTitle }),
                to
            };
        }
    }, [loadingViews, t, updatedViews]);
    const value = useMemo(() => {
        return {
            views: updatedViews,
            loadingViews,
            getViewBreadcrumbLink
        };
    }, [updatedViews, loadingViews, getViewBreadcrumbLink]);
    return React.createElement(ViewsContext.Provider, { value: value, ...props });
};
export const useViews = () => {
    const context = useContext(ViewsContext);
    if (context == null) {
        throw new Error('useViews must be used within an ViewsProvider');
    }
    return context;
};
