import { WORKFLOWS_API_PATH } from '../../../../../../common/utils/constants';
export const fetchNodeParameter = (type, methodName, node) => ({
    type: 'HP3N_NODE_PARAMETER_FETCH',
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/node/parameter/${type}`,
            method: 'GET',
            params: {
                methodName,
                node
            },
            allowPromiseReject: true
        }
    }
});
