import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { ErrorMessage } from '@hypercharge/hyper-react-base/lib/form';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { getTranslationForDescription } from '@hypercharge/hyper-react-base/lib/i18n/i18nUtils';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { Divider, Select, Tooltip } from 'antd';
import { AVAILABLE_LANGUAGES } from 'config';
import produce from 'immer';
import { compact, flatMap, get, isEmpty, isEqual, isError, map, omit, reduce, without } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { MdPlaylistPlay } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { CMS_API_PATH, ENTITY_ID_PROPERTY_ID, fetchItemsById } from '../../../../../cms';
import { generateDisplayDataMap } from '../../../../../cms/common/context/PropertiesProvider';
import { FieldLabel } from '../../../../../cms/data/components/item-property/FieldElements';
import EntityEditor from '../../../../../cms/data/components/item-property/editors/EntityEditor';
import { getEditorComponentForType, getFilePropertyValueList } from '../../../../../cms/data/components/item-property/utils';
import { PropertyContainerComponent } from '../../../../../cms/data/components/item/QuickAddItem';
import { Drawer, DrawerContent, DrawerFooter } from '../../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../../../common/components/Drawer/DrawerFormFooter';
import Toggle from '../../../../../common/components/Toggle';
import { FileField, getFileData, uploadFiles } from '../../../../../common/storage';
import { getScrollableAreaOrBody } from '../../../../../common/utils/common';
import { RequiredFieldIcon } from '../../../../processes/components/process/RequiredFieldInfoPopover';
import styles from './TestFieldsDrawerButton.module.scss';
const TestFieldsDrawerButton = ({ disabled, definition, onErrorDefinitionValidation }) => {
    const { t, language } = useI18n();
    const [labelsLanguage, setLabelsLanguage] = useState(language);
    const [item, setItem] = useState({
        [ENTITY_ID_PROPERTY_ID]: generateId(),
        definitionId: definition.definitionId
    });
    const [itemErrors, setItemErrors] = useState(null);
    const [displayDataList, setDisplayDataList] = useState({
        definitionId: definition.definitionId,
        entityId: '-',
        data: []
    });
    const [isValidating, setIsValidating] = useState(false);
    const [isLoadingSelectedItem, setIsLoadingSelectedItem] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [onlyRequiredFields, setOnlyRequiredFields] = useState(false);
    const [loadedItemEntityId, setLoadedItemEntityId] = useState(null);
    const dispatch = useDispatch();
    const fetchItemPreviewDisplayDataDispatch = useDispatch();
    const fetchItemsByIdDispatch = useDispatch();
    const getFileDataById = useSelector((s) => (fileId) => getFileData(s, fileId));
    const logError = useCallback((err) => {
        if (window.Sentry) {
            window.Sentry.captureException(err);
        }
        else {
            console.error(err);
        }
        return dispatch(error({
            title: t('ERROR'),
            message: t('SOMETHING_WENT_WRONG')
        }));
    }, [dispatch, t]);
    const fetchPreviewData = useCallback(async (itemToValidateAndCompute) => {
        setIsValidating(true);
        setItemErrors(null);
        try {
            const response = await fetchItemPreviewDisplayDataDispatch(fetchItemPreviewDisplayData(definition.definitionId, definition, itemToValidateAndCompute));
            const previousDisplayDataMap = generateDisplayDataMap(displayDataList);
            const currentDisplayDataMap = generateDisplayDataMap(response);
            const updatedItem = { ...item };
            let hasItemChanged = false;
            if (itemToValidateAndCompute == null) {
                // The user opened the drawer again and no item was passed to the backend, so the values are all null.
                // In this case we want to see which changes were done in the property definitions and clear the values of those fields because they might not be valid anymore (e.g. by toggling 'list')
                Object.keys(item).forEach((propertyId) => {
                    if (!isEqual(omit(previousDisplayDataMap[propertyId], ['labels', 'value']), omit(currentDisplayDataMap[propertyId], ['labels', 'value']))) {
                        updatedItem[propertyId] = null;
                        hasItemChanged = true;
                    }
                });
                setShowDrawer(true);
            }
            else {
                // We computed the values in the backend based on our input, by pressing on the bottom left button
                Object.keys(currentDisplayDataMap).forEach((propertyId) => {
                    if (item[propertyId] !== currentDisplayDataMap[propertyId].value) {
                        updatedItem[propertyId] = currentDisplayDataMap[propertyId].value;
                        hasItemChanged = true;
                    }
                });
            }
            if (hasItemChanged) {
                setItem(updatedItem);
            }
            setDisplayDataList(response);
            setIsValidating(false);
        }
        catch (e) {
            if (isError(e) && 'isAxiosError' in e && e.isAxiosError) {
                const err = e;
                const { response } = err;
                if (response?.data) {
                    if ('definition' in response.data && !isEmpty(response.data.definition)) {
                        onErrorDefinitionValidation(response.data.definition);
                    }
                    else if ('item' in response.data && !isEmpty(response.data.item)) {
                        setItemErrors(response.data.item);
                    }
                }
                else {
                    logError(response);
                }
            }
            setIsValidating(false);
        }
    }, [
        fetchItemPreviewDisplayDataDispatch,
        definition,
        displayDataList,
        item,
        onErrorDefinitionValidation,
        logError
    ]);
    const handleOpenDrawer = useCallback(async () => {
        await fetchPreviewData();
    }, [fetchPreviewData]);
    const handleCloseDrawer = useCallback(() => {
        setShowDrawer(false);
    }, []);
    const getSectionTitleJsx = useCallback((titles, isFirstSection) => {
        const title = getTranslation(titles, labelsLanguage);
        return !title && isFirstSection ? null : React.createElement(Divider, null, title);
    }, [labelsLanguage]);
    const firstErrorPropertyId = useMemo(() => reduce(map(compact(flatMap(displayDataList.data, 'values')), 'propertyId'), (res, propertyId) => (res == null && itemErrors?.[propertyId] ? propertyId : res), null), [itemErrors, displayDataList]);
    const isRequiredField = useCallback((field) => get(field, 'meta.validations.required', false), []);
    const hasRequiredFields = useMemo(() => reduce(flatMap(displayDataList.data, 'values'), (res, field) => isRequiredField(field) || res, false), [isRequiredField, displayDataList]);
    const getFieldLabelJsx = useCallback((field) => (React.createElement(React.Fragment, null,
        getTranslation(field.labels, labelsLanguage),
        getTranslation(get(field, 'meta.units'), labelsLanguage) &&
            ` (${getTranslation(get(field, 'meta.units'), labelsLanguage)})`,
        isRequiredField(field) && React.createElement(RequiredFieldIcon, null))), [isRequiredField, labelsLanguage]);
    const updatePropertyData = useCallback((propertyId, value) => {
        setItem(produce(item, (draft) => {
            draft[propertyId] = value;
        }));
    }, [item]);
    const fetchPreviewDataWithItem = useCallback(async () => {
        await fetchPreviewData(item);
    }, [fetchPreviewData, item]);
    const checkKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            void fetchPreviewDataWithItem();
        }
    }, [fetchPreviewDataWithItem]);
    const loadItem = useCallback(async (entityId) => {
        entityId = Array.isArray(entityId) ? entityId[0] : entityId;
        setItemErrors(null);
        if (entityId) {
            setIsLoadingSelectedItem(true);
            setLoadedItemEntityId(entityId);
            try {
                const response = await fetchItemsByIdDispatch(fetchItemsById(definition.definitionId, [entityId], {
                    responseFields: ['*']
                }));
                if (!response.results.length) {
                    throw new Error('LOADING_ITEM_FAILED');
                }
                setItem(response.results[0]);
            }
            catch (err) {
                dispatch(error({
                    title: t('ERROR'),
                    message: t('LOADING_ITEM_FAILED')
                }));
                setLoadedItemEntityId(null);
            }
            setIsLoadingSelectedItem(false);
        }
        else {
            setLoadedItemEntityId(null);
            setItem({
                [ENTITY_ID_PROPERTY_ID]: generateId()
            });
        }
    }, [definition, dispatch, fetchItemsByIdDispatch, t]);
    return (React.createElement(ErrorBoundary, null,
        React.createElement(Button, { className: styles.iconButton, inversed: true, onClick: handleOpenDrawer, disabled: disabled, loading: isValidating },
            React.createElement(Tooltip, { title: t('TRY_IT_OUT') },
                React.createElement(MdPlaylistPlay, null))),
        React.createElement(Drawer, { title: t('TEST_DATA'), onClose: handleCloseDrawer, open: showDrawer },
            React.createElement(DrawerContent, { className: styles.drawerContent, loading: isLoadingSelectedItem },
                React.createElement(DrawerContentBlock, { title: t('LABELS_LANGUAGE') },
                    React.createElement(Select, { className: "w-100", getPopupContainer: getScrollableAreaOrBody, allowClear: false, popupMatchSelectWidth: true, notFoundContent: null, value: labelsLanguage, onChange: setLabelsLanguage }, AVAILABLE_LANGUAGES.map((lang) => (React.createElement(Select.Option, { key: lang }, t(lang)))))),
                React.createElement(DrawerContentBlock, { title: t('LOAD_EXISTING_ITEM') },
                    React.createElement(EntityEditor, { value: loadedItemEntityId, onChange: loadItem, meta: { definitionId: definition.definitionId }, disabled: disabled, withoutCreateNew: true, autoFocus: false })),
                React.createElement(DrawerContentBlock, { title: React.createElement(React.Fragment, null,
                        t('CMS_ONLY_SHOW_REQUIRED'),
                        React.createElement(Toggle, { checked: onlyRequiredFields, onChange: setOnlyRequiredFields, disabled: !hasRequiredFields })) }, displayDataList.data.map(({ sectionId, titles, values }, index) => {
                    let filteredValues = hasRequiredFields && onlyRequiredFields ? values.filter(isRequiredField) : values;
                    filteredValues = filteredValues.filter(({ meta = {} }) => !meta.hidden);
                    if (isEmpty(filteredValues)) {
                        return null;
                    }
                    return (React.createElement(Fragment, { key: sectionId },
                        getSectionTitleJsx(titles, index === 0),
                        filteredValues.map((field, propertyIndex) => {
                            const { propertyId, type, meta, canEdit } = field;
                            const labelJsx = getFieldLabelJsx(field);
                            const EditorComponent = getEditorComponentForType(type);
                            const errorMessage = itemErrors?.[propertyId];
                            return (React.createElement(PropertyContainerComponent, { key: propertyId, needsScroll: propertyId === firstErrorPropertyId },
                                type === 'file' ? (React.createElement(FileField, { label: labelJsx, labelDescription: getTranslationForDescription(field.descriptions, language), files: compact(getFilePropertyValueList(item[propertyId]).map(getFileDataById)), meta: meta, error: !!errorMessage, canEdit: canEdit && !isValidating, displayActions: true, loading: false, onSave: (files) => uploadFiles(dispatch, files, `${definition.definitionId}_${propertyId}_preview`).then((data) => {
                                        updatePropertyData(propertyId, [
                                            ...(get(item, propertyId, []) || []),
                                            ...map(data, 'id')
                                        ]);
                                    }), onRemove: (id) => {
                                        const filteredFileIds = without(get(item, propertyId, []), id);
                                        updatePropertyData(propertyId, isEmpty(filteredFileIds) ? null : filteredFileIds);
                                        return Promise.resolve();
                                    }, onChange: (files) => {
                                        updatePropertyData(propertyId, map(files, 'id'));
                                    } })) : (React.createElement(React.Fragment, null,
                                    React.createElement(FieldLabel, { label: labelJsx, labelDescription: getTranslationForDescription(field.descriptions, language) }),
                                    React.createElement(EditorComponent, { onChange: (value) => updatePropertyData(propertyId, value), value: item[propertyId], meta: meta, disabled: !canEdit || isValidating, allowClear: true, entityId: item.entityId, autoFocus: index === 0 &&
                                            propertyIndex === 0 &&
                                            !['entity', 'date'].includes(type), onKeyDown: checkKeyDown, ...(type === 'entity'
                                            ? { ignoreOnEnterKeyDown: true, withoutCreateNew: true }
                                            : {}), editing: false, inlineMode: true }))),
                                errorMessage && React.createElement(ErrorMessage, null, errorMessage)));
                        })));
                }))),
            React.createElement(DrawerFooter, null,
                React.createElement(DrawerFormFooter, { onCancel: handleCloseDrawer, onSubmit: fetchPreviewDataWithItem, submitting: isValidating, submitText: t('VALIDATE_AND_COMPUTE') })))));
};
export default TestFieldsDrawerButton;
const fetchItemPreviewDisplayData = (definitionId, definition, item) => ({
    type: 'ITEM_PREVIEW_DISPLAY_DATA_FETCH',
    payload: {
        definition,
        item
    },
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${definitionId}/data/preview`,
            method: 'POST',
            allowPromiseReject: true
        }
    }
});
