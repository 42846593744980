import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Tag } from 'antd';
import cn from 'classnames';
import { isEqual, isNull, uniq, uniqWith } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { ENTITY_ID_PROPERTY_ID, TITLE_PROPERTY_ID } from '../../../../cms/common/utils/constants';
import { AggregationType } from '../../../../cms/hooks/useAggregation';
import { isTermsAggregationResultArray } from '../../../../cms/hooks/useAggregation/types';
import { MISSED_VALUE_PLACEHOLDER_STRING, useAggregation } from '../../../../cms/hooks/useAggregation/useAggregation';
import { Select } from '../../../../common/components/Select';
import { Tooltip } from '../../../../common/components/Tooltip';
import { useBrowserBreakpoints } from '../../../../common/components/with-browser-breakpoints';
import { prepareMetricGroupQuery } from '../MetricsDisplayContainer';
import { AggregationTypes } from '../types';
import styles from './GroupedMetric.module.scss';
const COUNT_TAG_DISPLAYED = 1;
const GroupedMetric = ({ definitionId, metricItem, onChangeAggregation, globalFilter }) => {
    const { t } = useI18n();
    const { id, metric, size, field, units, inPercents, aggregationId, rowGroupingOrderBy: orderBy, activeGroupingElements, rowGrouping } = metricItem;
    const [searchValue, setSearchValue] = useState('');
    const { isMobile } = useBrowserBreakpoints();
    const groupingPropertyId = useMemo(() => {
        if (!rowGrouping) {
            return;
        }
        const ids = rowGrouping.split('.');
        if (ids[1] === ENTITY_ID_PROPERTY_ID) {
            ids[1] = TITLE_PROPERTY_ID;
        }
        return ids.join('.');
    }, [rowGrouping]);
    const termAggregations = useMemo(() => {
        if (!groupingPropertyId) {
            return [];
        }
        let filter;
        if (metricItem.query) {
            filter = {
                query: metricItem.query
            };
        }
        const aggregation = {
            id: aggregationId,
            type: AggregationType.term,
            missing: MISSED_VALUE_PLACEHOLDER_STRING,
            field: groupingPropertyId,
            orderBy,
            filter,
            size
        };
        if (metric !== AggregationTypes.value_count) {
            aggregation.aggregations = [
                {
                    id,
                    field,
                    units,
                    inPercents,
                    // @ts-expect-error
                    type: metric.toUpperCase()
                }
            ];
        }
        return [aggregation];
    }, [
        aggregationId,
        field,
        groupingPropertyId,
        id,
        inPercents,
        metric,
        metricItem.query,
        orderBy,
        size,
        units
    ]);
    const aggregationDropdownFilter = useMemo(() => {
        const unSelectedGroupingElements = prepareMetricGroupQuery(rowGrouping, activeGroupingElements);
        const filtersToApply = globalFilter.query.filters.filter((group) => !isEqual(group, unSelectedGroupingElements));
        if (metricItem.query && !metricItem.selected) {
            filtersToApply.push(metricItem.query);
        }
        return {
            ...globalFilter,
            query: {
                ...globalFilter.query,
                filters: uniqWith(filtersToApply, isEqual)
            }
        };
    }, [rowGrouping, activeGroupingElements, globalFilter, metricItem.query, metricItem.selected]);
    const { isLoading, data: aggregationForDropdown } = useAggregation({
        definitionId: definitionId,
        aggregations: termAggregations,
        globalFilter: aggregationDropdownFilter
    });
    const { allAggregations, aggregationsBySearch } = useMemo(() => {
        const aggs = aggregationId && aggregationForDropdown?.aggregations?.[aggregationId];
        if (!isTermsAggregationResultArray(aggs)) {
            return { allAggregations: [], aggregationsBySearch: [] };
        }
        const normalizedAggregations = aggs.map((el) => {
            if (el.value === MISSED_VALUE_PLACEHOLDER_STRING) {
                return { ...el, label: null, value: null };
            }
            return el;
        });
        const filteredAggregations = searchValue
            ? normalizedAggregations.filter((item) => !isNull(item.label) && item.label.toLowerCase().includes(searchValue.toLowerCase()))
            : normalizedAggregations;
        return { allAggregations: normalizedAggregations, aggregationsBySearch: filteredAggregations };
    }, [aggregationForDropdown?.aggregations, aggregationId, searchValue]);
    const selectedFilteredAggregations = useMemo(() => {
        if (!aggregationsBySearch) {
            return [null];
        }
        const checkedAggregations = activeGroupingElements
            ? aggregationsBySearch.filter((aggr) => activeGroupingElements.includes(aggr.label))
            : [];
        return checkedAggregations.map((el) => el.value);
    }, [activeGroupingElements, aggregationsBySearch]);
    const selectedAggregations = useMemo(() => {
        if (!allAggregations) {
            return [null];
        }
        const checkedAggregations = activeGroupingElements
            ? allAggregations.filter((aggr) => activeGroupingElements.includes(aggr.label))
            : [];
        return checkedAggregations.map((el) => el.value);
    }, [activeGroupingElements, allAggregations]);
    const maxTagCount = useMemo(() => selectedAggregations && selectedAggregations.length !== 1
        ? selectedAggregations.length - COUNT_TAG_DISPLAYED
        : null, [selectedAggregations]);
    const isUnselectAllAvailable = useMemo(() => {
        return (!!aggregationsBySearch &&
            selectedFilteredAggregations &&
            !!selectedFilteredAggregations.length);
    }, [aggregationsBySearch, selectedFilteredAggregations]);
    const menuItems = useMemo(() => {
        if (!aggregationsBySearch?.length) {
            return;
        }
        const isCountMetric = metric === AggregationTypes.value_count;
        return aggregationsBySearch.map((el) => {
            const value = Number(isCountMetric ? el.count : el[id].value);
            const label = el.label === null ? t('EMPTY') : el.label;
            return {
                label: (React.createElement("p", { className: "d-flex justify-content-between w-100 m-0" },
                    React.createElement("p", { className: "m-0 d-flex align-items-start overflow-hidden text-nowrap" },
                        React.createElement("span", { className: "text-ellipsis label" }, label)),
                    React.createElement("p", { className: "mb-0 ms-2 count" },
                        React.createElement("span", null,
                            " ",
                            value)))),
                key: el.label,
                value: el.value
            };
        });
    }, [aggregationsBySearch, metric, id, t]);
    const handleChangeAggregation = useCallback((allSelected) => {
        if (!Array.isArray(allSelected)) {
            return;
        }
        const checkedAggregations = allAggregations?.filter((aggr) => allSelected.includes(aggr.value)).map((el) => el.label) ||
            [];
        const newMetric = {
            ...metricItem,
            selected: !!checkedAggregations.length,
            activeGroupingElements: [...checkedAggregations]
        };
        onChangeAggregation(newMetric, activeGroupingElements || []);
    }, [activeGroupingElements, allAggregations, metricItem, onChangeAggregation]);
    const handleToggleSelectAll = useCallback((isUnselectAllAvailable) => {
        let checkedAggregations = [];
        const mappedAggs = aggregationsBySearch?.map((el) => el.label) || [];
        if (!isUnselectAllAvailable) {
            checkedAggregations = uniq([...(activeGroupingElements || []), ...mappedAggs]);
        }
        else {
            checkedAggregations =
                activeGroupingElements?.filter((item) => !mappedAggs.includes(item)) || [];
        }
        const newMetric = {
            ...metricItem,
            selected: !isUnselectAllAvailable || !!checkedAggregations.length,
            activeGroupingElements: checkedAggregations
        };
        onChangeAggregation(newMetric, activeGroupingElements || []);
    }, [activeGroupingElements, aggregationsBySearch, metricItem, onChangeAggregation]);
    const tagRender = useCallback(({ label, closable, onClose }) => {
        const checkedAggregationsNew = activeGroupingElements
            ? allAggregations.filter((aggr) => activeGroupingElements.includes(aggr.label))
            : [];
        const isCountMetric = metric === AggregationTypes.value_count;
        return (React.createElement(Tag, { closable: closable, onClose: onClose, className: "ant-select-selection-item" },
            React.createElement(Tooltip, { showTooltip: !isMobile, overlayClassName: "maxTagPlaceholder", title: React.createElement(React.Fragment, null, checkedAggregationsNew && (React.createElement("ul", { className: "ps-3 mb-0", onMouseDown: (event) => event.stopPropagation() }, checkedAggregationsNew.map((item) => {
                    const value = Number(isCountMetric ? item.count : item[id].value);
                    const label = item.label === null ? t('EMPTY') : item.label;
                    return (React.createElement("li", { key: label },
                        React.createElement("div", { className: "d-flex justify-content-between w-100 m-0" },
                            React.createElement("p", { className: "m-0 d-flex align-items-start overflow-hidden text-nowrap" },
                                React.createElement("span", { className: "text-ellipsis label" }, label)),
                            React.createElement("p", { className: "mb-0 ms-2 count" },
                                React.createElement("span", null,
                                    " ",
                                    value)))));
                })))) },
                React.createElement("p", { className: "d-flex justify-content-between w-100 m-0" },
                    React.createElement("p", { className: "m-0 d-flex align-items-start overflow-hidden text-nowrap" },
                        React.createElement("span", { className: "text-ellipsis label" },
                            React.createElement(React.Fragment, null, label)),
                        maxTagCount ? (React.createElement(React.Fragment, null,
                            React.createElement("span", null, "+"),
                            React.createElement("span", null, maxTagCount))) : null)))));
    }, [activeGroupingElements, allAggregations, id, isMobile, maxTagCount, metric, t]);
    const AdditionalDropdownComponent = useCallback(() => {
        if (!aggregationsBySearch?.length) {
            return null;
        }
        const valueToggleSelectAll = isUnselectAllAvailable
            ? `${t('UNSELECT_INPUT')} ${t('CONDITIONS_ALL')}`
            : `${t('SELECT_INPUT')} ${t('CONDITIONS_ALL')}`;
        return (React.createElement("div", { className: "toggle-selectAll" },
            React.createElement(Button, { disabled: isLoading, className: "text-primary cursor-pointer d-flex m-auto py-2 h-auto w-100 justify-content-center", type: "link", onClick: () => handleToggleSelectAll(isUnselectAllAvailable) }, valueToggleSelectAll)));
    }, [t, isUnselectAllAvailable, aggregationsBySearch?.length, isLoading, handleToggleSelectAll]);
    const NoResultsFound = useMemo(() => {
        return (React.createElement("div", { className: "d-flex m-auto py-2 h-auto w-100 justify-content-center no-results" }, "No results found"));
    }, []);
    return (React.createElement("div", { className: cn('cursor-pointer col', styles.metricsWrp, { 'available-selected-items': !!selectedAggregations.length }, 'GroupedMetricOuter'), onClick: (event) => event.stopPropagation() },
        React.createElement(Select, { notFoundContent: NoResultsFound, additionalDropdownComponent: AdditionalDropdownComponent, popupClassName: cn(styles.metricsDropdownPopup), checkmarks: true, popupMatchSelectWidth: false, loading: isLoading, searchValue: searchValue, tagRender: tagRender, placement: "bottomRight", onSearchDropdown: (value) => {
                setSearchValue(value);
            }, maxTagPlaceholder: () => null, value: selectedAggregations, filterOption: false, onChange: (allSelected) => {
                handleChangeAggregation(allSelected);
            }, options: menuItems, mode: "multiple", maxTagCount: COUNT_TAG_DISPLAYED })));
};
export default GroupedMetric;
