import { Popover } from 'antd';
import cn from 'classnames';
import React, { useMemo } from 'react';
import { normalizeRichTextValue } from '~app/cms/data/components/item-property/editors/RichTextEditor';
import RichTextDisplay from '../../../cms/data/components/item-property/display/RichTextDisplay';
import { TooltipTruncate } from '../TooltipTruncate';
import styles from './PropertyLabel.module.scss';
const MOUSE_EVENT_DELAY = 0.5;
const PropertyLabel = ({ labelDescription, label, labelClassName }) => {
    const normalizedLabelDescription = useMemo(() => (labelDescription ? normalizeRichTextValue(labelDescription) : null), [labelDescription]);
    return (React.createElement("div", { className: "d-flex align-items-center justify-content-start mw-100 overflow-hidden" }, !normalizedLabelDescription ? (React.createElement(TooltipTruncate, { className: cn(labelClassName, 'label-text') }, label)) : (React.createElement(Popover, { title: label, mouseEnterDelay: MOUSE_EVENT_DELAY, mouseLeaveDelay: MOUSE_EVENT_DELAY, content: React.createElement(RichTextDisplay, { value: normalizedLabelDescription, asField: false }), rootClassName: styles.popover },
        React.createElement(TooltipTruncate, { className: cn(styles.dashLine, labelClassName, 'label-text cursor-help'), disabledTooltip: true }, label)))));
};
export default PropertyLabel;
