import { Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import ComputedExpressionEditor from './ComputedExpressionEditor';
const ComputedModal = ({ open, onCancel, onOk, value, propertiesTree }) => {
    const [tmpValue, setTmpValue] = useState(value);
    const onEditorOk = useCallback(() => {
        onOk(tmpValue);
    }, [onOk, tmpValue]);
    const onEditorCancel = useCallback(() => {
        setTmpValue(value);
        onCancel();
    }, [onCancel, value]);
    useEffect(() => {
        setTmpValue(value);
    }, [value]);
    return (React.createElement(Modal, { destroyOnClose: true, title: "Computed expression editor", centered: true, open: open, onOk: onEditorOk, onCancel: onEditorCancel, width: "70%", getContainer: () => document.body },
        React.createElement(ComputedExpressionEditor, { focus: true, value: tmpValue?.toString(), height: "300px", propertiesTree: propertiesTree, onChange: setTmpValue, debounceChangePeriod: 200 })));
};
export default ComputedModal;
