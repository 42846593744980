import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import { filter, isEmpty, sortBy, uniq } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { ENTITY_ID_PROPERTY_ID, TITLE_PROPERTY_ID } from '~app/cms';
import { useSearchItems } from '~app/cms/hooks/useSearchItems';
import { Select } from '~app/common/components/Select';
import { getColumnDefinitionIdKanbanGroupBy, initialFilterRequest } from '~app/views';
import { SortableKanbanColumnList } from '../SortableKanbanColumnList';
import styles from './KanbanColumnsField.module.scss';
export const KanbanColumnsField = ({ customAvailableColumns, customSelectedColumns, displayData, groupBy, onChangeCustomSearch, input: { value: inputValue = [], onChange } }) => {
    const { t } = useI18n();
    const [prevGroupBy, setPrevGroupBy] = useState();
    const columnDefinitionIdKanbanGroupBy = useMemo(() => getColumnDefinitionIdKanbanGroupBy(displayData, groupBy), [displayData, groupBy]);
    const [searchValue, setSearchValue] = useState('');
    const onSearch = useCallback((searchValue) => {
        setSearchValue(searchValue);
        onChangeCustomSearch?.(searchValue);
    }, [onChangeCustomSearch]);
    const [debouncedSearchValue] = useDebounce(searchValue, 300);
    const filterRequestAvailableColumns = useMemo(() => {
        const searchFilter = (!!debouncedSearchValue && [
            {
                field: TITLE_PROPERTY_ID,
                operator: FilterOperatorTypes.contains,
                data: debouncedSearchValue
            }
        ]) ||
            [];
        const filtersWithoutSelectedColumns = (inputValue || []).map((item) => ({
            field: ENTITY_ID_PROPERTY_ID,
            operator: FilterOperatorTypes.isNot,
            data: item
        }));
        return {
            ...initialFilterRequest,
            query: {
                ...initialFilterRequest.query,
                filters: [...filtersWithoutSelectedColumns, ...searchFilter]
            }
        };
    }, [debouncedSearchValue, inputValue]);
    const { data: { results: availableColumns = [] } = {}, isPending: isPendingAvailableColumns } = useSearchItems({
        definitionId: columnDefinitionIdKanbanGroupBy || '',
        filterRequest: filterRequestAvailableColumns
    }, {
        enabled: !!columnDefinitionIdKanbanGroupBy && !customAvailableColumns
    });
    const computedAvailableColumns = useMemo(() => customAvailableColumns ||
        availableColumns.map(({ entityId, title }) => ({
            value: entityId,
            label: title || entityId
        })), [availableColumns, customAvailableColumns]);
    useEffect(() => {
        if (!prevGroupBy || prevGroupBy !== groupBy) {
            if (!isEmpty(computedAvailableColumns) && isEmpty(inputValue)) {
                onChange(computedAvailableColumns.map(({ value }) => value));
                setPrevGroupBy(groupBy);
            }
        }
    }, [computedAvailableColumns, groupBy, inputValue, onChange, prevGroupBy]);
    if (!groupBy) {
        return React.createElement(React.Fragment, null, "Field `Group Id` not checked");
    }
    return (React.createElement("div", { className: "d-flex flex-column row-gap-3" },
        React.createElement(SortableKanbanColumnList, { definitionId: columnDefinitionIdKanbanGroupBy, selection: inputValue || [], customSelectedColumns: customSelectedColumns, handleChange: (newOrder) => {
                if (newOrder.length === inputValue.length) {
                    onChange(sortBy(inputValue, (item) => newOrder.indexOf(item)));
                }
                else {
                    onChange(filter(inputValue, (item) => newOrder.includes(item)));
                }
            } }),
        React.createElement(Select, { value: null, onChange: (option) => onChange(uniq([...inputValue, option])), searchValue: searchValue, onSearch: onSearch, options: computedAvailableColumns, loading: isPendingAvailableColumns, className: styles.columnsSelect, placeholder: React.createElement(React.Fragment, null, `+ ${t('VIEWS__KANBAN_COLUMNS_PLACEHOLDER')}`), filterOption: false, disabled: inputValue?.length >= 20, allowClear: false, showSearch: true })));
};
