import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useMemo } from 'react';
import { FaEye } from 'react-icons/fa';
import { IoSettingsOutline } from 'react-icons/io5';
import DataTable2Container from '../../../../../common/components/data-table/DataTable2Container';
import { ActionWhen } from '../../../../../common/components/data-table/types';
import { APP_ADMIN_PERMISSION, SETTINGS_PATH } from '../../../../../common/utils/constants';
import { ENTITY_ID_PROPERTY_ID } from '../../../../common/utils/constants';
import { EditLink } from '../utils';
const ItemsTable = ({ title, tableTitle, editBaseUrl, className, baseUrl, contentHeight, definitionId, showSettings = true, showViewsSelector = true, noFilters, actions }) => {
    const { t } = useI18n();
    const columnRenderer = useCallback(({ row }, data, filterRequest, isTaskDefinition) => (React.createElement(EditLink, { className: "safe-space", to: {
            pathname: `${editBaseUrl}/${row.original.entityId}`,
            state: {
                data,
                filterRequest,
                definitionId,
                isTaskDefinition
            }
        }, title: t('OPEN_ITEM') },
        React.createElement(FaEye, null))), [definitionId, editBaseUrl, t]);
    const actionButtons = useMemo(() => {
        const buttons = [];
        if (actions?.length) {
            buttons.push(...actions);
        }
        if (showSettings) {
            buttons.push({
                key: 'settings',
                when: ActionWhen.noSelected,
                buttonContent: React.createElement(IoSettingsOutline, { preserveAspectRatio: "none" }),
                buttonProps: {
                    inversed: true,
                    className: 'SettingsButton',
                    to: `${baseUrl}${SETTINGS_PATH}`
                },
                permission: APP_ADMIN_PERMISSION
            });
        }
        return buttons;
    }, [actions, showSettings, baseUrl]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${className || ''}` },
            title && title,
            React.createElement(DataTable2Container, { title: tableTitle, idField: ENTITY_ID_PROPERTY_ID, columnRenderer: columnRenderer, actions: actionButtons, withCmsImport: true, contentHeight: contentHeight, showViewsSelector: showViewsSelector, baseUrl: baseUrl, noFilters: noFilters }))));
};
export default ItemsTable;
