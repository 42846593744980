import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { ReadableDateTime, formatDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { Steps, Tooltip } from 'antd';
import { isArray } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { BsArrowsCollapse, BsArrowsExpand, BsCardChecklist, BsInboxFill } from 'react-icons/bs';
import { IoIosCheckmarkCircleOutline, IoMdAlarm, IoMdCloseCircleOutline, IoMdPower, IoMdSync } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { AvailableTasksStepDescription, DoneTasksStepDescription, FinalStatusStepDescription, LoadingBar, OngoingTasksStepDescription, ProcessStartedStepDescription, StepTitle } from '../..';
import { hasTenantPermission } from '../../../../../../../auth';
import { PROCESS_RESTART_TASKS_PERMISSION } from '../../../../../../../common/utils/constants';
import { isAxiosError } from '../../../../../../../common/utils/request';
import { PROCESS_DEADLINE_PROPERTY_ID } from '../../../../../../common/utils/constants';
import { ProcessStatus, TaskStatus } from '../../../../../../common/utils/types';
import { restartTaskRun } from '../../../../../actions';
import DeadlineEditor from '../../../DeadlineEditor';
import styles from './StepsOverview.module.scss';
const initOpenedCollapse = {
    availableTasks: [],
    ongoingTasks: [],
    doneTasks: [],
    failedTasks: [],
    restartedTasks: [],
    canceledTasks: []
};
const StepsOverview = ({ className, processDetails, tasks, loading }) => {
    const { t } = useI18n();
    const canRestartTasks = useSelector((s) => hasTenantPermission(s, PROCESS_RESTART_TASKS_PERMISSION));
    const dispatch = useDispatch();
    const dispatchRestartTaskRun = useDispatch();
    const [openedCollapse, setOpenedCollapse] = useState(initOpenedCollapse);
    const handleOpenCollapse = (key) => {
        return (value) => {
            setOpenedCollapse((prevState) => ({ ...prevState, [key]: isArray(value) ? value : [value] }));
        };
    };
    const onClickRestartTask = useCallback(async (taskRunId) => {
        const task = tasks.find((item) => item.taskRunId === taskRunId);
        if (!task) {
            return;
        }
        try {
            await dispatchRestartTaskRun(restartTaskRun(task.processMetaId, task.processRunId, task.taskMetaId, task.taskRunId));
            dispatch(success({
                title: t('SUCCESS'),
                message: t('RESTART_TASK_REQUEST_SENDED')
            }));
        }
        catch (err) {
            if (isAxiosError(err)) {
                dispatch(error({
                    title: t('ERROR'),
                    message: err.response?.data?.message ?? err.message
                }));
            }
            else {
                console.error('onClickRestartTask failed', task, err);
            }
        }
    }, [dispatch, dispatchRestartTaskRun, t, tasks]);
    const groupedTasks = useMemo(() => {
        const result = tasks.reduce((acc, task) => {
            if (task.status === TaskStatus.WAITING) {
                acc.availableTasks.push(task);
            }
            else if (task.status === TaskStatus.RUNNING) {
                acc.ongoingTasks.push(task);
            }
            else if (task.status === TaskStatus.FAILED) {
                acc.failedTasks.push(task);
            }
            else if (task.status === TaskStatus.CANCELLED) {
                acc.canceledTasks.push(task);
            }
            else if (task.restartedBy) {
                acc.restartedTasks.push(task);
            }
            else if (task.status === TaskStatus.SUCCEEDED) {
                acc.doneTasks.push(task);
            }
            return acc;
        }, {
            availableTasks: [],
            ongoingTasks: [],
            doneTasks: [],
            failedTasks: [],
            restartedTasks: [],
            canceledTasks: []
        });
        return result;
    }, [tasks]);
    const onClickExpandAll = useCallback(() => {
        setOpenedCollapse(Object.entries(groupedTasks).reduce((acc, [key, tasks]) => ({
            ...acc,
            [key]: tasks.map((task) => task.taskRunId)
        }), initOpenedCollapse));
    }, [groupedTasks]);
    const onClickCollapseAll = useCallback(() => {
        setOpenedCollapse(initOpenedCollapse);
    }, []);
    const emphasizeOverdue = useMemo(() => !!processDetails && !['CANCELLED', 'SUCCEEDED', 'FAILED'].includes(processDetails.status), [processDetails]);
    const isAllowRestartTask = canRestartTasks &&
        (processDetails?.status === ProcessStatus.FAILED ||
            processDetails?.status === ProcessStatus.RUNNING);
    return (React.createElement("div", { className: "p-2" },
        React.createElement("div", { className: "d-flex flex-row align-self-center right-buttons position-absolute end-0 z-1 pe-2" },
            React.createElement(Tooltip, { title: t('ACTIVITY_EXPAND_BTN_TOOLTIP') },
                React.createElement(Button, { type: "button", className: "me-2 action-button p-2", inversed: true, onClick: onClickExpandAll },
                    React.createElement(BsArrowsExpand, null))),
            React.createElement(Tooltip, { title: t('ACTIVITY_COLLAPSE_BTN_TOOLTIP') },
                React.createElement(Button, { type: "button", className: "action-button p-2", inversed: true, onClick: onClickCollapseAll },
                    React.createElement(BsArrowsCollapse, null)))),
        React.createElement(Steps, { className: `${styles.stepsOuter} ${className || ''}`, direction: "vertical" },
            React.createElement(Steps.Step, { status: "finish", title: React.createElement(StepTitle, { title: "PROCESS_STARTED" }), description: React.createElement(ProcessStartedStepDescription, { processDetails: processDetails }), icon: React.createElement("div", { className: `${styles.stepIconWrapper}` },
                    React.createElement(IoMdPower, { className: `${styles.stepIcon}` })) }),
            React.createElement(Steps.Step, { status: "finish", title: React.createElement(StepTitle, { title: "AVAILABLE_TASKS" }), description: React.createElement(AvailableTasksStepDescription, { availableTasks: groupedTasks.availableTasks, loading: loading, activeKeys: openedCollapse.availableTasks, handlePanelChange: handleOpenCollapse('availableTasks') }), icon: React.createElement("div", { className: `${styles.stepIconWrapper}` },
                    React.createElement(BsInboxFill, { className: `${styles.stepIcon}` })) }),
            React.createElement(Steps.Step, { status: "finish", title: React.createElement(StepTitle, { title: "ONGOING_TASKS" }), description: React.createElement(OngoingTasksStepDescription, { ongoingTasks: groupedTasks.ongoingTasks, loading: loading, activeKeys: openedCollapse.ongoingTasks, handlePanelChange: handleOpenCollapse('ongoingTasks') }), icon: React.createElement("div", { className: `${styles.stepIconWrapper}` },
                    React.createElement(IoMdSync, { className: `${styles.stepIcon} ${styles.syncIcon}` })) }),
            React.createElement(Steps.Step, { status: "finish", title: React.createElement(StepTitle, { title: "DONE_TASKS" }), icon: React.createElement("div", { className: `${styles.stepIconWrapper}` },
                    React.createElement(BsCardChecklist, { className: `${styles.stepIcon}` })), description: React.createElement(DoneTasksStepDescription, { tasks: groupedTasks.doneTasks, loading: loading, activeKeys: openedCollapse.doneTasks, onPanelChange: handleOpenCollapse('doneTasks'), onClickRestartTask: isAllowRestartTask ? onClickRestartTask : undefined }) }),
            !loading && processDetails?.status === 'SUCCEEDED' && (React.createElement(Steps.Step, { status: "finish", title: React.createElement(StepTitle, { title: "COMPLETED", completed: true }), description: React.createElement("div", { className: "d-flex align-items-center" },
                    React.createElement(Tooltip, { title: formatDateTime(processDetails.statusUpdatedAt) },
                        React.createElement("div", null,
                            React.createElement(ReadableDateTime, { dateTime: processDetails.statusUpdatedAt })))), icon: React.createElement("div", { className: `${styles.stepIconWrapper} ${styles.stepIconWrapper__completed}` },
                    React.createElement(IoIosCheckmarkCircleOutline, { className: `${styles.stepIcon} ${styles.stepIcon__completed}` })) })),
            processDetails && groupedTasks.failedTasks.length && (React.createElement(Steps.Step, { status: "error", title: t('FAILED'), description: React.createElement(FinalStatusStepDescription, { processDetails: processDetails, tasks: groupedTasks.failedTasks, activeKeys: openedCollapse.failedTasks, onPanelChange: handleOpenCollapse('failedTasks'), onClickRestartTask: isAllowRestartTask ? onClickRestartTask : undefined }), icon: React.createElement("div", { className: `${styles.stepIconWrapper}` },
                    React.createElement(IoMdCloseCircleOutline, { className: `${styles.stepIcon}` })) })),
            processDetails && groupedTasks.restartedTasks.length && (React.createElement(Steps.Step, { status: "finish", title: t('RESTARTED_TASKS'), description: React.createElement(DoneTasksStepDescription, { tasks: groupedTasks.restartedTasks, loading: loading, activeKeys: openedCollapse.restartedTasks, onPanelChange: handleOpenCollapse('restartedTasks'), onClickRestartTask: isAllowRestartTask ? onClickRestartTask : undefined }), icon: React.createElement("div", { className: `${styles.stepIconWrapper}` },
                    React.createElement(BsCardChecklist, { className: `${styles.stepIcon}` })) })),
            processDetails && groupedTasks.canceledTasks.length && (React.createElement(Steps.Step, { status: "error", title: t('CANCELLED'), description: React.createElement(FinalStatusStepDescription, { processDetails: processDetails, tasks: groupedTasks.canceledTasks, activeKeys: openedCollapse.canceledTasks, onPanelChange: handleOpenCollapse('canceledTasks') }), icon: React.createElement("div", { className: `${styles.stepIconWrapper}` },
                    React.createElement(IoMdCloseCircleOutline, { className: `${styles.stepIcon}` })) })),
            React.createElement(Steps.Step, { status: emphasizeOverdue &&
                    processDetails?.deadline &&
                    new Date(processDetails.deadline).getTime() < Date.now()
                    ? 'error'
                    : 'finish', title: t('DEADLINE'), description: React.createElement("div", { className: "deadline-description" }, processDetails ? (React.createElement(DeadlineEditor, { emphasizeOverdue: emphasizeOverdue, definitionId: processDetails.processMetaId, entityId: processDetails.processRunId, propertyId: PROCESS_DEADLINE_PROPERTY_ID, canEdit: processDetails.canExecute, value: processDetails.deadline || null })) : (React.createElement(LoadingBar, null))), icon: React.createElement("div", { className: `${styles.stepIconWrapper}` },
                    React.createElement(IoMdAlarm, { className: `${styles.stepIcon}` })) }))));
};
export default StepsOverview;
