import DynamicImport from '@hypercharge/hyper-react-base/lib/common/dynamic-import';
import { email, required } from '@hypercharge/hyper-react-base/lib/form';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { getTranslationForDescription } from '@hypercharge/hyper-react-base/lib/i18n/i18nUtils';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { Collapse } from 'antd';
import { Formik } from 'formik';
import { compact, flatMap, get, isEmpty, isEqual, isString, map, reduce } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import ConfirmationButtonModal from '../../../../../common/components/ConfirmationButtonModal';
import { Drawer, DrawerContent, DrawerFooter } from '../../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../../../common/components/Drawer/DrawerFormFooter';
import { PropertyLabel } from '../../../../../common/components/PropertyLabel';
import Toggle from '../../../../../common/components/Toggle';
import Message from '../../../../../common/conversation/components/Message/Message';
import { FileField } from '../../../../../common/storage';
import { PropertyTypes } from '../../../../../common/types';
import RequiredFieldInfoPopover from '../../../../../workflows/processes/components/process/RequiredFieldInfoPopover';
import useAddItem from '../../../hooks/useAddItem';
import { isNumberMeta, isPropertyItemMeta } from '../../../types';
import { FieldLabel } from '../../item-property/FieldElements';
import { RichTextEditorWithWrapper } from '../../item-property/editors/RichTextEditor';
import { getEditorComponentForType, getFilePropertyValueList } from '../../item-property/utils';
import styles from './QuickAddItem.module.scss';
import { getFilterRequestWithContextFromComputedFilters } from './utils';
const confirmModal = ConfirmationButtonModal.confirm;
const FieldLabelWrapper = ({ field, language, aggregatedRequiredFieldsConfig }) => {
    const unitsTranslation = getTranslation(isNumberMeta(field?.meta) ? field.meta.units : {}, language);
    const label = getTranslation(field.labels, language);
    const labelDescription = getTranslationForDescription(field.descriptions, language);
    return (React.createElement("div", { className: "d-flex align-items-center" },
        React.createElement(PropertyLabel, { label: label, labelDescription: labelDescription }),
        unitsTranslation && ` (${unitsTranslation})`,
        aggregatedRequiredFieldsConfig.isRequiredField(field.propertyId) && (React.createElement(RequiredFieldInfoPopover, { propertyId: field.propertyId, requiredFieldsConfig: aggregatedRequiredFieldsConfig }))));
};
const QuickAddItem = ({ onCancel, requiredFieldsConfig, isValidPhoneNumber, definitionId, clonedItemEntityId, onFinish, drawerTitle, isDrawerOpen }) => {
    const { t, language } = useI18n();
    const { saveComment, createItem, initialItem, propertySections, getFileData, uploadFiles, accountId, displayDataStatus } = useAddItem({ definitionId, clonedItemEntityId });
    const [initialValues, setInitialValues] = useState();
    const [comment, setComment] = useState({
        content: '',
        mentions: []
    });
    const [onlyRequiredFields, setOnlyRequiredFields] = useState(false);
    useEffect(() => {
        if (isDrawerOpen) {
            setInitialValues({ entityId: generateId(), ...initialItem });
        }
        // ! Don't add `initialItem` to the dependency list,
        // ! because the `cloned` item shouldn't be updated after the drawer is opened.
        // ! Even if the original item has changed.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDrawerOpen]);
    const _onCancel = useCallback((formValues, initialValues, resetForm) => {
        if (!isEqual(formValues, initialValues)) {
            confirmModal({
                title: t('UNSAVED_DATA_TITLE'),
                content: t('UNSAVED_DATA_MESSAGE'),
                okText: t('COMMON__LEAVE'),
                cancelText: t('COMMON_CANCEL'),
                onOk: () => {
                    onCancel();
                    resetForm();
                    setInitialValues(undefined);
                }
            }, t);
        }
        else {
            onCancel();
            resetForm();
            setInitialValues(undefined);
        }
    }, [onCancel, t]);
    const firstErrorPropertyId = useCallback((errors) => {
        const propertyIds = propertySections
            .flatMap(({ values }) => values)
            .filter(Boolean)
            .map(({ propertyId }) => propertyId);
        return propertyIds.reduce((acc, propertyId) => (acc === null && errors[propertyId] ? propertyId : acc), null);
    }, [propertySections]);
    const hasRequiredFieldsByCmsSettings = useMemo(() => reduce(flatMap(propertySections, 'values'), (res, field) => get(field, 'meta.validations.required', false) || res, false), [propertySections]);
    const hasRequiredFields = hasRequiredFieldsByCmsSettings || get(requiredFieldsConfig, 'hasRequiredFields', false);
    const onValidate = useCallback((formValues) => {
        const newErrors = {};
        propertySections.forEach(({ values }) => {
            values.forEach(({ type, propertyId, meta }) => {
                const propertyValue = formValues[propertyId];
                const isRequiredByCmsSettings = meta?.validations?.required || false;
                const isRequiredProperty = isRequiredByCmsSettings ||
                    (requiredFieldsConfig != null && requiredFieldsConfig.isRequiredField(propertyId));
                if (isRequiredProperty) {
                    const requiredMsg = required(propertyValue);
                    if (requiredMsg) {
                        newErrors[propertyId] = requiredMsg;
                    }
                }
                // Would be good to have a more generic way of doing this
                let error = null;
                if (type == 'email') {
                    error = email(propertyValue);
                }
                else if (type == 'phoneNumber' && propertyValue) {
                    error =
                        (!isValidPhoneNumber(propertyValue) && t('VALIDATIONS__INVALID_PHONE_NUMBER')) ||
                            null;
                }
                if (error) {
                    newErrors[propertyId] = error;
                }
            });
        });
        return newErrors;
    }, [propertySections, requiredFieldsConfig, isValidPhoneNumber, t]);
    const updateComment = useCallback((content, mentions) => setComment({ content, mentions }), []);
    const handleSubmit = useCallback(async (formValues, { resetForm }) => {
        const returnedItem = await createItem(formValues);
        const { content, mentions } = comment;
        if (content) {
            await saveComment(returnedItem.entityId, content, mentions, accountId);
            onFinish(returnedItem.entityId);
        }
        else {
            onFinish(returnedItem.entityId);
        }
        resetForm();
        setInitialValues(undefined);
    }, [createItem, comment, saveComment, accountId, onFinish]);
    const getSectionTitle = useCallback((titles, isFirstSection) => {
        const title = getTranslation(titles, language);
        if (!title && isFirstSection) {
            return null;
        }
        return title;
    }, [language]);
    const getAggregatedRequiredFieldsConfig = useCallback((field, errors, values) => ({
        // This combines the 'required' information of the CMS entity property settings with the passed requiredFieldsConfig argument, that comes from the process page
        isRequiredField: (propertyId) => !!field?.meta.validations?.required ||
            (requiredFieldsConfig != null && requiredFieldsConfig.isRequiredField(propertyId)),
        isErrorField: (propertyId) => !!errors[propertyId],
        isSuccessField: (propertyId) => propertyId in values && !errors[propertyId]
    }), [requiredFieldsConfig]);
    return !initialValues ? null : (React.createElement(Formik, { onSubmit: handleSubmit, enableReinitialize: true, validateOnChange: false, validateOnBlur: false, validate: onValidate, initialValues: initialValues }, ({ errors, isSubmitting, values, initialValues, setFieldValue, submitForm, resetForm }) => (React.createElement(Drawer, { title: drawerTitle, onClose: () => _onCancel(values, initialValues, resetForm), open: isDrawerOpen, destroyOnClose: true },
        React.createElement(DrawerContent, { className: styles.container, loading: displayDataStatus.isPending && isEmpty(propertySections) },
            React.createElement(DrawerContentBlock, null,
                React.createElement(Toggle, { checked: onlyRequiredFields, onChange: (value) => setOnlyRequiredFields(value), disabled: !hasRequiredFields, label: t('CMS_ONLY_SHOW_REQUIRED') })),
            propertySections.map(({ sectionId, titles, values: sectionValue }, index) => {
                const filteredValues = hasRequiredFields && onlyRequiredFields
                    ? sectionValue.filter((field) => {
                        const aggregatedRequiredFieldsConfig = getAggregatedRequiredFieldsConfig(field, errors, values);
                        return aggregatedRequiredFieldsConfig.isRequiredField(field.propertyId);
                    })
                    : sectionValue;
                const sectionTitle = getSectionTitle(titles, index === 0);
                if (isEmpty(filteredValues)) {
                    return null;
                }
                return (React.createElement(DrawerContentBlock, { key: sectionId, title: sectionTitle }, filteredValues.map((field, propertyIndex) => {
                    const { propertyId, type, meta } = field;
                    const EditorComponent = type === PropertyTypes.richtext
                        ? RichTextEditorWithWrapper
                        : getEditorComponentForType(type);
                    const isTouchDevice = 'ontouchstart' in document.documentElement;
                    const errorMessage = errors[propertyId];
                    return (React.createElement(PropertyContainerComponent, { key: propertyId, needsScroll: propertyId === firstErrorPropertyId(errors) }, type === 'file' ? (React.createElement(FileField, { label: React.createElement(FieldLabelWrapper, { field: field, language: language, aggregatedRequiredFieldsConfig: getAggregatedRequiredFieldsConfig(field, errors, values) }), files: compact(getFilePropertyValueList(values[propertyId]).map(getFileData)), meta: meta, error: !!errorMessage, canEdit: !isSubmitting, displayActions: true, loading: false, onSave: async (files) => {
                            const data = await uploadFiles(values.entityId, propertyId, files);
                            await setFieldValue(propertyId, [
                                ...((meta.list && values[propertyId]) || []),
                                ...map(data, 'id')
                            ]);
                        }, onRemove: (id) => {
                            const propertyValue = values[propertyId];
                            const idsList = Array.isArray(propertyValue) && propertyValue.every(isString)
                                ? propertyValue
                                : [];
                            const filteredFileIds = idsList.filter((propertValue) => propertValue !== id);
                            return setFieldValue(propertyId, isEmpty(filteredFileIds) ? null : filteredFileIds);
                        }, onChange: (files) => {
                            void setFieldValue(propertyId, map(files, 'id'));
                        } })) : (React.createElement(React.Fragment, null,
                        React.createElement(FieldLabel, { label: React.createElement(FieldLabelWrapper, { field: field, language: language, aggregatedRequiredFieldsConfig: getAggregatedRequiredFieldsConfig(field, errors, values) }) }),
                        React.createElement(EditorComponent, { onChange: (value) => void setFieldValue(propertyId, value), value: values[propertyId], meta: meta, disabled: isSubmitting, 
                            // @ts-expect-error `rich text with wrapper` component don't have this prop
                            name: propertyId, allowClear: true, label: type === PropertyTypes.richtext ? (React.createElement(FieldLabelWrapper, { field: field, language: language, aggregatedRequiredFieldsConfig: getAggregatedRequiredFieldsConfig(field, errors, values) })) : null, inlineMode: true, editing: false, filterRequest: isPropertyItemMeta(meta)
                                ? getFilterRequestWithContextFromComputedFilters(meta?.selector?.filtering?.filterRequest, values, definitionId)
                                : undefined, entityId: values.entityId, autoFocus: index === 0 &&
                                propertyIndex === 0 &&
                                ![PropertyTypes.entity, PropertyTypes.date].includes(type), isTouchDevice: isTouchDevice, onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                    void submitForm();
                                }
                            }, errorMessage: errorMessage, ...(type === PropertyTypes.entity
                                ? { ignoreOnEnterKeyDown: true }
                                : {}) })))));
                })));
            }),
            React.createElement(DrawerContentBlock, null,
                React.createElement(Collapse, { className: styles.collapse, bordered: false, defaultActiveKey: ['COMMENTS'], items: [
                        {
                            key: 'COMMENTS',
                            label: React.createElement("div", { className: "panel-header-title" }, t('COMMENTS')),
                            children: (React.createElement(Message, { contactId: accountId, disabled: isSubmitting, editable: true, placeholder: t('COMMENTS_PLACEHOLDER'), collapsable: true, mainInput: true, onChange: updateComment, hideFooter: true }))
                        }
                    ] }))),
        React.createElement(DrawerFooter, null,
            React.createElement(DrawerFormFooter, { onSubmit: submitForm, onCancel: () => _onCancel(values, initialValues, resetForm), submitting: isSubmitting }))))));
};
const QuickAddItemWrapper = (props) => (React.createElement(DynamicImport, { load: () => import('react-phone-number-input/min'), loadAllExports: true }, (module) => (React.createElement(QuickAddItem, { ...props, isValidPhoneNumber: module.isValidPhoneNumber }))));
export default QuickAddItemWrapper;
export const PropertyContainerComponent = ({ needsScroll, children }) => {
    const containerRef = useRef(null);
    useLayoutEffect(() => {
        if (needsScroll) {
            containerRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [needsScroll]);
    return (React.createElement("div", { className: styles.propertyContainer, ref: containerRef }, children));
};
