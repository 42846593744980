import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback } from 'react';
import { IoMdClose } from 'react-icons/io';
import { arrayMove } from 'react-sortable-hoc';
import Toggle from '../../../../../../common/components/Toggle';
import SortableList from '../../SortableEntities/SortableList';
import { getHighlightedItemId, getItemId, isProperties } from '../utils';
const HighlightedListField = ({ input, dataById }) => {
    const { t } = useI18n();
    const noItemsLabel = isProperties(dataById)
        ? t('HIGHLIGHTED_PROPERTIES_NO_ITEMS')
        : t('HIGHLIGHTED_REFERRALS_NO_ITEMS');
    const ActionsSortableItem = useCallback(({ item, selectedItem }) => {
        const onRemove = (id) => input.onChange(input.value.filter((item) => getHighlightedItemId(item) !== id));
        const onActiveChange = (id, active) => input.onChange(input.value.map((item) => getHighlightedItemId(item) === id ? { ...item, active } : item));
        return (React.createElement(React.Fragment, null,
            React.createElement(IoMdClose, { className: "me-1 ms-auto remove-btn", onClick: () => onRemove(getItemId(item)) }),
            React.createElement(Toggle, { className: "switcher", onChange: (value) => onActiveChange(getItemId(item), value), checked: selectedItem.active })));
    }, [input]);
    return (React.createElement(SortableList, { onSortEnd: ({ oldIndex, newIndex }) => input.onChange(arrayMove(input.value, oldIndex, newIndex)), useDragHandle: true, selectedItems: input.value, dataById: dataById, noItemsLabel: noItemsLabel, actionsSortableItemComponent: ActionsSortableItem, getItemId: getHighlightedItemId }));
};
export default HighlightedListField;
