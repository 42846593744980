import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType } from '@hypercharge/portal-utils';
import { Badge, Button } from 'antd';
import { isString } from 'lodash';
import queryString from 'query-string';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaHistory, FaRegCommentDots, FaRegStar, FaTasks } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ACTIVITY_DEFINITION_ID, useActivity } from '../../../../../common/activity';
import ActivityContainer from '../../../../../common/activity/components/ActivityContainer';
import ConversationContainer from '../../../../../common/conversation/components/ConversationContainer';
import { getList as getMessages } from '../../../../../common/conversation/reducer';
import { getMessagesState } from '../../../../../common/reducers/rootSelector';
import { AvailableHandlers } from '../../../../../common/types';
import { getOrderedHighlightedProperties, getTabState, saveTabState } from '../../../../../common/utils/common';
import { ArrowIcon } from '../../../../../icons';
import { CMS_ITEM_CONVERSATION_NAMESPACE } from '../../../../common/utils/constants';
import useDisplayData from '../../../../hooks/useDisplayData';
import useDisplayItemMeta from '../../../../hooks/useDisplayItemMeta';
import { DataHistoryList } from '../../item-property/DataHistoryList';
import DataListTabs from '../../item-property/DataList/DataListTabs';
import { HighlightedPropertiesList } from '../HighlightedPropertiesList';
import styles from './InfoPanel.module.scss';
export const ACTIVE_TAB_RIGHT_PANEL_KEY = 'activeTab';
export var AvailableTabs;
(function (AvailableTabs) {
    AvailableTabs["ACTIVITY"] = "ACTIVITY";
    AvailableTabs["COMMENTS"] = "COMMENTS";
    AvailableTabs["HIGHLIGHTED"] = "HIGHLIGHTED";
    AvailableTabs["DATA_HISTORY"] = "DATA_HISTORY";
})(AvailableTabs || (AvailableTabs = {}));
const isTabAvailable = (tabName) => !!AvailableTabs[tabName];
const getInitActiveTab = (isLoadingActivityItemMeta, handler, definitionId, activityItemMeta, availableHighlightedPropertyIds, location) => {
    if (isLoadingActivityItemMeta) {
        return AvailableTabs.COMMENTS;
    }
    let tabFromStore = getTabState(handler, definitionId);
    const isActiveActivityTabFromSearch = queryString.parse(location.search)[ACTIVE_TAB_RIGHT_PANEL_KEY] === AvailableTabs.ACTIVITY;
    if (isActiveActivityTabFromSearch) {
        const search = queryString.parse(location.search);
        const { activeTab } = search;
        if (isString(activeTab) && isTabAvailable(activeTab)) {
            tabFromStore = activeTab;
        }
    }
    if (tabFromStore) {
        if (tabFromStore === AvailableTabs.ACTIVITY) {
            if (activityItemMeta) {
                return AvailableTabs.ACTIVITY;
            }
            else if (availableHighlightedPropertyIds?.length) {
                return AvailableTabs.HIGHLIGHTED;
            }
            else {
                return AvailableTabs.COMMENTS;
            }
        }
        else if (tabFromStore === AvailableTabs.HIGHLIGHTED) {
            if (availableHighlightedPropertyIds?.length) {
                return AvailableTabs.HIGHLIGHTED;
            }
            else if (activityItemMeta) {
                return AvailableTabs.ACTIVITY;
            }
            else {
                return AvailableTabs.COMMENTS;
            }
        }
        else {
            return tabFromStore;
        }
    }
    else {
        return availableHighlightedPropertyIds?.length
            ? AvailableTabs.HIGHLIGHTED
            : activityItemMeta
                ? AvailableTabs.ACTIVITY
                : AvailableTabs.COMMENTS;
    }
};
const InfoPanel = memo(({ definitionId, entityId, collapsed, onCollapseClick }) => {
    const { t } = useI18n();
    const { data: displayData } = useDisplayData({
        definitionId,
        entityId
    });
    const location = useLocation();
    const mState = useSelector((s) => getMessagesState(s));
    const { data: displayItemMeta } = useDisplayItemMeta({
        definitionId
    });
    const { data: activityItemMeta, isLoading: isLoadingActivityItemMeta } = useDisplayItemMeta({
        definitionId: ACTIVITY_DEFINITION_ID
    });
    const availableHighlightedPropertyIds = useMemo(() => displayItemMeta?.custom?.highlightedProperties
        ?.filter(({ active }) => active)
        .map(({ propertyId }) => propertyId) || [], [displayItemMeta?.custom?.highlightedProperties]);
    const highlightedProperties = useMemo(() => getOrderedHighlightedProperties(displayData?.data, availableHighlightedPropertyIds), [availableHighlightedPropertyIds, displayData?.data]);
    const [activeTab, setActiveTab] = useState();
    useEffect(() => {
        setActiveTab(getInitActiveTab(isLoadingActivityItemMeta, AvailableHandlers.cms, definitionId, activityItemMeta, availableHighlightedPropertyIds, location));
    }, [
        activityItemMeta,
        availableHighlightedPropertyIds,
        definitionId,
        isLoadingActivityItemMeta,
        location
    ]);
    const conversationNamespace = `${CMS_ITEM_CONVERSATION_NAMESPACE}__${definitionId}`;
    const messages = useMemo(() => getMessages(mState, conversationNamespace, entityId).filter((msg) => !msg.hidden), [mState, conversationNamespace, entityId]);
    const [activities] = useActivity({
        definitionId,
        entityId,
        onlyMy: false,
        filterRequest: {
            responseFields: ['entityId'],
            query: { condition: ConditionType.or, filters: [] },
            sortBy: [],
            limit: 0
        }
    });
    const allActivitiesCount = activities.data?.pages?.[0]?.totalCount;
    const onChangeTab = useCallback((activeTab) => {
        if (isTabAvailable(activeTab)) {
            saveTabState(AvailableHandlers.cms, definitionId, activeTab);
            setActiveTab(activeTab);
        }
    }, [definitionId]);
    const _onCollapseClick = useCallback(({ currentTarget: { dataset: { tab } } }) => {
        if (tab) {
            onChangeTab(tab);
            onCollapseClick();
        }
    }, [onChangeTab, onCollapseClick]);
    return (React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: `${styles.outer} InfoPanelOuter ${collapsed ? 'collapsed' : ''}` },
            React.createElement("div", { className: "top-section" },
                collapsed && (React.createElement("div", { className: "arrow-icon-outer", onClick: onCollapseClick },
                    React.createElement(ArrowIcon, null))),
                !collapsed && (React.createElement(DataListTabs, { type: "card", popupClassName: "info-panel", tabBarExtraContent: {
                        left: (React.createElement("div", { className: "arrow-icon-outer", onClick: onCollapseClick },
                            React.createElement(ArrowIcon, null)))
                    }, activeKey: activeTab, onChange: onChangeTab, destroyInactiveTabPane: true, moreIcon: React.createElement(BsThreeDotsVertical, { size: 22 }), items: [
                        ...(highlightedProperties?.length
                            ? [
                                {
                                    key: AvailableTabs.HIGHLIGHTED,
                                    label: (React.createElement("div", { className: "fw-400 fs-6 mw-100 tabLabel" },
                                        React.createElement("div", { className: "layout-field" },
                                            React.createElement(FaRegStar, { className: "me-2", size: "24px" }),
                                            t('TAB_TITLE_HIGHLIGHTED_PROPERTIES')))),
                                    children: (React.createElement(HighlightedPropertiesList, { definitionId: definitionId, entityId: entityId, highlightedProperties: highlightedProperties }))
                                }
                            ]
                            : []),
                        ...((activityItemMeta && [
                            {
                                key: AvailableTabs.ACTIVITY,
                                label: (React.createElement("div", { className: "fw-400 fs-6 mw-100 tabLabel" },
                                    React.createElement("div", { className: "layout-field" },
                                        React.createElement(Badge, { className: allActivitiesCount ? 'me-3' : 'me-2', count: allActivitiesCount },
                                            React.createElement(FaTasks, { size: "24px" })),
                                        t('ACTIVITY')))),
                                children: (React.createElement(ActivityContainer, { className: "activity-container", definitionId: definitionId, entityId: entityId, showAddForm: false }))
                            }
                        ]) ||
                            []),
                        {
                            key: AvailableTabs.COMMENTS,
                            label: (React.createElement("div", { className: "fw-400 fs-6 mw-100 tabLabel" },
                                React.createElement("div", { className: "layout-field" },
                                    React.createElement(Badge, { className: messages.length ? 'me-3' : 'me-2', count: messages.length },
                                        React.createElement(FaRegCommentDots, { size: "24px" })),
                                    t('COMMENTS')))),
                            children: (React.createElement(ConversationContainer, { className: "conversation-container", conversationNamespace: conversationNamespace, conversationId: entityId, messagePlaceholder: t('COMMENTS_PLACEHOLDER') }))
                        },
                        {
                            key: AvailableTabs.DATA_HISTORY,
                            label: (React.createElement("div", { className: "fw-400 fs-6 mw-100 tabLabel" },
                                React.createElement("div", { className: "layout-field" },
                                    React.createElement(FaHistory, { className: "me-2", size: "22px" }),
                                    t('DATA_HISTORY')))),
                            children: (React.createElement("div", { className: `${styles.historyContainer} custom-scroll-styling d-flex flex-column gap-2` },
                                React.createElement(DataHistoryList, { definitionId: definitionId, entityId: entityId })))
                        }
                    ] }))),
            collapsed && (React.createElement("div", { className: "referrals-icons-col" },
                React.createElement("div", { className: "referrals-icons-col__common-icons" },
                    !!highlightedProperties?.length && (React.createElement(Button, { className: "referrals-icons-col__common-icon mb-3 p-0 border-0 shadow-none", "data-tab": AvailableTabs.HIGHLIGHTED, icon: React.createElement(FaRegStar, { size: "24px" }), onClick: _onCollapseClick })),
                    activityItemMeta && (React.createElement(Button, { className: "referrals-icons-col__common-icon mb-3 p-0 border-0 shadow-none", "data-tab": AvailableTabs.ACTIVITY, icon: React.createElement(FaTasks, { size: "24px" }), onClick: _onCollapseClick })),
                    React.createElement(Button, { className: "referrals-icons-col__common-icon mb-3 p-0 border-0 shadow-none", "data-tab": AvailableTabs.COMMENTS, icon: React.createElement(FaRegCommentDots, { size: "24px" }), onClick: _onCollapseClick }),
                    React.createElement(Button, { className: "referrals-icons-col__common-icon mb-3 p-0 border-0 shadow-none", "data-tab": AvailableTabs.DATA_HISTORY, icon: React.createElement(FaHistory, { size: "24px" }), onClick: _onCollapseClick })))))));
});
export default InfoPanel;
