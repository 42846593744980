import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Menu } from 'antd';
import { PORTAL_LOGO_SRC } from 'config';
import React, { useCallback, useEffect, useMemo } from 'react';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { MdLockOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AUTH_PATH } from '../../../../auth';
import DimensionsListener from '../../../../common/components/DimensionsListener/DimensionsListener';
import QuickActionButton from '../../../../common/components/StickyTabs/QuickActionButton';
import { SELECTED_VIEWS_STORAGE_KEY } from '../../../../common/components/data-table/ViewSelector';
import SideMenuIcon from '../../../../common/components/side-menu/SideMenuIcon/SideMenuIcon';
import { WhatsNewButton } from '../../../../common/components/whats-new-button';
import { useStorage } from '../../../../common/context/StorageContext';
import { NotificationsButton } from '../../../../common/notifications';
import { getEntityBrowseUrl, getEntityViewUrl } from '../../../../common/utils/url';
import { useMyContact } from '../../../../crm/components/use-my-contact';
import { fetchTenant, getTenant, getTenantStatus } from '../../../../tenant';
import { getStoredViewInLocalStorage, isDefaultViewId } from '../../../../views/utils';
import { WORKFLOWS_PATH, WORKFLOWS_YOUR_DASHBOARD_PATH } from '../../../../workflows';
import { WORKFLOWS_YOUR_DASHBOARD } from '../../../../workflows/common/utils/constants';
import DisableAntAnimationProvider from '../DisableAntAnimationProvider/DisableAntAnimationProvider';
import User from '../User';
import styles from './AppHeader.module.scss';
const AppHeader = ({ className, isMobile, isAuthenticated, menu, featuredDefinitionIds }) => {
    const { pathname } = useLocation();
    const { t } = useI18n();
    const { contactId } = useMyContact();
    const dispatch = useDispatch();
    const [viewIdMapInStorage] = useStorage({
        key: SELECTED_VIEWS_STORAGE_KEY,
        defaultValue: {}
    });
    const { isPending, isFailed } = useSelector((s) => getTenantStatus(s));
    const tenant = useSelector((s) => getTenant(s));
    const { contact } = useMyContact();
    const logoUrl = useMemo(() => tenant && tenant.logoUrl && tenant.overrideTenantLogo ? tenant.logoUrl : PORTAL_LOGO_SRC, [tenant]);
    const getMenuItemJsx = useCallback(({ key, to, title, disabled, icon: Icon, children }) => !to || disabled
        ? {
            key,
            label: getMenuRootItemJsx(title, !!children, disabled, Icon),
            disabled,
            className: `${styles.subMenu}`,
            popupClassName: 'app-header-menu-popup-submenu',
            children: children?.map((item, i) => ({
                key: `${item.key}-${i}`,
                label: (React.createElement(Link, { to: !item.disabled ? item.to || '' : '' },
                    item.icon && React.createElement(item.icon, { className: "me-3" }),
                    React.createElement("span", { className: "me-1" }, item.title),
                    item.disabled ? React.createElement(MdLockOutline, { className: styles.menuIcon }) : null)),
                className: `${styles.menuItem}`,
                expandIcon: ({ isSubMenu }) => (isSubMenu ? React.createElement(IoIosArrowForward, null) : null),
                popupClassName: 'app-header-menu-popup-submenu',
                children: item.children?.map((subItem, j) => ({
                    key: `${subItem.key}-${j}`,
                    label: (React.createElement(Link, { to: !subItem.disabled && subItem.to ? subItem.to : '' },
                        subItem.icon && React.createElement(subItem.icon, { className: "me-3" }),
                        React.createElement("span", { className: "me-1" }, subItem.title),
                        subItem.disabled ? React.createElement(MdLockOutline, { className: styles.menuIcon }) : null))
                }))
            }))
        }
        : {
            key,
            label: (React.createElement(Link, { className: styles.menuLink, to: to },
                Icon && (React.createElement("span", { className: styles.subMenuItemIconContainer },
                    React.createElement(SideMenuIcon, { itemIcon: Icon }))),
                title))
        }, []);
    const activeKeys = useMemo(() => {
        const response = [];
        menu.forEach(({ key, to, children }) => {
            if (to && RegExp(to).exec(pathname)) {
                response.push(key);
            }
            else if (children) {
                children.forEach((item) => {
                    if (item.to && RegExp(item.to).exec(pathname)) {
                        response.push(item.key);
                    }
                });
            }
        });
        return response;
    }, [menu, pathname]);
    const logoLink = useMemo(() => {
        const storedViewId = getStoredViewInLocalStorage({
            definitionId: WORKFLOWS_YOUR_DASHBOARD,
            pathname: WORKFLOWS_YOUR_DASHBOARD_PATH,
            contactId,
            viewIdMapInStorage
        });
        return storedViewId && !isDefaultViewId(storedViewId)
            ? getEntityViewUrl(WORKFLOWS_YOUR_DASHBOARD, storedViewId, WORKFLOWS_PATH)
            : getEntityBrowseUrl(WORKFLOWS_YOUR_DASHBOARD, WORKFLOWS_PATH);
    }, [contactId, viewIdMapInStorage]);
    useEffect(() => {
        if (!tenant && !isPending && !isFailed) {
            dispatch(fetchTenant());
        }
    }, [tenant, isPending, isFailed, dispatch]);
    if (!isAuthenticated) {
        return (React.createElement("header", { id: "header", className: `${styles.header} cp-c-row cp-c-align-end-center ${className || ''}` },
            React.createElement(Link, { className: "sign-in-link", to: AUTH_PATH }, t('AUTH_SIGN_IN_NAV_LINK'))));
    }
    return (React.createElement(DisableAntAnimationProvider, null,
        React.createElement("header", { className: `${styles.header} cp-c-row cp-c-align-spacebetween-center cp-i-flex ${className || ''}` },
            !!tenant && (React.createElement("div", { className: "cp-i-none", id: "logo" },
                React.createElement(Link, { className: "logo-link", to: logoLink },
                    React.createElement("img", { src: logoUrl, alt: "" })))),
            !isMobile && (React.createElement(DimensionsListener, { elementSelectors: widthSelectors, trackWidth: true, dependencies: contact }, (width) => (React.createElement(Menu, { selectedKeys: activeKeys, mode: "horizontal", className: `${styles.menu} cp-i-flex`, style: { maxWidth: `calc(100% - ${width}px)` }, overflowedIndicatorPopupClassName: "app-header-menu-popup-submenu", items: menu.map(getMenuItemJsx) })))),
            React.createElement("div", { className: "user-container cp-i-none cp-c-row cp-c-align-center-center", id: "user-container" },
                React.createElement(QuickActionButton, { className: "me-2" }),
                React.createElement(WhatsNewButton, { className: "me-2" }),
                React.createElement(NotificationsButton, { className: "icon-notify me-2 p-2", featuredDefinitionIds: featuredDefinitionIds }),
                React.createElement(User, { menu: menu, isMobile: isMobile })))));
};
export default AppHeader;
const widthSelectors = ['#logo', '#user-container'];
const getMenuRootItemJsx = (title, withArrow, disabled, Icon) => (React.createElement(React.Fragment, null,
    Icon && (React.createElement("span", { className: "icon-container" },
        React.createElement(Icon, null))),
    title,
    withArrow ? React.createElement(IoIosArrowDown, { className: `${styles.menuIcon} down-arrow` }) : null,
    disabled ? React.createElement(MdLockOutline, { className: styles.menuIcon }) : null));
