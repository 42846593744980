import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import NotFound from '@hypercharge/hyper-react-base/lib/common/not-found';
import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { Tabs } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { MdFormatListBulleted } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useStickyTabs } from '../../../../../common/components/StickyTabs/StickyTabsProvider';
import { TooltipTruncate } from '../../../../../common/components/TooltipTruncate';
import { SELECTED_VIEWS_STORAGE_KEY } from '../../../../../common/components/data-table/ViewSelector';
import { useStorage } from '../../../../../common/context/StorageContext';
import { SETTINGS_PATH } from '../../../../../common/utils/constants';
import { prepareGroupPermissionsForSubmit } from '../../../../../common/utils/formUtils';
import { LoadingOverlay } from '../../../../../common/utils/stylingUtils';
import { getEntityBrowseUrl, getEntityViewUrl } from '../../../../../common/utils/url';
import { useMyContact } from '../../../../../crm/components/use-my-contact';
import { ParentOwnerTranslationKeyContext } from '../../../../../groups';
import { ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP } from '../../../../../groups/constants';
import { getStoredViewInLocalStorage, isDefaultViewId } from '../../../../../views/utils';
import MetaDefinitionForm from '../../../../../workflows/meta/components/meta-definition-form';
import { CMS_PATH, HIGHLIGHTED_PROPERTIES_PATH, HIGHLIGHTED_REFERRALS_PATH, HISTORY_PATH, META_DATA_PATH, META_DEFINITION_PATH } from '../../../../common/utils/constants';
import useCreateDefinition from '../../../../hooks/useCreateDefinition';
import useDefinition from '../../../../hooks/useDefinition';
import useUpdateDefinition from '../../../../hooks/useUpdateDefinition';
import { HighlightedPropertiesForm, HighlightedReferralsForm } from '../HighlightedItems';
import { History } from '../History';
import EntityActions from '../entity-actions/EntityActions';
import ItemMetaForm from '../meta-entity-form/ItemMetaForm';
import styles from './EntityRoutes.module.scss';
const DEFAULT_DEFINITION = {
    definitionId: '',
    custom: {},
    titles: {},
    viewGroups: [ALL_USERS_PERMISSION_GROUP, ADMINS_PERMISSION_GROUP],
    createGroups: [ALL_USERS_PERMISSION_GROUP, ADMINS_PERMISSION_GROUP],
    deleteGroups: [ADMINS_PERMISSION_GROUP],
    handler: 'cms',
    sections: []
};
const EntityRoutes = ({ definitionId, entityTitle, setLinks, setCurrentBreadcrumbText, baseUrl, currentUrl, contentHeight }) => {
    const { pathname } = useLocation();
    const { t } = useI18n();
    const dispatch = useDispatch();
    const { mutateAsync: createDefinition } = useCreateDefinition();
    const { mutateAsync: updateDefinition } = useUpdateDefinition();
    const { isActiveStickySection } = useStickyTabs();
    const { contactId } = useMyContact();
    const [viewIdMapInStorage] = useStorage({
        key: SELECTED_VIEWS_STORAGE_KEY,
        defaultValue: {}
    });
    const { definition: fetchedDefinition, isError } = useDefinition({
        definitionId
    });
    const definition = definitionId ? fetchedDefinition : DEFAULT_DEFINITION;
    const isSystem = definition?.system ?? false;
    const navTabs = useMemo(() => {
        const navLinks = [{ to: currentUrl + META_DATA_PATH, text: t('BASIC_INFO') }];
        if (definitionId) {
            navLinks.push({
                to: currentUrl + META_DEFINITION_PATH,
                text: t('WORKFLOWS_SETTINGS_PROPERTIES_NAV_LINK')
            });
            navLinks.push({
                to: currentUrl + HIGHLIGHTED_REFERRALS_PATH,
                text: t('HIGHLIGHTED_REFERRALS_NAV_LINK')
            });
            navLinks.push({
                to: currentUrl + HIGHLIGHTED_PROPERTIES_PATH,
                text: t('HIGHLIGHTED_PROPERTIES_NAV_LINK')
            });
            navLinks.push({ to: currentUrl + HISTORY_PATH, text: t('HISTORY_NAV_LINK') });
        }
        return navLinks.map((link) => ({
            key: link.to,
            label: (React.createElement(Link, { className: "link", to: link.to }, link.text))
        }));
    }, [definitionId, currentUrl, t]);
    const toDefinitionLink = useMemo(() => {
        if (!definitionId) {
            return;
        }
        const storedViewId = getStoredViewInLocalStorage({
            viewIdMapInStorage,
            contactId,
            definitionId
        });
        return storedViewId && !isDefaultViewId(storedViewId)
            ? getEntityViewUrl(definitionId, storedViewId, baseUrl)
            : getEntityBrowseUrl(definitionId, baseUrl);
    }, [baseUrl, contactId, definitionId, viewIdMapInStorage]);
    const showItemsButtonJsx = useMemo(() => {
        if (!definitionId) {
            return;
        }
        return (React.createElement(Button, { inversed: true, to: toDefinitionLink, className: "ItemsButton ms-0 d-flex" },
            React.createElement(MdFormatListBulleted, { className: "me-1" }),
            React.createElement(TooltipTruncate, null, t('CMS_ITEMS'))));
    }, [definitionId, toDefinitionLink, t]);
    const getMainButtonJsx = useCallback(({ metaDefinitionId, submitForm, submitting, disabled, dirty, formIsValid }) => (React.createElement(EntityActions, { definitionId: metaDefinitionId, save: submitForm, saving: submitting, disabled: disabled, canSave: dirty, canDelete: !isSystem, formIsValid: formIsValid })), [isSystem]);
    useEffect(() => {
        if (!toDefinitionLink) {
            setLinks([{ text: t('CMS_NAV_LINK'), to: CMS_PATH }]);
            setCurrentBreadcrumbText(entityTitle);
        }
        else {
            setLinks([
                { text: t('CMS_NAV_LINK'), to: CMS_PATH },
                { text: entityTitle, to: toDefinitionLink }
            ]);
            setCurrentBreadcrumbText(t('SETTINGS'));
        }
    }, [setLinks, setCurrentBreadcrumbText, toDefinitionLink, entityTitle, t]);
    const contentStyle = useMemo(() => {
        if (!contentHeight) {
            return;
        }
        return {
            height: contentHeight
        };
    }, [contentHeight]);
    const onSubmit = useCallback(async (partialNewDefinition) => {
        partialNewDefinition = prepareGroupPermissionsForSubmit(partialNewDefinition);
        const newDefinition = {
            ...partialNewDefinition,
            definitionId: definitionId ?? ''
        };
        try {
            const savedDefinition = definitionId
                ? await updateDefinition(newDefinition)
                : await createDefinition(newDefinition);
            dispatch(success({
                title: t('SUCCESS'),
                message: t('SAVE_SETTINGS_SUCCESS')
            }));
            if (!definitionId) {
                dispatch(push(`${CMS_PATH}/${savedDefinition.definitionId}${SETTINGS_PATH}`));
            }
            return savedDefinition;
        }
        catch (err) {
            dispatch(error({
                title: t('ERROR'),
                message: t('SAVE_SETTINGS_FAIL')
            }));
            throw err;
        }
    }, [definitionId, updateDefinition, createDefinition, dispatch, t]);
    if (isError) {
        return React.createElement(NotFound, null);
    }
    return (React.createElement(SectionCard, { withoutPadding: true, withoutMargin: true, className: `${styles.wrapper} ${isActiveStickySection ? 'pb-5' : ''}` },
        React.createElement(Tabs, { animated: false, className: "nav-bar pt-md-0 pt-7", size: "middle", activeKey: pathname, items: navTabs }),
        React.createElement("div", { style: contentStyle }, !definition ? (React.createElement("div", { className: styles.loadingOuter },
            React.createElement(LoadingOverlay, null,
                React.createElement(LoadingRectangles, null)))) : (React.createElement(ParentOwnerTranslationKeyContext.Provider, { value: "CMS_ITEM_OWNER" },
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: `${currentUrl}${META_DATA_PATH}` },
                    React.createElement(ItemMetaForm, { definition: definition, extraActionButtons: showItemsButtonJsx, isSystem: isSystem, onSubmit: onSubmit })),
                React.createElement(Route, { exact: true, path: `${currentUrl}${META_DEFINITION_PATH}` },
                    React.createElement(MetaDefinitionForm, { definition: definition, parentViewGroups: definition.viewGroups, mainActionButtonComponent: getMainButtonJsx, extraActionButtons: showItemsButtonJsx, onSubmit: onSubmit })),
                React.createElement(Route, { exact: true, path: `${currentUrl}${HIGHLIGHTED_REFERRALS_PATH}` },
                    React.createElement(HighlightedReferralsForm, { definition: definition, isSystem: isSystem, extraActionButtons: showItemsButtonJsx, onSubmit: onSubmit })),
                React.createElement(Route, { exact: true, path: `${currentUrl}${HIGHLIGHTED_PROPERTIES_PATH}` },
                    React.createElement(HighlightedPropertiesForm, { definition: definition, isSystem: isSystem, extraActionButtons: showItemsButtonJsx, onSubmit: onSubmit })),
                React.createElement(Route, { exact: true, path: `${currentUrl}${HISTORY_PATH}` },
                    React.createElement(History, { definitionId: definition.definitionId, extraActionButtons: showItemsButtonJsx })),
                React.createElement(Redirect, { to: `${currentUrl}${META_DATA_PATH}` })))))));
};
export default EntityRoutes;
