import { Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import RichTextEditor from './RichTextEditor';
import { EDITOR_DEFAULT_VALUE } from './extendEditor';
const RichTextModal = ({ open, handleCancel, handleOk, value, entityId, meta, title, onChange }) => {
    const [tmpValue, setTmpValue] = useState(value || EDITOR_DEFAULT_VALUE);
    const onEditorOk = useCallback(() => {
        handleOk(tmpValue);
    }, [handleOk, tmpValue]);
    const onEditorCancel = useCallback(() => {
        setTmpValue(value);
        handleCancel();
    }, [handleCancel, value]);
    useEffect(() => {
        setTmpValue(value);
    }, [value]);
    return (React.createElement(Modal, { destroyOnClose: true, title: title, centered: true, open: open, onOk: onEditorOk, onCancel: onEditorCancel, closable: false, maskClosable: false, width: "70%" },
        React.createElement(RichTextEditor, { meta: meta, onChange: (value) => {
                setTmpValue(value);
                if (onChange) {
                    onChange(value);
                }
            }, value: tmpValue, withoutModalOpening: true, editing: true, autoFocus: true, disabled: false, entityId: entityId })));
};
export default RichTextModal;
