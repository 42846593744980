import React, { useCallback, useMemo, useState } from 'react';
import { MdOutlineTimelapse } from 'react-icons/md';
import { ValueHistoryDrawer, getEditorComponentForType } from '../../../../../cms';
import useDisplayData from '../../../../../cms/hooks/useDisplayData';
import { useUpdateProperty } from '../../../../../cms/hooks/useUpdateProperty';
import { InlineEditor } from '../../../../../common/components/InlineEditor';
import DisplayPriorityTag from '../../../../common/components/display-priority-tag';
import { TASK_DEADLINE_PROPERTY_ID, TASK_PRIORITY_DEFINITION_ID, TASK_PRIORITY_PROPERTY_ID } from '../../../../common/utils/constants';
import { isTaskPriority } from '../../../../common/utils/types';
import DeadlineEditor from '../DeadlineEditor';
import styles from './TaskInlineEditor.module.scss';
const TaskInlineEditor = ({ task, canEdit, onChangePriority, onChangeDeadline }) => {
    const { priority, deadline, taskDefinitionId, taskRunId } = task;
    const { data: displayDataList } = useDisplayData({
        definitionId: taskDefinitionId,
        entityId: taskRunId
    });
    const { mutateAsync: updateProperty } = useUpdateProperty({
        definitionId: taskDefinitionId
    });
    const [canViewDeadline, canEditDeadline] = useMemo(() => {
        const values = displayDataList?.data?.reduce((acc, item) => [...acc, ...item.values], []) ||
            [];
        for (const taskPropertyDisplayData of values) {
            if (taskPropertyDisplayData?.propertyId === TASK_DEADLINE_PROPERTY_ID) {
                return [true, taskPropertyDisplayData.canEdit && task.canExecute];
            }
        }
        return [false, false];
    }, [displayDataList?.data, task.canExecute]);
    const [priorityHistoryDrawerOpen, setPriorityHistoryDrawerOpen] = useState(false);
    const updatePriority = useCallback(async (newPriority) => {
        if (typeof newPriority !== 'string') {
            return;
        }
        await updateProperty({
            entityId: taskRunId,
            propertyId: TASK_PRIORITY_PROPERTY_ID,
            value: newPriority
        });
        await onChangePriority(newPriority);
    }, [updateProperty, taskRunId, onChangePriority]);
    const togglePriorityHistoryDrawer = useCallback(() => {
        setPriorityHistoryDrawerOpen((prev) => !prev);
    }, []);
    return (React.createElement("div", { className: styles.editor },
        React.createElement("div", { className: "d-flex w-100 align-items-center justify-content-between pb-2" },
            React.createElement(InlineEditor, { className: styles.priorityField, displayComponent: DisplayPriority, editorComponent: getEditorComponentForType('entity'), expand: true, value: priority, meta: { definitionId: TASK_PRIORITY_DEFINITION_ID }, canEdit: canEdit, hideActions: true, allowClear: false, onSave: updatePriority, onHistoryOpened: togglePriorityHistoryDrawer }),
            React.createElement(ValueHistoryDrawer, { open: priorityHistoryDrawerOpen, definitionId: taskDefinitionId, entityId: taskRunId, propertyId: TASK_PRIORITY_PROPERTY_ID, onSave: (propId, value) => updatePriority(value), onClose: togglePriorityHistoryDrawer })),
        canViewDeadline && (React.createElement("div", { className: "d-flex w-100 align-items-center justify-content-between" },
            React.createElement(DeadlineEditor, { hideActions: true, className: `${styles.deadlineField}`, definitionId: taskDefinitionId, entityId: taskRunId, propertyId: TASK_DEADLINE_PROPERTY_ID, canEdit: canEditDeadline, value: deadline, onChange: onChangeDeadline, deadlineIcon: () => React.createElement(MdOutlineTimelapse, { size: 20, className: "me-2" }) })))));
};
export default TaskInlineEditor;
const DisplayPriority = ({ value }) => (React.createElement(React.Fragment, null, value && isTaskPriority(value) ? React.createElement(DisplayPriorityTag, { priority: value, showLabel: true }) : '-'));
