import { DEFAULT_LANGUAGE } from 'config';
export const CURRENT_LANGUAGE = 'currentLanguage';
export const getTranslation = (labels, language, fallbackValue = '') => {
    labels = labels || {};
    return (labels[language] ||
        labels[DEFAULT_LANGUAGE] ||
        Object.values(labels).filter(Boolean).at(0) ||
        fallbackValue);
};
export const getTranslationForDescription = (dataByLanguage = {}, language, fallbackValue = null) => dataByLanguage[language] ||
    dataByLanguage[DEFAULT_LANGUAGE] ||
    Object.values(dataByLanguage).filter(Boolean).at(0) ||
    fallbackValue;
export const setLanguageToStorage = (language) => localStorage.setItem(CURRENT_LANGUAGE, language);
export const getLanguageFromStorage = () => localStorage.getItem(CURRENT_LANGUAGE);
