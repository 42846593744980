import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { STORAGE_URL, TENANT_ID } from 'config';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import { normalizeRichTextValue } from '~app/cms/data/components/item-property/editors/RichTextEditor';
import { fetchUploadUrl, uploadFile } from '~app/common/storage';
import { FieldError, FieldWrapper } from '../../FieldElements';
import styles from './RichTextEditor.module.scss';
import './extendEditor';
import { EDITOR_CONFIG, EDITOR_DEFAULT_VALUE } from './extendEditor';
import { OPEN_IN_MODAL_QUILL_ACTION_NAME } from './extendEditor/open-in-modal-format';
const RichTextEditor = ({ value, entityId, autoFocus = true, disabled = false, editing = true, onChange, errorMessage, withoutModalOpening, onModalOpen }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const [editorState, setEditorState] = useState(
    // @ts-expect-error it is not very clear how to create an instance of the Delta class
    normalizeRichTextValue(value) || EDITOR_DEFAULT_VALUE);
    const editor = useRef(null);
    const fetchUploadUrlDispatch = useCallback((file, groupId, isPublic) => dispatch(fetchUploadUrl(file, groupId, isPublic)), [dispatch]);
    const uploadFileDispatch = useCallback(async (file, data) => {
        if (data.uploadUrl) {
            return dispatch(uploadFile(file, data, data.uploadUrl));
        }
        else {
            return;
        }
    }, [dispatch]);
    const errorDispatch = useCallback((content) => dispatch(error(content)), [dispatch]);
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [
                        { header: '1' },
                        { header: '2' },
                        { header: '3' },
                        { header: '4' },
                        'bold',
                        'italic',
                        'link',
                        'strike',
                        'code',
                        { list: 'ordered' },
                        { list: 'bullet' },
                        { indent: '-1' },
                        { indent: '+1' },
                        { color: [] },
                        'image',
                        'code-block',
                        'blockquote',
                        { align: [] },
                        ...(withoutModalOpening ? [] : [OPEN_IN_MODAL_QUILL_ACTION_NAME])
                    ]
                ],
                handlers: {
                    [OPEN_IN_MODAL_QUILL_ACTION_NAME]: withoutModalOpening
                        ? () => null
                        : () => onModalOpen?.(true)
                }
            },
            imageUploader: {
                upload: (file) => new Promise((resolve, reject) => {
                    const filesize = file.size / 1024 / 1024;
                    if (filesize > 1) {
                        errorDispatch({
                            title: t('ERROR'),
                            message: t('CMS_EDITOR_IMAGE_TOO_BIG'),
                            autoDismiss: 5
                        });
                        setTimeout(() => {
                            reject(new Error('Too big'));
                        }, 500);
                    }
                    else {
                        fetchUploadUrlDispatch(file, entityId || 'rich-text-editor').then((data) => {
                            uploadFileDispatch(file, data).then(() => {
                                const storageUrl = `storage.${window.location.hostname
                                    .split('.')
                                    .slice(1, 3)
                                    .join('.')}`;
                                resolve(`https://${STORAGE_URL || storageUrl}/${TENANT_ID}/${data.id}`);
                            }, (e) => {
                                errorDispatch({
                                    title: t('ERROR'),
                                    message: t('UPLOAD_FAILED')
                                });
                                reject(e);
                            });
                        }, (e) => {
                            errorDispatch({
                                title: t('ERROR'),
                                message: t('UPLOAD_FAILED')
                            });
                            reject(e);
                        });
                    }
                })
            },
            clipboard: {
                matchers: [
                    [
                        Node.TEXT_NODE,
                        (node, delta) => {
                            const data = node.data;
                            if (typeof data !== 'string') {
                                return;
                            }
                            const matches = data.match(/https?:\/\/\S+/g);
                            if (matches && matches.length > 0) {
                                const ops = [];
                                let str = data;
                                matches.forEach((match) => {
                                    const split = str.split(match);
                                    const beforeLink = split.shift();
                                    ops.push({ insert: beforeLink });
                                    ops.push({ insert: match, attributes: { link: match } });
                                    str = split.join(match);
                                });
                                ops.push({ insert: str });
                                delta.ops = ops;
                            }
                            return delta;
                        }
                    ]
                ]
            },
            imageResize: {
                modules: ['Resize', 'DisplaySize']
            }
        };
    }, [
        entityId,
        errorDispatch,
        fetchUploadUrlDispatch,
        onModalOpen,
        t,
        uploadFileDispatch,
        withoutModalOpening
    ]);
    useEffect(() => {
        if (autoFocus && editor.current) {
            editor.current.focus();
        }
    }, [autoFocus]);
    useEffect(() => {
        const normalizedValue = value ? normalizeRichTextValue(value) : null;
        if (normalizedValue != null) {
            // @ts-expect-error Diff in types, need refactor
            setEditorState(normalizedValue);
        }
    }, [value]);
    return (React.createElement("div", { "data-text-editor": "rich-text-container" },
        React.createElement(FieldWrapper, { className: styles.editor, disabled: disabled, editing: editing, error: !!errorMessage },
            React.createElement(ReactQuill, { ref: editor, value: editorState, onChange: (_1, _2, _3, editor) => {
                    const currentEditorState = editor.getContents();
                    // this condition is always true as we compare Delta instance with plain object
                    if (!isEqual(editorState, currentEditorState)) {
                        setEditorState(currentEditorState);
                        if (onChange) {
                            const newValue = editor.getLength() !== 1 && currentEditorState.ops
                                ? {
                                    ops: currentEditorState.ops || EDITOR_DEFAULT_VALUE.ops
                                }
                                : null;
                            onChange(newValue);
                        }
                    }
                }, placeholder: t('RICH_TEXT_EDITOR_PLACEHOLDER'), modules: modules, readOnly: disabled, style: { minHeight: withoutModalOpening ? '75vh' : '3rem' }, bounds: `[data-text-editor="rich-text-container"]`, ...EDITOR_CONFIG })),
        React.createElement(FieldError, { error: errorMessage })));
};
export default RichTextEditor;
