import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button } from 'antd';
import React, { useMemo } from 'react';
import { HistoryCompare } from './components/HistoryCompare';
import { useDefinitionHistory } from './hooks/useDefinitionHistory';
import styles from './History.module.scss';
const History = ({ definitionId, extraActionButtons }) => {
    const { t } = useI18n();
    const { data, isLoading, historyMap, isError, fetchNextPage, isFetchingNextPage, hasNextPage } = useDefinitionHistory({
        definitionId
    });
    const historyGroups = useMemo(() => {
        return data?.reduce((acc, item, index) => {
            const previous = data.slice(index + 1);
            if (!previous.length) {
                return acc;
            }
            return [
                ...acc,
                {
                    current: item,
                    previous
                }
            ];
        }, []);
    }, [data]);
    if (isError) {
        return React.createElement("div", null, t('ERROR'));
    }
    if (isLoading) {
        return (React.createElement("div", { className: "p-5" },
            React.createElement(LoadingRectangles, null)));
    }
    if (!data || !historyGroups?.length) {
        return (React.createElement("div", { className: "h-100 d-flex justify-content-center align-items-center" }, t('NO_HISTORY_AVAILABLE_YET')));
    }
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: "form-buttons-container" }, extraActionButtons),
        React.createElement("div", { className: "mh-100 overflow-auto p-3 d-flex flex-column gap-3" },
            historyGroups.map((group, index) => (React.createElement(HistoryCompare, { key: group.current.modifiedAt, data: group, historyMap: historyMap, definitionId: definitionId, actual: index === 0 }))),
            React.createElement("div", { className: "d-flex justify-content-center" },
                React.createElement(Button, { loading: isFetchingNextPage, onClick: () => fetchNextPage(), disabled: !hasNextPage, type: "primary", className: "fw-600 rounded-5", size: "large" }, t('SHOW_MORE_ITEMS'))))));
};
export default History;
