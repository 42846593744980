import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { formatDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Badge, Button, Tooltip } from 'antd';
import cn from 'classnames';
import { AVAILABLE_LANGUAGES } from 'config';
import { head, omit } from 'lodash';
import React, { useRef, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { MdOutlineNoteAlt } from 'react-icons/md';
import { Select } from '../../../../../../../common/components/Select';
import { TooltipTruncate } from '../../../../../../../common/components/TooltipTruncate';
import { useBrowserBreakpoints } from '../../../../../../../common/components/with-browser-breakpoints';
import ContactDisplayName from '../../../../../../../crm/components/contact-display-name';
import { ReleaseNoteForm } from '../../../../../../../releaseNotes/components/ReleaseNoteForm';
import { RELEASE_NOTES_DEFINITION_ID, RELEASE_NOTES_PROPERTY_MAP, VERSION_PROPERTY_ID } from '../../../../../../../releaseNotes/constants';
import { initialFilterRequest } from '../../../../../../../views/components/ViewContext';
import { REFERENCE_DEFINITION_ID_PROPERTY_ID } from '../../../../../../constants';
import { useSearchItems } from '../../../../../../hooks/useSearchItems';
import { DiffViewer } from '../DiffViewer';
import styles from './HistoryCompare.module.scss';
const SMALL_VIEW_HEIGHT = '20vh';
const LARGE_VIEW_HEIGHT = '70vh';
const HistoryCompare = ({ data, historyMap, definitionId, actual }) => {
    const { isTablet } = useBrowserBreakpoints();
    const { t } = useI18n();
    const ref = useRef(null);
    const [selectedVersion, setSelectedVersion] = useState(data.previous[0].modifiedAt);
    const [showMore, setShowMore] = useState(false);
    const [openReleaseNoteModal, setOpenReleaseNoteModal] = useState(false);
    const [selectedVersionReleaseNote, setSelectedVersionReleaseNote] = useState(null);
    const handleOpenReleaseNoteModal = (version) => {
        setSelectedVersionReleaseNote(version);
        setOpenReleaseNoteModal(true);
    };
    const closeReleaseNoteModal = () => {
        setSelectedVersionReleaseNote(null);
        setOpenReleaseNoteModal(false);
    };
    const { data: selectedReleaseNotes } = useSearchItems({
        definitionId: RELEASE_NOTES_DEFINITION_ID,
        filterRequest: {
            ...initialFilterRequest,
            query: {
                condition: ConditionType.and,
                filters: [
                    {
                        field: REFERENCE_DEFINITION_ID_PROPERTY_ID,
                        operator: FilterOperatorTypes.is,
                        data: definitionId
                    },
                    {
                        field: VERSION_PROPERTY_ID,
                        operator: FilterOperatorTypes.equals,
                        data: selectedVersionReleaseNote || ''
                    }
                ]
            },
            limit: 1,
            responseFields: Object.values(RELEASE_NOTES_PROPERTY_MAP)
        }
    }, {
        enabled: openReleaseNoteModal && !!selectedVersionReleaseNote
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: ref, className: cn(styles.wrapper, 'border p-3 d-flex flex-column gap-3 position-relative') },
            React.createElement("div", { className: "row row-gap-1" },
                React.createElement("div", { className: "d-flex gap-2 align-items-center col-12 col-sm-6" },
                    React.createElement(Select, { className: "flex-fill w-100 flex-truncate", value: selectedVersion, popupMatchSelectWidth: false, onSelect: setSelectedVersion, options: data.previous?.map((item) => ({
                            value: item.modifiedAt,
                            label: (React.createElement(TooltipTruncate, { className: "d-flex align-items-center gap-1" },
                                React.createElement("span", null, formatDateTime(item.modifiedAt)),
                                React.createElement("span", { className: "text-lowercase" }, t('BY')),
                                React.createElement(ContactDisplayName, { contactId: item.modifiedBy }),
                                React.createElement("span", null, item.deleted ? `(${t('STATUS_DELETED')})` : '')))
                        })) }),
                    React.createElement(Tooltip, { title: t('RELEASE_NOTES') },
                        React.createElement(Button, { size: "large", className: "p-2", type: "text", onClick: () => {
                                handleOpenReleaseNoteModal(selectedVersion);
                            } },
                            React.createElement(Badge, { className: "d-flex", dot: !!historyMap[selectedVersion].releaseNote },
                                React.createElement(MdOutlineNoteAlt, { size: 24 }))))),
                React.createElement("div", { className: "d-flex gap-2 align-items-center col-12 col-sm-6" },
                    React.createElement(Select, { className: "flex-fill w-100 flex-truncate", value: data.current.modifiedAt, disabled: true, options: [
                            {
                                value: data.current.modifiedAt,
                                label: (React.createElement(TooltipTruncate, { className: "d-flex align-items-center gap-1" },
                                    React.createElement("span", null, formatDateTime(data.current.modifiedAt)),
                                    React.createElement("span", { className: "text-lowercase" }, t('BY')),
                                    React.createElement(ContactDisplayName, { contactId: data.current.modifiedBy }),
                                    React.createElement("span", null, data.current.deleted ? `(${t('STATUS_DELETED')})` : ''),
                                    actual && React.createElement("span", null,
                                        "(",
                                        t('COMMON_ACTUAL'),
                                        ")")))
                            }
                        ] }),
                    React.createElement(Tooltip, { title: t('RELEASE_NOTES') },
                        React.createElement(Button, { size: "large", className: "p-2", type: "text", onClick: () => {
                                handleOpenReleaseNoteModal(data.current.modifiedAt);
                            } },
                            React.createElement(Badge, { className: "d-flex", dot: !!data.current.releaseNote },
                                React.createElement(MdOutlineNoteAlt, { size: 24 })))))),
            React.createElement("div", { className: "position-relative" },
                React.createElement(DiffViewer, { height: showMore ? LARGE_VIEW_HEIGHT : SMALL_VIEW_HEIGHT, original: JSON.stringify(omit(historyMap[selectedVersion]?.value, ['modifiedAt']), null, 2), modified: JSON.stringify(omit(historyMap[data.current.modifiedAt].value, ['modifiedAt']), null, 2) }),
                !showMore && !isTablet && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: cn('h-100 position-absolute top-0', styles.overlay) }),
                    React.createElement("div", { className: cn('h-100 position-absolute top-0', styles.overlay) })))),
            React.createElement("div", { className: cn(styles.expandWrapper, 'd-flex justify-content-center position-absolute bottom-0 mb-2 start-50 translate-middle-x') },
                React.createElement(Button, { color: "primary", className: cn(styles.expand, 'rounded-5 d-flex align-items-center'), onClick: () => {
                        if (!showMore) {
                            ref.current?.scrollIntoView({
                                behavior: 'smooth'
                            });
                        }
                        setShowMore(!showMore);
                    }, icon: showMore ? React.createElement(FaEyeSlash, null) : React.createElement(FaEye, null) }, showMore ? t('SHOW_LESS') : t('SHOW_MORE')))),
        selectedReleaseNotes && selectedVersionReleaseNote && (React.createElement(ReleaseNoteForm, { initialValues: {
                releaseNoteMap: AVAILABLE_LANGUAGES.reduce((acc, lang) => ({
                    ...acc,
                    [lang]: head(selectedReleaseNotes.results)?.[RELEASE_NOTES_PROPERTY_MAP[lang]]
                }), {})
            }, definitionId: definitionId, open: openReleaseNoteModal, onClose: closeReleaseNoteModal, existedVersion: selectedVersionReleaseNote, releaseNoteEntityId: head(selectedReleaseNotes.results)?.entityId }))));
};
export default HistoryCompare;
