import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { DEFAULT_LANGUAGE } from 'config';
import { isEmpty } from 'lodash';
import { PropertyTypes } from '../../types';
import { isConditionQuery } from '../ConditionQuery/utils';
const getIdFromSplit = (complexString) => complexString.split('.')[1];
export const getParentPropertyId = (propertyId) => `parent.${propertyId}`;
export const getPropertyId = (selectedProperty, splitParent) => typeof selectedProperty === 'string'
    ? !splitParent
        ? selectedProperty
        : getIdFromSplit(selectedProperty)
    : selectedProperty?.propertyId;
export const getReferenceDefinitionId = (selectedPropertySource = '') => typeof selectedPropertySource === 'object'
    ? selectedPropertySource?.meta?.definitionId
    : getIdFromSplit(selectedPropertySource);
export const extractAvailableProperties = (displayData, entityMetaDefinitionId, isMultiple = false) => (displayData?.data ?? []).reduce((acc, section) => {
    if (['system', 'process'].includes(section.sectionId)) {
        return acc;
    }
    section.values.forEach((property) => {
        const metaDefinition = 'definitionId' in property.meta && property.meta.definitionId;
        const listProperty = !!('list' in property.meta && property.meta.list);
        if (property.type === PropertyTypes.entity &&
            property.canEdit &&
            (!entityMetaDefinitionId ? true : metaDefinition === entityMetaDefinitionId) &&
            !property.meta.hidden &&
            (!isMultiple ? true : listProperty)) {
            acc.push(property);
        }
    });
    return acc;
}, []);
export const getPropertyLabel = (property, language) => property.labels[language] || property.labels[DEFAULT_LANGUAGE];
export const getProcessRunDataArguments = (selectedPropertySource, entityMetaDefinitionId, selectedPropertyIdDestination, isAllItemsSelected, filterRequest, selectedItemIds) => {
    const isNestProperty = selectedPropertySource !== getParentPropertyId(entityMetaDefinitionId);
    const selectedPropertyIdSource = getPropertyId(selectedPropertySource, !isNestProperty);
    let filter;
    if (!selectedPropertyIdDestination) {
        return {};
    }
    if (isAllItemsSelected) {
        filter = {
            [selectedPropertyIdDestination]: {
                definitionId: entityMetaDefinitionId,
                request: filterRequest,
                srcPropertyId: isNestProperty ? selectedPropertyIdSource : undefined
            }
        };
    }
    else {
        filter = {
            [selectedPropertyIdDestination]: {
                definitionId: entityMetaDefinitionId,
                request: {
                    ...filterRequest,
                    ids: {
                        operator: FilterOperatorTypes.in,
                        data: selectedItemIds
                    }
                },
                srcPropertyId: isNestProperty ? selectedPropertyIdSource : undefined
            }
        };
    }
    return filter;
};
/**
 * Constructs a search filter based on provided parameters. It supports deep cleaning of nested filters,
 * ensuring that empty or invalid filter branches are removed from the query structure.
 *
 * @param isAllItemsSelected - Indicates if all items are selected, which determines whether to include specific IDs in the filter.
 * @param filterRequest - The base filter request to be used as the starting point.
 * @param selectedItemIds - A list of selected item IDs to include in the filter if `isAllItemsSelected` is false.
 * @param additionalConditions - Additional filter conditions to be added to the query.
 *
 * @returns A constructed `FilterRequestT` object with cleaned filters, or `undefined` if no valid filters are left.
 */
export const getSearchItemFilter = (isAllItemsSelected, filterRequest, selectedItemIds, additionalConditions) => {
    if (!additionalConditions) {
        return;
    }
    const cleanFilters = (conditions) => {
        if (!conditions || !conditions.filters?.length) {
            return undefined;
        }
        const validFilters = conditions.filters
            .map((filter) => {
            if (isConditionQuery(filter)) {
                const nestedFilters = cleanFilters(filter);
                return nestedFilters ? { ...filter, filters: nestedFilters.filters } : undefined;
            }
            return filter;
        })
            .filter((filter) => filter !== undefined);
        return !isEmpty(validFilters) ? { ...conditions, filters: validFilters } : undefined;
    };
    const cleanedConditions = cleanFilters(additionalConditions);
    if (!cleanedConditions) {
        return;
    }
    let filter = {
        ...filterRequest,
        limit: 0,
        sortBy: [],
        query: {
            condition: ConditionType.and,
            filters: [
                { ...filterRequest.query },
                {
                    not: true,
                    ...cleanedConditions
                }
            ]
        }
    };
    if (!isAllItemsSelected) {
        filter = {
            ...filter,
            ids: {
                data: selectedItemIds,
                operator: FilterOperatorTypes.in
            }
        };
    }
    return filter;
};
export const getAvailabilityLabel = (t, group) => {
    const notAvailable = t('NOT') + ' ' + t('AVAILABLE');
    const available = t('AVAILABLE');
    return group === 'false' ? available : notAvailable;
};
