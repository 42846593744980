import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { PropertiesProvider } from '../../../../cms/common/context/PropertiesProvider';
import CronosForm, { CronosInitialValues, getCronosFormSchema } from './CronosForm';
import FastbackForm, { FastbackInitialValues, getFastbackFormSchema } from './FastbackForm';
import styles from './InstancesForm.module.scss';
import IntranetForm, { IntranetInitialValues, getIntranetFormSchema } from './IntranetForm';
import StefiForm, { StefiInitialValues, getStefiFormSchema } from './StefiForm';
import Visuel3dForm, { Visuel3dInitialValues, getVisuel3dFormSchema } from './Visuel3dForm';
import WebSiteForm from './WebsiteForm';
import ComparisonForm, { getComparisonFormSchema } from './website/ComparisonForm';
import FacetsForm, { getFacetsFormSchema } from './website/FacetsForm';
import FooterHeaderForm from './website/FooterHeaderForm';
import ImagesForm from './website/ImagesForm';
import KioskForm, { getKioskFormSchema } from './website/KioskForm';
import ScriptForm from './website/ScriptsForm';
export const initialValues = {
    enabled: true,
    title: 'New HyperDealer app',
    configuration: {
        import: {
            ireach: {
                enabled: false
            },
            apollo: {
                enabled: false
            },
            visuel3d: Visuel3dInitialValues,
            cronos: CronosInitialValues,
            fastback: FastbackInitialValues,
            stefi: StefiInitialValues
        },
        intranet: IntranetInitialValues,
        customHosts: [],
        website: {
            stockFilters: [],
            comparison: {
                general: [],
                details: []
            },
            kiosk: {
                splashEnable: false
            },
            snippet: '',
            snippetRoot: '',
            snippetHead: '',
            mainColor: '',
            accentColor: '',
            enableIcons: false,
            imageCover: false,
            imageAspectRatio: '',
            imageGalleryCrop: false,
            isVatIncluded: true,
            showLeasePrice: false,
            showLeaseCalc: false,
            enableComparison: false,
            disableBreadcrumbs: false,
            overlayImagePosition: '',
            hideCtaWhenSold: false,
            fonts: [],
            logo: [],
            favicon: [],
            defaultImage: [],
            overlayImage: [],
            copyrightAdditionalText: '',
            reduceAggregationCountInfo: false
        }
    }
};
export const getFormSchema = () => {
    return Yup.object().shape({
        enabled: Yup.boolean().default(true),
        title: Yup.string().required(),
        configuration: Yup.object().shape({
            import: Yup.object().shape({
                ireach: Yup.object().shape({
                    enabled: Yup.boolean()
                }),
                apollo: Yup.object().shape({
                    vcb: Yup.object().shape({
                        market: Yup.string().optional(),
                        clientId: Yup.string().optional(),
                        resource: Yup.string().optional(),
                        tokenUrl: Yup.string().optional(),
                        apiVersion: Yup.string().optional(),
                        lastSuccessImport: Yup.string().optional(),
                        clientSecret: Yup.string().optional(),
                        ocpApimSubscriptionKey: Yup.string().optional(),
                    }).optional(),
                    enabled: Yup.boolean(),
                    folderPath: Yup.string().optional(),
                    priceLocale: Yup.string().optional(),
                    fileName: Yup.string().optional(),
                    gripConfig: Yup.object()
                        .shape({
                        auth: Yup.object().shape({
                            tokenUrl: Yup.string(),
                            clientId: Yup.string(),
                            clientSecret: Yup.string(),
                            scope: Yup.string()
                        }),
                        api: Yup.object().shape({
                            apiKey: Yup.string(),
                            apiVersion: Yup.string()
                        })
                    })
                        .optional()
                }),
                cronos: getCronosFormSchema(),
                fastback: getFastbackFormSchema(),
                stefi: getStefiFormSchema(),
                visuel3d: getVisuel3dFormSchema()
            }),
            intranet: getIntranetFormSchema(),
            customHosts: Yup.array().of(Yup.string()).optional(),
            website: Yup.object().shape({
                stockFilters: getFacetsFormSchema(),
                comparison: getComparisonFormSchema(),
                kiosk: getKioskFormSchema(),
                footerHeaderTemplate: Yup.string().optional(),
                snippet: Yup.string(),
                snippetRoot: Yup.string(),
                snippetHead: Yup.string(),
                mainColor: Yup.string(),
                accentColor: Yup.string(),
                enableIcons: Yup.boolean(),
                imageCover: Yup.boolean(),
                imageAspectRatio: Yup.string(),
                imageGalleryCrop: Yup.boolean().default(false),
                isVatIncluded: Yup.boolean().default(true),
                showLeasePrice: Yup.boolean(),
                showLeaseCalc: Yup.boolean(),
                enableComparison: Yup.boolean(),
                disableBreadcrumbs: Yup.boolean(),
                overlayImagePosition: Yup.string(),
                hideCtaWhenSold: Yup.boolean(),
                logo: Yup.array().optional().default([]),
                favicon: Yup.array().optional().default([]),
                fonts: Yup.array().optional().default([]),
                defaultImage: Yup.array().optional().nullable().default([]),
                overlayImage: Yup.array().optional().nullable().default([]),
                copyrightAdditionalText: Yup.string(),
                reduceAggregationCountInfo: Yup.boolean(),
            })
        })
    });
};
const Form = ({ instance, dispatch, disabled }) => {
    const [currentTab, setCurrentTab] = useState('website');
    const { t } = useI18n();
    const items = {
        website: {
            key: 'website',
            title: 'WebSite',
            form: WebSiteForm
        },
        websiteImages: {
            key: 'websiteImages',
            title: 'Images',
            form: ImagesForm,
            parent: 'website'
        },
        websiteFacets: {
            key: 'websiteFacets',
            title: 'Facets',
            form: FacetsForm,
            parent: 'website'
        },
        websiteComparison: {
            key: 'websiteComparison',
            title: 'Comparison',
            form: ComparisonForm,
            parent: 'website'
        },
        websiteKiosk: {
            key: 'websiteKiosk',
            title: 'Kiosk',
            form: KioskForm,
            parent: 'website'
        },
        websiteFooterHeader: {
            key: 'websiteFooterHeader',
            title: 'Footer & Header',
            form: FooterHeaderForm,
            parent: 'website'
        },
        websiteScripts: {
            key: 'websiteScripts',
            title: 'Scripts',
            form: ScriptForm,
            parent: 'website'
        },
        intranet: {
            key: 'intranet',
            title: 'Intranet',
            form: IntranetForm
        },
        stefi: {
            key: 'stefi',
            title: 'Stefi',
            form: StefiForm
        },
        cronos: {
            key: 'cronos',
            title: 'Cronos',
            form: CronosForm
        },
        fastback: {
            key: 'fastback',
            title: 'Fastback',
            form: FastbackForm
        },
        visuel3d: {
            key: 'visuel3d',
            title: 'Visuel3d',
            form: Visuel3dForm
        }
    };
    const FormComponent = items[currentTab].form;
    return (React.createElement("div", { className: `${styles.instancesForm} cp-c-row box cp-c-wrap` },
        React.createElement("div", { className: "cp-c-column cp-i-15 pe-2 menuWrapper" },
            React.createElement("ul", { className: "p-0 pe-2 ps-2" }, Object.values(items).map((menuItem) => (React.createElement("li", { className: `mb-1 mt-1 ${menuItem.parent ? 'ms-3' : 'ms-0'}`, key: menuItem.key },
                React.createElement("a", { className: menuItem.key == currentTab ? 'link active' : 'link', onClick: () => setCurrentTab(menuItem.key) }, menuItem.title)))))),
        React.createElement("div", { className: "cp-i-flex cp-c-padding-h-2" },
            React.createElement(PropertiesProvider, null,
                React.createElement(FormComponent, { translate: t, instance: instance, dispatch: dispatch, disabled: disabled })))));
};
export default Form;
