import { invalidateItemsQuery } from '../../cms/hooks/invalidateCache';
import { VIEW_DEFINITION_ID } from '../../views';
import { invalidateDisplayProcessMetaListCache } from './useDisplayProcessMetaList';
import { invalidateProcessDefinitionCache } from './useProcessDefinition';
export const invalidateProcessDefinitionQuery = async (definitionId) => {
    if (definitionId) {
        await invalidateProcessDefinitionCache(definitionId);
    }
    await invalidateDisplayProcessMetaListCache(); // to reflect new processes or updated names / permissions in the sidebar
    await invalidateItemsQuery({ definitionId: VIEW_DEFINITION_ID, waitNewData: true }); // A view is created for that workflow in the backend, so we have to re-fetch the list of views
};
