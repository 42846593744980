import Editor from '@monaco-editor/react';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { ResizableBox } from 'react-resizable';
import styles from './CloudWatchQueryEditor.module.scss';
const EDITOR_OPTIONS = {
    scrollBeyondLastLine: false,
    minimap: {
        enabled: false
    },
    lineNumbersMinChars: 3,
    renderWhitespace: 'all',
    scrollbar: {
        vertical: 'visible'
    }
};
const CloudWatchQueryEditor = ({ className, value, onChange }) => {
    const onBeforeMountEditor = useCallback((monaco) => {
        registerLanguage(monaco);
    }, []);
    const onChangeEditor = useCallback((value) => {
        onChange(value);
    }, [onChange]);
    return (React.createElement(ResizableBox, { className: cn(styles.wrapper, 'editor', className), height: 80, minConstraints: [Infinity, 80], maxConstraints: [Infinity, 500], draggableOpts: { grid: [1, 1] }, resizeHandles: ['s'], handleSize: [20, 20], axis: "y" },
        React.createElement(Editor, { height: "100%", width: "100%", language: "CloudWatchQuery", value: value, options: EDITOR_OPTIONS, beforeMount: onBeforeMountEditor, onChange: onChangeEditor })));
};
export default CloudWatchQueryEditor;
/**
 * The function is based on the source code of the AWS CloudWatch service.
 * @link https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=eu-west-1#logsV2:logs-insights
 */
function registerLanguage(monaco) {
    monaco.languages.register({ id: 'CloudWatchQuery' });
    monaco.languages.setMonarchTokensProvider('CloudWatchQuery', {
        defaultToken: 'invalid',
        start: 'root',
        ignoreCase: !0,
        tokenPostfix: '.pschorrql',
        commands: [
            'display',
            'fields',
            'filter',
            'limit',
            'sort',
            'stats',
            'parse',
            'dedup',
            'pattern',
            'diff',
            'filterIndex',
            'unnest'
        ],
        functions: [
            'abs',
            'avg',
            'bin',
            'ceil',
            'coalesce',
            'concat',
            'count',
            'count_distinct',
            'datefloor',
            'dateceil',
            'earliest',
            'floor',
            'fromMillis',
            'greatest',
            'isEmpty',
            'isPresent',
            'isBlank',
            'isValidIp',
            'isValidIpV4',
            'isValidIpV6',
            'isIpInSubnet',
            'isIpv4InSubnet',
            'isIpv6InSubnet',
            'latest',
            'least',
            'log',
            'ltrim',
            'max',
            'median',
            'min',
            'mod',
            'pct',
            'pow',
            'replace',
            'rtrim',
            'sortsFirst',
            'sortsLast',
            'strcontains',
            'strlen',
            'sqrt',
            'stddev',
            'substr',
            'sum',
            'toMillis',
            'toLower',
            'toUpper',
            'trim',
            'unmask',
            'jsonParse',
            'jsonStringify'
        ],
        keywords: [
            'and',
            'as',
            'asc',
            'by',
            'desc',
            'group',
            'in',
            'like',
            'not',
            'or',
            'to',
            'previousDay',
            'previousWeek',
            'previousMonth',
            'into'
        ],
        pseudoColumns: ['@timestamp', '@message', '@log', '@logStream'],
        operators: ['<=', '>=', '!=', '=', '|', '|>'],
        symbols: /[=><!~?:&|+\-*/^%]+/,
        escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,
        digits: /\d+(_+\d+)*/,
        octaldigits: /[0-7]+(_+[0-7]+)*/,
        binarydigits: /[0-1]+(_+[0-1]+)*/,
        hexdigits: /[[0-9a-fA-F]+(_+[0-9a-fA-F]+)*/,
        regexpctl: /[(){}[\]$^|\-*+?.]/,
        regexpesc: /\\(?:[bBdDfnrstvwWn0\\/]|@regexpctl|c[A-Z]|x[0-9a-fA-F]{2}|u[0-9a-fA-F]{4})/,
        tokenizer: {
            root: [
                {
                    include: '@comments'
                },
                {
                    include: '@whitespace'
                },
                [
                    /\/(?=([^\\/]|\\.)+\/([gimsuy]*)(\s*)(\.|;|\/|,|\)|\]|\}|$)*)/,
                    {
                        token: 'regexp',
                        bracket: '@open',
                        next: '@regexp'
                    }
                ],
                {
                    include: '@pseudoColumns'
                },
                {
                    include: '@numbers'
                },
                {
                    include: '@strings'
                },
                {
                    include: '@smartStrings'
                },
                {
                    include: '@backtickedIdentifiers'
                },
                [/[,]/, 'delimiter'],
                [/[()]/, '@brackets'],
                [
                    /[\w@#$]+/,
                    {
                        cases: {
                            '@commands': 'keyword',
                            '@keywords': 'keyword',
                            '@functions': 'key',
                            '@operators': 'operator',
                            '@default': 'identifier'
                        }
                    }
                ],
                [/[<>=!%&+\-*/|~^]/, 'operator']
            ],
            whitespace: [[/\s+/, 'white']],
            comments: [[/#.*$/, 'comment']],
            pseudoColumns: [
                [
                    /[@][\w]+/,
                    {
                        cases: {
                            '@pseudoColumns': 'emphasis',
                            '@default': 'identifier'
                        }
                    }
                ]
            ],
            numbers: [
                [/0[xX][0-9a-fA-F]*/, 'number'],
                [/[$][+-]*\d*(\.\d*)?/, 'number'],
                [/((\d+(\.\d*)?)|(\.\d+))([eE][-+]?\d+)?/, 'number']
            ],
            strings: [
                {
                    include: '@singleQuoteStrings'
                },
                {
                    include: '@doubleQuoteStrings'
                }
            ],
            singleQuoteStrings: [
                [
                    /'/,
                    {
                        token: 'string',
                        next: '@singleQuoteString'
                    }
                ]
            ],
            singleQuoteString: [
                [/[^']+/, 'string'],
                [/''/, 'string'],
                [
                    /'/,
                    {
                        token: 'string',
                        next: '@pop'
                    }
                ]
            ],
            doubleQuoteStrings: [
                [
                    /"/,
                    {
                        token: 'string',
                        next: '@doubleQuoteString'
                    }
                ]
            ],
            doubleQuoteString: [
                [/[^"]+/, 'string'],
                [/""/, 'string'],
                [
                    /"/,
                    {
                        token: 'string',
                        next: '@pop'
                    }
                ]
            ],
            smartStrings: [
                {
                    include: '@smartSingleQuoteStrings'
                },
                {
                    include: '@smartDoubleQuoteStrings'
                }
            ],
            smartSingleQuoteStrings: [
                [
                    /‘/,
                    {
                        token: 'string',
                        next: '@smartSingleQuoteString'
                    }
                ]
            ],
            smartSingleQuoteString: [
                [/[^’]+/, 'string'],
                [/’’/, 'string'],
                [
                    /’/,
                    {
                        token: 'string',
                        next: '@pop'
                    }
                ]
            ],
            smartDoubleQuoteStrings: [
                [
                    /“/,
                    {
                        token: 'string',
                        next: '@smartDoubleQuoteString'
                    }
                ]
            ],
            smartDoubleQuoteString: [
                [/[^”]+/, 'string'],
                [/””/, 'string'],
                [
                    /”/,
                    {
                        token: 'string',
                        next: '@pop'
                    }
                ]
            ],
            backtickedIdentifiers: [
                [
                    /`/,
                    {
                        token: 'identifier.backtick',
                        next: '@backtickedIdentifier'
                    }
                ]
            ],
            backtickedIdentifier: [
                [/[^`]+/, 'identifier'],
                [/``/, 'identifier'],
                [
                    /`/,
                    {
                        token: 'identifier.backtick',
                        next: '@pop'
                    }
                ]
            ],
            regexp: [
                [
                    /(\{)(\d+(?:,\d*)?)(\})/,
                    ['regexp.escape.control', 'regexp.escape.control', 'regexp.escape.control']
                ],
                [
                    /(\[)(\^?)(?=(?:[^\]\\/]|\\.)+)/,
                    [
                        'regexp.escape.control',
                        {
                            token: 'regexp.escape.control',
                            next: '@regexrange'
                        }
                    ]
                ],
                [/(\()(\?:|\?=|\?!)/, ['regexp.escape.control', 'regexp.escape.control']],
                [/[()]/, 'regexp.escape.control'],
                [/@regexpctl/, 'regexp.escape.control'],
                [/[^\\/]/, 'regexp'],
                [/@regexpesc/, 'regexp.escape'],
                [/\\\./, 'regexp.invalid'],
                [
                    /(\/)([gimsuy]*)/,
                    [
                        {
                            token: 'regexp',
                            bracket: '@close',
                            next: '@pop'
                        },
                        'keyword.other'
                    ]
                ]
            ],
            regexrange: [
                [/-/, 'regexp.escape.control'],
                [/\^/, 'regexp.invalid'],
                [/@regexpesc/, 'regexp.escape'],
                [/[^\]]/, 'regexp'],
                [
                    /\]/,
                    {
                        token: 'regexp.escape.control',
                        next: '@pop',
                        bracket: '@close'
                    }
                ]
            ]
        }
    });
}
