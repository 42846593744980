import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { replace } from '@hypercharge/hyper-react-base/lib/router';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Breadcrumbs } from '../../../cms/common/breadcrumbs';
import { createForm, fetchForm, saveForm } from '../../actions';
import { FORMS_PATH } from '../../constants';
import EditForm from './EditForm';
const FormContainer = ({ match: { params: { formId } } }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState(null);
    const isNew = formId === 'new';
    useEffect(() => {
        if (isNew) {
            setForm({
                id: generateId(),
                title: 'New Form',
                definitionId: '',
                form: {
                    data: {},
                    // @ts-expect-error
                    schema: {},
                    ui: {}
                }
            });
            setLoading(false);
            return;
        }
        setLoading(true);
        // @ts-expect-error
        dispatch(fetchForm(formId))
            // @ts-expect-error
            .then((response) => {
            if (response) {
                setForm(response);
            }
            setLoading(false);
        })
            .catch(() => {
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: t('FETCH_FORM__ERROR_MESSAGE')
            }));
        });
    }, [formId, dispatch, t, isNew]);
    const onSave = useCallback(async (form) => {
        try {
            let response;
            if (isNew) {
                response = await dispatch(createForm(form));
                dispatch(replace(`${FORMS_PATH}/${response.id}`));
            }
            else {
                // @ts-expect-error
                response = await dispatch(saveForm(formId, form));
            }
            setForm(response);
        }
        catch (err) {
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: t('SAVE_FORM__ERROR_MESSAGE')
            }));
        }
    }, [dispatch, formId, t, isNew]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Breadcrumbs, { links: [
                { text: t('TEMPLATES_NAV_LINK'), to: FORMS_PATH },
                { text: t('FORMS_NAV_LINK'), to: FORMS_PATH }
            ], currentBreadcrumb: isNew ? t('CREATE_FORM__TITLE') : (form && form.title) || '...' }),
        loading ? (React.createElement(LoadingRectangles, null)) : (form && (React.createElement("div", { style: { maxWidth: '1120px', margin: '0 auto', paddingBottom: '60px' } },
            React.createElement(EditForm, { form: form, loading: loading, save: onSave }))))));
};
export default FormContainer;
