import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import LogsPage from './LogsPage';
const Routes = ({ contentHeight, setLinks, setCurrentBreadcrumbText }) => {
    const match = useRouteMatch();
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: `${match.path}`, render: () => {
                return (React.createElement(LogsPage, { setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, contentHeight: contentHeight, basePath: match.path }));
            } }),
        React.createElement(Route, { path: [`${match.path}/:queryItemId`], render: (props) => {
                return (React.createElement(LogsPage, { setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, contentHeight: contentHeight, queryItemId: props.match.params.queryItemId, basePath: match.path }));
            } }),
        React.createElement(Redirect, { to: match.path })));
};
export default Routes;
