import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isAxiosError } from '../../../../../common/utils/request';
import { isManualTask } from '../../../../tasks/utils';
import { useRequiredFields } from '../RequiredFieldsProvider';
import AvailableTask from './AvailableTask';
import styles from './AvailableTasks.module.scss';
const AvailableTasks = ({ title, type = 'normal', isProcessPending, processStatus, assignTask, tasks, dispatchWarning, completeTask, canEdit, requiredFieldsConfig, permissionFieldsConfig, contactId, onChangePriority, onChangeDeadline }) => {
    const { t } = useI18n();
    const mountedDateTime = useRef(Date.now());
    const [submittingTaskRunId, setSubmittingTaskRunId] = useState();
    const [activeTaskStatuses, setActiveTaskStatuses] = useState({});
    const { requiredFieldsStatus, clearErrorFields, addErrorFields, clearSuccessFields, addSuccessFields } = useRequiredFields();
    const loading = useMemo(() => isProcessPending || requiredFieldsStatus.isPending, [isProcessPending, requiredFieldsStatus]);
    const onTaskToggle = useCallback((newTaskId) => {
        setActiveTaskStatuses((activeTaskStatuses) => ({
            ...activeTaskStatuses,
            [newTaskId]: !activeTaskStatuses[newTaskId]
        }));
    }, []);
    const handleAssignTask = useCallback((taskMetaId, taskRunId, currentContactId) => assignTask(taskMetaId, taskRunId, currentContactId).finally(() => {
        currentContactId === contactId && !activeTaskStatuses[taskRunId] && onTaskToggle(taskRunId);
    }), [assignTask, contactId, activeTaskStatuses, onTaskToggle]);
    const onSubmitTask = useCallback(async (task, additionalData) => {
        const { taskRunId, requiredProperties = [] } = task;
        clearErrorFields(taskRunId);
        clearSuccessFields(taskRunId);
        setSubmittingTaskRunId(taskRunId);
        try {
            await completeTask(task.taskMetaId, taskRunId, additionalData);
        }
        catch (err) {
            if (!isAxiosError(err) || !err.response || !err.response.data) {
                throw err;
            }
            const responseData = err.response.data;
            if (responseData.code === 'MISSING_REQUIRED_FIELDS') {
                addErrorFields(taskRunId, responseData.data);
                addSuccessFields(taskRunId, responseData.data, requiredProperties);
                void dispatchWarning(t('TASK_REQUIRED_PROPERTIES_WARNING'));
            }
            else if ('message' in responseData) {
                // This will only be wrong if what failed was just one required field that's missing, and that field has an id of 'message'
                void dispatchWarning(responseData.message);
            }
        }
        finally {
            setSubmittingTaskRunId(undefined);
        }
    }, [
        clearErrorFields,
        clearSuccessFields,
        completeTask,
        dispatchWarning,
        addErrorFields,
        addSuccessFields,
        t
    ]);
    useEffect(() => {
        setActiveTaskStatuses((activeTaskStatuses) => {
            const newActiveTaskStatuses = tasks.filter(isManualTask).reduce((newActiveTasksStatus, task) => {
                if (!(task.taskRunId in newActiveTasksStatus)) {
                    newActiveTasksStatus[task.taskRunId] = true;
                }
                return newActiveTasksStatus;
            }, { ...activeTaskStatuses });
            Object.keys(newActiveTaskStatuses).forEach((itemTaskId) => {
                const isExistTask = tasks.some((task) => task.taskRunId === itemTaskId);
                !isExistTask && delete newActiveTaskStatuses[itemTaskId];
            });
            return newActiveTaskStatuses;
        });
    }, [tasks]);
    if (processStatus !== 'RUNNING') {
        return null;
    }
    return (React.createElement("div", { className: classNames(styles.tasksContainer, {
            'warning-tasks': type === 'warning'
        }) },
        React.createElement("div", { className: styles.tasksContainerTitle }, title),
        loading || tasks.length ? (React.createElement("div", { className: `${styles.taskContainer} ${loading ? styles.fetchingTasks : ''}` },
            loading && (React.createElement("div", { className: `${styles.noTasks}` },
                React.createElement(LoadingRectangles, null))),
            !loading &&
                tasks.length &&
                tasks.map((task) => {
                    return (React.createElement(AvailableTask, { key: task.taskRunId, className: mountedDateTime.current < task.initiatedAt ? styles.isNewTask : '', task: task, contactId: contactId, onSubmit: onSubmitTask, onAssignTask: handleAssignTask, submitting: submittingTaskRunId === task.taskRunId, loading: loading, onTaskToggle: onTaskToggle, isActive: activeTaskStatuses[task.taskRunId], canEdit: canEdit, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, onChangePriority: (value) => {
                            return onChangePriority(task, value);
                        }, onChangeDeadline: (value) => {
                            return onChangeDeadline(task, value);
                        } }));
                }))) : (React.createElement("div", { className: `${styles.taskContainer} ${styles.noTasks}` },
            React.createElement("i", null, processStatus == 'RUNNING'
                ? t('NO_TASKS_DESCRIPTION_IN_ACTIVE_PROCESS')
                : t('NO_TASKS_DESCRIPTION'))))));
};
export default AvailableTasks;
