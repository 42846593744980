import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications/notificationActions';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Breadcrumbs } from '../../../cms/common/breadcrumbs';
import { Option, Select } from '../../../common/components/Select';
import HeightContext, { HeightContextProvider } from '../../../common/components/height-context';
import LoadingIndicator from '../../../common/components/loading-indicator';
import { getDashboards } from '../../actions';
import { EMBEDDED_BI_PATH } from '../../constants';
import { EmbeddedDashboard } from '../EmbeddedDashboard';
import styles from './EmbeddedDashboardsPage.module.scss';
// Variables for set div height to full display height
const otherElementSelectors = [
    '#header',
    '#featured-entities',
    '#breadcrumbs',
    '#footer',
    '#selectDashboard'
];
const otherHeightInPx = 32; // padding
const EmbeddedDashboardsPage = ({ match: { params: { id: dashboardId } } }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const dispatchGetDashboard = useDispatch();
    const [dashboards, setDashboards] = useState([]);
    const [loading, setLoading] = useState(true);
    // Get dashboard from QuickSight
    useEffect(() => {
        setLoading(true);
        dispatchGetDashboard(getDashboards())
            .then(setDashboards)
            .catch((e) => {
            console.error(e);
            dispatch(error({
                title: t('ERROR'),
                message: e.message
            }));
        })
            .finally(() => setLoading(false));
    }, [dispatch, dispatchGetDashboard, t]);
    const activeDashboard = useMemo(() => dashboards.findIndex((dashboard) => dashboard.dashboardId === dashboardId), [dashboardId, dashboards]);
    useEffect(() => {
        if (!dashboardId && dashboards.length) {
            dispatch(push(`${EMBEDDED_BI_PATH}/${dashboards[0].dashboardId}`));
        }
    }, [dashboardId, dashboards, dispatch]);
    //
    // Set active board via callback of select
    const onChangeDashboard = useCallback((newValue) => {
        if (dashboards[newValue]) {
            dispatch(push(`${EMBEDDED_BI_PATH}/${dashboards[newValue].dashboardId}`));
        }
    }, [dashboards, dispatch]);
    const mainPath = useMemo(() => `${EMBEDDED_BI_PATH}` + (dashboards[0] ? `/${dashboards[0].dashboardId}` : ''), [dashboards]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "breadcrumbs" },
            React.createElement(Breadcrumbs, { links: [{ text: t('EMBEDDED_BI_NAV_LINK'), to: mainPath }], currentBreadcrumb: dashboards[activeDashboard] ? dashboards[activeDashboard].name : '' })),
        dashboards.length > 1 && (React.createElement("div", { className: "position-relative p-0 mt-0 mx-2 mb-3" },
            React.createElement(Select, { value: activeDashboard, onChange: onChangeDashboard, showSearch: false, allowClear: false, className: styles.dashboardSelect },
                dashboards.map((option, index) => (React.createElement(Option, { key: index }, option.name))),
                activeDashboard === -1 ? React.createElement(Option, { key: -1 }, " ") : null))),
        React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: otherHeightInPx },
            React.createElement(HeightContext.Consumer, null, ({ contentHeight }) => (React.createElement("div", { className: cn(styles.dashboardContainer, 'mt-2 mx-3 p-0 position-relative'), style: { height: contentHeight } }, loading ? (React.createElement(LoadingIndicator, null)) : dashboards.length === 0 ? (React.createElement("div", { className: styles.errorContainer }, t('HYPERBI_DASHBOARDS__NO_RESULTS'))) : !dashboards[activeDashboard]?.dashboardId ? (React.createElement("div", { className: styles.errorContainer }, t('DATA_TABLE__NO_RESULTS'))) : (React.createElement(EmbeddedDashboard, { id: "activeDashboard", height: contentHeight, dashboardId: dashboards[activeDashboard].dashboardId, dispatch, t }))))))));
};
export default EmbeddedDashboardsPage;
