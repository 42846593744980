import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tag } from 'antd';
import cn from 'classnames';
import React, { useMemo } from 'react';
import { TITLE_PROPERTY_ID } from '../../../../cms';
import { useEntityDisplayData } from '../../../../cms/common/components/withEntityDisplayData';
import { getPropertyById } from '../../../../cms/common/utils/utils';
import { TASK_STATUS_DEFINITION_ID } from '../../../../cms/constants';
import { useEntityItems } from '../../../../cms/hooks/useEntityItems';
import style from './DisplayStatusTag.module.scss';
const tagStyle = { marginRight: '0', cursor: 'default' };
const statusDisplayMap = {
    SUCCEEDED: {
        translationKey: 'COMPLETED',
        mainColor: 'var(--successColor-6)',
        backgroundColor: 'var(--successColor-1)'
    },
    WAITING: {
        translationKey: 'AVAILABLE',
        mainColor: 'var(--primaryColor-6)',
        backgroundColor: 'var(--primaryColor-1)'
    },
    RUNNING: {
        translationKey: 'ONGOING',
        mainColor: 'var(--warningColor-6)',
        backgroundColor: 'var(--warningColor-1)'
    },
    FAILED: {
        translationKey: 'FAILED',
        mainColor: 'var(--errorColor-6)',
        backgroundColor: 'var(--errorColor-1)'
    },
    CANCELLED: {
        translationKey: 'CANCELLED',
        mainColor: 'var(--errorColor-6)',
        backgroundColor: 'var(--errorColor-1)'
    },
    UNKNOWN: {
        translationKey: 'UNKNOWN',
        mainColor: 'var(--errorColor-6)',
        backgroundColor: 'var(--errorColor-1)'
    }
};
export const DisplayStatusTag = ({ className, status }) => {
    const { t, language } = useI18n();
    const { displayData: taskStatusDisplayData } = useEntityDisplayData(TASK_STATUS_DEFINITION_ID);
    const titleTaskStatusDisplayData = getPropertyById(taskStatusDisplayData, TITLE_PROPERTY_ID);
    const titleTaskStatusTranslationKey = titleTaskStatusDisplayData?.meta?.translations?.[language];
    const responseFieldsTaskStatus = useMemo(() => {
        const responseFields = [];
        if (titleTaskStatusTranslationKey) {
            responseFields.push(titleTaskStatusTranslationKey);
        }
        return responseFields;
    }, [titleTaskStatusTranslationKey]);
    const { data } = useEntityItems({
        definitionId: TASK_STATUS_DEFINITION_ID,
        ids: [status],
        extraPayloadProps: {
            responseFields: responseFieldsTaskStatus
        },
        enabled: !!titleTaskStatusTranslationKey
    });
    const statusDisplay = useMemo(() => statusDisplayMap[status] || statusDisplayMap['UNKNOWN'], [status]);
    const dataTaskStatus = useMemo(() => {
        return data?.find((item) => item.entityId === status);
    }, [data, status]);
    const statusStyle = useMemo(() => ({
        '--main-color': statusDisplay.mainColor,
        '--background-color': statusDisplay.backgroundColor
    }), [statusDisplay]);
    return (React.createElement(Tag, { className: cn(style.tag, className), style: {
            ...tagStyle,
            ...statusStyle
        } }, (titleTaskStatusTranslationKey && dataTaskStatus?.[titleTaskStatusTranslationKey]) ||
        t(statusDisplay.translationKey)));
};
