import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider, Popover } from 'antd';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { FaAsterisk } from 'react-icons/fa';
import { useRequiredFields } from '../RequiredFieldsProvider';
import styles from './RequiredFieldInfoPopover.module.scss';
const RequiredFieldInfoPopover = ({ propertyId, requiredFieldsConfig: { isRequiredField, isErrorField, isSuccessField, getNestedRequiredFields } }) => {
    const { t } = useI18n();
    const isRequired = useMemo(() => isRequiredField(propertyId), [isRequiredField, propertyId]);
    const nestedRequiredFields = useMemo(() => (getNestedRequiredFields ? getNestedRequiredFields(propertyId) : []), [getNestedRequiredFields, propertyId]);
    return !isRequired && isEmpty(nestedRequiredFields) ? null : (React.createElement(Popover, { destroyTooltipOnHide: true, content: React.createElement(React.Fragment, null,
            isRequired && (React.createElement(RequiredField, { error: isErrorField(propertyId), success: isSuccessField(propertyId) }, t('PROPERTY_REQUIRED'))),
            isRequired && !isEmpty(nestedRequiredFields) && React.createElement(Divider, null),
            !isEmpty(nestedRequiredFields) && (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    t('NESTED_REQUIRED_FIELDS'),
                    ":"),
                React.createElement("div", { className: styles.list }, nestedRequiredFields.map((field) => (React.createElement(RequiredField, { key: field, error: isErrorField(field), success: isSuccessField(field) },
                    React.createElement(RequiredFieldLabel, { field: field })))))))) },
        React.createElement("span", null,
            React.createElement(RequiredFieldIcon, null))));
};
export default RequiredFieldInfoPopover;
const RequiredField = ({ children, error, success }) => (React.createElement("div", { className: `${styles.field} ${error ? 'error' : ''} ${success ? 'success' : ''}` }, children));
export const RequiredFieldIcon = ({ invalid = false }) => (React.createElement("sup", null,
    React.createElement(FaAsterisk, { className: `${styles.icon} ${invalid ? 'invalid' : ''}` })));
const RequiredFieldLabel = ({ field }) => {
    const { getFieldLabel } = useRequiredFields();
    return getFieldLabel(field);
};
