import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import NotFound from '@hypercharge/hyper-react-base/lib/common/not-found';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { LOG_QUERIES_DEFINITION_ID } from '@hypercharge/portal-utils';
import { Button } from 'antd';
import ms from 'ms';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import useCreateItem from '~app/cms/hooks/useCreateItem';
import useDeleteItems from '~app/cms/hooks/useDeleteItems';
import { useEntityItem } from '~app/cms/hooks/useEntityItem';
import DateRangeSelector from '~app/common/components/DateRangeSelector';
import { dateRangeSelectorValueToAbsoluteValue } from '~app/common/components/DateRangeSelector/utils';
import { useContentHeight } from '~app/common/components/height-context/useContentHeight';
import { bytesToSize } from '~app/common/utils/converters';
import { getErrorMessage } from '~app/common/utils/request';
import { LoadingOverlay } from '~app/common/utils/stylingUtils';
import { SETTINGS_PATH } from '../../../constants';
import CloudWatchQueryEditor from './CloudWatchQueryEditor';
import styles from './LogsPage.module.scss';
import LogsTable from './LogsTable';
import QueryItemActions from './QueryItemActions';
import QueryItemsSelector from './QueryItemsSelector';
import useLogs from './useLogs';
import useQueryResult from './useQueryResult';
const DEFAULT_QUERY = `fields fromMillis(time) as @time, level, msg, @message
| sort time desc
| limit 1000`;
const DEFAULT_DATE_RANGE = {
    offset: '1 h'
};
const RELATIVE_DATE_RANGES = ['h', 'd', 'w', 'M'];
const MAX_DATE_RANGE = ms('366 days') + 1000;
const LogsPage = ({ setLinks, setCurrentBreadcrumbText, contentHeight, queryItemId, basePath }) => {
    const { t } = useI18n();
    const scrollableContainerRef = useRef(null);
    const dispatch = useDispatch();
    const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);
    const [query, setQuery] = useState(() => {
        if (!queryItemId) {
            return DEFAULT_QUERY;
        }
    });
    const { enableLogs, isEnableLogsLoading, isLogsSettingsLoading, isLogsEnabled } = useLogs();
    const { queryId, queryResult, runQuery, cancelQuery, isQueryResultLoading, isCancelQueryLoading, isStartQueryLoading } = useQueryResult();
    const { data: queryItem, isFetching: isQueryItemLoading } = useEntityItem(LOG_QUERIES_DEFINITION_ID, queryItemId, ['title', 'configuration'], isLogsEnabled);
    const { mutateAsync: saveQueryItem } = useCreateItem({
        definitionId: LOG_QUERIES_DEFINITION_ID
    });
    const { mutateAsync: deleteQueryItem, isLoading: isQueryItemDeleteLoading } = useDeleteItems({
        definitionId: LOG_QUERIES_DEFINITION_ID
    });
    const excludeElementSelectors = useMemo(() => {
        return [`.${styles.wrapper} .logs-header`];
    }, []);
    const { height } = useContentHeight({
        excludeElementSelectors: excludeElementSelectors,
        /**
         * @TODO `contentHeight` is incorrect, `#side-menu-title` should be removed from it
         **/
        extraMargin: -54,
        parentContentHeight: contentHeight
    });
    useEffect(() => {
        if (!queryItemId) {
            setQuery(DEFAULT_QUERY);
        }
        if (isQueryItemLoading) {
            return;
        }
        setQuery((prev) => {
            if (prev === undefined && queryItem) {
                return queryItem.configuration.query;
            }
            return prev;
        });
    }, [isQueryItemLoading, queryItem, queryItemId]);
    useEffect(() => {
        let title = t('LOGS_TITLE');
        if (queryItem) {
            title = queryItem.title ?? t('NO_TITLE');
            setLinks([
                { text: t('SETTINGS'), to: SETTINGS_PATH },
                { text: t('LOGS_TITLE'), to: basePath }
            ]);
        }
        else {
            setLinks([{ text: t('SETTINGS'), to: SETTINGS_PATH }]);
        }
        setCurrentBreadcrumbText(title);
    }, [basePath, queryItem, setCurrentBreadcrumbText, setLinks, t]);
    const onClickEnableLogs = useCallback(async () => {
        try {
            await enableLogs();
        }
        catch (err) {
            dispatch(error({
                title: t('ERROR'),
                message: getErrorMessage(err, t)
            }));
        }
    }, [dispatch, enableLogs, t]);
    const onChangedDateRange = useCallback((value) => {
        setDateRange(value);
    }, []);
    const onSelectedStoredQuery = useCallback((value) => {
        setQuery(value.configuration.query);
        dispatch(push({
            pathname: `${basePath}/${value.entityId}`
        }));
    }, [basePath, dispatch]);
    const onRunQuery = useCallback(async () => {
        if (!query) {
            return;
        }
        const preparedDateRange = dateRangeSelectorValueToAbsoluteValue(dateRange);
        if (!preparedDateRange) {
            return;
        }
        if (preparedDateRange[1] - preparedDateRange[0] > MAX_DATE_RANGE) {
            dispatch(error({
                title: t('ERROR'),
                message: t('LOGS_ERROR_MAXIMUM_DATE_RANGE_REACHED')
            }));
            return;
        }
        await runQuery(query, preparedDateRange);
    }, [dateRange, dispatch, query, runQuery, t]);
    const onCancelQuery = useCallback(async () => {
        if (!queryId) {
            return;
        }
        await cancelQuery(queryId);
    }, [cancelQuery, queryId]);
    const onSaveQueryItem = useCallback(async (value) => {
        if (!query) {
            return;
        }
        try {
            const result = await saveQueryItem({
                ...value,
                entityId: value?.entityId || generateId(),
                configuration: {
                    query
                }
            });
            if (result.entityId !== queryItemId) {
                dispatch(push({
                    pathname: `${basePath}/${result.entityId}`
                }));
            }
        }
        catch (err) {
            dispatch(error({
                title: t('ERROR'),
                message: getErrorMessage(err, t)
            }));
        }
    }, [basePath, dispatch, query, queryItemId, saveQueryItem, t]);
    const onResetQueryItem = useCallback(() => {
        if (queryItem?.configuration.query) {
            setQuery(queryItem?.configuration.query);
        }
        else {
            setQuery(DEFAULT_QUERY);
        }
    }, [queryItem?.configuration.query]);
    const onDeleteQueryItem = useCallback(async () => {
        if (queryItem && queryItemId) {
            await deleteQueryItem({
                ids: [queryItemId]
            });
            setQuery(undefined);
            dispatch(push({
                pathname: basePath
            }));
        }
    }, [basePath, deleteQueryItem, dispatch, queryItem, queryItemId]);
    const isFirstLoadingPage = isQueryItemLoading && !query;
    const isNotFoundQueryItem = queryItemId && !isQueryItemLoading && !isQueryItemDeleteLoading && !queryItem;
    if (isFirstLoadingPage || isLogsSettingsLoading) {
        return (React.createElement(LoadingOverlay, null,
            React.createElement(LoadingRectangles, null)));
    }
    if (isNotFoundQueryItem) {
        return React.createElement(NotFound, null);
    }
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: "logs-header px-3 py-2 mb-3 d-flex flex-row align-items-center" }, !isLogsEnabled ? (React.createElement("span", { className: "px-3 py-2" }, t('LOGS_TITLE'))) : (React.createElement(React.Fragment, null,
            React.createElement(QueryItemsSelector, { className: "w-100", value: queryItemId, onSelected: onSelectedStoredQuery }),
            React.createElement("div", { className: "d-flex ms-auto" },
                React.createElement(DateRangeSelector, { value: dateRange, onChange: onChangedDateRange, showStartOfPeriod: false, availableRelativeRanges: RELATIVE_DATE_RANGES }))))),
        React.createElement("div", { className: "logs-content scrollable-area position-relative overflow-auto p-3", ref: scrollableContainerRef, style: { height } }, !isLogsEnabled ? (React.createElement("div", { className: "d-flex flex-column align-items-center justify-content-center gap-3 h-100" },
            React.createElement("div", null, t('LOGS_NEEDS_ACTIVATION_TEXT')),
            React.createElement(Button, { className: "", onClick: onClickEnableLogs, loading: isEnableLogsLoading }, t('LOGS_ACTIVATE')))) : (React.createElement(React.Fragment, null,
            React.createElement("span", null,
                t('COMMON__QUERY'),
                ":"),
            React.createElement(CloudWatchQueryEditor, { value: query, onChange: setQuery }),
            React.createElement("div", { className: "buttons d-flex flex-row align-items-center gap-3 mt-3" },
                React.createElement(Button, { className: "fw-600", type: "primary", loading: isStartQueryLoading || isQueryResultLoading, onClick: onRunQuery, disabled: isStartQueryLoading || isQueryResultLoading }, t('LOGS_RUN_QUERY')),
                React.createElement(Button, { className: "fw-600", loading: isCancelQueryLoading, onClick: onCancelQuery, disabled: isCancelQueryLoading || isStartQueryLoading || !isQueryResultLoading }, t('COMMON_CANCEL')),
                React.createElement(QueryItemActions, { className: "fw-600", onSave: onSaveQueryItem, onReset: onResetQueryItem, onDelete: onDeleteQueryItem, queryItem: queryItem }, t('COMMON_SAVE')),
                queryResult?.statistics?.recordsScanned &&
                    queryResult.statistics.recordsScanned > 0 ? (React.createElement("span", { className: "ms-auto" },
                    "Scanned ",
                    queryResult.statistics.recordsScanned.toLocaleString(),
                    " records (",
                    bytesToSize(queryResult?.statistics?.bytesScanned ?? 0),
                    "),",
                    ' ',
                    queryResult.results.length.toLocaleString(),
                    " of",
                    ' ',
                    (queryResult?.statistics?.recordsMatched ?? 0).toLocaleString(),
                    " showing")) : null),
            React.createElement("div", { className: "mt-3 logs-table h-100" }, queryResult?.results.length ? (React.createElement(LogsTable, { scrollableContainerRef: scrollableContainerRef, data: queryResult })) : null))))));
};
export default LogsPage;
