import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { transparentize } from '@hypercharge/hyper-react-base/lib/styling';
import cn from 'classnames';
import { MAX_CMS_ITEMS_LIMIT } from 'config';
import { last } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Virtuoso } from 'react-virtuoso';
import { KanbanCard } from '../KanbanCard';
import { KanbanListHeader } from '../KanbanListHeader';
import styles from './KanbanList.module.scss';
const LIST_BLOCK_PADDING = '8px';
const KANBAN_COLUMN_HEADER_HEIGHT = '40px';
const ColumnLoader = () => (React.createElement("div", { className: cn(styles.loaderCard, 'p-2') },
    React.createElement(LoadingRectangles, null)));
const HeightPreservingItem = ({ children, ...props }) => {
    const [size, setSize] = useState(0);
    const knownSize = props['data-known-size'];
    useEffect(() => {
        setSize((prevSize) => (knownSize == 0 ? prevSize : knownSize));
    }, [knownSize]);
    return (React.createElement("div", { ...props, className: "height-preserving-container", style: {
            '--child-height': `${size}px`
        } }, children));
};
const KanbanList = ({ baseUrl, cards, columnCountIsFetching, columnId, contentHeight, definitionId, disabled, fetchNextPage, fields, filterRequestOpenInNewTab, getCustomRouteHandleCardClick, hasNextPage, hidden, innerRef, isDropDisabled, isFetchingPage, onToggleList, theme, title, totalCountColumn, viewId }) => {
    const columnTitle = useMemo(() => title || last(cards)?.title || columnId, [cards, columnId, title]);
    return (React.createElement("div", { className: cn(styles.list, 'h-100', {
            [styles.list__hidden]: hidden,
            [styles.list__disabled]: disabled
        }), style: {
            borderColor: theme?.primary,
            backgroundColor: theme?.primary,
            ...(theme?.primary && { ['--hoverBackgroundColor']: transparentize(theme?.primary, 0.4) })
        } },
        React.createElement(KanbanListHeader, { totalCountColumn: totalCountColumn, filterRequestOpenInNewTab: filterRequestOpenInNewTab, baseUrl: baseUrl, columnId: columnId, onToggleList: onToggleList, hidden: hidden, title: columnTitle, theme: theme, columnCountIsFetching: columnCountIsFetching }),
        React.createElement("div", { ref: innerRef },
            React.createElement(Virtuoso, { className: `custom-scroll-styling ${styles.list_draggableArea} ${hidden ? styles.list_draggableArea__hidden : ''}`, components: {
                    Item: HeightPreservingItem,
                    Footer: hasNextPage || isFetchingPage ? ColumnLoader : undefined
                }, data: cards, style: {
                    width: 282,
                    minWidth: 282,
                    maxWidth: 282,
                    paddingTop: 8,
                    top: 4,
                    height: contentHeight
                        ? `calc(${contentHeight} - ${KANBAN_COLUMN_HEADER_HEIGHT} - ${LIST_BLOCK_PADDING})`
                        : '100vh'
                }, itemContent: (index, item) => (React.createElement(Draggable, { draggableId: item.entityId, index: index, key: item.entityId, isDragDisabled: isDropDisabled }, (provided) => (React.createElement(KanbanCard, { cards: cards, totalCountColumn: totalCountColumn, columnFilterRequest: {
                        ...filterRequestOpenInNewTab,
                        limit: Math.min(totalCountColumn, MAX_CMS_ITEMS_LIMIT)
                    }, getCustomRouteHandleCardClick: getCustomRouteHandleCardClick, baseUrl: baseUrl, card: item, definitionId: definitionId, fields: fields, provided: provided, viewId: viewId })))), endReached: () => void fetchNextPage() }))));
};
export default KanbanList;
