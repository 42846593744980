import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tabs } from 'antd';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from 'config';
import { isObject } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { IoMdAlert } from 'react-icons/io';
import { Field } from 'redux-form';
import * as Yup from 'yup';
import styles from './MultiLanguageField.module.scss';
export const MultiLanguageOptionalValidationSchema = Yup.lazy(() => Yup.object({
    ...AVAILABLE_LANGUAGES.reduce((acc, lang) => {
        acc[lang] = Yup.string().nullable();
        return acc;
    }, {}),
    [DEFAULT_LANGUAGE]: Yup.string()
}));
export const MultiLanguageValidationSchema = Yup.lazy(() => Yup.object({
    ...AVAILABLE_LANGUAGES.reduce((acc, lang) => {
        acc[lang] = Yup.string().nullable();
        return acc;
    }, {}),
    [DEFAULT_LANGUAGE]: Yup.string().required('Required')
}).required('Required'));
const isChangeEvent = (data) => {
    return isObject(data) && 'target' in data && isObject(data.target) && 'value' in data.target;
};
export const MultiLanguageInput = ({ label, className, value, onChange, onBlur, error, languages = AVAILABLE_LANGUAGES, defaultLanguage = DEFAULT_LANGUAGE, fieldComponent: FieldComponent, onChangeByLanguage, autoFocus, meta, input, extraFieldComponentProps, ...otherProps }) => {
    const { t } = useI18n();
    const currentValue = useMemo(() => {
        const dirtyValue = input?.value || value || {};
        if (typeof dirtyValue === 'string') {
            return {
                [defaultLanguage]: dirtyValue
            };
        }
        return dirtyValue;
    }, [defaultLanguage, input?.value, value]);
    const _onChange = useCallback((language, eventOrValue) => {
        let newValue;
        if (isChangeEvent(eventOrValue)) {
            // @ts-expect-error - Type string is not assignable to type T
            newValue = eventOrValue.target.value || '';
        }
        else {
            newValue = eventOrValue;
        }
        onChangeByLanguage && onChangeByLanguage(newValue, language);
        const toChangeValue = {
            ...currentValue,
            [language]: newValue
        };
        if (onChange) {
            onChange && onChange(toChangeValue);
        }
        else if (input?.onChange) {
            input?.onChange && input?.onChange(toChangeValue);
        }
    }, [currentValue, input, onChange, onChangeByLanguage]);
    const _onBlur = useCallback(() => {
        if (onBlur) {
            onBlur && onBlur();
        }
        else if (input?.onBlur) {
            input?.onBlur && input?.onBlur(undefined);
        }
    }, [input, onBlur]);
    const errors = useMemo(() => {
        if (error) {
            return error;
        }
        if (meta && (meta.touched || meta.submitFailed)) {
            return meta.error;
        }
    }, [error, meta]);
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''}` },
        label && React.createElement(LabelText, null, label),
        React.createElement(Tabs, { defaultActiveKey: languages[0], size: "small", items: languages.map((language, i) => ({
                key: language,
                label: (React.createElement("span", { className: errors && errors[language] ? 'invalid' : '' },
                    t(language),
                    errors && errors[language] && React.createElement(IoMdAlert, { className: "alertIcon" }))),
                children: (React.createElement(FieldComponent, { className: "mb-2", autoFocus: i === 0 && autoFocus, value: currentValue[language] || '', onBlur: _onBlur, error: errors?.[language], onChange: (event) => {
                        _onChange(language, event);
                    }, ...extraFieldComponentProps, ...otherProps }))
            })) })));
};
const MultiLanguageField = ({ name, component, placeholder, ...otherProps }) => {
    const { t } = useI18n();
    return (
    // @ts-expect-error
    React.createElement(Field, { name: name, component: MultiLanguageInput, fieldComponent: component, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')}${placeholder ? ` ${t(placeholder)}` : ''}`, ...otherProps }));
};
export default MultiLanguageField;
