import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { isString } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { TITLE_PROPERTY_ID } from '../../../../../cms';
import { useEntityDisplayData } from '../../../../../cms/common/components/withEntityDisplayData';
import { getPropertyById } from '../../../../../cms/common/utils/utils';
import { replaceConditionFromContextDataInFilters } from '../../../../../common/components/ConditionQuery/utils';
import { useHeight } from '../../../../../common/components/height-context/HeightContext';
import { useView } from '../../../../../views/components/ViewContext';
import { Assignee } from '../../../../common/components/Assignee';
import { DisplayStatusTag } from '../../../../common/components/DisplayStatusTag';
import { DisplayTaskDeadline } from '../../../../common/components/DisplayTaskDeadline';
import DisplayPriorityTag from '../../../../common/components/display-priority-tag';
import { ALL_TASKS_DEFINITION_ID } from '../../../../common/utils/constants';
import { TaskStatus } from '../../../../common/utils/types';
import { getProcessUrl } from '../../../../common/utils/utils';
import styles from '../TasksTableWrapper/TasksTableWrapper.module.scss';
var TasksTableSize;
(function (TasksTableSize) {
    TasksTableSize["large"] = "large";
    TasksTableSize["small"] = "small";
})(TasksTableSize || (TasksTableSize = {}));
const WorkflowsTasksTableList = ({ emptyText, size = TasksTableSize.large, getCustomRouteHandleTaskClick }) => {
    const { contentHeight } = useHeight();
    const { t, language } = useI18n();
    const history = useHistory();
    const { definitionId, filterRequestForSearch, searchItems: { data: { results: tasks = [], totalCount = 0 } = {}, isPending, isFailed: isError } } = useView();
    const { displayData } = useEntityDisplayData(definitionId);
    const titleProperty = getPropertyById(displayData, TITLE_PROPERTY_ID);
    const titleTranslatedKey = titleProperty?.meta.translations?.[language];
    return (React.createElement("div", { className: `${styles.container} overflow-y-auto custom-scroll-styling`, style: { height: contentHeight } }, isError ? (React.createElement("div", { className: `${styles.simpleRow} p-4` },
        React.createElement("i", null, t('SOMETHING_WENT_WRONG')))) : isPending ? (React.createElement("div", { className: styles.loader },
        React.createElement("div", { className: styles.loader__container },
            React.createElement(LoadingRectangles, null)))) : tasks.length > 0 ? (React.createElement(React.Fragment, null, tasks.map(({ entityId, taskDeadline, taskPriority, taskProcessCustomTitle, taskProcessMetaId, taskProcessRunId, taskStatus, title, taskAssignedTo, ...task }) => (React.createElement("div", { key: entityId, className: styles.task__item },
        React.createElement("div", { role: "button", className: `${styles.task__link} ${size === TasksTableSize.small ? styles.task__link_smallSize : ''}`, onClick: async (e) => {
                e.preventDefault();
                try {
                    const asyncPath = (await getCustomRouteHandleTaskClick?.({
                        entityId,
                        taskDeadline,
                        taskPriority,
                        taskProcessCustomTitle,
                        taskProcessMetaId,
                        taskProcessRunId,
                        taskStatus,
                        title,
                        taskAssignedTo,
                        ...task
                    })) || getProcessUrl(taskProcessMetaId, taskProcessRunId);
                    const entityIds = tasks.map((task) => task.taskProcessRunId);
                    const definitionIds = tasks.map((task) => task.taskProcessMetaId);
                    const links = tasks
                        .map((task) => task?.taskProcessLink?.link)
                        .filter(isString);
                    if (e.metaKey || e.ctrlKey) {
                        window.open(asyncPath, '_blank')?.focus();
                    }
                    else {
                        history.push(asyncPath, {
                            definitionId: ALL_TASKS_DEFINITION_ID,
                            data: { entityIds, definitionIds, totalCount, links },
                            filterRequest: {
                                ...filterRequestForSearch,
                                query: replaceConditionFromContextDataInFilters(filterRequestForSearch.query, filterRequestForSearch.context)
                            },
                            isTaskDefinition: true
                        });
                    }
                }
                catch (error) {
                    console.error('Error fetching data:', error);
                }
            } },
            size === TasksTableSize.large && (React.createElement("div", { className: styles.task__status },
                taskStatus !== TaskStatus.SUCCEEDED && (React.createElement(DisplayPriorityTag, { priority: taskPriority, className: "me-2" })),
                React.createElement(DisplayStatusTag, { className: styles.task__tag, status: taskStatus }))),
            React.createElement("div", { className: styles.task__info },
                React.createElement("div", { className: `${styles.task__infoTop} ${size === TasksTableSize.small ? styles.task__infoTop_smallSize : ''}` },
                    React.createElement("div", { className: styles.task__title }, (titleTranslatedKey && task[titleTranslatedKey]) || title),
                    size === TasksTableSize.small && (React.createElement("div", { className: styles.task__status },
                        React.createElement(DisplayPriorityTag, { priority: taskPriority, tooltipPlacement: "left", className: "me-1" }),
                        React.createElement(DisplayStatusTag, { status: taskStatus }))),
                    React.createElement(Assignee, { className: size === TasksTableSize.small ? styles.task__assignee : '', contactId: taskAssignedTo })),
                React.createElement("div", { className: styles.task__infoBottom },
                    React.createElement("div", { className: styles.task__processInfo }, taskProcessCustomTitle),
                    React.createElement("div", { className: styles.task__deadline },
                        React.createElement(DisplayTaskDeadline, { taskDeadline: taskDeadline })))))))))) : (React.createElement("div", { className: `${styles.simpleRow} p-4` },
        React.createElement("i", null, emptyText)))));
};
export default WorkflowsTasksTableList;
