import { BasicInput, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button } from 'antd';
import React, { useCallback, useState } from 'react';
import { Drawer, DrawerContent } from '../../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../../common/components/Drawer/DrawerContentBlock';
import Toggle from '../../../../../common/components/Toggle';
import { TooltipTruncate } from '../../../../../common/components/TooltipTruncate';
import FormikError from '../../../../../common/components/formik/FormikError';
import FormikField from '../../../../../common/components/formik/FormikField';
import { getPropertyTypeComponent } from '../../../../../workflows/meta/components/meta-definition-form/utils';
import { useEntityDisplayData } from '../../../../common/components/withEntityDisplayData';
import { getFlattenedDisplayDataList } from '../../../utils';
import styles from '../MappingStep.module.scss';
import { unsupportedPropertyTypes } from '../constants';
import { getFileType, getPropertyConfig } from '../utils';
const CreateIfNotExists = ({ propertyConfig, property, name, entities, columns, values }) => {
    const { t, language } = useI18n();
    const [showNewEntityMap, setShowNewEntityMap] = useState(false);
    const definitionId = property.meta.definitionId;
    const PropertyConfig = getPropertyConfig(values);
    const fileType = getFileType(values);
    const { displayData: definition } = useEntityDisplayData(definitionId);
    const onDrawerClose = useCallback(() => {
        setShowNewEntityMap(false);
    }, []);
    const onClickOpenNestedEntityMapping = useCallback((event) => {
        event.stopPropagation();
        setShowNewEntityMap(true);
    }, []);
    return (React.createElement("div", { className: "pt-2" },
        React.createElement("div", { className: "d-flex" },
            React.createElement(LabelText, { className: "w-100" }, t('CMS_IMPORTER__CREATE_IF_NOT_EXISTS_TOGGLE')),
            React.createElement(FormikField, { name: `${name}.createIfNotExists`, component: Toggle })),
        propertyConfig?.createIfNotExists && (React.createElement(React.Fragment, null,
            React.createElement(Button, { className: "px-0", type: "link", onClick: onClickOpenNestedEntityMapping },
                React.createElement(TooltipTruncate, null, t('CMS_IMPORTER__OPEN_MAPPER_FOR_NEW_ENTITIES'))),
            React.createElement(Drawer, { title: t('CMS_IMPORTER__NEW_ENTITY_MAP_DRAWER'), className: styles.noExistsConfig, onClose: onDrawerClose, open: showNewEntityMap, destroyOnClose: true, closable: true },
                React.createElement(DrawerContent, { loading: !definition },
                    definition && ['json', 'xml'].includes(fileType) && (React.createElement(DrawerContentBlock, null,
                        React.createElement(LabelText, null, t('CMS_IMPORTER__ROOT_PATH_LABEL')),
                        React.createElement(FormikField, { name: `${name}.rootPath`, component: BasicInput }),
                        React.createElement(FormikError, { name: `${name}.rootPath` }),
                        React.createElement(LabelText, { className: "cp-c-row cp-c-align-start-center" }, t('CMS_IMPORTER__ROOT_NODE_TOGGLE')),
                        React.createElement(FormikField, { name: `${name}.rootNode`, component: Toggle }))),
                    definition &&
                        getFlattenedDisplayDataList(definition).map((prop) => {
                            const { propertyId, labels, type, canEdit, meta = {} } = prop;
                            const Icon = getPropertyTypeComponent(type);
                            const label = `${getTranslation(labels, language)} (${propertyId})`;
                            return (React.createElement(DrawerContentBlock, { icon: Icon, title: `${label} ${(meta.validations?.required && t('CMS_IMPORTER__REQUIRED')) || ''}`, key: propertyId }, unsupportedPropertyTypes.includes(type) ? (React.createElement("div", { className: "d-flex align-items-center" }, t('CMS_IMPORTER__IMPORTING_NOT_SUPPORTED'))) : !canEdit && propertyConfig.propertyId !== propertyId ? (React.createElement("div", { className: "d-flex align-items-center" }, t('CMS_IMPORTER__NO_PERMISSIONS'))) : (React.createElement("div", null,
                                React.createElement(PropertyConfig, { propertyConfig: propertyConfig.propertyMappings &&
                                        propertyConfig.propertyMappings[propertyId], values: values, columns: columns, name: `${name}.propertyMappings.${propertyId}`, property: prop, entities: entities })))));
                        })))))));
};
export default CreateIfNotExists;
