import { ErrorMessage } from '@hypercharge/hyper-react-base/lib/form';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Divider, Switch } from 'antd';
import { cloneDeep, every, isEqual, last, partition, some, uniq, without } from 'lodash';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { Field } from 'redux-form';
import { ENTITY_ID_PROPERTY_ID, EntityEditor, getFlattenedDisplayDataList } from '../../../cms';
import { useEntityDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import { PropertiesProvider } from '../../../cms/common/context/PropertiesProvider';
import { ConditionQueryField } from '../../../common/components/ConditionQuery';
import { Drawer, DrawerContent, DrawerFooter } from '../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../common/components/Drawer/DrawerFormFooter';
import { Select } from '../../../common/components/Select';
import FormikField from '../../../common/components/formik/FormikField';
import { useBrowserBreakpoints } from '../../../common/components/with-browser-breakpoints';
import { getScrollableAreaOrBody } from '../../../common/utils/common';
import { LoadingBlock } from '../../../common/utils/formUtils';
import { CONTACT_CMS_DEFINITION_ID, DOMAIN_CMS_DEFINITION_ID } from '../../../crm';
import { useDisplayTenant } from '../../../tenant/useDisplayTenant';
import { ALL_USERS_PERMISSION_GROUP, AvailableSystemGroups, DOMAIN_GROUP_PREFIX, INDIVIDUAL_GROUP_PREFIX, OWNER_GROUP_PLACEHOLDER, PERMISSION_GROUP_TRANSLATION_KEYS, SYSTEM_GROUPS } from '../../constants';
import { useGroups } from '../../hooks/useGroups';
import GroupContact from '../GroupContact';
import GroupsList from '../GroupsList';
import ParentViewPermissionWarningIcon from '../ParentViewPermissionWarningIcon/ParentViewPermissionWarningIcon';
import { isConfiguredRowBasedPermission } from '../utils';
import styles from './GroupsField.module.scss';
import { ParentOwnerTranslationKeyContext, getRowPermissionGroupLabel } from './utils';
function isSystemAvailableGroup(groupName) {
    return !!AvailableSystemGroups[groupName];
}
const GroupsField = ({ names, permissionsGroupLabels, name, value, parentViewGroups, disabled, isPermissionToggleDisabled = () => false, withValidation, itemMetaDefinitionId, rowPermissions: rowPermissionsAsValue, allowedRowPermissions, onChangeRowPermissions, onChange, createGroups, deleteGroups, executeGroups, readGroups, viewGroups, writeGroups, withOwner = true, forceSelect, isDynamicPermissionSettings = false }) => {
    const { t, language } = useI18n();
    const { groups, groupsStatus } = useGroups();
    const { isMobile } = useBrowserBreakpoints();
    const { displayTenant } = useDisplayTenant();
    const { displayData, displayDataStatus: domainStatus } = useEntityDisplayData(DOMAIN_CMS_DEFINITION_ID);
    const withDomain = displayTenant?.domain;
    const [selectedGroupId, setSelectedGroupId] = useState();
    const [otherSelectValue, setOtherSelectValue] = useState();
    const rowPermissions = value?.permissions || rowPermissionsAsValue;
    const groupsFromProps = useMemo(() => ({
        createGroups: value?.createGroups || createGroups,
        deleteGroups: value?.deleteGroups || deleteGroups,
        executeGroups: executeGroups,
        readGroups: value?.readGroups || readGroups,
        viewGroups: value?.viewGroups || viewGroups,
        writeGroups: writeGroups,
        permissions: rowPermissions
    }), [
        createGroups,
        deleteGroups,
        executeGroups,
        readGroups,
        viewGroups,
        writeGroups,
        value,
        rowPermissions
    ]);
    const domainProperties = useMemo(() => getFlattenedDisplayDataList(displayData).filter(({ system, meta }) => !system && !(('list' in meta && meta.list) || false)), [displayData]);
    const groupListNames = useMemo(() => {
        const availableGroup = [];
        for (const name of names) {
            if (isSystemAvailableGroup(name)) {
                availableGroup.push(name);
                continue;
            }
            if (name.indexOf('.') === -1) {
                continue;
            }
            const lastElem = last(name.split('.'));
            if (!lastElem) {
                continue;
            }
            if (isSystemAvailableGroup(lastElem)) {
                availableGroup.push(lastElem);
            }
        }
        return availableGroup;
    }, [names]);
    const [systemGroups, otherGroups] = useMemo(() => partition(groups, ({ entityId }) => SYSTEM_GROUPS.includes(entityId)), [groups]);
    const isGroupChecked = useCallback((groupListName, groupId) => {
        if (groupId in (forceSelect?.[groupListName] || {})) {
            return !!forceSelect?.[groupListName]?.[groupId];
        }
        if (!groupsFromProps?.[groupListName]) {
            return false;
        }
        const groupList = groupsFromProps[groupListName]?.input?.value ||
            groupsFromProps[groupListName];
        return Array.isArray(groupList) ? groupList.includes(groupId) : false;
    }, [forceSelect, groupsFromProps]);
    const getSelectedContactIds = useCallback(() => {
        const selectedContacts = [];
        for (const groupListName of groupListNames) {
            const groupList = groupsFromProps?.[groupListName];
            const groupIds = groupList?.input?.value || groupList || [];
            for (const groupId of groupIds) {
                if (typeof groupId === 'string' && groupId.startsWith(INDIVIDUAL_GROUP_PREFIX)) {
                    selectedContacts.push(groupId.replace(INDIVIDUAL_GROUP_PREFIX, ''));
                }
            }
        }
        if (rowPermissions && allowedRowPermissions) {
            for (const groupPermission of Object.values(rowPermissions)) {
                for (const groupId of Object.keys(groupPermission)) {
                    if (groupId.startsWith(INDIVIDUAL_GROUP_PREFIX) &&
                        isConfiguredRowBasedPermission(groupId, allowedRowPermissions, rowPermissions)) {
                        selectedContacts.push(groupId.replace(INDIVIDUAL_GROUP_PREFIX, ''));
                    }
                }
            }
        }
        return selectedContacts;
    }, [allowedRowPermissions, groupListNames, groupsFromProps, rowPermissions]);
    const getSelectedOtherGroupIds = useCallback(() => {
        const otherGroups = [];
        for (const { entityId } of groups) {
            if (!SYSTEM_GROUPS.includes(entityId) &&
                some(groupListNames, (name) => isGroupChecked(name, entityId))) {
                otherGroups.push(entityId);
            }
        }
        if (rowPermissions && allowedRowPermissions) {
            for (const groupPermission of Object.values(rowPermissions)) {
                for (const groupId of Object.keys(groupPermission)) {
                    if (!groupId.startsWith(INDIVIDUAL_GROUP_PREFIX) &&
                        !SYSTEM_GROUPS.includes(groupId) &&
                        isConfiguredRowBasedPermission(groupId, allowedRowPermissions, rowPermissions)) {
                        otherGroups.push(groupId);
                    }
                }
            }
        }
        return otherGroups;
    }, [allowedRowPermissions, groupListNames, groups, isGroupChecked, rowPermissions]);
    const [selectedContactIds, setSelectedContactIds] = useState([]);
    const [selectedOtherGroupIds, setSelectedOtherGroupIds] = useState([]);
    useEffect(() => {
        setSelectedContactIds((prevValue) => {
            const newListedContactIds = uniq([...selectedContactIds, ...getSelectedContactIds()]);
            if (isEqual(newListedContactIds, selectedContactIds)) {
                return prevValue;
            }
            return newListedContactIds;
        });
    }, [getSelectedContactIds, selectedContactIds]);
    useEffect(() => {
        setSelectedOtherGroupIds((prevValue) => {
            const newSelectedOtherGroupIds = uniq([
                ...selectedOtherGroupIds,
                ...getSelectedOtherGroupIds()
            ]);
            if (isEqual(newSelectedOtherGroupIds, selectedOtherGroupIds)) {
                return prevValue;
            }
            return newSelectedOtherGroupIds;
        });
    }, [getSelectedOtherGroupIds, selectedOtherGroupIds]);
    const [visibleOtherGroups, selectableOtherGroups] = useMemo(() => partition(otherGroups, ({ entityId }) => selectedOtherGroupIds.includes(entityId)), [otherGroups, selectedOtherGroupIds]);
    const selectableOtherGroupsOptions = selectableOtherGroups.map(({ entityId, title }) => ({
        value: entityId,
        label: title
    }));
    const onChangeSelectContact = useCallback((contactId) => {
        contactId && setSelectedContactIds(selectedContactIds.concat(contactId));
    }, [selectedContactIds]);
    const onSelectOtherGroups = useCallback((value) => {
        setSelectedOtherGroupIds(selectedOtherGroupIds.concat(value));
        setOtherSelectValue(null); // ? solution to clear `Select` component on `onSelect` event
    }, [selectedOtherGroupIds]);
    const metaForContactEditor = useMemo(() => ({
        list: false,
        definitionId: CONTACT_CMS_DEFINITION_ID
    }), []);
    const filterRequest = useMemo(() => ({
        query: {
            condition: ConditionType.and,
            filters: [
                {
                    field: 'groups.entityId',
                    operator: FilterOperatorTypes.contains,
                    data: ALL_USERS_PERMISSION_GROUP
                },
                ...selectedContactIds.map((contactId) => ({
                    field: ENTITY_ID_PROPERTY_ID,
                    operator: FilterOperatorTypes.isNot,
                    data: contactId
                }))
            ]
        },
        sortBy: []
    }), [selectedContactIds]);
    const removeGroupIdFromAllSystemGroups = useCallback((groupsFromProps, groupId) => {
        const updatedCreateGroups = groupsFromProps.createGroups.filter((id) => id !== groupId);
        const updatedDeleteGroups = groupsFromProps.deleteGroups.filter((id) => id !== groupId);
        const updatedViewGroups = groupsFromProps.viewGroups.filter((id) => id !== groupId);
        return {
            ...groupsFromProps,
            createGroups: updatedCreateGroups,
            deleteGroups: updatedDeleteGroups,
            viewGroups: updatedViewGroups
        };
    }, []);
    const toggleGroup = useCallback((groupListName, groupId) => {
        const oldGroups = groupsFromProps[groupListName]?.input?.value ||
            groupsFromProps[groupListName];
        let newGroups;
        if (oldGroups.includes(groupId)) {
            newGroups = oldGroups.filter((g) => g !== groupId);
        }
        else {
            newGroups = [...oldGroups, groupId];
        }
        if (groupsFromProps[groupListName]?.input) {
            groupsFromProps[groupListName]?.input.onChange(newGroups);
        }
        else {
            const groupsFromPropsAfter = {
                ...groupsFromProps,
                [groupListName]: [...newGroups]
            };
            onChange && onChange(groupsFromPropsAfter);
        }
    }, [groupsFromProps, onChange]);
    const removeGroupFromRowPermissions = useCallback((groupId) => {
        if (!rowPermissions || (!onChangeRowPermissions && !onChange)) {
            return;
        }
        const newRowPermissions = cloneDeep(rowPermissions);
        let dirty = false;
        for (const permissionType in rowPermissions) {
            if (groupId in rowPermissions[permissionType]) {
                delete newRowPermissions[permissionType][groupId];
                dirty = true;
            }
        }
        if (dirty) {
            if (onChangeRowPermissions) {
                onChangeRowPermissions(newRowPermissions);
            }
            else {
                const newItemMetaGroups = cloneDeep(groupsFromProps);
                newItemMetaGroups.permissions = newRowPermissions;
                onChange && onChange(removeGroupIdFromAllSystemGroups(newItemMetaGroups, groupId));
            }
        }
    }, [
        rowPermissions,
        onChangeRowPermissions,
        onChange,
        groupsFromProps,
        removeGroupIdFromAllSystemGroups
    ]);
    const onRemoveGroup = useCallback((groupId) => {
        if (every(groupListNames, (groupName) => groupsFromProps[groupName]?.input)) {
            groupListNames.forEach((name) => {
                if (isGroupChecked(name, groupId)) {
                    toggleGroup(name, groupId);
                }
            });
        }
        else {
            onChange && onChange(removeGroupIdFromAllSystemGroups(groupsFromProps, groupId));
        }
        setSelectedOtherGroupIds(without(selectedOtherGroupIds, groupId));
        removeGroupFromRowPermissions(groupId);
    }, [
        groupListNames,
        groupsFromProps,
        isGroupChecked,
        onChange,
        removeGroupFromRowPermissions,
        removeGroupIdFromAllSystemGroups,
        selectedOtherGroupIds,
        toggleGroup
    ]);
    const onRemoveContact = useCallback((contactId) => {
        const groupId = [INDIVIDUAL_GROUP_PREFIX, contactId].join('');
        if (every(groupListNames, (groupName) => groupsFromProps[groupName]?.input)) {
            groupListNames.forEach((name) => {
                if (isGroupChecked(name, groupId)) {
                    toggleGroup(name, groupId);
                }
            });
        }
        else {
            onChange && onChange(removeGroupIdFromAllSystemGroups(groupsFromProps, groupId));
        }
        setSelectedContactIds(without(selectedContactIds, contactId));
        removeGroupFromRowPermissions(groupId);
    }, [
        groupListNames,
        groupsFromProps,
        isGroupChecked,
        onChange,
        removeGroupFromRowPermissions,
        removeGroupIdFromAllSystemGroups,
        selectedContactIds,
        toggleGroup
    ]);
    const closeRowBasedPermissionsDrawer = useCallback(() => {
        setSelectedGroupId(undefined);
    }, []);
    if (groupsStatus.isFailed || (withDomain && domainStatus?.isFailed)) {
        return React.createElement(React.Fragment, null, t('GROUPS_FAILED_TO_LOAD'));
    }
    else if (groupsStatus.isPending || (withDomain && domainStatus?.isPending)) {
        return React.createElement(LoadingBlock, null);
    }
    const isUsedInFormik = onChange && name;
    const ComputedField = isUsedInFormik ? FormikField : Field;
    const computedProps = isUsedInFormik ? { fast: true } : {};
    let numberOfErrorsDisplayedInMobile = 0;
    return (React.createElement(PropertiesProvider, null,
        React.createElement("div", { className: `${styles.fieldsContainer} mt-2 cp-c-sm-row px-2 d-none d-sm-flex` },
            React.createElement("div", { className: `${styles.text} cp-i-sm-flex px-2` }),
            groupListNames.map((name) => (React.createElement("div", { key: name, className: `${styles.text} cp-i-20 cp-i-sm-flex px-2` }, permissionsGroupLabels?.[name]
                ? t(permissionsGroupLabels[name])
                : t(PERMISSION_GROUP_TRANSLATION_KEYS[name])))),
            React.createElement("div", { className: "cp-i-10 px-2" })),
        withValidation && (React.createElement("div", { className: "cp-c-row flex-wrap cp-c-align-end-center cp-c-sm-align-start-center px-2" },
            React.createElement("div", { className: "cp-i-100 cp-i-sm-flex" }),
            groupListNames.map((name) => {
                const groupMeta = groupsFromProps?.[name]?.meta;
                const submitFailed = groupMeta?.submitFailed || false;
                const error = groupMeta?.error || null;
                if (isMobile) {
                    if (submitFailed && error && numberOfErrorsDisplayedInMobile === 0) {
                        numberOfErrorsDisplayedInMobile++;
                        return (React.createElement(ErrorMessage, { key: name, className: "text-end" }, error));
                    }
                    else {
                        return null;
                    }
                }
                return (React.createElement(Fragment, { key: name },
                    React.createElement("div", { className: `${styles.text} cp-i-80 d-sm-none` }),
                    React.createElement("div", { className: "cp-i-20 cp-i-sm-flex cp-c-row cp-c-align-start-start cp-c-sm-align-start-center" },
                        React.createElement(ErrorMessage, null, submitFailed && error))));
            }),
            React.createElement("div", { className: "cp-i-10" }))),
        withOwner && (React.createElement(React.Fragment, null,
            React.createElement(Divider, { className: "mt-0 mx-0 mb-3 px-2", orientation: "left" }, t('OWNER')),
            React.createElement("div", { className: `${styles.fieldsContainer} cp-c-row flex-wrap px-2 py-sm-3` },
                React.createElement("div", { className: `${styles.text} ${styles.groupName} cp-i-100 cp-i-sm-flex px-2 py-sm-3` },
                    React.createElement(ParentOwnerTranslationKeyContext.Consumer, null, (parentOwnerTranslationKey) => t(parentOwnerTranslationKey)),
                    React.createElement(ParentViewPermissionWarningIcon, { groupId: OWNER_GROUP_PLACEHOLDER, groupListNames: groupListNames, isGroupChecked: isGroupChecked, parentViewGroups: parentViewGroups })),
                groupListNames.map((name) => (React.createElement(Fragment, { key: name },
                    React.createElement("div", { className: `${styles.text} cp-i-80 d-sm-none px-2 py-sm-3` }, t(PERMISSION_GROUP_TRANSLATION_KEYS[name])),
                    React.createElement("div", { className: "py-sm-3 px-2 justify-content-start justify-content-sm-center cp-i-20 cp-i-sm-flex cp-c-row cp-c-align-start-start cp-c-sm-align-start-center" },
                        React.createElement(Switch, { checked: isGroupChecked(name, OWNER_GROUP_PLACEHOLDER), checkedChildren: React.createElement(IoMdCheckmark, { className: styles.smallText }), unCheckedChildren: React.createElement(IoClose, { className: styles.smallText }), onChange: () => toggleGroup(name, OWNER_GROUP_PLACEHOLDER), disabled: disabled || isPermissionToggleDisabled(OWNER_GROUP_PLACEHOLDER, name) }))))),
                React.createElement("div", { className: "cp-i-10 py-sm-3 px-2" })))),
        withDomain && (React.createElement(React.Fragment, null,
            React.createElement(Divider, { className: "my-3 mx-0 px-2", orientation: "left" }, t('DOMAIN_GROUPS')),
            domainProperties.length === 0 ? (React.createElement("div", { className: `${styles.smallText} px-2` }, t('NO_GROUPS_AVAILABLE'))) : (React.createElement(GroupsList, { groups: domainProperties.map(({ propertyId, labels }) => ({
                    entityId: [DOMAIN_GROUP_PREFIX, propertyId].join(''),
                    title: getTranslation(labels, language)
                })), isGroupChecked: isGroupChecked, groupListNames: groupListNames, setSelectedGroupId: setSelectedGroupId, rowPermissions: rowPermissions, allowedRowPermissions: allowedRowPermissions, disabled: disabled, isPermissionToggleDisabled: isPermissionToggleDisabled, toggleGroup: toggleGroup, parentViewGroups: parentViewGroups })))),
        React.createElement(Divider, { className: "my-3 mx-0 px-2", orientation: "left" }, t('SYSTEM_GROUPS')),
        systemGroups.length === 0 ? (React.createElement("div", { className: `${styles.smallText} px-2` }, t('NO_GROUPS_AVAILABLE'))) : (React.createElement(GroupsList, { groups: systemGroups, config: { asLink: true }, isGroupChecked: isGroupChecked, groupListNames: groupListNames, setSelectedGroupId: setSelectedGroupId, rowPermissions: rowPermissions, allowedRowPermissions: allowedRowPermissions, disabled: disabled, isPermissionToggleDisabled: isPermissionToggleDisabled, toggleGroup: toggleGroup, parentViewGroups: parentViewGroups })),
        React.createElement(Divider, { className: "my-3 mx-0 px-2", orientation: "left" }, t('CUSTOM_GROUPS')),
        otherGroups.length > 10 ? (React.createElement(React.Fragment, null,
            React.createElement(Select, { className: "w-100 mb-4", getPopupContainer: getScrollableAreaOrBody, showSearch: true, allowClear: false, optionFilterProp: "label", popupMatchSelectWidth: true, notFoundContent: null, placeholder: `${t('SEARCH')} ${t('COMMON__GROUP')}`, onSearch: undefined, disabled: disabled, defaultActiveFirstOption: false, value: otherSelectValue, onSelect: onSelectOtherGroups, options: selectableOtherGroupsOptions }),
            React.createElement(GroupsList, { groups: visibleOtherGroups, config: { asLink: true }, isGroupChecked: isGroupChecked, groupListNames: groupListNames, setSelectedGroupId: setSelectedGroupId, rowPermissions: rowPermissions, allowedRowPermissions: allowedRowPermissions, disabled: disabled, isPermissionToggleDisabled: isPermissionToggleDisabled, toggleGroup: toggleGroup, parentViewGroups: parentViewGroups, onRemove: onRemoveGroup }))) : otherGroups.length === 0 ? (React.createElement("div", { className: `${styles.smallText} px-2` }, t('NO_GROUPS_AVAILABLE'))) : (React.createElement(GroupsList, { groups: otherGroups, config: { asLink: true }, isGroupChecked: isGroupChecked, groupListNames: groupListNames, setSelectedGroupId: setSelectedGroupId, rowPermissions: rowPermissions, allowedRowPermissions: allowedRowPermissions, disabled: disabled, isPermissionToggleDisabled: isPermissionToggleDisabled, toggleGroup: toggleGroup, parentViewGroups: parentViewGroups })),
        React.createElement(Divider, { className: "my-3 mx-0 px-2", orientation: "left" }, t('INDIVIDUALS')),
        React.createElement(EntityEditor, { onChange: onChangeSelectContact, meta: metaForContactEditor, filterRequest: filterRequest, forceFetch // without forceFetch you can reopen the dropdown and select same entity again
            : true, disabled: disabled, autoFocus: false, withoutCreateNew: true }),
        selectedContactIds.map((contactId) => (React.createElement(GroupContact, { contactId: contactId, disabled: disabled, groupListNames: groupListNames, isGroupChecked: isGroupChecked, isPermissionToggleDisabled: isPermissionToggleDisabled, key: contactId, parentViewGroups: parentViewGroups, rowPermissions: rowPermissions, allowedRowPermissions: allowedRowPermissions, setSelectedGroupId: setSelectedGroupId, toggleGroup: toggleGroup, onRemove: onRemoveContact }))),
        React.createElement(Drawer, { title: t(isDynamicPermissionSettings ? 'DYNAMIC_PERMISSION_SETTINGS' : 'GROUP_ROW_PERMISSIONS'), onClose: closeRowBasedPermissionsDrawer, open: !!selectedGroupId }, itemMetaDefinitionId && selectedGroupId && allowedRowPermissions?.length && (React.createElement(React.Fragment, null,
            React.createElement(DrawerContent, null, allowedRowPermissions.map((permissionGroup) => {
                const label = getRowPermissionGroupLabel(permissionGroup, groupListNames.includes(AvailableSystemGroups.executeGroups));
                return (React.createElement(DrawerContentBlock, { title: allowedRowPermissions.length > 1
                        ? t(PERMISSION_GROUP_TRANSLATION_KEYS[label])
                        : undefined, key: permissionGroup },
                    React.createElement(ComputedField, { ...computedProps, className: "pb-3", name: `${isUsedInFormik ? name + '.' : ''}permissions.${permissionGroup}.${selectedGroupId}.restrictions`, component: ConditionQueryField, definitionId: itemMetaDefinitionId, includeNestedProperties: false })));
            })),
            React.createElement(DrawerFooter, null,
                React.createElement(DrawerFormFooter, { submitText: t('GROUP_PERMISSIONS_VIEW_SUBMIT'), onSubmit: closeRowBasedPermissionsDrawer })))))));
};
export default GroupsField;
