import { isArray, isString } from 'lodash';
import { isPropertyItemMeta } from '~app/cms/data/types';
import { getFlattenedDisplayDataList } from '~app/cms/data/utils';
export const processFieldsData = (displayData, fieldsData) => {
    const availablePropertiesByDefinitionMap = displayData?.reduce((acc, displayData) => {
        const { definitionId } = displayData;
        const flattenedList = getFlattenedDisplayDataList(displayData);
        if (isArray(flattenedList) && isString(definitionId)) {
            flattenedList.forEach((data) => {
                const { propertyId } = data || {};
                if (!acc[definitionId]) {
                    acc[definitionId] = [];
                }
                acc[definitionId].push(propertyId);
            });
        }
        return acc;
    }, {});
    return fieldsData.map((field) => {
        const meta = field.meta;
        if (isPropertyItemMeta(meta) && meta.selector?.expandedView?.properties) {
            const filteredProperties = meta.selector.expandedView.properties.filter((prop) => availablePropertiesByDefinitionMap &&
                availablePropertiesByDefinitionMap[meta.definitionId]?.includes(prop));
            if (filteredProperties.length < meta.selector.expandedView.properties.length) {
                field = {
                    ...field,
                    meta: {
                        ...meta,
                        // @ts-expect-error Diffs with the types of property meta
                        selector: {
                            ...meta.selector,
                            expandedView: { ...meta.selector.expandedView, properties: filteredProperties }
                        }
                    }
                };
            }
        }
        return field;
    });
};
