import { BasicInput, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import Select from 'antd/es/select';
import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { Field, FormSection } from 'redux-form';
import { AntInput } from '../../../../../../common/components/AntInput';
import { ComputedInputField } from '../../../../../../common/components/ComputedExpressionEditor';
import LanguageSelectorInput from '../../../../../../common/components/LanguageSelectorInput/LanguageSelectorInput';
import { CustomEntitySelector } from '../../../../../../common/components/entity-selectors';
import { PORTAL_AVAILABLE_LANGUAGES } from '../../../../../../common/utils/constants';
import { TemplateSelector } from '../../../../../../marketing';
import styles from './EmailTemplateMappingsField.module.scss';
const EmailTemplateMappingsField = ({ className, label, fields, disabled, isComputed, propertiesStatus, displayData, propertiesTree }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: `${className || ''} d-block w-100` },
        label && React.createElement(LabelText, null, label),
        React.createElement("div", null,
            fields.map((member, i) => (React.createElement(FormSection, { key: member, name: member },
                React.createElement("div", { className: "d-flex flex-row align-items-center mb-2" },
                    React.createElement("div", { className: "col-4 me-2" },
                        (!displayData || displayData.type === 'text') && (React.createElement(React.Fragment, null,
                            React.createElement(Field, { component: ComputedInputField, computedComponent: AntInput, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('COMMON__PROPERTY')}`, name: "propertyValue", type: "text", disabled: disabled || (!displayData && !isComputed) || propertiesStatus.isPending }))),
                        displayData && displayData.type === 'entity' && (React.createElement(Field, { component: CustomEntitySelector, name: "propertyValue", definitionId: displayData.meta.definitionId, disabled: disabled || propertiesStatus.isPending, autoFocus: false, popupMatchSelectWidth: false })),
                        displayData && displayData.type === 'number' && (React.createElement(Field, { component: NumberMappingField, name: "propertyValue", type: "number", disabled: disabled || propertiesStatus.isPending })),
                        displayData && displayData.type === 'checkbox' && (React.createElement(Field, { component: CheckBoxMappingField, name: "propertyValue", disabled: disabled || propertiesStatus.isPending }))),
                    React.createElement("div", { className: "col-4 me-2" },
                        React.createElement(Field, { name: "template.id", component: ComputedInputField, computedComponent: TemplateSelector, propertiesTree: propertiesTree, disabled: disabled || propertiesStatus.isPending, autoFocus: false, popupMatchSelectWidth: false })),
                    React.createElement("div", { className: "col-3" },
                        React.createElement(Field, { name: "template.lang", component: ComputedInputField, computedComponent: LanguageSelectorInput, propertiesTree: propertiesTree, disabled: disabled || propertiesStatus.isPending, languages: PORTAL_AVAILABLE_LANGUAGES })),
                    React.createElement("div", { className: "ms-auto" },
                        React.createElement(IoMdClose, { className: `${styles.removeButton} ${disabled || propertiesStatus.isPending ? styles.disabled : ''}`, onClick: () => fields.remove(i) })))))),
            React.createElement("a", { href: "#", onClick: (e) => {
                    e.preventDefault();
                    fields.push({});
                } },
                t('PROCESS_META__ADD_TASK'),
                "\u00A0",
                t('TASK_EMAIL_TEMPLATE_MAPPING')))));
};
export default EmailTemplateMappingsField;
//
// Utils
//
const Option = Select.Option;
const valueToKey = (value) => {
    switch (value) {
        case true:
            return 'true';
        case false:
            return 'false';
        default:
            return undefined;
    }
};
const keyToValue = (value) => {
    switch (value) {
        case 'true':
            return true;
        case 'false':
            return false;
        default:
            return null;
    }
};
const CheckBoxMappingField = ({ input: { value, onChange }, disabled }) => {
    const { t } = useI18n();
    const options = [
        { value: 'true', label: t('YES') },
        { value: 'false', label: t('NO') }
    ];
    return (React.createElement(Select, { value: valueToKey(value), style: { width: '100%' }, onChange: (key) => onChange(keyToValue(key)), disabled: disabled, options: options }));
};
const NumberMappingField = ({ input, disabled }) => (React.createElement(BasicInput, { input: {
        ...input,
        onChange: (e) => input.onChange((e.target.value && parseFloat(e.target.value)) || null)
    }, type: "number", disabled: disabled }));
