import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { formatDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { Button, DatePicker, Input, Radio, Select, Tabs, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { pick } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import Toggle from '../Toggle';
import styles from './DateRangeSelectorPopup.module.scss';
import { DEFAULT_RELATIVE_VALUE } from './constants';
import { isRelativeRangeValue } from './types';
import { getRelativeDateInAbsoluteValue } from './utils';
const hoursMetaValues = [1, 2, 3, 6, 8, 12];
const daysMetaValues = [1, 2, 3, 4, 5, 6];
const weeksMetaValues = [1, 2, 3, 4];
const monthsMetaValues = [1, 3, 6, 9, 12];
const yearsMetaValues = [1, 2, 3, 4, 5];
const showTimeConfig = {
    format: 'HH:mm'
};
const metaOptionsBuilder = (values, unit, labelSuffix = '') => values.map((value) => ({
    label: `${value}${labelSuffix}`,
    value: `${value} ${unit}`
}));
const DEFAULT_TYPE = 'relative';
const DEFAULT_DATE_START = null;
const DEFAULT_DATE_END = null;
const DateRangeSelectorPopup = ({ value, onClickApply, onCancel, availableRelativeRanges, showStartOfPeriod = true }) => {
    const { t } = useI18n();
    const [type, setType] = useState(DEFAULT_TYPE);
    const [absoluteRange, setAbsoluteRange] = useState([
        DEFAULT_DATE_START,
        DEFAULT_DATE_END
    ]);
    const [relativeOffset, setRelativeOffset] = useState(DEFAULT_RELATIVE_VALUE);
    const pickerOuterRef = useRef(null);
    const dateSelectorPopupRelativeRowsMeta = useDateRangeSelectorPopupRelativeRowsMeta(availableRelativeRanges);
    const { dateStart, timeStart, dateEnd, timeEnd } = useMemo(() => {
        const [start, end] = absoluteRange;
        return {
            dateStart: start?.format('YYYY-MM-DD') || '',
            timeStart: start?.format('HH:mm') || '',
            dateEnd: end?.format('YYYY-MM-DD') || '',
            timeEnd: end?.format('HH:mm') || ''
        };
    }, [absoluteRange]);
    const startTime = useMemo(() => {
        if (!relativeOffset.offset || !relativeOffset.fromStart) {
            return 0;
        }
        const [startTime] = getRelativeDateInAbsoluteValue(relativeOffset);
        return startTime;
    }, [relativeOffset]);
    const getPopupContainer = useCallback((node) => {
        return pickerOuterRef.current || node;
    }, []);
    const onCalendarChange = useCallback((dates) => {
        setAbsoluteRange([dates?.[0] || null, dates?.[1] || null]);
    }, []);
    /**
     * Relative inputs
     */
    const onChangeRelativeOffset = useCallback((e) => {
        setRelativeOffset((prevValue) => {
            if (e.target.value === prevValue.offset) {
                return prevValue;
            }
            return {
                ...prevValue,
                offset: e.target.value
            };
        });
    }, []);
    const onChangeRelativeFromStart = useCallback((checked) => {
        setRelativeOffset((prevValue) => {
            if (checked === prevValue.fromStart) {
                return prevValue;
            }
            return {
                ...prevValue,
                fromStart: checked
            };
        });
    }, []);
    const onChangeRelativeValue = useCallback(({ target }) => {
        setRelativeOffset((prevValue) => {
            const newOffset = `${target.value} ${prevValue.offset.split(' ')?.[1] || ''}`;
            if (newOffset === prevValue.offset) {
                return prevValue;
            }
            return {
                ...prevValue,
                offset: newOffset
            };
        });
    }, []);
    const onChangeRelativeUnit = useCallback((period) => {
        setRelativeOffset((prevValue) => {
            const newOffset = `${prevValue.offset.split(' ')?.[0] || '1'} ${period}`;
            if (newOffset === prevValue.offset) {
                return prevValue;
            }
            return {
                ...prevValue,
                offset: newOffset
            };
        });
    }, []);
    const onClickClearAll = useCallback(() => {
        setRelativeOffset(DEFAULT_RELATIVE_VALUE);
        setAbsoluteRange([DEFAULT_DATE_START, DEFAULT_DATE_END]);
    }, []);
    const _onClickApply = useCallback(() => {
        if (!onClickApply) {
            return;
        }
        if (type === 'relative') {
            void onClickApply(relativeOffset);
        }
        else {
            void onClickApply({
                time: absoluteRange
            });
        }
    }, [onClickApply, type, relativeOffset, absoluteRange]);
    /**
     * Absolute inputs
     */
    const setAbsoluteRangeDate = useCallback((index, formattedDate) => {
        const [year, month, date] = formattedDate.split('-');
        if (year && month && date) {
            setAbsoluteRange((absoluteRange) => {
                const dateInstance = absoluteRange[index] || dayjs();
                dateInstance.set('year', parseInt(year));
                dateInstance.set('month', parseInt(month) - 1);
                dateInstance.set('date', parseInt(date));
                absoluteRange[index] = dateInstance;
                return [...absoluteRange];
            });
        }
    }, []);
    const setAbsoluteRangeTime = useCallback((index, formattedTime) => {
        const [hour, minute] = formattedTime.split(':');
        if (hour && minute) {
            setAbsoluteRange((absoluteRange) => {
                const dateInstance = absoluteRange[index] || dayjs();
                dateInstance.set('hour', parseInt(hour));
                dateInstance.set('minute', parseInt(minute));
                absoluteRange[index] = dateInstance;
                return [...absoluteRange];
            });
        }
    }, []);
    const onChangeDateStart = useCallback((e) => {
        setAbsoluteRangeDate(0, e.target.value);
    }, [setAbsoluteRangeDate]);
    const onChangeTimeStart = useCallback((e) => {
        setAbsoluteRangeTime(0, e.target.value);
    }, [setAbsoluteRangeTime]);
    const onChangeDateEnd = useCallback((e) => {
        setAbsoluteRangeDate(1, e.target.value);
    }, [setAbsoluteRangeDate]);
    const onChangeTimeEnd = useCallback((e) => {
        setAbsoluteRangeTime(1, e.target.value);
    }, [setAbsoluteRangeTime]);
    /**
     * Type input
     */
    const onChangeType = useCallback((activeKey) => {
        if (activeKey === 'absolute' || activeKey === 'relative') {
            setType(activeKey);
        }
    }, []);
    /**
     * Update internal state by props
     */
    useEffect(() => {
        let newType = 'relative';
        let newRelativeOffset = DEFAULT_RELATIVE_VALUE;
        let newAbsoluteRange = [null, null];
        if (isRelativeRangeValue(value)) {
            newRelativeOffset = value;
        }
        else {
            newType = 'absolute';
            newAbsoluteRange = [
                value.time[0] ? dayjs(value.time[0]) : null,
                value.time[1] ? dayjs(value.time[1]) : null
            ];
        }
        setType(newType);
        setAbsoluteRange(newAbsoluteRange);
        setRelativeOffset(newRelativeOffset);
    }, [value]);
    const tabsItems = useMemo(() => [
        {
            key: 'absolute',
            label: t('ABSOLUTE_DATE'),
            children: (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "picker-wrapper", ref: pickerOuterRef },
                    React.createElement(DatePicker.RangePicker, { popupClassName: "picker-wrapper-inner", open: true, showTime: showTimeConfig, onCalendarChange: onCalendarChange, getPopupContainer: getPopupContainer, value: absoluteRange })),
                React.createElement("div", { className: "picker-inputs" },
                    React.createElement("div", { className: "picker-inputs__row" },
                        React.createElement("div", { className: "picker-inputs__cell" },
                            React.createElement("div", { className: "picker-inputs__label" }, t('DATE_RANGE_SELECTOR_START_DATE')),
                            React.createElement(Input, { className: "d-flex", type: "date", value: dateStart, onChange: onChangeDateStart })),
                        React.createElement("div", { className: "picker-inputs__cell" },
                            React.createElement("div", { className: "picker-inputs__label" }, t('DATE_RANGE_SELECTOR_TIME')),
                            React.createElement(Input, { className: "d-flex", type: "time", value: timeStart, onChange: onChangeTimeStart }))),
                    React.createElement("div", { className: "picker-inputs__row" },
                        React.createElement("div", { className: "picker-inputs__cell" },
                            React.createElement("div", { className: "picker-inputs__label" }, t('DATE_RANGE_SELECTOR_END_DATE')),
                            React.createElement(Input, { className: "d-flex", type: "date", value: dateEnd, onChange: onChangeDateEnd })),
                        React.createElement("div", { className: "picker-inputs__cell" },
                            React.createElement("div", { className: "picker-inputs__label" }, t('DATE_RANGE_SELECTOR_TIME')),
                            React.createElement(Input, { className: "d-flex", type: "time", value: timeEnd, onChange: onChangeTimeEnd }))))))
        },
        {
            key: 'relative',
            label: t('RELATIVE_DATE'),
            children: (React.createElement(React.Fragment, null,
                Object.entries(dateSelectorPopupRelativeRowsMeta).map(([name, meta]) => (React.createElement("div", { key: name, className: "relative-radio-row" },
                    React.createElement("div", { className: "relative-radio-row-label" }, meta.label),
                    React.createElement("div", null,
                        React.createElement(Radio.Group, { options: meta.options, onChange: onChangeRelativeOffset, value: relativeOffset.offset, optionType: "button", buttonStyle: "solid" }))))),
                showStartOfPeriod ? (React.createElement("div", { className: "inputs-row" },
                    React.createElement("div", { className: "inputs-row-cell" },
                        React.createElement("div", { className: "d-flex align-items-center" },
                            React.createElement(Toggle, { checked: relativeOffset.fromStart, onChange: onChangeRelativeFromStart, label: React.createElement(React.Fragment, null,
                                    t('DATE_RANGE_SELECTOR_START_OF_DATE_LABEL'),
                                    relativeOffset.fromStart && (React.createElement("span", { className: "date-range-preview ms-1 fw-semibold" },
                                        "(",
                                        formatDateTime(startTime),
                                        " - ",
                                        t('DATE_NOW'),
                                        ")"))) }),
                            React.createElement(Tooltip, { title: t('DATE_RANGE_SELECTOR_START_OF_DATE_TOOLTIP') },
                                React.createElement(IoMdInformationCircle, { className: "ms-1 infoIcon" })))))) : null,
                React.createElement("div", { className: "inputs-row" },
                    React.createElement("div", { className: "inputs-row-cell" },
                        React.createElement(Input, { type: "number", value: relativeOffset.offset.split(' ')?.[0], onChange: onChangeRelativeValue })),
                    React.createElement("div", { className: "inputs-row-cell" },
                        React.createElement(Select, { value: relativeOffset.offset.split(' ')?.[1], onChange: onChangeRelativeUnit, popupClassName: "periods-selector" }, Object.entries(dateSelectorPopupRelativeRowsMeta).map(([name, meta]) => (React.createElement(Select.Option, { key: name, value: name }, meta.label))))))))
        }
    ], [
        absoluteRange,
        dateEnd,
        dateSelectorPopupRelativeRowsMeta,
        dateStart,
        getPopupContainer,
        onCalendarChange,
        onChangeDateEnd,
        onChangeDateStart,
        onChangeRelativeFromStart,
        onChangeRelativeOffset,
        onChangeRelativeUnit,
        onChangeRelativeValue,
        onChangeTimeEnd,
        onChangeTimeStart,
        relativeOffset.fromStart,
        relativeOffset.offset,
        showStartOfPeriod,
        startTime,
        t,
        timeEnd,
        timeStart
    ]);
    return (React.createElement("div", { className: `${styles.dateWidget} date-selector-widget` },
        React.createElement(Tabs, { activeKey: type, onChange: onChangeType, items: tabsItems }),
        React.createElement("div", { className: "DashboardControlPanelCustomDropdown__footer" },
            React.createElement(Button, { className: "clear-all-btn", type: "link", onClick: onClickClearAll }, t('DATE_RANGE_SELECTOR_CLEAR_ALL')),
            React.createElement(Button, { className: "cancel-btn", onClick: onCancel }, t('DATE_RANGE_SELECTOR_CANCEL')),
            React.createElement(Button, { className: "save-btn", type: "primary", onClick: _onClickApply }, t('DATE_RANGE_SELECTOR_SAVE')))));
};
export default DateRangeSelectorPopup;
export const useDateRangeSelectorPopupRelativeRowsMeta = (availableRelativeRanges) => {
    const { t } = useI18n();
    const dateSelectorPopupRelativeRowsMeta = useMemo(() => {
        const rows = {
            h: {
                label: t('DATE_RANGE_SELECTOR_HOURS'),
                options: metaOptionsBuilder(hoursMetaValues, 'h'),
                optionsReadable: metaOptionsBuilder(hoursMetaValues, 'h', 'h')
            },
            d: {
                label: t('DATE_RANGE_SELECTOR_DAYS'),
                options: metaOptionsBuilder(daysMetaValues, 'd'),
                optionsReadable: metaOptionsBuilder(daysMetaValues, 'd', 'd')
            },
            w: {
                label: t('DATE_RANGE_SELECTOR_WEEKS'),
                options: metaOptionsBuilder(weeksMetaValues, 'w'),
                optionsReadable: metaOptionsBuilder(weeksMetaValues, 'w', 'w')
            },
            M: {
                label: t('DATE_RANGE_SELECTOR_MONTHS'),
                options: metaOptionsBuilder(monthsMetaValues, 'M'),
                optionsReadable: metaOptionsBuilder(monthsMetaValues, 'M', 'm')
            },
            y: {
                label: t('DATE_RANGE_SELECTOR_YEARS'),
                options: metaOptionsBuilder(yearsMetaValues, 'y'),
                optionsReadable: metaOptionsBuilder(yearsMetaValues, 'y', 'y')
            }
        };
        if (availableRelativeRanges?.length) {
            return pick(rows, availableRelativeRanges);
        }
        return rows;
    }, [availableRelativeRanges, t]);
    return dateSelectorPopupRelativeRowsMeta;
};
