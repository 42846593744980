import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import React, { useCallback, useMemo, useState } from 'react';
import { IoMdCreate } from 'react-icons/io';
import useDisplayItemMeta from '../../../../../../../cms/hooks/useDisplayItemMeta';
import { CustomEntitySelector } from '../../../../../../../common/components/entity-selectors';
import styles from './CredentialSelector.module.scss';
import QuickAddCredentialForm from './QuickAddCredentialForm';
import { CREDENTIALS_DEFINITION_ID } from './constants';
const CredentialSelector = ({ credential, disabled, onChange, value, invalid }) => {
    const { t } = useI18n();
    const { data } = useDisplayItemMeta({
        definitionId: CREDENTIALS_DEFINITION_ID
    });
    const selector = useMemo(() => {
        if (!data || !credential.name) {
            return;
        }
        return {
            query: {
                condition: ConditionType.and,
                filters: [{ field: 'type', operator: FilterOperatorTypes.is, data: credential.name }]
            }
        };
    }, [credential.name, data]);
    const quickAddItemForm = useCallback((props) => {
        return React.createElement(QuickAddCredentialForm, { credential: credential, ...props });
    }, [credential]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const onClose = useCallback(() => {
        setIsDrawerOpen(false);
    }, []);
    return data ? (React.createElement(React.Fragment, null,
        React.createElement(QuickAddCredentialForm, { credential: credential, entityId: value, onFinish: onClose, onCancel: onClose, isDrawerOpen: isDrawerOpen, drawerTitle: `${t('EDIT')}` }),
        React.createElement("div", { className: `row ${styles.wrapper}` },
            React.createElement("div", { className: "col" },
                React.createElement(CustomEntitySelector, { multiple: false, input: {
                        value,
                        // @ts-expect-error
                        onChange
                    }, definitionId: data.definitionId, disabled: disabled, autoFocus: false, extraPayloadProps: selector, quickAddItemForm: quickAddItemForm, invalid: invalid, forceFetch: true })),
            React.createElement("div", { className: "col-auto ps-0" },
                React.createElement(IoMdCreate, { className: `edit-button d-flex align-items-center h-100 ${value ? '' : 'disabled'}`, size: "20px", onClick: value
                        ? () => {
                            setIsDrawerOpen(true);
                        }
                        : undefined }))))) : null;
};
export default CredentialSelector;
