import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useState } from 'react';
import { IoIosPlay } from 'react-icons/io';
import { Drawer } from '../../../../common/components/Drawer';
import { useBrowserBreakpoints } from '../../../../common/components/with-browser-breakpoints';
import styles from './StartProcessButton.module.scss';
import StartProcess from './StartProcess';
const StartProcessButton = () => {
    const { isMobile } = useBrowserBreakpoints();
    const { t } = useI18n();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setIsDrawerOpen((prevState) => !prevState);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Drawer, { onClose: toggleDrawer, open: isDrawerOpen, destroyOnClose: true },
            React.createElement(StartProcess, { onClose: toggleDrawer })),
        React.createElement(Button, { onClick: toggleDrawer, className: styles.button },
            React.createElement("div", { className: "d-flex align-items-center" },
                React.createElement(IoIosPlay, { className: "me-sm-2" }),
                !isMobile && t('TASKS_START_NEW_PROCESS')))));
};
export default StartProcessButton;
