import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useMemo } from 'react';
import AceEditor from 'react-ace';
import * as Yup from 'yup';
import JsonEditor from '../../../../../cms/data/components/item-property/editors/JsonEditor';
import MultiTextEditor from '../../../../../cms/data/components/item-property/editors/MultiTextEditor';
import PlainTextEditor from '../../../../../cms/data/components/item-property/editors/PlainTextEditor';
import Toggle from '../../../../../common/components/Toggle';
import FormikField from '../../../../../common/components/formik/FormikField';
import styles from './GeneralOptionsForm.module.scss';
const stageSchema = Yup.object({
    hosts: Yup.array().of(Yup.string())
});
export const getGeneralFormSchema = () => {
    return {
        customHosts: Yup.array().of(Yup.string()),
        live: stageSchema,
        test: stageSchema,
        draft: stageSchema,
        snippet: Yup.string(),
        snippetHead: Yup.string(),
        authorizationKey: Yup.string().nullable(),
        frontendSettings: Yup.object({
            logoUrl: Yup.string().nullable(),
            faviconUrl: Yup.string().nullable(),
            issueLink: Yup.string().nullable(),
            feedbackLink: Yup.string().nullable(),
            requestFileLink: Yup.string().nullable(),
            passQueryThruLink: Yup.boolean().nullable(),
            preserveQueryParams: Yup.array().of(Yup.string())
        }),
        enabledModules: Yup.object({
            hyperDealer: Yup.boolean().nullable(),
            hyperCommerce: Yup.boolean().nullable(),
            hyperRecruiter: Yup.boolean().nullable(),
            volvoRetailerWebsite: Yup.boolean().nullable()
        }).nullable(),
        blocksSets: Yup.array()
            .of(Yup.object({
            id: Yup.string().required(),
            enabled: Yup.boolean().required()
        }))
            .nullable(),
        defaultBlocks: Yup.object({
            header: Yup.string().nullable(),
            footer: Yup.string().nullable()
        }).nullable(),
        propertyConfiguration: Yup.mixed().nullable(),
        robotsObject: Yup.object()
            .shape({
            rules: Yup.lazy((value) => Array.isArray(value)
                ? Yup.array()
                    .of(Yup.object({
                    userAgent: Yup.mixed().nullable(),
                    allow: Yup.mixed().nullable(),
                    disallow: Yup.mixed().nullable(),
                    crawlDelay: Yup.number().nullable()
                }))
                    .nullable()
                : Yup.object({
                    userAgent: Yup.mixed().nullable(),
                    allow: Yup.mixed().nullable(),
                    disallow: Yup.mixed().nullable(),
                    crawlDelay: Yup.number().nullable()
                }).nullable())
        })
            .nullable()
    };
};
const GeneralOptionsForm = ({ configuration, disabled }) => {
    const { t } = useI18n();
    const enabledModules = useMemo(() => [
        { id: 'hyperDealer', label: t('APPS_HYPER_BUILDER__MODULE_HYPER_DEALER') },
        { id: 'hyperCommerce', label: t('APPS_HYPER_BUILDER__MODULE_HYPER_COMMERCE') },
        { id: 'hyperRecruiter', label: t('APPS_HYPER_BUILDER__MODULE_HYPER_RECRUTER') },
        { id: 'volvoRetailerWebsite', label: t('APPS_HYPER_BUILDER__MODULE_VOLVO_RETAILER_WEBSITE') }
    ], [t]);
    const frontendSettingsTextFields = useMemo(() => [
        { id: 'faviconUrl', label: t('APPS_HYPER_BUILDER__FAVICON_URL') },
        { id: 'logoUrl', label: t('APPS_HYPER_BUILDER__LOGO_URL') },
        { id: 'issueLink', label: t('APPS_HYPER_BUILDER__ISSUE_LINK') },
        { id: 'feedbackLink', label: t('APPS_HYPER_BUILDER__FEEDBACK_LINK') },
        { id: 'requestFileLink', label: t('APPS_HYPER_BUILDER__REQUEST_FILE_LINK') }
    ], [t]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row" }, configuration.draft.hosts[0] && (React.createElement(Button, { className: "col-2 ms-auto", href: `https://${configuration.draft.hosts[0]}/builder`, target: "_blank", type: "button" }, t('APPS_HYPER_BUILDER__SITE_BUILDER')))),
        React.createElement("div", { className: `row ${styles.generalOptionsForm}` },
            React.createElement("div", { className: "col-md-4 col-12" },
                React.createElement("span", null,
                    React.createElement("span", null, t('APPS_HYPER_BUILDER__LIVE')),
                    React.createElement("span", null, ":")),
                React.createElement(FormikField, { name: "configuration.live.hosts", component: MultiTextEditor, value: configuration.live.hosts, disabled: disabled })),
            React.createElement("div", { className: "col-md-4 col-12" },
                React.createElement("span", null,
                    React.createElement("span", null, t('APPS_HYPER_BUILDER__TEST')),
                    React.createElement("span", null, ":")),
                React.createElement(FormikField, { name: "configuration.test.hosts", component: MultiTextEditor, value: configuration.test.hosts, disabled: disabled })),
            React.createElement("div", { className: "col-md-4 col-12" },
                React.createElement("span", null,
                    React.createElement("span", null, t('APPS_HYPER_BUILDER__DRAFT')),
                    React.createElement("span", null, ":")),
                React.createElement(FormikField, { name: "configuration.draft.hosts", component: MultiTextEditor, value: configuration.draft.hosts, disabled: disabled })),
            React.createElement("div", { className: "mt-3" },
                React.createElement("span", null,
                    React.createElement("span", null, t('APPS_HYPER_BUILDER__CUSTOM_HOSTS')),
                    React.createElement("span", null, ":")),
                React.createElement(FormikField, { name: "configuration.customHosts", component: MultiTextEditor, value: configuration.customHosts, disabled: disabled, autoFocus: false })),
            React.createElement("div", { className: "col-md-3 col-12 mt-3" },
                React.createElement("span", null,
                    React.createElement("span", null, t('APPS_HYPER_BUILDER__AUTHORIZATION_KEY')),
                    React.createElement("span", null, ":")),
                React.createElement(FormikField, { name: "configuration.authorizationKey", component: PlainTextEditor, value: configuration.authorizationKey, disabled: disabled })),
            frontendSettingsTextFields.map((field) => (React.createElement("div", { className: "col-md-3 col-12 mt-3", key: field.id },
                React.createElement("span", null,
                    React.createElement("span", null, field.label),
                    React.createElement("span", null, ":")),
                React.createElement(FormikField, { name: `configuration.frontendSettings.${field.id}`, component: PlainTextEditor, value: configuration.frontendSettings?.[field.id], disabled: disabled })))),
            React.createElement("div", { className: "col-md-3 col-12 mt-3" },
                React.createElement("span", null,
                    React.createElement("span", null, t('APPS_HYPER_BUILDER__DEFAULT_HEADER_BLOCK')),
                    React.createElement("span", null, ":")),
                React.createElement(FormikField, { name: `configuration.defaultBlocks.header`, component: PlainTextEditor, value: configuration.defaultBlocks?.header, disabled: disabled })),
            React.createElement("div", { className: "col-md-3 col-12 mt-3" },
                React.createElement("span", null,
                    React.createElement("span", null, t('APPS_HYPER_BUILDER__DEFAULT_FOOTER_BLOCK')),
                    React.createElement("span", null, ":")),
                React.createElement(FormikField, { name: `configuration.defaultBlocks.footer`, component: PlainTextEditor, value: configuration.defaultBlocks?.footer, disabled: disabled })),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-md-3 col-12 mt-3" },
                    React.createElement("span", null,
                        React.createElement("span", null, t('APPS_HYPER_BUILDER__PASS_QUERY_THRU_LINK')),
                        React.createElement("span", null, ":")),
                    React.createElement(FormikField, { name: "configuration.frontendSettings.passQueryThruLink", component: Toggle })),
                React.createElement("div", { className: "col-md-3 col-12 mt-3" },
                    React.createElement("span", null, "Preserved Query Params:"),
                    React.createElement(FormikField, { name: "configuration.frontendSettings.preserveQueryParams", component: MultiTextEditor, value: configuration.frontendSettings?.preserveQueryParams, disabled: disabled }))),
            React.createElement("div", { className: "row" },
                React.createElement("span", { className: "mt-3" }, "Enabled modules:"),
                enabledModules.map((field) => (React.createElement("div", { className: "col-md-3 col-12", key: field.id },
                    React.createElement("span", null,
                        field.label,
                        ":"),
                    React.createElement(FormikField, { name: `configuration.enabledModules.${field.id}`, component: Toggle }))))),
            React.createElement("div", { className: `row` },
                React.createElement("div", { className: "mt-3 col-6" },
                    React.createElement("span", null,
                        React.createElement("span", null, t('APPS_HYPER_BUILDER__SNIPPET_HEAD')),
                        React.createElement("span", null, ":")),
                    React.createElement(FormikField, { name: "configuration.snippetHead", component: AceEditor, mode: "html", theme: "textmate", fontSize: 14, showPrintMargin: true, showGutter: true, highlightActiveLine: true, className: "snippet-editing" })),
                React.createElement("div", { className: "mt-3 col-6" },
                    React.createElement("span", null,
                        React.createElement("span", null, t('APPS_HYPER_BUILDER__SNIPPET_BODY')),
                        React.createElement("span", null, ":")),
                    React.createElement(FormikField, { name: "configuration.snippet", component: AceEditor, mode: "html", theme: "textmate", fontSize: 14, showPrintMargin: true, showGutter: true, highlightActiveLine: true, className: "snippet-editing" }))),
            React.createElement("div", { className: "mt-3 col-6" },
                React.createElement("span", null,
                    React.createElement("span", null, t('APPS_HYPER_BUILDER__ROBOTS_OBJECT')),
                    React.createElement("span", null, ":")),
                React.createElement(FormikField, { name: "configuration.robotsObject", component: JsonEditor, value: configuration.robotsObject, disabled: disabled })))));
};
export default GeneralOptionsForm;
