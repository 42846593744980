import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import queryClient, { oneHourInMs, oneMinuteInMs } from '../../common/utils/react-query-client';
import { CMS_API_PATH } from '../common/utils/constants';
const fetchMetaDefinition = (definitionId) => ({
    type: 'ENTITY_META_DEFINITION__FETCH',
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${definitionId}`,
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
const DEFINITION_KEY = 'DEFINITION_KEY';
export const invalidateDefinitionCache = async (definitionId) => {
    await queryClient.invalidateQueries({
        predicate: (query) => {
            return query.queryKey.includes(DEFINITION_KEY) && query.queryKey.includes(definitionId);
        }
    });
};
const useDefinition = ({ definitionId }, options) => {
    const dispatch = useDispatch();
    const { data: definition, isLoading, isError } = useQuery([DEFINITION_KEY, definitionId], async () => {
        if (!definitionId) {
            return;
        }
        return dispatch(fetchMetaDefinition(definitionId));
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs,
        enabled: !!definitionId && (options?.enabled ?? true)
    });
    return {
        definition,
        isLoading,
        isError
    };
};
export default useDefinition;
export const getPropertiesFromDefinition = (definition) => {
    const result = {
        properties: [],
        propertiesMap: {}
    };
    for (const section of definition.sections) {
        for (const property of section.properties) {
            result.properties.push(property);
            result.propertiesMap[property.propertyId] = property;
        }
    }
    return result;
};
