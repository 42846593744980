import { ConditionType, SortOrder } from '@hypercharge/portal-utils';
import { isString } from 'formik';
// ////////////////////
// Query via filters
// ////////////////////
export const isSortOrder = (value) => typeof value === 'string' && Object.values(SortOrder).includes(value);
export const isConditionT = (value) => typeof value === 'string' && Object.values(ConditionType).includes(value);
export var ViewTypes;
(function (ViewTypes) {
    ViewTypes["table"] = "table";
    ViewTypes["kanban"] = "kanban";
})(ViewTypes || (ViewTypes = {}));
export const isViewTypes = (data) => {
    return isString(data) && !!ViewTypes[data];
};
export const isTableView = (viewType) => viewType === ViewTypes.table;
export const isKanbanView = (viewType) => viewType === ViewTypes.kanban;
