import { Skeleton, TextPlaceholder } from '@hypercharge/hyper-react-base/lib/common/skeletons';
import { find } from 'lodash';
import React, { useMemo } from 'react';
import { VIEW_DEFINITION_ID, useViews } from '../..';
import { EditableItemTitleContainer, EditableItemTitleStyles, titleValidations } from '../../../cms';
import { useEntityDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import { TooltipTruncate } from '../../../common/components/TooltipTruncate';
import styles from './EditableViewTitleComponent.module.scss';
const EditableViewTitleComponent = ({ definitionId, entityId, viewId, entityTitle, totalItemsCount }) => {
    const { views, loadingViews } = useViews();
    const isLoading = loadingViews && !views.length;
    const view = useMemo(() => find(views, viewId ? { entityId: viewId } : { referenceDefinitionId: definitionId, isDefault: true }), [definitionId, viewId, views]);
    const { displayDataStatus: { isPending: displayDataStatusIsPending } } = useEntityDisplayData(definitionId);
    if (isLoading) {
        return null;
    }
    return (React.createElement(React.Fragment, null, displayDataStatusIsPending ? (React.createElement(Skeleton, { isLoading: true, className: "d-flex" },
        React.createElement(TextPlaceholder, { heightInPx: 15, widthInPx: 250 }))) : (React.createElement("div", { className: "d-flex align-items-center", style: { minWidth: '0' } },
        view && !view.isDefault && !entityId ? (React.createElement(EditableItemTitleContainer, { className: `${EditableItemTitleStyles.breadcrumbsViewTitle} flex-truncate`, definitionId: VIEW_DEFINITION_ID, entityId: view.entityId, validations: {
                ...titleValidations,
                required: true
            } })) : (React.createElement(TooltipTruncate, null, entityTitle || '')),
        !!totalItemsCount && (React.createElement("span", { className: `${styles.totalCount} ms-2` }, totalItemsCount))))));
};
export default EditableViewTitleComponent;
