import dayjs from 'dayjs';
import moment from 'moment';
import { DEFAULT_RELATIVE_VALUE } from './constants';
import { isRelativeRangeValue } from './types';
export const MAP_FULL_RELATIVE_UNIT = {
    h: 'hour',
    d: 'day',
    w: 'week',
    M: 'month',
    y: 'year'
};
export const getRelativeOptions = (value) => {
    const [relativeValue, relativeUnit] = value.offset.split(' ');
    return {
        relativeValue,
        relativeUnit
    };
};
/**
 * This function has no place here at all, it is declared in DateRangeSelector, but it uses types from Dashboards
 *
 * @deprecated use dateRangeSelectorValueToAbsoluteValue instead
 * @TODO Remove this function or move it to Dashboards
 */
export const getRelativeDateInAbsoluteValue = (rangePicker) => {
    const filter = {
        startTime: 0,
        endTime: 0
    };
    if ('offset' in rangePicker) {
        const { relativeValue, relativeUnit } = getRelativeOptions(rangePicker) || rangePicker.offset.split(' ');
        filter.endTime = moment().valueOf();
        let startTime = moment();
        if (rangePicker?.fromStart) {
            startTime = startTime.subtract(Number(relativeValue) - 1, relativeUnit).startOf(relativeUnit);
        }
        else {
            startTime = startTime.subtract(relativeValue, relativeUnit);
        }
        filter.startTime = startTime.valueOf();
    }
    else {
        if (rangePicker.start) {
            filter.startTime = rangePicker.start;
        }
        if (rangePicker.end) {
            filter.endTime = rangePicker.end;
        }
    }
    return [filter.startTime, filter.endTime];
};
export const dateRangeSelectorValueToAbsoluteValue = (dateRange) => {
    const filter = {
        startTime: 0,
        endTime: 0
    };
    if (isRelativeRangeValue(dateRange)) {
        const { relativeValue, relativeUnit } = getRelativeOptions(dateRange);
        filter.endTime = Date.now();
        let startTime = dayjs();
        const unit = MAP_FULL_RELATIVE_UNIT[relativeUnit];
        if (dateRange?.fromStart) {
            startTime = startTime.subtract(+relativeValue - 1, unit).startOf(unit);
        }
        else {
            startTime = startTime.subtract(+relativeValue, unit);
        }
        filter.startTime = startTime.valueOf();
    }
    else {
        if (dayjs.isDayjs(dateRange.time[0])) {
            filter.startTime = dateRange.time[0].valueOf();
        }
        else if (typeof dateRange.time[0] === 'number') {
            filter.startTime = dateRange.time[0];
        }
        if (dayjs.isDayjs(dateRange.time[1])) {
            filter.endTime = dateRange.time[1].valueOf();
        }
        else if (typeof dateRange.time[1] === 'number') {
            filter.endTime = dateRange.time[1];
        }
    }
    return [filter.startTime, filter.endTime];
};
export const getDateRangeSelectorValue = (rangePicker) => {
    if (!rangePicker) {
        return DEFAULT_RELATIVE_VALUE;
    }
    if ('offset' in rangePicker && rangePicker.offset) {
        // relative
        return {
            ...rangePicker
        };
    }
    else if ('start' in rangePicker || 'end' in rangePicker) {
        // absolute
        return {
            time: [rangePicker.start, rangePicker.end]
        };
    }
    return DEFAULT_RELATIVE_VALUE;
};
