import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { AutoComplete, Input } from 'antd';
import { get } from 'lodash';
import React, { startTransition, useCallback, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { MdOutlineSearch } from 'react-icons/md';
import { useInView } from 'react-intersection-observer';
import { useDebouncedCallback } from 'use-debounce';
import { FULL_TEXT } from '../../../views';
import { useView } from '../../../views/components/ViewContext';
import { useStorage } from '../../context/StorageContext';
import style from './FullTextSearch.module.scss';
const LAST_SEARCH_ELEMENTS_STORAGE_KEY = 'lastSearchElements';
const MAX_LAST_SEARCH_ELEMENTS = 5;
const getNewLastSearchElements = (lastSearchElements, value) => {
    const newLastSearchElements = [...lastSearchElements];
    const index = newLastSearchElements.indexOf(value);
    if (index !== -1) {
        newLastSearchElements.splice(index, 1);
    }
    newLastSearchElements.unshift(value);
    if (newLastSearchElements.length > MAX_LAST_SEARCH_ELEMENTS) {
        newLastSearchElements.pop();
    }
    return newLastSearchElements;
};
const blackListInputElementTagNames = ['INPUT', 'TEXTAREA'];
export const FullTextSearchBase = ({ localStorageKey, value, onChange, hotkeyFocusSearch = true }) => {
    const { t } = useI18n();
    const { ref, inView } = useInView({
        threshold: 0.5
    });
    const inputRef = React.createRef();
    const [searchText, setSearchText] = useState(value ?? '');
    const [lastSearchElements, setLastSearchElements] = useStorage({
        key: LAST_SEARCH_ELEMENTS_STORAGE_KEY,
        defaultValue: {}
    });
    const currentLastSearchElements = useMemo(() => lastSearchElements[localStorageKey ?? ''] ?? [], [localStorageKey, lastSearchElements]);
    const handleSetLastSearchElements = useCallback((value) => {
        if (value && localStorageKey) {
            startTransition(() => {
                setLastSearchElements({
                    ...lastSearchElements,
                    [localStorageKey]: getNewLastSearchElements(currentLastSearchElements, value)
                });
            });
        }
    }, [currentLastSearchElements, localStorageKey, lastSearchElements, setLastSearchElements]);
    const onHotKeyPressed = useCallback((event) => {
        const activeElementTagName = document.activeElement?.tagName;
        if (hotkeyFocusSearch &&
            inView &&
            activeElementTagName &&
            !blackListInputElementTagNames.includes(activeElementTagName)) {
            event.preventDefault();
            inputRef.current?.focus();
        }
    }, [hotkeyFocusSearch, inView, inputRef]);
    useHotkeys('Alt+Q', onHotKeyPressed);
    const handleChange = useCallback((value) => {
        if (onChange) {
            onChange(value);
        }
    }, [onChange]);
    const debouncedOnChange = useDebouncedCallback(handleChange, 300);
    const handleSearchChange = useCallback((event) => {
        if (event.target) {
            const newSearchText = event.target.value || '';
            debouncedOnChange(newSearchText);
            setSearchText(newSearchText);
        }
    }, [debouncedOnChange]);
    const onSelect = useCallback((value) => {
        setSearchText(value);
        startTransition(() => handleChange(value));
    }, [handleChange]);
    const onBlur = useCallback(() => startTransition(() => handleSetLastSearchElements(searchText)), [handleSetLastSearchElements, searchText]);
    const onPressEnter = useCallback(() => startTransition(() => handleSetLastSearchElements(searchText)), [handleSetLastSearchElements, searchText]);
    const placeholder = useMemo(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTouchDevice = 'ontouchstart' in document.documentElement;
        if (!hotkeyFocusSearch || isTouchDevice) {
            return t('SEARCH');
        }
        const isMacOS = /macintosh|mac os x/.test(userAgent);
        if (isMacOS) {
            return t('TABLE__SEARCH_PLACEHOLDER_MACOS');
        }
        return t('TABLE__SEARCH_PLACEHOLDER');
    }, [hotkeyFocusSearch, t]);
    return (React.createElement("div", { ref: ref },
        React.createElement(AutoComplete, { dropdownRender: (menu) => (React.createElement(React.Fragment, null,
                React.createElement("div", { className: style.labelTitle }, t('TABLE__LAST_SEARCH_RESULTS')),
                menu)), value: searchText, options: currentLastSearchElements.map((element) => ({ value: element })), onSelect: onSelect, onBlur: onBlur, ref: inputRef, className: style.searchForm, popupClassName: style.popupMenu },
            React.createElement(Input, { size: "small", prefix: React.createElement(MdOutlineSearch, { color: "var(--neutralColor-4)" }), value: searchText, placeholder: placeholder, allowClear: true, onChange: handleSearchChange, onPressEnter: onPressEnter }))));
};
export const FullTextSearch = ({ hotkeyFocusSearch }) => {
    const { filterRequest, changeFullTextSearch, definitionId } = useView();
    return (React.createElement(FullTextSearchBase, { value: get(filterRequest, FULL_TEXT), onChange: changeFullTextSearch, localStorageKey: definitionId, hotkeyFocusSearch: hotkeyFocusSearch }));
};
export default FullTextSearch;
