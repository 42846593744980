import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType, SortOrder } from '@hypercharge/portal-utils';
import { isEmpty, isFunction } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getFlattenedDisplayDataList, getItemsTableColumns } from '../../../cms';
import { useEntityDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import { AggregationType, TermsAggregationFilterOrderBy, isTermsAggregationResultArray, useAggregation } from '../../../cms/hooks/useAggregation';
import { MISSED_VALUE_PLACEHOLDER_STRING } from '../../../cms/hooks/useAggregation/useAggregation';
import { getColumnPropertyIdsFromParams, getQueryParams } from '../../../views';
import { useView } from '../../../views/components/ViewContext';
import { ALL_TASKS_DEFINITION_ID } from '../../../workflows';
import DataTable2 from './DataTable2';
import TableProvider from './TableProvider';
import { getAdditionalFiltersForSearchInGroup, getFieldGroupingRow } from './utils';
const EMPTY_ARRAY = [];
const DataTable2Container = ({ title, columns, columnRenderer, isFailed = false, singleMode = false, showStartProcessSelector = true, actions = [], leftActions = [], noFilters, noKanban, noSearch, noRowHeightAction, noHotkeySearch, noViewActions, withCmsImport, idField, contentHeight, contentMaxHeight, showViewsSelector, baseUrl, onFilterRequestChange, selectable = true, showFilterSelected }) => {
    const { filterRequest, pageSize, view, definitionId, rowHeightType, changeSortBy, rowGrouping, changeRowGrouping, removeColumns, rowGroupingOrderBy, searchItems: { data: { results = EMPTY_ARRAY, totalCount = 0 } = {}, isPending: isPendingSearchItems, isFailed: isFailedSearchItems } } = useView();
    const { t } = useI18n();
    const { hash } = useLocation();
    const { displayData, displayDataStatus: { isPending } } = useEntityDisplayData(definitionId);
    const columnPropertyIds = useMemo(() => filterRequest?.fullTextFields ?? getColumnPropertyIdsFromParams(getQueryParams(hash), view), [filterRequest?.fullTextFields, hash, view]);
    const viewableEntityProperties = useMemo(() => (displayData ? getFlattenedDisplayDataList(displayData) : []), [displayData]);
    const changeTableSortBy = useCallback((sorted) => {
        if (isFunction(sorted)) {
            const sortedOld = (filterRequest.sortBy || []).map(({ field, order }) => ({
                id: field,
                desc: order === SortOrder.desc
            }));
            const newSortBy = sorted(sortedOld);
            changeSortBy(newSortBy.map((sortableItem) => ({
                field: sortableItem.id,
                order: sortableItem.desc ? SortOrder.desc : SortOrder.asc
            })));
            return;
        }
        const newSortBy = sorted.map(({ id, desc }) => ({
            field: id,
            order: desc ? SortOrder.desc : SortOrder.asc
        }));
        changeSortBy(newSortBy);
    }, [changeSortBy, filterRequest.sortBy]);
    const fieldGroupBy = useMemo(() => getFieldGroupingRow(rowGrouping, displayData), [displayData, rowGrouping]);
    const aggregation = useMemo(() => {
        if (!fieldGroupBy) {
            return;
        }
        return {
            type: AggregationType.term,
            missing: MISSED_VALUE_PLACEHOLDER_STRING,
            field: fieldGroupBy,
            orderBy: rowGroupingOrderBy ?? TermsAggregationFilterOrderBy.count,
            size: 1000
        };
    }, [fieldGroupBy, rowGroupingOrderBy]);
    const aggregations = useMemo(() => {
        if (aggregation) {
            return [aggregation];
        }
        return [];
    }, [aggregation]);
    const { isLoading, data: { aggregations: aggregationsResult } = {} } = useAggregation({
        definitionId,
        aggregations,
        globalFilter: filterRequest,
        enabled: !!rowGrouping
    });
    const groupingResults = useMemo(() => {
        if (aggregationsResult && fieldGroupBy && fieldGroupBy in aggregationsResult) {
            const result = aggregationsResult[fieldGroupBy];
            return ((isTermsAggregationResultArray(result) &&
                result.map((aggregationResult) => {
                    return {
                        groupingValue: aggregationResult.label,
                        count: aggregationResult.count
                    };
                })) ||
                []);
        }
        return [];
    }, [aggregationsResult, fieldGroupBy]);
    useEffect(() => {
        if (onFilterRequestChange) {
            onFilterRequestChange(filterRequest);
        }
    }, [onFilterRequestChange, filterRequest]);
    const errorMessageWhenNoColumns = useMemo(() => {
        if (isEmpty(columnPropertyIds)) {
            return t('DATA_TABLE_NO_COLUMNS_TEXT');
        }
        if (isEmpty(viewableEntityProperties)) {
            return t('DATA_TABLE_NO_PERMISSION_TEXT');
        }
        return t('DATA_TABLE_NO_PROPERTIES_FOR_COLUMNS');
    }, [columnPropertyIds, t, viewableEntityProperties]);
    const [expandedMap, setExpandedMap] = useState({});
    const computedData = useMemo(() => {
        if (rowGrouping) {
            if (isLoading) {
                return [];
            }
            return groupingResults.map((group, index) => {
                return {
                    [rowGrouping]: group.groupingValue === MISSED_VALUE_PLACEHOLDER_STRING
                        ? undefined
                        : group.groupingValue,
                    _countInGroup: group.count,
                    subRows: expandedMap[index]
                };
            });
        }
        if (isPending) {
            return [];
        }
        return results;
    }, [expandedMap, groupingResults, isPending, isLoading, results, rowGrouping]);
    const columnsToDisplay = useMemo(() => columnRenderer
        ? getItemsTableColumns({
            columnPropertyIds,
            viewableEntityProperties,
            rowGrouping,
            onChangeRowGrouping: changeRowGrouping,
            onRemoveColumns: removeColumns,
            itemLinkColumn: {
                id: 'open-item',
                accessorKey: 'open-item',
                header: '',
                enableResizing: false,
                maxSize: 45,
                minSize: 45,
                cell: (cell) => {
                    const parentRow = cell.row.getParentRow();
                    if (parentRow && rowGrouping) {
                        const subRows = parentRow.subRows;
                        return columnRenderer(cell, {
                            entityIds: subRows.map((r) => r.original.taskProcessRunId ?? r.original.entityId),
                            definitionIds: subRows.map((r) => r.original.definitionId),
                            links: subRows.map((r) => r.original.taskProcessLink?.link),
                            totalCount: parentRow.original._countInGroup
                        }, {
                            ...filterRequest,
                            query: {
                                condition: ConditionType.and,
                                filters: [
                                    ...filterRequest.query.filters,
                                    ...getAdditionalFiltersForSearchInGroup(getFieldGroupingRow(rowGrouping, displayData), rowGrouping, parentRow)
                                ]
                            }
                        }, definitionId === ALL_TASKS_DEFINITION_ID);
                    }
                    return columnRenderer(cell, {
                        entityIds: computedData.map((r) => r.taskProcessRunId ?? r.entityId),
                        definitionIds: computedData.map((r) => r.definitionId),
                        links: computedData.map((r) => r.taskProcessLink?.link),
                        totalCount
                    }, filterRequest, definitionId === ALL_TASKS_DEFINITION_ID);
                }
            },
            rowHeightType
        })
        : columns ?? [], [
        columnRenderer,
        columnPropertyIds,
        viewableEntityProperties,
        rowGrouping,
        changeRowGrouping,
        removeColumns,
        rowHeightType,
        columns,
        computedData,
        totalCount,
        filterRequest,
        definitionId,
        displayData
    ]);
    return (React.createElement(ErrorBoundary, null, !isPending && columnsToDisplay.length === 1 ? (React.createElement(SectionCard, { className: "section-card" },
        React.createElement("i", null, errorMessageWhenNoColumns))) : (React.createElement(TableProvider, { data: computedData },
        React.createElement(DataTable2, { title: title, actions: actions, leftActions: leftActions, showItemsLimitError: !!(rowGrouping && groupingResults.length >= (aggregation?.size || 0)), maxItemsCount: rowGrouping ? 1000 : 500, isPending: rowGrouping ? isLoading : isPendingSearchItems, setExpandedMap: setExpandedMap, isFailed: isFailed || isFailedSearchItems, singleMode: singleMode, totalCount: totalCount, pages: Math.ceil(totalCount / pageSize), changeTableSortBy: changeTableSortBy, idField: idField, columns: columnsToDisplay, noRowHeightAction: noRowHeightAction, noFilters: noFilters, noKanban: noKanban, noSearch: noSearch, noViewActions: noViewActions, noHotkeySearch: noHotkeySearch, withCmsImport: withCmsImport, contentHeight: contentHeight, contentMaxHeight: contentMaxHeight, showViewsSelector: showViewsSelector, baseUrl: baseUrl, showStartProcessSelector: showStartProcessSelector, selectable: selectable, showFilterSelected: showFilterSelected })))));
};
export default DataTable2Container;
