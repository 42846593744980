import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { REFERENCE_DEFINITION_ID_PROPERTY_ID } from '../../cms/constants';
import useDeleteItems from '../../cms/hooks/useDeleteItems';
import { RELEASE_NOTES_DEFINITION_ID } from '../../releaseNotes/constants';
import { VIEW_DEFINITION_ID } from '../../views';
import { initialFilterRequest } from '../../views/components/ViewContext';
import { WORKFLOWS_API_PATH } from '../common/utils/constants';
import { invalidateProcessDefinitionQuery } from './invalidateCache';
const deleteProcessDefinition = (processDefinitionId) => ({
    type: 'PROCESS_DEFINITION__DELETE',
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process/${processDefinitionId}`,
            method: 'DELETE',
            allowPromiseReject: true,
            actionContext: { id: processDefinitionId }
        }
    }
});
const useDeleteProcessDefinition = () => {
    const deleteProcessDefinitionDispatch = useDispatch();
    const { mutateAsync: deleteReleaseNotes } = useDeleteItems({
        definitionId: RELEASE_NOTES_DEFINITION_ID
    });
    const { mutateAsync: deleteViews } = useDeleteItems({ definitionId: VIEW_DEFINITION_ID });
    return useMutation({
        mutationFn: async (definitionId) => {
            return deleteProcessDefinitionDispatch(deleteProcessDefinition(definitionId));
        },
        onSettled: async (_response, _error, definitionId) => {
            const deleteFilterRequest = {
                ...initialFilterRequest,
                query: {
                    condition: ConditionType.and,
                    filters: [
                        {
                            field: REFERENCE_DEFINITION_ID_PROPERTY_ID,
                            operator: FilterOperatorTypes.is,
                            data: definitionId
                        }
                    ]
                }
            };
            await deleteReleaseNotes({ filterRequest: deleteFilterRequest });
            await deleteViews({ filterRequest: deleteFilterRequest });
            await invalidateProcessDefinitionQuery(definitionId);
        }
    });
};
export default useDeleteProcessDefinition;
