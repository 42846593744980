import { DOMAIN, TENANT_ID } from 'config';
import React from 'react';
import * as Yup from 'yup';
import MultiTextEditor from '../../../../../cms/data/components/item-property/editors/MultiTextEditor';
import FormikField from '../../../../../common/components/formik/FormikField';
export const getGeneralFormSchema = () => {
    return {
        emailBoxes: Yup.array().of(Yup.string()
            .email()
            .matches(new RegExp(`@${TENANT_ID}.${DOMAIN}$`), `Only domain "${TENANT_ID}.${DOMAIN}" is allowed`))
    };
};
const GeneralOptionsForm = ({ disabled }) => {
    return (React.createElement("div", { className: "row" },
        React.createElement("div", { className: "col-md-6 col-12" },
            React.createElement("span", null, "Email boxes:"),
            React.createElement(FormikField, { name: "configuration.emailBoxes", component: MultiTextEditor, disabled: disabled }))));
};
export default GeneralOptionsForm;
