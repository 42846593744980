import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import React, { useCallback } from 'react';
import { FieldError, FieldWrapper } from '../../FieldElements';
import styles from './NumberEditor.module.scss';
const numberDotsRegex = /\./g;
const numberCommasRegex = /,/g;
const NumberEditor = ({ value, name, onChange, disabled, onKeyDown, autoFocus = true, editing = true, meta, errorMessage }) => {
    const updateValue = useCallback((value) => 
    // @ts-expect-error
    onChange && onChange(value && !isNaN(value) ? Number(value) : null), [onChange]);
    const handleOnChange = useCallback((e) => updateValue(e.target.value), [updateValue]);
    // Tested paste values
    // 1500
    // 1.500
    // 1500.50
    // 1.500.500
    // 1.500,50
    // 1,500
    // 1,500.00
    // 24,000,000.50
    // 1.000.500,50
    // 1 500
    const handleOnPaste = useCallback((e) => {
        e.preventDefault();
        const clipboardText = e.clipboardData?.getData('text/plain') || '';
        // Clean characters that are invalid
        // e.g: 1 500 will work by removing the space
        let cleanedNumber = clipboardText.replace(/[^0-9\x2C\x2E]+/g, '');
        if (clipboardText) {
            const commaIndex = clipboardText.indexOf(',');
            const dotIndex = clipboardText.indexOf('.');
            const hasBothSeparators = commaIndex != -1 && dotIndex != -1;
            let thousandsRegex;
            let decimalRegex;
            if (hasBothSeparators && commaIndex > dotIndex) {
                // 1.500,50
                // 1.000.500,50
                // if the , comes after the . then its being used as decimal
                thousandsRegex = numberDotsRegex;
                decimalRegex = numberCommasRegex;
            }
            else if (hasBothSeparators && commaIndex < dotIndex) {
                // 24,000,000.50
                // 1,500.00
                // if there is a , and comes before . then just remove comma
                // as the . is already used as normal decimal
                thousandsRegex = numberCommasRegex;
            }
            else {
                // 1500
                // 1.500
                // 1500.50
                // 1.500.500
                // 1,500
                const separatorCount = ((dotIndex != -1 ? numberDotsRegex : numberCommasRegex).exec(clipboardText) || []).length;
                if (
                // if more than one separator then it means that its used as thousand separator
                separatorCount > 1 ||
                    // if only one spearator is used then we have no choice and we have to check
                    // the precision and if its not a match then we assume the separator is being used
                    // as a thousand separator and not as decimal
                    (separatorCount == 1 &&
                        (dotIndex != -1 ? dotIndex : commaIndex) + meta.precision != clipboardText.length - 1)) {
                    thousandsRegex = dotIndex == -1 ? numberCommasRegex : numberDotsRegex;
                }
            }
            if (thousandsRegex) {
                cleanedNumber = cleanedNumber.replace(thousandsRegex, '');
            }
            if (decimalRegex) {
                cleanedNumber = cleanedNumber.replace(decimalRegex, '.');
            }
        }
        updateValue(isFinite(parseFloat(cleanedNumber)) ? parseFloat(cleanedNumber) : null);
    }, [meta.precision, updateValue]);
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldWrapper, { className: styles.editor, disabled: disabled, editing: editing, error: !!errorMessage },
            React.createElement(BasicInput, { type: "number", 
                // @ts-expect-error
                input: { value: value === null ? '' : value }, name: name, autoFocus: autoFocus, onPaste: handleOnPaste, onChange: handleOnChange, disabled: disabled, onKeyDown: onKeyDown, preventUpdateSelection: true })),
        React.createElement(FieldError, { error: errorMessage })));
};
export default NumberEditor;
