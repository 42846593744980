import React, { useCallback, useMemo } from 'react';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import ReactTable from '~app/common/components/data-table/ReactTable';
import ColumnHeader from './ColumnHeader';
import LogItemDetails from './LogItemDetails';
const LogsTable = ({ scrollableContainerRef, data }) => {
    const columns = useMemo(() => {
        const columns = [
            {
                id: '#',
                header: ColumnHeader,
                enableResizing: false,
                maxSize: 70,
                minSize: 50,
                cell: ({ row }) => {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: "ms-2" }, row.getIsExpanded() ? React.createElement(FaAngleDown, { size: "1rem" }) : React.createElement(FaAngleRight, { size: "1rem" })),
                        React.createElement("span", { className: "mx-2" }, row.index + 1)));
                }
            }
        ];
        for (const field of data.fields || []) {
            columns.push({
                id: field,
                header: ColumnHeader,
                enableResizing: true,
                accessorFn: (originalRow) => {
                    return originalRow[field];
                },
                cell: ({ getValue }) => {
                    return React.createElement("span", { className: "px-2 text-truncate" }, getValue());
                }
            });
        }
        return columns;
    }, [data.fields]);
    const getRowCanExpand = useCallback(() => {
        return true;
    }, []);
    const getRowComponent = useCallback((row) => {
        if (row.parentId) {
            return LogItemDetails;
        }
        return;
    }, []);
    return (React.createElement(ReactTable, { columns: columns, tableContainerScrollerRef: scrollableContainerRef, data: data.results, pageSize: 10, pageIndex: 0, selectable: false, getRowCanExpand: getRowCanExpand, getRowComponent: getRowComponent }));
};
export default LogsTable;
