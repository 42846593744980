import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications/notificationActions';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import LoadingIndicator from '../../common/components/loading-indicator';
import { getDashboardUrl } from '../actions';
export const EmbeddedDashboard = ({ dashboardId, height, id }) => {
    const { t } = useI18n();
    const dispatchGetDashboardUrl = useDispatch();
    const dispatch = useDispatch();
    const [awsDashboardObject, setAwsDashboardObject] = useState(null);
    const [dashboard, setDashboard] = useState(null);
    const [loading, setLoading] = useState(true);
    // Get dashboard url from QuickSight
    useEffect(() => {
        if (dashboardId) {
            setLoading(true);
            dispatchGetDashboardUrl(getDashboardUrl(dashboardId))
                .then(setDashboard)
                .catch((e) => {
                dispatch(error({
                    title: t('ERROR'),
                    message: e.message
                }));
            })
                .finally(() => setLoading(false));
        }
    }, [dashboardId, dispatch, dispatchGetDashboardUrl, t]);
    // Init board by quicksight library when active board changed
    useEffect(() => {
        if (dashboard) {
            const options = {
                container: `#${id}`,
                footerPaddingEnabled: false
            };
            if (awsDashboardObject === null) {
                options['url'] = dashboard.url;
                setAwsDashboardObject(embedDashboard(options));
            }
            else {
                options['dashboardId'] = dashboardId;
                awsDashboardObject.navigateToDashboard(options);
            }
        }
        // ! `awsDashboardObject` cannot be added dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboard, dashboardId, id]);
    return (React.createElement("div", { className: "position-relative", style: { height }, id: id }, loading && React.createElement(LoadingIndicator, null)));
};
