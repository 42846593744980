import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { Input, Tag } from 'antd';
import cn from 'classnames';
import { get, sortBy, toLower } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MdClose, MdOutlineSearch } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { DrawerContent, DrawerFooter } from '../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../../common/components/Drawer/DrawerFormFooter';
import { startProcess as startProcessAction } from '../../../dashboard/actions';
import useDisplayProcessMetaList from '../../../hooks/useDisplayProcessMetaList';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID } from '../../utils/constants';
import { getProcessUrl } from '../../utils/utils';
import styles from './StartProcess.module.scss';
const useStartProcess = ({ onClose }) => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const { data: displayProcessMetaList, isPending } = useDisplayProcessMetaList();
    const runnableDisplayProcessMetaList = useMemo(() => displayProcessMetaList?.filter(({ canExecute, processMetaId }) => !!canExecute &&
        ![ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID].includes(processMetaId)) || [], [displayProcessMetaList]);
    const startProcess = useCallback(async (processMetaId) => {
        try {
            const data = await dispatch(startProcessAction(processMetaId));
            dispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('TASKS_START_PROCESS_SUCCESS')
            }));
            dispatch(push(getProcessUrl(data.processMetaId, data.id)));
            onClose();
        }
        catch (e) {
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: t('TASKS_START_PROCESS_FAIL')
            }));
        }
    }, [dispatch, onClose, t]);
    return {
        startProcess,
        runnableDisplayProcessMetaList,
        isPending
    };
};
const StartProcess = ({ onClose }) => {
    const [selectedProcessMetaId, setSelectedProcessMetaId] = useState(null);
    const [startingProcess, setStartingProcess] = useState(false);
    const [searchText, setSearchText] = useState('');
    const unmounted = useRef(false);
    const { t } = useI18n();
    const { startProcess, runnableDisplayProcessMetaList, isPending } = useStartProcess({ onClose });
    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);
    const handleSearchChange = (event) => {
        if (event.target instanceof HTMLInputElement) {
            const searchText = get(event, 'target.value', '');
            setSearchText(searchText);
        }
    };
    const toggleStartingProcess = () => {
        if (!unmounted.current) {
            setStartingProcess(!startingProcess);
        }
    };
    const handleSubmit = (e) => {
        e?.preventDefault();
        if (selectedProcessMetaId) {
            toggleStartingProcess();
            startProcess(selectedProcessMetaId).finally(toggleStartingProcess);
        }
    };
    const upperSearchText = searchText.toUpperCase();
    const filteredProcesses = searchText
        ? runnableDisplayProcessMetaList.filter(({ title, idPrefix }) => (title && title.toUpperCase().indexOf(upperSearchText) > -1) ||
            idPrefix.toUpperCase().indexOf(upperSearchText) > -1)
        : runnableDisplayProcessMetaList;
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerContent, { loading: isPending },
            React.createElement(DrawerContentBlock, { title: t('TASKS_START_NEW_PROCESS') },
                React.createElement(Input, { onChange: handleSearchChange, value: searchText, placeholder: t('SEARCH'), prefix: React.createElement(MdOutlineSearch, { size: 16, color: "var(--neutralColor-6)" }), allowClear: {
                        clearIcon: React.createElement(MdClose, { color: "var(--neutralColor-6)", size: 16 })
                    }, size: "small", className: cn(styles.input, 'rounded-5') }),
                React.createElement("div", { className: "d-flex flex-column gap-1" }, filteredProcesses.length ? (sortBy(filteredProcesses, [({ title }) => toLower(title)]).map(({ processMetaId, title, idPrefix, color }) => (React.createElement("div", { className: cn(styles.item, {
                        active: selectedProcessMetaId == processMetaId
                    }), onClick: () => setSelectedProcessMetaId(processMetaId), key: processMetaId },
                    React.createElement("div", { className: "d-flex align-items-center gap-3" },
                        React.createElement(Tag, { className: cn(styles.tag, 'rounded-5 fw-600 d-flex align-items-center justify-content-center'), style: {
                                backgroundColor: color,
                                borderColor: color
                            } }, idPrefix),
                        React.createElement("div", { className: "fw-500" }, title)))))) : (React.createElement("div", { className: cn(styles.noResults, 'p-3 d-flex justify-content-center fw-500') }, t('NO_RESULTS_WERE_FOUND')))))),
        React.createElement(DrawerFooter, null,
            React.createElement(DrawerFormFooter, { disabled: !selectedProcessMetaId, onSubmit: handleSubmit, onCancel: onClose, submitText: "TASKS_START_PROCESS", submitting: startingProcess }))));
};
export default StartProcess;
