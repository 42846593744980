import { useEffect, useState } from 'react';
export const useContentHeight = ({ excludeElementSelectors, parentContentHeight, extraMargin }) => {
    const [height, setHeight] = useState(() => {
        const initialHeight = calculateHeight(excludeElementSelectors, parentContentHeight, extraMargin);
        return initialHeight;
    });
    useEffect(() => {
        setHeight(calculateHeight(excludeElementSelectors, parentContentHeight, extraMargin));
        const onResize = () => {
            setHeight(calculateHeight(excludeElementSelectors, parentContentHeight, extraMargin));
        };
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [excludeElementSelectors, extraMargin, parentContentHeight]);
    return {
        height
    };
};
const calculateHeight = (excludeElementSelectors, parentContentHeight, extraMargin = 0) => {
    let elementsHeight = 0;
    for (const selector of excludeElementSelectors) {
        const element = document.querySelector(selector);
        if (element) {
            const computedStyles = window.getComputedStyle(element);
            const marginTop = parseInt(computedStyles.getPropertyValue('margin-top'));
            const marginBottom = parseInt(computedStyles.getPropertyValue('margin-bottom'));
            const elementHeight = element.offsetHeight + marginTop + marginBottom;
            elementsHeight += elementHeight;
        }
    }
    if (extraMargin) {
        elementsHeight += extraMargin;
    }
    let height;
    if (parentContentHeight) {
        height = `calc(${parentContentHeight} - ${elementsHeight}px)`;
    }
    else {
        height = `calc(100vh - ${elementsHeight}px)`;
    }
    return height;
};
