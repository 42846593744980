import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { error, success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import { useEntityDisplayData } from '~app/cms/common/components/withEntityDisplayData';
import { ENTITY_OWNER_PROPERTY_ID, MY_USER_COMPUTED, TITLE_PROPERTY_ID } from '~app/cms/common/utils/constants';
import { getPropertyById } from '~app/cms/common/utils/utils';
import { KanbanBoardWrapper } from '~app/cms/data/components/items/Kanban/KanbanBoardWrapper';
import { invalidateColumnsCountQuery } from '~app/cms/data/components/items/Kanban/KanbanListHeader/KanbanListHeader';
import { getKanbanColumnQueryKey } from '~app/cms/data/components/items/utils';
import { invalidateAggregationQuery } from '~app/cms/hooks/useAggregation';
import { useEntityItems } from '~app/cms/hooks/useEntityItems';
import { invalidateInfiniteSearchItemsQuery } from '~app/cms/hooks/useSearchItems';
import { useUpdateProperty } from '~app/cms/hooks/useUpdateProperty';
import { ACTIVITY_DEFINITION_ID } from '~app/common/activity';
import { ACTIVITY_ASSIGNED_TO_PROPERTY_ID, ACTIVITY_CONTACT_PROPERTY_ID, ACTIVITY_DUE_DATE_PROPERTY_ID, ACTIVITY_RELATED_TO_DEFINITION_ID_PROPERTY_ID, ACTIVITY_RELATED_TO_ENTITY_ID_PROPERTY_ID } from '~app/common/activity/constants';
import { sleep } from '~app/utils/time';
import { useView } from '~app/views/components/ViewContext';
import { getColumnDefinitionIdKanbanGroupBy } from '~app/views/utils';
export const DESIGN_FIELDS_FOR_KANBAN = {
    workflow: {
        process: [TITLE_PROPERTY_ID, ENTITY_OWNER_PROPERTY_ID, 'processStatus'],
        task: [
            TITLE_PROPERTY_ID,
            'taskProcessCustomTitle',
            'taskProcessRunId',
            'taskPriority',
            'taskStatus',
            'taskAssignedTo',
            'taskDeadline'
        ]
    },
    cms: {
        [ACTIVITY_DEFINITION_ID]: [
            TITLE_PROPERTY_ID,
            ENTITY_OWNER_PROPERTY_ID,
            ACTIVITY_ASSIGNED_TO_PROPERTY_ID,
            ACTIVITY_DUE_DATE_PROPERTY_ID,
            ACTIVITY_RELATED_TO_DEFINITION_ID_PROPERTY_ID,
            ACTIVITY_RELATED_TO_ENTITY_ID_PROPERTY_ID,
            ACTIVITY_CONTACT_PROPERTY_ID
        ],
        default: ['title', 'createdAt', 'entityOwner']
    }
};
const KanbanContainer = ({ actions = [], baseUrl, contentHeight, customColumnsTitles, definitionId, displayData, fields, getCustomColumnCountFilters, getCustomRouteHandleCardClick, idField, isPending = false, noFilters, noSearch, noViewActions, showStartProcessSelector = true, showViewsSelector, viewId, withCmsImport }) => {
    const { t, language } = useI18n();
    const dispatch = useDispatch();
    const { kanban } = useView();
    const [disabledColumns, setDisabledColumns] = useState(new Set());
    const { mutateAsync: updateProperty } = useUpdateProperty({
        definitionId,
        skipOnSettled: true,
        skipOnMutate: true
    });
    const onToggleDisabledColumns = useCallback((columnIds) => {
        for (const columnId of columnIds) {
            if (disabledColumns.has(columnId)) {
                disabledColumns.delete(columnId);
            }
            else {
                disabledColumns.add(columnId);
            }
        }
        setDisabledColumns(new Set([...disabledColumns]));
    }, [disabledColumns]);
    const columnDefinitionIdKanbanGroupBy = useMemo(() => getColumnDefinitionIdKanbanGroupBy(displayData, kanban?.groupBy), [displayData, kanban?.groupBy]);
    const { displayData: displayDataColumnDefinition } = useEntityDisplayData(columnDefinitionIdKanbanGroupBy);
    const titleColumnDefinition = getPropertyById(displayDataColumnDefinition, TITLE_PROPERTY_ID);
    const titleColumnDefinitionTranslatedKey = titleColumnDefinition?.meta.translations?.[language];
    const responseFieldsColumnsItems = useMemo(() => {
        const responseFields = [TITLE_PROPERTY_ID];
        if (titleColumnDefinitionTranslatedKey) {
            responseFields.push(titleColumnDefinitionTranslatedKey);
        }
        return responseFields;
    }, [titleColumnDefinitionTranslatedKey]);
    const { data: columnsItems = [] } = useEntityItems({
        definitionId: columnDefinitionIdKanbanGroupBy || '',
        ids: kanban?.columns.filter((column) => !['EMPTY', MY_USER_COMPUTED].includes(column)) || [],
        extraPayloadProps: {
            responseFields: responseFieldsColumnsItems
        },
        enabled: !!columnDefinitionIdKanbanGroupBy && !isEmpty(kanban?.columns)
    });
    const columnsTitles = useMemo(() => ({
        ...customColumnsTitles,
        ...columnsItems.reduce((acc, item) => ({
            ...acc,
            [item.entityId]: (titleColumnDefinitionTranslatedKey && item[titleColumnDefinitionTranslatedKey]) ||
                item.title ||
                item.entityId
        }), {})
    }), [columnsItems, customColumnsTitles, titleColumnDefinitionTranslatedKey]);
    const lists = useMemo(() => {
        if (!kanban?.columns || isEmpty(kanban.columns)) {
            return [];
        }
        return kanban?.columns.map((value) => ({
            propertyId: value,
            definitionId,
            title: columnsTitles[value]
        }));
    }, [columnsTitles, definitionId, kanban?.columns]);
    const onChangePosition = useCallback(async ({ entityId, destinationColumnId, sourceColumnId, title }) => {
        if (!kanban?.groupBy) {
            return;
        }
        try {
            onToggleDisabledColumns([sourceColumnId, destinationColumnId]);
            await updateProperty({ entityId, propertyId: kanban.groupBy, value: destinationColumnId });
            await sleep(1000);
            dispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('KANBAN_MOVE_ITEM_SUCCESS', { title })
            }));
            onToggleDisabledColumns([sourceColumnId, destinationColumnId]);
            await Promise.all([
                invalidateInfiniteSearchItemsQuery({
                    definitionId,
                    queryKey: getKanbanColumnQueryKey(sourceColumnId)
                }),
                invalidateInfiniteSearchItemsQuery({
                    definitionId,
                    queryKey: getKanbanColumnQueryKey(destinationColumnId)
                }),
                invalidateColumnsCountQuery({
                    definitionId,
                    columnId: sourceColumnId
                }),
                invalidateColumnsCountQuery({
                    definitionId,
                    columnId: destinationColumnId
                }),
                invalidateAggregationQuery({ definitionId })
            ]);
        }
        catch (e) {
            onToggleDisabledColumns([sourceColumnId, destinationColumnId]);
            const message = e?.response?.status == 403
                ? t('KANBAN_MOVE_ITEM_PERMISSION_DENIED', { title })
                : t('KANBAN_MOVE_ITEM_ERROR', { title });
            dispatch(error({
                title: t('ERROR'),
                message
            }));
        }
    }, [definitionId, dispatch, kanban?.groupBy, onToggleDisabledColumns, t, updateProperty]);
    return (React.createElement(ErrorBoundary, null,
        React.createElement(KanbanBoardWrapper, { isDropDisabled: !columnDefinitionIdKanbanGroupBy, disabledColumns: disabledColumns, actions: actions, baseUrl: baseUrl, contentHeight: contentHeight, definitionId: definitionId, fields: fields, idField: idField, isPending: isPending, lists: lists, noFilters: noFilters, noSearch: noSearch, noViewActions: noViewActions, onChangePosition: onChangePosition, showStartProcessSelector: showStartProcessSelector, getCustomColumnCountFilters: getCustomColumnCountFilters, getCustomRouteHandleCardClick: getCustomRouteHandleCardClick, showViewsSelector: showViewsSelector, viewId: viewId, withCmsImport: withCmsImport })));
};
export default KanbanContainer;
