import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MdRedo, MdUndo } from 'react-icons/md';
import { ClipboardCopyButton, ClipboardPasteButton } from '../../../../../../common/components/ClipboardButtons';
import ConfirmationButtonModal from '../../../../../../common/components/ConfirmationButtonModal';
import { ActivityNodeTypes } from '../../../../../common/utils/types';
import { isProcessActivity } from '../../../../utils';
import ProcessMetaActions from '../../../common/ProcessMetaActions';
import ActivityDiagramRenderer from '../ActivityDiagramRenderer';
import AddNodeDialog from '../AddNodeDialog/AddNodeDialog';
import { exportSvg } from '../exporter';
import { getNextNodeId, isDecisionNode } from '../nodes';
import { useNodes } from '../useNode';
import { canDeleteNode, copyNodes, firstLevelNodes, getEdges, getNodeWithNewNext, getNodes, getSubTree, isParent } from '../utils';
import styles from './ActivityDiagramField.module.scss';
const confirmModal = ConfirmationButtonModal.confirm;
const ActivityDiagramField = ({ value: _value, onChange: _onChange, input, meta, selectedNode, nodesWithErrors, processDefinition, extraActionButtons, isSystem, disabled, formIsValid, isSubmitting, isDirty, handleSubmit, closePropertiesDrawer, onSelectNode, contentHeight }) => {
    const value = input?.value || _value;
    const onChange = input?.onChange || _onChange;
    const { t, language } = useI18n();
    const { data: nodesDescription } = useNodes();
    const [graphSvg, setGraphSvg] = useState(null);
    const [clipboardNode, setClipboardNode] = useState();
    const [versions, setVersions] = useState([
        { diagram: value, selectedNodeId: 'Start' }
    ]);
    const [currentVersion, setCurrentVersion] = useState(0);
    const [addNodeDialogMode, setAddNodeDialogMode] = useState();
    const actualSelectedNode = value[selectedNode?.id] || value.Start;
    const edges = useMemo(() => {
        if (!nodesDescription?.nodes) {
            return [];
        }
        return getEdges(value, nodesDescription.nodes);
    }, [value, nodesDescription?.nodes]);
    const nodes = useMemo(() => getNodes(value, [selectedNode], language, (meta?.submitFailed && nodesWithErrors) || {}), [value, language, meta?.submitFailed, nodesWithErrors, selectedNode]);
    const nodesInFirstLevel = useMemo(() => firstLevelNodes(value), [value]);
    const isFirstLevelSelected = useMemo(() => !!nodesInFirstLevel[actualSelectedNode.id], [nodesInFirstLevel, actualSelectedNode]);
    const canAddOrPasteStep = useMemo(() => {
        if (!isFirstLevelSelected) {
            return false;
        }
        if (selectedNode.target == 'hp3n_if') {
            return false;
        }
        if (isDecisionNode(selectedNode)) {
            return false;
        }
        if ([ActivityNodeTypes.LOOP_DO, ActivityNodeTypes.FORK].includes(selectedNode.type)) {
            return false;
        }
        return true;
    }, [isFirstLevelSelected, selectedNode]);
    const exportGraph = () => {
        exportSvg(graphSvg);
        return Promise.resolve();
    };
    const selectNode = useCallback((node, openProperties, updateSelectedNodeId) => {
        onSelectNode(node, openProperties);
        if (updateSelectedNodeId && currentVersion === versions.length - 1) {
            setVersions([
                ...versions.slice(0, versions.length - 1),
                { ...versions[versions.length - 1], selectedNodeId: node.id }
            ]);
        }
    }, [currentVersion, onSelectNode, versions]);
    useEffect(() => {
        if (versions[currentVersion]) {
            versions[currentVersion].diagram = value;
            setVersions([...versions]);
        }
    }, [value]);
    const updateDiagram = useCallback((newActivityDiagram, newSelectedNode) => {
        selectNode(newSelectedNode, false);
        const newVersion = currentVersion + 1;
        setVersions([
            ...versions.slice(0, newVersion),
            {
                diagram: newActivityDiagram,
                selectedNodeId: selectedNode.id
            }
        ]);
        setCurrentVersion(newVersion);
        onChange(newActivityDiagram);
    }, [currentVersion, onChange, selectNode, selectedNode?.id, versions]);
    const addNode = useCallback((newNodes, decisionBranch) => {
        const newNode = newNodes[0];
        const newParentNode = getNodeWithNewNext(actualSelectedNode, newNode.id, decisionBranch);
        const newActivityDiagram = {
            ...value,
            [actualSelectedNode.id]: newParentNode
        };
        newNodes.forEach((n) => {
            newActivityDiagram[n.id] = n;
        });
        updateDiagram(newActivityDiagram, newNode);
    }, [actualSelectedNode, value, updateDiagram]);
    const paste = useCallback((decisionBranch) => {
        if (clipboardNode) {
            const clonedNodes = copyNodes(clipboardNode, actualSelectedNode, value, decisionBranch);
            addNode(clonedNodes, decisionBranch);
        }
    }, [actualSelectedNode, addNode, clipboardNode, value]);
    const applyNewVersion = useCallback((newVersionNumber) => {
        const newVersion = versions[newVersionNumber];
        const newSelectedNode = value[newVersion.selectedNodeId];
        if (newSelectedNode) {
            selectNode(newSelectedNode, false);
        }
        else {
            // if the previous node doesn't exist then it means
            // we are reverting to a node that got deleted
            setTimeout(() => {
                selectNode(value[newVersion.selectedNodeId], false);
            }, 100);
        }
        onChange(newVersion.diagram);
        if (clipboardNode && !newVersion[clipboardNode.id]) {
            setClipboardNode(undefined);
        }
    }, [clipboardNode, onChange, selectNode, value, versions]);
    const undo = useCallback(() => {
        const newVersion = Math.max(0, currentVersion - 1);
        if (newVersion != currentVersion) {
            setCurrentVersion(newVersion);
            applyNewVersion(newVersion);
        }
    }, [applyNewVersion, currentVersion]);
    const redo = useCallback(() => {
        const newVersion = Math.min(versions.length - 1, currentVersion + 1);
        if (newVersion != currentVersion) {
            setCurrentVersion(newVersion);
            applyNewVersion(newVersion);
        }
    }, [applyNewVersion, currentVersion, versions.length]);
    const copy = useCallback(() => {
        if (canDeleteNode(actualSelectedNode)) {
            setClipboardNode(actualSelectedNode);
        }
    }, [actualSelectedNode]);
    const onPaste = useCallback(() => {
        if (isDecisionNode(actualSelectedNode) ||
            (actualSelectedNode.type === ActivityNodeTypes.ACTION && 'nextIds' in actualSelectedNode)) {
            setAddNodeDialogMode('paste');
        }
        else {
            paste('TRUE');
        }
    }, [actualSelectedNode, paste]);
    const deleteSelectedNode = useCallback(() => {
        const startId = actualSelectedNode.type === ActivityNodeTypes.LOOP_WHILE
            ? actualSelectedNode.startId
            : actualSelectedNode.id;
        const parentNode = Object.values(value).find((n) => isParent(n, startId));
        if (parentNode == null) {
            throw new Error('Failed to find parent node');
        }
        let nextId;
        let endNodeId;
        if ('endId' in actualSelectedNode && actualSelectedNode.endId) {
            endNodeId = actualSelectedNode.endId;
            const endNode = value[endNodeId];
            nextId = getNextNodeId(endNode);
        }
        if (actualSelectedNode.type === ActivityNodeTypes.ACTION && 'nextIds' in actualSelectedNode) {
            endNodeId = actualSelectedNode.nextId;
            const endNode = value[endNodeId];
            nextId = getNextNodeId(endNode);
        }
        else if ('nextId' in actualSelectedNode) {
            endNodeId = actualSelectedNode.id;
            nextId = actualSelectedNode.nextId;
        }
        if (!nextId) {
            throw new Error('Next node id not found');
        }
        let decisionBranch;
        let newParentNode;
        if (isDecisionNode(parentNode)) {
            decisionBranch = parentNode.queryTrueId === startId ? 'TRUE' : 'FALSE';
        }
        else if (parentNode.type === ActivityNodeTypes.ACTION &&
            'nextIds' in parentNode &&
            parentNode.nextIds) {
            decisionBranch = parentNode.nextIds.findIndex((nid) => nid === startId);
        }
        if (parentNode.type === ActivityNodeTypes.FORK) {
            newParentNode = getNodeWithNewNext({
                ...parentNode,
                nextIds: parentNode.nextIds.filter((nid) => nid !== startId)
            }, nextId, decisionBranch);
        }
        else {
            newParentNode = getNodeWithNewNext(parentNode, nextId, decisionBranch);
        }
        const newActivityDiagram = {
            ...value,
            [newParentNode.id]: newParentNode
        };
        const deletedNodes = getSubTree(value, startId, endNodeId);
        deletedNodes.forEach((n) => {
            delete newActivityDiagram[n.id];
        });
        updateDiagram(newActivityDiagram, parentNode);
        if (clipboardNode && !newActivityDiagram[clipboardNode.id]) {
            setClipboardNode(undefined);
        }
    }, [clipboardNode, actualSelectedNode, updateDiagram, value]);
    const onDelete = useCallback(() => {
        confirmModal({
            content: t('PROCESS_META__CONFIRM_NODE_DELETE'),
            okText: t('MESSAGE_DELETE'),
            cancelText: t('COMMON_CANCEL'),
            onOk: deleteSelectedNode
        }, t);
    }, [deleteSelectedNode, t]);
    const updateSelectedNode = (id, openProperties) => {
        const node = value[id];
        selectNode(node, openProperties, true);
    };
    const editSelectedNode = () => {
        selectNode(actualSelectedNode, true, true);
    };
    const addAfterSelectedNode = () => {
        setAddNodeDialogMode('add');
    };
    const replaceSelectedNode = () => {
        setAddNodeDialogMode('replace');
    };
    const onAddNodeDialogSelected = (decisionBranch, nodes) => {
        if (nodes) {
            addNode(nodes, decisionBranch);
        }
        else {
            paste(decisionBranch);
        }
        onAddNodeDialogClose();
    };
    const onAddNodeDialogClose = () => {
        setAddNodeDialogMode(undefined);
    };
    useEffect(() => {
        const processShortcut = (e) => {
            if (!(e.target instanceof HTMLElement)) {
                return;
            }
            if (e.target.nodeName.toUpperCase() == 'BODY' && !window.getSelection()?.toString()) {
                // COPY
                if (e.key === 'c' && (e.metaKey || e.ctrlKey)) {
                    copy();
                    e.preventDefault();
                }
                else if (e.key === 'v' && (e.metaKey || e.ctrlKey)) {
                    // PASTE
                    onPaste();
                    e.preventDefault();
                }
                else if ((e.key === 'z' || e.key === 'Z') && e.shiftKey && (e.metaKey || e.ctrlKey)) {
                    // Redo
                    redo();
                    e.preventDefault();
                }
                else if (e.key === 'z' && (e.metaKey || e.ctrlKey)) {
                    // Undo
                    undo();
                    e.preventDefault();
                }
                else if (e.key === 'Delete' || e.key === 'Backspace') {
                    onDelete();
                }
            }
            if (e.key === 'Escape') {
                closePropertiesDrawer();
                e.preventDefault();
            }
            else if (e.key === 's' && (e.metaKey || e.ctrlKey)) {
                void handleSubmit();
                e.preventDefault();
            }
        };
        document.addEventListener('keydown', processShortcut);
        return () => {
            document.removeEventListener('keydown', processShortcut);
        };
    }, [closePropertiesDrawer, copy, handleSubmit, onDelete, onPaste, redo, undo]);
    const isValidClipboard = useCallback((clipText) => {
        try {
            const clipValue = JSON.parse(clipText);
            if (isProcessActivity(clipValue)) {
                return true;
            }
        }
        catch (error) {
            return false;
        }
        return false;
    }, []);
    const onClipboardPaste = useCallback((clipText) => {
        const clipValue = JSON.parse(clipText);
        updateDiagram(clipValue, clipValue.Start);
    }, [updateDiagram]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${styles.actions} cp-c-row cp-c-wrap` },
            React.createElement(ClipboardCopyButton, { value: value, inversed: true, disabled: isSubmitting }),
            React.createElement(ClipboardPasteButton, { inversed: true, disabled: isSubmitting, isValid: isValidClipboard, onClick: onClipboardPaste }),
            React.createElement(Tooltip, { title: t('UNDO') },
                React.createElement(Button, { inversed: true, onClick: undo, disabled: disabled || isSubmitting || currentVersion == 0 },
                    React.createElement(MdUndo, null))),
            React.createElement(Tooltip, { title: t('REDO') },
                React.createElement(Button, { inversed: true, onClick: redo, disabled: disabled || isSubmitting || currentVersion == versions.length - 1 },
                    React.createElement(MdRedo, null))),
            React.createElement(ProcessMetaActions, { disabled: disabled || isSubmitting || !processDefinition, definitionId: (!!processDefinition && processDefinition.definitionId) || '', save: handleSubmit, formIsValid: formIsValid, saving: isSubmitting, canSave: isDirty, canDelete: !isSystem, extraActions: [
                    {
                        action: exportGraph,
                        canRun: true,
                        buttonLabel: 'PROCESS_META__EXPORT_DIAGRAM'
                    }
                ] }),
            extraActionButtons),
        React.createElement("div", { className: "h-100" },
            React.createElement(ActivityDiagramRenderer, { disabled: disabled, nodes: nodes, edges: edges, contentHeight: contentHeight, selectedNode: actualSelectedNode, onNodeClick: updateSelectedNode, addAfterSelectedNode: addAfterSelectedNode, replaceSelectedNode: replaceSelectedNode, editSelectedNode: editSelectedNode, deleteSelectedNode: onDelete, copySelectedNode: !clipboardNode || clipboardNode.id != actualSelectedNode.id ? copy : undefined, pasteAfterSelectedNode: clipboardNode && (clipboardNode.target !== 'STEP' || canAddOrPasteStep)
                    ? onPaste
                    : undefined, svgRef: setGraphSvg })),
        !!addNodeDialogMode && !disabled && nodesDescription?.nodes ? (React.createElement(AddNodeDialog, { open: !!addNodeDialogMode, selectedNode: actualSelectedNode, canAddStepNode: canAddOrPasteStep, nodes: nodesDescription.nodes, mode: addNodeDialogMode, onSelected: onAddNodeDialogSelected, onCancel: onAddNodeDialogClose })) : null));
};
export default ActivityDiagramField;
