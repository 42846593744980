import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import Indicator from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Collapse } from 'antd';
import { flatten, isEqual, uniq } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { RichTextDisplay } from '../../../../cms';
import { useStorage } from '../../../context/StorageContext';
import FileInput from '../../../storage/components/FileField/FileInput';
import { ActivityStatus } from '../../types';
import { useLoadActivityTypes } from '../../useLoadActivityTypes';
import ActivityHeader from './ActivityHeader';
import styles from './ActivityList.module.scss';
const ON_SUCCESS_FOCUSED_BLINK_UPCOMING_TASK = 'ON_SUCCESS_FOCUSED_BLINK_UPCOMING_TASK';
const ActivityList = ({ className, upcomingActivities, completedActivities, definitionId, ownerEntityId, onEdit, onDelete, onUpdate, loadNextPage, activeKeys, onChangeActiveKeys }) => {
    const { t } = useI18n();
    const location = useLocation();
    const blinkUpcomingTask = new URLSearchParams(location.search).get('blinkUpcomingTask');
    const { activityTypes, isPending } = useLoadActivityTypes();
    const [groupActiveKeys, setGroupActiveKeys] = useState(['upcoming']);
    const onClickUpcomingViewMore = useCallback(() => {
        return loadNextPage('upcoming');
    }, [loadNextPage]);
    const onClickCompletedViewMore = useCallback(() => {
        return loadNextPage('completed');
    }, [loadNextPage]);
    const [onSuccessFocusedBlinkUpcomingTask, setOnSuccessFocusedBlinkUpcomingTask] = useStorage({
        key: `${ON_SUCCESS_FOCUSED_BLINK_UPCOMING_TASK}_${definitionId ? definitionId : ''}`,
        defaultValue: false,
        storageType: 'session'
    });
    const [debouncedOnSuccessFocusedBlinkUpcomingTask] = useDebounce(onSuccessFocusedBlinkUpcomingTask, 3000);
    const onChangeUpcomingActiveKey = useCallback((key) => {
        onChangeActiveKeys({ ...activeKeys, upcoming: Array.isArray(key) ? key : [key] });
    }, [activeKeys, onChangeActiveKeys]);
    const onChangeCompletedActiveKey = useCallback((key) => {
        onChangeActiveKeys({ ...activeKeys, completed: Array.isArray(key) ? key : [key] });
    }, [activeKeys, onChangeActiveKeys]);
    const [groupedCompletedActivities, groupedCompletedActivitiesTitles] = useMemo(() => {
        let result;
        let groupTitles = [];
        if (completedActivities?.data?.pages.length) {
            result = {};
            for (const page of completedActivities.data.pages) {
                if (!page.results.length) {
                    continue;
                }
                for (const activityItem of page.results) {
                    const groupTitle = moment(activityItem.statusUpdatedAt).format('MMMM • YYYY');
                    groupTitles.push(groupTitle);
                    groupTitles = uniq(groupTitles);
                    if (!result[groupTitle]) {
                        result[groupTitle] = [];
                    }
                    result[groupTitle].push(activityItem);
                }
            }
        }
        return [result, groupTitles];
    }, [completedActivities?.data?.pages]);
    useEffect(() => {
        if (!groupedCompletedActivitiesTitles.length) {
            return;
        }
        setGroupActiveKeys((prev) => {
            let newVal = [...prev];
            if (prev.length === 1 && prev[0] === 'upcoming') {
                newVal.push(groupedCompletedActivitiesTitles[0]);
            }
            newVal.push(groupedCompletedActivitiesTitles[groupedCompletedActivitiesTitles.length - 1]);
            newVal = uniq(newVal);
            if (isEqual(newVal, prev)) {
                return prev;
            }
            return newVal;
        });
    }, [groupedCompletedActivitiesTitles]);
    const noComments = useMemo(() => {
        return {
            ops: [
                {
                    insert: t('ACTIVITY_NO_COMMENTS')
                }
            ]
        };
    }, [t]);
    const activityTypeMap = useMemo(() => {
        return activityTypes.reduce((map, activityType) => {
            return {
                ...map,
                [activityType.entityId]: activityType
            };
        }, {});
    }, [activityTypes]);
    const refBlinkUpcomingTask = useRef(null);
    useEffect(() => {
        if (blinkUpcomingTask && !onSuccessFocusedBlinkUpcomingTask) {
            const hasBlinkUpcomingTask = flatten(upcomingActivities?.data?.pages.map((page) => page.results))
                .map((activity) => activity.entityId)
                .includes(blinkUpcomingTask);
            if (!upcomingActivities?.isFetchingNextPage) {
                if (!hasBlinkUpcomingTask) {
                    loadNextPage('upcoming');
                }
                else {
                    refBlinkUpcomingTask.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });
                    onChangeUpcomingActiveKey(blinkUpcomingTask);
                    setOnSuccessFocusedBlinkUpcomingTask(true);
                }
            }
        }
    }, [
        blinkUpcomingTask,
        loadNextPage,
        onChangeUpcomingActiveKey,
        onSuccessFocusedBlinkUpcomingTask,
        setOnSuccessFocusedBlinkUpcomingTask,
        upcomingActivities
    ]);
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''}` },
        React.createElement(Collapse, { activeKey: groupActiveKeys, onChange: setGroupActiveKeys, expandIconPosition: "end", ghost: true, className: "sections pe-0", items: [
                ...(((upcomingActivities?.status === 'loading' ||
                    upcomingActivities?.data?.pages[0]?.results.length) && [
                    {
                        key: 'upcoming',
                        label: t('ACTIVITY_UPCOMING_ACTIVITIES_TITLE'),
                        children: isPending || upcomingActivities?.status === 'loading' ? (React.createElement(Indicator, { className: "mt-3" })) : (React.createElement(React.Fragment, null,
                            React.createElement(Collapse, { ghost: true, className: "activities pe-0", activeKey: activeKeys.upcoming, onChange: onChangeUpcomingActiveKey, items: [
                                    ...flatten(upcomingActivities.data.pages.map((page) => page.results.map((activity) => ({
                                        style: {
                                            '--activity-background-color': activityTypeMap[activity.typeText]?.color
                                        },
                                        key: activity.entityId,
                                        className: `rounded ${blinkUpcomingTask === activity.entityId &&
                                            !debouncedOnSuccessFocusedBlinkUpcomingTask
                                            ? styles.blinkActivityTask
                                            : ''}`,
                                        ref: blinkUpcomingTask === activity.entityId
                                            ? refBlinkUpcomingTask
                                            : undefined,
                                        label: (React.createElement(ActivityHeader, { activityType: activityTypeMap[activity.typeText], showContact: activity.contact !== ownerEntityId, activity: activity, showRelatedTo: definitionId !== activity.relatedToDefinitionId, onChangeCheckBox: (event) => {
                                                return (onUpdate &&
                                                    onUpdate({
                                                        ...activity,
                                                        statusItem: event.target.checked
                                                            ? ActivityStatus.Completed
                                                            : ActivityStatus.NotStarted
                                                    }));
                                            }, onClickEdit: onEdit
                                                ? () => {
                                                    return onEdit(activity);
                                                }
                                                : undefined, onClickDelete: onDelete
                                                ? () => {
                                                    return onDelete(activity);
                                                }
                                                : undefined })),
                                        children: (React.createElement(React.Fragment, null,
                                            React.createElement("div", { className: "p-2 comments" },
                                                React.createElement(RichTextDisplay, { value: activity.comments || noComments, asField: true })),
                                            !!activity.files?.length && (React.createElement("div", { className: "p-2 files" },
                                                React.createElement("div", { className: "mb-2" },
                                                    t('COMMON_FILES'),
                                                    ":"),
                                                React.createElement(FileInput, { className: "files-input", layout: "vertical", value: activity.files, groupId: activity.entityId, meta: { list: true, carousel: false }, error: false, disabled: true, loading: false })))))
                                    }))))
                                ] }),
                            upcomingActivities.hasNextPage && (React.createElement("div", { className: "text-center mt-3" },
                                React.createElement(Button, { type: "button", inversed: true, onClick: onClickUpcomingViewMore }, t('ACTIVITY_VIEW_MORE'))))))
                    }
                ]) ||
                    []),
                ...(isPending || completedActivities?.status === 'loading'
                    ? [{ children: React.createElement(Indicator, { className: "mt-3" }) }]
                    : groupedCompletedActivitiesTitles.length
                        ? groupedCompletedActivitiesTitles.map((title) => {
                            return {
                                key: title,
                                label: title,
                                children: (React.createElement(Collapse, { ghost: true, className: "activities pe-0", activeKey: activeKeys.completed, onChange: onChangeCompletedActiveKey, items: groupedCompletedActivities?.[title].map((activity) => ({
                                        style: {
                                            '--activity-background-color': activityTypeMap[activity.typeText]?.color
                                        },
                                        key: activity.entityId,
                                        label: (React.createElement(ActivityHeader, { activityType: activityTypeMap[activity.typeText], showContact: activity.contact !== ownerEntityId, activity: activity, showRelatedTo: definitionId !== activity.relatedToDefinitionId, onClickEdit: onEdit
                                                ? () => {
                                                    return onEdit(activity);
                                                }
                                                : undefined, onClickUndo: () => {
                                                return (onUpdate &&
                                                    onUpdate({
                                                        ...activity,
                                                        statusItem: ActivityStatus.NotStarted
                                                    }));
                                            }, onClickDelete: onDelete
                                                ? () => {
                                                    return onDelete(activity);
                                                }
                                                : undefined })),
                                        children: (React.createElement(React.Fragment, null,
                                            React.createElement("div", { className: "p-2 comments" },
                                                React.createElement(RichTextDisplay, { value: activity.comments || noComments, asField: true })),
                                            !!activity.files?.length && (React.createElement("div", { className: "p-2 files" },
                                                React.createElement("div", { className: "mb-2" },
                                                    t('COMMON_FILES'),
                                                    ":"),
                                                React.createElement(FileInput, { className: "files-input", layout: "vertical", value: activity.files, groupId: activity.entityId, meta: { list: true, carousel: false }, error: false, disabled: true, loading: false })))))
                                    })) }))
                            };
                        })
                        : [])
            ] }),
        groupedCompletedActivitiesTitles.length && completedActivities?.hasNextPage ? (React.createElement("div", { className: "text-center mt-3" },
            React.createElement(Button, { type: "button", inversed: true, onClick: onClickCompletedViewMore }, t('ACTIVITY_VIEW_MORE')))) : null));
};
export default ActivityList;
