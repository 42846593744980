import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Collapse } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { TbPin } from 'react-icons/tb';
import { useSystemSectionsVisibility } from '../../../../common/context/SystemSectionsVisibilityProvider';
import { TableFields } from '../TableFields';
const SectionList = ({ className, definitionId, entityId, canEdit = true, doubleCol = false, pinnedSectionData, requiredFieldsConfig, permissionFieldsConfig, uploadFiles, updatePropertyData, activePinnedSection, onPinnedSectionToggle, sectionsData }) => {
    const { language } = useI18n();
    const { isSystemSectionsVisible, hiddenSections } = useSystemSectionsVisibility();
    const [activeKey, setActiveKey] = useState('');
    const [activeKeyInitialized, setActiveKeyInitialized] = useState(false);
    useEffect(() => {
        if (activeKey === '' && sectionsData.length) {
            const allDefaultOpenSections = sectionsData
                .map(({ sectionId }) => sectionId)
                .filter((id) => !hiddenSections.includes(id));
            setActiveKey([
                ...(pinnedSectionData?.sectionId ? [pinnedSectionData.sectionId] : []),
                ...allDefaultOpenSections
            ]);
        }
    }, [activeKey, sectionsData, hiddenSections, pinnedSectionData?.sectionId]);
    useEffect(() => {
        if (isSystemSectionsVisible && Array.isArray(activeKey) && !activeKeyInitialized) {
            setActiveKeyInitialized(true);
            setActiveKey(Array.from(new Set([
                ...(pinnedSectionData?.sectionId ? [pinnedSectionData.sectionId] : []),
                ...activeKey,
                ...hiddenSections
            ])));
        }
    }, [
        isSystemSectionsVisible,
        hiddenSections,
        activeKey,
        activeKeyInitialized,
        pinnedSectionData?.sectionId
    ]);
    return (React.createElement("div", { className: className },
        React.createElement(Collapse, { activeKey: activePinnedSection ? pinnedSectionData?.sectionId : undefined, className: "collapse-outer", onChange: onPinnedSectionToggle }, pinnedSectionData && !isEmpty(pinnedSectionData.values) ? (React.createElement(Collapse.Panel, { header: React.createElement("span", { className: "collapse-panel-header" },
                React.createElement(TbPin, { size: 22 }),
                getTranslation(pinnedSectionData.titles, language)), key: pinnedSectionData.sectionId },
            React.createElement(TableFields, { doubleCol: doubleCol, fieldsData: pinnedSectionData.values, updatePropertyData: updatePropertyData, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, canEdit: canEdit, uploadFiles: uploadFiles, definitionId: definitionId, entityId: entityId }))) : null),
        React.createElement(Collapse, { activeKey: activeKey, className: "collapse-outer", onChange: setActiveKey, items: sectionsData
                .filter(({ values }) => !isEmpty(values))
                .map(({ sectionId, values, titles }) => ({
                key: sectionId,
                label: (React.createElement("span", { className: "collapse-panel-header" }, getTranslation(titles, language))),
                children: (React.createElement(TableFields, { doubleCol: doubleCol, fieldsData: values, updatePropertyData: updatePropertyData, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, canEdit: canEdit, uploadFiles: uploadFiles, definitionId: definitionId, entityId: entityId }))
            })) })));
};
export default SectionList;
