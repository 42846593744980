import ImageResize from 'quill-image-resize-module-react';
import ImageUploader from 'quill-image-uploader';
import 'quill-image-uploader/dist/quill.imageUploader.min.css';
import { Quill } from 'react-quill';
import { EDITOR_CONFIG, EDITOR_DEFAULT_VALUE } from './constants';
import { iconH1, iconH2, iconH3, iconH4 } from './headers';
import { OPEN_IN_MODAL_QUILL_ACTION_NAME, OpenInModalFormat, iconOpenInModal } from './open-in-modal-format';
const icons = Quill.import('ui/icons');
icons.header[1] = iconH1;
icons.header[2] = iconH2;
icons.header[3] = iconH3;
icons.header[4] = iconH4;
icons[OPEN_IN_MODAL_QUILL_ACTION_NAME] = iconOpenInModal;
Quill.debug(false);
Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/imageResize', ImageResize);
Quill.register(`formats/${OPEN_IN_MODAL_QUILL_ACTION_NAME}`, OpenInModalFormat);
export { EDITOR_CONFIG, EDITOR_DEFAULT_VALUE };
