import React, { useMemo } from 'react';
import { ACTIVITY_DEFINITION_ID } from '../../../../../common/activity';
import LoadingIndicator from '../../../../../common/components/loading-indicator';
import { CONTACT_CMS_DEFINITION_ID } from '../../../../../crm';
import { useMyContact } from '../../../../../crm/components/use-my-contact';
import { ViewProvider, useViews } from '../../../../../views';
import { TaskDashboardProvider } from '../../../../../workflows/dashboard/components/dashboard/TaskDashboardContext/TaskDashboardProvider';
import { getActivityTaskFilterTypeConfig, mapPropertyActivityTaskDashboardGroupBy } from '../../../../../workflows/dashboard/constants';
import { getActivityTaskDashboardView, getCustomActivityKanbanColumnsFilters, getDefaultFilterQueryActivityTaskDashboard, getNewFilterForActivityTaskDashboard } from '../../../../../workflows/dashboard/utils';
import ActivityTasksWidget from './ActivityTasksWidget';
const ActivityTasksWidgetWrapper = ({ className, settings, defaultFilter }) => {
    const { contactId } = useMyContact();
    const { views } = useViews();
    const view = useMemo(() => getActivityTaskDashboardView(views, settings.viewId), [settings.viewId, views]);
    return (React.createElement("div", { className: className }, view ? (React.createElement(ViewProvider, { enableGrouping: false, getCustomKanbanColumnsFilters: getCustomActivityKanbanColumnsFilters, definitionId: ACTIVITY_DEFINITION_ID, serializeToUrl: false, context: {
            definitionId: CONTACT_CMS_DEFINITION_ID,
            contactId
        }, view: view },
        React.createElement(TaskDashboardProvider, { getTaskFilterTypeConfig: getActivityTaskFilterTypeConfig, mapProperty: mapPropertyActivityTaskDashboardGroupBy, getNewFilterForTaskDashboard: getNewFilterForActivityTaskDashboard, getDefaultFilterQueryTaskDashboard: getDefaultFilterQueryActivityTaskDashboard },
            React.createElement(ActivityTasksWidget, { className: "w-100 h-100", defaultFilter: defaultFilter, settings: settings })))) : (React.createElement(LoadingIndicator, null))));
};
export default ActivityTasksWidgetWrapper;
