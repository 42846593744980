import { isString } from 'lodash';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { PERMISSIONS_PROPERTY_ID } from '../../cms/common/utils/constants';
import { invalidateItemsQuery } from '../../cms/hooks/invalidateCache';
import useCreateItem from '../../cms/hooks/useCreateItem';
import useDeleteItems from '../../cms/hooks/useDeleteItems';
import useDisplayItemMeta from '../../cms/hooks/useDisplayItemMeta';
import { useSearchItems } from '../../cms/hooks/useSearchItems';
import SessionSubscription, { useSessionSubscriptionCallback } from '../../common/session/components/SessionSubscription';
import { DASHBOARDS_ENTITY_ID } from './constants';
const DashboardsContext = createContext(undefined);
export const DashboardsProvider = ({ ...otherProps }) => {
    const { data: dashboardDisplayItemMeta, isLoading } = useDisplayItemMeta({
        definitionId: DASHBOARDS_ENTITY_ID
    });
    const { mutateAsync: dashboardCreateItem } = useCreateItem({
        definitionId: DASHBOARDS_ENTITY_ID
    });
    const { mutateAsync: dashboardDeleteItems } = useDeleteItems({
        definitionId: DASHBOARDS_ENTITY_ID
    });
    const { data: { results: dashboards } = {}, isPending } = useSearchItems({
        definitionId: DASHBOARDS_ENTITY_ID,
        filterRequest: {
            responseFields: ['title', 'configuration', PERMISSIONS_PROPERTY_ID],
            limit: 500
        }
    }, {
        enabled: !!dashboardDisplayItemMeta
    });
    const sessionSubscriptionCallback = useCallback((payload) => {
        if (payload?.['relatedToType'] == 'sharedDashboard') {
            void invalidateItemsQuery({
                definitionId: DASHBOARDS_ENTITY_ID,
                ids: isString(payload?.['relatedToId']) ? [payload?.['relatedToId']] : undefined
            });
        }
    }, []);
    useSessionSubscriptionCallback({
        messageKey: 'UPDATED_NOTIFICATIONS',
        callback: sessionSubscriptionCallback
    });
    const getDashboard = useCallback((dashboardId) => {
        return dashboards?.find((item) => item.entityId === dashboardId);
    }, [dashboards]);
    const saveDashboard = useCallback(async (dashboard) => {
        const dashboardItem = {
            entityId: dashboard.entityId,
            title: dashboard.title,
            configuration: dashboard.configuration,
            [PERMISSIONS_PROPERTY_ID]: dashboard[PERMISSIONS_PROPERTY_ID]
        };
        return await dashboardCreateItem(dashboardItem);
    }, [dashboardCreateItem]);
    const deleteDashboard = useCallback(async (dashboard) => {
        await dashboardDeleteItems({ ids: [dashboard.entityId] });
    }, [dashboardDeleteItems]);
    const value = useMemo(() => ({
        dashboards: dashboards || [],
        loading: isLoading || isPending,
        getDashboard,
        saveDashboard,
        deleteDashboard
    }), [dashboards, isLoading, isPending, getDashboard, saveDashboard, deleteDashboard]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SessionSubscription, { subscriptionKey: `notifications` }),
        React.createElement(DashboardsContext.Provider, { value: value, ...otherProps })));
};
export const useDashboards = () => {
    const context = useContext(DashboardsContext);
    if (context == null) {
        throw new Error('useDashboards must be used within an DashboardsProvider');
    }
    return context;
};
