import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType } from '@hypercharge/portal-utils';
import { INITIAL_PAGE_SIZE } from 'config';
import memoize from 'fast-memoize';
import produce from 'immer';
import { find, get, uniq } from 'lodash';
import React, { useCallback } from 'react';
import { FaEye } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import DataTable2Container from '../../../../../common/components/data-table/DataTable2Container';
import { SELECTED_VIEWS_STORAGE_KEY } from '../../../../../common/components/data-table/ViewSelector';
import { useStorage } from '../../../../../common/context/StorageContext';
import { getEntityBrowseUrl, getEntityViewUrl } from '../../../../../common/utils/url';
import { useMyContact } from '../../../../../crm/components/use-my-contact';
import { VIEW_DEFINITION_ID, ViewProvider, getColumnPropertyIdsFromParams, getDefaultOperatorByType, getQueryParams, useViews } from '../../../../../views';
import { getStoredViewInLocalStorage, isDefaultViewId } from '../../../../../views/utils';
import { PROCESSES_PATH, WORKFLOWS_PATH } from '../../../../../workflows';
import CenteredLoading from '../../../../../workflows/meta/components/common/CenteredLoading';
import { useEntityDisplayData } from '../../../../common/components/withEntityDisplayData';
import { generateDisplayDataMap } from '../../../../common/context/PropertiesProvider';
import { CMS_PATH, ENTITY_ID_PROPERTY_ID } from '../../../../common/utils/constants';
import { EditLink } from '../../items/utils';
import styles from './ReferredItemsTableContainer.module.scss';
const ReferredItemsTableContainer = ({ definitionId, isProcess = false, referredPropertyIds, baseEntityId, noFilters = false, noSearch = false, virtualMode = false }) => {
    const { t, language } = useI18n();
    const { contactId } = useMyContact();
    const { displayData } = useEntityDisplayData(definitionId);
    const { hash } = useLocation();
    const [viewIdMapInStorage] = useStorage({
        key: SELECTED_VIEWS_STORAGE_KEY,
        defaultValue: {}
    });
    const { views } = useViews();
    const view = getUpdatedView(views, definitionId, isProcess, hash, language, displayData, referredPropertyIds, baseEntityId);
    const columnRenderer = useCallback(({ row }, data, filterRequest, isTaskDefinition) => {
        const storedViewId = getStoredViewInLocalStorage({
            viewIdMapInStorage,
            contactId,
            definitionId
        });
        const baseUrl = isProcess ? `${WORKFLOWS_PATH}${PROCESSES_PATH}` : CMS_PATH;
        const toDefinitionLink = storedViewId && !isDefaultViewId(storedViewId)
            ? getEntityViewUrl(definitionId, storedViewId, baseUrl)
            : getEntityBrowseUrl(definitionId, baseUrl);
        return (React.createElement(EditLink, { className: "safe-space", to: {
                pathname: `${toDefinitionLink}/${row.original.entityId}`,
                state: {
                    data,
                    filterRequest,
                    definitionId,
                    isTaskDefinition
                }
            }, title: isProcess ? t('WORKFLOWS__GO_TO_PROCESS') : t('OPEN_ITEM') },
            React.createElement(FaEye, null)));
    }, [viewIdMapInStorage, contactId, definitionId, isProcess, t]);
    if (!displayData) {
        // This is to guarantee that the view contains the filters based on the references and for that, the displayData object is necessary
        // If this is omitted, the ViewProvider will only update the url path once and that won't include the correct filters
        return (React.createElement(CenteredLoading, { className: `${styles.centeredLoading} ReferredItemsTableContainerLoading` }));
    }
    return (React.createElement("div", { className: `${styles.outer} ReferredItemsTableContainerOuter` },
        React.createElement(ViewProvider, { definitionId: definitionId, scrollToTopOnPageChange: false, view: view, serializeToUrl: !virtualMode },
            React.createElement(DataTable2Container, { idField: ENTITY_ID_PROPERTY_ID, columnRenderer: columnRenderer, noViewActions: true, noKanban: true, noFilters: noFilters, noSearch: noSearch }))));
};
export default ReferredItemsTableContainer;
const getUpdatedView = memoize((views, definitionId, isProcess, hash, language, displayData, referredPropertyIds, baseEntityId) => {
    let view = find(views, { referenceDefinitionId: definitionId, isDefault: true });
    if (!view) {
        // Passing the view to the ViewProvider is what manages the filters, columns, ... of the table
        // Since we need to specify the filters associated with the referred items, we need to construct a dummy view
        const queryParamOrDefaultColumnPropertyIds = getColumnPropertyIdsFromParams(getQueryParams(hash), null);
        view = {
            definitionId: VIEW_DEFINITION_ID,
            entityId: 'dummy',
            title: '',
            isDefault: true,
            referenceDefinitionId: definitionId,
            processMetaId: isProcess ? definitionId : null,
            filters: {
                query: { condition: ConditionType.or, filters: [] },
                sortBy: [],
                fullText: '',
                fullTextFields: queryParamOrDefaultColumnPropertyIds,
                responseFields: queryParamOrDefaultColumnPropertyIds,
                limit: INITIAL_PAGE_SIZE,
                offset: 0,
                languageCode: language
            },
            metrics: []
        };
    }
    const displayDataMap = generateDisplayDataMap(displayData);
    view = produce(view, (draftView) => {
        // The items need to be filters on usage.
        // The default filters are with the 'AND' condition, so we can't just add to the filters array.
        // To guarantee this, we override the whole query object.
        draftView.filters.query = {
            condition: ConditionType.or,
            filters: referredPropertyIds.map((propertyId) => {
                return {
                    field: `${propertyId}.${ENTITY_ID_PROPERTY_ID}`,
                    operator: getDefaultOperatorByType(get(displayDataMap, [propertyId, 'type'], 'entity'), get(displayDataMap, [propertyId, 'meta', 'list'], false)),
                    data: baseEntityId
                };
            })
        };
        // We add columns for the fields that the item got referenced by
        // However we make the queryParams leading, otherwise the user can't edit the view
        draftView.filters.fullTextFields = uniq([
            ...(draftView?.filters?.fullTextFields || []),
            ...referredPropertyIds
        ]);
        draftView.filters.responseFields = draftView.filters.fullTextFields;
    });
    return view;
});
