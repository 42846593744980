import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { BasicInput, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { push as routerPush } from '@hypercharge/hyper-react-base/lib/router';
import { Wrapper } from '@hypercharge/hyper-react-base/lib/styling';
import { Divider } from 'antd';
import cn from 'classnames';
import { omit, pick } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { compose } from 'redux';
import { Field, Fields, getFormValues, reduxForm } from 'redux-form';
import { AntInput } from '../../../../common/components/AntInput';
import { ClipboardCopyButton, ClipboardPasteButton } from '../../../../common/components/ClipboardButtons';
import ColorCustomization from '../../../../common/components/Color/ColorCustomization/ColorCustomization';
import ColorField from '../../../../common/components/Color/ColorField/ColorField';
import MultiLanguageField from '../../../../common/components/MultiLanguageField/MultiLanguageField';
import Toggle from '../../../../common/components/Toggle';
import TabCloseConfirmation from '../../../../common/components/tab-close-confirmation/TabCloseConfirmation';
import { BROWSE_PATH } from '../../../../common/utils/constants';
import { FieldsContainer, FieldsContainerTitle } from '../../../../common/utils/formUtils';
import GroupsField, { ADMINS_PERMISSION_GROUP, OWNER_GROUP_PLACEHOLDER } from '../../../../groups';
import { AvailableSystemGroups } from '../../../../groups/constants';
import { ALL_PROCESSES_DEFINITION_ID, PROCESSES_PATH, WORKFLOWS_PATH } from '../../../common/utils/constants';
import { PERMISSION_GROUPS, PERMISSION_GROUP_LABELS } from '../../utils';
import ProcessMetaActions from '../common/ProcessMetaActions';
import styles from './ProcessMetaForm.module.scss';
import { validateProcessDefinition } from './utils';
const ALLOWED_ROW_PERMISSIONS = ['view'];
const CLIPBOARD_PROPERTIES = ['titles', 'permissions', ...PERMISSION_GROUPS];
const normalizeIdPrefix = (value) => value && value.replace(/[^a-z0-9]/gi, '').toUpperCase();
const ProcessMetaForm = ({ dirty, extraActionButtons, form, handleSubmit, invalid, isPending, isSystem, processDefinition, reset, submitting, change }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const formValue = useSelector(getFormValues(form));
    const isNew = !processDefinition.definitionId;
    useEffect(() => {
        if (invalid) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [invalid]);
    const valueToClipboard = useMemo(() => {
        return {
            ...pick(formValue, CLIPBOARD_PROPERTIES),
            custom: omit(formValue.custom, ['activity'])
        };
    }, [formValue]);
    const isValidClipboard = useCallback((clipText) => {
        try {
            const clipValue = JSON.parse(clipText);
            if (clipValue && clipValue.custom?.idPrefix && clipValue.titles) {
                return true;
            }
        }
        catch (error) {
            return false;
        }
        return false;
    }, []);
    const onPasteClipboard = useCallback((clipText) => {
        const clipValue = JSON.parse(clipText);
        for (const property of CLIPBOARD_PROPERTIES) {
            change(property, clipValue[property]);
        }
        if (clipValue.custom) {
            change('custom', {
                ...formValue.custom,
                ...omit(clipValue.custom, ['activity'])
            });
        }
    }, [change, formValue.custom]);
    const onChangeRowPermissions = useCallback((newRowPermissions) => {
        change('permissions', newRowPermissions);
    }, [change]);
    return (React.createElement("form", { className: cn('h-100', styles.metaForm), onSubmit: handleSubmit, noValidate: true },
        React.createElement(Wrapper, { className: "h-100" },
            React.createElement("div", { className: "h-100 cp-c-row cp-c-wrap cp-c-padding-2" },
                React.createElement("div", { className: "position-relative overflow-auto cp-i-100 cp-i-lg-50" },
                    React.createElement(FieldsContainerTitle, null, t('WORKFLOWS_SETTINGS_PROCESS_NAME')),
                    React.createElement(FieldsContainer, { className: "cp-c-padding-2" },
                        React.createElement(MultiLanguageField, { name: "titles", component: AntInput, disabled: isPending || submitting || isSystem, maxLength: 60, placeholder: t('COMMON__TITLE') })),
                    React.createElement(FieldsContainerTitle, null, t('PROCESS_META__TECHNICAL_DETAILS')),
                    React.createElement(FieldsContainer, { className: "cp-c-row cp-c-wrap cp-c-padding-2" },
                        React.createElement(Field, { className: "cp-i-100 cp-i-sm-33 id-prefix", name: "custom.idPrefix", type: "text", component: BasicInput, normalize: normalizeIdPrefix, label: t('PROCESS_META__ID_PREFIX'), disabled: isPending || submitting, maxLength: 5 })),
                    React.createElement(LabelText, { className: "pt-3" }, t('PROCESS_META__COLOR')),
                    React.createElement("div", { className: "cp-c-row" },
                        React.createElement(Field, { component: ColorField, name: "custom.color" })),
                    React.createElement(ColorCustomization, { submitting: submitting }),
                    React.createElement(Field, { component: Toggle, name: "custom.requiredReleaseNotes", label: t('REQUIRE_RELEASE_NOTES'), disabled: submitting })),
                React.createElement("div", { className: "position-relative overflow-auto cp-i-100 cp-i-lg-50" },
                    React.createElement(Divider, null, t('PERMISSIONS')),
                    React.createElement(Fields, { names: PERMISSION_GROUPS, permissionsGroupLabels: PERMISSION_GROUP_LABELS, component: GroupsField, disabled: submitting, withValidation: true, isPermissionToggleDisabled: (groupId, permission) => groupId === ADMINS_PERMISSION_GROUP ||
                            (groupId === OWNER_GROUP_PLACEHOLDER &&
                                AvailableSystemGroups.createGroups === permission), itemMetaDefinitionId: processDefinition?.definitionId, rowPermissions: formValue.permissions, allowedRowPermissions: ALLOWED_ROW_PERMISSIONS, onChangeRowPermissions: onChangeRowPermissions }))),
            React.createElement("div", { className: `${styles.formButtons} position-absolute top-0 end-0 w-50` },
                React.createElement("div", { className: "form-buttons-container" },
                    React.createElement(React.Fragment, null,
                        React.createElement(ClipboardCopyButton, { value: valueToClipboard, inversed: true }),
                        React.createElement(ClipboardPasteButton, { inversed: true, isValid: isValidClipboard, onClick: onPasteClipboard }),
                        isNew ? (React.createElement(Button, { inversed: true, onClick: () => {
                                dispatch(routerPush(`${WORKFLOWS_PATH}${PROCESSES_PATH}/${ALL_PROCESSES_DEFINITION_ID}${BROWSE_PATH}`));
                            }, disabled: submitting }, t('FORM_CANCEL'))) : (dirty && (React.createElement(Button, { inversed: true, onClick: reset, disabled: submitting }, t('FORM__RESET')))),
                        React.createElement(ProcessMetaActions, { disabled: submitting, definitionId: processDefinition.definitionId, 
                            // @ts-expect-error
                            save: handleSubmit, saving: submitting, canSave: dirty, canDelete: !isNew && !isSystem }),
                        extraActionButtons))),
            React.createElement(Prompt, { when: dirty && !submitting, message: () => t('UNSAVED_DATA_CONFIRM') }),
            React.createElement(TabCloseConfirmation, { dirty: dirty }))));
};
const shouldError = () => true;
export default compose(reduxForm({
    // @ts-expect-error
    validate: validateProcessDefinition,
    shouldError,
    enableReinitialize: true
}))(ProcessMetaForm);
