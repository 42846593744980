import { t } from '@hypercharge/hyper-react-base/lib/i18n';
import { DEFAULT_LANGUAGE } from 'config';
import memoize from 'fast-memoize';
import { find, flatten, get, isEmpty, isString, mapValues, set } from 'lodash';
import moment from 'moment';
import * as NodeHelpers from 'n8n-workflow/dist/NodeHelpers';
import { ActivityActionNodeSubtypes, ActivityNodeTypes } from '../../../../common/utils/types';
import { MAX_IFRAME_HEIGHT, MIN_IFRAME_HEIGHT } from '../../../../processes/components/process/IframeUrl';
import { displayNodeParameter, getNodeCredentialIssues } from '../node-form/Hp3nNodeForm/utils';
import { getIdForNode, getNextNodeId, getNodeTypeKey, isDecisionNode } from './nodes';
const stripHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
};
export const isParent = (node, id) => {
    switch (node.type) {
        case ActivityNodeTypes.ACTION:
            if (isDecisionNode(node)) {
                return node.queryTrueId === id || node.queryFalseId === id;
            }
            if ('nextIds' in node && node.nextIds) {
                return node.nextIds.some((nid) => nid === id);
            }
            return node.nextId === id;
        case ActivityNodeTypes.MERGE:
        case ActivityNodeTypes.JOIN:
        case ActivityNodeTypes.LOOP_DO:
        case ActivityNodeTypes.START:
        case ActivityNodeTypes.LOOP_WHILE:
            return node.nextId === id;
        case ActivityNodeTypes.FORK:
            return node.nextIds.some((nid) => nid === id);
        default:
            return false;
    }
};
export const getNodeWithNewNext = (node, nextId, decisionBranch) => {
    switch (node.type) {
        case ActivityNodeTypes.ACTION:
            if (isDecisionNode(node)) {
                return {
                    ...node,
                    [decisionBranch === 'TRUE' ? 'queryTrueId' : 'queryFalseId']: nextId
                };
            }
            if (typeof decisionBranch === 'number' && 'nextIds' in node) {
                const nextIds = [...(node.nextIds || [])];
                nextIds[decisionBranch] = nextId;
                return {
                    ...node,
                    nextIds
                };
            }
            return { ...node, nextId };
        case ActivityNodeTypes.MERGE:
        case ActivityNodeTypes.LOOP_DO:
        case ActivityNodeTypes.JOIN:
        case ActivityNodeTypes.START:
        case ActivityNodeTypes.LOOP_WHILE:
            return { ...node, nextId };
        case ActivityNodeTypes.FORK: {
            const newNextNodes = [...node.nextIds, nextId].filter((nid) => nid !== node.endId); // if we add a new node it is no longer needed to connect Fork to Join
            return {
                ...node,
                nextIds: newNextNodes.length === 0 ? [node.endId] : newNextNodes
            };
        }
        default:
            return node;
    }
};
export const firstLevelNodes = (nodes) => {
    const result = {};
    let nodeId = 'Start';
    while (nodeId !== 'End') {
        const node = nodes[nodeId];
        if (!node) {
            break;
        }
        Object.assign(result, { [nodeId]: node });
        if ('endId' in node && isString(node.endId)) {
            nodeId = node.endId;
        }
        else if ('nextId' in node && isString(node.nextId)) {
            nodeId = node.nextId;
        }
        else {
            break;
        }
    }
    return result;
};
export const fixClonedReferences = (nodes, mappings) => {
    nodes.forEach((node) => {
        switch (node.type) {
            case ActivityNodeTypes.FORK:
                node.nextIds = node.nextIds.map((id) => mappings[id] || id);
                node.endId = mappings[node.endId] || node.endId;
                break;
            case ActivityNodeTypes.LOOP_WHILE:
                node.startId = mappings[node.startId] || node.startId;
                node.nextId = mappings[node.nextId] || node.nextId;
                break;
            case ActivityNodeTypes.LOOP_DO:
                node.endId = (node.endId && mappings[node.endId]) || node.endId;
                node.nextId = mappings[node.nextId] || node.nextId;
                break;
            default:
                if ('nextIds' in node && node.nextIds && node.nextIds.length > 1) {
                    node.nextIds = node.nextIds.map((id) => mappings[id] || id);
                }
                if ('nextId' in node) {
                    node.nextId = mappings[node.nextId] || node.nextId;
                }
                if (isDecisionNode(node)) {
                    node.queryTrueId = mappings[node.queryTrueId] || node.queryTrueId;
                    node.queryFalseId = mappings[node.queryFalseId] || node.queryFalseId;
                    node.endId = mappings[node.endId] || node.endId;
                }
        }
    });
    return nodes;
};
export const copyNodes = (copiedNode, selectedNode, diagram, decisionBranch) => {
    const startId = copiedNode.type === ActivityNodeTypes.LOOP_WHILE ? copiedNode.startId : copiedNode.id;
    let endNodeId = 'endId' in copiedNode ? copiedNode.endId : copiedNode.id;
    if (copiedNode.type === ActivityNodeTypes.ACTION && 'nextIds' in copiedNode) {
        endNodeId = copiedNode.nextId;
    }
    const nodes = getSubTree(diagram, startId, endNodeId);
    let clonedNodes = [];
    const mappings = {};
    const nextNodeId = getNextNodeId(selectedNode, decisionBranch);
    let newLastNode;
    nodes.forEach((node) => {
        const newNode = { ...node, id: getIdForNode(getNodeTypeKey(node)) };
        mappings[node.id] = newNode.id;
        clonedNodes.push(newNode);
        if (('endId' in copiedNode && node.id == copiedNode.endId) || node.id == copiedNode.id) {
            newLastNode = newNode;
        }
        else if (copiedNode.type === ActivityNodeTypes.ACTION &&
            'nextIds' in copiedNode &&
            node.id == copiedNode.nextId) {
            newLastNode = newNode;
        }
    });
    clonedNodes = fixClonedReferences(clonedNodes, mappings);
    // @ts-expect-error
    newLastNode.nextId = nextNodeId;
    return clonedNodes;
};
export const getChildrenIds = (node, firstNode) => {
    switch (node.type) {
        case ActivityNodeTypes.ACTION:
            if (isDecisionNode(node)) {
                return [node.queryTrueId, node.queryFalseId];
            }
            if ('nextIds' in node && node.nextIds) {
                return node.nextIds;
            }
            return [node.nextId];
        case ActivityNodeTypes.MERGE:
        case ActivityNodeTypes.LOOP_DO:
        case ActivityNodeTypes.JOIN:
        case ActivityNodeTypes.START:
            return [node.nextId];
        case ActivityNodeTypes.FORK:
            return node.nextIds;
        case ActivityNodeTypes.LOOP_WHILE:
            return firstNode ? [node.startId] : [node.nextId];
        default:
            throw new Error('Could not obtain children ids');
    }
};
export const getSubTree = (activityMap, startId, endId) => {
    const nodes = new Set();
    const f = (id, firstNode) => {
        const node = activityMap[id];
        if (node == null) {
            throw new Error('Unknown node id');
        }
        nodes.add(node);
        if (id === endId) {
            return;
        }
        getChildrenIds(node, firstNode).forEach(f);
    };
    f(startId, true);
    return nodes;
};
// language only used for memoize key
export const getEdges = memoize((activityMap, nodesDescription) => {
    const f = Object.values(activityMap).map((node) => {
        switch (node.type) {
            case ActivityNodeTypes.FORK:
                return node.nextIds.map((nid) => [node.id, nid, {}]);
            case ActivityNodeTypes.LOOP_WHILE:
                return [
                    [
                        node.id,
                        node.startId,
                        {
                            label: t('PROCESS_META___CRITERIA_MET')
                        }
                    ],
                    [
                        node.id,
                        node.nextId,
                        {
                            label: ''
                        }
                    ]
                ];
            case ActivityNodeTypes.ACTION:
                if (isDecisionNode(node)) {
                    return [
                        [
                            node.id,
                            node.queryTrueId,
                            {
                                label: t('COMMON__YES'),
                                style: 'stroke: var(--successColor-6); fill: transparent'
                            }
                        ],
                        [
                            node.id,
                            node.queryFalseId,
                            {
                                label: t('COMMON__NO'),
                                style: 'stroke: var(--errorColor-5); fill: transparent'
                            }
                        ]
                    ];
                }
                if ('nextIds' in node && node.nextIds && node.nextIds.length > 1) {
                    const nodeDescription = nodesDescription[node.target];
                    return node.nextIds.map((nextId, index) => [
                        node.id,
                        nextId,
                        {
                            label: nodeDescription?.outputNames?.[index] || index
                        }
                    ]);
                }
                return [[node.id, node.nextId, {}]]; // wrapped in an array to simplify code, since both FORK and DECISION return array of arrays
            case ActivityNodeTypes.MERGE:
            case ActivityNodeTypes.LOOP_DO:
            case ActivityNodeTypes.JOIN:
            case ActivityNodeTypes.START:
                return [[node.id, node.nextId, {}]]; // wrapped in an array to simplify code, since both FORK and DECISION return array of arrays
            case ActivityNodeTypes.END:
            default:
                return [];
        }
    });
    return flatten(f);
});
const loopSvg = '<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="30" width="30" viewBox="0 0 40 40" style="vertical-align:middle;"><g><path d="m20 30v-5l6.6 6.6-6.6 6.8v-5q-5.5 0-9.4-4t-4-9.4q0-3.9 2.2-7.1l2.4 2.4q-1.2 2.1-1.2 4.7 0 4.1 2.9 7.1t7.1 2.9z m0-23.4q5.5 0 9.4 4t4 9.4q0 3.9-2.2 7.1l-2.4-2.4q1.2-2.1 1.2-4.7 0-4.1-2.9-7.1t-7.1-2.9v5l-6.6-6.6 6.6-6.8v5z"></path></g></svg>';
const emailSvg = '<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="20" width="20" viewBox="0 0 40 40" style="vertical-align:middle;"><g><path d="m20 18.4l13.4-8.4h-26.8z m13.4 11.6v-16.6l-13.4 8.2-13.4-8.2v16.6h26.8z m0-23.4c1.8 0 3.2 1.6 3.2 3.4v20c0 1.8-1.4 3.4-3.2 3.4h-26.8c-1.8 0-3.2-1.6-3.2-3.4v-20c0-1.8 1.4-3.4 3.2-3.4h26.8z"></path></g></svg>';
const warningSvg = '<svg class="warning" fill="currentColor" preserveAspectRatio="xMidYMid meet" height="25" width="25" viewBox="0 0 40 40" style="vertical-align:middle;"><g><path d="m24.4 27.9v5q0 0.5-0.4 1t-1 0.4h-5.7q-0.6 0-1-0.4t-0.4-1v-5q0-0.6 0.4-1t1-0.5h5.7q0.6 0 1 0.5t0.4 1z m0.7-23.6l-0.6 17.1q0 0.6-0.5 1t-1 0.5h-5.7q-0.6 0-1-0.5t-0.5-1l-0.6-17.1q0-0.6 0.4-1t1-0.4h7.1q0.6 0 1 0.4t0.4 1z"></path></g></svg>';
const automatedSvg = '<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="20" width="20" viewBox="0 0 40 40" style="vertical-align:middle;"><g><path d="m29.8 12.6q0.4 0.5 0.1 1l-12 25.8q-0.3 0.6-1 0.6-0.1 0-0.3 0-0.4-0.2-0.6-0.5t-0.1-0.6l4.4-18.1-9 2.3q-0.1 0-0.3 0-0.4 0-0.7-0.2-0.4-0.4-0.3-0.9l4.5-18.4q0.1-0.3 0.4-0.5t0.6-0.2h7.3q0.4 0 0.7 0.2t0.3 0.7q0 0.2-0.1 0.4l-3.8 10.3 8.8-2.2q0.2 0 0.3 0 0.4 0 0.8 0.3z"></path></g></svg>';
const processRunSvg = '<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="20" width="20"  viewBox="0 0 40 40" style="vertical-align: middle;"><g><path d="m35 7.5c0 1.9-1 3.4-2.5 4.3v7.3l-10 5v4.1c1.5 0.9 2.5 2.4 2.5 4.3 0 2.7-2.3 5-5 5s-5-2.3-5-5c0-1.9 1-3.4 2.5-4.3v-4.1l-10-5v-7.3c-1.5-0.9-2.5-2.4-2.5-4.3 0-2.7 2.3-5 5-5s5 2.3 5 5c0 1.9-1 3.4-2.5 4.3v4.1l7.5 3.8 7.5-3.8v-4.1c-1.5-0.9-2.5-2.4-2.5-4.3 0-2.7 2.3-5 5-5s5 2.3 5 5z m-25-2.5c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z m10 30c1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5-2.5 1.1-2.5 2.5 1.1 2.5 2.5 2.5z m10-25c1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5-2.5 1.1-2.5 2.5 1.1 2.5 2.5 2.5z"></path></g></svg>';
const generateDocSvg = '<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="20" width="20" viewBox="0 0 40 40" style="vertical-align: middle;"><g><path d="m22.5 10h-12.5v2.5h12.5v-2.5z m5-7.5h-22.5v35h30v-27.5l-7.5-7.5z m5 32.5h-25v-30h17.5l7.5 7.5v22.5z m-22.5-5h20v-2.5h-20v2.5z m0-5h20v-2.5h-20v2.5z m0-5h20v-2.5h-20v2.5z"></path></g></svg>';
const smsSvg = '<svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="20" width="20" viewBox="0 0 40 40" style="vertical-align:middle;"><g><path d="m28.8 30v-23.4h-17.5v23.4h17.5z m-5.4 5v-1.6h-6.8v1.6h6.8z m3.2-33.4c2.8 0 5 2.3 5 5v26.8c0 2.7-2.2 5-5 5h-13.2c-2.8 0-5-2.3-5-5v-26.8c0-2.7 2.2-5 5-5h13.2z"></path></g></svg>';
const delaySvg = '<svg width="1.4em" height="1em" fill="currentColor" aria-hidden="truev" data-icon="clock-circle" focusable="false" viewBox="64 64 896 896" xmlns="http://www.w3.org/2000/svg"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"/><path d="M686.7 638.6L544.1 535.5V288c0-4.4-3.6-8-8-8H488c-4.4 0-8 3.6-8 8v275.4c0 2.6 1.2 5 3.3 6.5l165.4 120.6c3.6 2.6 8.6 1.8 11.2-1.7l28.6-39c2.6-3.7 1.8-8.7-1.8-11.2z"/></svg>';
const milestoneSvg = '<svg width="1.4em" height="1em" fill="currentColor" aria-hidden="truev" data-icon="fa-flag" focusable="false" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg"><path d="M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z"/></svg>';
const stepSvg = '<svg height="20" width="20" fill="currentColor" aria-hidden="truev" data-icon="fa-forward-step" focusable="false" viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M287.1 447.1c17.67 0 31.1-14.33 31.1-32V96.03c0-17.67-14.33-32-32-32c-17.67 0-31.1 14.33-31.1 31.1v319.9C255.1 433.6 270.3 447.1 287.1 447.1zM52.51 440.6l192-159.1c7.625-6.436 11.43-15.53 11.43-24.62c0-9.094-3.809-18.18-11.43-24.62l-192-159.1C31.88 54.28 0 68.66 0 96.03v319.9C0 443.3 31.88 457.7 52.51 440.6z"/></svg>';
const getActionProps = (shapeProps, node, title, isInvalid) => {
    let extraSvg = '';
    let extraClass = '';
    const result = {
        ...shapeProps,
        labelType: 'html',
        shape: 'rect',
        height: 40,
        width: 200
    };
    if (node.type === ActivityNodeTypes.ACTION) {
        if (node.target === 'EMAIL') {
            extraClass = 'email';
            extraSvg = emailSvg;
        }
        else if (node.target === 'SEND_SMS') {
            extraClass = 'sms';
            extraSvg = smsSvg;
        }
        else if (node.target === 'CREATE_PROCESS_RUN') {
            extraClass = 'process_run';
            extraSvg = processRunSvg;
        }
        else if (node.target === 'GENERATE_DOCUMENT') {
            extraClass = 'generate_document';
            extraSvg = generateDocSvg;
        }
        else if (node.target === 'MILESTONE') {
            extraClass = 'milestone';
            extraSvg = milestoneSvg;
        }
        else if (node.target === 'STEP') {
            extraClass = 'step';
            extraSvg = stepSvg;
        }
        else if (isDecisionNode(node)) {
            return {
                ...shapeProps,
                label: `<div class="decision">${title}${(isInvalid && warningSvg) || ''}</div>`,
                shape: 'diamond',
                labelType: 'html',
                height: 60,
                width: 120
            };
        }
        else if (node.subtype === ActivityActionNodeSubtypes.AUTOMATED) {
            extraClass = 'automated';
            if (node.target === 'hp3n_if') {
                extraClass += ' pe-0';
            }
            extraSvg = automatedSvg;
            if ('nextIds' in node) {
                if (node.nextIds && node.nextIds.length === 2) {
                    result.shape = 'diamond';
                    result.height = 60;
                    result.width = 120;
                    result.label = `<div class="${extraClass}">${stripHTML(title)}${(isInvalid && warningSvg) || ''}</div>`;
                }
            }
        }
        else if (node.subtype === ActivityActionNodeSubtypes.SEMIAUTOMATED &&
            node.target === 'DELAY') {
            result.label = `<div style="width: 200px; height: 40px;" class="delay">${delaySvg}${stripHTML(title)}<span class="small fw-600"> ${node.parameters && node.parameters.value && node.parameters.unit
                ? node.done
                    ? 'Delay finished'
                    : node.parameters.resolveTime
                        ? `Delay ongoing: ${moment(Number(node.parameters.resolveTime)).fromNow(true)}`
                        : `${stripHTML(node.parameters.value)} ${stripHTML(node.parameters.unit)}`
                : ''}</span>${(isInvalid && warningSvg) || ''}</div>`;
        }
    }
    return {
        label: `<div style="width: 200px; height: 40px;" class="${extraClass}">${extraSvg}${stripHTML(title)}${(isInvalid && warningSvg) || ''}</div>`,
        ...result
    };
};
export const getNodes = memoize((activityMap, selectedNodes, language, nodesWithErrors, availableNodes) => {
    return mapValues(activityMap, (node) => {
        let title = '---';
        if ('titles' in node) {
            title = stripHTML(node.titles[language] || '---');
        }
        if (node.type !== ActivityNodeTypes.ACTION && title.length > 20) {
            title = `${title.slice(0, 20)}...`;
        }
        const isInvalid = !!nodesWithErrors[node.id];
        const isAvailable = availableNodes && !!availableNodes[node.id];
        const shapeProps = {
            class: `${node.id || ''} ${node.type.toLowerCase()}${find(selectedNodes, { id: node.id }) ? ' selected' : ''}${isInvalid ? ' invalid' : ''}${isAvailable ? ' available' : ''}`
        };
        switch (node.type) {
            case ActivityNodeTypes.ACTION:
                return getActionProps(shapeProps, node, title, isInvalid);
            case ActivityNodeTypes.LOOP_DO:
                return {
                    ...shapeProps,
                    label: '',
                    shape: 'circle',
                    height: 5,
                    width: 5
                };
            case ActivityNodeTypes.LOOP_WHILE:
                return {
                    ...shapeProps,
                    label: `<div style="width: 30px; height: 30px;" class="loop">${loopSvg}${(isInvalid && warningSvg) || ''}</div>`,
                    labelType: 'html',
                    width: 30,
                    height: 30,
                    shape: 'circle'
                };
            case ActivityNodeTypes.MERGE:
                return {
                    ...shapeProps,
                    label: '',
                    shape: 'diamond',
                    height: 5,
                    width: 5
                };
            case ActivityNodeTypes.FORK:
                return {
                    ...shapeProps,
                    label: '',
                    shape: 'rect',
                    width: 150
                };
            case ActivityNodeTypes.JOIN:
                return {
                    ...shapeProps,
                    label: '',
                    shape: 'rect',
                    width: 50
                };
            case ActivityNodeTypes.START:
                if (node.target) {
                    return {
                        ...shapeProps,
                        label: title,
                        shape: 'ellipse',
                        height: 40,
                        width: 150
                    };
                }
                return {
                    ...shapeProps,
                    label: node.id,
                    shape: 'circle'
                };
            case ActivityNodeTypes.END:
            default:
                return {
                    ...shapeProps,
                    label: node.id,
                    shape: 'circle'
                };
        }
    });
});
export const canDeleteNode = (selectedNode) => selectedNode.type === ActivityNodeTypes.ACTION ||
    selectedNode.type === ActivityNodeTypes.FORK ||
    selectedNode.type === ActivityNodeTypes.LOOP_WHILE;
export const canAddNode = (selectedNode) => selectedNode.type != ActivityNodeTypes.END;
export const canReplaceNode = (selectedNode) => selectedNode.type == ActivityNodeTypes.START;
export const nodeHasProperties = (selectedNode) => {
    if (selectedNode.type === ActivityNodeTypes.START && selectedNode.target) {
        return true;
    }
    return (selectedNode.type == ActivityNodeTypes.ACTION ||
        selectedNode.type == ActivityNodeTypes.LOOP_WHILE);
};
export const validateTitles = (nodeId, taskErrors, titles, t) => {
    if (!(titles && titles[DEFAULT_LANGUAGE])) {
        return {
            ...taskErrors,
            [nodeId]: {
                ...taskErrors[nodeId],
                titles: { [DEFAULT_LANGUAGE]: t('VALIDATIONS__REQUIRED') }
            }
        };
    }
    return taskErrors;
};
export const validateIframeNodeParameters = (field, nodeId, taskErrors, iframe, t) => {
    const value = get(iframe, field);
    let errorMessage;
    if (Number(value) > MAX_IFRAME_HEIGHT) {
        errorMessage = `${t('PROPERTY_MAX_VALUE')} ${MAX_IFRAME_HEIGHT}`;
    }
    if (Number(value) < MIN_IFRAME_HEIGHT) {
        errorMessage = `${t('PROPERTY_MIN_VALUE')} ${MIN_IFRAME_HEIGHT}`;
    }
    if (errorMessage) {
        return {
            ...taskErrors,
            [nodeId]: {
                parameters: {
                    iframe: {
                        [field]: errorMessage
                    }
                }
            }
        };
    }
    return taskErrors;
};
export const validateQuery = (nodeId, taskErrors, query, t) => {
    if (!query || query.filters.length === 0) {
        return {
            ...taskErrors,
            [nodeId]: { ...taskErrors[nodeId], query: t('VALIDATIONS__REQUIRED') }
        };
    }
    return taskErrors;
};
export const validateDelayParameter = (nodeId, taskErrors, parameter, t) => {
    if (!parameter?.value || !parameter?.unit) {
        const newError = {
            ...taskErrors,
            [nodeId]: {
                ...taskErrors[nodeId]
            }
        };
        if (!parameter?.value) {
            set(newError, [nodeId, 'parameters', 'delay', 'value'], t('VALIDATIONS__REQUIRED'));
        }
        if (!parameter?.unit) {
            set(newError, [nodeId, 'parameters', 'delay', 'unit'], t('VALIDATIONS__REQUIRED'));
        }
        return newError;
    }
    return taskErrors;
};
export const validateRequiredMilestoneParameters = (requiredParameters, nodeId, taskErrors, parameters, t) => {
    const errors = !isEmpty(parameters)
        ? Object.entries(parameters).reduce((acc, [key, val]) => {
            for (const param of requiredParameters) {
                if (!Object.keys(val).includes(param)) {
                    acc[key] = acc[key] || {};
                    acc[key][param] = t('VALIDATIONS__REQUIRED');
                }
            }
            return acc;
        }, {})
        : t('VALIDATIONS__REQUIRED');
    if (!isEmpty(errors)) {
        return {
            ...taskErrors,
            [nodeId]: {
                parameters: {
                    milestones: errors
                }
            }
        };
    }
    return taskErrors;
};
export const validateRequiredParameters = (requiredParameters, nodeId, taskErrors, parameters, t) => {
    return requiredParameters
        .filter((field) => {
        const value = get(parameters, field);
        if (value && Array.isArray(value) && !value.length) {
            return true;
        }
        return !value;
    })
        .reduce((newTaskErrors, field) => {
        const newErrVal = set({
            ...(newTaskErrors[nodeId] || {})
        }, field, t('VALIDATIONS__REQUIRED'));
        return {
            ...newTaskErrors,
            [nodeId]: newErrVal
        };
    }, taskErrors);
};
export const validateRequiredHp3nParameters = (parameters, credentialTypes, nodes, nodeId, taskErrors, t) => {
    const nodeDescription = nodes[parameters.target];
    const filteredParameters = nodeDescription.properties
        ? nodeDescription.properties.filter((property) => displayNodeParameter(property, parameters))
        : [];
    let nodeIssues = NodeHelpers.getNodeParametersIssues(filteredParameters, parameters);
    const nodeCredentialIssues = getNodeCredentialIssues(parameters, nodeDescription, credentialTypes);
    if (nodeIssues === null) {
        nodeIssues = nodeCredentialIssues;
    }
    else {
        NodeHelpers.mergeIssues(nodeIssues, nodeCredentialIssues);
    }
    // option validation is not implemented in NodeHelpers.getNodeParametersIssues at the moment
    for (const nodeProperty of filteredParameters) {
        if (['options'].includes(nodeProperty.type) && nodeProperty.options?.length) {
            const validOptions = nodeProperty.options.map((option) => {
                if ('value' in option) {
                    return option.value;
                }
                return '';
            });
            const value = parameters.parameters[nodeProperty.name];
            const checkValue = typeof value === 'string' || typeof value === 'boolean' || typeof value === 'number'
                ? value
                : '';
            if (checkValue && !validOptions.includes(checkValue)) {
                nodeIssues = nodeIssues ?? {};
                NodeHelpers.mergeIssues(nodeIssues, {
                    parameters: { [nodeProperty.name]: [`The value "${value}" is not supported!`] }
                });
            }
        }
    }
    if (nodeIssues?.parameters) {
        // get `parameters` errors
        for (const [field, value] of Object.entries(nodeIssues.parameters)) {
            set(taskErrors, [nodeId, 'parameters', field], value[0] ?? t('VALIDATIONS__REQUIRED'));
        }
    }
    if (nodeIssues?.credentials) {
        // get `credentials` errors
        for (const [field, value] of Object.entries(nodeIssues.credentials)) {
            set(taskErrors, [nodeId, 'credentials', field], value[0] ?? t('VALIDATIONS__REQUIRED'));
        }
    }
    return taskErrors;
};
