import { Avatar } from 'antd';
import cn from 'classnames';
import React, { useMemo } from 'react';
import useElementSize from '../../../hooks/useElementSize';
import { getImageUrl, getParentElementOrBody } from '../../../utils/common';
import { SortableFile } from '../../SortableFileWrapper';
import AvatarGroup from './AvatarGroup';
import styles from './AvatarPreview.module.scss';
// Value gathered from css rule margin-left: -3%
const MARGIN_AVATAR_OFFSET = 0.03;
const SortableAvatarGroup = ({ size, maxAvatars, containerRef, images, onImageClick }) => (React.createElement("div", { ref: containerRef },
    React.createElement(AvatarGroup, { className: cn(styles.antAvatarGroupWrapper, 'AvatarPreviewOuter'), size: size, shape: "circle", maxCount: maxAvatars, getPopupContainer: (node) => getParentElementOrBody(node, true) }, images.map((image, index) => (React.createElement(SortableFile, { key: image.id, index: index, className: "sortable bg-transparent" },
        React.createElement("div", { key: image.id, role: "button", onClick: (e) => {
                e.stopPropagation();
                onImageClick(index, image);
            } },
            React.createElement(Avatar, { shape: "circle", src: getImageUrl(image?.id, { height: size, width: size, fit: 'cover' }), alt: image.name, size: size }))))))));
const AvatarPreview = ({ images, onImageClick, size = 32 }) => {
    const { size: { width: containerWidth }, ref: containerRef } = useElementSize();
    const maxAvatars = useMemo(() => {
        // Calculate how much space one avatar takes
        const avatarSizeWithOffset = size - size * MARGIN_AVATAR_OFFSET;
        const possibleAvatars = Math.floor(containerWidth / avatarSizeWithOffset);
        // Handle case when we have unnecessary +1 placeholder
        return images.length - possibleAvatars > 0 ? possibleAvatars - 1 : possibleAvatars;
    }, [containerWidth, images.length, size]);
    return (React.createElement(SortableAvatarGroup, { size: size, maxAvatars: maxAvatars, containerRef: containerRef, images: images, onImageClick: onImageClick }));
};
export default AvatarPreview;
