import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { flatten } from 'flat';
import { isEmpty, omit } from 'lodash';
import React from 'react';
const LogItemDetails = ({ row }) => {
    const { t } = useI18n();
    let logItem = omit(row.getParentRow()?.original, ['subRows']);
    if (logItem['@message']) {
        try {
            logItem = {
                ...logItem,
                ...JSON.parse(logItem['@message'])
            };
        }
        catch (err) {
            // skip error
        }
    }
    const flattenedLogItem = flatten(logItem);
    return (React.createElement("td", { colSpan: row.getVisibleCells().length },
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: "px-2 py-1" }, t('COMMON__FIELD')),
                    React.createElement("th", { className: "px-2 py-1" }, t('COMMON__VALUE')))),
            React.createElement("tbody", null, Object.entries(flattenedLogItem).map(([key, value]) => {
                return (React.createElement("tr", { key: key },
                    React.createElement("th", { className: "px-2 py-1 align-top fw-600" }, key),
                    React.createElement("td", { className: "px-2 py-1 text-break" }, typeof value === 'object' && isEmpty(value) ? null : (React.createElement("pre", { className: "m-0" }, value)))));
            })))));
};
export default LogItemDetails;
