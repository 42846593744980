import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { isString } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import SessionSubscription from '../../../../common/session/components/SessionSubscription';
import { fetchFilesData, getFileData, isFileDataAvailable, isFileDataPending, uploadFiles } from '../../../../common/storage';
import { PropertyTypes } from '../../../../common/types';
import { getPropertiesForTranslation } from '../../../../common/utils/common';
import { DEFAULT_META_DEFINITION_TAB } from '../../../../workflows/meta/components/meta-definition-form/utils';
import { useEntityDisplayData } from '../../../common/components/withEntityDisplayData';
import { useSystemSectionsVisibility } from '../../../common/context/SystemSectionsVisibilityProvider';
import useDisplayData from '../../../hooks/useDisplayData';
import useDisplayItemMetaList from '../../../hooks/useDisplayItemMetaList';
import { useUpdateProperty } from '../../../hooks/useUpdateProperty';
import { fetchItemReferrals } from '../../actions';
import { getItemReferrals } from '../../selectors';
import DataList from './DataList';
import { getFilePropertyValueList } from './utils';
const DataListContainer = ({ className, definitionId, entityId, requiredFieldsConfig, permissionFieldsConfig, doubleCol, blacklistedPropertyIds = [], showRequiredFieldsSwitcher = true, withoutReferrals = false, expandedProperties, showAllProperties, updatePropertyData }) => {
    const { t, language } = useI18n();
    const { isSystemSectionsVisible, hiddenSections } = useSystemSectionsVisibility();
    const { displayDataWithHidden } = useEntityDisplayData(definitionId);
    const dispatch = useDispatch();
    const fetchFilesDispatch = useDispatch();
    const notificationDispatch = useDispatch();
    const [forbiddenError, setForbiddenError] = useState(false);
    const propertiesForTranslation = useMemo(() => getPropertiesForTranslation(displayDataWithHidden?.data), [displayDataWithHidden?.data]);
    const isTranslationProperty = useCallback((propertyId) => propertiesForTranslation.includes(propertyId), [propertiesForTranslation]);
    const getSortedExpandedPropertiesDataList = useCallback((propertiesDataList, propertyList) => propertyList?.reduce((acc, el) => {
        const index = propertiesDataList.findIndex((item) => item?.propertyId === el);
        index !== -1 && acc.push(propertiesDataList[index]);
        return acc;
    }, []), []);
    const { data: normalizedDataList, isLoading, isError } = useDisplayData({ definitionId, entityId }, {
        onError: (err) => {
            if (err.response && err.response.status === 403) {
                setForbiddenError(true);
            }
        }
    });
    const { fileDataIds, dataList } = useSelector((state) => {
        let dataList;
        const fileDataIds = [];
        const expandedPropertiesDataList = [];
        if (normalizedDataList) {
            const defaultTabs = {
                tabId: DEFAULT_META_DEFINITION_TAB.id,
                titles: DEFAULT_META_DEFINITION_TAB.titles,
                sections: []
            };
            const modifyFileField = (displayData) => {
                const values = getFilePropertyValueList(displayData.value);
                const displayDataValues = [];
                // added to the fileDataIds, so we don't have to loop it again
                values.forEach((file) => {
                    if (file && typeof file === 'string' && !isFileDataAvailable(state, file)) {
                        fileDataIds.push(file);
                    }
                    if (isString(file)) {
                        displayDataValues.push(getFileData(state, file) || file);
                    }
                    else if (file) {
                        displayDataValues.push(getFileData(state, file.id) || file.id);
                    }
                });
                displayData.value = displayDataValues;
                displayData.pending = values.reduce((result, file) => file ? isFileDataPending(state, typeof file === 'string' ? file : file.id) : result, false);
            };
            const tabs = (normalizedDataList.tabs || [defaultTabs])
                .map((tab) => {
                return {
                    ...tab,
                    sections: normalizedDataList.data
                        .filter((section) => {
                        if (tab.tabId == 'default') {
                            return !section.tabId || section.tabId === tab.tabId;
                        }
                        return section.tabId === tab.tabId;
                    })
                        .map((section) => {
                        section.values.forEach((displayData) => {
                            if (expandedProperties?.includes(displayData.propertyId)) {
                                if (displayData.type === PropertyTypes.file) {
                                    modifyFileField(displayData);
                                }
                                expandedPropertiesDataList.push(displayData);
                            }
                        });
                        return section;
                    })
                        .filter(({ sectionId }) => isSystemSectionsVisible || !hiddenSections.includes(sectionId))
                        .map((section) => ({
                        ...section,
                        values: section.values
                            .filter((displayData) => !blacklistedPropertyIds.includes(displayData.propertyId))
                            .filter((displayData) => !displayData?.meta?.hidden)
                            .filter((displayData) => !isTranslationProperty(displayData.propertyId))
                            .map((displayData) => {
                            if (displayData.type === PropertyTypes.file) {
                                modifyFileField(displayData);
                            }
                            return displayData;
                        })
                    }))
                };
            })
                .filter((tab) => {
                return tab.sections.length && tab.sections.some((section) => section.values.length);
            });
            dataList = {
                definitionId: normalizedDataList.definitionId,
                entityId: normalizedDataList.entityId,
                tabs,
                expandedProperties: getSortedExpandedPropertiesDataList(expandedPropertiesDataList, expandedProperties)
            };
        }
        return {
            fileDataIds,
            dataList
        };
    });
    const { data: entitiesDisplayMeta } = useDisplayItemMetaList();
    const itemReferrals = useSelector((s) => !withoutReferrals ? getItemReferrals(s, definitionId, entityId, entitiesDisplayMeta) : []);
    const activeReferrals = useMemo(() => (entitiesDisplayMeta.find((entityDisplayMeta) => entityDisplayMeta.definitionId === definitionId)?.custom?.highlightedReferrals || [])
        .filter((item) => item.active)
        .map((item) => item.definitionId), [entitiesDisplayMeta, definitionId]);
    const { mutateAsync: updateProperty } = useUpdateProperty({
        definitionId
    });
    const internalUpdatePropertyData = useCallback((propertyId, value) => {
        if (updatePropertyData) {
            return updatePropertyData(propertyId, value);
        }
        return updateProperty({
            propertyId,
            value,
            entityId
        });
    }, [updatePropertyData, updateProperty, entityId]);
    const fetchFilesDataHandler = useCallback((ids) => fetchFilesDispatch(fetchFilesData(ids)), [fetchFilesDispatch]);
    const uploadFilesHandler = useCallback((propertyId, files) => uploadFiles(dispatch, files, entityId).then((data) => internalUpdatePropertyData(propertyId, data.map((f) => f.id)), () => {
        notificationDispatch(error({ title: t('ERROR'), message: t('UPLOAD_FAILED') }));
    }), [dispatch, entityId, internalUpdatePropertyData, notificationDispatch, t]);
    useEffect(() => {
        if (fileDataIds.length) {
            void fetchFilesDataHandler(fileDataIds);
        }
    }, [fileDataIds, fetchFilesDataHandler]);
    useEffect(() => {
        if (!withoutReferrals) {
            dispatch(fetchItemReferrals(definitionId, entityId, language));
        }
    }, [dispatch, definitionId, entityId, language, withoutReferrals]);
    if (isError) {
        return forbiddenError ? React.createElement(Redirect, { to: "/" }) : React.createElement(React.Fragment, null, t('DATA_TABLE__FETCH_FAILED'));
    }
    if (dataList) {
        return (React.createElement(React.Fragment, null,
            React.createElement(SessionSubscription, { subscriptionKey: `entityData--${entityId}` }),
            React.createElement(DataList, { className: className, definitionId: definitionId, entityId: entityId, dataList: dataList, showAllProperties: showAllProperties, uploadFiles: uploadFilesHandler, updatePropertyData: internalUpdatePropertyData, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, doubleCol: doubleCol, showRequiredFieldsSwitcher: showRequiredFieldsSwitcher, itemReferrals: itemReferrals, highlightedReferrals: activeReferrals, entitiesDisplayMeta: entitiesDisplayMeta })));
    }
    return isLoading ? React.createElement(LoadingRectangles, null) : null;
};
export default DataListContainer;
