import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { push, replace } from '@hypercharge/hyper-react-base/lib/router';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { INITIAL_PAGE, INITIAL_PAGE_SIZE, PAGE_SIZES } from 'config';
import { isEmpty, isEqual, map, omit, toNumber, uniq } from 'lodash';
import React, { startTransition, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TITLE_PROPERTY_ID, getFlattenedDisplayDataList } from '~app/cms';
import { useEntityDisplayData } from '~app/cms/common/components/withEntityDisplayData';
import { getPropertyIdsForConditionalFormatting, getPropertyIdsForTranslations } from '~app/cms/data/components/items/utils';
import { TermsAggregationFilterOrderBy } from '~app/cms/hooks/useAggregation';
import { useSearchItems } from '~app/cms/hooks/useSearchItems';
import { getStoredTableCustomizations } from '~app/common/components/data-table/utils';
import { FULL_TEXT, KANBAN_KEY, ROW_HEIGHT, SORT_BY_KEY, URL_STRINGIFY_SEPARATOR } from '../constants';
import { ViewTypes, isKanbanView } from '../types';
import { getColumnDefinitionIdKanbanGroupBy, getColumnsFromParams, getFilterRequest, getFilterRequestWithAdditionalFiltersForSearch, getHasCustomColumns, getHasCustomKanban, getHasCustomMetrics, getHasCustomPage, getHasCustomRowGrouping, getHasCustomRowGroupingOrderBy, getHasCustomRowHeight, getHasCustomSortBy, getHasCustomViewType, getMetricsFromParams, getParamsForNewRoute, getQueryParams, getSortByFromParams, getTableRowGrouping, getTableRowGroupingOrderBy, getTableRowHeightType, getUpdatedLocation, parseKanbanFilter } from '../utils';
import { ViewContext, initialFilterRequest } from './ViewContext';
const defaultSortBy = [];
const defaultMetrics = [];
export const MAX_KANBAN_ITEMS = 500;
const ViewProvider = ({ definitionId, filterRequest, metrics, view, rowHeightType, scrollToTopOnPageChange = true, children, canSelectAll = false, context, serializeToUrl = true, initialFilterSelected, initialSelectedItems = [], selectedItems: parentSelectedItems, getCustomKanbanColumnsFilters, filteredItems, fetchInViewProvider = true, enabledFetch = true, initialPageSize = INITIAL_PAGE_SIZE, initialPage = INITIAL_PAGE, enableGrouping = false, getDesignFields, additionalFiltersForSearch, additionalResponseFields, initialRowGrouping, initialRowGroupingOrderBy }) => {
    const dispatch = useDispatch();
    const { language } = useI18n();
    const { displayData, displayDataStatus, displayDataWithHidden } = useEntityDisplayData(definitionId);
    const { search, hash } = useLocation();
    const virtualViewMode = !serializeToUrl || !!(filterRequest || metrics);
    const queryParams = useMemo(() => (virtualViewMode ? {} : getQueryParams(hash || search)), [virtualViewMode, hash, search]);
    useEffect(() => {
        if (search && !hash) {
            dispatch(replace(getUpdatedLocation(queryParams)));
        }
    }, [dispatch, hash, queryParams, search]);
    const viewablePropertyIds = useMemo(() => displayData && map(getFlattenedDisplayDataList(displayData), 'propertyId'), [displayData]);
    const page = convertToNumber(queryParams.page, initialPage);
    const tableRowHeight = getTableRowHeightType(queryParams, rowHeightType, view?.configuration?.rowHeightType);
    const tableRowGrouping = getTableRowGrouping(queryParams, view?.configuration?.rowGrouping);
    const tableRowGroupingOrderBy = getTableRowGroupingOrderBy(queryParams, view?.configuration?.rowGroupingOrderBy);
    const queryParamsPageSize = convertToNumber(queryParams.pageSize, initialPageSize);
    const pageSize = queryParams.pageSize && PAGE_SIZES.includes(queryParamsPageSize)
        ? queryParamsPageSize
        : getStoredTableCustomizations().pageSize || initialPageSize;
    const defaultColumns = useMemo(() => {
        return view && view.filters && view.filters.fullTextFields
            ? view.filters.fullTextFields
            : [TITLE_PROPERTY_ID];
    }, [view]);
    const finalFilterRequest = useMemo(() => {
        if (!filterRequest) {
            return getFilterRequest(queryParams, language, page, pageSize, getColumnsFromParams(queryParams, defaultColumns, viewablePropertyIds), getSortByFromParams(queryParams, view?.filters.sortBy || defaultSortBy, viewablePropertyIds), view);
        }
        else {
            return {
                ...filterRequest,
                offset: (page - 1) * pageSize,
                languageCode: language
            };
        }
    }, [
        defaultColumns,
        filterRequest,
        language,
        page,
        pageSize,
        queryParams,
        view,
        viewablePropertyIds
    ]);
    const computedMetrics = useMemo(() => metrics || getMetricsFromParams(queryParams, view?.metrics || [], viewablePropertyIds), [metrics, queryParams, view?.metrics, viewablePropertyIds]);
    const computeViewType = useMemo(() => {
        const viewTypeFromUrl = queryParams.viewType;
        const kanbanQueryFromUrl = queryParams.kanban;
        if (!viewTypeFromUrl) {
            if (isKanbanView(view?.viewType)) {
                return ViewTypes.kanban;
            }
            return ViewTypes.table;
        }
        return isKanbanView(viewTypeFromUrl) && kanbanQueryFromUrl ? ViewTypes.kanban : ViewTypes.table;
    }, [queryParams, view]);
    const hasCustomFilterRequestQuery = !isEqual(finalFilterRequest.query, view?.filters.query);
    const hasCustomViewType = getHasCustomViewType(queryParams, view);
    const hasCustomKanban = getHasCustomKanban(queryParams, view);
    const hasCustomSortBy = getHasCustomSortBy(queryParams, defaultSortBy, viewablePropertyIds, view);
    const hasCustomColumns = getHasCustomColumns(queryParams, defaultColumns, viewablePropertyIds);
    const hasCustomMetrics = getHasCustomMetrics(queryParams, view?.metrics || defaultMetrics, viewablePropertyIds);
    const hasCustomPage = getHasCustomPage(queryParams);
    const hasCustomRowHeight = getHasCustomRowHeight(queryParams, view);
    const hasCustomRowGrouping = useMemo(() => getHasCustomRowGrouping(queryParams, view), [queryParams, view]);
    const hasCustomRowGroupingOrderBy = useMemo(() => getHasCustomRowGroupingOrderBy(queryParams, view), [queryParams, view]);
    const [isAllItemsSelected, setIsAllItemsSelected] = useState(false);
    const [isAllItemsSelectedOnPage, setIsAllItemsSelectedOnPage] = useState(false);
    const [selectedItems, setSelectedItems] = useState(initialSelectedItems);
    const [filterSelected, setFilterSelected] = useState(initialFilterSelected);
    const [changedTableRowHeight, setChangedTableRowHeight] = useState();
    const [changedTableRowGrouping, setChangedTableRowGrouping] = useState(initialRowGrouping || tableRowGrouping);
    const [changedTableRowGroupingOrderBy, setChangedTableRowGroupingOrderBy] = useState(initialRowGroupingOrderBy);
    const [changedFilterRequest, setChangedFilterRequest] = useState();
    const [changedMetrics, setChangedMetrics] = useState();
    const [changedViewType, setChangedViewType] = useState();
    const [changedKanban, setChangedKanban] = useState();
    const [virtualPage, setVirtualPage] = useState(initialPage);
    const [virtualPageSize, setVirtualPageSize] = useState(initialPageSize);
    const [virtualFullTextSearch, setVirtualFullTextSearch] = useState('');
    const computedPage = virtualViewMode ? virtualPage : page;
    const computedPageSize = virtualViewMode ? virtualPageSize : pageSize;
    const computedFilterRequest = useMemo(() => {
        const req = { ...(changedFilterRequest || finalFilterRequest || initialFilterRequest) };
        if (context) {
            req.context = context;
        }
        if (filteredItems) {
            req.ids = {
                data: filteredItems,
                operator: FilterOperatorTypes.in
            };
        }
        else if (filterSelected) {
            req.ids = {
                data: selectedItems,
                operator: filterSelected
            };
        }
        else if (req.ids) {
            req.ids = undefined;
        }
        const propertyIdsForConditionalFormatting = getPropertyIdsForConditionalFormatting(displayDataWithHidden, req.responseFields);
        req.responseFields = uniq([
            ...(req.responseFields || []),
            ...propertyIdsForConditionalFormatting
        ]);
        req.offset = (computedPage - 1) * computedPageSize;
        req.limit = computedPageSize;
        req.sortBy = req.sortBy || [];
        req.query = req.query || { condition: ConditionType.and, filters: [] };
        req.fullText = virtualViewMode && virtualFullTextSearch ? virtualFullTextSearch : req.fullText;
        return req;
    }, [
        changedFilterRequest,
        finalFilterRequest,
        context,
        filteredItems,
        filterSelected,
        displayDataWithHidden,
        computedPage,
        computedPageSize,
        virtualViewMode,
        virtualFullTextSearch,
        selectedItems
    ]);
    const computedKanban = useMemo(() => parseKanbanFilter(queryParams) || view?.configuration?.kanban, [queryParams, view?.configuration?.kanban]);
    useEffect(() => {
        if (parentSelectedItems) {
            setSelectedItems(parentSelectedItems);
        }
    }, [parentSelectedItems]);
    const changeSortBy = useCallback((newSortBy) => {
        if (virtualViewMode) {
            startTransition(() => {
                setChangedFilterRequest((prevFilterRequest = computedFilterRequest) => ({
                    ...prevFilterRequest,
                    sortBy: newSortBy
                }));
                setVirtualPage(initialPage);
            });
        }
        else {
            startTransition(() => {
                dispatch(push(getUpdatedLocation({
                    ...queryParams,
                    [SORT_BY_KEY]: newSortBy.map(({ field, order }) => [field, order].join(URL_STRINGIFY_SEPARATOR)),
                    page: initialPage
                })));
            });
        }
    }, [virtualViewMode, initialPage, computedFilterRequest, dispatch, queryParams]);
    const changePage = useCallback((pageIndex) => {
        if (virtualViewMode) {
            startTransition(() => {
                setVirtualPage(pageIndex);
            });
        }
        else {
            scrollToTopOnPageChange && window.scrollTo(0, 0);
            startTransition(() => {
                dispatch(push(getUpdatedLocation({ ...queryParams, page: pageIndex })));
            });
        }
    }, [dispatch, queryParams, scrollToTopOnPageChange, virtualViewMode]);
    const changePageSize = useCallback((newPageSize) => {
        if (virtualViewMode) {
            startTransition(() => {
                setVirtualPageSize(newPageSize);
                setVirtualPage(initialPage);
            });
        }
        else {
            startTransition(() => {
                dispatch(push(getUpdatedLocation({ ...queryParams, page: initialPage, pageSize: newPageSize })));
            });
        }
    }, [dispatch, initialPage, queryParams, virtualViewMode]);
    const changeFullTextSearch = useCallback((fullText) => {
        const newParams = fullText
            ? {
                ...queryParams,
                [FULL_TEXT]: fullText,
                page: initialPage
            }
            : omit(queryParams, [FULL_TEXT]);
        startTransition(() => {
            setVirtualPage(initialPage);
            setVirtualFullTextSearch(fullText);
            !virtualViewMode && dispatch(replace(getUpdatedLocation(newParams)));
        });
    }, [dispatch, initialPage, queryParams, virtualViewMode]);
    const changeRowHeightType = useCallback((heightType) => {
        if (virtualViewMode) {
            startTransition(() => {
                setChangedTableRowHeight(heightType);
            });
        }
        else {
            startTransition(() => {
                dispatch(replace(getUpdatedLocation({ ...queryParams, [ROW_HEIGHT]: heightType })));
            });
        }
    }, [dispatch, queryParams, virtualViewMode]);
    const _setFilterSelected = useCallback((newValue) => {
        startTransition(() => {
            setFilterSelected(newValue);
        });
        changePage(1);
    }, [changePage]);
    const hasViewCustomizations = useMemo(() => hasCustomFilterRequestQuery ||
        hasCustomSortBy ||
        hasCustomViewType ||
        hasCustomKanban ||
        hasCustomColumns ||
        hasCustomMetrics ||
        hasCustomRowHeight ||
        hasCustomRowGrouping ||
        hasCustomRowGroupingOrderBy ||
        hasCustomPage, [
        hasCustomFilterRequestQuery,
        hasCustomSortBy,
        hasCustomViewType,
        hasCustomKanban,
        hasCustomColumns,
        hasCustomMetrics,
        hasCustomRowHeight,
        hasCustomRowGrouping,
        hasCustomRowGroupingOrderBy,
        hasCustomPage
    ]);
    const rowHeightTypeComputed = useMemo(() => {
        if (virtualViewMode) {
            return changedTableRowHeight || tableRowHeight;
        }
        return tableRowHeight;
    }, [tableRowHeight, virtualViewMode, changedTableRowHeight]);
    const rowGroupingComputed = useMemo(() => {
        if (virtualViewMode) {
            return changedTableRowGrouping;
        }
        return tableRowGrouping;
    }, [virtualViewMode, tableRowGrouping, changedTableRowGrouping]);
    const rowGroupingOrderByComputed = useMemo(() => {
        if (virtualViewMode) {
            return changedTableRowGroupingOrderBy || tableRowGroupingOrderBy;
        }
        return tableRowGroupingOrderBy;
    }, [changedTableRowGroupingOrderBy, tableRowGroupingOrderBy, virtualViewMode]);
    const updateRouteWithView = useCallback(({ newFilters, newMetrics, shouldReplacePath = true, viewType: newViewType = computeViewType, rowHeightType = tableRowHeight, kanban = computedKanban, rowGrouping, rowGroupingOrderBy = rowGroupingOrderByComputed }) => {
        if (virtualViewMode) {
            startTransition(() => {
                setVirtualPage(initialPage);
                setChangedFilterRequest({
                    ...newFilters,
                    offset: (page - 1) * pageSize,
                    languageCode: language,
                    fullText: virtualFullTextSearch
                });
                setChangedMetrics(newMetrics);
                setChangedViewType(newViewType);
                setChangedKanban(kanban);
                setChangedTableRowHeight(rowHeightType);
                setChangedTableRowGrouping(rowGrouping);
                setChangedTableRowGroupingOrderBy(rowGroupingOrderBy);
            });
            return;
        }
        const newParams = getParamsForNewRoute({
            newFilters,
            newMetrics,
            queryParams,
            viewType: newViewType,
            rowHeightType,
            kanban,
            rowGrouping,
            rowGroupingOrderBy
        });
        const getChangeRouteAction = shouldReplacePath ? replace : push;
        startTransition(() => {
            dispatch(getChangeRouteAction(getUpdatedLocation({ ...newParams, page: initialPage })));
        });
    }, [
        computeViewType,
        tableRowHeight,
        computedKanban,
        rowGroupingOrderByComputed,
        virtualViewMode,
        queryParams,
        initialPage,
        page,
        pageSize,
        language,
        virtualFullTextSearch,
        dispatch
    ]);
    const changeRowGrouping = useCallback((grouping) => {
        startTransition(() => {
            updateRouteWithView({
                newFilters: {
                    ...computedFilterRequest,
                    ...(grouping && {
                        responseFields: uniq([...(computedFilterRequest?.responseFields || []), grouping]),
                        fullTextFields: uniq([...(computedFilterRequest?.fullTextFields || []), grouping])
                    })
                },
                newMetrics: computedMetrics,
                shouldReplacePath: true,
                viewType: computeViewType,
                rowHeightType: rowHeightTypeComputed,
                kanban: computedKanban,
                rowGrouping: grouping,
                rowGroupingOrderBy: changedTableRowGroupingOrderBy || TermsAggregationFilterOrderBy.field
            });
        });
    }, [
        changedTableRowGroupingOrderBy,
        computeViewType,
        computedFilterRequest,
        computedKanban,
        computedMetrics,
        rowHeightTypeComputed,
        updateRouteWithView
    ]);
    const removeColumns = useCallback((removingColumns) => {
        startTransition(() => {
            updateRouteWithView({
                newFilters: {
                    ...computedFilterRequest,
                    responseFields: uniq(computedFilterRequest.responseFields?.filter((column) => !removingColumns.includes(column))),
                    fullTextFields: uniq(computedFilterRequest.fullTextFields?.filter((column) => !removingColumns.includes(column)))
                },
                newMetrics: computedMetrics,
                shouldReplacePath: true,
                viewType: computeViewType,
                rowHeightType: rowHeightTypeComputed,
                kanban: computedKanban,
                rowGrouping: rowGroupingComputed,
                rowGroupingOrderBy: changedTableRowGroupingOrderBy || TermsAggregationFilterOrderBy.field
            });
        });
    }, [
        changedTableRowGroupingOrderBy,
        computeViewType,
        computedFilterRequest,
        computedKanban,
        computedMetrics,
        rowGroupingComputed,
        rowHeightTypeComputed,
        updateRouteWithView
    ]);
    const resetVirtualView = useCallback(() => {
        startTransition(() => {
            setChangedFilterRequest(undefined);
            setChangedMetrics(undefined);
            setChangedViewType(undefined);
            setChangedKanban(undefined);
            setChangedTableRowHeight(undefined);
            setChangedTableRowGrouping(undefined);
            setChangedTableRowGroupingOrderBy(undefined);
        });
    }, []);
    const resetView = useCallback(() => {
        if (hasViewCustomizations && view) {
            startTransition(() => {
                updateRouteWithView({
                    newFilters: view.filters,
                    newMetrics: view.metrics,
                    shouldReplacePath: false,
                    viewType: view.viewType,
                    rowHeightType: view.configuration?.rowHeightType,
                    kanban: view.configuration?.kanban,
                    rowGrouping: view.configuration?.rowGrouping,
                    rowGroupingOrderBy: view.configuration?.rowGroupingOrderBy
                });
            });
        }
    }, [hasViewCustomizations, updateRouteWithView, view]);
    const viewType = changedViewType || computeViewType;
    const kanban = changedKanban || computedKanban;
    const filterRequestForSearch = useMemo(() => {
        const withAdditionalFiltersForSearch = getFilterRequestWithAdditionalFiltersForSearch(computedFilterRequest, additionalFiltersForSearch);
        withAdditionalFiltersForSearch.responseFields = getPropertyIdsForTranslations(displayData, [
            ...(withAdditionalFiltersForSearch.responseFields || []),
            ...(getDesignFields ? getDesignFields(computeViewType) : [])
        ], language);
        withAdditionalFiltersForSearch.fullTextFields = getPropertyIdsForTranslations(displayData, [
            ...(withAdditionalFiltersForSearch.fullTextFields || []),
            ...(getDesignFields ? getDesignFields(computeViewType) : [])
        ], language);
        withAdditionalFiltersForSearch.responseFields = uniq([
            ...(withAdditionalFiltersForSearch.responseFields || []),
            ...(additionalResponseFields || [])
        ]);
        if (isKanbanView(viewType)) {
            const columnDefinitionId = getColumnDefinitionIdKanbanGroupBy(displayData, kanban?.groupBy || '');
            return {
                ...withAdditionalFiltersForSearch,
                query: {
                    condition: ConditionType.and,
                    filters: [
                        withAdditionalFiltersForSearch.query,
                        ...(getCustomKanbanColumnsFilters?.(kanban?.columns, kanban?.groupBy) || [
                            {
                                id: KANBAN_KEY,
                                condition: ConditionType.or,
                                filters: kanban?.columns.map((entityId) => {
                                    const field = columnDefinitionId
                                        ? `${kanban.groupBy}.entityId`
                                        : kanban.groupBy;
                                    if (entityId === FilterOperatorTypes.empty) {
                                        return {
                                            field,
                                            operator: FilterOperatorTypes.empty
                                        };
                                    }
                                    return {
                                        field,
                                        operator: FilterOperatorTypes.is,
                                        data: entityId
                                    };
                                }) || []
                            }
                        ])
                    ]
                },
                responseFields: uniq([
                    ...(withAdditionalFiltersForSearch.responseFields || []),
                    ...((kanban && kanban.groupBy && [kanban.groupBy]) || [])
                ]),
                limit: MAX_KANBAN_ITEMS,
                offset: (withAdditionalFiltersForSearch.offset || 0) * MAX_KANBAN_ITEMS
            };
        }
        return withAdditionalFiltersForSearch;
    }, [
        computedFilterRequest,
        additionalFiltersForSearch,
        displayData,
        getDesignFields,
        computeViewType,
        language,
        additionalResponseFields,
        viewType,
        kanban,
        getCustomKanbanColumnsFilters
    ]);
    const computerEnableToFetchItems = useMemo(() => {
        if (isKanbanView(viewType)) {
            return !isEmpty(kanban?.columns);
        }
        return fetchInViewProvider;
    }, [fetchInViewProvider, kanban?.columns, viewType]);
    const searchItems = useSearchItems({
        definitionId,
        filterRequest: filterRequestForSearch
    }, {
        enabled: viewType !== ViewTypes.kanban &&
            enabledFetch &&
            computerEnableToFetchItems &&
            !rowGroupingComputed,
        keepPreviousData: true
    });
    const { data: { totalCount } = {} } = useSearchItems({
        definitionId,
        filterRequest: {
            ...filterRequestForSearch,
            responseFields: [],
            limit: 0,
            offset: 0
        }
    }, {
        enabled: !computerEnableToFetchItems || !!rowGroupingComputed
    });
    const providerValue = useMemo(() => {
        return {
            definitionId,
            displayDataStatus,
            filterRequest: computedFilterRequest,
            filterRequestForSearch,
            searchItems,
            page: computedPage,
            pageSize: computedPageSize,
            rowHeightType: rowHeightTypeComputed,
            enableGrouping,
            rowGrouping: rowGroupingComputed,
            rowGroupingOrderBy: rowGroupingOrderByComputed,
            changePage,
            changePageSize,
            changeSortBy,
            changeFullTextSearch,
            changeRowHeightType,
            changeRowGrouping,
            removeColumns,
            updateRouteWithView,
            view,
            viewType,
            kanban: changedKanban || computedKanban,
            metrics: changedMetrics || computedMetrics || [],
            hasViewCustomizations,
            changedVirtualView: !!changedFilterRequest || !!changedMetrics,
            resetVirtualView,
            resetView,
            virtualViewMode,
            isAllItemsSelected,
            setIsAllItemsSelected,
            initialSelectedItems,
            selectedItems,
            setSelectedItems,
            canSelectAll,
            filterSelected,
            setFilterSelected: _setFilterSelected,
            totalItemsCount: totalCount || searchItems.data?.totalCount || 0,
            isAllItemsSelectedOnPage,
            setIsAllItemsSelectedOnPage
        };
    }, [
        definitionId,
        displayDataStatus,
        computedFilterRequest,
        filterRequestForSearch,
        searchItems,
        computedPage,
        computedPageSize,
        rowHeightTypeComputed,
        enableGrouping,
        rowGroupingComputed,
        rowGroupingOrderByComputed,
        changePage,
        changePageSize,
        changeSortBy,
        changeFullTextSearch,
        changeRowHeightType,
        changeRowGrouping,
        removeColumns,
        updateRouteWithView,
        view,
        viewType,
        changedKanban,
        computedKanban,
        changedMetrics,
        computedMetrics,
        hasViewCustomizations,
        changedFilterRequest,
        resetVirtualView,
        resetView,
        virtualViewMode,
        isAllItemsSelected,
        initialSelectedItems,
        selectedItems,
        canSelectAll,
        filterSelected,
        _setFilterSelected,
        totalCount,
        isAllItemsSelectedOnPage
    ]);
    return React.createElement(ViewContext.Provider, { value: providerValue }, children);
};
const convertToNumber = (value, fallbackValue) => {
    if (Array.isArray(value)) {
        return fallbackValue;
    }
    return value != null && !Number.isNaN(toNumber(value)) ? toNumber(value) : fallbackValue;
};
export default ViewProvider;
