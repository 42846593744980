import React, { Component } from 'react';
import HeightContext from './HeightContext';
/**
 * @deprecated use `useContentHeight`
 */
class HeightContextProvider extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            value: { contentHeight: 'auto' }
        };
        this.updateOtherElementsHeight = () => {
            const { otherElementSelectors, otherHeightInPx = 0, parentContentHeight } = this.props;
            let otherElementsHeight = 0;
            otherElementSelectors.forEach((selector) => {
                const element = document.querySelector(selector);
                if (element) {
                    // @ts-expect-error
                    otherElementsHeight += element.offsetHeight;
                }
            });
            let height = `calc(100vh - ${otherHeightInPx + otherElementsHeight}px)`;
            if (parentContentHeight) {
                height = `calc(${parentContentHeight} - ${otherHeightInPx + otherElementsHeight}px)`;
            }
            if (this.state.value.contentHeight !== height) {
                this.setState({ value: { contentHeight: height } });
            }
        };
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateOtherElementsHeight);
        this.updateOtherElementsHeight();
    }
    componentDidUpdate() {
        this.updateOtherElementsHeight();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateOtherElementsHeight);
    }
    render() {
        return (React.createElement(HeightContext.Provider, { value: this.state.value }, this.props.children));
    }
}
export default HeightContextProvider;
