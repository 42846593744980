import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MdHistory } from 'react-icons/md';
import ArrowButton from '~app/common/components/ArrowButton';
import { InlineEditor } from '~app/common/components/InlineEditor';
import { useTranslation } from '~app/common/components/InlineEditor/SelectLanguage/SelectLanguage';
import { useBrowserBreakpoints } from '~app/common/components/with-browser-breakpoints';
import { PropertyTypes } from '~app/common/types';
import { getPropertyTypeComponent } from '~app/workflows/meta/components/meta-definition-form/utils';
import { FieldLabel } from '../FieldElements';
import { EntityDisplayAsTableContainer } from '../display/EntityDisplay/EntityDisplayAsTable';
import { getDisplayComponentForType, getEditorComponentForType, isDisplayEntityAsTable, isExpandedView, isPropertyList } from '../utils';
import styles from './PropertyFieldInlineEditor.module.scss';
const PropertyFieldInlineEditor = ({ type, label, labelClassname = '', labelDescription, value, meta, propertyId, style, canEdit, computed = false, showIcon = false, entityId, definitionId, error, onHistoryOpened, onChange, onSave, help, layout = 'horizontal', extraDisplayComponentProps = {}, extraEditorComponentProps = {}, className = '', placeholder, fromExpandedView, onShowExpandedViews, showExpandedView, updateNestedPropertyData, bulkUpdateNestedItems, additionalColumns }) => {
    const { isMobile } = useBrowserBreakpoints();
    const { t } = useI18n();
    const [unmounted, setUnmounted] = useState(false);
    const [editing, setEditing] = useState(false);
    const [hasFailed, setHasFailed] = useState(false);
    const [prevValue, setPrevValue] = useState(value);
    const { translationOptions, selectedLanguage, multiLanguagePermission, setSelectedLanguage } = useTranslation({ meta, definitionId, entityId, propertyId });
    useEffect(() => () => setUnmounted(true), []);
    useEffect(() => {
        if (!unmounted && prevValue !== value && hasFailed) {
            setHasFailed(false);
        }
        if (prevValue !== value) {
            setPrevValue(value);
        }
    }, [unmounted, prevValue, value, hasFailed]);
    const _onSave = useCallback(async (value, propertyId) => {
        onChange && onChange(value, propertyId);
        return onSave && onSave(value, propertyId);
    }, [onChange, onSave]);
    const Icon = getPropertyTypeComponent(type);
    const withFrame = type === PropertyTypes.richtext && (editing || !!value);
    const displayAsTable = useMemo(() => isDisplayEntityAsTable(meta), [meta]);
    const isAvailableExpandedView = useMemo(() => onShowExpandedViews &&
        !fromExpandedView &&
        (isExpandedView(meta) || (!isPropertyList(meta) && !!additionalColumns?.length)) &&
        value, [additionalColumns?.length, fromExpandedView, meta, onShowExpandedViews, value]);
    return (React.createElement("section", { className: cn(styles.wrapper, `d-flex flex-wrap PropertyFieldInlineEditorOuter mt-2 t-${type}`, className, {
            'available-expanded-view': isAvailableExpandedView && !isMobile,
            'show-expanded-view': !!showExpandedView && isAvailableExpandedView && !isMobile
        }) },
        label && (React.createElement("div", { className: "FieldLabelWrapper d-flex align-items-start" },
            React.createElement("div", { className: cn({ 'mw-100': !isMobile }, 'field-label-wrapper') },
                React.createElement("div", { className: "layout-field" },
                    React.createElement(FieldLabel, { className: cn(labelClassname, {
                            'w-100': layout === 'vertical'
                        }), icon: showIcon && Icon ? React.createElement(Icon, null) : null, label: label, labelDescription: labelDescription, computed: computed, help: help, hasTranslations: !!translationOptions, required: meta?.validations?.required, labelRight: isAvailableExpandedView && (React.createElement(ArrowButton, { className: cn('ms-2 rounded-circle arrow-button', {
                                collapsed: !showExpandedView
                            }), injectable: true, onClick: onShowExpandedViews, open: !!showExpandedView })) }))),
            displayAsTable && onHistoryOpened && (React.createElement(Tooltip, { title: t('VALUE_HISTORY') },
                React.createElement("div", { className: "h-100 d-flex align-items-center" },
                    React.createElement("div", { className: "safe-space", role: "button", onClick: () => {
                            onHistoryOpened();
                        } },
                        React.createElement(MdHistory, { size: 20, color: "var(--primaryColor-6)" }))))))),
        displayAsTable || (isPropertyList(meta) && !!additionalColumns?.length) ? (React.createElement(EntityDisplayAsTableContainer, { meta: meta, canEdit: canEdit, value: value, onSave: onSave, entityId: entityId, definitionId: definitionId, permissionFieldsConfig: extraDisplayComponentProps.permissionFieldsConfig, nestedRequiredFieldsConfig: extraDisplayComponentProps.requiredFieldsConfig, updateNestedPropertyData: updateNestedPropertyData, bulkUpdateNestedItems: bulkUpdateNestedItems, additionalColumns: additionalColumns })) : (React.createElement(InlineEditor, { translationOptions: multiLanguagePermission ? translationOptions : undefined, placeholder: placeholder, className: cn({
                'w-100': layout === 'vertical'
            }), displayComponent: getDisplayComponentForType(type), editorComponent: getEditorComponentForType(type), expand: true, style: style, disabled: !canEdit, propertyId: translationOptions && translationOptions[selectedLanguage]?.propertyId, value: translationOptions ? translationOptions[selectedLanguage]?.value || value : value, valueFromTranslations: translationOptions && translationOptions[selectedLanguage]?.value, meta: (translationOptions && translationOptions[selectedLanguage]?.meta) || meta, setSelectedLanguageMultiLanguage: setSelectedLanguage, selectedLanguageMultiLanguage: selectedLanguage, invalid: error, canEdit: canEdit || false, entityId: entityId, definitionId: definitionId, onSave: _onSave, afterSave: (err) => !unmounted && setHasFailed(!!err), onEditingChanged: (editing) => !unmounted && setEditing(editing), onHistoryOpened: onHistoryOpened, isWithinFrame: withFrame, extraDisplayComponentProps: extraDisplayComponentProps, extraEditorComponentProps: extraEditorComponentProps, label: label }))));
};
export default PropertyFieldInlineEditor;
