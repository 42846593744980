import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import cn from 'classnames';
import { keyBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { getDisplayComponentForType, getEditorComponentForType, getFlattenedDisplayDataList } from '~app/cms';
import { useEntityDisplayData } from '~app/cms/common/components/withEntityDisplayData';
import { FieldLabel } from '~app/cms/data/components/item-property/FieldElements';
import PropertyFieldInlineEditor from '~app/cms/data/components/item-property/PropertyFieldInlineEditor';
import { ACTIVITY_DEFINITION_ID } from '~app/common/activity';
import SelectActivityType from '~app/common/activity/components/SelectActivityType';
import { InlineEditor } from '~app/common/components/InlineEditor';
import FileInput from '~app/common/storage/components/FileField/FileInput';
import { PropertyTypes, isRichTextValue } from '~app/common/types';
import styles from './AvailableTaskActivityForm.module.scss';
const FOLLOW_UP_EXTRA_EDITOR_PROPS = {
    disabledDate: (current) => {
        return !current || current.isBefore(moment().startOf('day'));
    },
    disabledTime: (current) => {
        if (current == null) {
            return null;
        }
        const getRange = (start, end) => [...Array(end - start + 1).keys()].map((i) => i + start);
        const minDate = moment();
        if (current.isSame(minDate, 'day')) {
            return {
                disabledHours: () => {
                    return minDate.hour() > 0 ? getRange(0, minDate.hour() - 1) : [];
                },
                disabledMinutes: (hour) => {
                    return hour === minDate.hour() && minDate.minutes() > 0
                        ? getRange(0, minDate.minutes() - 1)
                        : [];
                }
            };
        }
    }
};
const AvailableTaskActivityForm = ({ value, onChange, task, className, onChangeFollowUp, canEdit }) => {
    const { t } = useI18n();
    const activityDisplayData = useEntityDisplayData(ACTIVITY_DEFINITION_ID);
    const propertyMap = useMemo(() => {
        if (!activityDisplayData.displayData) {
            return {};
        }
        return keyBy(getFlattenedDisplayDataList(activityDisplayData.displayData), 'propertyId');
    }, [activityDisplayData.displayData]);
    const isOptionHidden = useCallback((activityType) => {
        return !task.activity?.available?.includes(activityType);
    }, [task.activity?.available]);
    const onChangeType = useCallback((val) => {
        if (!val || Array.isArray(val)) {
            return;
        }
        onChange?.({
            ...value,
            typeText: val
        });
    }, [onChange, value]);
    const onChangeContact = useCallback((val) => {
        if (Array.isArray(val)) {
            return;
        }
        if (typeof val === 'number') {
            return;
        }
        if (isRichTextValue(val)) {
            return;
        }
        onChange?.({
            ...value,
            contact: val ?? undefined
        });
    }, [onChange, value]);
    const onChangeComments = useCallback((val) => {
        if (Array.isArray(val)) {
            return;
        }
        if (typeof val === 'number') {
            return;
        }
        if (typeof val === 'string') {
            return;
        }
        onChange?.({
            ...value,
            comments: val ?? undefined
        });
    }, [onChange, value]);
    const onChangeFiles = useCallback((val) => {
        onChange?.({
            ...value,
            files: val
        });
    }, [onChange, value]);
    const _onChangeFollowUp = useCallback(async (value) => {
        if (value == null || typeof value === 'number') {
            return onChangeFollowUp(value);
        }
    }, [onChangeFollowUp]);
    return (React.createElement("div", { className: cn(className, 'row') },
        React.createElement("div", { className: "col-12 col-xl-6" },
            React.createElement("div", { className: "d-flex flex-wrap mt-2 align-items-center" },
                React.createElement(FieldLabel, { className: "col-12 col-sm-4", label: t('ACTIVITY_SHORT_TYPE_LABEL'), required: true }),
                React.createElement(SelectActivityType, { className: `${styles.activitySelector} col-12 col-sm-8 w-auto`, value: value.typeText, isOptionHidden: isOptionHidden, onChange: onChangeType, showSearch: false, showArrow: true, bordered: false })),
            propertyMap.contact && (React.createElement(PropertyFieldInlineEditor, { propertyId: propertyMap.contact.propertyId, className: "mt-0", label: t('ACTIVITY_NAME'), labelClassname: "ps-1", value: value.contact, help: t('ACTIVITY_NAME_INFO'), definitionId: ACTIVITY_DEFINITION_ID, canEdit: propertyMap.contact.canEdit, type: propertyMap.contact.type, meta: propertyMap.contact.meta, computed: propertyMap.contact.computed, layout: "horizontal", onChange: onChangeContact })),
            task.taskTarget === 'ACTIVITY' && (React.createElement("div", { className: "d-flex w-100 align-items-center justify-content-between mt-2" },
                React.createElement(FieldLabel, { className: "col-12 col-sm-4", label: t('FOLLOW_UP') }),
                React.createElement(InlineEditor, { className: styles.followUpField, displayComponent: getDisplayComponentForType('date'), editorComponent: getEditorComponentForType('date'), meta: { withTime: true }, canEdit: canEdit, onSave: _onChangeFollowUp, extraEditorComponentProps: FOLLOW_UP_EXTRA_EDITOR_PROPS })))),
        React.createElement("div", { className: "col-12 col-xl-6" },
            propertyMap.comments && (React.createElement(PropertyFieldInlineEditor, { propertyId: propertyMap.comments.propertyId, value: value.comments, label: t('COMMENTS'), labelClassname: "ps-1", help: t('ACTIVITY_COMMENTS_INFO'), definitionId: ACTIVITY_DEFINITION_ID, canEdit: propertyMap.comments.canEdit, type: propertyMap.comments.type, meta: propertyMap.comments.meta, computed: propertyMap.comments.computed, layout: "horizontal", onChange: onChangeComments })),
            propertyMap.files && propertyMap.files.type === PropertyTypes.file && (React.createElement(FileInput, { label: t('COMMON_FILES'), disabled: !propertyMap.files.canEdit, groupId: task.taskRunId, loading: false, layout: "horizontal", meta: propertyMap.files.meta, value: Array.isArray(value.files) ? value.files : [], onChange: onChangeFiles })))));
};
export default AvailableTaskActivityForm;
