import { TreeSelect } from 'antd';
import React, { useMemo } from 'react';
import { MdExpandMore } from 'react-icons/md';
import { getScrollableAreaOrBody } from '../../../utils/common';
import { useLoadActivityTypes } from '../../useLoadActivityTypes';
import ActivityIcon from '../ActivityList/ActivityIcon';
import styles from './SelectActivityType.module.scss';
const { TreeNode } = TreeSelect;
const SelectActivityType = ({ className, value, onChange, placeholder, disabled, isOptionDisabled, isOptionHidden, multiple, popupMatchSelectWidth = false, showSearch = true, allowClear, style, withIcons = true, showArrow, bordered, error }) => {
    const { activityTypes, isPending } = useLoadActivityTypes();
    const currentValue = useMemo(() => {
        return multiple && !value ? [] : value;
    }, [multiple, value]);
    const options = useMemo(() => {
        if (isOptionHidden) {
            return activityTypes.filter((activityType) => {
                return !isOptionHidden(activityType.entityId);
            });
        }
        return activityTypes;
    }, [isOptionHidden, activityTypes, isPending]);
    return (React.createElement(TreeSelect, { className: className, popupClassName: styles.dropdownWrapper, showSearch: showSearch, getPopupContainer: getScrollableAreaOrBody, allowClear: allowClear, value: currentValue, placeholder: placeholder, multiple: multiple, disabled: disabled, onChange: onChange, popupMatchSelectWidth: popupMatchSelectWidth, style: style, suffixIcon: showArrow ? React.createElement(MdExpandMore, { color: "var(--neutralColor-6)" }) : undefined, bordered: bordered, status: error ? 'warning' : undefined }, options.map((item) => {
        return (React.createElement(TreeNode, { key: item.entityId, value: item.entityId, label: React.createElement("div", { className: "d-flex flex-nowrap align-items-center" },
                withIcons && React.createElement(ActivityIcon, { activityType: item, className: "ActivityIcon me-2" }),
                item.title), disabled: isOptionDisabled?.(item.entityId) || disabled }));
    })));
};
export default SelectActivityType;
