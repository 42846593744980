import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { ACTIVITY_DEFINITION_ID } from '../../../../common/activity';
import { ALL_TASKS_DEFINITION_ID } from '../../../../workflows';
import ActivityTasksWidgetWrapper from './ActivityTasks/ActivityTasksWidgetWrapper';
import WorkflowsTasksWidgetWrapper from './WorkflowsTasks/WorkflowsTasksWidgetWrapper';
const YourTasksWidget = ({ className, settings, defaultFilter }) => {
    const { t } = useI18n();
    if (settings.definitionId === ALL_TASKS_DEFINITION_ID) {
        return (React.createElement(WorkflowsTasksWidgetWrapper, { className: className, settings: settings, defaultFilter: defaultFilter }));
    }
    if (settings.definitionId === ACTIVITY_DEFINITION_ID) {
        return (React.createElement(ActivityTasksWidgetWrapper, { className: className, settings: settings, defaultFilter: defaultFilter }));
    }
    return (React.createElement("div", { className: "w-100 h-100 d-flex align-items-center justify-content-center" }, t('NO_DATA')));
};
export default YourTasksWidget;
