import memoize from 'fast-memoize';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItemsById, getItem, isItemAvailable, isItemFailed, isItemPending } from '../../../cms';
import { CONTACT_CMS_DEFINITION_ID, FETCH_CONTACT_RESPONSE_FIELDS } from '../../constants';
const contactsToFetch = new Set();
const fetchFromQueue = (fetchContacts) => {
    fetchContacts([...contactsToFetch]);
    contactsToFetch.clear();
};
const debouncedFetchContactsById = debounce(fetchFromQueue);
const addToQueueAndFetch = (id, fetchContacts) => {
    contactsToFetch.add(id);
    debouncedFetchContactsById(fetchContacts);
};
const getContactStatus = memoize((isAvailable, isPending, isFailed) => ({
    isAvailable,
    isPending,
    isFailed
}));
export const useContact = (contactId) => {
    const fetchItemsDispatch = useDispatch();
    const contactStatus = useSelector((s) => {
        if (!contactId) {
            return { isAvailable: false, isPending: false, isFailed: false };
        }
        return getContactStatus(isItemAvailable(s, CONTACT_CMS_DEFINITION_ID, contactId, FETCH_CONTACT_RESPONSE_FIELDS), isItemPending(s, CONTACT_CMS_DEFINITION_ID, contactId), isItemFailed(s, CONTACT_CMS_DEFINITION_ID, contactId));
    });
    const contact = useSelector((s) => contactId ? getItem(s, CONTACT_CMS_DEFINITION_ID, contactId) : undefined);
    const fetchContacts = useCallback((ids) => {
        void fetchItemsDispatch(fetchItemsById(CONTACT_CMS_DEFINITION_ID, ids, {
            responseFields: FETCH_CONTACT_RESPONSE_FIELDS
        }));
    }, [fetchItemsDispatch]);
    useEffect(() => {
        if (contactId && !contactStatus?.isAvailable && contactId !== 'system') {
            addToQueueAndFetch(contactId, fetchContacts);
        }
    }, [contactId, fetchContacts, contactStatus]);
    return useMemo(() => ({
        contactId,
        fetchContacts,
        contactStatus,
        contact
    }), [contact, contactId, contactStatus, fetchContacts]);
};
