import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Tooltip } from 'antd';
import cn from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';
import { MdOutlineSettings } from 'react-icons/md';
import { Link, NavLink, useLocation } from 'react-router-dom';
import ConditionalWrapper from '../ConditionalWrapper';
import { TooltipTruncate } from '../TooltipTruncate';
import styles from './DesktopSideMenuItem.module.scss';
import { FeaturedButton } from './FeaturedButton';
import SideMenuIcon from './SideMenuIcon/SideMenuIcon';
const DesktopSideMenuItem = ({ className, item: { icon: ItemIcon, exact, to, toSettings, title, subTitle, style, key }, tooltip, isFeatured, withFeaturedEntitiesSwitch, handleFeaturedChange }) => {
    const { t } = useI18n();
    const location = useLocation();
    const ref = useRef(null);
    const isActiveLink = useCallback((_, location) => {
        return (!!(to && location.pathname.startsWith(to)) ||
            !!(toSettings && location.pathname.startsWith(toSettings)));
    }, [to, toSettings]);
    useEffect(() => {
        if (ref.current &&
            isActiveLink(null, location) &&
            localStorage.getItem('prevent-side-menu-scroll')) {
            setTimeout(() => {
                ref?.current?.scrollIntoView();
            });
        }
        localStorage.removeItem('prevent-side-menu-scroll');
    }, [isActiveLink, location, ref]);
    return (React.createElement("div", { className: cn(styles.container, 'DesktopSideMenuItem', className), ref: ref },
        React.createElement(ConditionalWrapper, { Wrapper: Tooltip, condition: Boolean(tooltip), title: tooltip, placement: "right" },
            React.createElement(NavLink, { className: cn('DesktopSideMenuItem__nav-link', {
                    'DesktopSideMenuItem__nav-link--hasSettings': toSettings
                }), to: to || '', exact: exact, isActive: isActiveLink, "data-test-id": `Link-DesktopSideMenuItem-${key}` },
                React.createElement(SideMenuIcon, { itemIcon: ItemIcon, style: style, className: "me-2" }),
                React.createElement("div", { className: "DesktopSideMenuItem__text" },
                    subTitle && (React.createElement("span", { className: "DesktopSideMenuItem__subtitle-text" },
                        React.createElement(TooltipTruncate, null, subTitle))),
                    title)),
            React.createElement("div", { className: "DesktopSideMenuItem__actions-block" },
                React.createElement(FeaturedButton, { id: key, isFeatured: isFeatured, withFeaturedEntitiesSwitch: withFeaturedEntitiesSwitch, handleFeaturedChange: handleFeaturedChange }),
                toSettings && (React.createElement(Tooltip, { placement: "top", title: t('SETTINGS') },
                    React.createElement(Link, { to: toSettings },
                        React.createElement(Button, { className: "DesktopSideMenuItem__settings-link safe-space" },
                            React.createElement(MdOutlineSettings, null)))))))));
};
export default DesktopSideMenuItem;
