import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { isEmpty, isEqual, uniqWith } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { ENTITY_ID_PROPERTY_ID, TITLE_PROPERTY_ID } from '../../../cms/common/utils/constants';
import { useView } from '../ViewContext';
import MetricItemDisplay from './MetricItemDisplay';
import styles from './MetricsDisplayContainer.module.scss';
export const prepareMetricGroupQuery = (field, activeGroupingElements) => {
    if (!activeGroupingElements?.length || !field) {
        return;
    }
    const splittedFieldIds = field.split('.');
    if (splittedFieldIds[1] === ENTITY_ID_PROPERTY_ID) {
        splittedFieldIds[1] = TITLE_PROPERTY_ID;
    }
    return {
        condition: ConditionType.or,
        filters: activeGroupingElements.map((value) => {
            if (value === null) {
                return {
                    field: splittedFieldIds.join('.'),
                    operator: FilterOperatorTypes.empty
                };
            }
            return {
                data: value,
                field: splittedFieldIds.join('.'),
                operator: FilterOperatorTypes.is
            };
        })
    };
};
const getSelectedMetricParams = (metric) => {
    let metricQuery;
    let metricGroupQuery;
    if (metric.query?.filters?.length) {
        metricQuery = {
            condition: metric.query.condition,
            filters: metric.query.filters
        };
    }
    if (metric.rowGrouping) {
        metricGroupQuery = prepareMetricGroupQuery(metric.rowGrouping, metric.activeGroupingElements);
    }
    return { metricQuery, metricGroupQuery };
};
const MetricsDisplayContainer = ({ className }) => {
    const { definitionId, filterRequest, filterRequestForSearch, metrics, updateRouteWithView, viewType, rowGrouping } = useView();
    const updateRouteAndMetrics = useCallback((selectedMetric, filtersToApply, metrics) => {
        const searchQuery = {
            ...filterRequest,
            query: {
                ...filterRequest.query,
                filters: uniqWith(filtersToApply, isEqual)
            }
        };
        const updatedMetrics = metrics.map((metric) => {
            if (metric.id === selectedMetric.id) {
                return selectedMetric;
            }
            return metric;
        });
        updateRouteWithView({
            newFilters: searchQuery,
            newMetrics: updatedMetrics,
            shouldReplacePath: false,
            viewType,
            rowGrouping
        });
    }, [filterRequest, rowGrouping, updateRouteWithView, viewType]);
    const onChangeMetric = useCallback((selectedMetric) => {
        const { metricQuery, metricGroupQuery } = getSelectedMetricParams(selectedMetric);
        let filtersToApply;
        if (selectedMetric.selected) {
            filtersToApply = [...filterRequest.query.filters];
            metricQuery && filtersToApply.push(metricQuery);
            metricGroupQuery && filtersToApply.push(metricGroupQuery);
        }
        else {
            filtersToApply = filterRequest.query.filters.filter((group) => !isEqual(group, metricQuery) && !isEqual(group, metricGroupQuery));
        }
        updateRouteAndMetrics(selectedMetric, filtersToApply, metrics);
    }, [filterRequest.query.filters, metrics, updateRouteAndMetrics]);
    const onChangeAggregation = useCallback((selectedMetric, previousFilterActiveElements) => {
        let filtersToApply;
        const oldMetricGroup = prepareMetricGroupQuery(selectedMetric.rowGrouping, previousFilterActiveElements);
        const { metricQuery, metricGroupQuery } = getSelectedMetricParams(selectedMetric);
        if (selectedMetric.selected && selectedMetric?.rowGrouping) {
            const cleanedFilters = filterRequest.query.filters.filter((group) => !isEqual(group, oldMetricGroup) && !isEqual(group, metricQuery));
            filtersToApply = [...cleanedFilters];
            metricGroupQuery && filtersToApply.push(metricGroupQuery);
            metricQuery && filtersToApply.push(metricQuery);
        }
        else {
            filtersToApply = filterRequest.query.filters.filter((group) => !isEqual(group, metricQuery) && !isEqual(group, oldMetricGroup));
        }
        updateRouteAndMetrics(selectedMetric, filtersToApply, metrics);
    }, [filterRequest.query.filters, metrics, updateRouteAndMetrics]);
    const conditionalQuery = useMemo(() => ({
        ...filterRequestForSearch,
        limit: 0,
        offset: 0,
        sortBy: [],
        responseFields: []
    }), [filterRequestForSearch]);
    if (!definitionId || isEmpty(metrics)) {
        return null;
    }
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''} pb-2 MetricsDisplayOuter`, id: "aggregation-content" }, metrics.map((metric) => (React.createElement(MetricItemDisplay, { key: metric.id, definitionId: definitionId, metric: metric, onChangeMetric: onChangeMetric, onChangeAggregation: onChangeAggregation, additionalMetricFilter: conditionalQuery })))));
};
export default MetricsDisplayContainer;
