import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import styles from './Breadcrumbs.module.scss';
const renderBreadcrumb = (link, index) => (React.createElement("div", { className: `d-flex overflow-hidden text-nowrap align-items-center ${styles.breadcrumbItem}`, key: `${link.text}-${index}` },
    React.createElement(NavLink, { to: link.to, className: `text-nowrap overflow-hidden border-0 ${styles.styledLink}` }, link.text)));
const Breadcrumbs = ({ links, currentBreadcrumb }) => {
    const pageTitle = useMemo(() => {
        return [
            ...links.map((l) => (typeof l.text === 'string' ? l.text : '')),
            typeof currentBreadcrumb === 'string' ? currentBreadcrumb : ''
        ]
            .filter(Boolean)
            .join(' › ');
    }, [currentBreadcrumb, links]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, { title: pageTitle }),
        React.createElement("div", { className: `flex-wrap d-flex overflow-hidden m-0 text-nowrap px-3 py-1 ${styles.breadcrumbsList}` },
            links.map(renderBreadcrumb),
            React.createElement("div", { className: `d-flex flex-truncate overflow-hidden text-nowrap align-items-center ${styles.breadcrumbItem} ${styles.currentBreadcrumb} ` }, currentBreadcrumb))));
};
export default Breadcrumbs;
