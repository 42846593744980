import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Menu } from 'antd';
import { find, isObject, isString } from 'lodash';
import React, { useMemo } from 'react';
import { FaRegFolder } from 'react-icons/fa';
import { GoDatabase } from 'react-icons/go';
import { IoMdGitNetwork } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FEATURED_ENTITIES_PATH } from '../../cms';
import useDisplayItemMetaList from '../../cms/hooks/useDisplayItemMetaList';
import DropdownDoubleArrowButton from '../../common/DropdownDoubleArrowButton';
import { SELECTED_VIEWS_STORAGE_KEY } from '../../common/components/data-table/ViewSelector';
import SideMenuIcon from '../../common/components/side-menu/SideMenuIcon/SideMenuIcon';
import { useStorage } from '../../common/context/StorageContext';
import { getConfigsState } from '../../common/reducers/rootSelector';
import { PROCESS_META_HANDLER } from '../../common/utils/constants';
import { getEntityBrowseUrl, getEntityViewUrl } from '../../common/utils/url';
import { getConfig } from '../../configs';
import { useMyContact } from '../../crm/components/use-my-contact';
import { GROUP_CMS_DEFINITION_ID } from '../../groups';
import { CMS_KEY, SETTINGS_KEY } from '../../settings';
import { filterRemovedDefinition } from '../../settings/components/cms/components/FeaturedDefinitionIds/FeaturedDefinitionIds';
import { useViews } from '../../views';
import { getStoredViewInLocalStorage, isDefaultViewId } from '../../views/utils';
import { FEATURED_PROCESSES_PATH } from '../../workflows';
import DisableAntAnimationProvider from '../Header/components/DisableAntAnimationProvider/DisableAntAnimationProvider';
import styles from './FeaturedEntities.module.scss';
const isSubMenuType = (item) => {
    return isObject(item) && 'type' in item && 'children' in item;
};
const FeaturedEntities = ({ location: { pathname } }) => {
    const { language } = useI18n();
    const { contactId } = useMyContact();
    const { views } = useViews();
    const { data: entities } = useDisplayItemMetaList();
    const configsState = useSelector((s) => getConfigsState(s));
    const cmsConfig = getConfig(configsState, SETTINGS_KEY, CMS_KEY);
    const [viewIdMapInStorage] = useStorage({
        key: SELECTED_VIEWS_STORAGE_KEY,
        defaultValue: {}
    });
    const { data: displayItemMetaList } = useDisplayItemMetaList();
    const menu = useMemo(() => {
        const getItem = (featuredDefinitionId) => {
            const view = views.find((view) => view.entityId === featuredDefinitionId);
            const definitionId = view ? view.referenceDefinitionId : featuredDefinitionId;
            const displayItemMeta = find(entities, { definitionId });
            const basePath = displayItemMeta?.handler === PROCESS_META_HANDLER
                ? FEATURED_PROCESSES_PATH
                : FEATURED_ENTITIES_PATH;
            let to;
            let key;
            if (view) {
                to = getEntityViewUrl(definitionId, view.entityId, basePath);
                key = to;
            }
            else if ([GROUP_CMS_DEFINITION_ID].includes(definitionId)) {
                to = getEntityBrowseUrl(definitionId, basePath);
                key = to;
            }
            else {
                const storedViewId = getStoredViewInLocalStorage({
                    definitionId,
                    contactId,
                    viewIdMapInStorage
                });
                to =
                    storedViewId && !isDefaultViewId(storedViewId)
                        ? getEntityViewUrl(definitionId, storedViewId, basePath)
                        : getEntityBrowseUrl(definitionId, basePath);
                key = storedViewId ? `${to}-${storedViewId}` : to;
            }
            return {
                key,
                label: (React.createElement(Link, { "data-test-id": `Link-Header-FeaturedEntities-${featuredDefinitionId || ''}`, className: "outline-animation d-flex align-items-center", to: to },
                    React.createElement(SideMenuIcon, { className: "me-1", itemIcon: displayItemMeta?.handler === PROCESS_META_HANDLER ? IoMdGitNetwork : GoDatabase, style: displayItemMeta?.style }),
                    view?.title || displayItemMeta?.title || displayItemMeta?.definitionId))
            };
        };
        return (cmsConfig?.data?.featuredDefinitionIds
            ?.filter((featuredDefinitionId) => filterRemovedDefinition(featuredDefinitionId, views, displayItemMetaList))
            ?.filter((item) => isString(item) || item.values.length)
            .map((featuredDefinitionId) => {
            if (isString(featuredDefinitionId)) {
                return getItem(featuredDefinitionId);
            }
            const key = `folder-${featuredDefinitionId.id}`;
            return {
                key,
                label: (React.createElement("div", { "data-test-id": `Folder-Header-FeaturedEntities-${featuredDefinitionId.id}`, className: "d-flex align-items-center" },
                    React.createElement(SideMenuIcon, { className: "me-1", itemIcon: FaRegFolder }),
                    getTranslation(featuredDefinitionId.titles, language))),
                popupClassName: 'app-header-menu-popup-submenu',
                type: `subMenu-${featuredDefinitionId.id}`,
                children: featuredDefinitionId.values
                    .filter((featuredDefinitionId) => filterRemovedDefinition(featuredDefinitionId, views, displayItemMetaList))
                    .map((value) => getItem(value))
            };
        }) || []);
    }, [
        cmsConfig?.data?.featuredDefinitionIds,
        contactId,
        displayItemMetaList,
        entities,
        language,
        viewIdMapInStorage,
        views
    ]);
    const activeKeys = useMemo(() => {
        const response = [];
        menu.forEach((menuItem) => {
            const to = menuItem?.key;
            if (isString(to) && RegExp(to).exec(pathname)) {
                response.push(to);
            }
            if (isSubMenuType(menuItem)) {
                menuItem.children.forEach((subItem) => {
                    const to = subItem?.key;
                    if (isString(to) && RegExp(to).exec(pathname)) {
                        response.push(menuItem.key, to);
                    }
                });
            }
        });
        return response;
    }, [menu, pathname]);
    if (!menu.length) {
        return null;
    }
    return (React.createElement(DisableAntAnimationProvider, null,
        React.createElement("section", { id: "featured-entities", className: `${styles.header} pe-3` },
            React.createElement(Menu, { className: styles.menu, selectedKeys: activeKeys, mode: "horizontal", triggerSubMenuAction: "click", overflowedIndicatorPopupClassName: "app-header-menu-popup-submenu w-75 w-md-auto", overflowedIndicator: React.createElement(DropdownDoubleArrowButton, null), items: menu }))));
};
export default FeaturedEntities;
