import { maxLength, required } from '@hypercharge/hyper-react-base/lib/form';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from 'config';
import { isEmpty, pickBy, reduce } from 'lodash';
import { validateGroups } from '../../../../groups';
import { PERMISSION_GROUPS } from '../../utils';
export const formName = 'processMeta';
export const validateProcessDefinition = (formValues) => {
    const errors = {
        ...validateLabels(formValues),
        ...validatePrefixId(formValues),
        ...validateGroups(PERMISSION_GROUPS, formValues)
    };
    if (!isEmpty(errors)) {
        return errors;
    }
};
const validateLabels = (formValues) => pickBy(reduce(AVAILABLE_LANGUAGES, (acc, language) => {
    const value = formValues?.titles?.[language];
    const requiredMsg = language === DEFAULT_LANGUAGE ? required(value) : undefined;
    if (!requiredMsg) {
        return acc;
    }
    else {
        return {
            ...acc,
            titles: {
                ...acc.titles,
                [language]: requiredMsg
            }
        };
    }
}, {}));
const MAX_ID_PREFIX_LENGTH = 5;
const getMaxLengthMsg = maxLength(MAX_ID_PREFIX_LENGTH);
const validatePrefixId = (formValues) => {
    const value = formValues.custom?.idPrefix;
    const maxLengthMsg = getMaxLengthMsg(value, {});
    const requiredMsg = required(value);
    return maxLengthMsg || requiredMsg ? { custom: { idPrefix: maxLengthMsg || requiredMsg } } : {};
};
