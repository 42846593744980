import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { chunk, debounce, find, forEach, get, groupBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TooltipTruncate } from '../../../../../../common/components/TooltipTruncate';
import { SELECTED_VIEWS_STORAGE_KEY } from '../../../../../../common/components/data-table/ViewSelector';
import ShowMore from '../../../../../../common/components/show-more';
import { useStorage } from '../../../../../../common/context/StorageContext';
import { MAX_ITEMS_COUNT_FOR_ENTITY_SELECTOR, PROCESS_META_HANDLER } from '../../../../../../common/utils/constants';
import { getEntityBrowseUrl, getEntityViewUrl } from '../../../../../../common/utils/url';
import { useMyContact } from '../../../../../../crm/components/use-my-contact';
import { CONTACT_CMS_DEFINITION_ID, SYSTEM_USERS } from '../../../../../../crm/constants';
import { getStoredViewInLocalStorage, isDefaultViewId } from '../../../../../../views/utils';
import { PROCESSES_PATH, WORKFLOWS_PATH } from '../../../../../../workflows';
import { useEntityDisplayData } from '../../../../../common/components/withEntityDisplayData';
import { ENTITY_ID_PROPERTY_ID, TITLE_PROPERTY_ID } from '../../../../../common/utils/constants';
import useDisplayItemMeta from '../../../../../hooks/useDisplayItemMeta';
import { fetchItemsByGroupedDefinitionId } from '../../../../actions';
import { getItem, isItemAvailable, isItemPending } from '../../../../selectors';
import { isTextDisplayData } from '../../../../types';
import { getFlattenedDisplayDataList, getItemRepresentation } from '../../../../utils';
import QuickEditItem from '../../../item/QuickEditItem';
import { FieldWrapper } from '../../FieldElements';
import styles from './EntityDisplay.module.scss';
const isEntityDefinitionSystem = (definitionId, entityId) => definitionId === CONTACT_CMS_DEFINITION_ID && SYSTEM_USERS.includes(entityId);
const itemsToFetch = new Set();
const fetchFromQueue = (fetchItemsByGroupedDefinitionId) => {
    const itemsToFetchInfo = [...itemsToFetch].map((info) => JSON.parse(info));
    forEach(groupBy(itemsToFetchInfo, 'language'), (infoByLanguage) => {
        // When fetching results from the search there is a
        // limit of 500 results per request. To make the request
        // smaller and avoid possible timeouts we chunk each request
        // to maximum of 200 ids per request instead of 500.
        const chunkedItems = chunk(infoByLanguage, 200);
        for (const items of chunkedItems) {
            const idsByDefinition = {};
            const titleTranslationsFields = new Set();
            forEach(groupBy(items, 'definitionId'), (infoByLanguageAndDefinitionId, definitionId) => {
                idsByDefinition[definitionId] = infoByLanguageAndDefinitionId.map(({ id, titleTranslations }) => {
                    Object.values(titleTranslations || {}).forEach((key) => titleTranslationsFields.add(key));
                    return id;
                });
            });
            fetchItemsByGroupedDefinitionId(idsByDefinition, [...titleTranslationsFields]);
        }
    });
    itemsToFetch.clear();
};
const debouncedFetchItemsByGroupedDefinitionId = debounce(fetchFromQueue);
const addToQueueAndFetch = (definitionId, id, language, titleTranslations, fetchItemsByGroupedDefinitionId) => {
    itemsToFetch.add(JSON.stringify({ definitionId, id, language, titleTranslations }));
    debouncedFetchItemsByGroupedDefinitionId(fetchItemsByGroupedDefinitionId);
};
const maxCollapsedLines = 5;
const EntityDisplay = ({ value, meta: { definitionId }, disabled, asField, requiredFieldsConfig, permissionFieldsConfig, updateNestedPropertyData, className, onMultipleValuesClick, style, tabIndex, onKeyDown }) => {
    const { t, language } = useI18n();
    const { contactId } = useMyContact();
    const dispatch = useDispatch();
    const [viewIdMapInStorage] = useStorage({
        key: SELECTED_VIEWS_STORAGE_KEY,
        defaultValue: {}
    });
    const [validatedAvailability, setValidatedAvailability] = useState(false);
    const [activeEntityId, setActiveEntityId] = useState(undefined);
    const [showQuickEditItem, setShowQuickEditItem] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const { displayDataWithHidden, displayDataStatus: { isAvailable: isAvailableDisplayData } } = useEntityDisplayData(definitionId);
    const { data: displayItemMeta } = useDisplayItemMeta({ definitionId });
    const isProcess = useMemo(() => get(displayItemMeta, 'handler') === PROCESS_META_HANDLER, [displayItemMeta]);
    const getItemSelector = useSelector((s) => (definitionId, id) => getItem(s, definitionId, id));
    const isItemAvailableSelector = useSelector((s) => (definitionId, id) => isItemAvailable(s, definitionId, id));
    const isItemPendingSelector = useSelector((s) => (definitionId, id) => isItemPending(s, definitionId, id));
    const titleTranslations = useMemo(() => {
        const displayData = find(getFlattenedDisplayDataList(displayDataWithHidden), {
            propertyId: TITLE_PROPERTY_ID
        });
        return isTextDisplayData(displayData) ? displayData.meta.translations : undefined;
    }, [displayDataWithHidden]);
    const fetchItemsByGroupedDefinitionIdDispatch = useCallback((idsByDefinition, titleTranslationsFields) => dispatch(fetchItemsByGroupedDefinitionId(idsByDefinition, {
        responseFields: [ENTITY_ID_PROPERTY_ID, TITLE_PROPERTY_ID, ...titleTranslationsFields]
    })), [dispatch]);
    const onDrawerClose = useCallback(() => {
        setShowQuickEditItem(false);
    }, []);
    const toggleShowMore = useCallback((e) => {
        e.stopPropagation();
        setCollapsed((prevValue) => !prevValue);
    }, []);
    useEffect(() => {
        if (value && isAvailableDisplayData) {
            (Array.isArray(value) ? value : [value])
                .filter((id) => !isItemAvailableSelector(definitionId, id))
                .forEach((id) => addToQueueAndFetch(definitionId, id, language, titleTranslations, fetchItemsByGroupedDefinitionIdDispatch));
            setValidatedAvailability(true);
        }
    }, [
        value,
        definitionId,
        language,
        isItemAvailableSelector,
        fetchItemsByGroupedDefinitionIdDispatch,
        isAvailableDisplayData,
        titleTranslations
    ]);
    const handleMultipleValuesClick = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        if (onMultipleValuesClick) {
            onMultipleValuesClick();
        }
    }, [onMultipleValuesClick]);
    const theme = useMemo(() => ({
        primary: style?.backgroundColor,
        secondary: style?.color
    }), [style]);
    const { itemsJsx, isMultiLine, hasInvisible, totalItems } = useMemo(() => {
        let itemsJsx = null;
        let isMultiLine = false;
        let hasInvisible = false;
        let totalItems = 0;
        if (value) {
            const values = Array.isArray(value) ? value : [value];
            isMultiLine = values.length > 1;
            const valueListBulletJsx = isMultiLine ? (React.createElement("span", { className: "list-bullet", style: { background: theme.secondary } })) : null;
            totalItems = values.length;
            hasInvisible =
                totalItems < MAX_ITEMS_COUNT_FOR_ENTITY_SELECTOR && totalItems > maxCollapsedLines;
            const visibleValues = collapsed && hasInvisible ? values.slice(0, maxCollapsedLines) : values;
            itemsJsx =
                visibleValues.length < MAX_ITEMS_COUNT_FOR_ENTITY_SELECTOR
                    ? visibleValues.map((id) => {
                        if (!isEntityDefinitionSystem(definitionId, id)) {
                            const item = getItemSelector(definitionId, id);
                            const storedViewId = getStoredViewInLocalStorage({
                                viewIdMapInStorage,
                                contactId,
                                definitionId
                            });
                            const toProcessLink = storedViewId && !isDefaultViewId(storedViewId)
                                ? getEntityViewUrl(definitionId, storedViewId, `${WORKFLOWS_PATH}${PROCESSES_PATH}`)
                                : getEntityBrowseUrl(definitionId, `${WORKFLOWS_PATH}${PROCESSES_PATH}`);
                            // TODO: note that the url should contain the processMetaId instead of the definitionId
                            // For now we can leave it because it'll become the same very soon and for most of the cases it's already the same
                            return (React.createElement("div", { className: "w-100 d-flex", key: id }, isProcess ? (React.createElement(Link, { className: "styled-link d-flex align-items-center mw-100", to: `${toProcessLink}/${id}` },
                                valueListBulletJsx,
                                React.createElement(TooltipTruncate, null, item
                                    ? getItemRepresentation(item, titleTranslations && titleTranslations[language])
                                    : id))) : (React.createElement("span", { className: `${styles.openDrawerLink} d-flex align-items-center open-item-info-drawer-link mw-100`, onClick: (e) => {
                                    setActiveEntityId(id);
                                    setShowQuickEditItem(true);
                                    e.stopPropagation();
                                    e.preventDefault();
                                } },
                                valueListBulletJsx,
                                React.createElement(TooltipTruncate, null, item
                                    ? getItemRepresentation(item, titleTranslations && titleTranslations[language])
                                    : id)))));
                        }
                        else if (!validatedAvailability ||
                            itemsToFetch.has(JSON.stringify({ definitionId, id, language, titleTranslations })) ||
                            isItemPendingSelector(definitionId, id)) {
                            return (React.createElement("span", { key: id },
                                valueListBulletJsx,
                                "..."));
                        }
                        return (React.createElement("span", { key: id },
                            valueListBulletJsx,
                            React.createElement("span", { className: "id-outer" }, id)));
                    })
                    : [
                        React.createElement("div", { key: visibleValues.toString(), className: styles.multipleValues, onClick: handleMultipleValuesClick },
                            React.createElement(FaEye, { className: "icon" }),
                            React.createElement("span", { className: "text" },
                                React.createElement("span", null,
                                    visibleValues.length,
                                    " "),
                                React.createElement("span", null, t('CMS_ITEMS'))))
                    ];
            if (itemsJsx.length == 0) {
                itemsJsx = '\u00a0';
            }
        }
        return { itemsJsx, isMultiLine, hasInvisible, totalItems };
    }, [
        value,
        theme.secondary,
        collapsed,
        handleMultipleValuesClick,
        t,
        definitionId,
        validatedAvailability,
        language,
        titleTranslations,
        isItemPendingSelector,
        getItemSelector,
        viewIdMapInStorage,
        contactId,
        isProcess
    ]);
    const updatePropertyData = useCallback(async (propertyId, value) => {
        if (!activeEntityId) {
            return;
        }
        return updateNestedPropertyData?.(activeEntityId, propertyId, value);
    }, [activeEntityId, updateNestedPropertyData]);
    return itemsJsx ? (React.createElement(ErrorBoundary, null,
        activeEntityId && (React.createElement(QuickEditItem, { definitionId: definitionId, entityId: activeEntityId || '', open: showQuickEditItem, onClose: onDrawerClose, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, updatePropertyData: updateNestedPropertyData ? updatePropertyData : undefined })),
        React.createElement(FieldWrapper, { tabIndex: tabIndex, onKeyDown: onKeyDown, simple: !asField, disabled: disabled, className: `${styles.outer} ${className ?? ''} ${isMultiLine && totalItems < MAX_ITEMS_COUNT_FOR_ENTITY_SELECTOR ? 'multiline' : ''}`, style: style },
            React.createElement(React.Fragment, null,
                itemsJsx,
                hasInvisible && (React.createElement(ShowMore, { className: "w-100", itemsCount: totalItems, collapsed: collapsed, onClick: toggleShowMore, theme: theme })))))) : (React.createElement(FieldWrapper, { tabIndex: tabIndex, onKeyDown: onKeyDown, simple: !asField, disabled: disabled, className: `${styles.outer} ${className ?? ''} ${isMultiLine && totalItems < MAX_ITEMS_COUNT_FOR_ENTITY_SELECTOR ? 'multiline' : ''}`, style: style }, '\u00a0'));
};
export default EntityDisplay;
