import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { capitalize, find, get, head, isEmpty, isEqual, map } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CONTACT_GROUPS_PROPERTY_ID, ENTITY_ID_PROPERTY_ID, TITLE_PROPERTY_ID, getFlattenedDisplayDataList } from '../../../cms';
import { useEntityDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import useDeleteItems from '../../../cms/hooks/useDeleteItems';
import useDisplayItemMeta from '../../../cms/hooks/useDisplayItemMeta';
import { Drawer } from '../../../common/components/Drawer';
import { useBrowserBreakpoints } from '../../../common/components/with-browser-breakpoints';
import { CONTACT_CMS_DEFINITION_ID } from '../../../crm';
import { GROUPS_PATH, GROUP_CMS_DEFINITION_ID } from '../../constants';
import { useGroups } from '../../hooks/useGroups';
import { GroupItemTitle } from './GroupItemTitle';
import { Hierarchy } from './Hierarchy';
import { MobileHierarchy } from './MobileHierarchy';
import { UpdateGroupMembers } from './UpdateGroupMembers';
import { getParentGroupIds, getParentGroupMap, getTreeData } from './utils';
const HierarchyContainer = ({ activeGroupEntityId, className = '', contentHeight, isCollapsed, onCollapseChange, setCurrentBreadcrumbText, setLinks }) => {
    const { t } = useI18n();
    const { isDesktop } = useBrowserBreakpoints();
    const { groups, groupsStatus, parentGroupMap } = useGroups();
    const { displayData } = useEntityDisplayData(CONTACT_CMS_DEFINITION_ID);
    const { mutateAsync: deleteItems } = useDeleteItems({ definitionId: GROUP_CMS_DEFINITION_ID });
    const { data: groupsEntity, isLoading: isGroupEntityLoading } = useDisplayItemMeta({
        definitionId: GROUP_CMS_DEFINITION_ID
    });
    const mounted = useRef(false);
    const [previousGroups, setPreviousGroups] = useState([]);
    const [previousActiveGroupEntityId, setPreviousActiveGroupEntityId] = useState(activeGroupEntityId);
    const [selectedKeys, setSelectedKeys] = useState(activeGroupEntityId ? [activeGroupEntityId] : []);
    const [expandedKeys, setExpandedKeys] = useState(() => getParentGroupIds(parentGroupMap));
    const [saving, setSaving] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [membersUpdateDrawerVisible, setMembersUpdateDrawerVisible] = useState(false);
    const [membersUpdateGroup, setMembersUpdateGroup] = useState();
    const [membersUpdateType, setMembersUpdateType] = useState('add');
    const dispatchNotification = useDispatch();
    const dispatchRouterPush = useDispatch();
    const canCreateAndDeleteGroup = useMemo(() => groupsEntity?.canCreate || false, [groupsEntity?.canCreate]);
    const canUpdateGroupMembers = useMemo(() => getFlattenedDisplayDataList(displayData).find((dataList) => dataList.propertyId === CONTACT_GROUPS_PROPERTY_ID)?.canEdit || false, [displayData]);
    const entityTitle = useMemo(() => groupsEntity?.[TITLE_PROPERTY_ID] || (isGroupEntityLoading ? '...' : t('NO_TITLE')), [groupsEntity, isGroupEntityLoading, t]);
    const deleteGroup = useCallback(async (groupId) => {
        try {
            await deleteItems({ ids: [groupId] });
            dispatchNotification(success({
                title: t('COMMON__SUCCESS'),
                message: t('GROUP__DELETE_SUCCESS')
            }));
        }
        catch (_) {
            dispatchNotification(error({
                title: t('COMMON__FAILURE'),
                message: t('GROUP__DELETE_ERROR')
            }));
        }
    }, [deleteItems, dispatchNotification, t]);
    const handleSelectGroup = useCallback((selectedKeys) => {
        const groupId = head(selectedKeys);
        dispatchRouterPush(push(groupId ? [GROUPS_PATH, groupId].join('/') : GROUPS_PATH));
        setSelectedKeys(selectedKeys);
    }, [dispatchRouterPush]);
    const updateBreadcrumbs = useCallback(() => {
        if (activeGroupEntityId) {
            setLinks([
                { text: t('CRM_NAV_LINK'), to: GROUPS_PATH },
                { text: t('GROUP__NAV_LINK'), to: GROUPS_PATH }
            ]);
            setCurrentBreadcrumbText(get(find(groups, { entityId: activeGroupEntityId }), TITLE_PROPERTY_ID) ||
                (groupsStatus.isPending ? '...' : t('NO_TITLE')));
        }
        else {
            setLinks([{ text: t('CRM_NAV_LINK'), to: GROUPS_PATH }]);
            setCurrentBreadcrumbText(t('GROUP__NAV_LINK'));
        }
    }, [activeGroupEntityId, groups, groupsStatus.isPending, setCurrentBreadcrumbText, setLinks, t]);
    const getFilteredGroups = useCallback(() => {
        const upperSearchText = searchText.toUpperCase();
        const filteredGroups = searchText
            ? groups.filter(({ title }) => title ? title.toUpperCase().indexOf(upperSearchText) > -1 : false)
            : groups;
        const groupsToShow = new Set(map(filteredGroups, ENTITY_ID_PROPERTY_ID));
        filteredGroups.forEach(({ entityId }) => {
            let parentGroup = parentGroupMap[entityId];
            while (parentGroup) {
                groupsToShow.add(parentGroup);
                parentGroup = parentGroupMap[parentGroup];
            }
        });
        return groups.filter(({ entityId }) => groupsToShow.has(entityId));
    }, [groups, parentGroupMap, searchText]);
    const onExpand = useCallback((expandedKeys) => {
        if (!searchText) {
            setExpandedKeys(expandedKeys.filter((key) => getParentGroupIds(parentGroupMap).includes(key)));
        }
    }, [parentGroupMap, searchText]);
    const handleDelete = useCallback(async (groupId) => {
        setSaving(true);
        await deleteGroup(groupId);
        setSaving(false);
        if (selectedKeys.includes(groupId)) {
            handleSelectGroup([]);
        }
    }, [deleteGroup, handleSelectGroup, selectedKeys]);
    const toggleMembersUpdateDrawer = useCallback((group, membersUpdateType) => {
        if (mounted.current) {
            if (membersUpdateType) {
                setMembersUpdateDrawerVisible(true);
                setMembersUpdateGroup(group);
                setMembersUpdateType(membersUpdateType);
            }
            else {
                setMembersUpdateDrawerVisible(false);
            }
        }
    }, []);
    const getTree = useCallback((item) => {
        const isSelected = selectedKeys.includes(item.key);
        const treeNodeProps = {
            key: item.key,
            selectable: !isSelected,
            title: (React.createElement(GroupItemTitle, { canCreateAndDeleteGroup: canCreateAndDeleteGroup, canUpdateGroupMembers: canUpdateGroupMembers, handleDelete: handleDelete, isCollapsed: isCollapsed, isSelected: isSelected, saving: saving, searchText: searchText, toggleMembersUpdateDrawer: toggleMembersUpdateDrawer, treeItem: item }))
        };
        if (isEmpty(item.children)) {
            return treeNodeProps;
        }
        return { ...treeNodeProps, children: item.children.map(getTree) };
    }, [
        canCreateAndDeleteGroup,
        canUpdateGroupMembers,
        handleDelete,
        isCollapsed,
        saving,
        searchText,
        selectedKeys,
        toggleMembersUpdateDrawer
    ]);
    const afterAddGroupMembers = useCallback((groupId) => {
        toggleMembersUpdateDrawer();
        if (selectedKeys.includes(groupId)) {
            handleSelectGroup([]);
        }
        handleSelectGroup([groupId]);
    }, [handleSelectGroup, selectedKeys, toggleMembersUpdateDrawer]);
    const parentGroupIds = useMemo(() => getParentGroupIds(parentGroupMap), [parentGroupMap]);
    const filteredGroups = useMemo(() => getFilteredGroups(), [getFilteredGroups]);
    const treeData = useMemo(() => getTreeData(filteredGroups, parentGroupMap), [filteredGroups, parentGroupMap]);
    useEffect(() => {
        updateBreadcrumbs();
    }, [updateBreadcrumbs]);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);
    useEffect(() => {
        if (!isEqual(previousGroups, groups)) {
            setPreviousGroups(groups);
            updateBreadcrumbs();
            const newParentGroupMap = getParentGroupMap(groups);
            const parentGroupIds = getParentGroupIds(newParentGroupMap);
            let newExpandedKeys = isEmpty(previousGroups)
                ? parentGroupIds
                : expandedKeys.filter((id) => parentGroupIds.includes(id)); // Filter out expanded groups that are no longer parents
            // If a group gets nested under an existing hierarchy structure that is not expanded, we will expand it
            if (activeGroupEntityId &&
                newParentGroupMap[activeGroupEntityId] &&
                newParentGroupMap[activeGroupEntityId] !== parentGroupMap[activeGroupEntityId]) {
                let parentGroup = newParentGroupMap[activeGroupEntityId];
                while (parentGroup) {
                    if (!expandedKeys.includes(parentGroup)) {
                        newExpandedKeys = [...newExpandedKeys, parentGroup];
                    }
                    parentGroup = newParentGroupMap[parentGroup];
                }
            }
            setExpandedKeys(newExpandedKeys);
        }
        else if (previousActiveGroupEntityId !== activeGroupEntityId) {
            setPreviousActiveGroupEntityId(activeGroupEntityId);
            updateBreadcrumbs();
            if (!activeGroupEntityId) {
                setSelectedKeys([]);
            }
        }
    }, [
        activeGroupEntityId,
        expandedKeys,
        groups,
        parentGroupMap,
        previousActiveGroupEntityId,
        previousGroups,
        updateBreadcrumbs
    ]);
    return (React.createElement(ErrorBoundary, null,
        isDesktop ? (React.createElement(Hierarchy, { onCollapseChange: onCollapseChange, isCollapsed: isCollapsed, className: className, contentHeight: contentHeight, canCreateAndDeleteGroup: canCreateAndDeleteGroup, entityTitle: entityTitle, saving: saving, handleSelectGroup: handleSelectGroup, expandedKeys: expandedKeys, setExpandedKeys: setExpandedKeys, searchText: searchText, parentGroupMap: parentGroupMap, setSearchText: setSearchText, selectedKeys: selectedKeys, onExpand: onExpand, filteredGroups: filteredGroups, parentGroupIds: parentGroupIds, treeData: treeData, getTree: getTree })) : (React.createElement(MobileHierarchy, { className: className, canCreateAndDeleteGroup: canCreateAndDeleteGroup, entityTitle: entityTitle, saving: saving, handleSelectGroup: handleSelectGroup, expandedKeys: expandedKeys, setExpandedKeys: setExpandedKeys, searchText: searchText, parentGroupMap: parentGroupMap, setSearchText: setSearchText, selectedKeys: selectedKeys, onExpand: onExpand, filteredGroups: filteredGroups, parentGroupIds: parentGroupIds, treeData: treeData, getTree: getTree })),
        React.createElement(Drawer, { title: `${capitalize(get(membersUpdateGroup, TITLE_PROPERTY_ID, '') || '')}: ${t(membersUpdateType === 'add' ? 'GROUP__ADD_MEMBERS' : 'GROUP__REMOVE_MEMBERS')}`, onClose: () => toggleMembersUpdateDrawer(), open: membersUpdateDrawerVisible, destroyOnClose: true }, membersUpdateGroup && (React.createElement(UpdateGroupMembers, { updateType: membersUpdateType, group: membersUpdateGroup, onFinish: afterAddGroupMembers, onCancel: toggleMembersUpdateDrawer })))));
};
export default HierarchyContainer;
