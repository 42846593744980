import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { RiSettings3Line } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFeaturedDefinitionIdsOneLevel } from '../../../cms/data/utils';
import { getConfig } from '../../../configs';
import { CMS_KEY, SETTINGS_KEY } from '../../../settings';
import { getConfigsState } from '../../reducers/rootSelector';
import { FeaturedButton } from '../side-menu/FeaturedButton';
import SideMenuIcon from '../side-menu/SideMenuIcon/SideMenuIcon';
import styles from './PushMenu.module.scss';
import { SlideTransition } from './SlideTransition';
import { VersatileNavLink } from './VersatileNavLink';
export const PushMenu = ({ key, items, handleFeaturedChange, hideHome, onItemClick, withFeaturedEntitiesSwitch, itemClassName = '' }) => {
    const { t } = useI18n();
    const configsState = useSelector((s) => getConfigsState(s));
    const cmsConfig = getConfig(configsState, SETTINGS_KEY, CMS_KEY);
    const featuredDefinitionIds = useMemo(() => cmsConfig?.data?.featuredDefinitionIds || [], [cmsConfig]);
    const [path, setPath] = useState([]);
    const navUp = useCallback(() => {
        setPath((currentPath) => currentPath.slice(0, -1));
    }, []);
    const navDown = useCallback((index) => {
        setPath((currentPath) => currentPath.concat(index));
    }, []);
    const pathItems = useMemo(() => path.reduce((resultItems, index) => resultItems[index]?.children || [], items), [items, path]);
    return (React.createElement("div", { className: styles.menu, key: key || 'push-menu' },
        path.length > 0 ? (React.createElement(VersatileNavLink, { className: `${styles.navLink} item`, to: "#", onClick: navUp, "data-test-id": "mobile-menu-item-user-dropdown-nav-back" },
            React.createElement("div", { className: "cp-c-row cp-c-align-start-center" },
                React.createElement(IoIosArrowBack, { size: 17 }),
                React.createElement("div", null,
                    React.createElement("div", { className: `${styles.text} has-icon` }, t('BACK_NAV_LINK')))))) : (!hideHome && (React.createElement(VersatileNavLink, { className: cn('fw-600 item', styles.navLink), exact: true, to: "/", onClick: onItemClick, "data-test-id": "mobile-menu-item-user-dropdown-home" }, t('HOME_NAV_LINK')))),
        React.createElement(SlideTransition, { depth: path.length },
            React.createElement("div", { className: styles.subMenu }, pathItems &&
                pathItems.map(({ icon: ItemIcon, title, subTitle, isActive, exact, key, children, to, toSettings, disabled, style, onClickAction }, index) => {
                    const className = cn('fw-600 ', styles.navLink, itemClassName, {
                        active: isActive
                    });
                    if (children?.length) {
                        return (React.createElement(VersatileNavLink, { className: className, onClick: () => navDown(index), to: "#", key: key, exact: !!exact, isActive: (_, location) => {
                                if ((to && location.pathname.startsWith(to)) ||
                                    (toSettings && location.pathname.startsWith(toSettings))) {
                                    return true;
                                }
                                return false;
                            }, "data-test-id": `mobile-menu-item-user-dropdown-${key}` },
                            React.createElement("div", { className: "cp-c-row cp-c-align-spacebetween-center" },
                                React.createElement("div", { className: "cp-c-row cp-c-align-start-center" },
                                    React.createElement(SideMenuIcon, { itemIcon: ItemIcon, style: style }),
                                    React.createElement("div", null,
                                        React.createElement("div", { className: `${styles.text} ${ItemIcon ? 'has-icon' : ''}` },
                                            subTitle && React.createElement("span", { className: styles.subTitleText }, subTitle),
                                            title))),
                                React.createElement(IoIosArrowForward, { size: 17 }))));
                    }
                    if (to) {
                        return (React.createElement(VersatileNavLink, { key: key, className: cn('d-flex justify-content-between align-items-center pe-3', styles.menuItem, className), to: to, onClick: onItemClick, disabled: disabled, exact: !!exact, isActive: (_, location) => {
                                if ((to && location.pathname.startsWith(to)) ||
                                    (toSettings && location.pathname.startsWith(toSettings))) {
                                    return true;
                                }
                                return false;
                            }, "data-test-id": `mobile-menu-item-user-dropdown-${key}` },
                            React.createElement("div", { className: "d-flex justify-content-start align-items-center" },
                                React.createElement(SideMenuIcon, { itemIcon: ItemIcon, style: style }),
                                React.createElement("div", null,
                                    React.createElement("div", { className: `${styles.text} ${ItemIcon ? 'has-icon' : ''}` },
                                        subTitle && React.createElement("span", { className: styles.subTitleText }, subTitle),
                                        title))),
                            React.createElement("div", { className: "d-flex align-items-center gap-3" },
                                React.createElement(FeaturedButton, { id: key, isFeatured: getFeaturedDefinitionIdsOneLevel(featuredDefinitionIds).includes(key), withFeaturedEntitiesSwitch: withFeaturedEntitiesSwitch, handleFeaturedChange: handleFeaturedChange }),
                                toSettings && (React.createElement(Link, { className: styles.settingsLink, to: toSettings },
                                    React.createElement(Tooltip, { placement: "bottom", title: t('SETTINGS') },
                                        React.createElement(RiSettings3Line, null)))))));
                    }
                    if (onClickAction) {
                        return (React.createElement("div", { key: index, className: cn(className, 'd-flex align-items-center'), onClick: onClickAction }, title));
                    }
                    return null;
                })))));
};
