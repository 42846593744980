import React, { useCallback, useEffect } from 'react';
import { KanbanBoard } from '~app/cms/data/components/items/Kanban/KanbanBoard';
import { handleKanbanDragInCache } from '~app/cms/data/components/items/utils';
import { DataActionsContainer } from '~app/common/components/DataActionsContainer';
import queryClient from '~app/common/utils/react-query-client';
import { MetricsDisplayContainer } from '~app/views';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID } from '~app/workflows';
const KanbanBoardWrapper = ({ actions, baseUrl, contentHeight, definitionId, disabledColumns, fields, getCustomColumnCountFilters, getCustomRouteHandleCardClick, idField, isDropDisabled, isPending, lists, noFilters, noSearch, noViewActions, onChangePosition, showStartProcessSelector, showViewsSelector, viewId, withCmsImport }) => {
    const onDragEnd = useCallback(async (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            return;
        }
        const { destinationCachedData, destinationColumnQuery, item, newDestinationList, newSourceList, sourceCachedData, sourceColumnQuery } = handleKanbanDragInCache({
            destinationColumnId: destination.droppableId,
            sourceColumnId: source.droppableId,
            definitionId,
            sourceDragIndex: source.index
        });
        try {
            if (sourceColumnQuery) {
                queryClient.setQueryData(sourceColumnQuery.queryKey, newSourceList);
            }
            if (destinationColumnQuery) {
                queryClient.setQueryData(destinationColumnQuery.queryKey, newDestinationList);
            }
            await onChangePosition({
                entityId: item.entityId,
                destinationColumnId: destination.droppableId,
                sourceColumnId: source.droppableId,
                title: item.title || item.entityId
            });
        }
        catch (_) {
            if (sourceColumnQuery) {
                queryClient.setQueryData(sourceColumnQuery.queryKey, sourceCachedData);
            }
            if (destinationColumnQuery) {
                queryClient.setQueryData(destinationColumnQuery.queryKey, destinationCachedData);
            }
        }
    }, [definitionId, onChangePosition]);
    useEffect(() => {
        const stopPropagation = (e) => {
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
                e.message === 'ResizeObserver loop limit exceeded') {
                e.stopImmediatePropagation();
            }
        };
        // Virtuoso's resize observer can this error,
        // which is caught by DnD and aborts dragging.
        window.addEventListener('error', stopPropagation);
        return () => window.removeEventListener('error', stopPropagation);
    }, []);
    return (React.createElement(React.Fragment, null,
        (!noFilters || !noSearch || actions.length > 0) && (React.createElement(DataActionsContainer, { actions: actions, isPending: isPending, items: [], baseUrl: baseUrl, idField: idField, noFilters: noFilters, noSearch: noSearch, noViewActions: noViewActions, showViewsSelector: showViewsSelector, withCmsImport: withCmsImport, showStartProcessSelector: showStartProcessSelector })),
        React.createElement("div", { style: { height: contentHeight }, className: "overflow-y-auto custom-scroll-styling" },
            React.createElement(MetricsDisplayContainer, null),
            React.createElement(KanbanBoard, { isDropDisabled: isDropDisabled ||
                    definitionId === ALL_PROCESSES_DEFINITION_ID ||
                    definitionId === ALL_TASKS_DEFINITION_ID, disabledColumns: disabledColumns, isPending: isPending, onDragEnd: onDragEnd, baseUrl: baseUrl, contentHeight: contentHeight, getCustomColumnCountFilters: getCustomColumnCountFilters, getCustomRouteHandleCardClick: getCustomRouteHandleCardClick, definitionId: definitionId, fields: fields, lists: lists, viewId: viewId }))));
};
export default KanbanBoardWrapper;
