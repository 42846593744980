import { DownOutlined } from '@ant-design/icons';
import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import cn from 'classnames';
import { groupBy, keyBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IoMdGitNetwork } from 'react-icons/io';
import { VscDebugStart } from 'react-icons/vsc';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { useEntitiesDisplayData } from '../../../../../cms/common/components/withEntityDisplayData';
import { PropertiesProvider } from '../../../../../cms/common/context/PropertiesProvider';
import useDisplayItemMetaList from '../../../../../cms/hooks/useDisplayItemMetaList';
import { useHandleSearchItems } from '../../../../../cms/hooks/useSearchItems';
import DisableAntAnimationProvider from '../../../../../header/Header/components/DisableAntAnimationProvider/DisableAntAnimationProvider';
import { useView } from '../../../../../views/components/ViewContext';
import { ConditionQueryField } from '../../../ConditionQuery';
import { Drawer, DrawerContent, DrawerFooter } from '../../../Drawer';
import { DrawerContentBlock } from '../../../Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../Drawer/DrawerFormFooter';
import DropdownMenu from '../../../DropdownMenu/DropdownMenu';
import { TooltipTruncate } from '../../../TooltipTruncate';
import SideMenuIcon from '../../../side-menu/SideMenuIcon/SideMenuIcon';
import { getAvailabilityLabel, getSearchItemFilter } from '../../utils';
import { RelatedProcessesSelectorModal } from '../RelatedProcessesSelectorModal';
import { ItemProcessDisabled } from './ItemProcessDisabled';
import styles from './RelatedProcessesSelector.module.scss';
const RELATED_PROCESS_SELECTOR_MAX_SELECTED_ITEMS = 12000;
const SEARCH_DELAY = 800;
const hasItems = (items) => !!items && items?.totalCount > 0;
export const RelatedProcessesSelector = ({ metaDefinitionId, selectedIds }) => {
    const dispatch = useDispatch();
    const [processConditionQuery, setProcessConditionQuery] = useState();
    const [selectedProcessDefinitionId, setSelectedProcessDefinitionId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [dropdownMenuItems, setDropdownMenuItems] = useState([]);
    const [isDisabledProcessToStart, setIsDisabledProcessToStart] = useState(false);
    const { filterRequestForSearch, isAllItemsSelected } = useView();
    const { data: entities } = useDisplayItemMetaList();
    const { handleFetch: handleFetchSearchItems } = useHandleSearchItems();
    const { t } = useI18n();
    const availableProcessesDisplayMeta = useMemo(() => entities.filter((itemMeta) => itemMeta.handler === 'workflows' &&
        (itemMeta?.custom?.processRunFromEntities || []).some((entity) => entity.definitionId === metaDefinitionId && entity.active)), [entities, metaDefinitionId]);
    const availableProcessesDisplayMetaIds = useMemo(() => availableProcessesDisplayMeta.map(({ definitionId }) => definitionId), [availableProcessesDisplayMeta]);
    const { displayData, displayDataStatus: { isPending: isLoadingProcesses } } = useEntitiesDisplayData(availableProcessesDisplayMetaIds);
    const displayDataMap = useMemo(() => keyBy(displayData, 'definitionId'), [displayData]);
    const filteredProcessDisplayMeta = useMemo(() => availableProcessesDisplayMeta.filter(({ canCreate, definitionId }) => displayDataMap[definitionId] && canCreate), [availableProcessesDisplayMeta, displayDataMap]);
    const getDropdownMenuItems = useCallback(async () => {
        const items = await Promise.all(filteredProcessDisplayMeta.map(async ({ definitionId, title, custom, style }) => {
            let isDisabledRunProcess = false;
            const processRunEntitiesItem = custom?.processRunFromEntities?.find((item) => item.definitionId === metaDefinitionId);
            const filter = getSearchItemFilter(isAllItemsSelected, filterRequestForSearch, selectedIds, processRunEntitiesItem?.conditions);
            if (filter) {
                const items = await handleFetchSearchItems(metaDefinitionId, filter);
                isDisabledRunProcess = hasItems(items);
            }
            const setConditions = () => {
                if (processRunEntitiesItem?.conditions) {
                    setProcessConditionQuery(processRunEntitiesItem?.conditions);
                }
            };
            return {
                key: definitionId,
                label: isDisabledRunProcess ? (React.createElement(ItemProcessDisabled, { onShowDisabledReason: setConditions, title: title })) : (title),
                icon: React.createElement(SideMenuIcon, { itemIcon: IoMdGitNetwork, style: custom?.style || style || {} }),
                disabled: isDisabledRunProcess
            };
        }));
        const itemsByStatus = groupBy(items, ({ disabled }) => disabled);
        return Object.entries(itemsByStatus)
            .sort(([disabled]) => (disabled === 'false' ? -1 : 1))
            .map(([group, value]) => {
            const groupValue = getAvailabilityLabel(t, group);
            return {
                key: groupValue,
                label: groupValue,
                type: 'group',
                children: value
            };
        });
    }, [
        filterRequestForSearch,
        filteredProcessDisplayMeta,
        handleFetchSearchItems,
        isAllItemsSelected,
        metaDefinitionId,
        selectedIds,
        t
    ]);
    const handleModalClose = useCallback(() => {
        setSelectedProcessDefinitionId('');
    }, []);
    const handleClickDropDownButton = useCallback(async (dropdownOpen) => {
        if (!dropdownOpen) {
            return;
        }
        try {
            setIsLoading(true);
            const items = await getDropdownMenuItems();
            setDropdownMenuItems(items);
        }
        catch (err) {
            setDropdownMenuItems([]);
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: t('QUICKSTART_PROCESS_ERROR_CONDITIONS')
            }));
            throw err;
        }
        finally {
            setIsLoading(false);
        }
    }, [dispatch, getDropdownMenuItems, t]);
    const handleChangeSelectedIds = useCallback(async () => {
        if (filteredProcessDisplayMeta?.length === 1) {
            try {
                const process = filteredProcessDisplayMeta[0];
                const processRunEntitiesItem = process.custom?.processRunFromEntities?.find((item) => item.definitionId === metaDefinitionId);
                let isDisabledRunProcess = false;
                const filter = getSearchItemFilter(isAllItemsSelected, filterRequestForSearch, selectedIds, processRunEntitiesItem?.conditions);
                if (filter) {
                    setIsLoading(true);
                    const items = await handleFetchSearchItems(metaDefinitionId, filter);
                    isDisabledRunProcess = !!items && items?.totalCount > 0;
                }
                setIsDisabledProcessToStart(isDisabledRunProcess);
            }
            catch (err) {
                console.error('error: ', err);
                setIsDisabledProcessToStart(true);
            }
            finally {
                setIsLoading(false);
            }
        }
    }, [
        filterRequestForSearch,
        filteredProcessDisplayMeta,
        handleFetchSearchItems,
        isAllItemsSelected,
        metaDefinitionId,
        selectedIds
    ]);
    const debouncedOnChangeSelectedIds = useDebouncedCallback(handleChangeSelectedIds, SEARCH_DELAY);
    const closeQuickStartFiltersDrawer = useCallback(() => {
        setProcessConditionQuery(undefined);
    }, []);
    useEffect(() => {
        debouncedOnChangeSelectedIds();
    }, [
        handleChangeSelectedIds,
        debouncedOnChangeSelectedIds,
        filteredProcessDisplayMeta,
        handleFetchSearchItems,
        metaDefinitionId
    ]);
    if (!filteredProcessDisplayMeta.length) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        filteredProcessDisplayMeta.length === 1 ? (React.createElement(Button, { loading: isLoadingProcesses || isLoading, disabled: isDisabledProcessToStart, key: filteredProcessDisplayMeta[0].definitionId, className: `${styles.button} fw-600 me-2 actionButton`, onClick: () => {
                setSelectedProcessDefinitionId(filteredProcessDisplayMeta[0].definitionId);
            } },
            React.createElement(React.Fragment, null, isDisabledProcessToStart ? (React.createElement(ItemProcessDisabled, { title: React.createElement("span", { className: cn(styles.disabledTitle, 'text-ellipsis text-nowrap') }, filteredProcessDisplayMeta[0].title), onShowDisabledReason: () => {
                    const process = filteredProcessDisplayMeta[0];
                    const processRunEntitiesItem = process.custom?.processRunFromEntities?.find(({ definitionId }) => definitionId === metaDefinitionId);
                    if (processRunEntitiesItem?.conditions) {
                        setProcessConditionQuery(processRunEntitiesItem?.conditions);
                    }
                } })) : (React.createElement(TooltipTruncate, null,
                React.createElement(React.Fragment, null, filteredProcessDisplayMeta[0].title)))))) : (React.createElement(DisableAntAnimationProvider, null,
            React.createElement(DropdownMenu, { className: cn(styles.menu, { ['is-open']: !!dropdownMenuItems.length }), searchPlaceholder: t('RELATED_PROCESS_SELECTOR_SEARCH_PLACEHOLDER'), menuItems: dropdownMenuItems, onMenuItemClick: setSelectedProcessDefinitionId, onMenuClick: handleClickDropDownButton },
                React.createElement(Button, { loading: isLoadingProcesses || isLoading, className: `${styles.button} fw-600 me-2 actionButton` },
                    React.createElement("span", { className: "d-inline d-md-none" },
                        React.createElement(VscDebugStart, { size: "1.5em" })),
                    React.createElement("span", { className: "d-none d-md-flex" },
                        `${t('RELATED_PROCESS_SELECTOR_LABEL')}`,
                        React.createElement(DownOutlined, { className: "ps-2 align-items-end" })))))),
        !!selectedProcessDefinitionId && !!displayDataMap[selectedProcessDefinitionId] && (React.createElement(RelatedProcessesSelectorModal, { processMetaDefinitionId: selectedProcessDefinitionId, processMetaDefinition: displayDataMap[selectedProcessDefinitionId], entityMetaDefinitionId: metaDefinitionId, selectedItemIds: selectedIds, onClose: handleModalClose, maxSelectedItems: RELATED_PROCESS_SELECTOR_MAX_SELECTED_ITEMS })),
        React.createElement(PropertiesProvider, null,
            React.createElement(Drawer, { title: t('RELATED_PROCESS_SELECTOR_CONDITIONS_TITLE'), onClose: closeQuickStartFiltersDrawer, open: !!processConditionQuery },
                React.createElement(React.Fragment, null,
                    React.createElement(DrawerContent, null,
                        React.createElement(DrawerContentBlock, null,
                            React.createElement(ConditionQueryField, { disabled: true, definitionId: metaDefinitionId, value: processConditionQuery }))),
                    React.createElement(DrawerFooter, null,
                        React.createElement(DrawerFormFooter, { submitText: t('COMMON__MODAL_CLOSE'), onSubmit: closeQuickStartFiltersDrawer })))))));
};
