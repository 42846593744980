import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { INITIAL_PAGE_SIZE } from 'config';
import { flatten } from 'lodash';
import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import queryClient, { oneHourInMs, oneMinuteInMs } from '../../../../../../common/utils/react-query-client';
import { RELEASE_NOTES_DEFINITION_ID, VERSION_PROPERTY_ID } from '../../../../../../releaseNotes/constants';
import { CMS_API_PATH } from '../../../../../common/utils/constants';
import { REFERENCE_DEFINITION_ID_PROPERTY_ID } from '../../../../../constants';
import { useHandleSearchItems } from '../../../../../hooks/useSearchItems';
export const fetchDefinitionHistory = (metaDefinitionId, page, pageSize) => ({
    type: 'ENTITY_DEFINITION_HISTORY__FETCH',
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${metaDefinitionId}/history`,
            params: {
                offset: (page - 1) * pageSize,
                limit: pageSize
            },
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
const DEFINITION_HISTORY_QUERY_KEY = 'definition-history';
export const invalidateDefinitionHistoryQuery = async (definitionId) => {
    await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(DEFINITION_HISTORY_QUERY_KEY) && query.queryKey.includes(definitionId)
    });
};
export const useDefinitionHistory = ({ definitionId }) => {
    const { handleFetch: handleFetchReleaseNotes } = useHandleSearchItems();
    const dispatchFetchDefinitionHistory = useDispatch();
    const { data, isFetchingNextPage, fetchNextPage, hasNextPage, isLoading, isError } = useInfiniteQuery([DEFINITION_HISTORY_QUERY_KEY, definitionId], async ({ pageParam = 1 }) => {
        const history = await dispatchFetchDefinitionHistory(fetchDefinitionHistory(definitionId, pageParam, INITIAL_PAGE_SIZE));
        const releaseNotes = await handleFetchReleaseNotes(RELEASE_NOTES_DEFINITION_ID, {
            query: {
                condition: ConditionType.and,
                filters: [
                    {
                        condition: ConditionType.or,
                        filters: [
                            ...history.map((item) => ({
                                field: VERSION_PROPERTY_ID,
                                operator: FilterOperatorTypes.equals,
                                data: item.modifiedAt
                            }))
                        ]
                    },
                    {
                        field: REFERENCE_DEFINITION_ID_PROPERTY_ID,
                        operator: FilterOperatorTypes.is,
                        data: definitionId
                    }
                ]
            },
            responseFields: [VERSION_PROPERTY_ID],
            limit: history.length
        });
        return history.map((item) => ({
            ...item,
            releaseNote: releaseNotes?.results.find((releaseNote) => releaseNote.version === item.modifiedAt)
        }));
    }, {
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.length < INITIAL_PAGE_SIZE) {
                return;
            }
            return flatten(allPages).length / INITIAL_PAGE_SIZE + 1;
        },
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs
    });
    const allData = useMemo(() => flatten(data?.pages), [data?.pages]);
    const historyMap = useMemo(() => {
        return (allData.reduce((acc, item) => {
            return {
                ...acc,
                [item.modifiedAt]: item
            };
        }, {}) || {});
    }, [allData]);
    return {
        data: allData,
        historyMap,
        isError,
        isLoading,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage
    };
};
