import { PropertyTypes } from '@hypercharge/portal-utils';
import { truncate } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { TooltipTruncate } from '../../../../../../common/components/TooltipTruncate';
import { getItemRepresentation } from '../../../../utils';
import { getDisplayComponentForType } from '../../../item-property/utils';
import ItemLink from '../../../item/ItemLink/ItemLink';
import ItemQuickPreview from './ItemQuickPreview';
const PropertyPreview = ({ item, property }) => {
    const [newItemPreview, setNewItemPreview] = useState();
    const onCloseNewItemPreview = useCallback(() => {
        setNewItemPreview(undefined);
    }, []);
    if (!item) {
        return React.createElement(React.Fragment, null, " - ");
    }
    const value = item[property.propertyId];
    if (value == null) {
        return null;
    }
    if (property.type === PropertyTypes.file) {
        const LinkField = getDisplayComponentForType('link');
        const links = Array.isArray(value) ? value : [value];
        return (React.createElement("div", { className: "property-preview" }, links.map((url) => (React.createElement("div", { key: url, className: "cp-i-flex" },
            React.createElement("span", null, " - "),
            React.createElement(LinkField, { value: {
                    link: url,
                    text: truncate(url, { length: 40 })
                } }))))));
    }
    if (property.type === PropertyTypes.entity) {
        const items = Array.isArray(value) ? value : [value];
        return (React.createElement("div", { className: "property-preview" },
            items.map((item, index) => {
                if (typeof item === 'string') {
                    return (React.createElement(ItemLink, { key: item, entityId: item, definitionId: property.meta.definitionId }));
                }
                else if ('entityId' in item) {
                    const itemName = getItemRepresentation(item) ?? ' - ';
                    return (React.createElement(Link, { key: index, to: "#", title: itemName, onClick: () => {
                            setNewItemPreview(item);
                        } },
                        React.createElement(TooltipTruncate, null, itemName)));
                }
                return null;
            }),
            React.createElement(ItemQuickPreview, { definitionId: property.meta.definitionId, entity: newItemPreview, open: !!newItemPreview, onClose: onCloseNewItemPreview })));
    }
    const DisplayComp = getDisplayComponentForType(property.type);
    return (React.createElement("div", { className: "property-preview" },
        React.createElement(DisplayComp, { value: value, meta: property.meta })));
};
export default PropertyPreview;
