import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { getTranslationForDescription } from '@hypercharge/hyper-react-base/lib/i18n/i18nUtils';
import React, { useMemo } from 'react';
import { PropertyLabel } from '../../../../../common/components/PropertyLabel';
import { HeaderActionsButton } from '../../../../../common/components/data-table/TableHeaderActionsButton';
import { RequiredFieldIcon } from '../../../../../workflows/processes/components/process/RequiredFieldInfoPopover';
import { isColumnMeta } from '../utils';
export const GetColumnHeader = ({ header }) => {
    const { language } = useI18n();
    const { column: { columnDef: { meta: columnDefMeta } } } = header;
    if (!columnDefMeta) {
        throw new Error('Column meta is missing');
    }
    if (!isColumnMeta(columnDefMeta)) {
        throw new Error('Column meta is invalid');
    }
    const { labels, descriptions, type, meta, showQuickFilter = true, hideColumn = true, showGroupedBy = true, rowGrouping, onChangeRowGrouping, onRemoveColumns, required = false, isUnfilledRequiredField = false } = columnDefMeta.headerProps;
    const label = useMemo(() => getTranslation(labels, language), [labels, language]);
    const labelDescription = useMemo(() => getTranslationForDescription(descriptions, language), [descriptions, language]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PropertyLabel, { label: label, labelDescription: labelDescription }),
        required && React.createElement(RequiredFieldIcon, { invalid: isUnfilledRequiredField }),
        React.createElement(HeaderActionsButton, { type: type, rowGrouping: rowGrouping, onChangeRowGrouping: onChangeRowGrouping, header: header, onRemoveColumns: onRemoveColumns, showQuickFilter: showQuickFilter, hideColumn: hideColumn, showGroupedBy: showGroupedBy, meta: meta })));
};
