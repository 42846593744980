import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { replace as routeReplace } from '@hypercharge/hyper-react-base/lib/router';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import { getDisplayComponentForType, getEditorComponentForType } from '../../../cms/data/components/item-property/utils';
import { invalidateItemsQuery } from '../../../cms/hooks/invalidateCache';
import { useEntityItem } from '../../../cms/hooks/useEntityItem';
import { InlineEditor } from '../../../common/components/InlineEditor';
import ErrorFocus from '../../../common/components/formik/ErrorFocus';
import FormikField from '../../../common/components/formik/FormikField';
import { APPS_PATH, SETTINGS_PATH } from '../../constants';
import styles from './AppInstancePage.module.scss';
import { saveInstanceConfig } from './actions';
import { HYPER_APPS_DEFINITION_ID } from './constants';
import { getConfigurationFormFor, getFormInitialValuesFor, getFormSchemaFor } from './utils';
const AppInstancePage = ({ setLinks, setCurrentBreadcrumbText, match: { params: { appId, appInstanceId }, url } }) => {
    const { t } = useI18n();
    const notificationDispatch = useDispatch();
    const routerReplaceDispatch = useDispatch();
    const { data: app, isFetching: isPendingApp } = useEntityItem(HYPER_APPS_DEFINITION_ID, appId, ['*']);
    const ConfigurationForm = getConfigurationFormFor(appId);
    const formSchema = getFormSchemaFor(appId);
    const initialValue = useMemo(() => {
        return getFormInitialValuesFor(appId);
    }, [appId]);
    const { data: instanceById, isFetching: isPendingAppInstance } = useEntityItem(appId, appInstanceId, ['*'], appInstanceId != 'new');
    const appInstance = useMemo(() => {
        if (appInstanceId != 'new') {
            const config = formSchema(t).cast(instanceById || initialValue, {
                stripUnknown: true
            });
            return config;
        }
        return initialValue;
    }, [appInstanceId, formSchema, initialValue, instanceById, t]);
    const saveInstanceConfigDispatch = useDispatch();
    const saveAppInstance = useCallback(async (instanceId, newConfig, onSuccess) => {
        try {
            const configToSave = formSchema(t).cast(newConfig, { stripUnknown: true });
            const result = await saveInstanceConfigDispatch(saveInstanceConfig(appId, instanceId, configToSave));
            await invalidateItemsQuery({
                definitionId: appId,
                ids: [instanceId],
                waitNewData: true
            });
            if (instanceId == 'new') {
                const newUrl = url.replace('/new', `/${result.entityId}`);
                void routerReplaceDispatch(routeReplace(newUrl));
            }
            void notificationDispatch(success({
                title: t('SUCCESS'),
                message: t('COMMON__UPDATE_SUCCESS')
            }));
            onSuccess();
        }
        catch (err) {
            console.error(err);
            void notificationDispatch(error({
                title: t('ERROR'),
                message: t('COMMON__UPDATE_FAILED')
            }));
        }
    }, [
        formSchema,
        t,
        saveInstanceConfigDispatch,
        appId,
        notificationDispatch,
        url,
        routerReplaceDispatch
    ]);
    useEffect(() => {
        if (app) {
            const links = [
                { text: t('SETTINGS'), to: SETTINGS_PATH },
                { text: t('APPS'), to: `${SETTINGS_PATH}${APPS_PATH}` },
                {
                    text: app.title,
                    to: `${SETTINGS_PATH}${APPS_PATH}/${app.entityId}`
                }
            ];
            setLinks(links);
            setCurrentBreadcrumbText(appInstance?.title || '...');
        }
    }, [app, appInstance, setCurrentBreadcrumbText, setLinks, t]);
    const onSubmit = useCallback(async (values, submitProps) => {
        if (appInstance) {
            await saveAppInstance(appInstanceId, values, () => {
                submitProps.resetForm({ values });
            });
        }
    }, [appInstance, appInstanceId, saveAppInstance]);
    const errorDispatch = useCallback((content) => notificationDispatch(error(content)), [notificationDispatch]);
    return (React.createElement(React.Fragment, null, appInstance && !isPendingApp && !isPendingAppInstance ? (React.createElement(Formik, { initialValues: appInstance, onSubmit: onSubmit, validateOnBlur: true, validateOnChange: true, enableReinitialize: true, validationSchema: formSchema && formSchema(t) }, ({ handleSubmit, resetForm, isSubmitting, dirty, values, isValid, setFieldValue }) => {
        const notification = () => {
            errorDispatch({
                title: t('COMMON__FAILURE'),
                message: t('CORRECT_INVALID_FIELDS')
            });
        };
        return (React.createElement("form", { noValidate: true, onSubmit: isValid ? handleSubmit : notification },
            React.createElement("div", { className: `${styles.wrapper} editable-item-header justify-content-between` },
                React.createElement(FormikField, { name: "title", className: "title-inline-editor can-edit pe-5", component: InlineEditor, actionsPosition: "bottom", displayComponent: getDisplayComponentForType('text'), editorComponent: getEditorComponentForType('text'), hideActions: false, canEdit: true, extraDisplayComponentProps: { asField: false }, onSave: (title) => setFieldValue('title', title) }),
                appInstance && dirty && (React.createElement(Button, { onClick: () => resetForm(), type: "button", className: "me-2 ms-auto", inversed: true }, t('FORM__RESET'))),
                React.createElement(Button, { disabled: !dirty, loading: isSubmitting, onClick: () => {
                        if (isValid) {
                            handleSubmit();
                        }
                        else {
                            notification();
                        }
                    }, type: "button" }, t(appInstance ? 'FORM__SAVE' : 'APPS_ADD_BUTTON'))),
            ConfigurationForm && (React.createElement("div", { className: "p-4 with-background" },
                React.createElement(ConfigurationForm, { instance: values, disabled: isSubmitting, setFieldValue: setFieldValue }))),
            React.createElement(Prompt, { when: dirty && !isSubmitting, message: t('UNSAVED_DATA_CONFIRM') }),
            React.createElement(ErrorFocus, null)));
    })) : (React.createElement(LoadingRectangles, null))));
};
export default AppInstancePage;
