import { WORKFLOWS_API_PATH } from '../common/utils/constants';
// ////////////////////
// DisplayProcessMeta list
// ////////////////////
export const fetchDisplayProcessMetaList = (languageCode) => ({
    type: 'WORKFLOWS__FETCH_DISPLAY_PROCESS_META_LIST',
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process/display`,
            method: 'GET',
            params: { languageCode }
        }
    }
});
// ////////////////////
// Start process
// ////////////////////
export const startProcess = (processMetaId, processRunDataFromFilter) => ({
    type: 'WORKFLOWS__START_PROCESS',
    payload: { processMetaId, processRunDataFromFilter },
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process/${processMetaId}/run`,
            method: 'POST',
            allowPromiseReject: true
        }
    }
});
