import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { uniq } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isWorkflowDefinition } from '../../../common/utils/url';
import { TASK_PROCESS_LINK_PROPERTY_ID, TASK_PROCESS_META_ID_PROPERTY_ID, TASK_PROCESS_RUN_ID_PROPERTY_ID } from '../../../workflows/common/utils/constants';
import { searchTasks } from '../../../workflows/tasks/actions';
import { ENTITY_ID_PROPERTY_ID } from '../../common/utils/constants';
import useDisplayItemMeta from '../../hooks/useDisplayItemMeta';
import { searchItems } from '../actions';
const responseTasksFields = [
    ENTITY_ID_PROPERTY_ID,
    TASK_PROCESS_META_ID_PROPERTY_ID,
    TASK_PROCESS_RUN_ID_PROPERTY_ID,
    TASK_PROCESS_LINK_PROPERTY_ID
];
const useBackForwardItem = ({ definitionId, entityId }) => {
    const dispatch = useDispatch();
    const { language } = useI18n();
    const location = useLocation();
    const entityIds = location.state?.data?.entityIds;
    const definitionIds = location.state?.data?.definitionIds;
    const links = uniq(location.state?.data?.links);
    const totalCount = location.state?.data.totalCount;
    const filterRequest = location.state?.filterRequest;
    const stateDefinitionId = location.state?.definitionId;
    const isTaskDefinition = location.state?.isTaskDefinition;
    const offset = filterRequest?.offset ?? 0;
    const limit = filterRequest?.limit ?? entityIds?.length ?? 5;
    const currentPoint = useMemo(() => {
        if (isTaskDefinition) {
            const currentPointByPathName = links?.indexOf(location.pathname);
            if (currentPointByPathName !== -1) {
                return currentPointByPathName;
            }
        }
        const currentPointByEntityId = entityIds?.indexOf(entityId);
        if (currentPointByEntityId !== -1) {
            return currentPointByEntityId;
        }
        return -1;
    }, [entityId, entityIds, isTaskDefinition, links, location.pathname]);
    const pickedDefinitionId = stateDefinitionId ?? definitionId;
    const { data: displayItemMeta } = useDisplayItemMeta({ definitionId });
    const updateItems = useCallback(async (filterQuery, direction) => {
        const newOffset = direction === 'back' ? (offset - limit >= 0 ? offset - limit : 0) : offset + limit;
        let data;
        // TODO migrate to useHandleSearchItems
        if (isWorkflowDefinition(displayItemMeta) && isTaskDefinition) {
            data = await dispatch(searchTasks({
                ...filterQuery,
                responseFields: [...(filterQuery.fullTextFields ?? []), ...responseTasksFields],
                offset: newOffset,
                languageCode: language,
                limit
            }));
        }
        else {
            data = await dispatch(searchItems(pickedDefinitionId, {
                ...filterQuery,
                offset: newOffset,
                languageCode: language,
                limit
            }));
        }
        const newItem = direction === 'back' ? data.results?.[data.results.length - 1] : data.results?.[0];
        return {
            entityId: newItem.taskProcessRunId ?? newItem.entityId,
            definitionId: newItem.taskProcessMetaId ?? newItem.definitionId,
            link: newItem?.link,
            state: {
                data: {
                    entityIds: data.results?.map((item) => item.taskProcessRunId ?? item.entityId),
                    definitionIds: data.results?.map((item) => item.taskProcessMetaId ?? item.definitionId),
                    links: data.results?.map((item) => item?.taskProcessLink?.link),
                    totalCount: data.totalCount
                },
                filterRequest: { ...filterQuery, offset: newOffset },
                definitionId: stateDefinitionId ?? definitionId,
                isTaskDefinition
            }
        };
    }, [
        offset,
        limit,
        displayItemMeta,
        isTaskDefinition,
        stateDefinitionId,
        definitionId,
        dispatch,
        language,
        pickedDefinitionId
    ]);
    const handleBack = useCallback(async () => {
        const backEntityId = entityIds?.[currentPoint - 1];
        const backDefinitionId = definitionIds?.[currentPoint - 1];
        const backLink = links?.[currentPoint - 1];
        const back = isTaskDefinition ? backLink : backEntityId;
        if (!back) {
            const updatedItems = await updateItems(filterRequest, 'back');
            return {
                definitionId: updatedItems.definitionId,
                entityId: updatedItems.entityId,
                link: updatedItems.link,
                state: updatedItems.state
            };
        }
        return {
            definitionId: backDefinitionId,
            entityId: backEntityId,
            link: backLink,
            state: location.state
        };
    }, [
        currentPoint,
        definitionIds,
        entityIds,
        filterRequest,
        isTaskDefinition,
        links,
        location.state,
        updateItems
    ]);
    const handleForward = useCallback(async () => {
        const forwardEntityId = entityIds?.[currentPoint + 1];
        const forwardDefinitionId = definitionIds?.[currentPoint + 1];
        const forwardLink = links?.[currentPoint + 1];
        const forward = isTaskDefinition ? forwardLink : forwardEntityId;
        if (!forward) {
            const updatedItems = await updateItems(filterRequest, 'forward');
            return {
                definitionId: updatedItems.definitionId,
                entityId: updatedItems.entityId,
                link: updatedItems.link,
                state: updatedItems.state
            };
        }
        return {
            definitionId: forwardDefinitionId,
            entityId: forwardEntityId,
            link: forwardLink,
            state: location.state
        };
    }, [
        currentPoint,
        definitionIds,
        entityIds,
        filterRequest,
        isTaskDefinition,
        links,
        location.state,
        updateItems
    ]);
    return {
        isBackDisabled: !entityIds?.length || currentPoint === -1 || (currentPoint === 0 && offset === 0),
        isForwardDisabled: !entityIds?.length ||
            currentPoint === -1 ||
            (currentPoint === entityIds?.length - 1 && offset + limit >= totalCount),
        totalCount,
        numberIndex: offset + currentPoint + 1,
        handleBack,
        handleForward
    };
};
export default useBackForwardItem;
