import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import NotFound from '@hypercharge/hyper-react-base/lib/common/not-found';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { EMAIL_TEMPLATES_DEFINITION_ID } from '@hypercharge/portal-utils';
import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { Breadcrumbs } from '../../../../cms/common/breadcrumbs';
import { useEntityItem } from '../../../../cms/hooks/useEntityItem';
import HeightContext, { HeightContextProvider } from '../../../../common/components/height-context';
import { oneMinuteInMs } from '../../../../common/utils/react-query-client';
import { Content, LoadingOverlay } from '../../../../common/utils/stylingUtils';
import { MARKETING_PATH } from '../../../common/utils/constants';
import { listCrossClientsPreview } from '../../actions';
import { EMAIL_TEMPLATES_PATH } from '../../constants';
import PreviewResultContainer from './PreviewResultContainer';
const otherElementSelectors = ['#header', '#featured-entities', '#breadcrumbs'];
const otherHeightInPx = 188;
const TemplateCrossClientsPreview = ({ templateId, language }) => {
    const { t } = useI18n();
    const { url } = useRouteMatch();
    const dispatch = useDispatch();
    const dispatchListPreview = useDispatch();
    const [_, setBreadcrumbsContainer] = useState();
    const { data: template, isError } = useEntityItem(EMAIL_TEMPLATES_DEFINITION_ID, templateId, ['title', 'image', 'configuration']);
    const { data: listPreview, isLoading: isFirstListLoading, isError: isListError } = useQuery(['listPreview', templateId, language], () => {
        return dispatchListPreview(listCrossClientsPreview(templateId, language));
    }, {
        staleTime: oneMinuteInMs,
        cacheTime: oneMinuteInMs
    });
    const breadcrumbs = useMemo(() => {
        return [
            { text: t('TEMPLATES_NAV_LINK'), to: MARKETING_PATH },
            {
                text: t('EMAILS_NAV_LINK'),
                to: `${MARKETING_PATH}${EMAIL_TEMPLATES_PATH}`
            },
            {
                text: template?.title || templateId,
                to: `${MARKETING_PATH}${EMAIL_TEMPLATES_PATH}/${templateId}?language=${language}`
            }
        ];
    }, [language, t, template?.title, templateId]);
    const onChangeActivePreview = useCallback((id) => {
        dispatch(push(`${url}/${id}`));
    }, [dispatch, url]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: setBreadcrumbsContainer },
            React.createElement(Breadcrumbs, { links: breadcrumbs, currentBreadcrumb: t('CROSS_CLIENT_PREVIEW_HISTORY') })),
        isError || isListError ? (React.createElement(NotFound, null)) : (React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: otherHeightInPx },
            React.createElement(HeightContext.Consumer, null, ({ contentHeight }) => !listPreview || listPreview.results.length == 0 ? (React.createElement(Content, { className: "position-relative", height: contentHeight },
                React.createElement(LoadingOverlay, null, isFirstListLoading ? React.createElement(LoadingRectangles, null) : t('DATA_TABLE__NO_RESULTS')))) : (React.createElement(Switch, null,
                React.createElement(Route, { path: `${url}/:previewId` },
                    React.createElement(PreviewResultContainer, { contentHeight: contentHeight, onChangeActivePreview: onChangeActivePreview, listPreview: listPreview.results, templateId: templateId, language: language })),
                React.createElement(Route, { path: `${url}` },
                    React.createElement(Redirect, { to: `${url}/${listPreview.results[0].id}` })))))))));
};
export default TemplateCrossClientsPreview;
