import { isEmpty } from 'lodash';
export const normalizeRichTextValue = (value) => {
    if (value?.ops?.length && value.ops.some(isEmpty)) {
        const newValue = { ...value, ops: value.ops.filter((row) => !isEmpty(row)) };
        if (newValue.ops.length === 0) {
            return null;
        }
        return newValue;
    }
    return value;
};
