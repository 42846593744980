import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import ActivityContainer from '../../../../common/activity';
import styles from './ActivityWidget.module.scss';
const ActivityWidget = ({ className, settings }) => {
    const { t, language } = useI18n();
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''}` },
        React.createElement("div", { className: "widget-title drag-handler" }, getTranslation(settings.title, language, t('ACTIVITY'))),
        React.createElement(ActivityContainer, { className: "widget-body", extendedFilter: true, noActivityMessage: React.createElement(React.Fragment, null,
                React.createElement("img", { src: "/assets/images/no-activity.svg", className: "m-0 mx-auto no-activity-img", alt: "no-activity-img" }),
                React.createElement("div", { className: "mt-4 px-4 text-center no-activity-txt fw-600" }, t('ACTIVITY_NO_ACTIVITIES'))) })));
};
export default ActivityWidget;
