import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useState } from 'react';
import { ComputedInputInline } from '../../../../common/components/ComputedExpressionEditor';
import { usePropertiesTree } from '../../../../common/components/ComputedExpressionEditor/usePropertiesTree';
import Toggle from '../../../../common/components/Toggle';
import { ComputedExpressionsDocumentationLink } from '../../../../common/components/documentation-link';
import styles from './ComputedExpressionField.module.scss';
const ComputedExpressionField = ({ className, value, onChange, input, meta, disabled, metaDefinitionId, error }) => {
    value = input ? input.value : value;
    error = meta ? meta.error : error;
    onChange = input ? input.onChange : onChange;
    const propertiesTree = usePropertiesTree(metaDefinitionId);
    const { t } = useI18n();
    const [showExpressionInput, setShowExpressionInput] = useState(!!value);
    const onChangeToggle = useCallback((checked) => {
        setShowExpressionInput(checked);
        if (!checked) {
            onChange && onChange(null);
        }
    }, [onChange]);
    const onChangeComputedExpression = useCallback((val) => {
        onChange && onChange(val);
    }, [onChange]);
    return (React.createElement("div", { className: className },
        React.createElement(ErrorBoundary, null,
            React.createElement(Toggle, { className: "pb-3", label: `${t('PROPERTY_COMPUTED')} ⚡️`, disabled: disabled ?? false, onChange: onChangeToggle, checked: showExpressionInput }),
            showExpressionInput && (React.createElement(React.Fragment, null,
                React.createElement(ErrorBoundary, null,
                    React.createElement("div", { className: `${styles.wrapper} ${error ? 'warning' : ''}` },
                        React.createElement("label", { className: "input-label" },
                            React.createElement("span", { className: "me-1" }, t('PROPERTY_COMPUTED_EXPRESSION')),
                            React.createElement(ComputedExpressionsDocumentationLink, null)),
                        React.createElement(ComputedInputInline, { value: value, 
                            // @ts-expect-error
                            onChange: onChangeComputedExpression, propertiesTree: propertiesTree }),
                        !!error && React.createElement("div", { className: "input-warning" }, error))))))));
};
export default ComputedExpressionField;
