import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import { flatten, isString, omit } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import EntityEditor from '../../../cms/data/components/item-property/editors/EntityEditor';
import { useHandleSearchItems } from '../../../cms/hooks/useSearchItems';
import { isComputedExpression } from '../../../utils/computedExpression';
import { initialFilterRequest } from '../../../views/components/ViewContext';
import { ComputedInput } from '../ComputedExpressionEditor';
import { MAX_CMS_ITEMS_LIMIT } from '../entity-selectors';
import BaseFilter from './BasicFilter';
import { buildFilter, copyRemainingTags, getFilterData, parseTags } from './utils';
const availableOperators = [
    { value: FilterOperatorTypes.empty },
    { value: FilterOperatorTypes.notEmpty },
    { value: FilterOperatorTypes.isIn },
    { value: FilterOperatorTypes.isNotIn }
];
const availableOperatorsNonList = [
    ...availableOperators,
    { value: FilterOperatorTypes.is },
    { value: FilterOperatorTypes.isNot }
];
const availableOperatorsForList = [
    ...availableOperators,
    { value: FilterOperatorTypes.contains },
    { value: FilterOperatorTypes.notContains }
];
const EntityFilter = ({ className, filter, onChange, meta, disabled, allowComputed, propertiesTree, autoFocus = false, unavailableOperators, availableCountForFilters, clearRemoveComputedExpression, disableAddOption = false }) => {
    const notificationDispatch = useDispatch();
    const { t } = useI18n();
    const data = useMemo(() => getFilterData(filter), [filter]);
    const onChangeInput = useCallback((value) => {
        if (!isComputedExpression(value) &&
            Array.isArray(value) &&
            'data' in filter &&
            Array.isArray(filter.data) &&
            availableCountForFilters < value.length - filter.data.length) {
            notificationDispatch(error({
                title: t('LIMIT_COUNT_FOR_FILTERS_TITLE'),
                message: t('LIMIT_COUNT_FOR_FILTER_VALUE')
            }));
            return;
        }
        const newFilter = buildFilter(filter.field, filter.operator, value);
        onChange(newFilter);
    }, [availableCountForFilters, filter, notificationDispatch, onChange, t]);
    const operators = 'list' in meta && meta.list ? availableOperatorsForList : availableOperatorsNonList;
    const { handleFetch } = useHandleSearchItems();
    const handlePaste = useCallback((e) => {
        if (filter.operator === FilterOperatorTypes.isIn ||
            filter.operator === FilterOperatorTypes.isNotIn) {
            e.preventDefault();
            const pastedText = e.clipboardData.getData('text');
            const newTags = parseTags(pastedText);
            const fullTags = newTags.slice(0, availableCountForFilters);
            const remainingTags = newTags.slice(availableCountForFilters);
            void copyRemainingTags(remainingTags, notificationDispatch, t, !availableCountForFilters);
            onChangeInput([...flatten([data] || []).filter(isString), ...fullTags]);
            if (!('definitionId' in meta)) {
                throw new Error('No definitionId in meta');
            }
            void handleFetch(meta.definitionId, {
                ...initialFilterRequest,
                limit: MAX_CMS_ITEMS_LIMIT,
                ids: {
                    operator: FilterOperatorTypes.in,
                    data: fullTags.slice(0, availableCountForFilters)
                }
            });
            if (fullTags.length > MAX_CMS_ITEMS_LIMIT) {
                void handleFetch(meta.definitionId, {
                    ...initialFilterRequest,
                    limit: MAX_CMS_ITEMS_LIMIT,
                    ids: {
                        operator: FilterOperatorTypes.in,
                        data: fullTags.slice(availableCountForFilters)
                    }
                });
            }
        }
    }, [availableCountForFilters, handleFetch, meta, notificationDispatch, onChangeInput, t]);
    return (React.createElement(BaseFilter, { onChange: onChange, operators: operators, unavailableOperators: unavailableOperators, filter: filter, className: className, disabled: disabled }, allowComputed || isComputedExpression(data) ? (React.createElement(ComputedInput, { component: EntityEditor, value: data, onChange: onChangeInput, meta: filter.operator &&
            [FilterOperatorTypes.isNotIn, FilterOperatorTypes.isIn].includes(filter.operator)
            ? { ...meta, list: true }
            : omit(meta, 'list'), handlePaste: handlePaste, disabled: disabled, autoFocus: autoFocus, simpleClear: true, propertiesTree: propertiesTree, disableWrapperHover: true, editing: false, clearRemoveComputedExpression: clearRemoveComputedExpression, withoutCreateNew: disableAddOption })) : (React.createElement(EntityEditor, { value: data, onChange: onChangeInput, 
        // @ts-expect-error
        meta: filter.operator &&
            [FilterOperatorTypes.isNotIn, FilterOperatorTypes.isIn].includes(filter.operator)
            ? { ...meta, list: true }
            : omit(meta, 'list'), handlePaste: handlePaste, disabled: disabled, autoFocus: autoFocus, simpleClear: true, withMaxTagCount: false, withoutCreateNew: disableAddOption }))));
};
export default EntityFilter;
