import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import cn from 'classnames';
import { get, without } from 'lodash';
import React, { useCallback } from 'react';
import { SortableContainer as getSortableContainer, SortableElement as getSortableElement } from 'react-sortable-hoc';
import { getPropertyTypeComponent } from '../../../workflows/meta/components/meta-definition-form/utils';
import ExtendableSortableItem from './ExtendableSortableItem';
import styles from './SortablePropertyList.module.scss';
export const SortableItem = getSortableElement(({ position, item, iconComponent, label, sectionTitle, isDisabled, selection, handleChange, idDisabledSort, removeItem, className, itemComponent: Item = ExtendableSortableItem }) => {
    const handleRemove = useCallback(() => {
        removeItem(item);
    }, [removeItem, item]);
    return (React.createElement(Item, { position: position, item: item, label: label, iconComponent: iconComponent, sectionTitle: sectionTitle, isDisabled: isDisabled, idDisabledSort: idDisabledSort, selection: selection, handleChange: handleChange, handleRemove: handleRemove, className: className }));
});
const SortableList = getSortableContainer(({ disabled, sectionTitleByPropertyId, displayDataMap, selection, propertyIdPath, handleChange, prioritySort, itemComponent, disabledItems, className }) => {
    const { language } = useI18n();
    const removeItem = useCallback((item) => {
        handleChange(without(selection, item));
    }, [handleChange, selection]);
    return (React.createElement("ul", { className: cn(styles.wrapper, 'p-0 m-0 overflow-hidden') }, selection
        .filter((item) => {
        // Filter out propertyIds that were added in the past but for which the property was removed in the meantime
        const propertyId = (propertyIdPath ? get(item, propertyIdPath) : item);
        return propertyId in displayDataMap;
    })
        .map((item, index) => {
        const propertyId = (propertyIdPath ? get(item, propertyIdPath) : item);
        const displayData = displayDataMap[propertyId];
        const label = getTranslation(get(displayData, 'labels'), language);
        const sectionTitle = getTranslation(sectionTitleByPropertyId[propertyId], language);
        const iconComponent = displayData != null ? getPropertyTypeComponent(displayData.type) : undefined;
        const disabledItem = Boolean(disabledItems?.find((disabledItem) => disabledItem === item));
        const isPrioritySort = prioritySort?.find((item) => item === propertyId) != null;
        return (React.createElement(SortableItem, { key: propertyId, index: index, position: index, item: item, label: label, idDisabledSort: isPrioritySort, disabled: isPrioritySort, sectionTitle: sectionTitle, iconComponent: iconComponent, isDisabled: disabledItem || disabled, selection: selection, handleChange: handleChange, removeItem: removeItem, itemComponent: itemComponent, className: className }));
    })));
});
export default SortableList;
