import { DeleteConfirmationButton } from '@hypercharge/hyper-react-base/lib/common/table';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Collapse, Popover, Tooltip } from 'antd';
import cn from 'classnames';
import { isEmpty, omit } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { CgInfo } from 'react-icons/cg';
import { FaRegStar, FaRegTrashAlt, FaStar } from 'react-icons/fa';
import { IoMdAlert } from 'react-icons/io';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { RiArrowUpDownLine } from 'react-icons/ri';
import { TbPin, TbPinnedOff } from 'react-icons/tb';
import { normalizeRichTextValue } from '~app/cms/data/components/item-property/editors/RichTextEditor';
import RichTextDisplay from '../../../../cms/data/components/item-property/display/RichTextDisplay';
import { TooltipTruncate } from '../../../../common/components/TooltipTruncate';
import styles from './MetaPropertyListItem.module.scss';
import PropertySettingsPanel from './PropertySettingsPanel';
import { getPropertyTypeComponent } from './utils';
const MetaPropertyListItem = ({ className, disabled, value, values, initialValues, onChange, isActive, onActiveChange, canDelete = true, onClickDelete, canMove = true, onClickMove, parentViewGroups, metaDefinitionId, dragHandler, error }) => {
    const { t, language } = useI18n();
    const isSection = 'properties' in value;
    const isTab = 'sections' in value;
    const propertyType = isSection ? 'section' : isTab ? 'tab' : value.type;
    const isRequiredProperty = 'meta' in value ? !!value.meta.validations?.required : false;
    const isComputedProperty = 'computedValue' in value ? !!value.computedValue : false;
    const isHiddenProperty = 'meta' in value ? !!value.meta.hidden : false;
    const isHighlightedProperty = 'meta' in value ? !!value.meta.highlighted : false;
    const isPinnedProperty = 'pinned' in value ? !!value.pinned : false;
    const id = 'propertyId' in value ? value.propertyId : value.id;
    const title = useMemo(() => {
        return getTranslation('titles' in value ? value.titles : value.labels, language);
    }, [language, value]);
    const headerTitle = useMemo(() => {
        const titleText = title || t('NO_TITLE');
        if (isComputedProperty) {
            return (React.createElement(React.Fragment, null,
                title,
                React.createElement(Tooltip, { title: t('PROPERTY_COMPUTED') }, "\u26A1\uFE0F")));
        }
        return titleText;
    }, [t, isComputedProperty, title]);
    const propertyDescription = useMemo(() => {
        if (!('propertyId' in value)) {
            return null;
        }
        for (const tab of initialValues.tabs) {
            for (const section of tab.sections) {
                const propertyWithInternalDescription = section.properties.find((prop) => prop.propertyId === value.propertyId && prop.internalDescription);
                if (propertyWithInternalDescription) {
                    return propertyWithInternalDescription.internalDescription;
                }
            }
        }
        return null;
    }, [initialValues.tabs, value]);
    const onToggleHideItem = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isSection && !isTab) {
            onChange({
                ...value,
                meta: {
                    ...value.meta,
                    hidden: !value.meta.hidden
                }
            });
        }
    }, [isSection, isTab, onChange, value]);
    const onToggleHighlightedItem = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isSection && !isTab) {
            onChange({
                ...value,
                meta: {
                    ...value.meta,
                    highlighted: !value.meta.highlighted
                }
            });
        }
    }, [isSection, isTab, onChange, value]);
    const onTogglePinSection = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isSection && !isTab) {
            onChange({
                ...value,
                pinned: !value.pinned
            });
        }
    }, [isSection, isTab, onChange, value]);
    const _onClickMove = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        onClickMove();
    }, [onClickMove]);
    const onChangePropertySettings = useCallback((value) => {
        onChange(value);
    }, [onChange]);
    const onCollapseChanged = useCallback((key) => {
        if (!key || !key.length) {
            onActiveChange('');
        }
        else {
            onActiveChange(id);
        }
    }, [id, onActiveChange]);
    return (React.createElement("div", { className: `${className || ''} ${styles.listItem} ${isSection ? 'is-section-item' : isTab ? 'is-tab-item' : 'is-property-item'}` },
        React.createElement(Collapse, { activeKey: isActive ? 'activePanel' : undefined, bordered: false, onChange: onCollapseChanged, destroyInactivePanel: true, items: [
                {
                    key: 'activePanel',
                    label: (React.createElement(Header, { className: isSection ? 'is-section' : isTab ? 'is-tab' : 'is-property', canDelete: canDelete, canMove: canMove, type: propertyType, title: headerTitle, propertyDescription: propertyDescription, subtitle: id, isNew: value.isNew, isSystem: !!value.system, hasError: !isEmpty(omit(error, ['sections', 'properties'])), disabled: disabled, onClickDelete: onClickDelete, onClickMove: _onClickMove, toggleHideItem: onToggleHideItem, toggleHighlightedItem: onToggleHighlightedItem, togglePinSection: onTogglePinSection, isRequiredProperty: isRequiredProperty, isPinnedProperty: isPinnedProperty, isHiddenProperty: isHiddenProperty, isHighlightedProperty: isHighlightedProperty, dragHandler: dragHandler })),
                    children: (React.createElement(PropertySettingsPanel, { value: value, values: values, onChange: onChangePropertySettings, disabled: !!disabled, parentViewGroups: parentViewGroups, metaDefinitionId: metaDefinitionId, error: error }))
                }
            ] })));
};
export default MetaPropertyListItem;
//
// Utils
//
const DeleteConfirmStyle = {
    marginLeft: '0.5rem'
};
const iconStyle = { width: '1rem', height: '1rem' };
const hiddenPropertyIconStyle = { ...iconStyle, opacity: '0.5' };
const MOUSE_EVENT_DELAY = 0.5;
const Header = ({ className, type, title, subtitle, hasError, isRequiredProperty, isHiddenProperty, isHighlightedProperty, isPinnedProperty, disabled, isNew, isSystem, canDelete, canMove, onClickDelete, onClickMove, toggleHideItem, togglePinSection, toggleHighlightedItem, dragHandler, propertyDescription }) => {
    const { t } = useI18n();
    const propertyDescriptionButtonJsx = useMemo(() => {
        if (type === 'tab' || type === 'section' || !propertyDescription) {
            return;
        }
        const normalizedPropertyDescription = normalizeRichTextValue(propertyDescription);
        if (!normalizedPropertyDescription) {
            return null;
        }
        return (React.createElement(Popover, { title: title, mouseEnterDelay: MOUSE_EVENT_DELAY, mouseLeaveDelay: MOUSE_EVENT_DELAY, content: React.createElement(RichTextDisplay, { value: normalizedPropertyDescription, asField: false }), rootClassName: styles.popover },
            React.createElement(CgInfo, { size: 24, className: cn({
                    disabled,
                    'pointer-events-none': disabled
                }) })));
    }, [disabled, propertyDescription, title, type]);
    const highlightedButtonJsx = useMemo(() => {
        if (type === 'tab' || type === 'section') {
            return;
        }
        const tooltipText = isHiddenProperty
            ? t('DEFINITION_FORM__CAN_NOT_HIGHLIGHT_HIDDEN_PROPERTY_TOOLTIP')
            : disabled
                ? ''
                : t(isHighlightedProperty
                    ? 'DEFINITION_FORM__HIGHLIGHTED_FIELD_TOOLTIP'
                    : 'DEFINITION_FORM__NOT_HIGHLIGHTED_FIELD_TOOLTIP');
        return (React.createElement(Tooltip, { placement: "topRight", title: tooltipText }, isHighlightedProperty ? (React.createElement(FaStar, { size: 22, className: `${isHiddenProperty || disabled ? 'disabled' : ''}`, onClick: !(isHiddenProperty || disabled) ? toggleHighlightedItem : undefined })) : (React.createElement(FaRegStar, { size: 22, className: `${isHiddenProperty || disabled ? 'disabled' : ''}`, onClick: !(isHiddenProperty || disabled) ? toggleHighlightedItem : undefined }))));
    }, [disabled, isHiddenProperty, isHighlightedProperty, t, toggleHighlightedItem, type]);
    const deleteButtonJsx = useMemo(() => {
        if (disabled) {
            return React.createElement(FaRegTrashAlt, { className: "disabled", size: 24 });
        }
        if (type === 'tab') {
            if (!canDelete) {
                return React.createElement(FaRegTrashAlt, { className: "disabled", size: 24 });
            }
        }
        else if (type === 'section') {
            if (!canDelete) {
                return React.createElement(FaRegTrashAlt, { className: "disabled", size: 24 });
            }
            if (isSystem) {
                return (React.createElement(Tooltip, { placement: "topRight", title: t('DEFINITION_FORM__CAN_NOT_DELETE_SYSTEM_SECTION') },
                    React.createElement(FaRegTrashAlt, { className: "disabled", size: 24 })));
            }
        }
        else {
            // Property
            // Regardless of anything, new properties can be deleted
            if (!canDelete && !isNew) {
                return React.createElement(FaRegTrashAlt, { className: "disabled", size: 24 });
            }
        }
        if (isSystem) {
            return (React.createElement(Tooltip, { placement: "topRight", title: t('DEFINITION_FORM__CAN_NOT_DELETE_SYSTEM_PROPERTY') },
                React.createElement(FaRegTrashAlt, { className: "disabled", size: 24 })));
        }
        return (React.createElement(DeleteConfirmationButton, { confirmStyle: DeleteConfirmStyle, onClick: onClickDelete, originalStyle: {
                minWidth: 'auto',
                padding: 0
            } }));
    }, [disabled, type, canDelete, isNew, isSystem, onClickDelete, t]);
    const hideButtonJsx = useMemo(() => {
        if (type === 'tab' || type === 'section') {
            return null;
        }
        // Saved fields: Can be hidden if they are not a system field
        if (disabled) {
            return (React.createElement(React.Fragment, null, isHiddenProperty ? (React.createElement(IoEyeOff, { className: "disabled", size: 24 })) : (React.createElement(IoEye, { className: "disabled", size: 24 }))));
        }
        if (isSystem) {
            return (React.createElement(Tooltip, { placement: "topRight", title: t('DEFINITION_FORM__CAN_NOT_HIDE_SYSTEM_PROPERTY') },
                React.createElement(IoEye, { className: "disabled", size: 24 })));
        }
        return (React.createElement(Tooltip, { placement: "topRight", title: t(isHiddenProperty
                ? 'DEFINITION_FORM__HIDDEN_FIELD_TOOLTIP'
                : 'DEFINITION_FORM__DISPLAYED_FIELD_TOOLTIP') }, isHiddenProperty ? (React.createElement(IoEyeOff, { size: 24, onClick: toggleHideItem })) : (React.createElement(IoEye, { size: 24, onClick: toggleHideItem }))));
    }, [toggleHideItem, isHiddenProperty, isSystem, disabled, t, type]);
    const Icon = getPropertyTypeComponent(type);
    const additionalTitle = useMemo(() => {
        if (type === 'tab') {
            return React.createElement("span", { className: "item-type" },
                " | ",
                t('DEFINITION_FORM__TAB_LABEL'));
        }
        if (type === 'section') {
            return React.createElement("span", { className: "item-type" },
                " | ",
                t('DEFINITION_FORM__SECTION_LABEL'));
        }
    }, [t, type]);
    return (React.createElement("div", { className: cn(styles.headerTitle, 'd-flex flex-row align-items-center flex-truncate', className) },
        React.createElement("div", { className: "d-flex flex-row flex-grow-1 align-items-center flex-truncate" },
            React.createElement("div", { className: "title-outer d-flex flex-row flex-grow-1 align-items-center" },
                Icon && React.createElement(Icon, { style: isHiddenProperty ? hiddenPropertyIconStyle : iconStyle }),
                React.createElement(TooltipTruncate, { className: cn(`title-container`, {
                        'has-icon': Icon,
                        'is-hidden-property': isHiddenProperty
                    }) },
                    title,
                    additionalTitle),
                isRequiredProperty && (React.createElement("span", { className: "ms-1 required-info" },
                    " (",
                    t('PROPERTY_REQUIRED'),
                    ")"))),
            React.createElement(TooltipTruncate, { className: "subtitle" }, subtitle)),
        hasError && React.createElement(IoMdAlert, { size: 24, className: "ms-2 alertIcon" }),
        React.createElement("div", { className: "buttons-outer d-flex justify-content-end ms-2 gap-2" },
            type === 'section' && (React.createElement("div", { className: disabled ? 'disabled' : '', tabIndex: 0, role: "button", onClick: togglePinSection }, isPinnedProperty ? React.createElement(TbPin, { size: 22 }) : React.createElement(TbPinnedOff, { size: 22 }))),
            propertyDescriptionButtonJsx,
            highlightedButtonJsx,
            deleteButtonJsx,
            !isNew && hideButtonJsx,
            React.createElement(RiArrowUpDownLine, { className: `${disabled || !canMove ? 'disabled' : ''}`, size: 22, onClick: disabled || !canMove ? undefined : onClickMove }),
            dragHandler)));
};
