import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { CMS_API_PATH } from '../common/utils/constants';
import { invalidateDefinitionQuery } from './invalidateCache';
const updateMetaDefinition = (definition) => ({
    type: 'ENTITY_META_DEFINITION__UPDATE',
    payload: definition,
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${definition.definitionId}`,
            method: 'PUT',
            allowPromiseReject: true
        }
    }
});
const useUpdateDefinition = () => {
    const updateDefinitionDispatch = useDispatch();
    return useMutation({
        mutationFn: async (definition) => {
            return updateDefinitionDispatch(updateMetaDefinition(definition));
        },
        onSettled: async (result, _error, _definition) => {
            if (result) {
                await invalidateDefinitionQuery(result.definitionId);
            }
        }
    });
};
export default useUpdateDefinition;
