import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { FaEye, FaEyeSlash, FaMinusCircle, FaTrash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { hasTenantPermission } from '../../../../../auth';
import { useSystemSectionsVisibility } from '../../../../../cms/common/context/SystemSectionsVisibilityProvider';
import BackForwardButton from '../../../../../cms/data/components/item/BackForwardButton';
import ConfirmationButtonModal from '../../../../../common/components/ConfirmationButtonModal';
import { APP_ADMIN_PERMISSION, PROCESS_CANCELLATION_PERMISSION } from '../../../../../common/utils/constants';
import { ReleaseNoteHistory } from '../../../../../releaseNotes/components/ReleaseNoteHistory';
import styles from './ProcessActionsButton.module.scss';
const ProcessActionsButton = ({ className, processDetails, processRunId, definitionId, isProcessPending, onCancelProcess, onDeleteProcess }) => {
    const { t } = useI18n();
    const { isSystemSectionsVisible, flipIsSystemSectionsVisible, hiddenSections } = useSystemSectionsVisibility();
    const canCancelFromState = useSelector((s) => hasTenantPermission(s, PROCESS_CANCELLATION_PERMISSION));
    const isWorkflowAdmin = useSelector((s) => hasTenantPermission(s, APP_ADMIN_PERMISSION));
    const { canCancel, canDelete } = useMemo(() => {
        const canCancel = (isWorkflowAdmin || canCancelFromState) &&
            !!processDetails &&
            !['CANCELLED', 'SUCCEEDED', 'FAILED'].includes(processDetails.status);
        const canDelete = processDetails?.canDelete || false;
        return {
            canCancel,
            canDelete: isWorkflowAdmin || canDelete
        };
    }, [canCancelFromState, isWorkflowAdmin, processDetails]);
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''}` },
        React.createElement("div", { className: "separate-buttons d-flex flex-wrap gap-2 align-items-center" },
            definitionId && React.createElement(BackForwardButton, { entityId: processRunId, definitionId: definitionId }),
            processDetails && (React.createElement(ReleaseNoteHistory, { definitionId: definitionId, createdAt: processDetails.initiatedAt })),
            !!hiddenSections.length && (React.createElement(Tooltip, { title: isSystemSectionsVisible
                    ? t('CMS_HIDE_SYSTEM_INFORMATION')
                    : t('CMS_SHOW_SYSTEM_INFORMATION') },
                React.createElement(Button, { type: "text", size: "large", onClick: flipIsSystemSectionsVisible, className: "action-button", icon: isSystemSectionsVisible ? React.createElement(FaEye, { size: 16 }) : React.createElement(FaEyeSlash, { size: 16 }) }))),
            canCancel && (React.createElement(ConfirmationButtonModal, { className: "action-button", disabled: isProcessPending, onClick: onCancelProcess, tooltip: t('WORKFLOWS__CANCEL_PROCESS'), title: t('WORKFLOWS__CANCEL_PROCESS'), message: t('WORKFLOWS__CANCEL_PROCESS_CONFIRMATION'), okButtonProps: {
                    danger: true
                } },
                React.createElement(FaMinusCircle, { size: 16 }))),
            canDelete && (React.createElement(ConfirmationButtonModal, { className: "action-button", danger: true, disabled: isProcessPending, onClick: onDeleteProcess, tooltip: t('WORKFLOWS__PROCESS_DELETE'), title: t('WORKFLOWS__PROCESS_DELETE'), message: t('WORKFLOWS__PROCESS_DELETE_CONFIRMATION'), warningMessage: t('DELETE_WARNING_CONCERNING_REFERRALS'), confirmButtonText: t('COMMON__DELETE'), cancelButtonText: t('COMMON_CANCEL') },
                React.createElement(FaTrash, { size: 16 }))))));
};
export default ProcessActionsButton;
