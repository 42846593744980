import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Pagination } from 'antd';
import Select from 'antd/lib/select';
import cn from 'classnames';
import { PAGE_SIZES } from 'config';
import React, { useCallback, useMemo } from 'react';
import { MdChevronLeft, MdChevronRight, MdExpandMore } from 'react-icons/md';
import styles from './TablePagination.module.scss';
const TablePagination = ({ className = '', totalCount, pageSize, page, onPageChange, onPageSizeChange }) => {
    const { t } = useI18n();
    const paginationSelectValues = useMemo(() => PAGE_SIZES.map((size) => ({
        value: size,
        label: `${size} / ${t('PAGINATION_PAGE').toLowerCase()}`
    })), [t]);
    const showTotal = useCallback((total, range) => {
        return `${range[0]}-${range[1]} ${t('PAGINATION', {
            count: totalCount
        })}`;
    }, [t, totalCount]);
    return (React.createElement("div", { className: cn(styles.wrapper, 'pt-2 d-flex flex-row justify-content-center justify-content-sm-end align-items-center', className) },
        React.createElement(Pagination, { total: Math.min(totalCount, 9510), current: page, pageSize: pageSize, onChange: onPageChange, showLessItems: true, showTotal: showTotal, nextIcon: React.createElement(MdChevronRight, { size: 24 }), prevIcon: React.createElement(MdChevronLeft, { size: 24 }), showSizeChanger: false }),
        onPageSizeChange ? (React.createElement(Select, { options: paginationSelectValues, popupClassName: styles.selector, value: pageSize, suffixIcon: React.createElement(MdExpandMore, { color: "var(--neutralColor-6)" }), onChange: onPageSizeChange })) : null));
};
export default TablePagination;
