import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { formatDateTime, ReadableDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { Tooltip } from 'antd';
import React from 'react';
import { LoadingBar } from '../..';
import { FEATURED_ENTITIES_PATH } from '../../../../../../../cms';
import { TooltipTruncate } from '../../../../../../../common/components/TooltipTruncate';
import { CONTACT_CMS_DEFINITION_ID, ContactDisplayName } from '../../../../../../../crm';
const ProcessStartedStepDescription = ({ processDetails }) => {
    const { t } = useI18n();
    return processDetails ? (React.createElement(TooltipTruncate, { className: "d-flex align-items-center" },
        React.createElement("span", { className: "me-1" }, t('BY')),
        React.createElement("span", { className: "me-1 d-block" },
            React.createElement(ContactDisplayName, { contactId: processDetails?.initiatedBy, link: [
                    FEATURED_ENTITIES_PATH,
                    CONTACT_CMS_DEFINITION_ID,
                    processDetails.initiatedBy
                ].join('/') })),
        React.createElement(Tooltip, { title: formatDateTime(processDetails.initiatedAt) },
            React.createElement(TooltipTruncate, { title: formatDateTime(processDetails.initiatedAt) },
                React.createElement(ReadableDateTime, { dateTime: processDetails.initiatedAt }))))) : (React.createElement(LoadingBar, null));
};
export default ProcessStartedStepDescription;
