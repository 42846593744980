import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback } from 'react';
import { FieldLabel } from '../../../../../cms/data/components/item-property/FieldElements';
import { RichTextEditorWithWrapper } from '../../../../../cms/data/components/item-property/editors/RichTextEditor';
import { MultiLanguageInput } from '../../../../../common/components/MultiLanguageField';
const DescriptionsTab = ({ disabled, value, onChange }) => {
    const { t } = useI18n();
    const onChangeDescriptions = useCallback((val) => {
        onChange({ ...value, descriptions: val });
    }, [onChange, value]);
    const onChangeValue = useCallback((val) => {
        onChange(val);
    }, [onChange]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-column w-100 mb-3" },
            React.createElement(FieldLabel, { className: "ps-0", label: t('PROPERTY_EXTERNAL_DESCRIPTION') }),
            React.createElement(MultiLanguageInput, { fieldComponent: RichTextEditorWithWrapper, disabled: disabled, value: value.descriptions, onChange: onChangeDescriptions, autoFocus: false, extraFieldComponentProps: {
                    label: t('PROPERTY_EXTERNAL_DESCRIPTION')
                } })),
        React.createElement("div", { className: "flex flex-column w-100" },
            React.createElement(FieldLabel, { label: t('PROPERTY_INTERNAL_DESCRIPTION') }),
            React.createElement(RichTextEditorWithWrapper, { withoutModalOpening: false, label: t('PROPERTY_INTERNAL_DESCRIPTION'), value: value.internalDescription, onChange: (newValue) => {
                    onChangeValue({ ...value, internalDescription: newValue });
                }, autoFocus: false, meta: {}, disabled: disabled }))));
};
export default DescriptionsTab;
