import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { Skeleton, TextPlaceholder } from '@hypercharge/hyper-react-base/lib/common/skeletons';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications/notificationActions';
import { Input } from 'antd';
import { filter, times } from 'lodash';
import React, { useEffect, useState } from 'react';
import { GoGear, GoLink, GoSearch } from 'react-icons/go';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { invalidateItemsQuery } from '../../../cms/hooks/invalidateCache';
import { useSearchItems } from '../../../cms/hooks/useSearchItems';
import { Content } from '../../../common/utils/stylingUtils';
import { SETTINGS_PATH } from '../../constants';
import { StyledSectionCard, styledSectionCardHeaderStyle } from '../common/stylingUtils';
import IntegrationLogoContainer from './IntegrationLogoContainer';
import IntegrationModule from './IntegrationModule';
import IntegrationModules from './IntegrationModules';
import { disableIntegrationById, enableIntegrationById, saveIntegrationConfig } from './actions';
import { HYPER_INTEGRATION, HYPER_INTEGRATION_CATEGORY, HYPER_INTEGRATION_MODULE } from './constants';
import { getDisableActionFor, getEnableActionFor } from './utils';
const IntegrationList = ({ integrations, basepath, disableIntegration }) => {
    const history = useHistory();
    const { t } = useI18n();
    return integrations.length > 0 ? (React.createElement(ListContainer, null, integrations.map((integration, index) => (React.createElement(Integration, { key: index, integration: integration, add: () => history.push(`${basepath}/${integration.entityId}`), disable: () => disableIntegration(integration.entityId) }))))) : (React.createElement("div", { style: { textAlign: 'center' } },
        React.createElement("span", { className: "cp-c-align-center-center" }, t('INTEGRATIONS_FOUND_NOTHING'))));
};
const Integrations = ({ contentHeight, setLinks, setCurrentBreadcrumbText }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const toggleIntegrationDispatch = useDispatch();
    const saveConfigDispatch = useDispatch();
    const fetchIntegrationsDispatch = useDispatch();
    const fetchIntegrationCategoriesDispatch = useDispatch();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const { data: { results: integrations } = {}, isPending: isPendingIntegration } = useSearchItems({
        definitionId: HYPER_INTEGRATION,
        filterRequest: {
            responseFields: [
                'title',
                'categories',
                'enabled',
                'description',
                'logo',
                'websiteUrl',
                'configuration'
            ],
            limit: 100
        }
    });
    const { data: { results: categories } = {}, isPending: isPendingIntegrationCategory } = useSearchItems({
        definitionId: HYPER_INTEGRATION_CATEGORY,
        filterRequest: {
            limit: 100
        }
    });
    const match = useRouteMatch();
    useEffect(() => {
        setLinks([{ text: t('SETTINGS'), to: SETTINGS_PATH }]);
        setCurrentBreadcrumbText(t('INTEGRATIONS'));
    }, [
        dispatch,
        fetchIntegrationCategoriesDispatch,
        fetchIntegrationsDispatch,
        setCurrentBreadcrumbText,
        setLinks,
        t
    ]);
    const saveConfig = async (integrationId, newConfig) => {
        await saveConfigDispatch(saveIntegrationConfig(integrationId, newConfig));
    };
    const toggleIntegration = async (integrationId, enable, newConfig) => {
        try {
            const customAction = enable
                ? getEnableActionFor(integrationId)
                : getDisableActionFor(integrationId);
            if (customAction) {
                await dispatch(customAction(newConfig));
            }
            else {
                await toggleIntegrationDispatch(enable ? enableIntegrationById(integrationId) : disableIntegrationById(integrationId));
                if (enable) {
                    await saveConfig(integrationId, newConfig);
                }
            }
            await invalidateItemsQuery({
                definitionId: HYPER_INTEGRATION,
                ids: [integrationId],
                waitNewData: true
            });
            dispatch(success({
                title: t('SUCCESS'),
                message: t('INTEGRATIONS_SUCCESS_UPDATE')
            }));
        }
        catch (e) {
            const message = 
            // @ts-expect-error
            (e.response && e.response.data && e.response.data.message) ||
                t('INTEGRATIONS_FAILED_UPDATE');
            dispatch(error({
                title: t('ERROR'),
                message: message
            }));
        }
    };
    const enableIntegration = (integrationId, newConfig) => toggleIntegration(integrationId, true, newConfig);
    const disableIntegration = (integrationId) => {
        return toggleIntegration(integrationId, false);
    };
    function filterByCategories(integration) {
        if (selectedCategory === null) {
            return true;
        }
        else if (!integration.categories) {
            return false;
        }
        else {
            return filter(integration.categories, { entityId: selectedCategory });
        }
    }
    function filterBySearchQuery(integration) {
        return integration.title?.toLowerCase().includes(searchQuery.toLowerCase());
    }
    function changeSearchQuery(event) {
        setSearchQuery(event.target.value);
    }
    return (React.createElement(StyledSectionCard, { withoutPadding: true, withoutMargin: true, title: "Integrations", headerStyle: styledSectionCardHeaderStyle, loading: isPendingIntegrationCategory || isPendingIntegration },
        React.createElement(Content, { height: contentHeight },
            React.createElement("div", { className: "p-3" },
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: `${match.path}/:integrationId/:moduleId`, render: ({ match }) => React.createElement(IntegrationModule, { moduleId: match.params.moduleId }) }),
                    React.createElement(Route, { exact: true, path: `${match.path}/:integrationId`, render: ({ match }) => {
                            const integration = integrations?.find(({ entityId }) => entityId === match.params.integrationId);
                            if (!integration) {
                                return 'Integration not found';
                            }
                            return (React.createElement(IntegrationModules, { integration: integration, enableIntegration: enableIntegration }));
                        } }),
                    React.createElement(Route, { exact: true, path: `${match.path}`, render: () => (React.createElement("div", { className: "cp-c-row box cp-c-wrap" },
                            React.createElement("div", { className: "cp-c-column cp-i-20", style: {
                                    borderRight: '1px solid var(--grayscaleColor-5)',
                                    paddingRight: 10
                                } },
                                React.createElement(CategoryListHeader, null,
                                    t('INTEGRATIONS_CATEGORIES'),
                                    ":"),
                                isPendingIntegration || isPendingIntegrationCategory ? (React.createElement(Skeleton, { isLoading: true },
                                    React.createElement(CategoriesPlaceholder, null, times(4, (index) => (React.createElement(TextPlaceholder, { key: index, heightInPx: 16 })))))) : (React.createElement(CategoryList, null,
                                    React.createElement(CategoryListItem, { selected: selectedCategory === null, title: t('INTEGRATIONS_SHOW_ALL'), onClick: () => setSelectedCategory(null) }),
                                    categories?.map((category, index) => (React.createElement(CategoryListItem, { selected: selectedCategory === category.entityId, key: index, title: category.title, onClick: () => setSelectedCategory(category.entityId) })))))),
                            React.createElement("div", { className: "cp-i-flex" },
                                React.createElement("div", { className: "cp-c-col" },
                                    React.createElement("div", { className: "cp-i-noshrink cp-c-column cp-c-align-start-center cp-c-padding-1", style: { borderBottom: '1px solid var(--grayscaleColor-5)' } },
                                        React.createElement(Title, null, t('INTEGRATIONS_TITLE')),
                                        React.createElement(Subtitle, null, t('INTEGRATIONS_SUBTITLE')),
                                        React.createElement(Input, { className: "w-75 my-3 px-3 integrationsSearch", value: searchQuery, type: "text", placeholder: t('INTEGRATIONS_SEARCH'), onChange: changeSearchQuery, suffix: React.createElement(GoSearch, null) })),
                                    React.createElement("div", { className: "cp-i-flex", style: { margin: '20px' } }, isPendingIntegrationCategory || isPendingIntegration ? (React.createElement(IntegrationListSkeleton, null)) : (React.createElement(IntegrationList, { basepath: match.path, disableIntegration: disableIntegration, integrations: (integrations || [])
                                            ?.filter(filterByCategories)
                                            .filter(filterBySearchQuery) }))))))) }),
                    React.createElement(Redirect, { to: match.path }))))));
};
export const Integration = ({ integration, add, disable, disabled = false }) => {
    const { t } = useI18n();
    return (React.createElement(Card, null,
        React.createElement(Logo, null, integration.logo && React.createElement(IntegrationLogoContainer, { logoFileId: integration.logo[0] })),
        React.createElement(Info, null,
            React.createElement(CardTitle, null, integration.definitionId === HYPER_INTEGRATION_MODULE
                ? t(integration.entityId)
                : integration.title),
            integration.enabled ? (React.createElement(React.Fragment, null,
                React.createElement(Button, { className: "mt-3", onClick: () => add(integration), style: { width: 'fit-content' }, disabled: disabled },
                    React.createElement(GoGear, { style: { marginRight: 5 } }),
                    t('SETTINGS')),
                React.createElement(Button, { className: "mt-2", onClick: () => disable(integration), style: { width: 'fit-content' }, disabled: disabled }, "Disable"))) : (React.createElement(Button, { className: "mt-3", onClick: () => add(integration), style: { width: 'fit-content' }, disabled: disabled }, t('ADD'))),
            React.createElement(Description, null, integration.definitionId === HYPER_INTEGRATION
                ? t(integration.entityId)
                : integration.description),
            integration.websiteUrl && (React.createElement(WebsiteLink, { href: integration.websiteUrl, target: "_blank" },
                React.createElement(GoLink, { style: { marginRight: '5px' } }),
                React.createElement("span", null, "Visit website"))))));
};
const CategoryListItem = ({ title, onClick, selected }) => {
    return (React.createElement("li", null,
        React.createElement(Category, { style: { fontWeight: selected ? 'bold' : undefined }, onClick: onClick }, title)));
};
export function IntegrationListSkeleton() {
    return (React.createElement(Skeleton, { isLoading: true },
        React.createElement(ListContainer, null, times(4, (idx) => (React.createElement(TextPlaceholder, { key: idx, heightInPx: 170, radiusInPx: 4 }))))));
}
const WebsiteLink = styled.a `
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: var(--grayscaleColor-8) !important;
  margin-top: 13px;
`;
const Description = styled.span `
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--grayscaleColor-8);
  margin-top: 12px;
  text-align: left;
`;
const CardTitle = styled.span `
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--grayscaleColor-8);
`;
const Category = styled.a `
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  color: var(--grayscaleColor-8);
`;
const CategoryListHeader = styled.span `
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: var(--grayscaleColor-8);
  margin-bottom: 10px;
`;
const CategoriesPlaceholder = styled.div `
  display: grid;
  grid-gap: 10px;
`;
const CategoryList = styled.ul `
  text-decoration: none;
  list-style-type: none;
  padding-inline-start: 0;
`;
export const Title = styled.p `
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  color: var(--grayscaleColor-8);
  margin: 10px 0 0;
`;
export const Subtitle = styled.p `
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: var(--grayscaleColor-8);
  width: 50%;
  text-align: center;
  margin: 15px 0 0;
`;
export const ListContainer = styled.div `
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
`;
const Logo = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34%;
  padding: 20px;
`;
const Card = styled.div `
  display: flex;
  border: 1px solid var(--grayscaleColor-5);
  border-radius: 4px;
  padding: 0;
`;
const Info = styled.div `
  display: flex;
  flex-direction: column;
  width: 66%;
  border-left: 1px solid var(--grayscaleColor-5);
  padding: 20px;
`;
export default Integrations;
