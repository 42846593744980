import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { LOG_QUERIES_DEFINITION_ID } from '@hypercharge/portal-utils';
import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useSearchItems } from '~app/cms/hooks/useSearchItems';
import { Select } from '~app/common/components/Select';
import styles from './QueryItemsSelector.module.scss';
const QueryItemsSelector = ({ className, value, onSelected }) => {
    const { t } = useI18n();
    const { isPending, data: items } = useSearchItems({
        definitionId: LOG_QUERIES_DEFINITION_ID,
        filterRequest: {
            limit: 500,
            responseFields: ['title', 'configuration']
        }
    });
    const options = useMemo(() => {
        if (!items?.results.length) {
            return [];
        }
        return items.results.map(({ entityId, title }) => {
            return {
                value: entityId,
                key: entityId,
                label: React.createElement("span", { className: "text-truncate" }, title)
            };
        });
    }, [items?.results]);
    const onSelect = useCallback((value) => {
        const selectedQueryItem = items?.results.find((item) => item.entityId === value);
        if (selectedQueryItem) {
            onSelected(selectedQueryItem);
        }
    }, [items?.results, onSelected]);
    if (isPending) {
        return null;
    }
    return (React.createElement(Select, { className: cn(styles.wrapper, 'ant-select-transparent', className), size: "large", popupClassName: "antd-dropdown-popup-styled2", value: value, options: options, onSelect: onSelect, placeholder: t('LOGS_QUERIES') }));
};
export default QueryItemsSelector;
