import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { push as routerPush } from '@hypercharge/hyper-react-base/lib/router';
import { Wrapper } from '@hypercharge/hyper-react-base/lib/styling';
import { Divider } from 'antd';
import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import * as Yup from 'yup';
import { AntInput } from '../../../../../common/components/AntInput';
import { ClipboardCopyButton, ClipboardPasteButton } from '../../../../../common/components/ClipboardButtons';
import { ColorCustomization } from '../../../../../common/components/Color/ColorCustomization';
import { MultiLanguageInput, MultiLanguageValidationSchema } from '../../../../../common/components/MultiLanguageField';
import Toggle from '../../../../../common/components/Toggle';
import FormikField from '../../../../../common/components/formik/FormikField';
import { FieldsContainer, FieldsContainerTitle } from '../../../../../common/utils/formUtils';
import GroupsField from '../../../../../groups';
import { ADMINS_PERMISSION_GROUP, AvailableSystemGroups, OWNER_GROUP_PLACEHOLDER } from '../../../../../groups/constants';
import { CMS_PATH } from '../../../../common/utils/constants';
import EntityActions from '../entity-actions/EntityActions';
import styles from './ItemMetaForm.module.scss';
const PERMISSION_GROUPS = [
    AvailableSystemGroups.viewGroups,
    AvailableSystemGroups.createGroups,
    AvailableSystemGroups.deleteGroups
];
const ALLOWED_ROW_PERMISSIONS = ['view'];
const CLIPBOARD_PROPERTIES = ['titles', 'custom', `itemMetaGroups`, ...PERMISSION_GROUPS];
const validationSchema = Yup.object({
    titles: MultiLanguageValidationSchema,
    itemMetaGroups: Yup.object({
        viewGroups: Yup.array(Yup.string()).min(1).required(),
        createGroups: Yup.array(Yup.string()).default([]),
        deleteGroups: Yup.array(Yup.string()).default([]),
        permissions: Yup.object({
            view: Yup.object()
        })
    }),
    custom: Yup.object({
        style: Yup.object({
            icon: Yup.object({
                name: Yup.string(),
                backgroundColor: Yup.string().matches(/^#.+$/).length(7),
                color: Yup.string().matches(/^#.+$/).length(7)
            })
        })
    })
});
const ItemMetaForm = ({ definition, extraActionButtons, isSystem, onSubmit }) => {
    const { t } = useI18n();
    const isNew = !definition.definitionId;
    const dispatch = useDispatch();
    const initialValues = useMemo(() => {
        return mapDefinitionToFormValues(definition);
    }, [definition]);
    const isValidClipboard = useCallback((clipText) => {
        try {
            const clipValue = JSON.parse(clipText);
            if (clipValue && 'itemMetaGroups' in clipValue && 'titles' in clipValue) {
                return true;
            }
        }
        catch (error) {
            return false;
        }
        return false;
    }, []);
    const onSubmitForm = useCallback(async (values, actions) => {
        try {
            const newDefinition = mapFormValueToDefinition(values);
            const savedDefinition = await onSubmit(newDefinition);
            // Reset the form after successful submission
            actions.resetForm({ values: mapDefinitionToFormValues(savedDefinition) });
            return savedDefinition;
        }
        catch (error) {
            console.error('Error submitting form:', error);
        }
    }, [onSubmit]);
    const isPermissionToggleDisabled = (groupId, permission) => {
        return (groupId === ADMINS_PERMISSION_GROUP ||
            (groupId === OWNER_GROUP_PLACEHOLDER &&
                // TODO: Delete must be enabled when fixed https://trello.com/c/rEuiWDPm/1545-cms-owner-permission-cant-delete-record
                [AvailableSystemGroups.createGroups, AvailableSystemGroups.deleteGroups].some((p) => p === permission)));
    };
    return (React.createElement(Wrapper, { className: `${styles.wrapper} h-100` },
        React.createElement(Formik, { initialValues: initialValues, validateOnBlur: true, validateOnChange: false, validationSchema: validationSchema, onSubmit: onSubmitForm, enableReinitialize: true }, (formikProps) => {
            const { isSubmitting, dirty, values, resetForm, setValues, submitForm, validateForm } = formikProps;
            return (React.createElement("div", { className: "h-100" },
                React.createElement("div", { className: "form-buttons-container position-absolute top-0 end-0" },
                    React.createElement(React.Fragment, null,
                        React.createElement(ClipboardCopyButton, { value: values, inversed: true, clipboardProperties: CLIPBOARD_PROPERTIES }),
                        React.createElement(ClipboardPasteButton, { inversed: true, isValid: isValidClipboard, onClick: (clipText) => {
                                try {
                                    const clipValue = JSON.parse(clipText);
                                    setValues(clipValue);
                                }
                                catch (error) {
                                    console.error('Invalid JSON: ', error);
                                }
                            } }),
                        isNew ? (React.createElement(Button, { inversed: true, onClick: () => {
                                dispatch(routerPush(CMS_PATH));
                            }, disabled: isSubmitting }, t('FORM_CANCEL'))) : (dirty && (React.createElement(Button, { inversed: true, onClick: () => {
                                resetForm();
                            }, disabled: isSubmitting }, t('FORM__RESET')))),
                        React.createElement(EntityActions, { definitionId: definition.definitionId, save: submitForm, saving: isSubmitting, canSave: dirty, canDelete: !isNew && !isSystem, formIsValid: validateForm }),
                        extraActionButtons)),
                React.createElement("div", { className: "h-100 cp-c-row cp-c-wrap cp-c-padding-2" },
                    React.createElement("div", { className: "position-relative overflow-auto cp-i-100 cp-i-lg-50" },
                        React.createElement(FieldsContainerTitle, null, t('ENTITY_NAME')),
                        React.createElement(FieldsContainer, { className: "cp-c-padding-2" },
                            React.createElement(FormikField, { name: "titles", label: t('HYPER_BI_TITLES'), component: MultiLanguageInput, fieldComponent: AntInput })),
                        React.createElement(ColorCustomization, { submitting: isSubmitting, formik: true }),
                        React.createElement(FormikField, { component: Toggle, name: "custom.requiredReleaseNotes", label: t('REQUIRE_RELEASE_NOTES'), disabled: isSubmitting })),
                    React.createElement("div", { className: "position-relative overflow-auto cp-i-100 cp-i-lg-50 " },
                        React.createElement(Divider, null, t('PERMISSIONS')),
                        React.createElement(FormikField, { fast: true, name: "itemMetaGroups", component: GroupsField, names: PERMISSION_GROUPS, disabled: isSubmitting, withValidation: true, isPermissionToggleDisabled: isPermissionToggleDisabled, allowedRowPermissions: ALLOWED_ROW_PERMISSIONS, itemMetaDefinitionId: definition.definitionId }))),
                React.createElement(Prompt, { when: dirty && !isSubmitting, message: t('UNSAVED_DATA_CONFIRM') })));
        })));
};
const mapDefinitionToFormValues = (definition) => {
    return {
        titles: definition.titles,
        custom: definition.custom ?? {},
        itemMetaGroups: {
            viewGroups: definition.viewGroups ?? [],
            createGroups: definition.createGroups ?? [],
            deleteGroups: definition.deleteGroups ?? [],
            permissions: definition.permissions
        }
    };
};
const mapFormValueToDefinition = (values) => {
    if (!values) {
        return values;
    }
    const { itemMetaGroups, ...rest } = values;
    return {
        ...rest,
        ...itemMetaGroups
    };
};
export default ItemMetaForm;
