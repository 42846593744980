import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import { difference, find, flatten, forEach, get, isEmpty, keyBy, reduce, uniq } from 'lodash';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThisIsHiddenProperty } from '../../../common/components/ThisIsHiddenProperty/ThisIsHiddenProperty';
import { PropertyTypes } from '../../../common/types';
import { getPropertyTypeComponent } from '../../../workflows/meta/components/meta-definition-form/utils';
import { getFlattenedDisplayDataList } from '../../data/utils';
import { fetchEntitiesDisplayData } from '../components/withEntityDisplayData';
import { ENTITY_ID_PROPERTY_ID } from '../utils/constants';
import { getField } from '../utils/utils';
import styles from './PropertiesProvider.module.scss';
const DisplayDataMapContext = React.createContext(undefined);
const PropertiesProvider = ({ ...otherProps }) => {
    const dispatch = useDispatch();
    const [displayDataMaps, setDisplayDataMaps] = useState({});
    const [statusMap, setStatusMap] = useState({});
    const unmounted = useRef(false);
    const startFetchTimeout = useRef(null);
    const fetchEntitiesDisplayDataHandler = useCallback((definitionIds) => dispatch(fetchEntitiesDisplayData(definitionIds)), [dispatch]);
    useEffect(() => {
        return () => {
            // This is added because previously the useEffect below had an unmounted variable, but it was set to true when one of the dependencies changed, so the component was not truly unmounted
            // That prevented the setStatusMap to be executed
            unmounted.current = true;
        };
    }, []);
    const fetchDefinitions = useCallback(() => {
        const getDisplayDataMap = (rootDisplayDataList, nestedDisplayDataLists) => {
            // First construct the options for the properties of the CMS entity that we're filtering on
            let newDisplayDataMap = generateDisplayDataMap(rootDisplayDataList);
            // Get info of the properties of type entity
            const entityProperties = [];
            forEach(newDisplayDataMap, (displayData, propertyId) => {
                if (displayData.type === PropertyTypes.entity) {
                    const propertyDefinitionId = displayData?.meta?.definitionId;
                    if (propertyDefinitionId) {
                        entityProperties.push({ propertyId, propertyDefinitionId });
                    }
                }
            });
            // Use the fetched DisplayDataList objects to complete the propertyOptions by adding the nested properties
            forEach(entityProperties, ({ propertyId, propertyDefinitionId }) => {
                const displayDataList = find([...nestedDisplayDataLists, rootDisplayDataList], {
                    definitionId: propertyDefinitionId
                });
                if (displayDataList) {
                    newDisplayDataMap = {
                        ...newDisplayDataMap,
                        ...generateDisplayDataMap(displayDataList, propertyId)
                    };
                }
            });
            return newDisplayDataMap;
        };
        const getNestedDefinitionIds = (rootDisplayDataList) => {
            const nestedDefinitionIds = [];
            getFlattenedDisplayDataList(rootDisplayDataList).forEach((displayData) => {
                if (displayData.type === 'entity') {
                    const propertyDefinitionId = displayData?.meta?.definitionId;
                    if (propertyDefinitionId) {
                        nestedDefinitionIds.push(propertyDefinitionId);
                    }
                }
            });
            return nestedDefinitionIds;
        };
        const handleDisplayDataMapsProcessing = async (rootDefinitionIds) => {
            try {
                const rootDisplayDataLists = await fetchEntitiesDisplayDataHandler(rootDefinitionIds);
                const nestedDefinitionIds = uniq(flatten(rootDisplayDataLists.map(getNestedDefinitionIds)));
                let nestedDisplayDataLists = rootDisplayDataLists.filter(({ definitionId }) => nestedDefinitionIds.includes(definitionId));
                const unfetchedNestedDefinitionIds = difference(nestedDefinitionIds, rootDefinitionIds);
                if (!isEmpty(unfetchedNestedDefinitionIds)) {
                    const fetchedNestedDisplayDataLists = await fetchEntitiesDisplayDataHandler(unfetchedNestedDefinitionIds);
                    nestedDisplayDataLists = [...nestedDisplayDataLists, ...fetchedNestedDisplayDataLists];
                }
                if (!unmounted.current) {
                    setDisplayDataMaps((prevDisplayDataMaps) => reduce(rootDisplayDataLists, (result, rootDisplayDataList) => ({
                        ...result,
                        [rootDisplayDataList.definitionId]: getDisplayDataMap(rootDisplayDataList, nestedDisplayDataLists)
                    }), prevDisplayDataMaps));
                    setStatusMap((prevStatusMap) => reduce(rootDefinitionIds, (result, definitionId) => ({
                        ...result,
                        [definitionId]: { isAvailable: true, isPending: false, isFailed: false }
                    }), prevStatusMap));
                }
            }
            catch (e) {
                console.error(e);
                if (!unmounted.current) {
                    setStatusMap((prevStatusMap) => reduce(rootDefinitionIds, (result, definitionId) => ({
                        ...result,
                        [definitionId]: { isAvailable: false, isPending: false, isFailed: true }
                    }), prevStatusMap));
                }
            }
        };
        if (definitionIdsToFetch.size > 0) {
            const definitionIds = [...definitionIdsToFetch].filter((definitionId) => definitionId &&
                !statusMap?.[definitionId]?.isAvailable &&
                !statusMap?.[definitionId]?.isFailed);
            if (!unmounted.current) {
                setStatusMap((prevStatusMap) => reduce(definitionIds, (result, definitionId) => ({
                    ...result,
                    [definitionId]: {
                        isAvailable: false,
                        isPending: true,
                        isFailed: false
                    }
                }), prevStatusMap));
            }
            if (!isEmpty(definitionIds)) {
                void handleDisplayDataMapsProcessing(definitionIds);
            }
            definitionIdsToFetch.clear();
        }
    }, [fetchEntitiesDisplayDataHandler, statusMap]);
    const getDisplayDataMap = useCallback((definitionId) => displayDataMaps[definitionId] ? displayDataMaps[definitionId] : undefined, [displayDataMaps]);
    const getStatus = useCallback((definitionId) => {
        if (statusMap[definitionId]) {
            return statusMap[definitionId];
        }
        else {
            definitionIdsToFetch.add(definitionId);
            if (startFetchTimeout.current) {
                clearTimeout(startFetchTimeout.current);
            }
            startFetchTimeout.current = setTimeout(fetchDefinitions, 20);
            return {
                isAvailable: false,
                isPending: true,
                isFailed: false
            };
        }
    }, [statusMap, fetchDefinitions]);
    const value = useMemo(() => ({ getDisplayDataMap, getStatus }), [getDisplayDataMap, getStatus]);
    return React.createElement(DisplayDataMapContext.Provider, { value: value, ...otherProps });
};
const useProperties = (definitionId) => {
    const context = useContext(DisplayDataMapContext);
    const { t, language } = useI18n();
    if (!context) {
        throw new Error('useProperties must be used within an PropertiesProvider');
    }
    const displayDataMap = useMemo(() => context.getDisplayDataMap(definitionId), [context, definitionId]);
    const getDisplayData = useCallback((fieldPath) => displayDataMap?.[fieldPath] || undefined, [displayDataMap]);
    const isPropertyListHandler = useCallback((fieldPath) => (fieldPath ? isPropertyList(getDisplayData(fieldPath)) : false), [getDisplayData]);
    const getFieldLabel = useCallback((fieldPath, includeParentLabel, withTooltip) => {
        let parentLabelJsx = null;
        const labelJsx = [];
        const fieldDisplayData = getDisplayData(fieldPath);
        const propertiesStatus = context.getStatus(definitionId);
        if (propertiesStatus.isPending || propertiesStatus.isFailed) {
            return fieldPath;
        }
        else if (!fieldDisplayData) {
            labelJsx.push(React.createElement(Fragment, { key: `missing_field_${fieldPath}` }, `${fieldPath} (${t('MISSING_FIELD_INFO')})`));
        }
        else {
            const splittedPropertyIds = fieldPath.split('.');
            const Wrapper = withTooltip ? Tooltip : 'span';
            if (includeParentLabel && splittedPropertyIds.length > 1) {
                const parentDisplayData = getDisplayData(splittedPropertyIds[0]);
                const EntityIcon = getPropertyTypeComponent(PropertyTypes.entity);
                const hiddenProperty = parentDisplayData?.meta.hidden;
                const parentLabel = parentDisplayData
                    ? getTranslation(parentDisplayData.labels, language)
                    : splittedPropertyIds[0];
                parentLabelJsx = (React.createElement("span", { className: "d-flex align-items-center w-100", key: `${parentLabel}_${fieldPath}` },
                    hiddenProperty && React.createElement(ThisIsHiddenProperty, null),
                    React.createElement(Wrapper, { title: withTooltip ? parentLabel : undefined, className: "d-flex align-items-center w-100" },
                        EntityIcon && (React.createElement("span", { className: "me-2" },
                            React.createElement(EntityIcon, { size: "1rem" }))),
                        React.createElement("span", { className: "text-ellipsis text-nowrap me-1" }, parentLabel),
                        React.createElement("span", { className: "me-1" }, "/"))));
            }
            if (fieldDisplayData.meta.hidden) {
                labelJsx.push(React.createElement(ThisIsHiddenProperty, { key: `hidden_${fieldPath}` }));
            }
            const Icon = getPropertyTypeComponent(fieldDisplayData.type);
            const label = getTranslation(fieldDisplayData.labels, language);
            labelJsx.push(React.createElement(Wrapper, { title: withTooltip ? label : undefined, className: "d-flex align-items-center overflow-hidden", key: fieldPath },
                Icon && (React.createElement("span", { className: "me-2 d-flex", key: fieldPath },
                    React.createElement(Icon, { size: "1rem" }))),
                React.createElement("span", { className: "text-ellipsis text-nowrap" }, label)));
            if (getTranslation(get(fieldDisplayData, 'meta.units'), language)) {
                labelJsx.push(React.createElement(Fragment, { key: `units_${fieldPath}` }, ` (${getTranslation(get(fieldDisplayData, 'meta.units'), language)})`));
            }
        }
        return (React.createElement("div", { className: `${styles.container} h-100 overflow-hidden d-flex align-items-center` },
            parentLabelJsx && (React.createElement("div", { className: "parent-label-container overflow-hidden d-flex align-items-center" }, parentLabelJsx)),
            React.createElement("div", { className: "overflow-hidden d-flex align-items-center" }, labelJsx)));
    }, [getDisplayData, context, definitionId, t, language]);
    const getPropertyOptions = useCallback((includeProperty, includeNestedProperties, asFilter) => {
        let options = [];
        const displayDataMap = context.getDisplayDataMap(definitionId);
        if (displayDataMap) {
            // First we go through the root properties
            forEach(displayDataMap, (fieldDisplayData, fieldPath) => {
                const splittedPropertyIds = fieldPath.split('.');
                if (splittedPropertyIds.length > 1) {
                    // Ignore nested properties for now
                    return;
                }
                const newPropertyOption = {
                    value: includeNestedProperties || asFilter
                        ? getField(fieldDisplayData, undefined, asFilter)
                        : fieldPath,
                    label: getFieldLabel(fieldPath),
                    title: getTranslation(fieldDisplayData.labels, language),
                    disabled: false
                };
                if (fieldDisplayData.meta.hidden) {
                    return;
                }
                if (!includeProperty(fieldDisplayData)) {
                    // Ignore properties that aren't supported, except for:
                    if (includeNestedProperties && fieldDisplayData.type === 'entity') {
                        // In this case we don't want to exclude the field, because otherwise its allowed children won't be accessible by the user
                        newPropertyOption.disabled = true;
                    }
                    else {
                        return;
                    }
                }
                options.push(newPropertyOption);
            });
            if (includeNestedProperties) {
                // Now we add the children
                forEach(displayDataMap, (fieldDisplayData, fieldPath) => {
                    const splittedPropertyIds = fieldPath.split('.');
                    if (splittedPropertyIds.length === 1) {
                        // Now ignore root-level properties
                        return;
                    }
                    if (fieldDisplayData.meta.hidden) {
                        return;
                    }
                    if (!includeProperty(fieldDisplayData)) {
                        // Ignore properties that aren't supported
                        return;
                    }
                    const newPropertyOption = {
                        value: getField(fieldDisplayData, splittedPropertyIds[0], asFilter),
                        label: getFieldLabel(fieldPath),
                        title: getTranslation(fieldDisplayData.labels, language),
                        disabled: false
                    };
                    if (splittedPropertyIds[1] === ENTITY_ID_PROPERTY_ID) {
                        // no add entityId to children
                        return;
                    }
                    const parentPropertyOption = find(options, {
                        value: asFilter
                            ? `${splittedPropertyIds[0]}.${ENTITY_ID_PROPERTY_ID}`
                            : splittedPropertyIds[0]
                    });
                    if (!parentPropertyOption) {
                        return;
                    }
                    if (parentPropertyOption.children) {
                        parentPropertyOption.children.push(newPropertyOption);
                    }
                    else {
                        parentPropertyOption.children = [newPropertyOption];
                    }
                });
                // Remove root entity properties that didn't end up having children that are supported
                options = options.filter((option) => {
                    const fieldPath = option.value;
                    const splittedPropertyIds = fieldPath.split('.');
                    if (splittedPropertyIds.length === 1) {
                        // Only consider removing root-level properties because nested were already filtered above
                        const propertyId = splittedPropertyIds[0];
                        const fieldDisplayData = displayDataMap[propertyId];
                        if (!includeProperty(fieldDisplayData) &&
                            fieldDisplayData.type === 'entity' &&
                            isEmpty(option.children)) {
                            return false;
                        }
                    }
                    return true;
                });
            }
        }
        return options
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((option) => ({
            ...option,
            children: option.children?.sort((a, b) => a.title.localeCompare(b.title))
        }));
    }, [context, definitionId, getFieldLabel, language]);
    return useMemo(() => ({
        propertiesStatus: context.getStatus(definitionId),
        getDisplayData,
        getFieldLabel,
        getPropertyOptions,
        isPropertyList: isPropertyListHandler
    }), [
        context,
        definitionId,
        getDisplayData,
        getFieldLabel,
        getPropertyOptions,
        isPropertyListHandler
    ]);
};
export { PropertiesProvider, useProperties };
//
// Utils
//
export const isPropertyList = (displayData) => {
    if (displayData) {
        if (displayData.type === PropertyTypes.multitext) {
            return true;
        }
        if ('list' in displayData.meta && displayData.meta.list) {
            return true;
        }
        if ('multiline' in displayData.meta && displayData.meta.multiline) {
            return true;
        }
    }
    return false;
};
export const generateDisplayDataMap = (displayDataList, parentPropertyId) => keyBy(getFlattenedDisplayDataList(displayDataList), (displayData) => parentPropertyId ? `${parentPropertyId}.${displayData.propertyId}` : displayData.propertyId);
const definitionIdsToFetch = new Set();
