import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import queryClient, { oneHourInMs } from '../common/utils/react-query-client';
import { fetchTenant } from './actions';
const TENANT_KEY = 'tenant-settings';
export function invalidateTenantCache() {
    return queryClient.invalidateQueries([TENANT_KEY]);
}
export const useTenant = () => {
    const dispatch = useDispatch();
    const { data: tenant, isError, isLoading } = useQuery([TENANT_KEY], () => {
        return dispatch(fetchTenant());
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneHourInMs
    });
    return {
        tenant,
        isAvailable: !!tenant,
        isPending: isLoading,
        isFailed: isError
    };
};
