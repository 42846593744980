import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { formatDateTime, ReadableDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { Collapse, Tooltip } from 'antd';
import { capitalize } from 'lodash';
import React from 'react';
import { PoseGroup } from 'react-pose';
import { Item, LoadingCommonBar } from '../..';
import { TooltipTruncate } from '../../../../../../../common/components/TooltipTruncate';
const AvailableTasksStepDescription = ({ availableTasks, loading, activeKeys, handlePanelChange }) => {
    const { t } = useI18n();
    return availableTasks.length > 0 ? (React.createElement(PoseGroup, null,
        React.createElement(Collapse, { key: "AvailableTasksStepDescription", ghost: true, onChange: handlePanelChange, activeKey: activeKeys, items: availableTasks.map(({ taskTitle, initiatedAt, taskRunId }) => ({
                key: taskRunId,
                label: React.createElement(TooltipTruncate, null, taskTitle),
                children: (React.createElement(Item, { key: taskRunId },
                    React.createElement("div", { className: "SmallText", key: `initiatedAt_${taskRunId}` },
                        React.createElement("span", { className: "me-2" }, capitalize(t('AVAILABLE_SINCE'))),
                        React.createElement(Tooltip, { title: formatDateTime(initiatedAt) },
                            React.createElement("span", { className: "fw-bold" },
                                React.createElement(ReadableDateTime, { dateTime: initiatedAt })))))),
                className: 'CustomCollapsePanel'
            })) }))) : (React.createElement(LoadingCommonBar, { loading: loading && !availableTasks.length }));
};
export default AvailableTasksStepDescription;
