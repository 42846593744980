import { DEFAULT_LANGUAGE } from 'config';
import { isEmpty, isEqual } from 'lodash';
import React, { Component } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import styled, { css } from 'styled-components';
import { CircularLoader } from '../../common';
import { ErrorMessage, InputContainer, LabelText, fieldCss } from './utils';
export const SelectContainer = styled.div `
  position: relative;
  min-width: ${({ isLoading }) => (isLoading ? '5rem' : null)};
`;
const placeholderCss = css `
  color: #b3b3b3;
  font-weight: 400;
`;
export const Select = styled.select `
  ${fieldCss};

  cursor: default;
  appearance: none;
  padding-right: 2rem;

  ${({ noOptionSelected }) => noOptionSelected && placeholderCss};
`;
export const ArrowIcon = styled(IoIosArrowDown) `
  position: absolute;
  pointer-events: none;
  top: calc(50%);
  transform: translate(0, -50%);
  right: 0.5rem;
  font-size: 1.2rem;
  color: ${({ disabled }) => (disabled ? '#ccc' : '#868686')};
`;
const StyledCircularLoader = styled(CircularLoader) `
  position: absolute;
  right: 0.5rem;
  top: calc(50% - 10.5px);
`;
// Having a lot of options significantly delays the time to First Meaningful Paint
// Delaying adding the options to the dom allows the browser to first render the page and then render the options afterwards
const lazyLoadOptionsThreshold = 20;
class BasicSelect extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            loadedOptions: this.props.options.length > lazyLoadOptionsThreshold ? [] : this.props.options,
            prevOptions: this.props.options
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (!isEqual(nextProps.options, prevState.prevOptions)) {
            return {
                loadedOptions: nextProps.options,
                prevOptions: nextProps.options
            };
        }
        return null;
    }
    componentDidMount() {
        const { options } = this.props;
        if (options.length > lazyLoadOptionsThreshold) {
            setTimeout(() => this.setState({ loadedOptions: options }));
        }
    }
    render() {
        const { className, input, meta, label, options, language, emptyOption = true, emptyDisabled = true, placeholder = '', disabled, loading, value, ...otherProps } = this.props;
        const { loadedOptions } = this.state;
        const v = value !== undefined ? value : input?.value;
        return (React.createElement(InputContainer, { className: className },
            label && React.createElement(LabelText, { htmlFor: input?.name }, label),
            React.createElement(SelectContainer, { isLoading: !!loading },
                React.createElement(Select, { ...input, ...otherProps, className: "BasicSelectOuter", value: v, disabled: disabled || (options.length > lazyLoadOptionsThreshold && isEmpty(loadedOptions)), invalid: !!(meta && (meta.touched || meta.submitFailed) && meta.error), noOptionSelected: v === '' }, loadedOptions && [
                    emptyOption && (React.createElement("option", { key: "empty", disabled: emptyDisabled, value: "" }, placeholder)),
                    ...loadedOptions.map((o, i) => (React.createElement("option", { key: `${o.value}-${i}`, value: o.value }, o.labels[language] || o.labels[DEFAULT_LANGUAGE])))
                ]),
                !loading && React.createElement(ArrowIcon, { disabled: !!disabled }),
                loading && React.createElement(StyledCircularLoader, { useTheme: true })),
            meta && (meta.touched || meta.submitFailed) && meta.error && (React.createElement(ErrorMessage, null, meta.error))));
    }
}
export default BasicSelect;
