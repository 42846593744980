import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { MY_USER_COMPUTED } from '~app/cms/common/utils/constants';
import { ItemsViewKanban } from '~app/cms/data/components/items/ItemsView';
import useDisplayItemMetaList from '~app/cms/hooks/useDisplayItemMetaList';
import { useView } from '~app/views/components/ViewContext';
import { getFromEndWeekToEndMonthFilter, getFromNowToEndDayFilter, getFromTomorrowToEndWeekFilter, getLaterEndMonthFilter, getLessThenNowFilter } from '../../../constants';
import { TasksFilterType } from '../../../types';
import { TasksDashboardDeadlineColumns, tasksDashboardDeadlineColumns } from '../../../utils';
import { useTaskDashboard } from '../TaskDashboardContext/TaskDashboardProvider';
const TasksKanban = ({ baseUrl, getCustomRouteHandleCardClick }) => {
    const { t } = useI18n();
    const { kanban, view, definitionId } = useView();
    const { mapProperty } = useTaskDashboard();
    const { data: displayItemMetaList } = useDisplayItemMetaList();
    const customColumnsTitles = useMemo(() => {
        if (kanban?.groupBy === mapProperty[TasksFilterType.relatedTo]) {
            return displayItemMetaList.reduce((acc, displayItemMeta) => ({
                ...acc,
                [displayItemMeta.definitionId]: displayItemMeta.title
            }), {});
        }
        if (kanban?.groupBy === mapProperty[TasksFilterType.assignees]) {
            return {
                [FilterOperatorTypes.empty]: t('NOT_ASSIGNED'),
                [MY_USER_COMPUTED]: t('ASSIGN_TO_ME')
            };
        }
        if (kanban?.groupBy === mapProperty[TasksFilterType.deadline]) {
            return {
                ...tasksDashboardDeadlineColumns.reduce((acc, column) => ({ ...acc, [column]: t(column) }), {}),
                [FilterOperatorTypes.empty]: t('NO_DEADLINE')
            };
        }
    }, [kanban?.groupBy, mapProperty, displayItemMetaList, t]);
    const getCustomColumnCountFilters = useCallback((columnId) => {
        if (kanban?.groupBy === mapProperty[TasksFilterType.deadline]) {
            switch (columnId) {
                case TasksDashboardDeadlineColumns.overdue:
                    return [getLessThenNowFilter(mapProperty[TasksFilterType.deadline])];
                case TasksDashboardDeadlineColumns.today:
                    return [getFromNowToEndDayFilter(mapProperty[TasksFilterType.deadline])];
                case TasksDashboardDeadlineColumns.thisWeek:
                    return [getFromTomorrowToEndWeekFilter(mapProperty[TasksFilterType.deadline])];
                case TasksDashboardDeadlineColumns.thisMonth:
                    return [getFromEndWeekToEndMonthFilter(mapProperty[TasksFilterType.deadline])];
                case TasksDashboardDeadlineColumns.later:
                    return [getLaterEndMonthFilter(mapProperty[TasksFilterType.deadline])];
            }
        }
    }, [kanban?.groupBy, mapProperty]);
    if (!definitionId) {
        return React.createElement(Typography.Text, { type: "danger" }, t('SOMETHING_WENT_WRONG'));
    }
    return (React.createElement("div", { className: "pb-2 pt-2 h-100" },
        React.createElement(ItemsViewKanban, { customColumnsTitles: customColumnsTitles, getCustomColumnCountFilters: getCustomColumnCountFilters, getCustomRouteHandleCardClick: getCustomRouteHandleCardClick, noFilters: true, noSearch: true, view: view, noViewActions: true, baseUrl: baseUrl, definitionId: definitionId })));
};
export default TasksKanban;
