import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { INTEGRATION_ADMIN_PERMISSION, MANAGE_FAVOURITES_PERMISSION } from '@hypercharge/portal-utils';
import { DOCUMENTATION_URL, ROADMAP_URL } from 'config';
import React, { useMemo } from 'react';
import { MdExitToApp, MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { REQUIRED_ADMIN_TENANT_PERMISSION } from '../../../../../../admin';
import { getAccountId, hasTenantPermission } from '../../../../../../auth';
import { useRetrieveAccounts } from '../../../../../../auth/hooks';
import { TooltipTruncate } from '../../../../../../common/components/TooltipTruncate';
import { PushMenu } from '../../../../../../common/components/push-menu';
import { APP_ADMIN_PERMISSION, PLATFORM_ADMIN_PERMISSION } from '../../../../../../common/utils/constants';
import { ContactDisplayName } from '../../../../../../crm';
import { useMyContact } from '../../../../../../crm/components/use-my-contact';
import { SETTINGS_PATH } from '../../../../../../settings';
import { PAYMENT_SUBSCRIPTION_PERMISSION } from '../../../../../../settings/components/subscriptions/constants';
import LanguageSelector from '../../../LanguageSelector';
import styles from './Modal.module.scss';
const Modal = ({ close, signOut, menu, onSwitchUser }) => {
    const { t } = useI18n();
    const { contact } = useMyContact();
    const { data: contacts } = useRetrieveAccounts();
    const currentEntityId = useSelector((s) => getAccountId(s));
    const fullName = `${contact?.firstName} ${contact?.lastName}`;
    const { hasRequiredAdminTenantPermission, hasAdminPermission, hasPlatformAdminPermission, hasIntegrationsAdminPermission, hasPaymentAdminPermission, hasManageFavouritesPermission } = useSelector((s) => ({
        hasRequiredAdminTenantPermission: hasTenantPermission(s, REQUIRED_ADMIN_TENANT_PERMISSION),
        hasAdminPermission: hasTenantPermission(s, APP_ADMIN_PERMISSION),
        hasPlatformAdminPermission: hasTenantPermission(s, PLATFORM_ADMIN_PERMISSION),
        hasIntegrationsAdminPermission: hasTenantPermission(s, INTEGRATION_ADMIN_PERMISSION),
        hasPaymentAdminPermission: hasTenantPermission(s, PAYMENT_SUBSCRIPTION_PERMISSION),
        hasManageFavouritesPermission: hasTenantPermission(s, MANAGE_FAVOURITES_PERMISSION)
    }));
    const additionalMenuItems = useMemo(() => {
        const menu = [];
        if (hasRequiredAdminTenantPermission ||
            hasAdminPermission ||
            hasPlatformAdminPermission ||
            hasIntegrationsAdminPermission ||
            hasPaymentAdminPermission ||
            hasManageFavouritesPermission) {
            menu.push({
                key: 'settings',
                title: t('SETTINGS'),
                to: SETTINGS_PATH
            });
        }
        menu.push({
            key: 'help',
            title: t('HELP'),
            to: DOCUMENTATION_URL
        }, {
            key: 'roadmap',
            title: t('FEATURE_WISHLIST_AND_ROADMAP'),
            to: ROADMAP_URL
        }, {
            key: 'switch',
            title: t('MAIN_MENU__SWITCH_USER'),
            children: contacts?.map((contact) => ({
                key: contact.entityId,
                disabled: contact.entityId === currentEntityId,
                onClickAction: () => {
                    if (contact.entityId !== currentEntityId) {
                        onSwitchUser(contact.entityId);
                    }
                },
                title: (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "d-flex align-items-center" }, contact.entityId === currentEntityId ? (React.createElement(MdRadioButtonChecked, null)) : (React.createElement(MdRadioButtonUnchecked, null))),
                    React.createElement(TooltipTruncate, { style: { maxWidth: '200px' }, className: "ps-2" }, [contact.firstName, contact.lastName].join(' '))))
            }))
        });
        return menu;
    }, [
        contacts,
        currentEntityId,
        hasAdminPermission,
        hasIntegrationsAdminPermission,
        hasManageFavouritesPermission,
        hasPaymentAdminPermission,
        hasPlatformAdminPermission,
        hasRequiredAdminTenantPermission,
        onSwitchUser,
        t
    ]);
    return (React.createElement("div", { className: "p-0 pb-5" },
        React.createElement("div", { className: `${styles.profileContainer} px-0 pt-1 cp-c-row` },
            React.createElement("div", { className: `${styles.profile} position-relative rounded-circle text-center d-flex align-items-center justify-content-center` },
                React.createElement(ContactDisplayName, { contact: contact, justFirstLetter: true })),
            React.createElement("div", { className: "d-flex flex-column my-auto" },
                React.createElement("div", { className: "fw-600 ps-3 cp-c-column cp-c-align-spacebetween-start cp-c-row cp-c-wrap" }, fullName),
                contact && React.createElement("div", { className: "fw-500 ps-3 email-work" }, contact.emailWork))),
        React.createElement("div", { className: "cp-c-column" },
            React.createElement(PushMenu, { items: [...menu, ...additionalMenuItems], onItemClick: close })),
        React.createElement("div", { className: "mt-2 d-flex justify-content-between align-items-center" },
            React.createElement(LanguageSelector, { icon: true, desktopHeader: false }),
            React.createElement("div", { className: `${styles.signOut} rounded-circle cursor-pointer pt-0 justify-content-center align-items-center cp-c-row`, onClick: signOut, "data-test-id": "mobile-menu-user-dropdown-logout" },
                React.createElement(MdExitToApp, { size: 19 })))));
};
export default Modal;
