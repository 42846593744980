import { ConditionType } from '@hypercharge/portal-utils';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { getAggregations } from '~app/hyperbi/dashboards/actions';
import queryClient, { oneHourInMs, oneMinuteInMs } from '../../../common/utils/react-query-client';
import { DEFAULT_SORT_BY_FIELDS_VIEW } from '../../../views/constants';
import { AggregationType } from './types';
import { getAggregationId } from './utils';
export const MISSED_VALUE_PLACEHOLDER_STRING = '-empty-';
export const DEFAULT_FILTER = {
    languageCode: 'en',
    sortBy: DEFAULT_SORT_BY_FIELDS_VIEW,
    query: { condition: ConditionType.and, filters: [] }
};
const AGGREGATION_QUERY_KEY = 'aggregation';
export const invalidateAggregationQuery = async ({ definitionId }) => {
    await queryClient.invalidateQueries({
        predicate: (query) => [AGGREGATION_QUERY_KEY, definitionId].every((key) => query.queryKey.includes(key))
    });
};
export const useAggregation = ({ definitionId, aggregations, globalFilter, enabled = true, keepPreviousData = false }) => {
    const dispatchAggregations = useDispatch();
    const filter = useMemo(() => {
        if (!globalFilter?.query) {
            return DEFAULT_FILTER;
        }
        return { ...DEFAULT_FILTER, ...globalFilter };
    }, [globalFilter]);
    const trackTotal = useMemo(() => {
        return !!aggregations?.find((aggregation) => 'inPercents' in aggregation && aggregation.inPercents);
    }, [aggregations]);
    const { data, isLoading, refetch } = useQuery([AGGREGATION_QUERY_KEY, definitionId, { aggregations, filter, trackTotal }], async () => {
        if (!definitionId) {
            return;
        }
        const requestedAggregations = aggregations;
        const response = await dispatchAggregations(getAggregations(definitionId, {
            aggregations: {
                aggregations
            },
            filter,
            trackTotal
        }));
        return sortAggregationResult(requestedAggregations, response);
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs,
        enabled,
        keepPreviousData
    });
    return useMemo(() => {
        return {
            isLoading,
            data,
            refetch
        };
    }, [data, isLoading, refetch]);
};
function sortAggregationResult(aggregations, response) {
    for (const aggregationItem of aggregations) {
        if (aggregationItem.type === AggregationType.term &&
            aggregationItem.customOrder &&
            aggregationItem.customOrder.enabled &&
            aggregationItem.customOrder.values &&
            aggregationItem.customOrder.values.length) {
            const aggregationItemId = getAggregationId(aggregationItem);
            const aggregationResponse = response.aggregations[aggregationItemId];
            if (Array.isArray(aggregationResponse)) {
                aggregationResponse.sort((a, b) => {
                    let aSortPosition = aggregationItem.customOrder?.values?.indexOf(a.value) ?? -1;
                    let bSortPosition = aggregationItem.customOrder?.values?.indexOf(b.value) ?? -1;
                    aSortPosition = aSortPosition === -1 ? 99999 : aSortPosition;
                    bSortPosition = bSortPosition === -1 ? 99999 : bSortPosition;
                    return aSortPosition - bSortPosition;
                });
            }
        }
    }
    return response;
}
