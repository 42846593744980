import { I18nProvider } from '@hypercharge/hyper-react-base/lib/i18n';
import bowser from 'bowser';
import { SENTRY_URL } from 'config';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import React from 'react';
import Script from 'react-load-script';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { DashboardsProvider } from '../../hyperbi/dashboards/DashboardsProvider';
import { DisplayTenantProvider } from '../../tenant';
import ThemeProvider from '../../theme/themeProvider';
import { ViewsProvider } from '../../views';
import { StickyTabsProvider } from '../components/StickyTabs/StickyTabsProvider';
import UnsupportedBrowser from '../components/UnsupportedBrowser/UnsupportedBrowser';
import { FileMovingContextProvider } from '../context/FileMovingContext';
import { StorageContextProvider } from '../context/StorageContext';
import { commonMessages } from '../messages';
import { getI18nState } from '../reducers/rootSelector';
import queryClient from '../utils/react-query-client';
import App from './App';
const browser = bowser.getParser(window.navigator.userAgent);
const isInvalidBrowser = browser.satisfies({
    windows: {
        'internet explorer': '>0',
        'Microsoft Edge': '<15',
        'Samsung Internet for Android': '>0'
    }
});
const extendedMessages = [commonMessages];
const handleScriptLoad = () => {
    if (window && window.Sentry) {
        window.Sentry.init({
            dsn: SENTRY_URL
        });
    }
};
// need for start week from monday
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
    weekStart: 1
});
const Root = ({ store, persistor, history }) => (React.createElement(StorageContextProvider, null,
    React.createElement(QueryClientProvider, { client: queryClient },
        process.env.NODE_ENV === 'development' && React.createElement(ReactQueryDevtools, { initialIsOpen: false }),
        React.createElement(PersistGate, { persistor: persistor },
            React.createElement(ReduxProvider, { store: store },
                React.createElement(Router, { history: history },
                    React.createElement(DisplayTenantProvider, null,
                        React.createElement(I18nProvider
                        // @ts-expect-error
                        , { 
                            // @ts-expect-error
                            getI18nState: getI18nState, extendedMessages: extendedMessages },
                            React.createElement(ThemeProvider, null,
                                React.createElement(DashboardsProvider, null, !isInvalidBrowser ? (React.createElement(React.Fragment, null,
                                    React.createElement(StickyTabsProvider, null,
                                        React.createElement(FileMovingContextProvider, null,
                                            React.createElement(ViewsProvider, null,
                                                React.createElement(App, null)))),
                                    SENTRY_URL ? (React.createElement(Script, { url: "https://browser.sentry-cdn.com/4.5.3/bundle.min.js", crossOrigin: "anonymous", onLoad: handleScriptLoad })) : null)) : (React.createElement(UnsupportedBrowser, null))))))))))));
export default Root;
