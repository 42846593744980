import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { RowHeight } from '@hypercharge/portal-utils';
import { uniq } from 'lodash';
import React from 'react';
import { FaBars, FaColumns, FaFilter, FaLayerGroup, FaSortAmountDown, FaTachometerAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { TITLE_PROPERTY_ID, getFlattenedDisplayDataList } from '../../../cms';
import { useEntityDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import { PropertiesProvider } from '../../../cms/common/context/PropertiesProvider';
import { TermsAggregationFilterOrderBy } from '../../../cms/hooks/useAggregation';
import { AntInputField } from '../../../common/components/AntInput';
import { usePropertiesTree } from '../../../common/components/ComputedExpressionEditor/usePropertiesTree';
import { ConditionQueryField } from '../../../common/components/ConditionQuery';
import { DrawerContent, DrawerFooter } from '../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../common/components/Drawer/DrawerContentBlock';
import { DrawerContentBlockSubTitle } from '../../../common/components/Drawer/DrawerContentBlockSubTitle';
import { DrawerFormFooter } from '../../../common/components/Drawer/DrawerFormFooter';
import SelectField from '../../../common/components/Select/SelectField';
import Toggle from '../../../common/components/Toggle';
import { ROW_HEIGHT_TYPES_LIST } from '../../../common/components/data-table/utils';
import { LoadingOverlay } from '../../../common/utils/stylingUtils';
import { MediumRowSizeIcon } from '../../../icons';
import { METRICS_KEY, SORT_BY_KEY } from '../../constants';
import { ViewTypes, isKanbanView, isTableView } from '../../types';
import ColumnsField from '../ColumnsField';
import { GroupingPropertyList } from '../GroupingPropertyList';
import { GroupingRow } from '../GroupingRow';
import { KanbanColumnsField } from '../KanbanColumnsField';
import SortByField from '../SortByField';
import { ToggleViewField } from '../ToggleView';
import MetricsField from '../metrics/MetricsField';
import styles from './EditViewForm.module.scss';
const EditViewForm = ({ definitionId, handleSubmit, onClose, change, allowComputedForConditionQuery, metaDefinitionId, noKanban = false, noSelectFieldsOnTableView = false, showTitle = false, showIsDefault = false, submitText, canEditFilters = true, canEditSorting = true, unavailableOperators, canEditRowHeight = true, canEditGroupingRow = true, canEditMetrics = true, customOptionsGroupBy, customAvailableColumns, customSelectedColumns, onChangeCustomSearch }) => {
    const { t } = useI18n();
    const { displayData, displayDataStatus } = useEntityDisplayData(definitionId);
    const propertiesTree = usePropertiesTree(metaDefinitionId);
    const { viewType, kanban } = useSelector((s) => {
        const filtersForm = formValueSelector('filters');
        return {
            viewType: filtersForm(s, 'viewType'),
            kanban: filtersForm(s, 'kanban')
        };
    });
    if (displayDataStatus.isFailed ||
        (!displayDataStatus.isPending &&
            (!displayData || getFlattenedDisplayDataList(displayData).length === 0))) {
        return React.createElement("div", { className: styles.failedMessage }, t('ENTITY_TABLE_NO_PROPERTIES_TEXT'));
    }
    if (displayDataStatus.isPending) {
        return (React.createElement(DrawerContent, null,
            React.createElement(DrawerContentBlock, null,
                React.createElement(LoadingOverlay, null,
                    React.createElement(LoadingRectangles, null)))));
    }
    return (React.createElement(ErrorBoundary, null,
        React.createElement(PropertiesProvider, null,
            React.createElement(DrawerContent, null,
                React.createElement("form", { onSubmit: handleSubmit, noValidate: true, className: "d-flex flex-column gap-2 flex-basis-100" },
                    !noKanban && (React.createElement(DrawerContentBlock, null,
                        React.createElement(Field, { name: "viewType", component: ToggleViewField, className: "w-100" }))),
                    showTitle && (React.createElement(DrawerContentBlock, { title: t('VIEWS__TITLE_LABEL') },
                        React.createElement(Field, { name: "title", component: AntInputField, placeholder: t('VIEWS__TITLE_LABEL') }))),
                    showIsDefault && (React.createElement(DrawerContentBlock, { title: t('VIEWS__IS_DEFAULT') },
                        React.createElement(Field, { name: "isDefault", component: Toggle, label: t('VIEWS__IS_DEFAULT') }))),
                    canEditFilters && (React.createElement(DrawerContentBlock, { icon: FaFilter, title: t('VIEWS__FILTERS') },
                        React.createElement(Field, { name: "conditionQuery", component: ConditionQueryField, definitionId: definitionId, allowComputed: allowComputedForConditionQuery, propertiesTree: propertiesTree, unavailableOperators: unavailableOperators }))),
                    !noKanban && isKanbanView(viewType) && (React.createElement(DrawerContentBlock, { icon: FaColumns, title: `${t('VIEWS__COLUMNS')} (20 max)` },
                        React.createElement(React.Fragment, null,
                            React.createElement(DrawerContentBlockSubTitle, null, t('VIEWS__KANBAN__GROUP_BY')),
                            React.createElement(Field, { name: "kanban.groupBy", component: GroupingPropertyList, customOptionsGroupBy: customOptionsGroupBy, changeField: change, definitionId: definitionId }),
                            React.createElement(DrawerContentBlockSubTitle, null, t('VIEWS__COLUMNS')),
                            React.createElement(Field, { name: "kanban.columns", component: KanbanColumnsField, onChangeCustomSearch: onChangeCustomSearch, customAvailableColumns: customAvailableColumns, customSelectedColumns: customSelectedColumns, displayData: displayData, groupBy: kanban?.groupBy })))),
                    (!noSelectFieldsOnTableView || viewType !== ViewTypes.table) && (React.createElement(DrawerContentBlock, { icon: isTableView(viewType) ? FaColumns : FaBars, title: t(isTableView(viewType) ? 'VIEWS__COLUMNS' : 'VIEWS__FIELDS') },
                        React.createElement(Field, { name: "columns", component: ColumnsField, displayData: displayData, viewType: viewType }))),
                    canEditGroupingRow && isTableView(viewType) && (React.createElement(DrawerContentBlock, { icon: FaLayerGroup, title: t('VIEWS__GROUPING_ROW') },
                        React.createElement(Field, { name: "rowGrouping", component: GroupingRow, definitionId: definitionId }))),
                    canEditSorting && (React.createElement(DrawerContentBlock, { icon: FaSortAmountDown, title: t('VIEWS__SORTING') },
                        React.createElement(Field, { name: SORT_BY_KEY, component: SortByField, displayData: displayData }))),
                    (noKanban || viewType !== ViewTypes.kanban) && canEditRowHeight && (React.createElement(DrawerContentBlock, { icon: MediumRowSizeIcon, title: t('VIEWS__ROW_HEIGHT_TYPE') },
                        React.createElement(Field, { name: "rowHeightType", component: SelectField, options: ROW_HEIGHT_TYPES_LIST.map((rowHeightItem) => ({
                                value: rowHeightItem,
                                label: t(`DATA_TABLE__ROW_HEIGHT_TYPE_${rowHeightItem}`)
                            })) }))),
                    canEditMetrics && (React.createElement(DrawerContentBlock, { icon: FaTachometerAlt, title: t('VIEWS__METRICS') },
                        React.createElement(Field, { name: METRICS_KEY, definitionId: definitionId, component: MetricsField, displayData: displayData })))))),
        React.createElement(DrawerFooter, null,
            React.createElement(DrawerFormFooter, { submitText: submitText || t('FILTERS_SHOW_RESULTS'), onSubmit: handleSubmit, onCancel: onClose }))));
};
export default compose(withProps(({ filterRequest: { query: conditionQuery, sortBy, fullText, fullTextFields }, metrics = [], viewType, kanban, rowHeightType, rowGrouping, rowGroupingOrderBy, title, isDefault }) => ({
    initialValues: {
        conditionQuery,
        sortBy,
        fullText,
        columns: fullTextFields,
        metrics,
        kanban,
        viewType: viewType || ViewTypes.table,
        rowHeightType: rowHeightType || RowHeight.SMALL,
        rowGrouping,
        rowGroupingOrderBy: rowGroupingOrderBy || TermsAggregationFilterOrderBy.field,
        title,
        isDefault
    }
})), reduxForm({
    form: 'filters',
    // @ts-expect-error
    onSubmit: (formValues, dispatch, props) => {
        const { updateRouteWithView } = props;
        const columns = uniq([
            ...(formValues.columns || [TITLE_PROPERTY_ID]),
            ...((formValues.rowGrouping && [formValues.rowGrouping]) || [])
        ]);
        updateRouteWithView?.({
            newFilters: {
                ...props.filterRequest,
                [SORT_BY_KEY]: formValues[SORT_BY_KEY],
                query: formValues.conditionQuery,
                fullTextFields: columns,
                responseFields: columns
            },
            newMetrics: formValues.metrics,
            shouldReplacePath: false,
            viewType: formValues.viewType,
            rowHeightType: formValues.rowHeightType,
            kanban: isKanbanView(formValues.viewType) ? formValues.kanban : undefined,
            rowGrouping: formValues.rowGrouping,
            rowGroupingOrderBy: formValues.rowGroupingOrderBy
        });
        props.onCloseCallback(formValues);
    },
    enableReinitialize: true,
    destroyOnUnmount: true
}))(EditViewForm);
