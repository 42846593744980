import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Divider } from 'antd';
import { DEFAULT_LANGUAGE, AVAILABLE_LANGUAGES as TENANT_AVAILABLE_LANGUAGES } from 'config';
import { omit, uniq, values } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { hasTenantPermission } from '~app/auth';
import { FieldLabel } from '~app/cms/data/components/item-property/FieldElements';
import LanguageSelectorInput from '~app/common/components/LanguageSelectorInput/LanguageSelectorInput';
import { Option, Select } from '~app/common/components/Select';
import { PropertyTypes } from '~app/common/types';
import { getScrollableAreaOrBody } from '~app/common/utils/common';
import { MULTI_LANGUAGE_PROPERTY_PERMISSION, PORTAL_AVAILABLE_LANGUAGES } from '~app/common/utils/constants';
import { dropdownStyle } from '~app/common/utils/stylingUtils';
import { getProperties } from '~app/workflows';
import { getMetaDefinitionFromFormValue, getPropertyTypeComponent, isFormValueMetaDefinitionPropertyT } from '~app/workflows/meta/components/meta-definition-form/utils';
import styles from './TranslationsSelectorTab.module.scss';
export const useTranslationsSelectorTab = (value, formValues) => {
    const metaDefinition = useMemo(() => getMetaDefinitionFromFormValue(formValues), [formValues]);
    const multiLanguagePermission = useSelector((s) => hasTenantPermission(s, MULTI_LANGUAGE_PROPERTY_PERMISSION));
    const isPropertyType = isFormValueMetaDefinitionPropertyT(value);
    const propertyId = isPropertyType ? value.propertyId : undefined;
    const isPropertyTranslation = useMemo(() => propertyId &&
        isPropertyType &&
        metaDefinition.sections?.some((section) => section.properties.some((property) => values(property?.meta?.translations || {}).includes(propertyId))), [isPropertyType, metaDefinition.sections, propertyId]);
    const isVisible = isPropertyType && value.type === PropertyTypes.text;
    return { isVisible: isVisible && !isPropertyTranslation && multiLanguagePermission };
};
const TranslationsSelectorTab = ({ disabled, defaultLanguage = DEFAULT_LANGUAGE, value, onChange, values }) => {
    const { t, language: selectedLanguage } = useI18n();
    const filteredProperties = useMemo(() => {
        return getProperties(getMetaDefinitionFromFormValue(values)).filter((prop) => prop.type === value.type);
    }, [value.type, values]);
    const { addedLanguages, languageAvailableForAdding } = useMemo(() => {
        const languagesInMeta = value.meta.translations ? Object.keys(value.meta.translations) : [];
        const usedLanguages = uniq([...TENANT_AVAILABLE_LANGUAGES, ...languagesInMeta]).map((langCode) => ({
            code: langCode,
            label: PORTAL_AVAILABLE_LANGUAGES.find(({ value }) => value === langCode)?.label || langCode,
            isCustom: !TENANT_AVAILABLE_LANGUAGES.find((tenantLangCode) => tenantLangCode == langCode)
        }));
        const availableLanguage = PORTAL_AVAILABLE_LANGUAGES.filter(({ value }) => !usedLanguages.map(({ code }) => code).includes(value));
        return {
            languageAvailableForAdding: availableLanguage,
            addedLanguages: usedLanguages
        };
    }, [value.meta.translations]);
    const _onChange = useCallback((newValue, selectedLangCode) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                translations: {
                    ...value.meta.translations,
                    [selectedLangCode]: newValue
                }
            }
        });
    }, [onChange, value]);
    const onAddNewLanguage = useCallback((newLangCode) => {
        if (!newLangCode) {
            return;
        }
        _onChange(null, newLangCode);
    }, [_onChange]);
    const onDeleteCustomLanguage = useCallback((langCode) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                translations: {
                    ...omit(value.meta.translations, langCode)
                }
            }
        });
    }, [onChange, value]);
    return (React.createElement("div", { className: `${styles.container} w-100 overflow-hidden` },
        addedLanguages.map(({ code, isCustom, label }) => (React.createElement("div", { key: code, className: "d-flex align-items-center justify-content-between mb-3" },
            React.createElement(FieldLabel, { label: label, className: "me-auto" }),
            isCustom && (React.createElement(Button, { className: "me-2 p-0 px-2 text-danger", type: "text", onClick: () => {
                    onDeleteCustomLanguage(code);
                } },
                React.createElement(FaTrash, null))),
            React.createElement("div", { className: "w-50" },
                React.createElement(Select, { showSearch: true, allowClear: true, defaultValue: value.meta?.translations && value.meta.translations[code], disabled: disabled || code === defaultLanguage, getPopupContainer: getScrollableAreaOrBody, dropdownStyle: dropdownStyle, value: defaultLanguage === code
                        ? value.propertyId
                        : value.meta.translations && value.meta.translations[code], onChange: (newValue) => _onChange(newValue, code) }, filteredProperties.map((prop) => {
                    const Icon = getPropertyTypeComponent(prop.type);
                    return (React.createElement(Option, { key: prop.propertyId, value: prop.propertyId, disabled: prop.propertyId === value.propertyId },
                        Icon && React.createElement(Icon, { className: "me-2" }),
                        getTranslation(prop.labels, selectedLanguage) || t('NO_TITLE')));
                })))))),
        !!languageAvailableForAdding.length && (React.createElement(React.Fragment, null,
            React.createElement(Divider, null),
            React.createElement(LanguageSelectorInput, { languages: languageAvailableForAdding, allowClear: false, onChange: (newLanguage) => onAddNewLanguage(newLanguage), value: '+ ' + t('PROPERTY_TRANSLATIONS_ADD_LANGUAGE') })))));
};
export default TranslationsSelectorTab;
