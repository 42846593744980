import { omit } from 'lodash';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { WORKFLOWS_API_PATH } from '../common/utils/constants';
import { invalidateProcessDefinitionQuery } from './invalidateCache';
const createProcessDefinition = (process) => ({
    type: 'PROCESS_DEFINITION__CREATE',
    payload: omit(process, ['definitionId']),
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process`,
            method: 'POST',
            allowPromiseReject: true,
            actionContext: {
                process
            }
        }
    }
});
const useCreateProcessDefinition = () => {
    const createProcessDefinitionDispatch = useDispatch();
    return useMutation({
        mutationFn: async (definition) => {
            return createProcessDefinitionDispatch(createProcessDefinition(definition));
        },
        onSettled: async (result, _error, _definition) => {
            if (result) {
                await invalidateProcessDefinitionQuery(result.definitionId);
            }
        }
    });
};
export default useCreateProcessDefinition;
