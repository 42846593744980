import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { PropertyTypes } from '@hypercharge/portal-utils';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDisplayComponentForType } from '~app/cms';
import { invalidateItemsQuery } from '~app/cms/hooks/invalidateCache';
import DropdownButton from '~app/common/components/DropdownButton';
import { InlineEditor } from '~app/common/components/InlineEditor';
import { TooltipTruncate } from '~app/common/components/TooltipTruncate';
import { ContactDisplayName } from '~app/crm';
import { useMyContact } from '~app/crm/components/use-my-contact';
import { ALL_TASKS_DEFINITION_ID } from '~app/workflows';
import { searchTaskExecutors } from '~app/workflows/processes/actions';
import AssigneeSearchComponent from '../AssigneeSearchComponent';
import styles from './AssigneeSelector.module.scss';
const AssigneeSelector = ({ assignTo, canEdit, inlineEditorClassName, popupClassName, disabled, processMetaId, processRunId, taskRunId, taskMetaId, value }) => {
    const { t } = useI18n();
    const { contactId: myContactId } = useMyContact();
    const [searching, setSearching] = useState(false);
    const [saving, setSaving] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [totalContacts, setTotalContacts] = useState(0);
    const unmounted = useRef(false);
    const dispatch = useDispatch();
    const getTaskExecutors = useCallback(() => dispatch(searchTaskExecutors({
        taskMetaId,
        taskRunId,
        processRunId,
        processMetaId
    })), [dispatch, processMetaId, processRunId, taskMetaId, taskRunId]);
    const _assignTo = useCallback(async (contactId) => {
        setSaving(true);
        try {
            await assignTo(contactId);
            void invalidateItemsQuery({
                definitionId: ALL_TASKS_DEFINITION_ID
            });
        }
        finally {
            if (!unmounted.current) {
                setSaving(false);
            }
        }
    }, [assignTo]);
    const assignToMe = useCallback(async () => {
        if (myContactId) {
            await _assignTo(myContactId);
        }
    }, [_assignTo, myContactId]);
    const assignAndSave = useCallback(async (contactId) => {
        if (typeof contactId !== 'string') {
            throw new Error('Invalid data type for contactId');
        }
        try {
            return _assignTo(contactId);
        }
        finally {
            if (!unmounted.current) {
                setSearching(false);
            }
        }
    }, [_assignTo]);
    const cancelSearch = useCallback(() => {
        setSearching(false);
    }, []);
    const handleMenuClick = useCallback(async ({ key }) => {
        switch (key) {
            case 'release':
                await _assignTo();
                break;
            case 'other':
                setSearching(true);
                break;
            default:
                await _assignTo(key);
                break;
        }
    }, [_assignTo]);
    const getMenu = useMemo(() => {
        const items = [];
        if (value) {
            items.push({
                key: 'release',
                label: React.createElement("div", { className: "fw-500" }, t('RELEASE_TASK'))
            });
        }
        if (value && contacts.length) {
            items.push({ type: 'divider' });
        }
        if (totalContacts) {
            const typeGroup = 'group';
            const assignTo = {
                key: 'assign-to',
                type: typeGroup,
                label: `${t('ASSIGN_TO')}:`,
                children: contacts
                    .filter(({ entityId }) => !value || value !== entityId)
                    .sort(({ entityId }) => (entityId === myContactId ? -1 : 0))
                    .map(({ entityId, title }) => ({
                    key: entityId,
                    label: (React.createElement(TooltipTruncate, { style: { maxWidth: '200px' } }, entityId === myContactId ? t('ASSIGN_TO_ME') : title))
                }))
            };
            if (totalContacts > contacts.length) {
                assignTo.children?.push({
                    key: 'other',
                    label: (React.createElement("div", { className: "fw-500" },
                        React.createElement("span", { className: "me-1" }, t('SEARCH_OTHERS')),
                        React.createElement("span", null,
                            "(",
                            totalContacts - contacts.length,
                            ")")))
                });
            }
            items.push(assignTo);
        }
        else {
            if (!value) {
                items.push({
                    key: 'no-contacts',
                    disabled: true,
                    label: (React.createElement("div", { style: { maxWidth: '20rem', color: 'var(--neutralColor-6)' }, className: "text-center" }, t('NO_CONTACTS_TO_ASSIGN_TASK_TO')))
                });
            }
        }
        return {
            onClick: handleMenuClick,
            items
        };
    }, [contacts, handleMenuClick, myContactId, t, totalContacts, value]);
    useEffect(() => {
        getTaskExecutors()
            .then(({ results, totalCount }) => {
            if (!unmounted.current) {
                setContacts(results);
                setTotalContacts(totalCount);
            }
        })
            .catch(console.error);
    }, [getTaskExecutors]);
    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);
    return searching ? (React.createElement(InlineEditor, { className: `w-100 ${inlineEditorClassName ? inlineEditorClassName : ''}`, displayComponent: getDisplayComponentForType(PropertyTypes.entity), editorComponent: AssigneeSearchComponent, value: '', autoFocus: true, canEdit: canEdit, expand: true, invalid: false, onSave: assignAndSave, onCancel: cancelSearch, immediatelySaveOnChange: true, extraEditorComponentProps: {
            taskMetaId,
            taskRunId,
            processRunId,
            processMetaId
        } })) : (React.createElement(DropdownButton, { className: `${popupClassName ? popupClassName : ''} ${value ? '' : styles.disabledAssigneeSelector}`, inversed: true, onClick: assignToMe, disabled: disabled || !!value || saving || !myContactId, menuDisabled: disabled, loading: saving, menu: getMenu }, value ? React.createElement(ContactDisplayName, { contactId: value }) : React.createElement("span", null, t('TAKE_TASK'))));
};
export default AssigneeSelector;
