import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { AggregationType, PropertyTypes } from '@hypercharge/portal-utils';
export function getDefaultMetric() {
    return {
        id: generateId(),
        type: AggregationType.count,
        field: 'entityId',
        inPercents: false
    };
}
export const MetricsOptions = [
    {
        labels: {
            en: 'Count values',
            nl: 'Tel waarden',
            de: 'Werte zählen',
            fr: 'Compter les valeurs',
            it: 'Contare i valori',
            pl: 'Zliczanie wartości'
        },
        value: AggregationType.count
    },
    {
        labels: {
            en: 'Count unique values',
            nl: 'Tel unieke waarden',
            de: 'Zählen Sie eindeutige Werte',
            fr: 'Compter des valeurs uniques',
            it: 'Contare valori unici',
            pl: 'Zliczanie unikalnych wartości'
        },
        value: AggregationType.uniqCount
    },
    {
        labels: {
            en: 'Sum',
            nl: 'Som',
            de: 'Summe',
            fr: 'Somme',
            it: 'Somma',
            pl: 'Suma'
        },
        value: AggregationType.sum,
        propertyTypes: [PropertyTypes.number]
    },
    {
        labels: {
            en: 'Average',
            nl: 'Gemiddelde',
            de: 'Durchschnittlich',
            fr: 'Moyenne',
            it: 'Media',
            pl: 'Średnia'
        },
        value: AggregationType.avg,
        propertyTypes: [PropertyTypes.number]
    },
    {
        labels: {
            en: 'Min',
            nl: 'Min',
            de: 'Min',
            fr: 'Min',
            it: 'Min',
            pl: 'Min'
        },
        value: AggregationType.min,
        propertyTypes: [PropertyTypes.number]
    },
    {
        labels: {
            en: 'Max',
            nl: 'Max',
            de: 'Max',
            fr: 'Max',
            it: 'Massimo',
            pl: 'Max'
        },
        value: AggregationType.max,
        propertyTypes: [PropertyTypes.number]
    }
];
export function isAvailableMetricField(metricType, displayData) {
    const metricOption = MetricsOptions.find((item) => item.value === metricType);
    if (!metricOption?.propertyTypes) {
        return true;
    }
    return metricOption.propertyTypes.includes(displayData.type);
}
