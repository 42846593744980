import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { isEqual, keyBy, pick, sortBy } from 'lodash';
import { parse, stringify } from 'qs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import RGL, { WidthProvider as widthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { useSelector } from 'react-redux';
import 'react-resizable/css/styles.css';
import { useLocation } from 'react-router';
import { hasTenantPermission } from '../../../auth';
import { PERMISSIONS_PROPERTY_ID } from '../../../cms/common/utils/constants';
import { DEFAULT_RELATIVE_VALUE } from '../../../common/components/DateRangeSelector/constants';
import { getRelativeDateInAbsoluteValue } from '../../../common/components/DateRangeSelector/utils';
import ShareDrawer from '../../../common/components/ShareDrawer';
import { HYPER_BI_PERMISSION } from '../../constants';
import DashboardControlPanel from './DashboardControlPanel';
import styles from './DashboardView.module.scss';
import { Widget } from './Widget';
import YourTasksWidget from './YourTasks/YourTasksWidget';
import ActivityWidget from './activity/ActivityWidget';
import ChartWidget from './chart/ChartWidget';
import TableWidget from './table/TableWidget';
const ReactGridLayout = widthProvider(RGL);
const MAX_COLS = 10;
const MARGIN = [10, 10];
const PERMISSION_FOR_PROPERTIES = ['configuration'];
const DashboardView = ({ className, dashboard, onChange, isDirty, isActionCreate, onDelete, onSave, onAddWidget, onEditWidget, onDeleteWidget, canEdit, canDelete, canCreate, canShare, onLocationChange, contentHeight }) => {
    const { t } = useI18n();
    const { hash, search, pathname, state } = useLocation();
    const [isShowShareDrawer, setIsShowShareDrawer] = useState(false);
    const hasHyperBiPermission = useSelector((s) => hasTenantPermission(s, HYPER_BI_PERMISSION));
    const [globalFilter, setGlobalFilter] = useState(() => {
        const filterParam = parse(hash || search, {
            ignoreQueryPrefix: true
        })?.filter;
        if (typeof filterParam !== 'string') {
            return { rangePicker: DEFAULT_RELATIVE_VALUE };
        }
        const filter = JSON.parse(filterParam);
        filter.rangePicker = filter.rangePicker || DEFAULT_RELATIVE_VALUE;
        return filter;
    });
    const getDefaultFilterValues = useCallback(() => {
        const [startTime, endTime] = getRelativeDateInAbsoluteValue(globalFilter.rangePicker || DEFAULT_RELATIVE_VALUE);
        if (!startTime && !endTime) {
            return;
        }
        return {
            startTime,
            endTime
        };
    }, [globalFilter.rangePicker]);
    const [defaultFilter, setDefaultFilter] = useState(getDefaultFilterValues);
    const onApplyGlobalFilter = useCallback((filter, withChange = true) => {
        setGlobalFilter((prevValue) => {
            if (isEqual(prevValue, filter)) {
                return prevValue;
            }
            return filter;
        });
        if (onLocationChange) {
            const oldLocation = {
                search,
                pathname,
                state,
                hash
            };
            const newLocation = {
                search,
                pathname,
                state,
                hash: stringify({
                    ...parse(hash.startsWith('#') ? hash.slice(1) : hash, {
                        ignoreQueryPrefix: true
                    }),
                    filter: JSON.stringify(filter)
                })
            };
            if (!isEqual(oldLocation, newLocation)) {
                onLocationChange(newLocation);
            }
        }
        if (withChange && onChange) {
            onChange({
                ...dashboard,
                configuration: { ...dashboard.configuration, rangePicker: filter.rangePicker }
            });
        }
    }, [onLocationChange, onChange, search, pathname, state, hash, dashboard]);
    const onClickRefresh = useCallback(() => {
        setDefaultFilter(getDefaultFilterValues);
    }, [getDefaultFilterValues]);
    const layout = useMemo(() => {
        if (!dashboard.configuration?.charts) {
            return [];
        }
        return dashboard.configuration.charts.map((item) => {
            const defaultLayout = {
                minH: 4,
                minW: ['activity', 'table', 'your-tasks'].includes(item.type) ? 3 : 1
            };
            if (item.layout) {
                return { ...item.layout, ...defaultLayout };
            }
            return { i: item.id, x: 0, y: 0, w: 2, h: 4, ...defaultLayout };
        });
        // dashboard.configuration?.charts  - reset button not working
    }, [dashboard]);
    const onLayoutChange = useCallback((newLayout) => {
        const chartsMap = keyBy(dashboard.configuration?.charts, 'id');
        let dirty = false;
        for (const layout of newLayout) {
            const newLayout = pick(layout, ['i', 'x', 'y', 'w', 'h']);
            if (!isEqual(chartsMap[layout.i].layout, newLayout)) {
                chartsMap[layout.i].layout = newLayout;
                dirty = true;
            }
        }
        if (dirty && onChange) {
            onChange({ ...dashboard });
        }
    }, [dashboard, onChange]);
    const handleShare = useCallback(async (permissions) => {
        if (onSave) {
            await onSave({ ...dashboard, [PERMISSIONS_PROPERTY_ID]: permissions });
            setIsShowShareDrawer(false);
        }
    }, [onSave, dashboard]);
    const onChangeTitle = useCallback(async (title) => {
        if (onChange) {
            onChange({ ...dashboard, title: title || '' });
        }
        if (onSave) {
            await onSave({ ...dashboard, title: title || '' });
        }
    }, [dashboard, onChange, onSave]);
    const onClickMenuItem = useCallback((action, widget) => {
        switch (action) {
            case 'edit':
                if (onEditWidget) {
                    onEditWidget(widget);
                }
                break;
            case 'delete':
                if (onDeleteWidget) {
                    onDeleteWidget(dashboard, widget);
                }
                break;
        }
    }, [dashboard, onDeleteWidget, onEditWidget]);
    const handleDelete = useCallback(() => {
        if (onDelete) {
            return onDelete(dashboard);
        }
    }, [onDelete, dashboard]);
    const handleSave = useCallback(() => {
        if (onSave) {
            void onSave(dashboard);
        }
    }, [onSave, dashboard]);
    const toggleShowShareDrawer = useCallback(() => {
        setIsShowShareDrawer((prevValue) => !prevValue);
    }, []);
    useEffect(() => {
        if (!isDirty) {
            onApplyGlobalFilter({
                ...globalFilter,
                rangePicker: dashboard.configuration?.rangePicker || DEFAULT_RELATIVE_VALUE
            }, false);
        }
    }, [
        isDirty,
        dashboard.entityId,
        onApplyGlobalFilter,
        globalFilter,
        dashboard.configuration?.rangePicker
    ]);
    useEffect(() => {
        setDefaultFilter(getDefaultFilterValues);
    }, [getDefaultFilterValues]);
    const chartsStyles = useMemo(() => {
        return {
            height: contentHeight
        };
    }, [contentHeight]);
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''}` },
        React.createElement(ShareDrawer, { title: t('HYPER_BI_SHARE'), open: isShowShareDrawer, permissions: dashboard.rowPermissions, onSave: handleShare, permissionForProperties: PERMISSION_FOR_PROPERTIES, onClose: toggleShowShareDrawer }),
        React.createElement("div", { className: "ms-2" },
            React.createElement(DashboardControlPanel, { dashboard: dashboard, onClickApply: onApplyGlobalFilter, onClickRefresh: onClickRefresh, isDirty: isDirty, value: globalFilter, onClickDelete: handleDelete, onClickSave: handleSave, onClickShare: toggleShowShareDrawer, onClickAddWidget: onAddWidget, onUpdateTitle: onChangeTitle, canEdit: canEdit, canDelete: canDelete && !isActionCreate, canCreate: canCreate, canShare: canShare })),
        React.createElement("div", { className: "charts", style: chartsStyles },
            React.createElement(ReactGridLayout, { className: "layout", layout: layout, cols: MAX_COLS, isResizable: true, isDraggable: true, isBounded: true, margin: MARGIN, rowHeight: 50, allowOverlap: false, useCSSTransforms: true, onLayoutChange: onLayoutChange, draggableHandle: ".drag-handler" },
                !dashboard.configuration?.charts?.length && React.createElement("div", null, t('HYPER_BI_NO_CHARTS')),
                sortBy(dashboard.configuration?.charts || [], [
                    (item) => item.layout?.y,
                    (item) => item?.layout?.x
                ]).map((settings) => {
                    if (settings.type === 'activity') {
                        return (React.createElement("div", { key: settings.id },
                            React.createElement(Widget, { className: "widget-wrapper w-100 h-100", onClickMenu: (action) => {
                                    return onClickMenuItem(action, settings);
                                }, showControlButtons: canEdit },
                                React.createElement(ActivityWidget, { className: "w-100 h-100", settings: settings }))));
                    }
                    else if (settings.type === 'table') {
                        return (React.createElement("div", { key: settings.id },
                            React.createElement(Widget, { className: "widget-wrapper w-100 h-100", onClickMenu: (action) => {
                                    return onClickMenuItem(action, settings);
                                }, showControlButtons: canEdit },
                                React.createElement(TableWidget, { className: "w-100 h-100", defaultFilter: defaultFilter, settings: settings }))));
                    }
                    else if (settings.type === 'your-tasks') {
                        return (React.createElement("div", { key: settings.id },
                            React.createElement(Widget, { className: "widget-wrapper w-100 h-100", onClickMenu: (action) => {
                                    return onClickMenuItem(action, settings);
                                }, showControlButtons: canEdit },
                                React.createElement(YourTasksWidget, { settings: settings, defaultFilter: defaultFilter, className: "w-100 h-100" }))));
                    }
                    else {
                        return (React.createElement("div", { key: settings.id },
                            React.createElement(Widget, { className: "widget-wrapper w-100 h-100", onClickMenu: (action) => {
                                    return onClickMenuItem(action, settings);
                                }, showControlButtons: canEdit }, hasHyperBiPermission ? (React.createElement(ChartWidget, { className: "w-100 h-100", defaultFilter: defaultFilter, settings: settings })) : (React.createElement(React.Fragment, null,
                                React.createElement("img", { src: "/assets/images/no-permissions.svg", className: "m-0 mx-auto no-permissions-img", alt: "no-permissions-img" }),
                                React.createElement("div", { className: "mt-4 px-4 text-center no-permissions-txt fw-600" }, t('HYPER_BI_NO_PERMISSIONS')))))));
                    }
                })))));
};
export default DashboardView;
