import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { formatDateTime, ReadableDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { Tooltip } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { FaCube, FaTasks } from 'react-icons/fa';
import { GoDatabase } from 'react-icons/go';
import { IoMdGitNetwork } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { CMS_PATH, FEATURED_ENTITIES_PATH } from '../../../cms';
import { getFeaturedDefinitionIdsOneLevel, getView } from '../../../cms/data/utils';
import useDisplayItemMeta from '../../../cms/hooks/useDisplayItemMeta';
import { CONTACT_CMS_DEFINITION_ID, ContactDisplayName } from '../../../crm';
import { useContact } from '../../../crm/components/use-contact';
import { SYSTEM_USER } from '../../../crm/constants';
import { CMS_KEY } from '../../../settings';
import { useViews } from '../../../views';
import { ACTIVITY_YOUR_DASHBOARD_PATH, FEATURED_PROCESSES_PATH, PROCESSES_PATH, WORKFLOWS_PATH, WORKFLOWS_YOUR_DASHBOARD_PATH } from '../../../workflows';
import { uniqViewIdActivityTaskDashboard, uniqViewIdWorkflowsTaskDashboard } from '../../../workflows/dashboard/constants';
import { TooltipTruncate } from '../../components/TooltipTruncate';
import { useBrowserBreakpoints } from '../../components/with-browser-breakpoints';
import { getMessageContentJsx } from '../../conversation/utils';
import { BROWSE_PATH, VIEW_PATH } from '../../utils/constants';
import { SharedEntityAction } from '../types';
import DeleteButton from './DeleteButton';
import MarkAsReadButton from './MarkAsReadButton';
const Notification = ({ notification, featuredDefinitionIds, onClick, onChange }) => {
    const { isMobile } = useBrowserBreakpoints();
    const { views } = useViews();
    const onReadNotification = useCallback(async () => {
        await onChange(notification.id, 'OPEN');
    }, [notification.id, onChange]);
    const onClickNotification = useCallback(async () => {
        if (!notification.openedAt) {
            await onReadNotification();
        }
        onClick();
    }, [notification.openedAt, onClick, onReadNotification]);
    const onDeleteNotification = useCallback(async () => {
        await onChange(notification.id, 'DELETE');
    }, [notification.id, onChange]);
    const message = useMemo(() => {
        switch (notification.relatedToType) {
            case 'conversation':
                return getMessageContentJsx(notification.message || '', true);
            case 'sharedView':
                return React.createElement(SharedViewMessage, { notification: notification });
            case 'sharedDashboard':
                return React.createElement(React.Fragment, null,
                    notification.title,
                    " ");
            default:
                return getMessageContentJsx(notification.taskComments || '', true);
        }
    }, [notification]);
    const typeNotification = useMemo(() => {
        switch (notification.relatedToType) {
            case 'conversation':
                return 'comment';
            case 'sharedView':
                return 'view';
            case 'sharedDashboard':
                return 'dashboard';
            default:
                return 'reminder';
        }
    }, [notification.relatedToType]);
    const eventDefinitionId = notification['eventDefinitionId'];
    const view = useMemo(() => getView(views, eventDefinitionId, notification.relatedToId), [eventDefinitionId, notification.relatedToId, views]);
    return (React.createElement("div", { className: "notificationItem" }, isMobile ? (React.createElement("div", { className: "mobile" },
        React.createElement(Link, { className: "link", to: getLink(notification, featuredDefinitionIds, view?.configuration?.availableOn), onClick: onClickNotification },
            React.createElement("div", { className: "cp-c-row cp-c-align-spacebetween-center cp-c-padding-2" },
                React.createElement("div", { className: "cp-i-none" },
                    React.createElement(NotificationIcon, { notification: notification })),
                React.createElement("div", { className: "titleContainer cp-i-flex cp-c-column" },
                    React.createElement("span", { className: "author cp-i-none" },
                        React.createElement(NotificationTitle, { notification: notification })),
                    React.createElement("span", { className: "dateTime cp-i-none" },
                        React.createElement(ReadableDateTime, { dateTime: notification.createdAt })))),
            React.createElement("div", { className: "message" }, message)),
        React.createElement("div", { className: "actionContainer" },
            React.createElement(MarkAsReadButton, { openedAt: notification.openedAt, onClick: onReadNotification })))) : (React.createElement("div", { className: "desktop cp-c-row cp-c-align-spacebetween-center" },
        React.createElement(Link, { className: "link cp-i-flex", to: getLink(notification, featuredDefinitionIds, view?.configuration?.availableOn), onClick: onClickNotification },
            React.createElement("div", { className: "cp-c-row cp-c-align-spacebetween-center cp-c-padding-h-2" },
                React.createElement("div", { className: "cp-i-none" },
                    React.createElement(NotificationIcon, { notification: notification })),
                React.createElement("div", { className: "flex-grow-1" },
                    React.createElement("div", { className: "d-flex justify-content-between align-items-center" },
                        React.createElement("span", { className: "author" },
                            React.createElement(NotificationTitle, { notification: notification })),
                        React.createElement("span", { className: "type" }, typeNotification)),
                    React.createElement("div", { className: "d-flex" },
                        React.createElement(Tooltip, { title: formatDateTime(notification.createdAt) },
                            React.createElement(TooltipTruncate, { className: "dateTime", title: formatDateTime(notification.createdAt) },
                                React.createElement(ReadableDateTime, { dateTime: notification.createdAt })))),
                    React.createElement("div", { className: "message" }, message)))),
        React.createElement("div", { className: "actionContainer cp-i-none" },
            React.createElement(MarkAsReadButton, { openedAt: notification.openedAt, onClick: onReadNotification }),
            React.createElement(DeleteButton, { deletedAt: notification.deletedAt, onDelete: onDeleteNotification }))))));
};
export default Notification;
const NotificationTitle = ({ notification }) => {
    const { t } = useI18n();
    switch (notification.relatedToType) {
        case 'conversation':
            return React.createElement(NotificationAuthor, { contactId: notification.authorId });
        case 'sharedView':
            return (React.createElement(React.Fragment, null,
                React.createElement(NotificationAuthor, { contactId: notification.actionContactId }),
                React.createElement("span", { className: "fw-normal" }, notification.action == SharedEntityAction.share
                    ? t('NOTIFICATIONS__VIEW_SHARED_TITLE', { contact: '' })
                    : t('NOTIFICATIONS__VIEW_SHARED_DELETED_TITLE', { contact: '' }))));
        case 'sharedDashboard':
            return (React.createElement(React.Fragment, null,
                React.createElement(NotificationAuthor, { contactId: notification.actionContactId }),
                React.createElement("span", { className: "fw-normal" }, notification.action == SharedEntityAction.share
                    ? t('NOTIFICATIONS__DASHBOARD_SHARED_TITLE', { contact: '' })
                    : t('NOTIFICATIONS__DASHBOARD_SHARED_DELETED_TITLE', { contact: '' }))));
        default:
            return React.createElement("span", null, notification.taskTitle);
    }
};
const NotificationAuthor = ({ contactId }) => {
    const { contact } = useContact(contactId);
    return contactId == SYSTEM_USER ? React.createElement(React.Fragment, null, SYSTEM_USER) : React.createElement(ContactDisplayName, { contact: contact });
};
const NotificationIcon = ({ notification }) => {
    switch (notification.relatedToType) {
        case 'conversation':
        case 'sharedView':
            if (notification.eventHandler == 'workflows') {
                return React.createElement(IoMdGitNetwork, null);
            }
            return React.createElement(GoDatabase, null);
        case 'sharedDashboard':
            return React.createElement(FaCube, null);
        case 'activity':
            return React.createElement(FaTasks, { size: "24px" });
        default:
            return React.createElement("div", { className: "iconPlaceholder" });
    }
};
const SharedViewMessage = ({ notification }) => {
    const { t } = useI18n();
    const { data: displayItemMeta } = useDisplayItemMeta({
        definitionId: notification.eventDefinitionId
    });
    return (React.createElement(React.Fragment, null, t('NOTIFICATIONS__VIEW_SHARED_MESSAGE', {
        view: notification.viewTitle,
        entity: displayItemMeta?.title
    })));
};
const getLink = ({ relatedToType, relatedToId, ...notification }, featuredDefinitionIds, availableOn) => {
    if (relatedToType == 'sharedDashboard') {
        return `/home/${relatedToId}`;
    }
    const messageHash = relatedToType == 'conversation' ? `#message_${relatedToId}` : '';
    let eventEntityId = notification['eventEntityId'];
    let eventDefinitionId = notification['eventDefinitionId'];
    let eventHandler = notification['eventHandler'];
    if (relatedToType == 'activity' && !eventHandler) {
        eventHandler = CMS_KEY;
        eventDefinitionId = CONTACT_CMS_DEFINITION_ID;
        eventEntityId = relatedToId;
    }
    const featured = eventDefinitionId
        ? getFeaturedDefinitionIdsOneLevel(featuredDefinitionIds).includes(eventDefinitionId)
        : false;
    if (!eventDefinitionId) {
        return '#';
    }
    const prefix = relatedToType == 'sharedView'
        ? notification['action'] == SharedEntityAction.share
            ? `${VIEW_PATH}/${relatedToId}`
            : ''
        : `${BROWSE_PATH}/${eventEntityId}${messageHash}`;
    const getCustomRoutePath = () => {
        if (availableOn?.length && prefix) {
            for (const uniqId of availableOn) {
                if (uniqId === uniqViewIdWorkflowsTaskDashboard) {
                    return `${WORKFLOWS_PATH}${WORKFLOWS_YOUR_DASHBOARD_PATH}${prefix}`;
                }
                if (uniqId === uniqViewIdActivityTaskDashboard) {
                    return `${WORKFLOWS_PATH}${ACTIVITY_YOUR_DASHBOARD_PATH}${prefix}`;
                }
            }
        }
        return;
    };
    switch (eventHandler) {
        case 'workflows':
            return (getCustomRoutePath() ||
                `${featured ? FEATURED_PROCESSES_PATH : `${WORKFLOWS_PATH}${PROCESSES_PATH}`}/${eventDefinitionId}${prefix}`);
        case CMS_KEY:
            return (getCustomRoutePath() ||
                `${featured ? FEATURED_ENTITIES_PATH : CMS_PATH}/${eventDefinitionId}${prefix}`);
        default:
            return '#';
    }
};
