import { Tooltip } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useContact } from '../../../../../../../crm/components/use-contact';
import { SYSTEM_USER } from '../../../../../../../crm/constants';
import { capitalize } from '../../../../../../../workflows/processes/components/process/StepsOverview';
import QuickEditItem from '../../../../item/QuickEditItem';
import styles from '../KanbanCard.module.scss';
export const CardAvatar = ({ entityOwner, isTask, assignedTo }) => {
    const { contact: assignedToContact } = useContact(assignedTo && assignedTo !== 'system' ? assignedTo : undefined);
    const { contact: ownerContact } = useContact(entityOwner);
    const [showQuickEditItem, setShowQuickEditItem] = useState(false);
    const onDrawerOpen = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowQuickEditItem(true);
    }, []);
    const onDrawerClose = useCallback(() => setShowQuickEditItem(false), []);
    const contact = useMemo(() => (isTask ? assignedToContact : ownerContact), [assignedToContact, isTask, ownerContact]);
    const { contactMonogram, isSystemUser } = useMemo(() => {
        if (!contact) {
            const isSystemUser = (isTask ? assignedTo : entityOwner) === SYSTEM_USER;
            return {
                contactMonogram: isSystemUser ? 'S' : '',
                isSystemUser
            };
        }
        const firstNameFirstLetter = contact?.firstName?.charAt(0) || '';
        const lastNameFirstLetter = contact?.lastName?.charAt(0) || '';
        const titleFirstLetter = contact?.title?.charAt(0) || '';
        const monogram = `${firstNameFirstLetter}${lastNameFirstLetter}`;
        return {
            contactMonogram: `${monogram || titleFirstLetter}`,
            isSystemUser: false
        };
    }, [contact, entityOwner, isTask, assignedTo]);
    return (React.createElement(React.Fragment, null, contactMonogram && (React.createElement(React.Fragment, null,
        contact && (React.createElement(QuickEditItem, { definitionId: contact.definitionId, entityId: contact.entityId, open: showQuickEditItem, onClose: onDrawerClose })),
        React.createElement(Tooltip, { title: isSystemUser ? capitalize(SYSTEM_USER) : contact ? contact.title : '', placement: "top" },
            React.createElement("button", { type: "button", className: `${styles.userAvatar} ${isSystemUser ? styles.userAvatar__systemUser : ''} d-flex justify-content-center align-items-center fw-600 border-0 `, onClick: onDrawerOpen }, contactMonogram))))));
};
