import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { compact } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { SELECTED_VIEWS_STORAGE_KEY } from '../../../../common/components/data-table/ViewSelector';
import { useStorage } from '../../../../common/context/StorageContext';
import { getEntityBrowseUrl, getEntityViewUrl } from '../../../../common/utils/url';
import { useMyContact } from '../../../../crm/components/use-my-contact';
import { useViews } from '../../../../views';
import { getStoredViewInLocalStorage, isDefaultViewId } from '../../../../views/utils';
import { SystemSectionsVisibilityProvider } from '../../../common/context/SystemSectionsVisibilityProvider';
import { CMS_PATH, TITLE_PROPERTY_ID } from '../../../common/utils/constants';
import { getPropertyById } from '../../../common/utils/utils';
import useDisplayData from '../../../hooks/useDisplayData';
import EditableItemPage from './EditableItemPage';
const blacklistedPropertyIds = [TITLE_PROPERTY_ID];
const hiddenSections = ['system'];
const EditableItemPageContainer = ({ contentHeight, definitionId, viewId, entityId, entityTitle, setLinks, setCurrentBreadcrumbText, setExtraCountForBreadcrumbsComponent, baseUrl = CMS_PATH, canDeleteItem }) => {
    const { t } = useI18n();
    const { contactId } = useMyContact();
    const { getViewBreadcrumbLink } = useViews();
    const [viewIdMapInStorage] = useStorage({
        key: SELECTED_VIEWS_STORAGE_KEY,
        defaultValue: {}
    });
    const { data: displayDataList, isLoading } = useDisplayData({ definitionId, entityId });
    const titleProperty = useMemo(() => getPropertyById(displayDataList, TITLE_PROPERTY_ID), [displayDataList]);
    useEffect(() => {
        if (setExtraCountForBreadcrumbsComponent) {
            setExtraCountForBreadcrumbsComponent(0);
        }
    }, [setExtraCountForBreadcrumbsComponent]);
    useEffect(() => {
        if (setCurrentBreadcrumbText && setLinks && baseUrl) {
            setCurrentBreadcrumbText(titleProperty?.value || (isLoading ? '...' : t('NO_TITLE')));
            const storedViewId = getStoredViewInLocalStorage({
                viewIdMapInStorage,
                contactId,
                definitionId
            });
            const toDefinitionLink = storedViewId && !isDefaultViewId(storedViewId)
                ? getEntityViewUrl(definitionId, storedViewId, baseUrl)
                : getEntityBrowseUrl(definitionId, baseUrl);
            setLinks(compact([
                { text: t('CMS_NAV_LINK'), to: CMS_PATH },
                { text: entityTitle, to: toDefinitionLink },
                getViewBreadcrumbLink(`${baseUrl}/${definitionId}`, entityTitle, viewId)
            ]));
        }
    }, [
        baseUrl,
        definitionId,
        entityTitle,
        getViewBreadcrumbLink,
        isLoading,
        setCurrentBreadcrumbText,
        setLinks,
        titleProperty,
        t,
        viewId,
        viewIdMapInStorage,
        contactId
    ]);
    return (React.createElement(SystemSectionsVisibilityProvider, { id: definitionId, hiddenSections: hiddenSections },
        React.createElement(EditableItemPage, { baseUrl: baseUrl, blacklistedPropertyIds: blacklistedPropertyIds, canDeleteItem: canDeleteItem, contentHeight: contentHeight, definitionId: definitionId, entityId: entityId, entityTitle: entityTitle, loading: isLoading })));
};
export default EditableItemPageContainer;
