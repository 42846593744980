import React, { useCallback } from 'react';
import { Triangle } from '../../../../../common/components/Triangle';
import { useBrowserBreakpoints } from '../../../../../common/components/with-browser-breakpoints';
import DataListContainer from '../DataListContainer';
import styles from './ExpandedView.module.scss';
const ExpandedView = ({ order, definitionId, properties, entityId, doubleCol = true, requiredFieldsConfig, className = '', fieldOnLeftSide, permissionFieldsConfig, updateNestedPropertyData, field }) => {
    const { isMobile } = useBrowserBreakpoints();
    const updatePropertyData = useCallback(async (propertyId, value) => {
        if (!updateNestedPropertyData || typeof field.value !== 'string') {
            return;
        }
        return updateNestedPropertyData(field.value, `${field.propertyId}.${propertyId}`, value);
    }, [field.propertyId, field.value, updateNestedPropertyData]);
    return (React.createElement("div", { style: { order: order }, className: `${className} ExpandedViewWrapper` },
        React.createElement("div", { className: `${styles.wrapper}  ${fieldOnLeftSide || !doubleCol ? `` : 'order-even'} ExpandedView p-2 mt-2 pt-0` },
            React.createElement(Triangle, { variant: "top" }),
            React.createElement(DataListContainer, { doubleCol: doubleCol, definitionId: definitionId, entityId: entityId, withoutReferrals: true, showRequiredFieldsSwitcher: false, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, expandedProperties: properties, showAllProperties: false, updatePropertyData: updateNestedPropertyData ? updatePropertyData : undefined }))));
};
export default ExpandedView;
