import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import ReactTagInput from '@pathofdev/react-tag-input';
import { isString } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { copyRemainingTags, parseTags } from './utils';
const FilterTagInput = ({ value, disabled, onChange, availableCountForFilters }) => {
    const notificationDispatch = useDispatch();
    const { t } = useI18n();
    const tags = useMemo(() => {
        return Array.isArray(value) ? value.filter(isString) || [] : [value].filter(isString);
    }, [value]);
    const handlePaste = useCallback((e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text');
        const newTags = parseTags(pastedText);
        const fullTags = newTags.slice(0, availableCountForFilters);
        const remainingTags = newTags.slice(availableCountForFilters);
        onChange([...tags, ...fullTags]);
        void copyRemainingTags(remainingTags, notificationDispatch, t, !availableCountForFilters);
    }, [availableCountForFilters, notificationDispatch, onChange, t, tags]);
    const _onChange = (newTags) => {
        if (!Math.max(availableCountForFilters, 0) && tags.length < newTags.length) {
            notificationDispatch(error({
                title: t('LIMIT_COUNT_FOR_FILTERS_TITLE'),
                message: t('LIMIT_COUNT_FOR_FILTER_VALUE')
            }));
            return;
        }
        onChange(newTags);
    };
    return (React.createElement("div", { className: "ant-input h-auto d-block pe-0", onPaste: handlePaste },
        React.createElement(ReactTagInput, { tags: tags || [], placeholder: t('ADD_MULTIPLE_TEXT_VALUES_PLACEHOLDER'), editable: true, readOnly: disabled, removeOnBackspace: true, onChange: _onChange })));
};
export default FilterTagInput;
