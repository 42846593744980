import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import useBackForwardItem from '../../hooks/useBackForwardItem';
import styles from './BackForwardButton.module.scss';
const BackForwardButton = ({ entityId, definitionId }) => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useI18n();
    const { isBackDisabled, isForwardDisabled, handleForward, handleBack, totalCount, numberIndex } = useBackForwardItem({
        entityId,
        definitionId
    });
    const onBackClick = useCallback(async () => {
        const prevItem = await handleBack();
        let url = location.pathname.replace(entityId, prevItem.entityId);
        if (prevItem.definitionId !== definitionId && prevItem.definitionId) {
            url = url.replace(definitionId, prevItem.definitionId);
        }
        if (prevItem.link) {
            url = prevItem.link;
        }
        history.push(url, { ...prevItem.state });
    }, [handleBack, location.pathname, entityId, definitionId, history]);
    const onForwardClick = useCallback(async () => {
        const nextItem = await handleForward();
        let url = location.pathname.replace(entityId, nextItem.entityId);
        if (nextItem.definitionId !== definitionId && nextItem.definitionId) {
            url = url.replace(definitionId, nextItem.definitionId);
        }
        if (nextItem.link) {
            url = nextItem.link;
        }
        history.push(url, { ...nextItem.state });
    }, [handleForward, location.pathname, entityId, definitionId, history]);
    if (isBackDisabled && isForwardDisabled) {
        return null;
    }
    return (React.createElement("div", { className: "d-flex flex-nowrap align-items-center gap-2" },
        React.createElement("div", { className: cn(styles.numberOfPage, 'd-flex align-items-center text-nowrap gap-1') },
            React.createElement("span", null, numberIndex),
            React.createElement("span", null, t('OF')),
            React.createElement("span", null, totalCount)),
        React.createElement("div", { className: "overflow-hidden" },
            React.createElement(Tooltip, { title: t('CMS_PREVIOUS_ITEM'), placement: "top" },
                React.createElement(Button, { key: "back", disabled: isBackDisabled, inversed: true, onClick: onBackClick, className: cn(styles.buttonBack, 'action-button px-2') },
                    React.createElement(FaArrowLeft, { size: 16 })))),
        React.createElement("div", { className: "overflow-hidden" },
            React.createElement(Tooltip, { title: t('CMS_NEXT_ITEM'), placement: "top" },
                React.createElement(Button, { key: "forward", disabled: isForwardDisabled, inversed: true, onClick: onForwardClick, className: cn(styles.buttonForward, 'action-button px-2') },
                    React.createElement(FaArrowRight, { size: 16 }))))));
};
export default BackForwardButton;
