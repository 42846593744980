import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import queryClient, { oneHourInMs, oneMinuteInMs } from '../../common/utils/react-query-client';
import { fetchDisplayProcessMetaList } from '../dashboard/actions';
const DISPLAY_PROCESS_META_LIST_KEY = 'DISPLAY_PROCESS_META_LIST_KEY';
export const invalidateDisplayProcessMetaListCache = () => {
    void queryClient.invalidateQueries(DISPLAY_PROCESS_META_LIST_KEY);
};
const useDisplayProcessMetaList = () => {
    const { language } = useI18n();
    const dispatch = useDispatch();
    const { data = [], isLoading, isError } = useQuery([DISPLAY_PROCESS_META_LIST_KEY, language], async () => dispatch(fetchDisplayProcessMetaList(language)), {
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs
    });
    return useMemo(() => {
        return {
            data,
            isPending: isLoading,
            isFailed: isError,
            isAvailable: !isEmpty(data)
        };
    }, [data, isError, isLoading]);
};
export default useDisplayProcessMetaList;
