import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { isString } from 'lodash';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FEATURED_ENTITIES_PATH, TITLE_PROPERTY_ID } from '../../../../../cms';
import { useEntityDisplayData } from '../../../../../cms/common/components/withEntityDisplayData';
import { getPropertyById } from '../../../../../cms/common/utils/utils';
import EntityDisplay from '../../../../../cms/data/components/item-property/display/EntityDisplay';
import { ACTIVITY_DEFINITION_ID, useLoadActivityTypes } from '../../../../../common/activity';
import ActivityIcon from '../../../../../common/activity/components/ActivityList/ActivityIcon';
import { replaceConditionFromContextDataInFilters } from '../../../../../common/components/ConditionQuery/utils';
import { useHeight } from '../../../../../common/components/height-context/HeightContext';
import { useView } from '../../../../../views/components/ViewContext';
import { Assignee } from '../../../../common/components/Assignee';
import { DisplayTaskDeadline } from '../../../../common/components/DisplayTaskDeadline';
import styles from '../TasksTableWrapper/TasksTableWrapper.module.scss';
var TasksTableSize;
(function (TasksTableSize) {
    TasksTableSize["large"] = "large";
    TasksTableSize["small"] = "small";
})(TasksTableSize || (TasksTableSize = {}));
const ActivityTasksTableList = ({ getCustomRouteHandleTaskClick, emptyText, size = TasksTableSize.large }) => {
    const { contentHeight } = useHeight();
    const { t, language } = useI18n();
    const history = useHistory();
    const { definitionId, filterRequestForSearch, searchItems: { data: { results: tasks = [], totalCount = 0 } = {}, isPending, isFailed: isError } } = useView();
    const entityIds = useMemo(() => tasks.map((task) => task.entityId), [tasks]);
    const definitionIds = useMemo(() => tasks.map((task) => task.definitionId).filter(isString), [tasks]);
    const { displayData } = useEntityDisplayData(definitionId);
    const titleProperty = getPropertyById(displayData, TITLE_PROPERTY_ID);
    const titleTranslatedKey = titleProperty?.meta.translations?.[language];
    const { activityTypes } = useLoadActivityTypes();
    const activityTypeMap = useMemo(() => {
        return activityTypes.reduce((map, activityType) => {
            return {
                ...map,
                [activityType.entityId]: activityType
            };
        }, {});
    }, [activityTypes]);
    return (React.createElement("div", { className: `${styles.container} overflow-y-auto custom-scroll-styling`, style: { height: contentHeight } }, isError ? (React.createElement("div", { className: `${styles.simpleRow} p-4` },
        React.createElement("i", null, t('SOMETHING_WENT_WRONG')))) : isPending ? (React.createElement("div", { className: styles.loader },
        React.createElement("div", { className: styles.loader__container },
            React.createElement(LoadingRectangles, null)))) : tasks.length > 0 ? (React.createElement(React.Fragment, null, tasks.map((task) => (React.createElement("div", { key: task.entityId, className: styles.task__item },
        React.createElement("div", { role: "button", className: `${styles.task__link} ${size === TasksTableSize.small ? styles.task__link_smallSize : ''}`, onClick: async (e) => {
                e.preventDefault();
                try {
                    const asyncPath = (await getCustomRouteHandleTaskClick?.(task)) ||
                        [FEATURED_ENTITIES_PATH, ACTIVITY_DEFINITION_ID, task.entityId].join('/');
                    if (e.metaKey || e.ctrlKey) {
                        window.open(asyncPath, '_blank')?.focus();
                    }
                    else {
                        history.push(asyncPath, {
                            definitionId: ACTIVITY_DEFINITION_ID,
                            data: { entityIds, definitionIds, totalCount },
                            filterRequest: {
                                ...filterRequestForSearch,
                                query: replaceConditionFromContextDataInFilters(filterRequestForSearch.query, filterRequestForSearch.context)
                            },
                            isTaskDefinition: true
                        });
                    }
                }
                catch (error) {
                    console.error('Error fetching data:', error);
                }
            } },
            size === TasksTableSize.large && task.typeText && (React.createElement("div", { className: styles.task__status }, React.createElement(ActivityIcon, { activityType: activityTypeMap[task.typeText] }))),
            React.createElement("div", { className: styles.task__info },
                React.createElement("div", { className: `${styles.task__infoTop} ${size === TasksTableSize.small ? styles.task__infoTop_smallSize : ''}` },
                    React.createElement("div", { className: styles.task__title }, (titleTranslatedKey && task[titleTranslatedKey]) || task.title),
                    task.assignedTo && (React.createElement(Assignee, { className: size === TasksTableSize.small ? styles.task__assignee : '', contactId: task.assignedTo }))),
                React.createElement("div", { className: styles.task__infoBottom },
                    React.createElement("div", { className: styles.task__processInfo, onClick: (event) => {
                            event.stopPropagation();
                        } }, task.relatedToEntityId && task.relatedToDefinitionId ? (React.createElement(EntityDisplay, { value: task.relatedToEntityId, meta: { definitionId: task.relatedToDefinitionId } })) : null),
                    React.createElement("div", { className: styles.task__deadline },
                        React.createElement(DisplayTaskDeadline, { taskDeadline: task.dueDate })))))))))) : (React.createElement("div", { className: `${styles.simpleRow} p-4` },
        React.createElement("i", null, emptyText)))));
};
export default ActivityTasksTableList;
