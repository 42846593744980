import { t, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { AutoComplete, Table } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FormikField from '../../../../common/components/formik/FormikField';
import { fetchPreviewByModuleId } from './actions';
const XDMSModuleToXdmsForm = ({ dispatch, moduleId, disabled, selectedEntityDefinitionId, entityProperties, xdmsSchema, mappings = {} }) => {
    const [previews, setPreviews] = useState([]);
    const [countLines, setCountLines] = useState(0);
    const { t } = useI18n();
    const updatePreview = useCallback((definitionId, mappings) => dispatch(fetchPreviewByModuleId(definitionId, moduleId, mappings))
        // @ts-expect-error
        .then(setPreviews), [dispatch, moduleId]);
    const timer = useRef(null);
    useEffect(() => {
        if (selectedEntityDefinitionId && !isEmpty(mappings)) {
            if (timer.current) {
                clearTimeout(timer.current);
                timer.current = null;
            }
            timer.current = setTimeout(() => {
                updatePreview(selectedEntityDefinitionId, mappings);
            }, 500);
        }
    }, [mappings, selectedEntityDefinitionId, updatePreview]);
    const propertyOptions = useMemo(() => entityProperties.map((property) => ({
        value: property.propertyId,
        label: property.title
    })), [entityProperties]);
    const tableData = useMemo(() => {
        let index = 0;
        const data = [];
        const entries = Object.entries(xdmsSchema);
        for (const [xdmsProperty, xdmsPropertyValue] of entries) {
            const propertyMap = {
                property: '',
                mapping: {}
            };
            if (mappings[xdmsProperty] && typeof mappings[xdmsProperty] !== 'string') {
                Object.assign(propertyMap, mappings[xdmsProperty]);
            }
            if (xdmsPropertyValue.type === 'array' &&
                xdmsPropertyValue.ablType === 'object' &&
                xdmsPropertyValue.ablFormat) {
                data.push(lineData({
                    index,
                    name: `mappings.toXdms.${xdmsProperty}.property`,
                    value: propertyMap.property,
                    disabled,
                    propertyOptions,
                    xdmsName: xdmsProperty,
                    preview: ''
                }));
                for (const xdmsSubProperty of Object.keys(xdmsPropertyValue.ablFormat)) {
                    index++;
                    const preview = (previews[xdmsProperty] && previews[xdmsProperty][0]) || {};
                    data.push(lineData({
                        index,
                        name: `mappings.toXdms.${xdmsProperty}.mapping.${xdmsSubProperty}`,
                        value: propertyMap.mapping[xdmsSubProperty] || '',
                        disabled,
                        propertyOptions,
                        xdmsName: `${xdmsProperty} / ${xdmsSubProperty}`,
                        preview: preview[xdmsSubProperty] || '',
                        style: { width: '90%', marginLeft: '10%' }
                    }));
                }
            }
            else if (xdmsPropertyValue.type === 'array' && xdmsPropertyValue.ablSize) {
                for (let i = 1; i <= xdmsPropertyValue.ablSize; i++) {
                    data.push(lineData({
                        index,
                        name: `mappings.toXdms.${xdmsProperty}(${i})`,
                        value: mappings[`${xdmsProperty}(${i})`] || '',
                        disabled,
                        propertyOptions,
                        xdmsName: `${xdmsProperty}(${i})`,
                        preview: previews[`${xdmsProperty}(${i})`]
                    }));
                    index++;
                }
            }
            else {
                data.push(lineData({
                    index,
                    name: `mappings.toXdms.${xdmsProperty}`,
                    value: mappings[xdmsProperty] || '',
                    disabled,
                    propertyOptions,
                    xdmsName: xdmsProperty,
                    preview: previews[xdmsProperty]
                }));
            }
            index++;
        }
        setCountLines(index);
        return data;
    }, [xdmsSchema, previews, mappings, propertyOptions, disabled]);
    return (React.createElement(Table, { pagination: {
            total: countLines,
            pageSize: countLines,
            hideOnSinglePage: true
        }, dataSource: tableData, columns: [
            {
                title: t('INTEGRATIONS_XDMS_MODULE_CMS_PROPERTY'),
                dataIndex: 'cmsProperty'
            },
            {
                title: t('INTEGRATIONS_XDMS_MODULE_XDMS_PROPERTY'),
                dataIndex: 'xdmsProperty',
                width: 150
            },
            {
                title: t('INTEGRATIONS_XDMS_MODULE_PREVIEW'),
                dataIndex: 'preview',
                width: 200
            }
        ], style: { marginTop: 20 } }));
};
export default XDMSModuleToXdmsForm;
const lineData = ({ index, name, value, disabled, propertyOptions, xdmsName, preview, style = {} }) => {
    return {
        key: index,
        cmsProperty: (React.createElement(FormikField, { name: name, value: value, backfill: true, component: AutoComplete, disabled: disabled, style: { width: '100%', ...style }, placeholder: t('INTEGRATIONS_XDMS_MODULE_SELECT_PROPERTY'), options: propertyOptions })),
        xdmsProperty: xdmsName,
        preview: preview
    };
};
