import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Alert } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Drawer, DrawerContent } from '../../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../../common/components/Drawer/DrawerContentBlock';
import { SELECTED_VIEWS_STORAGE_KEY } from '../../../../../common/components/data-table/ViewSelector';
import { StyledLink } from '../../../../../common/components/data-table/utils';
import { useStorage } from '../../../../../common/context/StorageContext';
import ConversationContainer from '../../../../../common/conversation/components/ConversationContainer';
import { getConfigsState } from '../../../../../common/reducers/rootSelector';
import { PROCESS_META_HANDLER } from '../../../../../common/utils/constants';
import { getEntityBrowseUrl, getEntityViewUrl } from '../../../../../common/utils/url';
import { getConfig } from '../../../../../configs';
import { useMyContact } from '../../../../../crm/components/use-my-contact';
import { GROUPS_PATH, GROUP_CMS_DEFINITION_ID } from '../../../../../groups';
import { nestedFeaturedCmsDefinitionIds } from '../../../../../header';
import { CMS_KEY, SETTINGS_KEY } from '../../../../../settings';
import { getStoredViewInLocalStorage, isDefaultViewId } from '../../../../../views/utils';
import { FEATURED_PROCESSES_PATH, PROCESSES_PATH, WORKFLOWS_PATH } from '../../../../../workflows';
import { SystemSectionsVisibilityProvider } from '../../../../common/context/SystemSectionsVisibilityProvider';
import { CMS_ITEM_CONVERSATION_NAMESPACE, CMS_PATH, FEATURED_ENTITIES_PATH, TITLE_PROPERTY_ID } from '../../../../common/utils/constants';
import useDisplayData from '../../../../hooks/useDisplayData';
import useDisplayItemMeta from '../../../../hooks/useDisplayItemMeta';
import { getFeaturedDefinitionIdsOneLevel } from '../../../utils';
import DataListContainer from '../../item-property/DataListContainer';
import { EditableItemTitleContainer } from '../EditableItemTitleContainer';
import styles from './QuickEditItem.module.scss';
const blacklistedItemDrawerPropertyIds = [TITLE_PROPERTY_ID];
const hiddenSections = ['system'];
const QuickEditItem = ({ entityId, definitionId, onClose, open, requiredFieldsConfig, permissionFieldsConfig, updatePropertyData }) => {
    const { t } = useI18n();
    const { contactId } = useMyContact();
    const [viewIdMapInStorage] = useStorage({
        key: SELECTED_VIEWS_STORAGE_KEY,
        defaultValue: {}
    });
    const { data: displayItemMeta } = useDisplayItemMeta({ definitionId });
    const isProcess = useMemo(() => get(displayItemMeta, 'handler') === PROCESS_META_HANDLER, [displayItemMeta]);
    const { isLoading: isEntityDisplayDataLoading } = useDisplayData({ definitionId, entityId });
    const isFeaturedEntity = useSelector((s) => {
        const config = getConfig(getConfigsState(s), SETTINGS_KEY, CMS_KEY);
        const featuredDefinitionIds = config?.data?.featuredDefinitionIds || [];
        return nestedFeaturedCmsDefinitionIds
            .concat(getFeaturedDefinitionIdsOneLevel(featuredDefinitionIds))
            .includes(definitionId);
    });
    const getItemDetailsPagePath = useCallback((id) => {
        if (definitionId === GROUP_CMS_DEFINITION_ID) {
            return [GROUPS_PATH, id].join('/');
        }
        const featuredPath = isProcess ? FEATURED_PROCESSES_PATH : FEATURED_ENTITIES_PATH;
        const commonPath = isProcess ? `${WORKFLOWS_PATH}${PROCESSES_PATH}` : CMS_PATH;
        const storedViewId = getStoredViewInLocalStorage({
            viewIdMapInStorage,
            contactId,
            definitionId
        });
        const to = storedViewId && !isDefaultViewId(storedViewId)
            ? getEntityViewUrl(definitionId, storedViewId, isFeaturedEntity ? featuredPath : commonPath)
            : getEntityBrowseUrl(definitionId, isFeaturedEntity ? featuredPath : commonPath);
        return `${to}/${id}`;
    }, [contactId, definitionId, isFeaturedEntity, isProcess, viewIdMapInStorage]);
    const onDrawerClose = useCallback(() => {
        onClose && onClose();
    }, [onClose]);
    return (React.createElement(SystemSectionsVisibilityProvider, { id: definitionId, hiddenSections: hiddenSections },
        React.createElement(Drawer, { onClose: onDrawerClose, onClick: (e) => e.stopPropagation(), open: open, destroyOnClose: true },
            React.createElement(DrawerContent, { loading: isEntityDisplayDataLoading },
                React.createElement(DrawerContentBlock, null,
                    React.createElement(EditableItemTitleContainer, { className: styles.title, definitionId: definitionId, entityId: entityId }),
                    React.createElement(Alert, { message: React.createElement(StyledLink, { to: getItemDetailsPagePath(entityId), title: t('CMS_GO_TO_DETAILS_PAGE') }, t('CMS_GO_TO_DETAILS_PAGE')), type: "info" }),
                    React.createElement(DataListContainer, { definitionId: definitionId, entityId: entityId, blacklistedPropertyIds: blacklistedItemDrawerPropertyIds, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, withoutReferrals: true, updatePropertyData: updatePropertyData })),
                React.createElement(DrawerContentBlock, { title: t('COMMENTS') },
                    React.createElement(ConversationContainer, { conversationNamespace: [CMS_ITEM_CONVERSATION_NAMESPACE, definitionId].join('__'), conversationId: entityId, messagePlaceholder: t('COMMENTS_PLACEHOLDER') }))))));
};
export default QuickEditItem;
