import { invalidateRetrieveAccountsQuery } from '~app/auth/hooks';
import { invalidateEntityDisplayDataQueries } from '~app/cms/common/components/withEntityDisplayData';
import { invalidateColumnsCountQuery } from '~app/cms/data/components/items/Kanban/KanbanListHeader/KanbanListHeader';
import { invalidateAggregationQuery } from '~app/cms/hooks/useAggregation';
import { invalidateDefinitionCache } from '~app/cms/hooks/useDefinition';
import { invalidateDisplayDataQuery } from '~app/cms/hooks/useDisplayData';
import { invalidateDisplayItemMetaListCache } from '~app/cms/hooks/useDisplayItemMetaList';
import { invalidateEntityItemsQuery } from '~app/cms/hooks/useEntityItems';
import { invalidateHistoryDataQuery } from '~app/cms/hooks/useHistoryData';
import { invalidateSearchItemsQuery, removeInfiniteSearchItemsQuery } from '~app/cms/hooks/useSearchItems';
import { invalidateDefinitionHistoryQuery } from '~app/cms/settings/components/entity/History/hooks/useDefinitionHistory';
import { sleep } from '~app/utils/time';
export const invalidateItemsQuery = async ({ definitionId, ids, changedPropertyIds, waitNewData }) => {
    await invalidateEntityItemsQuery({
        definitionId,
        entityIds: ids,
        changedPropertyIds
    });
    await invalidateDisplayDataQuery({ definitionId, entityIds: ids });
    await invalidateHistoryDataQuery({ definitionId, entityIds: ids });
    if (waitNewData) {
        await sleep(1000); // We wait few seconds to allow ElasticSearch to reindex
    }
    await invalidateDefinitionHistoryQuery(definitionId);
    await invalidateSearchItemsQuery({ definitionId });
    await invalidateAggregationQuery({ definitionId });
    await invalidateColumnsCountQuery({ definitionId });
    await removeInfiniteSearchItemsQuery({ definitionId });
    await invalidateRetrieveAccountsQuery({ definitionId });
};
export const invalidateDefinitionQuery = async (definitionId) => {
    if (definitionId) {
        await invalidateDefinitionCache(definitionId);
        invalidateEntityDisplayDataQueries(definitionId);
        await invalidateItemsQuery({ definitionId });
    }
    await invalidateDisplayItemMetaListCache();
};
