import { ConditionType } from '@hypercharge/portal-utils';
import { INITIAL_PAGE_SIZE } from 'config';
import { useCallback, useMemo } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { TITLE_PROPERTY_ID, searchItems } from '~app/cms';
import queryClient, { oneHourInMs, oneMinuteInMs } from '~app/common/utils/react-query-client';
import { DEFAULT_SORT_BY_FIELDS_VIEW, initialFilterRequest } from '~app/views';
export const ENTITY_ITEMS_QUERY_KEY = 'entity-items';
export const INFINITE_ENTITY_ITEMS_QUERY_KEY = 'infinite-entity-items';
export const MAX_ITEMS_CAN_BE_FETCHED = 10000;
export const invalidateSearchItemsQuery = async ({ definitionId }) => {
    await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(ENTITY_ITEMS_QUERY_KEY) && query.queryKey.includes(definitionId)
    });
};
export const removeInfiniteSearchItemsQuery = async ({ definitionId, queryKey }) => queryClient.removeQueries({
    predicate: (query) => query.queryKey.includes(INFINITE_ENTITY_ITEMS_QUERY_KEY) &&
        query.queryKey.includes(definitionId) &&
        (queryKey ? query.queryKey.includes(queryKey) : true)
});
export const invalidateInfiniteSearchItemsQuery = async ({ definitionId, queryKey }) => queryClient.invalidateQueries({
    predicate: (query) => query.queryKey.includes(INFINITE_ENTITY_ITEMS_QUERY_KEY) &&
        query.queryKey.includes(definitionId) &&
        (queryKey ? query.queryKey.includes(queryKey) : true)
});
const fetchData = (filterRequest, dispatch, definitionId) => {
    if (!definitionId || !filterRequest) {
        throw new Error('Definition ID and filter request are required');
    }
    const { sortBy, ...restParamsFilterRequest } = filterRequest;
    return dispatch(searchItems(definitionId, {
        sortBy: sortBy?.length ? sortBy : DEFAULT_SORT_BY_FIELDS_VIEW,
        query: { condition: ConditionType.or, filters: [] },
        fullText: '',
        offset: 0,
        languageCode: 'en',
        limit: INITIAL_PAGE_SIZE,
        responseFields: [TITLE_PROPERTY_ID],
        fullTextFields: [TITLE_PROPERTY_ID],
        ...restParamsFilterRequest
    }));
};
export const useSearchItems = ({ definitionId, filterRequest }, { queryKey, staleTime, cacheTime, ...otherOptions } = {}) => {
    const dispatchSearchItems = useDispatch();
    const { data, isError, isLoading, isFetching, isFetched } = useQuery([queryKey, ENTITY_ITEMS_QUERY_KEY, definitionId, { filterRequest }], () => fetchData(filterRequest, dispatchSearchItems, definitionId), {
        ...otherOptions,
        cacheTime: cacheTime ?? oneHourInMs,
        staleTime: staleTime ?? oneMinuteInMs
    });
    return useMemo(() => ({
        data,
        isAvailable: !!data,
        isFailed: isError,
        isFetched,
        isFetching,
        isPending: isLoading
    }), [data, isError, isFetched, isFetching, isLoading]);
};
export const useInfiniteSearchItems = ({ definitionId, filterRequest }, { queryKey, staleTime, cacheTime, ...otherOptions } = {}) => {
    const dispatchSearchItems = useDispatch();
    return useInfiniteQuery([queryKey, INFINITE_ENTITY_ITEMS_QUERY_KEY, definitionId, { filterRequest }], (context) => fetchData({ ...filterRequest, offset: context.pageParam }, dispatchSearchItems, definitionId), {
        ...otherOptions,
        getNextPageParam: (lastPage, allPages) => {
            const limit = filterRequest.limit || initialFilterRequest.limit || 0;
            if (lastPage && lastPage?.results.length < limit) {
                return;
            }
            if (allPages.length * limit >= MAX_ITEMS_CAN_BE_FETCHED) {
                return;
            }
            return allPages.length * limit || initialFilterRequest.offset;
        },
        cacheTime: cacheTime ?? oneHourInMs,
        staleTime: staleTime ?? oneMinuteInMs
    });
};
export const useHandleSearchItems = () => {
    const dispatchSearchItems = useDispatch();
    const handleFetch = useCallback((definitionId, filterRequest) => queryClient.fetchQuery({
        queryKey: [ENTITY_ITEMS_QUERY_KEY, definitionId, { filterRequest }],
        queryFn: () => fetchData(filterRequest, dispatchSearchItems, definitionId),
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs
    }), [dispatchSearchItems]);
    return {
        handleFetch
    };
};
