import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { formatDate, formatDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { PropertyTypes } from '@hypercharge/portal-utils';
import { Button as AntButton } from 'antd';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import React, { useCallback } from 'react';
import { getItemRepresentation } from '../../../../cms';
import { useHandleEntityItems } from '../../../../cms/hooks/useEntityItems';
import ClipboardCopyTableButton from '../../ClipboardButtons/ClipboardCopyTableButton';
import styles from './CopyPropertyButton.module.scss';
const CopyPropertyButton = ({ value, meta, type, className }) => {
    const { t } = useI18n();
    const { handleFetch } = useHandleEntityItems();
    const asyncCopy = useCallback(async () => {
        if (type === PropertyTypes.link && isObject(value) && 'link' in value && isString(value.link)) {
            return value.link;
        }
        if (type === PropertyTypes.richtext && isObject(value) && 'ops' in value) {
            const converter = new QuillDeltaToHtmlConverter(value.ops, { linkTarget: '_blank' });
            const html = converter.convert();
            return [new ClipboardItem({ 'text/html': new Blob([html], { type: 'text/html' }) })];
        }
        if (type === PropertyTypes.checkbox && isBoolean(value)) {
            return value ? t('YES') : t('NO');
        }
        if (type === PropertyTypes.entity &&
            meta &&
            'definitionId' in meta &&
            (isString(value) || Array.isArray(value))) {
            const clipboardItem = new ClipboardItem({
                'text/plain': handleFetch({
                    ids: isString(value) ? [value] : value,
                    definitionId: meta.definitionId
                }).then((entityCmsListResponse) => {
                    if (!entityCmsListResponse) {
                        throw new Error('entityCmsListResponse not found');
                    }
                    const entityItems = entityCmsListResponse?.results;
                    const entityItemList = entityItems
                        .map((item) => (item ? getItemRepresentation(item) : ''))
                        .join('\n');
                    return new Promise((resolve) => {
                        resolve(new Blob([entityItemList], { type: 'text/plain' }));
                    });
                })
            });
            return [clipboardItem];
        }
        if (type === PropertyTypes.date && isNumber(value) && meta && 'withTime' in meta) {
            return meta?.withTime ? formatDateTime(value) : formatDate(value) || '\u00a0';
        }
        if (type === PropertyTypes.multitext && Array.isArray(value)) {
            return value?.map((item) => ` -${item}`).join('\n');
        }
        return value;
    }, [handleFetch, meta, t, type, value]);
    return (React.createElement(ClipboardCopyTableButton, { iconSize: 18, className: `${styles.copyProperty} ${className || ''}`, component: (props) => React.createElement(AntButton, { ...props }), asyncCopy: asyncCopy }));
};
export default CopyPropertyButton;
