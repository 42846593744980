import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { media } from '@hypercharge/hyper-react-base/lib/styling';
import { find, first, get, isString, map, sortBy, toLower } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GoDatabase } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { getAuthJwt, getUserTenantPermissions, hasPermission, redirectIfNoPermission } from '../../../auth';
import { SELECTED_VIEWS_STORAGE_KEY } from '../../../common/components/data-table/ViewSelector';
import HeightContext, { HeightContextProvider } from '../../../common/components/height-context';
import { CollapsibleContainer } from '../../../common/components/side-menu';
import { useBrowserBreakpoints } from '../../../common/components/with-browser-breakpoints';
import { useStorage } from '../../../common/context/StorageContext';
import { getConfigsState } from '../../../common/reducers/rootSelector';
import { APP_ADMIN_PERMISSION, BROWSE_PATH, CMS_ITEM_META_HANDLER, SETTINGS_PATH, VIEW_PATH } from '../../../common/utils/constants';
import { getEntityBrowseUrl, getEntityViewUrl } from '../../../common/utils/url';
import { fetchConfig, getConfig, isFetchConfigPending } from '../../../configs';
import { useMyContact } from '../../../crm/components/use-my-contact';
import { getMenu, getNestedMenuCmsReferences } from '../../../header';
import { CMS_KEY, SETTINGS_KEY } from '../../../settings';
import EditableViewTitleComponent from '../../../views/components/EditableViewTitleComponent';
import { getStoredViewInLocalStorage, isDefaultViewId } from '../../../views/utils';
import CmsDataRoutes from '../../data/components/Routes';
import useDisplayItemMetaList from '../../hooks/useDisplayItemMetaList';
import CmsSettingsRoutes from '../../settings/components/entity/EntityRoutes';
import { Breadcrumbs } from '../breadcrumbs';
import { TITLE_PROPERTY_ID } from '../utils/constants';
import CmsPage from './cms-page/CmsPage';
const RestrictedEntitiesRoutes = ({ match: { url: currentUrl } }) => {
    const { t } = useI18n();
    const { contactId } = useMyContact();
    const { isDesktop } = useBrowserBreakpoints();
    const [viewIdMapInStorage] = useStorage({
        key: SELECTED_VIEWS_STORAGE_KEY,
        defaultValue: {}
    });
    const [extraCountForBreadcrumbsComponent, setExtraCountForBreadcrumbsComponent] = useState();
    const dispatch = useDispatch();
    const [links, setLinks] = useState([]);
    const [currentBreadcrumbText, setCurrentBreadcrumbText] = useState('');
    const cmsConfig = useSelector((s) => getConfig(getConfigsState(s), SETTINGS_KEY, CMS_KEY));
    const isCmsConfigPending = useSelector((s) => isFetchConfigPending(getConfigsState(s), SETTINGS_KEY, CMS_KEY));
    const { data: entities, isError: isFailed, isLoading } = useDisplayItemMetaList();
    const userPermissions = useSelector((s) => getUserTenantPermissions(getAuthJwt(s)));
    const createEntityPath = `${currentUrl}/create`;
    const hasAdminPermission = hasPermission(userPermissions, APP_ADMIN_PERMISSION);
    const cmsEntities = useMemo(() => entities.filter(({ handler }) => handler == null || handler === CMS_ITEM_META_HANDLER), [entities]);
    const singleInactiveCrumb = useMemo(() => {
        const isSingleBreadcrumb = links?.length === 1 && !currentBreadcrumbText;
        return isSingleBreadcrumb ? first(links)?.text : '';
    }, [currentBreadcrumbText, links]);
    useEffect(() => {
        if (!cmsConfig && !isCmsConfigPending) {
            dispatch(fetchConfig(SETTINGS_KEY, CMS_KEY));
        }
    }, [cmsConfig, isCmsConfigPending, dispatch]);
    const getEntityTitle = useCallback((definitionId) => get(find(cmsEntities, { definitionId }), TITLE_PROPERTY_ID) ||
        (isLoading ? '...' : t('NO_TITLE')), [cmsEntities, isLoading, t]);
    const getSideMenuSectionTitle = useCallback((definitionId) => {
        const headerMenu = getMenu({
            availableDefinitionIds: map(cmsEntities, 'definitionId'),
            contactId,
            hasPermission: (permission) => hasPermission(userPermissions, permission),
            viewIdMapInStorage
        });
        const headerMenuReference = find(getNestedMenuCmsReferences(headerMenu), { definitionId });
        if (headerMenuReference) {
            return headerMenuReference.menuTitle;
        }
        if ((cmsConfig?.data?.featuredDefinitionIds || []).includes(definitionId)) {
            return t('SIDE_MENU__FEATURED_ITEMS');
        }
        return null;
    }, [
        cmsEntities,
        contactId,
        viewIdMapInStorage,
        cmsConfig?.data?.featuredDefinitionIds,
        userPermissions,
        t
    ]);
    const [sideMenuItems, sectionSortOrderIndexMap] = useMemo(() => {
        let items = [];
        const sectionSortOrderIndexMap = {};
        if (isLoading || isCmsConfigPending) {
            return [items, sectionSortOrderIndexMap];
        }
        items = [
            ...items,
            ...cmsEntities.map(({ title, definitionId, style }) => {
                const storedViewId = getStoredViewInLocalStorage({
                    definitionId,
                    contactId,
                    viewIdMapInStorage
                });
                const to = storedViewId && !isDefaultViewId(storedViewId)
                    ? getEntityViewUrl(definitionId, storedViewId, currentUrl)
                    : getEntityBrowseUrl(definitionId, currentUrl);
                let sideMenuItem = {
                    key: definitionId,
                    exact: false,
                    to,
                    title: title || definitionId,
                    icon: GoDatabase,
                    style
                };
                if (hasAdminPermission) {
                    sideMenuItem = {
                        ...sideMenuItem,
                        toSettings: `${currentUrl}/${definitionId}${SETTINGS_PATH}`
                    };
                }
                const sectionTitle = getSideMenuSectionTitle(definitionId);
                if (sectionTitle) {
                    sideMenuItem = { ...sideMenuItem, sectionTitle };
                }
                return sideMenuItem;
            })
        ];
        sectionSortOrderIndexMap[t('SIDE_MENU__FEATURED_ITEMS')] = 1;
        return [
            sortBy(items, [(o) => isString(o.title) && toLower(o.title)]),
            sectionSortOrderIndexMap
        ];
    }, [
        isLoading,
        isCmsConfigPending,
        cmsEntities,
        t,
        contactId,
        viewIdMapInStorage,
        currentUrl,
        hasAdminPermission,
        getSideMenuSectionTitle
    ]);
    const getContentJsx = useCallback((contentHeight) => (React.createElement(CollapsibleContainer, { sectionName: "cms", title: t('ENTITIES'), 
        // if you cant create don't pass the path id and it will
        // act as a "canCreate"
        createPath: hasAdminPermission ? createEntityPath : undefined, headerIcon: GoDatabase, items: sideMenuItems, sectionSortOrderIndexMap: sectionSortOrderIndexMap, isFailed: isFailed, isLoading: isLoading || isCmsConfigPending, withSearch: true, withFeaturedEntitiesSwitch: true },
        React.createElement(Container, null,
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: currentUrl, render: () => (React.createElement(CmsPage, { contentHeight: contentHeight || undefined, setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText })) }),
                hasAdminPermission && (React.createElement(Route, { path: [createEntityPath, `${currentUrl}/:definitionId${SETTINGS_PATH}`], render: (props) => {
                        const { definitionId } = props.match.params;
                        const entityTitle = definitionId
                            ? getEntityTitle(definitionId)
                            : t('CREATE_ENTITY_META__TITLE');
                        return (React.createElement(CmsSettingsRoutes, { definitionId: definitionId, entityTitle: entityTitle, setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, contentHeight: contentHeight, baseUrl: currentUrl, currentUrl: props.match.url }));
                    } })),
                React.createElement(Route, { path: `${currentUrl}/:definitionId`, render: (props) => {
                        const { definitionId } = props.match.params;
                        const displayItemMeta = find(cmsEntities, { definitionId });
                        return (React.createElement(CmsDataRoutes, { definitionId: definitionId, entityTitle: getEntityTitle(definitionId), canCreateItems: displayItemMeta?.canCreate || false, canDeleteItems: displayItemMeta?.canDelete || false, setCurrentBreadcrumbText: setCurrentBreadcrumbText, setLinks: setLinks, contentHeight: contentHeight, isFailed: isFailed, isLoading: isLoading, baseUrl: currentUrl, setExtraCountForBreadcrumbsComponent: setExtraCountForBreadcrumbsComponent, ...props }));
                    } }),
                React.createElement(Redirect, { to: currentUrl }))))), [
        t,
        hasAdminPermission,
        createEntityPath,
        sideMenuItems,
        sectionSortOrderIndexMap,
        isFailed,
        isLoading,
        isCmsConfigPending,
        currentUrl,
        getEntityTitle,
        cmsEntities
    ]);
    return (React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: "BreadcrumbsContainer", id: "breadcrumbs" },
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: `${currentUrl}`, render: () => React.createElement(Breadcrumbs, { links: [], currentBreadcrumb: singleInactiveCrumb }) }),
                React.createElement(Route, { path: [`${currentUrl}/:definitionId${SETTINGS_PATH}`, `${currentUrl}/create`], render: () => React.createElement(Breadcrumbs, { links: links, currentBreadcrumb: currentBreadcrumbText }) }),
                React.createElement(Route, { path: [
                        `${currentUrl}/:definitionId${BROWSE_PATH}`,
                        `${currentUrl}/:definitionId${VIEW_PATH}/:viewId/:entityId`,
                        `${currentUrl}/:definitionId${VIEW_PATH}/:viewId`
                    ], render: (props) => {
                        const { definitionId, viewId, entityId } = props.match.params;
                        return (React.createElement(Breadcrumbs, { currentBreadcrumb: React.createElement(EditableViewTitleComponent, { definitionId: definitionId, entityId: entityId, viewId: viewId, totalItemsCount: extraCountForBreadcrumbsComponent, entityTitle: currentBreadcrumbText }), links: links }));
                    } }))),
        React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: otherHeightInPx },
            React.createElement(HeightContext.Consumer, null, ({ contentHeight }) => getContentJsx(isDesktop ? contentHeight : undefined)))));
};
export default redirectIfNoPermission(RestrictedEntitiesRoutes);
//
// Utils
//
const Container = styled.div `
  position: relative;
  margin-bottom: 1rem;

  ${media.xl} {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
`;
const otherElementSelectors = ['#header', '#featured-entities', '#breadcrumbs'];
const otherHeightInPx = 0;
