import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import axios from 'axios';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { getErrorMessage } from '~app/common/utils/request';
import { sleep } from '~app/utils/time';
const fetchQueryRequest = (queryId) => ({
    type: 'LOGS__QUERY_RESULT',
    meta: {
        http: {
            path: `/v2-logs/query/${queryId}`,
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
const cancelQueryRequest = (queryId) => ({
    type: 'LOGS__QUERY_CANCEL',
    meta: {
        http: {
            path: `/v2-logs/query/${queryId}`,
            method: 'DELETE',
            allowPromiseReject: true
        }
    }
});
const startQuery = (request) => ({
    type: 'LOGS__START_QUERY',
    payload: request,
    meta: {
        http: {
            path: `/v2-logs/query`,
            method: 'POST',
            allowPromiseReject: true
        }
    }
});
const INITIAL_QUERY_RESULT = {
    fields: [],
    results: [],
    statistics: {
        bytesScanned: 0,
        recordsMatched: 0,
        recordsScanned: 0
    },
    status: 'Running'
};
const useQueryResult = () => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const run = useDispatch();
    const getQueryResult = useDispatch();
    const cancel = useDispatch();
    const [queryId, setQueryId] = useState();
    const [queryResult, setQueryResult] = useState();
    const { mutateAsync: runQueryAsync, isLoading: isStartQueryLoading } = useMutation({
        mutationFn: async (variables) => {
            return run(startQuery(variables));
        }
    });
    const { mutateAsync: cancelQueryAsync, isLoading: isCancelQueryLoading } = useMutation({
        mutationFn: async (queryId) => {
            await cancel(cancelQueryRequest(queryId));
            return;
        }
    });
    const runQuery = useCallback(async (query, dateRange) => {
        setQueryResult(INITIAL_QUERY_RESULT);
        try {
            const { queryId } = await runQueryAsync({
                startTime: dateRange[0],
                endTime: dateRange[1],
                query
            });
            setQueryId(queryId);
            let queryResult;
            do {
                await sleep(1000);
                const response = await getQueryResult(fetchQueryRequest(queryId));
                if ('download' in response) {
                    queryResult = (await axios(response.download)).data;
                }
                else {
                    queryResult = response;
                }
                setQueryResult({
                    ...queryResult,
                    results: queryResult.results.map((item) => {
                        return { ...item, subRows: [{}] };
                    })
                });
            } while (queryResult.status === 'Running');
            setQueryId(undefined);
        }
        catch (err) {
            setQueryId(undefined);
            setQueryResult(undefined);
            dispatch(error({
                title: t('ERROR'),
                message: getErrorMessage(err, t),
                autoDismiss: 10
            }));
        }
    }, [dispatch, getQueryResult, runQueryAsync, t]);
    const cancelQuery = useCallback(async (queryId) => {
        try {
            await cancelQueryAsync(queryId);
            setQueryId(undefined);
        }
        catch (err) {
            dispatch(error({
                title: t('ERROR'),
                message: getErrorMessage(err, t)
            }));
        }
    }, [cancelQueryAsync, dispatch, t]);
    const isQueryResultLoading = queryResult?.status === 'Running';
    return {
        runQuery,
        cancelQuery,
        queryId,
        queryResult,
        isQueryResultLoading,
        isStartQueryLoading,
        isCancelQueryLoading
    };
};
export default useQueryResult;
