import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { BasicInput, DescriptionText, FormFooter, required, Section, slug, SubTitle, Title } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { Field } from 'redux-form';
import { Breadcrumbs } from '../../../../cms/common/breadcrumbs';
import { ADMIN_PATH } from '../../../utils/constants';
const OptionGroupForm = ({ submitting, handleSubmit }) => {
    const { t } = useI18n();
    return (React.createElement(React.Fragment, null,
        React.createElement(Breadcrumbs, { links: [
                { text: t('ADMIN_NAV_LINK'), to: `${ADMIN_PATH}` },
                { text: t('SETTINGS_OPTIONS_NAV_LINK'), to: `${ADMIN_PATH}/option-groups` }
            ], currentBreadcrumb: t('ADD_OPTION_TITLE') }),
        React.createElement(Section, null,
            React.createElement(Title, null, t('ADD_OPTION_TITLE')),
            React.createElement(SubTitle, null, t('ADD_OPTION_SUBTITLE')),
            React.createElement("form", { onSubmit: handleSubmit, noValidate: true },
                React.createElement(SectionCard, { title: t('SETTINGS__OPTIONS_GROUP_ID'), withoutPadding: true },
                    React.createElement("div", { className: "cp-c-padding-2" },
                        React.createElement(DescriptionText, null, t('ADD_OPTION_FORM_VALUE_TEXT')),
                        React.createElement("div", null,
                            React.createElement(Field, { name: "groupId", autoFocus: true, component: BasicInput, type: "text", validate: required, normalize: slug })))),
                React.createElement(FormFooter, { showCancel: true, submitting: submitting })))));
};
export default OptionGroupForm;
