import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button } from 'antd';
import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaEyeSlash } from 'react-icons/fa';
import { MdArrowDownward, MdArrowUpward, MdFilterListAlt, MdLayers, MdLayersClear, MdOutlineArrowUpward } from 'react-icons/md';
import { AVAILABLE_GROUPING_ROWS } from '../../../../cms/data/components/items/utils';
import { supportedSortByPropertyTypes } from '../../../../views';
import { QuickFilter } from '../../ConditionQuery';
import { supportedFilterPropertyTypes } from '../../ConditionQuery/ConditionQueryField';
import DropdownButton from '../../DropdownButton';
import { TABLE_EXTRA_COLS } from '../utils';
import styles from './TableHeaderActionsButton.module.scss';
const TableHeaderActionsButton = ({ header, rowGrouping, onChangeRowGrouping, onRemoveColumns, type, meta, showQuickFilter, hideColumn, showGroupedBy }) => {
    const { t } = useI18n();
    const sorted = useMemo(() => header.column.getIsSorted(), [header.column]);
    const [openQuickFilters, setOpenQuickFilters] = useState(false);
    const supportedSort = useMemo(() => supportedSortByPropertyTypes.includes(type), [type]);
    const overflowTabsMenu = useMemo(() => {
        const items = [];
        const sorted = header.column.getIsSorted();
        if (supportedSort) {
            if (!sorted || sorted === 'desc') {
                items.push({
                    label: t('TABLE_COLUMN_HEADER_SORT_BY_ASC'),
                    key: 'sort-by-asc',
                    icon: React.createElement(MdArrowUpward, null),
                    onClick: () => header.column.toggleSorting(false, false)
                });
            }
            if (!sorted || sorted === 'asc') {
                items.push({
                    label: t('TABLE_COLUMN_HEADER_SORT_BY_DESC'),
                    key: 'sort-by-desc',
                    icon: React.createElement(MdArrowDownward, null),
                    onClick: () => header.column.toggleSorting(true, false)
                });
            }
            if (header.column.getIsSorted()) {
                items.push({
                    className: 'no-icon',
                    label: t('TABLE_COLUMN_HEADER_UNSORT'),
                    key: 'unsort',
                    onClick: () => header.column.clearSorting()
                });
            }
            items.push({
                key: 'sort-divider',
                type: 'divider'
            });
        }
        if (showQuickFilter && supportedFilterPropertyTypes.includes(type)) {
            items.push({
                label: t('TABLE_COLUMN_HEADER_FILTER'),
                key: 'filter',
                icon: React.createElement(MdFilterListAlt, null),
                onClick: () => setOpenQuickFilters(true)
            });
        }
        if (rowGrouping === header.column.id) {
            items.push({
                label: t('UN_GROUP_BY_THIS_PROPERTY'),
                key: 'ungroup',
                icon: React.createElement(MdLayersClear, { color: "var(--neutralColor-6)" }),
                onClick: () => onChangeRowGrouping?.(undefined)
            });
        }
        else if (AVAILABLE_GROUPING_ROWS.includes(type) && showGroupedBy) {
            items.push({
                label: t('GROUP_BY_THIS_PROPERTY'),
                key: 'group',
                icon: React.createElement(MdLayers, { color: "var(--neutralColor-6)" }),
                onClick: () => onChangeRowGrouping?.(header.column.id)
            });
        }
        if (((AVAILABLE_GROUPING_ROWS.includes(type) && showGroupedBy) ||
            (showQuickFilter && supportedFilterPropertyTypes.includes(type))) &&
            rowGrouping !== header.column.id &&
            hideColumn) {
            items.push({
                key: 'hide-column-divider',
                type: 'divider'
            });
        }
        if (rowGrouping !== header.column.id && hideColumn) {
            items.push({
                label: t('TABLE_COLUMN_HEADER_HIDE_COLUMN'),
                icon: React.createElement(FaEyeSlash, null),
                key: 'hide-column',
                onClick: () => onRemoveColumns?.([header.column.id])
            });
        }
        return {
            items
        };
    }, [
        header.column,
        supportedSort,
        showQuickFilter,
        type,
        rowGrouping,
        showGroupedBy,
        hideColumn,
        t,
        onChangeRowGrouping,
        onRemoveColumns
    ]);
    const sortClass = useMemo(() => (sorted === 'desc' ? '-sort-desc' : sorted === 'asc' ? '-sort-asc' : ''), [sorted]);
    const onCloseFilter = useCallback(() => {
        setOpenQuickFilters(false);
    }, []);
    const onOpenFilter = useCallback(() => {
        setOpenQuickFilters(true);
    }, []);
    return (React.createElement(React.Fragment, null,
        showQuickFilter && (React.createElement(QuickFilter, { open: openQuickFilters, onClose: onCloseFilter, onOpen: onOpenFilter, type: type, propertyId: header.column.id, meta: meta })),
        rowGrouping === header.column.id && (React.createElement(Button, { shape: "circle", className: cn('safe-space border-0 bg-transparent shadow-none'), onClick: () => onChangeRowGrouping?.(undefined) },
            React.createElement(MdLayersClear, { size: 16, color: "var(--neutralColor-6)" }))),
        TABLE_EXTRA_COLS.includes(header.id) || !supportedSort ? null : (React.createElement(Button, { shape: "circle", className: cn('safe-space sort-button border-0 bg-transparent shadow-none', sortClass), onClick: header.column.getToggleSortingHandler() },
            React.createElement(MdOutlineArrowUpward, { size: 16, color: sortClass ? 'var(--neutralColor-6)' : 'var(--neutralColor-4)', className: sortClass }))),
        React.createElement(DropdownButton, { placement: "bottomRight", mainButtonClassName: "safe-space bg-transparent", className: cn(styles.actionsContainer, 'position-relative ms-auto cursor-pointer ActionsButton'), dropdownRootClassName: styles.menu, menuIcon: React.createElement(BsThreeDotsVertical, { size: 16 }), menu: overflowTabsMenu })));
};
export default TableHeaderActionsButton;
