import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { formatDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { RowHeight } from '@hypercharge/portal-utils';
import { Modal, Steps, Tooltip } from 'antd';
import { INITIAL_PAGE, INITIAL_PAGE_SIZE } from 'config';
import { last } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BsCircle } from 'react-icons/bs';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { IoEye } from 'react-icons/io5';
import { MdImportantDevices } from 'react-icons/md';
import { EmailSendingDetailsAggregation } from '../..';
import { EmailDisplay } from '../../../../../../../cms';
import { GetColumnHeader } from '../../../../../../../cms/data/components/items/GetColumnHeader';
import { Drawer, DrawerContent } from '../../../../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../../../../common/components/Drawer/DrawerContentBlock';
import ReactTable from '../../../../../../../common/components/data-table/ReactTable';
import { ROW_HEIGHT_TYPES_TO_PIXELS } from '../../../../../../../common/components/data-table/utils';
import TablePagination from '../../../../../../../common/components/table-pagination';
import { PropertyTypes } from '../../../../../../../common/types';
import styles from './EmailSendingDetailsModal.module.scss';
const MAIL_STATUSES_SUCCESS = ['Delivery', 'Open', 'Click'];
const getClassForSummary = (summary) => {
    if (summary.successes && summary.fails) {
        return 'sending-warn';
    }
    if (summary.statuses.length === 1 && summary.statuses[0].status === 'Send') {
        return 'sending-process';
    }
    return summary.successes ? 'sending-success' : 'sending-fail';
};
const EmailSendingDetailsModal = ({ mailStatusForModal, setMailStatusForModal, isMailPreviewLoading, mailBodiesForModal, showPreview }) => {
    const { t, language } = useI18n();
    const [page, setPage] = useState(INITIAL_PAGE);
    const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);
    const [details, setDetails] = useState(null);
    const [mailBody, setMailBody] = useState('');
    const getStatusForSummary = useCallback((summary) => {
        if (summary.successes && summary.fails) {
            return t('EMAIL_SENDING_DETAILS_STATUS_PARTIAL_DELIVERY');
        }
        const lastStatus = last(summary.statuses);
        if (summary.statuses.length === 1 && lastStatus?.status === 'Send') {
            return t('EMAIL_SENDING_DETAILS_STATUS_SEND');
        }
        if (lastStatus?.status === 'Rendering Failure') {
            return t('EMAIL_SENDING_DETAILS_STATUS_RENDERING_FAILURE');
        }
        return t(summary.successes
            ? 'EMAIL_SENDING_DETAILS_STATUS_DELIVERY'
            : 'EMAIL_SENDING_DETAILS_STATUS_BOUNCE');
    }, [t]);
    const { data, totalCount } = useMemo(() => {
        if (!mailStatusForModal || !mailBodiesForModal) {
            return { data: [], totalCount: 0 };
        }
        const grouped = mailStatusForModal.reduce((acc, status) => {
            if (!acc[status.messageId]) {
                acc[status.messageId] = new EmailSendingDetailsAggregation(status.messageId, mailBodiesForModal);
            }
            acc[status.messageId].applyStatus(status);
            return acc;
        }, {});
        const data = Object.values(grouped);
        return { data, totalCount: data.length };
    }, [mailStatusForModal, mailBodiesForModal]);
    useEffect(() => {
        const pageLimit = Math.ceil(totalCount / pageSize);
        if (page > pageLimit && pageLimit > 0) {
            setPage(pageLimit);
        }
    }, [page, pageSize, totalCount]);
    const columns = useMemo(() => [
        {
            header: '',
            accessorKey: 'messageId',
            id: 'messageId',
            sortable: false,
            enableResizing: false,
            cell: ({ row }) => (React.createElement("div", { className: "mail-preview w-100 d-flex align-items-center justify-content-center" },
                React.createElement(Tooltip, { title: t('PREVIEW') },
                    React.createElement(Button, { className: "icon-btn safe-space", inversed: true, loading: !!mailBody && isMailPreviewLoading[mailBody] && mailBody === row.original.bodyId, onClick: () => {
                            const mailBody = row.original.bodyId || '';
                            setMailBody(mailBody);
                            if (mailBody) {
                                showPreview(mailBody).catch(console.error);
                            }
                        } },
                        React.createElement(MdImportantDevices, null))),
                React.createElement(Tooltip, { title: t('EMAIL_SENDING_DETAILS_MODAL_DETAILS') },
                    React.createElement(Button, { className: "icon-btn safe-space", inversed: true, onClick: () => {
                            setDetails(row.original);
                        } },
                        React.createElement(IoEye, null)))))
        },
        {
            header: GetColumnHeader,
            meta: {
                headerProps: {
                    labels: { [language]: t('EMAIL_SENDING_DETAILS_MODAL_COL_RECIPIENT') },
                    type: PropertyTypes.email,
                    meta: {},
                    showQuickFilter: false,
                    hideColumn: false,
                    showGroupedBy: false
                }
            },
            accessorKey: 'recipient',
            id: 'recipient',
            cell: ({ row }) => (React.createElement("div", { className: "recipients-list w-100" }, (row.original.recipients || []).map((email) => (React.createElement(EmailDisplay, { key: email, value: email })))))
        },
        {
            header: GetColumnHeader,
            meta: {
                headerProps: {
                    labels: { [language]: t('EMAIL_SENDING_DETAILS_MODAL_COL_STATUS') },
                    type: PropertyTypes.text,
                    meta: {},
                    showQuickFilter: false,
                    hideColumn: false,
                    showGroupedBy: false
                }
            },
            accessorKey: 'status',
            id: 'status',
            cell: ({ row }) => (React.createElement("div", { className: 'status-cell ' + getClassForSummary(row.original) }, getStatusForSummary(row.original)))
        },
        {
            header: GetColumnHeader,
            meta: {
                headerProps: {
                    labels: { [language]: t('EMAIL_SENDING_DETAILS_MODAL_COL_ADDITION_RECIPIENTS') },
                    type: PropertyTypes.email,
                    meta: {},
                    showQuickFilter: false,
                    hideColumn: false,
                    showGroupedBy: false
                }
            },
            accessorKey: 'details',
            id: 'details',
            cell: ({ row }) => (React.createElement("div", { className: "expanded-details" },
                !!row.original.cc && (React.createElement("div", { className: "expanded-details__row" },
                    React.createElement("span", { className: "expanded-details__label" },
                        t('EMAIL_SENDING_DETAILS_MODAL_LABEL_CC'),
                        ":"),
                    React.createElement("span", { className: "expanded-details__value" }, row.original.cc.map((email) => (React.createElement("div", { key: email }, email)))))),
                !!row.original.bcc && (React.createElement("div", { className: "expanded-details__row" },
                    React.createElement("span", { className: "expanded-details__label" },
                        t('EMAIL_SENDING_DETAILS_MODAL_LABEL_BCC'),
                        ":"),
                    React.createElement("span", { className: "expanded-details__value" }, row.original.bcc.map((email) => (React.createElement("div", { key: email }, email))))))))
        },
        {
            header: GetColumnHeader,
            meta: {
                headerProps: {
                    labels: { [language]: t('EMAIL_SENDING_DETAILS_MODAL_COL_TIMESTAMP') },
                    type: PropertyTypes.date,
                    meta: {},
                    showQuickFilter: false,
                    hideColumn: false,
                    showGroupedBy: false
                }
            },
            accessorKey: 'timestamp',
            id: 'timestamp',
            cell: ({ row }) => (React.createElement("div", { className: "timestamp-cell" }, formatDateTime(row.original.timestamp)))
        },
        {
            header: GetColumnHeader,
            meta: {
                headerProps: {
                    labels: { [language]: t('EMAIL_SENDING_DETAILS_MODAL_COL_OPENS') },
                    type: PropertyTypes.number,
                    meta: {},
                    showQuickFilter: false,
                    hideColumn: false,
                    showGroupedBy: false
                }
            },
            accessorKey: 'opens',
            id: 'opens',
            cell: ({ row }) => React.createElement("div", { className: "timestamp-cell" }, row.original.opens)
        },
        {
            header: GetColumnHeader,
            meta: {
                headerProps: {
                    labels: { [language]: t('EMAIL_SENDING_DETAILS_MODAL_COL_CLICKS') },
                    type: PropertyTypes.number,
                    meta: {},
                    showQuickFilter: false,
                    hideColumn: false,
                    showGroupedBy: false
                }
            },
            accessorKey: 'clicks',
            id: 'clicks',
            cell: ({ row }) => React.createElement("div", { className: "timestamp-cell" }, row.original.clicks)
        }
    ], [t, mailBody, isMailPreviewLoading, showPreview, language, getStatusForSummary]);
    const handleCloseModal = useCallback(() => {
        setMailStatusForModal(null);
    }, [setMailStatusForModal]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { centered: true, title: t('EMAIL_SENDING_DETAILS'), open: !!mailStatusForModal, footer: React.createElement("div", { className: "align-items-center d-flex justify-content-end gap-1" }, totalCount > 0 && (React.createElement(TablePagination, { totalCount: totalCount, pageSize: pageSize, onPageSizeChange: setPageSize, page: page, onPageChange: setPage }))), onCancel: handleCloseModal, destroyOnClose: true, wrapClassName: styles.modal },
            React.createElement(ReactTable, { rowHeight: ROW_HEIGHT_TYPES_TO_PIXELS[RowHeight.TALL], pageIndex: page, pageSize: pageSize, data: data, columns: columns, manualPagination: false, selectable: false })),
        React.createElement(Drawer, { title: details?.recipients
                ? `${t('EMAIL_SENDING_DETAILS_MODAL_DETAILS')}: ${details.recipients[0] || ''}`
                : '', onClose: () => {
                setDetails(null);
            }, open: !!details, destroyOnClose: true, closable: true, className: styles.drawer },
            React.createElement(DrawerContent, null, details && (React.createElement(React.Fragment, null,
                React.createElement(DrawerContentBlock, { title: React.createElement("span", { className: "collapse-panel-header" }, t('EMAIL_SENDING_DETAILS_MODAL_COMMON')) },
                    React.createElement("div", { className: "row details-row pt-3" },
                        React.createElement("div", { className: "col-4" },
                            React.createElement("strong", null, t('EMAIL_SENDING_DETAILS_MODAL_LABEL_FROM'))),
                        React.createElement("div", { className: "col-8" }, details.from)),
                    React.createElement("div", { className: "row details-row" },
                        React.createElement("div", { className: "col-4" },
                            React.createElement("strong", null, t('EMAIL_SENDING_DETAILS_MODAL_LABEL_RECIPIENTS'))),
                        React.createElement("div", { className: "col-8" }, details.recipients?.join(', '))),
                    !!details.cc && (React.createElement("div", { className: "row details-row" },
                        React.createElement("div", { className: "col-4" },
                            React.createElement("strong", null, t('EMAIL_SENDING_DETAILS_MODAL_LABEL_CC'))),
                        React.createElement("div", { className: "col-8" }, details.cc.join(', ')))),
                    !!details.bcc && (React.createElement("div", { className: "row details-row" },
                        React.createElement("div", { className: "col-4" },
                            React.createElement("strong", null, t('EMAIL_SENDING_DETAILS_MODAL_LABEL_BCC'))),
                        React.createElement("div", { className: "col-8" }, details.bcc.join(', ')))),
                    details.subject && (React.createElement("div", { className: "row details-row" },
                        React.createElement("div", { className: "col-4" },
                            React.createElement("strong", null, t('EMAIL_SENDING_DETAILS_MODAL_LABEL_SUBJECT'))),
                        React.createElement("div", { className: "col-8" }, details.subject))),
                    React.createElement("div", { className: "row details-row" },
                        React.createElement("div", { className: "col-4" },
                            React.createElement("strong", null, t('EMAIL_SENDING_DETAILS_MODAL_COL_STATUS'))),
                        React.createElement("div", { className: 'col-8 ' + getClassForSummary(details) }, getStatusForSummary(details))),
                    React.createElement("div", { className: "row details-row" },
                        React.createElement("div", { className: "col-4" },
                            React.createElement("strong", null, t('EMAIL_SENDING_DETAILS_MODAL_LABEL_MESSAGE_ID'))),
                        React.createElement("div", { className: "col-8" }, details.messageId)),
                    React.createElement("div", { className: "row details-row" },
                        React.createElement("div", { className: "col-4" },
                            React.createElement("strong", null, t('EMAIL_SENDING_DETAILS_MODAL_COL_TIMESTAMP'))),
                        React.createElement("div", { className: "col-8" }, formatDateTime(details.timestamp)))),
                React.createElement(DrawerContentBlock, { title: React.createElement("span", { className: "collapse-panel-header" }, t('EMAIL_SENDING_DETAILS_MODAL_GLOBAL_STATUSES')) },
                    React.createElement(Steps, { className: "pt-3", direction: "vertical" }, details.commonSuccessStatuses.map((status) => (React.createElement(Steps.Step, { key: status.timestamp, status: status.success ? 'finish' : 'error', title: React.createElement("div", null, status.status), description: React.createElement("div", null,
                            status.status === 'Click' &&
                                status.description &&
                                'link' in status.description && (React.createElement("div", null,
                                t('EMAIL_SENDING_DETAILS_MODAL_LABEL_CLICKED') + ' ',
                                React.createElement(Tooltip, { title: status.description.link },
                                    React.createElement("a", { href: status.description.link, target: "_blank", rel: "noreferrer" }, t('EMAIL_SENDING_DETAILS_MODAL_LABEL_LINK'))))),
                            React.createElement("div", null, formatDateTime(status.timestamp))), icon: MAIL_STATUSES_SUCCESS.includes(status.status) ? (React.createElement(IoIosCheckmarkCircleOutline, { className: status.success ? 'sending-success' : '' })) : (React.createElement(BsCircle, { className: "step-icon" })) }))))),
                Object.entries(details.emails).map(([email, summary]) => (React.createElement(DrawerContentBlock, { key: email, title: React.createElement("span", { className: "collapse-panel-header" }, `${t('EMAIL_SENDING_DETAILS_MODAL_HISTORY')}: ${email}`) },
                    React.createElement(Steps, { className: "pt-3", direction: "vertical" }, summary.statuses.map((status) => (React.createElement(Steps.Step, { key: status.timestamp, status: status.success ? 'finish' : 'error', title: React.createElement("div", null, status.status), description: React.createElement("div", null,
                            status.status === 'Bounce' && (React.createElement("div", null, status.description
                                .bouncedRecipients[0].diagnosticCode)),
                            status.status === 'Complaint' && (React.createElement("div", null, t('EMAIL_SENDING_DETAILS_MODAL_LABEL_TYPE') +
                                ': ' +
                                status.description
                                    .complaintFeedbackType)),
                            status.status === 'Reject' && (React.createElement("div", null, t('EMAIL_SENDING_DETAILS_MODAL_LABEL_REASON') +
                                ': ' +
                                status.description.reason)),
                            status.status === 'Rendering Failure' && (React.createElement("div", null, t('EMAIL_SENDING_DETAILS_MODAL_LABEL_ERROR') +
                                ': ' +
                                status.description
                                    .errorMessage)),
                            status.status === 'DeliveryDelay' && (React.createElement("div", null, t('EMAIL_SENDING_DETAILS_MODAL_LABEL_ERROR') +
                                ': ' +
                                status.description
                                    .delayedRecipients[0].diagnosticCode)),
                            React.createElement("div", null, formatDateTime(status.timestamp))), icon: MAIL_STATUSES_SUCCESS.includes(status.status) ? (React.createElement(IoIosCheckmarkCircleOutline, { className: status.success ? 'sending-success' : '' })) : (React.createElement(BsCircle, { className: "step-icon" })) })))))))))))));
};
export default EmailSendingDetailsModal;
