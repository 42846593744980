import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import React, { useCallback } from 'react';
import { FaEye } from 'react-icons/fa';
import { IoEyeOff } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import DataTable2Container from '../../../../../common/components/data-table/DataTable2Container';
import { StyledLink } from '../../../../../common/components/data-table/utils';
import { BROWSE_PATH, VIEW_PATH } from '../../../../../common/utils/constants';
import { isCmsDefinition, isWorkflowDefinition } from '../../../../../common/utils/url';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID } from '../../../../../workflows';
import { TASK_PROCESS_LINK_PROPERTY_ID } from '../../../../../workflows/common/utils/constants';
import useDisplayItemMeta from '../../../../hooks/useDisplayItemMeta';
import { ENTITY_ID_PROPERTY_ID, EditLink } from '../../../../index';
export const ItemsViewTable = ({ actions, baseUrl, contentHeight, definitionId, viewId }) => {
    const { t } = useI18n();
    const { pathname } = useLocation();
    const { data: displayItemMeta } = useDisplayItemMeta({ definitionId });
    const columnRenderer = useCallback(({ cell }, data, filterRequest, isTaskDefinition) => {
        if (isCmsDefinition(displayItemMeta)) {
            return (React.createElement(StyledLink, { className: "safe-space", to: {
                    pathname: viewId
                        ? `${baseUrl}${VIEW_PATH}/${viewId}/${cell.row.original.entityId}`
                        : `${baseUrl}${BROWSE_PATH}/${cell.row.original.entityId}`,
                    state: {
                        data,
                        filterRequest,
                        definitionId,
                        isTaskDefinition
                    }
                }, title: t('OPEN_ITEM') },
                React.createElement(FaEye, { size: 16 })));
        }
        if (isWorkflowDefinition(displayItemMeta)) {
            const processRunId = cell.row.original.entityId;
            let to = viewId
                ? `${baseUrl}${VIEW_PATH}/${viewId}/${processRunId}`
                : `${baseUrl}${BROWSE_PATH}/${processRunId}`;
            if (definitionId === ALL_PROCESSES_DEFINITION_ID && cell.row.original.definitionId) {
                const updatedBaseUrl = baseUrl.replace(ALL_PROCESSES_DEFINITION_ID, cell.row.original.definitionId);
                to = viewId
                    ? `${updatedBaseUrl}${VIEW_PATH}/${viewId}/${processRunId}`
                    : `${updatedBaseUrl}${BROWSE_PATH}/${processRunId}`;
            }
            else if (definitionId === ALL_TASKS_DEFINITION_ID) {
                if (!cell.row.original[TASK_PROCESS_LINK_PROPERTY_ID]) {
                    return (React.createElement(Tooltip, { title: t('WORKFLOWS__PROCESS_LINK_UNAVAILABLE') },
                        React.createElement(IoEyeOff, { className: "ms-3" })));
                }
                // The link is available so we pass the url
                to = cell.row.original[TASK_PROCESS_LINK_PROPERTY_ID].link;
            }
            return (React.createElement(EditLink, { className: "safe-space", to: {
                    pathname: to,
                    state: {
                        data,
                        filterRequest,
                        definitionId,
                        isTaskDefinition
                    }
                }, title: t('WORKFLOWS__GO_TO_PROCESS') },
                React.createElement(FaEye, null)));
        }
        return (React.createElement(EditLink, { className: "safe-space", to: {
                pathname: `${pathname}/${cell.row.original.entityId || ''}`,
                state: {
                    data,
                    filterRequest,
                    definitionId,
                    isTaskDefinition
                }
            }, title: t('WORKFLOWS__GO_TO_PROCESS') },
            React.createElement(FaEye, null)));
    }, [baseUrl, definitionId, displayItemMeta, pathname, t, viewId]);
    return (React.createElement(DataTable2Container, { idField: ENTITY_ID_PROPERTY_ID, columnRenderer: columnRenderer, actions: actions, withCmsImport: isCmsDefinition(displayItemMeta), contentHeight: contentHeight, showViewsSelector: true, baseUrl: baseUrl }));
};
