import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { KanbanListWrapper } from '~app/cms/data/components/items/Kanban/KanbanListWrapper';
import LoadingIndicator from '~app/common/components/loading-indicator';
import styles from './KanbanBoard.module.scss';
const KanbanBoard = ({ baseUrl, contentHeight, definitionId, disabledColumns, fields, getCustomColumnCountFilters, getCustomRouteHandleCardClick, isDropDisabled, isPending, lists, onDragEnd, viewId }) => {
    const [hiddenColumns, setHiddenColumns] = useState(new Set());
    const onToggleList = useCallback((entityId) => {
        if (hiddenColumns.has(entityId)) {
            hiddenColumns.delete(entityId);
        }
        else {
            hiddenColumns.add(entityId);
        }
        setHiddenColumns(new Set([...hiddenColumns]));
    }, [hiddenColumns]);
    useEffect(() => {
        const stopPropagation = (e) => {
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
                e.message === 'ResizeObserver loop limit exceeded') {
                e.stopImmediatePropagation();
            }
        };
        // Virtuoso's resize observer can this error,
        // which is caught by DnD and aborts dragging.
        window.addEventListener('error', stopPropagation);
        return () => window.removeEventListener('error', stopPropagation);
    }, []);
    return (React.createElement("div", null,
        React.createElement(DragDropContext, { onDragEnd: onDragEnd },
            React.createElement("div", { className: `custom-scroll-styling ${styles.board}`, style: {
                    height: contentHeight
                } }, isPending ? (React.createElement(LoadingIndicator, null)) : (lists.map(({ propertyId, title }) => (React.createElement(KanbanListWrapper, { key: propertyId, baseUrl: baseUrl, contentHeight: contentHeight, definitionId: definitionId, disabledColumns: disabledColumns, fields: fields, viewId: viewId, onToggleList: onToggleList, getCustomColumnCountFilters: getCustomColumnCountFilters, getCustomRouteHandleCardClick: getCustomRouteHandleCardClick, hiddenColumns: hiddenColumns, isDropDisabled: isDropDisabled, columnId: propertyId, title: title }))))))));
};
export default KanbanBoard;
