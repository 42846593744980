import { RowHeight } from '@hypercharge/portal-utils';
import { flexRender } from '@tanstack/react-table';
import { useVirtualizer, useWindowVirtualizer } from '@tanstack/react-virtual';
import cn from 'classnames';
import { last } from 'lodash';
import React, { useCallback } from 'react';
import { ReactTableGroup } from './ReactTableGroup';
import { ROW_HEIGHT_TYPES_TO_PIXELS, TABLE_EXTRA_COLS } from './utils';
export const ReactTableBaseVirtualizedBody = ({ rowHeight, loading, rowVirtualizer, tableContainerScrollerRef, table, children, expandedPagination, setExpandedPagination, setExpandedMap, getRowComponent }) => {
    const { rows } = table.getRowModel();
    const virtualRows = rowVirtualizer.getVirtualItems();
    const toggleExpand = useCallback((row) => {
        /**
         * during expanding the scroll jumps trying to adapt to the changing height
         * shouldAdjustScrollPositionOnItemSizeChange is needed to disable this logic
         *
         * @see https://github.com/TanStack/virtual/issues/562
         */
        const oldValue = rowVirtualizer.shouldAdjustScrollPositionOnItemSizeChange;
        rowVirtualizer.shouldAdjustScrollPositionOnItemSizeChange = () => false;
        row.toggleExpanded();
        setTimeout(() => {
            rowVirtualizer.shouldAdjustScrollPositionOnItemSizeChange = oldValue;
        }, 0);
    }, [rowVirtualizer]);
    return (React.createElement("tbody", { className: cn('d-block', { 'rt-tbody rt-tbody-loading': loading, 'rt-tbody': !loading }), style: {
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative'
        } },
        virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index];
            const rowCanExpand = row.getCanExpand();
            const RowComponent = getRowComponent?.(row);
            return (React.createElement("tr", { "data-index": virtualRow.index, ref: rowVirtualizer.measureElement, className: "rt-tr", key: row.id, style: {
                    position: 'absolute',
                    transform: `translateY(${virtualRow.start}px)`,
                    top: 0,
                    left: 0,
                    width: '100%',
                    minHeight: rowHeight ?? ROW_HEIGHT_TYPES_TO_PIXELS[RowHeight.SMALL],
                    boxSizing: 'border-box',
                    transition: 'transform 0.2s ease 0s',
                    ...(rowCanExpand && {
                        zIndex: 1,
                        minHeight: ROW_HEIGHT_TYPES_TO_PIXELS[RowHeight.SMALL]
                    })
                } }, RowComponent ? (React.createElement(RowComponent, { row: row })) : rowCanExpand && '_countInGroup' in row.original ? (React.createElement(React.Fragment, null,
                React.createElement("td", { className: "rt-td w-100 mh-100 d-flex flex-wrap z-1", onClick: row.getToggleExpandedHandler(), role: "button" },
                    React.createElement(ReactTableGroup, { row: row, width: tableContainerScrollerRef?.current?.clientWidth || 0, setExpandedMap: setExpandedMap, expandedPagination: expandedPagination, setExpandedPagination: setExpandedPagination })))) : (row.getVisibleCells().map((cell) => {
                const canResize = cell.column.getCanResize();
                return (React.createElement("td", { key: cell.id, className: cn('rt-td d-flex align-items-center', {
                        [cell.column.id]: TABLE_EXTRA_COLS.includes(cell.column.id),
                        'row-shadow': last(TABLE_EXTRA_COLS) === cell.column.id,
                        'flex-grow-0': !canResize
                    }), style: { width: cell.column.getSize() }, ...(rowCanExpand
                        ? {
                            onClick: () => {
                                toggleExpand(row);
                            },
                            role: 'button'
                        }
                        : {}) },
                    React.createElement(React.Fragment, null, flexRender(cell.column.columnDef.cell, cell.getContext()))));
            }))));
        }),
        children));
};
export const ReactTableBodyVirtualizedWindow = ({ rowHeight, loading, table, expandedPagination, setExpandedPagination, children, setExpandedMap, getRowComponent, getRowEstimateSize }) => {
    const { rows } = table.getRowModel();
    const rowVirtualizer = useWindowVirtualizer({
        count: rows.length,
        getItemKey: (index) => rows[index].id,
        estimateSize: (index) => {
            const row = rows[index];
            if (getRowEstimateSize) {
                return getRowEstimateSize(row);
            }
            /**
             * @TODO move to `getRowEstimateSize` of the corresponding component
             */
            return row.getCanExpand()
                ? ROW_HEIGHT_TYPES_TO_PIXELS[RowHeight.SMALL]
                : rowHeight || ROW_HEIGHT_TYPES_TO_PIXELS[RowHeight.SMALL];
        },
        overscan: 10
    });
    return (React.createElement(ReactTableBaseVirtualizedBody, { setExpandedMap: setExpandedMap, rowHeight: rowHeight, loading: loading, table: table, expandedPagination: expandedPagination, setExpandedPagination: setExpandedPagination, rowVirtualizer: rowVirtualizer, tableContainerScrollerRef: { current: null }, getRowComponent: getRowComponent }, children));
};
const ReactTableBodyVirtualized = ({ rowHeight, loading, table, expandedPagination, setExpandedPagination, children, tableContainerScrollerRef, setExpandedMap, getRowComponent, getRowEstimateSize }) => {
    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        getItemKey: (index) => rows[index].id,
        getScrollElement: () => tableContainerScrollerRef.current,
        estimateSize: (index) => {
            const row = rows[index];
            if (getRowEstimateSize) {
                return getRowEstimateSize(row);
            }
            /**
             * @TODO move to `getRowEstimateSize` of the corresponding component
             */
            return row.getCanExpand()
                ? ROW_HEIGHT_TYPES_TO_PIXELS[RowHeight.SMALL]
                : rowHeight || ROW_HEIGHT_TYPES_TO_PIXELS[RowHeight.SMALL];
        },
        overscan: 10
    });
    return (React.createElement(ReactTableBaseVirtualizedBody, { rowHeight: rowHeight, loading: loading, table: table, expandedPagination: expandedPagination, setExpandedPagination: setExpandedPagination, rowVirtualizer: rowVirtualizer, tableContainerScrollerRef: tableContainerScrollerRef, setExpandedMap: setExpandedMap, getRowComponent: getRowComponent }, children));
};
export default ReactTableBodyVirtualized;
