import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { FaRegDotCircle } from 'react-icons/fa';
import RichTextDisplay from '../../../cms/data/components/item-property/display/RichTextDisplay';
import { ContactDisplayName } from '../../../crm';
export const ReleaseNoteHistoryItem = ({ version, entityOwner, value, actual }) => {
    const { t } = useI18n();
    return (React.createElement("div", { key: version, className: "border rounded-4 p-3 d-flex flex-column gap-1" },
        React.createElement("div", { className: "d-flex align-items-center justify-content-between" },
            React.createElement(ContactDisplayName, { contactId: entityOwner }),
            React.createElement("div", { className: "d-flex align-items-center" },
                React.createElement("span", null, moment(version).format('HH:mm')),
                actual && (React.createElement(React.Fragment, null,
                    React.createElement(Divider, { type: "vertical" }),
                    React.createElement(FaRegDotCircle, { className: "me-2", color: "var(--neutralColor-1)" }),
                    React.createElement(Typography.Text, null, t('COMMON_ACTUAL')))))),
        React.createElement("div", { className: "border rounded-4 px-2 " },
            React.createElement(RichTextDisplay, { className: "p-0", value: value }))));
};
