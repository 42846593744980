import { ConditionType, RowHeight } from '@hypercharge/portal-utils';
import { INITIAL_PAGE, INITIAL_PAGE_SIZE } from 'config';
import { fromPairs, get, head, intersection, isArray, isEmpty, isEqual, isNil, isNull, isString, isUndefined, omit, pickBy, sortBy, toPairs, toString, xorWith } from 'lodash';
import hash from 'object-hash';
import queryString from 'query-string';
import { TITLE_PROPERTY_ID } from '~app/cms';
import { DESIGN_FIELDS_FOR_KANBAN } from '~app/cms/data/components/items/Kanban/KanbanContainer';
import { isPropertyItemMeta } from '~app/cms/data/types';
import { isWorkflowDefinition } from '~app/common/utils/url';
import { ALL_TASKS_DEFINITION_ID } from '~app/workflows';
import { ACTIVITY_YOUR_DASHBOARD, WORKFLOWS_YOUR_DASHBOARD } from '~app/workflows/common/utils/constants';
import { AggregationTypes } from './components/metrics/types';
import { COLUMNS, CONDITION_QUERY_KEY, DEFAULT_COLUMNS_VIEW, DEFAULT_FILTERS_CONDITION, DEFAULT_SORT_BY_FIELDS_VIEW, DEFAULT_VIEW_ID_SUFFIX, FULL_TEXT, KANBAN_KEY, METRICS_KEY, ROW_GROUPING, ROW_GROUPING_ORDER_BY, ROW_HEIGHT, SORT_BY_KEY, URL_STRINGIFY_SEPARATOR, VIEW_TYPE_BY_KEY } from './constants';
import { isSortOrder } from './types';
function isAggregationType(candidate) {
    return Object.keys(AggregationTypes).includes(candidate);
}
function encodeUtf8Base64(str) {
    const utf8Bytes = new TextEncoder().encode(str);
    return btoa(String.fromCharCode(...utf8Bytes));
}
function decodeUtf8Base64(base64Str) {
    const binaryStr = atob(base64Str);
    const utf8Bytes = Uint8Array.from(binaryStr, (char) => char.charCodeAt(0));
    return new TextDecoder().decode(utf8Bytes);
}
export const getQueryParams = (search, options) => {
    // When redirecting after login, the query string may be URL-encoded.
    // Decode the search string if it's encoded to ensure correct parsing.
    try {
        const decodedSearch = decodeURIComponent(search.slice(1));
        return JSON.parse(decodeUtf8Base64(decodedSearch));
    }
    catch (e) {
        return queryString.parse(decodeURIComponent(search), {
            // it is needed for suport old url
            arrayFormat: 'separator',
            arrayFormatSeparator: '|',
            splitEncodedValues: false,
            ...options
        });
    }
};
export const getUpdatedLocation = (queryParams) => {
    return {
        hash: encodeUtf8Base64(JSON.stringify(queryParams))
    };
};
const parseConditionQuery = (locationSearch, view) => {
    let locationFilters = locationSearch[CONDITION_QUERY_KEY];
    if (!locationFilters) {
        return (view?.filters.query || {
            condition: DEFAULT_FILTERS_CONDITION,
            filters: []
        });
    }
    if (Array.isArray(locationFilters)) {
        locationFilters = locationFilters[0];
    }
    if (typeof locationFilters === 'number') {
        locationFilters = locationFilters.toString();
    }
    try {
        return JSON.parse(locationFilters);
    }
    catch (e) {
        return {
            condition: DEFAULT_FILTERS_CONDITION,
            filters: []
        };
    }
};
export const stringifyConditionQuery = (value) => {
    return JSON.stringify(value);
};
export const parseKanbanFilter = (locationSearch) => {
    if (!locationSearch[KANBAN_KEY]) {
        return;
    }
    try {
        return JSON.parse(locationSearch[KANBAN_KEY]);
    }
    catch (e) {
        return;
    }
};
export const stringifyKanbanFilter = (value) => {
    return JSON.stringify(value);
};
const getDefaultColumnPropertyIds = (view) => view?.filters?.fullTextFields || [TITLE_PROPERTY_ID];
export const getColumnPropertyIdsFromParams = (locationSearch, view) => {
    const locationColumnsArray = getLocationColumns(locationSearch);
    return isEmpty(locationColumnsArray) ? getDefaultColumnPropertyIds(view) : locationColumnsArray;
};
const getLocationSortBy = (locationSearch) => {
    const locationSortBy = locationSearch?.[SORT_BY_KEY] || [];
    const stringSortByInArray = (isArray(locationSortBy) ? locationSortBy : [locationSortBy]).map(String);
    return stringSortByInArray.map((sortBy) => {
        const [field, order] = sortBy.split(URL_STRINGIFY_SEPARATOR);
        if (isSortOrder(order)) {
            return { field, order };
        }
        else {
            throw new Error(`Invalid SortOrder for the data in the location search string. Value - ${order}`);
        }
    });
};
export const getSortByFromParams = (locationSearch, defaultSortBy, viewablePropertyIds) => {
    const sortBy = getLocationSortBy(locationSearch);
    const sortByFiltered = viewablePropertyIds
        ? sortBy.filter(({ field }) => viewablePropertyIds.includes(field))
        : sortBy;
    return isEmpty(sortByFiltered) ? defaultSortBy : sortByFiltered;
};
export const getHasCustomSortBy = (locationSearch, defaultSortBy, viewablePropertyIds, view) => {
    const sortBy = getLocationSortBy(locationSearch);
    const filteredSortBy = viewablePropertyIds
        ? sortBy.filter(({ field }) => viewablePropertyIds.includes(field))
        : sortBy;
    return (!isEmpty(filteredSortBy) &&
        !isEqual(xorWith(defaultSortBy, filteredSortBy, isEqual), view?.filters.sortBy));
};
export const getParamsForNewRoute = ({ newFilters, newMetrics, queryParams, viewType, rowHeightType, kanban, rowGrouping, rowGroupingOrderBy }) => {
    const newParams = {};
    if (newFilters[SORT_BY_KEY]) {
        newParams[SORT_BY_KEY] = newFilters[SORT_BY_KEY].map(({ field, order }) => [field, order].join(URL_STRINGIFY_SEPARATOR));
    }
    if (kanban) {
        newParams[KANBAN_KEY] = stringifyKanbanFilter(kanban);
    }
    if (queryParams[FULL_TEXT]) {
        // The fullTextSearch is not included in the view form, but is managed in the data table
        // Therefore we have to rely on what's in current queryParams and pass it through
        newParams[FULL_TEXT] = queryParams[FULL_TEXT];
    }
    if (rowHeightType) {
        newParams[ROW_HEIGHT] = rowHeightType;
    }
    if (rowGrouping) {
        newParams[ROW_GROUPING] = rowGrouping;
    }
    if (rowGroupingOrderBy) {
        newParams[ROW_GROUPING_ORDER_BY] = rowGroupingOrderBy;
    }
    if (newFilters.limit !== INITIAL_PAGE_SIZE) {
        newParams.pageSize = newFilters.limit;
    }
    if (newFilters.fullTextFields) {
        newParams[COLUMNS] = newFilters.fullTextFields;
    }
    if (newFilters.query && newFilters.query.filters) {
        newParams[CONDITION_QUERY_KEY] = stringifyConditionQuery(newFilters.query);
    }
    newParams[METRICS_KEY] = !isEmpty(newMetrics) ? newMetrics.map(stringifyMetric) : null;
    if (viewType) {
        newParams.viewType = viewType;
    }
    return newParams;
};
export const getHashForUrl = ({ newFilters, newMetrics, viewType, queryParams, kanban }) => {
    const newParams = getParamsForNewRoute({
        newFilters,
        newMetrics,
        queryParams,
        viewType,
        kanban
    });
    const { hash } = getUpdatedLocation({ ...newParams, page: INITIAL_PAGE });
    return hash;
};
const getLocationViewType = (locationSearch) => locationSearch?.[VIEW_TYPE_BY_KEY];
export const getLocationRowHeight = (locationSearch) => locationSearch?.[ROW_HEIGHT];
export const getLocationRowGrouping = (locationSearch) => locationSearch?.[ROW_GROUPING];
export const getLocationRowGroupingOrderBy = (locationSearch) => locationSearch?.[ROW_GROUPING_ORDER_BY];
const getLocationColumns = (locationSearch) => {
    const locationColumns = locationSearch[COLUMNS] || [];
    const locationColumnsArray = isArray(locationColumns) ? locationColumns : [locationColumns];
    return locationColumnsArray.map((item) => item.toString());
};
export const getHasCustomViewType = (locationSearch, view) => {
    const locationViewType = getLocationViewType(locationSearch);
    if (!locationViewType) {
        return false;
    }
    const viewTypeInView = view?.viewType;
    return locationViewType !== viewTypeInView;
};
export const getHasCustomKanban = (locationSearch, view) => {
    const locationViewType = getLocationViewType(locationSearch);
    const locationKanban = parseKanbanFilter(locationSearch);
    if (!locationViewType || !locationKanban) {
        return false;
    }
    const viewTypeInView = view?.viewType;
    const kanbanInView = view?.configuration?.kanban;
    const viewTypeChanged = locationViewType !== viewTypeInView;
    const kanbanChanged = !isEqual(locationKanban, kanbanInView);
    return kanbanChanged || viewTypeChanged;
};
export const getColumnsFromParams = (locationSearch, defaultColumns, viewablePropertyIds = []) => {
    const columns = intersection(getLocationColumns(locationSearch), viewablePropertyIds);
    return isEmpty(columns) ? defaultColumns : columns;
};
export const getHasCustomColumns = (locationSearch, defaultColumns, viewablePropertyIds = []) => {
    const columns = intersection(getLocationColumns(locationSearch), viewablePropertyIds);
    return !isEmpty(columns) && !isEqual(defaultColumns, columns);
};
export const getTableRowHeightType = (locationSearch, predefinedRowHeightType, defaultRowHeightType) => {
    const rowHeight = getLocationRowHeight(locationSearch);
    return rowHeight ?? predefinedRowHeightType ?? defaultRowHeightType ?? RowHeight.SMALL;
};
export const getTableRowGrouping = (locationSearch, defaultRowGrouping) => !isEmpty(locationSearch) ? getLocationRowGrouping(locationSearch) : defaultRowGrouping;
export const getTableRowGroupingOrderBy = (locationSearch, defaultRowGroupingOrderBy) => !isEmpty(locationSearch)
    ? getLocationRowGroupingOrderBy(locationSearch)
    : defaultRowGroupingOrderBy;
export const getHasCustomMetrics = (locationSearch, defaultMetrics, viewablePropertyIds = []) => {
    const metrics = getMetricsFromParams(locationSearch, defaultMetrics, viewablePropertyIds);
    const getFormattedMetrics = (metrics) => metrics.map((metric) => omit(fromPairs(sortBy(toPairs(metric), ([key]) => key)), [
        'id',
        ...(!metric.inPercents ? ['inPercents'] : []),
        ...(!metric.query ? ['query'] : [])
    ]));
    return !isEqual(getFormattedMetrics(defaultMetrics), getFormattedMetrics(metrics));
};
export const getHasCustomRowHeight = (locationSearch, view) => {
    const rowHeight = getLocationRowHeight(locationSearch);
    return !!rowHeight && rowHeight !== view?.configuration?.rowHeightType;
};
export const getHasCustomRowGrouping = (locationSearch, view) => {
    const rowGrouping = getLocationRowGrouping(locationSearch);
    return !isEmpty(locationSearch) && rowGrouping !== view?.configuration?.rowGrouping;
};
export const getHasCustomRowGroupingOrderBy = (locationSearch, view) => {
    const rowGroupingOrderBy = getLocationRowGroupingOrderBy(locationSearch);
    return !isEmpty(locationSearch) && rowGroupingOrderBy !== view?.configuration?.rowGroupingOrderBy;
};
export const getHasCustomPage = (locationSearch) => 'page' in locationSearch && typeof locationSearch.page === 'number' && locationSearch.page > 1;
export const getFilterRequest = (locationSearch, language, page, pageSize, columns, sortBy, view) => {
    return {
        query: parseConditionQuery(locationSearch, view),
        sortBy,
        fullText: toString(locationSearch[FULL_TEXT] || ''),
        fullTextFields: columns,
        responseFields: columns,
        limit: pageSize,
        offset: (page - 1) * pageSize,
        languageCode: language
    };
};
export const stringifyMetric = ({ titles, metric, field, query, units, selected, inPercents, rowGrouping, rowGroupingOrderBy, size, aggregationId, activeGroupingElements, }) => {
    const stringifyMetricValues = [JSON.stringify(titles), metric, field];
    stringifyMetricValues.push(query ? JSON.stringify(query) : '_');
    stringifyMetricValues.push(units ? JSON.stringify(units) : '_');
    stringifyMetricValues.push(isUndefined(selected) ? '_' : JSON.stringify(selected));
    stringifyMetricValues.push(isUndefined(inPercents) ? '_' : JSON.stringify(inPercents));
    stringifyMetricValues.push(isUndefined(rowGrouping) ? '_' : rowGrouping);
    stringifyMetricValues.push(isUndefined(rowGroupingOrderBy) ? '_' : rowGroupingOrderBy);
    stringifyMetricValues.push(isUndefined(size) ? '_' : JSON.stringify(size));
    stringifyMetricValues.push(isUndefined(aggregationId) ? '_' : aggregationId);
    stringifyMetricValues.push(isUndefined(activeGroupingElements) || !activeGroupingElements.length
        ? '_'
        : JSON.stringify(activeGroupingElements));
    return stringifyMetricValues.join(URL_STRINGIFY_SEPARATOR);
};
export const buildMetricId = ({ titles, metric, field, query }) => {
    return (stringifyMetric({ titles, metric, field }) +
        (!query || isEmpty(query) || query.filters?.length === 0
            ? ''
            : URL_STRINGIFY_SEPARATOR + hash(query)));
};
const getLocationMetrics = (locationSearch, viewablePropertyIds) => {
    const locationMetrics = get(locationSearch, METRICS_KEY);
    if (isNull(locationMetrics)) {
        return null;
    }
    if (isUndefined(locationMetrics)) {
        return;
    }
    const serializedMetrics = isArray(locationMetrics) ? locationMetrics : [locationMetrics];
    const metrics = serializedMetrics
        .filter(isString)
        .reduce((acc, metricString) => {
        const [titlesText, aggregationTypeText, field, queryText, unitsText, selectedText, inPercentsText, rowGroupingText, rowGroupingOrderByText, sizeText, aggregationIdText, activeGroupingElementsText,] = metricString.split(URL_STRINGIFY_SEPARATOR);
        if (!isAggregationType(aggregationTypeText)) {
            return acc;
        }
        const aggregationType = aggregationTypeText;
        const titles = JSON.parse(titlesText);
        const activeGroupingElements = activeGroupingElementsText == '_' || isUndefined(activeGroupingElementsText)
            ? undefined
            : JSON.parse(activeGroupingElementsText);
        const query = queryText == '_' || isUndefined(queryText)
            ? undefined
            : JSON.parse(queryText);
        const units = unitsText == '_' || isUndefined(unitsText)
            ? {}
            : JSON.parse(unitsText);
        const selected = selectedText == 'true';
        const inPercents = inPercentsText == 'true';
        const rowGrouping = rowGroupingText == '_' ? undefined : rowGroupingText;
        const rowGroupingOrderBy = rowGroupingOrderByText == '_'
            ? undefined
            : rowGroupingOrderByText;
        const size = sizeText == '_' ? undefined : JSON.parse(sizeText);
        const aggregationId = aggregationIdText == '_' ? undefined : aggregationIdText;
        const id = buildMetricId({ titles, metric: aggregationType, field, query });
        const metricGrouping = !isUndefined(rowGrouping)
            ? {
                rowGrouping,
                rowGroupingOrderBy,
                size,
                aggregationId,
                activeGroupingElements,
            }
            : {};
        const cleanedMetricGrouping = pickBy(metricGrouping, (mg) => !isNil(mg));
        const metric = {
            id,
            titles,
            metric: aggregationType,
            field,
            query,
            units,
            selected,
            inPercents,
            ...cleanedMetricGrouping
        };
        acc.push(metric);
        return acc;
    }, [])
        .filter(({ field }) => {
        const dividedPropertyIds = field.split('.');
        return viewablePropertyIds.includes(dividedPropertyIds.length > 1 ? head(dividedPropertyIds) || '' : field);
    });
    return metrics;
};
export const getMetricsFromParams = (locationSearch, defaultMetrics, viewablePropertyIds = []) => {
    const metrics = getLocationMetrics(locationSearch, viewablePropertyIds);
    return isUndefined(metrics) ? defaultMetrics : metrics || [];
};
export function getDefaultView(definitionId) {
    return {
        entityId: `${definitionId}${DEFAULT_VIEW_ID_SUFFIX}`,
        title: 'Default: view',
        isDefault: true,
        metrics: [],
        referenceDefinitionId: definitionId,
        filters: {
            sortBy: DEFAULT_SORT_BY_FIELDS_VIEW,
            fullTextFields: DEFAULT_COLUMNS_VIEW,
            responseFields: DEFAULT_COLUMNS_VIEW,
            query: {
                condition: DEFAULT_FILTERS_CONDITION,
                filters: []
            }
        }
    };
}
export const getColumnDefinitionIdKanbanGroupBy = (displayData, groupBy) => {
    const foundPropertyGroupBy = displayData?.data
        .flatMap((dt) => dt.values)
        .find((value) => value.propertyId === groupBy);
    if (isPropertyItemMeta(foundPropertyGroupBy?.meta)) {
        return foundPropertyGroupBy?.meta.definitionId;
    }
};
export const getSystemFieldsForKanban = (definitionId, displayItemMeta) => {
    const isProcess = isWorkflowDefinition(displayItemMeta);
    const isTasks = isProcess && definitionId === ALL_TASKS_DEFINITION_ID;
    return isProcess
        ? isTasks
            ? DESIGN_FIELDS_FOR_KANBAN.workflow.task
            : DESIGN_FIELDS_FOR_KANBAN.workflow.process
        : (definitionId && DESIGN_FIELDS_FOR_KANBAN.cms[definitionId]) ||
            DESIGN_FIELDS_FOR_KANBAN.cms.default;
};
export const getFilterRequestWithAdditionalFiltersForSearch = (filterRequest, additionalFilters) => {
    if (additionalFilters) {
        return {
            ...filterRequest,
            query: {
                condition: ConditionType.and,
                filters: [filterRequest.query, ...additionalFilters]
            }
        };
    }
    return { ...filterRequest };
};
export const getKeyForViewToSaveToStorage = (definitionId, locationPathname) => {
    if (locationPathname?.includes(ACTIVITY_YOUR_DASHBOARD)) {
        return ACTIVITY_YOUR_DASHBOARD;
    }
    if (locationPathname?.includes(WORKFLOWS_YOUR_DASHBOARD)) {
        return WORKFLOWS_YOUR_DASHBOARD;
    }
    if (definitionId) {
        return definitionId;
    }
    throw new Error(`Parameters must be specified`);
};
export const getStoredViewInLocalStorage = ({ definitionId, contactId, viewIdMapInStorage, pathname }) => {
    const idKey = getKeyForViewToSaveToStorage(definitionId, pathname);
    const storedViewId = contactId ? viewIdMapInStorage[contactId]?.[idKey] : null;
    return storedViewId;
};
export const isDefaultViewId = (viewId, isUserDashboardsView = false) => isUserDashboardsView
    ? viewId.includes(`${DEFAULT_VIEW_ID_SUFFIX}__`)
    : viewId.endsWith(DEFAULT_VIEW_ID_SUFFIX);
