import { BasicInput, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Radio } from 'antd';
import { toString } from 'lodash';
import React, { Fragment } from 'react';
import FormikError from '../../../../../common/components/formik/FormikError';
import FormikField from '../../../../../common/components/formik/FormikField';
import { SelectPropertyField } from '../../item-property/SelectProperty';
import CreateIfNotExists from '../common/CreateIfNotExistsConfig';
import { numberFormatOptions, unsupportedPropertyTypes } from '../constants';
import { getEntityTitle } from '../utils';
import styles from './TxtPropertyConfig.module.scss';
const PropertyConfig = ({ propertyConfig, property, name, entities, columns, values }) => {
    const { t } = useI18n();
    return (React.createElement(Fragment, null,
        React.createElement(LabelText, null, t('CMS_IMPORTER__COLUMN')),
        React.createElement(FormikField, { name: `${name}.txtColumnIndex`, component: BasicInput, className: "w-100", value: propertyConfig && propertyConfig.txtColumnIndex >= 0
                ? toString(propertyConfig.txtColumnIndex)
                : null }),
        React.createElement(FormikError, { name: `${name}.txtColumnIndex` }),
        React.createElement(LabelText, null, t('PROPERTY_COMPUTED_EXPRESSION')),
        React.createElement(FormikField, { name: `${name}.computedProperty`, component: BasicInput, className: "w-100", value: propertyConfig && propertyConfig.computedProperty
                ? toString(propertyConfig.computedProperty)
                : '' }),
        property.type === 'number' && (React.createElement(Fragment, null,
            React.createElement(LabelText, { className: "mt-3" }, t('CMS_IMPORTER__NUMBER_FORMAT')),
            React.createElement(FormikField, { name: `${name}.numberFormat`, component: ({ input }) => (React.createElement(Radio.Group, { value: propertyConfig && toString(propertyConfig.numberFormat), onChange: (e) => {
                        input.onChange(e.target.value);
                    } }, numberFormatOptions.map(({ id, display }) => (React.createElement(Radio, { key: id, className: `${styles.numberFormatRadioStyle} d-block`, value: id }, display))))) }))),
        property.type === 'entity' && (React.createElement(Fragment, null,
            React.createElement(FormikField, { name: `${name}.propertyId`, placeholder: t('SELECT_PROPERTY'), label: t('CMS_IMPORTER__CMS_LOOKUP_PROPERTY_LABEL', {
                    entityTitle: getEntityTitle(property.meta.definitionId, entities)
                }), value: propertyConfig && toString(propertyConfig.propertyId), component: SelectPropertyField, className: "mt-3", definitionId: property.meta.definitionId, includeProperty: ({ type }) => !unsupportedPropertyTypes.includes(type), includeNestedProperties: false }),
            property.meta.list && (React.createElement(FormikField, { name: `${name}.listSeparator`, className: `${styles.listSeparatorStyle} mt-3`, placeholder: t('CMS_IMPORTER__LIST_SEPARATOR_PLACEHOLDER'), label: t('CMS_IMPORTER__LIST_SEPARATOR'), component: BasicInput, value: propertyConfig && toString(propertyConfig.listSeparator) })),
            React.createElement(CreateIfNotExists, { propertyConfig: propertyConfig, property: property, name: name, entities: entities, columns: columns, values: values }))),
        property.type === 'multitext' && (React.createElement(FormikField, { name: `${name}.listSeparator`, className: `${styles.listSeparatorStyle} mt-3`, placeholder: t('CMS_IMPORTER__LIST_SEPARATOR_PLACEHOLDER'), label: t('CMS_IMPORTER__LIST_SEPARATOR'), component: BasicInput, value: propertyConfig && toString(propertyConfig.listSeparator) })),
        property.type === 'file' && property.meta.list && (React.createElement(FormikField, { name: `${name}.listSeparator`, className: `${styles.listSeparatorStyle} mt-3`, placeholder: t('CMS_IMPORTER__LIST_SEPARATOR_PLACEHOLDER'), label: t('CMS_IMPORTER__LIST_SEPARATOR'), component: BasicInput, value: propertyConfig && toString(propertyConfig.listSeparator) })),
        property.type === 'date' && (React.createElement(Fragment, null,
            React.createElement(FormikField, { name: `${name}.dateFormat`, placeholder: t('CMS_IMPORTER__DATE_FORMAT_PLACEHOLDER'), label: t('CMS_IMPORTER__DATE_FORMAT'), className: "mt-3", component: BasicInput, value: propertyConfig && toString(propertyConfig.dateFormat) }),
            React.createElement(FormikField, { name: `${name}.timezone`, placeholder: t('CMS_IMPORTER__TIMEZONE_PLACEHOLDER'), label: t('CMS_IMPORTER__TIMEZONE'), component: BasicInput, value: propertyConfig && toString(propertyConfig.timezone) })))));
};
export default PropertyConfig;
