import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaLink } from 'react-icons/fa';
import { GoDatabase } from 'react-icons/go';
import { IoMdGitNetwork } from 'react-icons/io';
import DropdownArrowButton from '../../../../../common/components/DropdownArrowButton';
import DropdownMenu from '../../../../../common/components/DropdownMenu';
import Toggle from '../../../../../common/components/Toggle';
import TooltipTruncate from '../../../../../common/components/TooltipTruncate/TooltipTruncate';
import SideMenuIcon from '../../../../../common/components/side-menu/SideMenuIcon/SideMenuIcon';
import ReferredItemsTableContainer from '../../item/ReferredItemsTableContainer';
import { TableFields } from '../TableFields';
import styles from './DataList.module.scss';
import DataListTabs from './DataListTabs';
import SectionList from './SectionList';
const referralsToDropdownMenuItems = (referrals, entitiesDisplayMetaMap) => referrals.map((referral) => ({
    key: referral.definitionId,
    label: referral.title,
    icon: (React.createElement(SideMenuIcon, { itemIcon: referral.isProcess ? IoMdGitNetwork : GoDatabase, style: entitiesDisplayMetaMap[referral.definitionId]?.style || {} }))
}));
const checkHasRequiredFieldsInSections = (requiredFieldsConfig, dataListSections) => dataListSections?.some((data) => data.values.some(({ propertyId }) => requiredFieldsConfig.isRequiredField(propertyId)));
const REFERRALS_TAB = '__referrals';
const DataList = ({ className, definitionId, entityId, dataList, canEdit = true, doubleCol = false, requiredFieldsConfig, permissionFieldsConfig, uploadFiles, updatePropertyData, showRequiredFieldsSwitcher, itemReferrals, highlightedReferrals, entitiesDisplayMeta, showAllProperties = true }) => {
    const { t, language } = useI18n();
    const [onlyRequiredFields, setOnlyRequiredFields] = useState(false);
    const [selectedReferralDefinitionId, setSelectedReferralDefinitionId] = useState('');
    const [activeTabKey, setActiveTabKey] = useState('');
    const [isActivePinnedSection, setIsActivePinnedSection] = useState(true);
    const selectedReferral = useMemo(() => selectedReferralDefinitionId
        ? itemReferrals.find((referral) => referral.definitionId === selectedReferralDefinitionId)
        : undefined, [selectedReferralDefinitionId, itemReferrals]);
    const entitiesDisplayMetaMap = useMemo(() => entitiesDisplayMeta.reduce((acc, entity) => {
        acc[entity.definitionId] = entity;
        return acc;
    }, {}), [entitiesDisplayMeta]);
    const itemReferralsDropdownMenuItemsNew = useMemo(() => {
        const items = [];
        const commonReferralsItems = [];
        const highlightedReferralsItems = [];
        itemReferrals.forEach((referral) => {
            if (highlightedReferrals.includes(referral.definitionId)) {
                highlightedReferralsItems.push(referral);
            }
            else {
                commonReferralsItems.push(referral);
            }
        });
        if (highlightedReferralsItems.length) {
            items.push({
                key: 'referrals',
                type: 'group',
                label: t('HIGHLIGHTED_REFERRALS_NAV_LINK'),
                children: referralsToDropdownMenuItems(highlightedReferralsItems, entitiesDisplayMetaMap)
            });
        }
        if (commonReferralsItems.length) {
            items.push({
                key: 'common',
                type: 'group',
                label: t('REFERRALS'),
                children: referralsToDropdownMenuItems(commonReferralsItems, entitiesDisplayMetaMap)
            });
        }
        return items;
    }, [itemReferrals, highlightedReferrals, entitiesDisplayMetaMap, t]);
    const getSectionsData = useCallback((sections) => {
        return sections
            .filter((section) => !section.pinned)
            .map((section) => {
            return {
                ...section,
                values: onlyRequiredFields && requiredFieldsConfig?.hasRequiredFields
                    ? section.values.filter(({ propertyId }) => requiredFieldsConfig.isRequiredField(propertyId))
                    : section.values
            };
        });
    }, [onlyRequiredFields, requiredFieldsConfig]);
    const dataListTabs = useMemo(() => {
        return dataList?.tabs?.reduce((tabs, tab) => {
            const sectionsData = getSectionsData(tab.sections);
            if (onlyRequiredFields && requiredFieldsConfig?.hasRequiredFields) {
                if (checkHasRequiredFieldsInSections(requiredFieldsConfig, tab.sections)) {
                    tabs.push({ ...tab, sections: sectionsData });
                }
            }
            else {
                tabs.push({ ...tab, sections: sectionsData });
            }
            return tabs;
        }, []);
    }, [onlyRequiredFields, dataList, requiredFieldsConfig, getSectionsData]);
    const pinnedSection = useMemo(() => {
        return dataList?.tabs?.reduce((pinnedSection, tab) => {
            const section = tab.sections.find((section) => section.pinned);
            if (section) {
                return section;
            }
            return pinnedSection;
        }, undefined);
    }, [dataList]);
    const handleReferral = useCallback((id) => {
        setSelectedReferralDefinitionId(id);
        setActiveTabKey(REFERRALS_TAB);
    }, []);
    const handleTabChange = useCallback((tab) => {
        if (tab !== REFERRALS_TAB) {
            setSelectedReferralDefinitionId('');
        }
        setActiveTabKey(tab);
    }, []);
    const tabBarExtraContent = useMemo(() => itemReferrals?.length ? (itemReferrals.length === 1 ? (React.createElement(React.Fragment, null, itemReferrals.map((referral) => (React.createElement("div", { key: referral.definitionId, role: "button", className: `position-relative d-flex align-items-center py-2 px-3 ${styles.referralsTab} ${activeTabKey === REFERRALS_TAB ? 'active' : ''}`, onClick: (event) => {
            event.stopPropagation();
            handleReferral(referral.definitionId);
        } },
        React.createElement("div", { className: "layout-referrals d-flex align-items-center" },
            React.createElement(SideMenuIcon, { className: styles.referralsTabIcon, itemIcon: referral.isProcess ? IoMdGitNetwork : GoDatabase, style: entitiesDisplayMetaMap[referral.definitionId]?.style || {} }),
            React.createElement(TooltipTruncate, { className: "referralsLabel fs-6 me-3" }, referral.title))))))) : (React.createElement(DropdownMenu, { searchPlaceholder: t('REFERRALS_SEARCH'), menuItems: itemReferralsDropdownMenuItemsNew, onMenuItemClick: handleReferral },
        React.createElement("div", { className: `position-relative py-2 px-3 ${styles.referralsTab} ${activeTabKey === REFERRALS_TAB ? 'active' : ''}`, role: "button" },
            React.createElement("div", { className: "layout-referrals d-flex align-items-center" },
                selectedReferral ? (React.createElement(SideMenuIcon, { className: styles.referralsTabIcon, itemIcon: selectedReferral.isProcess ? IoMdGitNetwork : GoDatabase, style: entitiesDisplayMetaMap[selectedReferral.definitionId].style || {} })) : (React.createElement(FaLink, { className: styles.referralsTabIcon })),
                React.createElement(TooltipTruncate, { className: "referralsLabel fs-6 me-3" }, selectedReferral?.title || t('REFERRALS')),
                React.createElement(DropdownArrowButton, { className: cn('rounded-circle arrow-button', styles.arrowButton) })))))) : undefined, [
        activeTabKey,
        entitiesDisplayMetaMap,
        handleReferral,
        itemReferrals,
        itemReferralsDropdownMenuItemsNew,
        selectedReferral,
        t
    ]);
    const onPinnedSectionToggle = useCallback(() => setIsActivePinnedSection((prevState) => !prevState), []);
    useEffect(() => {
        if (!dataListTabs?.length || activeTabKey === REFERRALS_TAB) {
            return;
        }
        if (!activeTabKey) {
            setActiveTabKey(dataListTabs[0].tabId);
        }
        else {
            const currentTab = dataListTabs?.find((tab) => tab.tabId === activeTabKey);
            if (!currentTab) {
                setActiveTabKey(dataListTabs[0].tabId);
            }
        }
    }, [activeTabKey, dataListTabs, showRequiredFieldsSwitcher]);
    return (React.createElement(React.Fragment, null,
        showRequiredFieldsSwitcher && !!requiredFieldsConfig && (React.createElement(Toggle, { onChange: setOnlyRequiredFields, checked: onlyRequiredFields, disabled: !requiredFieldsConfig.hasRequiredFields, label: t('CMS_ONLY_SHOW_REQUIRED'), className: "pb-3" })),
        dataList.expandedProperties && (React.createElement(TableFields, { fromExpandedView: true, fieldsData: dataList.expandedProperties, doubleCol: doubleCol && !!dataList.expandedProperties?.length, canEdit: canEdit, definitionId: definitionId, entityId: entityId, updatePropertyData: updatePropertyData, uploadFiles: uploadFiles, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig })),
        showAllProperties && !!dataListTabs?.length && (React.createElement("div", { className: `${styles.wrapper} ${(dataList.tabs?.length || 0) < 2 && !itemReferrals?.length ? 'hide-tab-panel' : ''} ${className || ''} DataListOuter` },
            React.createElement(DataListTabs, { className: "tabs-with-styled-more-btn", activeKey: activeTabKey, type: "card", moreIcon: React.createElement(BsThreeDotsVertical, { size: 22 }), onChange: handleTabChange, tabBarExtraContent: tabBarExtraContent, items: dataListTabs?.map((tab, indx) => ({
                    key: tab.tabId,
                    label: (React.createElement("div", { className: "fw-400 fs-6 tabLabel" },
                        React.createElement("div", { className: "text-ellipsis layout-field" },
                            React.createElement(TooltipTruncate, null, getTranslation(tab.titles, language))))),
                    children: (React.createElement(SectionList, { className: cn('section overflow-hidden', {
                            'first-section': indx === 0
                        }), definitionId: definitionId, entityId: entityId, canEdit: canEdit, sectionsData: tab.sections, pinnedSectionData: pinnedSection, activePinnedSection: isActivePinnedSection, onPinnedSectionToggle: onPinnedSectionToggle, uploadFiles: uploadFiles, updatePropertyData: updatePropertyData, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, doubleCol: doubleCol }))
                })) }),
            !!selectedReferral && (React.createElement(ReferredItemsTableContainer, { key: definitionId, baseEntityId: entityId, definitionId: selectedReferral.definitionId, referredPropertyIds: selectedReferral.propertyIds, isProcess: selectedReferral.isProcess, virtualMode: true }))))));
};
export default DataList;
