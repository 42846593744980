import NotFound from '@hypercharge/hyper-react-base/lib/common/not-found';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { FaBars } from 'react-icons/fa';
import { CMS_PATH } from '../../../../cms';
import ItemsTable from '../../../../cms/data/components/items/ItemsTable';
import useDisplayItemMeta from '../../../../cms/hooks/useDisplayItemMeta';
import { SELECTED_VIEWS_STORAGE_KEY } from '../../../../common/components/data-table/ViewSelector';
import LoadingIndicator from '../../../../common/components/loading-indicator';
import { useStorage } from '../../../../common/context/StorageContext';
import { PROCESS_META_HANDLER, VIEW_PATH } from '../../../../common/utils/constants';
import { getEntityBrowseUrl, getEntityViewUrl } from '../../../../common/utils/url';
import { useMyContact } from '../../../../crm/components/use-my-contact';
import { ViewProvider, ViewTitle, useViews } from '../../../../views';
import { getDefaultView, getStoredViewInLocalStorage, isDefaultViewId } from '../../../../views/utils';
import { PROCESSES_PATH, WORKFLOWS_PATH } from '../../../../workflows';
import styles from './TableWidget.module.scss';
const TableWidget = ({ className, settings, defaultFilter }) => {
    const { t, language } = useI18n();
    const { contactId } = useMyContact();
    const [viewIdMapInStorage] = useStorage({
        key: SELECTED_VIEWS_STORAGE_KEY,
        defaultValue: {}
    });
    const { loadingViews, views } = useViews();
    const { data: displayItemMeta, isError: isFailed, isLoading } = useDisplayItemMeta({ definitionId: settings.definitionId });
    const isProcess = displayItemMeta?.handler === PROCESS_META_HANDLER;
    const baseUrl = isProcess ? `${WORKFLOWS_PATH}${PROCESSES_PATH}` : CMS_PATH;
    const baseUrlWithDefinition = isProcess
        ? `${WORKFLOWS_PATH}${PROCESSES_PATH}/${settings.definitionId}`
        : `${CMS_PATH}/${settings.definitionId}`;
    const urlWithStoredView = useMemo(() => {
        const storedViewId = getStoredViewInLocalStorage({
            definitionId: settings.definitionId,
            contactId,
            viewIdMapInStorage
        });
        return storedViewId && !isDefaultViewId(storedViewId)
            ? getEntityViewUrl(settings.definitionId, storedViewId, baseUrl)
            : getEntityBrowseUrl(settings.definitionId, baseUrl);
    }, [baseUrl, contactId, settings.definitionId, viewIdMapInStorage]);
    const view = useMemo(() => {
        let foundedView;
        foundedView = views.find((item) => {
            if (item.referenceDefinitionId !== settings.definitionId) {
                return false;
            }
            if (settings.viewId) {
                return item.entityId === settings.viewId;
            }
            else {
                return item.isDefault;
            }
        });
        if (!foundedView) {
            foundedView = getDefaultView(settings.definitionId);
        }
        const newQuery = {
            condition: ConditionType.and,
            filters: [foundedView.filters.query]
        };
        if (defaultFilter?.startTime) {
            newQuery.filters.push({
                field: 'createdAt',
                operator: FilterOperatorTypes.greaterThan,
                data: defaultFilter?.startTime
            });
        }
        if (defaultFilter?.endTime) {
            newQuery.filters.push({
                field: 'createdAt',
                operator: FilterOperatorTypes.lessThan,
                data: defaultFilter?.endTime
            });
        }
        return {
            ...foundedView,
            filters: {
                ...foundedView.filters,
                query: newQuery
            }
        };
    }, [views, settings, defaultFilter?.startTime, defaultFilter?.endTime]);
    const entityTitle = useMemo(() => {
        if (view) {
            return view.title;
        }
        return displayItemMeta?.title || (isLoading ? '...' : t('NO_TITLE'));
    }, [displayItemMeta?.title, isLoading, t, view]);
    const actions = useMemo(() => {
        let url;
        if (view && !view.isDefault) {
            url = `${baseUrlWithDefinition}${VIEW_PATH}/${view?.entityId}`;
        }
        else {
            url = urlWithStoredView;
        }
        const actionsList = [];
        actionsList.push({
            key: 'openTable',
            buttonContent: (React.createElement(Tooltip, { title: t('HYPER_BI_VIEW_ALL_BUTTON_TOOLTIP') },
                React.createElement(FaBars, null))),
            buttonProps: {
                inversed: true,
                className: 'action-button open-table-btn',
                to: url,
                target: '_blank'
            }
        });
        return actionsList;
    }, [baseUrlWithDefinition, t, urlWithStoredView, view]);
    if (isFailed) {
        return React.createElement(NotFound, null);
    }
    if (isLoading) {
        return React.createElement(LoadingIndicator, null);
    }
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''}` },
        React.createElement("div", { className: "widget-title drag-handler" }, getTranslation(settings.title, language, t('HYPER_BI_TABLE'))),
        React.createElement("div", { className: "widget-body" },
            React.createElement(ViewProvider, { definitionId: settings.definitionId, view: view, canSelectAll: true, serializeToUrl: false, enableGrouping: true }, settings.viewId && loadingViews ? null : (React.createElement(ItemsTable, { actions: actions, editBaseUrl: settings.viewId
                    ? `${baseUrlWithDefinition}${VIEW_PATH}/${settings.viewId}`
                    : urlWithStoredView, tableTitle: React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'custom-view-title' },
                        React.createElement(ViewTitle, { title: entityTitle })),
                    React.createElement("div", { className: "flex-grow-1" })), className: "h-100 overflow-auto", baseUrl: baseUrlWithDefinition, definitionId: settings.definitionId, contentHeight: "calc(100%)", showViewsSelector: false, showSettings: false, noFilters: true }))))));
};
export default TableWidget;
