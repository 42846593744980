import { find } from 'lodash';
import queryString from 'query-string';
import { FEATURED_ENTITIES_PATH } from '../../../../../cms';
import { ACTIVE_TAB_RIGHT_PANEL_KEY, AvailableTabs } from '../../../../../cms/data/components/item/InfoPanel/InfoPanel';
import { ACTIVITY_DEFINITION_ID } from '../../../../../common/activity';
import { BROWSE_PATH } from '../../../../../common/utils/constants';
import { isWorkflowDefinition } from '../../../../../common/utils/url';
import { FEATURED_PROCESSES_PATH } from '../../../../../workflows';
export const getCustomRouteHandleActivityTaskClickBase = async (displayItemMetaList, card, fetchAdditionalData) => {
    let relatedToDefinitionId = card.relatedToDefinitionId;
    let relatedToEntityId = card.relatedToEntityId;
    if (!(card.relatedToDefinitionId && card.relatedToEntityId) && fetchAdditionalData) {
        relatedToDefinitionId = await fetchAdditionalData?.(ACTIVITY_DEFINITION_ID, card.entityId, 'relatedToDefinitionId');
        relatedToEntityId = await fetchAdditionalData?.(ACTIVITY_DEFINITION_ID, card.entityId, 'relatedToEntityId');
    }
    if (relatedToDefinitionId && relatedToEntityId) {
        const searchParams = queryString.stringify({
            [ACTIVE_TAB_RIGHT_PANEL_KEY]: AvailableTabs.ACTIVITY,
            blinkUpcomingTask: card.entityId
        });
        const basePath = isWorkflowDefinition(find(displayItemMetaList, { definitionId: relatedToDefinitionId }))
            ? FEATURED_PROCESSES_PATH
            : FEATURED_ENTITIES_PATH;
        return `${basePath}/${relatedToDefinitionId}${BROWSE_PATH}/${relatedToEntityId}?${searchParams.toString()}`;
    }
    const searchParams = queryString.stringify({
        [ACTIVE_TAB_RIGHT_PANEL_KEY]: AvailableTabs.ACTIVITY
    });
    return `${FEATURED_ENTITIES_PATH}/${ACTIVITY_DEFINITION_ID}${BROWSE_PATH}/${card.entityId}?${searchParams.toString()}`;
};
