import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { readableDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import { MdOutlineWarning } from 'react-icons/md';
import { TooltipTruncate } from '../../../../../../../common/components/TooltipTruncate';
import { capitalize } from '../../../../../../../workflows/processes/components/process/StepsOverview';
import styles from '../KanbanCard.module.scss';
export const CardTaskDeadline = ({ deadline }) => {
    const { t } = useI18n();
    if (!deadline) {
        return null;
    }
    moment.relativeTimeThreshold('M', 999999999);
    if (new Date(deadline).getTime() < Date.now()) {
        return (React.createElement("div", { className: cn(styles.deadline, styles.missedDeadline, 'fw-600 overflow-hidden d-flex align-items-center gap-2') },
            React.createElement(MdOutlineWarning, { size: 24 }),
            React.createElement(TooltipTruncate, { title: `${t('DEADLINE')} ${readableDateTime(deadline)}` }, `${capitalize(moment(deadline).fromNow(true))} ${t('LATE')}`)));
    }
    return (React.createElement(TooltipTruncate, { title: `${t('DEADLINE')} ${readableDateTime(deadline)}`, className: cn(styles.deadline, 'fw-600 overflow-hidden') }, capitalize(moment(deadline).fromNow(true))));
};
