import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { formatDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { FaRegCalendar } from 'react-icons/fa';
import { Select } from '../Select';
import { TooltipTruncate } from '../TooltipTruncate';
import styles from './DateRangeSelectorPicker.module.scss';
import DateRangeSelectorPopup, { useDateRangeSelectorPopupRelativeRowsMeta } from './DateRangeSelectorPopup';
import { isRelativeRangeValue } from './types';
const DateRangeSelectorPicker = ({ prefixComponent, value, onChange }) => {
    const { t } = useI18n();
    const dateSelectorPopupRelativeRowsMeta = useDateRangeSelectorPopupRelativeRowsMeta();
    const [openDropdown, setOpenDropDown] = useState(false);
    const onCancel = useCallback(() => {
        setOpenDropDown(false);
    }, []);
    const onApply = useCallback((newValue) => {
        setOpenDropDown(false);
        onChange(newValue);
    }, [onChange]);
    return (React.createElement(Select, { prefixComponent: prefixComponent, className: "DateRangeSelectorPicker", open: openDropdown, onDropdownVisibleChange: setOpenDropDown, value: null, showSearch: false, mode: "multiple", tagRender: () => (React.createElement("span", { className: "d-flex align-items-center w-100" }, isRelativeRangeValue(value) ? (React.createElement("span", null, `${value.offset.slice(0, -1)} ${dateSelectorPopupRelativeRowsMeta[value.offset[value.offset.length - 1]].label}`)) : value.time[0] || value.time[1] ? (React.createElement(React.Fragment, null,
            React.createElement(TooltipTruncate, null, value.time[0]
                ? formatDateTime(dayjs(value.time[0]).valueOf())
                : t('DATE_RANGE_SELECTOR_START_DATE')),
            React.createElement("span", { className: "mx-1" }, "-"),
            React.createElement(TooltipTruncate, null, value.time[1]
                ? formatDateTime(dayjs(value.time[1]).valueOf())
                : t('DATE_RANGE_SELECTOR_END_DATE')))) : (React.createElement(React.Fragment, null,
            React.createElement(TooltipTruncate, null, t('DEADLINE_DATE')))))), suffixIcon: React.createElement(FaRegCalendar, { color: "var(--neutralColor-6)" }), popupClassName: styles.select, placement: "bottomRight", options: [], notFoundContent: React.createElement(DateRangeSelectorPopup, { value: value, onCancel: onCancel, onClickApply: onApply }), popupMatchSelectWidth: false }));
};
export default DateRangeSelectorPicker;
