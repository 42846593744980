import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { Body, Cell, DeleteConfirmationButton, Header, HeaderCell, InformationRow, Row, Table } from '@hypercharge/hyper-react-base/lib/common/table';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { omit } from 'lodash';
import React, { Component, useState } from 'react';
import styled from 'styled-components';
import { Breadcrumbs } from '../../../../cms/common/breadcrumbs';
import { StyledWrapper } from '../../../../common/utils/stylingUtils';
import { ADMIN_PATH } from '../../../utils/constants';
const ActionsContainer = styled.div `
  margin-bottom: 1rem;
`;
class PerformantBody extends Component {
    shouldComponentUpdate(newProps) {
        return (newProps.options.length != this.props.options.length ||
            newProps.loading != this.props.loading ||
            newProps.isMobile != this.props.isMobile);
    }
    render() {
        return React.createElement(Body, { ...this.props }, this.props.children);
    }
}
class PerformantRow extends Component {
    shouldComponentUpdate(newProps) {
        return newProps.to != this.props.to || newProps.deleting != this.props.deleting;
    }
    render() {
        return React.createElement(Row, { ...this.props }, this.props.children);
    }
}
const Options = ({ groupId, options, isPending, failed, deleteOption, isMobile }) => {
    const { t, language } = useI18n();
    const [deleting, setDeleting] = useState({});
    return (React.createElement(React.Fragment, null,
        React.createElement(Breadcrumbs, { links: [
                { text: t('ADMIN_NAV_LINK'), to: `${ADMIN_PATH}` },
                { text: t('SETTINGS_OPTIONS_NAV_LINK'), to: `${ADMIN_PATH}/option-groups` }
            ], currentBreadcrumb: groupId }),
        React.createElement(StyledWrapper, null,
            React.createElement(ActionsContainer, { className: "cp-c-row cp-c-align-end-center" },
                React.createElement(Button, { to: `${ADMIN_PATH}/option-groups/${groupId}/options/add` }, t('SETTINGS__OPTION_VALUE_ADD'))),
            React.createElement(Table, null,
                React.createElement(Header, null,
                    React.createElement(HeaderCell, { style: { width: '30%' } }, t('SETTINGS__OPTION_DESCRIPTION')),
                    React.createElement(HeaderCell, { style: { width: '30%' } }, t('SETTINGS__OPTION_VALUE')),
                    React.createElement(HeaderCell, { style: { width: '20%' }, className: "cp-hide cp-sm-show-table-cell" }, t('SETTINGS__OPTIONS_DEPENDENCY')),
                    React.createElement(HeaderCell, { className: "actions", style: { width: '8rem' } })),
                React.createElement(PerformantBody, { loading: isPending, cellLength: isMobile ? 3 : 4, isMobile: isMobile, options: options }, !isPending && options.length === 0 ? (React.createElement(InformationRow, null, t(failed ? 'COMMON__DATA_FETCH_FAILED' : 'SETTINGS__OPTIONS_NO_VALUES'))) : (options.map(({ labels, value, groupId, dependantGroupId, dependantValues }) => (React.createElement(PerformantRow, { key: value, to: `${ADMIN_PATH}/option-groups/${groupId}/options/${value}`, deleting: deleting[value] },
                    React.createElement(Cell, null, getTranslation(labels, language)),
                    React.createElement(Cell, null, value),
                    React.createElement(Cell, { className: "cp-hide cp-sm-show-table-cell" }, dependantValues &&
                        dependantValues.length > 0 &&
                        `${dependantGroupId}: ${dependantValues.join(', ')}`),
                    React.createElement(Cell, { className: "actions", style: { width: '8rem' } },
                        React.createElement("div", null,
                            React.createElement(DeleteConfirmationButton, { onClick: () => {
                                    setDeleting({ ...deleting, [value]: true });
                                    return deleteOption(groupId, value).then(() => setDeleting(omit(deleting, value)));
                                } }))))))))))));
};
export default Options;
