import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { formatDateTime, ReadableDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { Tooltip } from 'antd';
import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import { MdWarning } from 'react-icons/md';
import { TooltipTruncate } from '../../../../common/components/TooltipTruncate';
import styles from './DisplayTaskDeadline.module.scss';
export const DisplayTaskDeadline = ({ taskDeadline }) => {
    const { t } = useI18n();
    if (!taskDeadline) {
        return (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "me-1" }, t('NO_DEADLINE'))));
    }
    else if (taskDeadline < Date.now()) {
        return (React.createElement("div", { className: styles.container },
            React.createElement(Tooltip, { title: formatDateTime(taskDeadline) },
                React.createElement("div", { className: styles.pastDeadlineText }, `${capitalize(moment(taskDeadline).fromNow(true))} ${t('LATE')}`)),
            React.createElement(Tooltip, { title: t('PASSED_DEADLINE'), placement: "topRight" },
                React.createElement(MdWarning, { className: styles.styledWarning }))));
    }
    return (React.createElement(Tooltip, { title: formatDateTime(taskDeadline) },
        React.createElement(TooltipTruncate, { title: formatDateTime(taskDeadline) },
            React.createElement(ReadableDateTime, { dateTime: taskDeadline }))));
};
