import { ErrorMessage } from '@hypercharge/hyper-react-base/lib/form';
import { Select } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { FieldLabel } from '../../../../../../../../cms/data/components/item-property/FieldElements';
import { getDependenciesValues } from '../../utils';
import { fetchNodeParameter } from '../actions';
import MappingsField from './MappingsField';
const MAPPING_MODES = [
    {
        value: 'defineBelow',
        label: 'Map Each Column Manually'
    },
    {
        value: 'autoMapInputData',
        label: 'Map Automatically'
    }
];
const ResourceMapperField = ({ disabled, value, path, onChange, parameter, node, propertiesTree, error }) => {
    const httpDispatch = useDispatch();
    const methodName = parameter.typeOptions?.resourceMapper?.resourceMapperMethod;
    const resourceMapperMode = parameter.typeOptions?.resourceMapper?.mode;
    const dependencies = useMemo(() => {
        return getDependenciesValues(node, parameter);
    }, [node, parameter]);
    const { data, isFetching } = useQuery([parameter.name, methodName, dependencies], async () => {
        if (!node || !methodName) {
            return;
        }
        const response = await httpDispatch(fetchNodeParameter('resourceMapping', methodName, node));
        return response.result;
    }, {
        enabled: !!methodName && !!node,
        cacheTime: 60 * 1000,
        refetchOnWindowFocus: false,
        retry: false
    });
    const hasAvailableMatchingColumns = useMemo(() => {
        if (resourceMapperMode !== 'add') {
            if (!data?.fields.length) {
                return false;
            }
            return (data.fields.filter((field) => {
                return ((field.canBeUsedToMatch || field.defaultMatch) &&
                    field.display &&
                    field.removed !== true);
            }).length > 0);
        }
        return true;
    }, [data?.fields, resourceMapperMode]);
    const showMatchingColumnsSelector = !isFetching && resourceMapperMode !== 'add' && data?.fields.length;
    const showMappingFields = value.mappingMode === 'defineBelow' &&
        !isFetching &&
        data?.fields &&
        data.fields.length > 0 &&
        hasAvailableMatchingColumns;
    const availableMatchingFields = useMemo(() => {
        if (!data?.fields.length) {
            return [];
        }
        return data.fields.filter((field) => {
            return (field.canBeUsedToMatch || field.defaultMatch) && field.display;
        });
    }, [data?.fields]);
    const columnSelectorOptions = useMemo(() => {
        return availableMatchingFields.map((field) => {
            return {
                value: field.id,
                label: field.displayName
            };
        });
    }, [availableMatchingFields]);
    const onChangeMode = useCallback((newMode) => {
        onChange?.({ ...value, mappingMode: newMode });
    }, [onChange, value]);
    const onChangeColumnSelector = useCallback((newValue) => {
        onChange?.({ ...value, matchingColumns: [newValue] });
    }, [onChange, value]);
    const onChangeMappingsField = useCallback((newValue) => {
        onChange?.({ ...value, value: newValue });
    }, [onChange, value]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Select, { className: "w-100 mb-2", popupMatchSelectWidth: false, options: MAPPING_MODES, loading: isFetching, onChange: onChangeMode, value: value.mappingMode, disabled: !!disabled }),
        showMatchingColumnsSelector ? (React.createElement(React.Fragment, null,
            React.createElement(FieldLabel, { label: "Column to Match On" }),
            React.createElement(Select, { className: "w-100 mb-2", popupMatchSelectWidth: false, options: columnSelectorOptions, onChange: onChangeColumnSelector, value: value.matchingColumns?.[0], disabled: !!disabled }))) : null,
        showMappingFields ? (React.createElement(React.Fragment, null,
            React.createElement(FieldLabel, { label: "Values to Send" }),
            React.createElement(MappingsField, { className: "ms-4", fields: availableMatchingFields, value: value.value, onChange: onChangeMappingsField, propertiesTree: propertiesTree, disabled: !!disabled }))) : null,
        !!error && React.createElement(ErrorMessage, null, error)));
};
export default ResourceMapperField;
