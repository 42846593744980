import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const languageSelection = {
    cs: 'Češka',
    de: 'Deutsch',
    en: 'English',
    es: 'Español',
    fi: 'Suomi',
    fr: 'Français',
    hu: 'Magyar',
    it: 'Italiano',
    ja: '日本語',
    nl: 'Nederlands',
    pl: 'Polski',
    pt: 'Português',
    ro: 'Românesc',
    ru: 'Русский',
    sk: 'Slovenský',
    sv: 'Svenska'
};
i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    resources: {
        en: {
            translation: {
                ...languageSelection
            }
        }
    }
});
export const t = i18n.t.bind(i18n);
export default i18n;
