import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Radio } from 'antd';
import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import DropdownButton from '../DropdownButton';
import styles from './DateRangeSelector.module.scss';
import DateRangeSelectorPicker from './DateRangeSelectorPicker';
import DateRangeSelectorPopup, { useDateRangeSelectorPopupRelativeRowsMeta } from './DateRangeSelectorPopup';
import { isAbsoluteRangeValue, isRelativeRangeValue } from './types';
const DateRangeSelector = ({ prefixComponent, value, onChange, className, showCustomButton = true, showQuickRelative = true, showStartOfPeriod = true, availableRelativeRanges }) => {
    const { t } = useI18n();
    const relativeRowsMeta = useDateRangeSelectorPopupRelativeRowsMeta();
    const [isButtonPopupVisible, setIsButtonPopupVisible] = useState(false);
    const isRelativeMode = isRelativeRangeValue(value);
    const isAbsoluteMode = isAbsoluteRangeValue(value);
    const relativeOffsetUnit = useMemo(() => {
        return isRelativeMode ? value.offset.split(' ')[1] || '' : '';
    }, [isRelativeMode, value]);
    const onChangeRadioGroupButtons = useCallback((e) => {
        onChange({
            ...value,
            offset: e.target.value
        });
    }, [onChange, value]);
    const onCancelPopup = useCallback(() => {
        setIsButtonPopupVisible(false);
    }, []);
    const onApply = useCallback((value) => {
        onChange(value);
        setIsButtonPopupVisible(false);
    }, [onChange]);
    return (React.createElement("div", { className: cn(styles.wrapper, 'DateRangeSelectorWrapper', className, {
            [styles.emptyDate]: isAbsoluteMode && !value.time.length
        }) },
        isRelativeMode && showQuickRelative ? (React.createElement(Radio.Group, { options: relativeRowsMeta[relativeOffsetUnit]?.optionsReadable, onChange: onChangeRadioGroupButtons, value: value.offset, optionType: "button", buttonStyle: "solid" })) : (React.createElement(DateRangeSelectorPicker, { prefixComponent: prefixComponent, value: value, onChange: onApply })),
        showCustomButton && (React.createElement(DropdownButton, { className: "ms-3", popover: {
                destroyTooltipOnHide: true,
                placement: 'bottomRight',
                open: isButtonPopupVisible,
                onOpenChange: setIsButtonPopupVisible,
                content: (React.createElement(DateRangeSelectorPopup, { value: value, onCancel: onCancelPopup, onClickApply: onApply, showStartOfPeriod: showStartOfPeriod, availableRelativeRanges: availableRelativeRanges }))
            }, popoverButton: React.createElement(Button, { inversed: true, className: "action-button custom-btn" },
                React.createElement(FaEdit, { className: "me-2" }),
                React.createElement("span", null,
                    t('DATE_RANGE_SELECTOR_CUSTOM'),
                    isRelativeMode && ` (${(value.offset || '').replace(' ', '')})`)) }))));
};
export default DateRangeSelector;
