import { website as validationUrl } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useMemo } from 'react';
export const MIN_IFRAME_HEIGHT = 300; // in pixels
export const MAX_IFRAME_HEIGHT = 1000; // in pixels
const DEFAULT_IFRAME_HEIGHT = 800; // in pixels
const currentLocationOrigin = window.location.origin;
const IframeUrl = ({ url, height }) => {
    const { t } = useI18n();
    const errorMessage = useMemo(() => {
        const invalidUrlMessage = validationUrl(url);
        if (invalidUrlMessage) {
            return `${t('TASK_IFRAME_URL')} ${invalidUrlMessage}`;
        }
        const urlObject = new URL(url);
        if (urlObject.origin === currentLocationOrigin) {
            return t('URL_NOT_SAME_ORIGIN');
        }
    }, [t, url]);
    if (errorMessage) {
        return React.createElement("div", { className: "mt-2" }, errorMessage);
    }
    return (React.createElement("iframe", { className: "rounded-3 mt-2", src: url, height: height || DEFAULT_IFRAME_HEIGHT, sandbox: "allow-scripts allow-forms allow-same-origin", loading: "lazy" }));
};
export default IframeUrl;
