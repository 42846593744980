import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { find, get } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { hasTenantPermission, redirectIfNoPermission } from '../../../auth';
import HeightContext, { HeightContextProvider } from '../../../common/components/height-context';
import { useBrowserBreakpoints } from '../../../common/components/with-browser-breakpoints';
import { APP_ADMIN_PERMISSION, BROWSE_PATH, SETTINGS_PATH, VIEW_PATH } from '../../../common/utils/constants';
import { getEntityBrowseUrl } from '../../../common/utils/url';
import { GROUPS_PATH, GROUP_CMS_DEFINITION_ID } from '../../../groups';
import EditableViewTitleComponent from '../../../views/components/EditableViewTitleComponent';
import CmsDataRoutes from '../../data/components/Routes';
import useDisplayItemMetaList from '../../hooks/useDisplayItemMetaList';
import CmsSettingsRoutes from '../../settings/components/entity/EntityRoutes';
import { Breadcrumbs } from '../breadcrumbs';
import { FEATURED_ENTITIES_PATH, TITLE_PROPERTY_ID } from '../utils/constants';
import styles from './FeaturedEntitiesRoutes.module.scss';
const otherElementSelectors = ['#header', '#featured-entities', '#breadcrumbs'];
const otherHeightInPx = 43; // data action block;
const RestrictedEntitiesRoutes = ({ match: { url: currentUrl } }) => {
    const { t } = useI18n();
    const { isDesktop } = useBrowserBreakpoints();
    const [links, setLinks] = useState([]);
    const [currentBreadcrumbText, setCurrentBreadcrumbText] = useState('');
    const { data: entities, isLoading, isError: isFailed } = useDisplayItemMetaList();
    const hasAdminPermission = useSelector((s) => hasTenantPermission(s, APP_ADMIN_PERMISSION));
    const getDisplayItemMeta = useCallback((definitionId) => find(entities, { definitionId }), [entities]);
    const getEntityTitle = useCallback((definitionId) => get(getDisplayItemMeta(definitionId), TITLE_PROPERTY_ID) ||
        (isLoading ? '...' : t('NO_TITLE')), [getDisplayItemMeta, isLoading, t]);
    const [extraCountForBreadcrumbsComponent, setExtraCountForBreadcrumbsComponent] = useState();
    return (React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: "BreadcrumbsContainer", id: "breadcrumbs" },
            React.createElement(Switch, null,
                React.createElement(Redirect, { exact: true, from: getEntityBrowseUrl(GROUP_CMS_DEFINITION_ID, FEATURED_ENTITIES_PATH), to: GROUPS_PATH }),
                React.createElement(Redirect, { exact: true, from: `${getEntityBrowseUrl(GROUP_CMS_DEFINITION_ID, FEATURED_ENTITIES_PATH)}/:entityId`, to: `${GROUPS_PATH}/:entityId` }),
                React.createElement(Redirect, { from: `${FEATURED_ENTITIES_PATH}/${GROUP_CMS_DEFINITION_ID}`, to: GROUPS_PATH }),
                React.createElement(Route, { path: `${currentUrl}/:definitionId${SETTINGS_PATH}`, render: () => React.createElement(Breadcrumbs, { links: links, currentBreadcrumb: currentBreadcrumbText }) }),
                React.createElement(Route, { path: [
                        `${currentUrl}/:definitionId${BROWSE_PATH}`,
                        `${currentUrl}/:definitionId${VIEW_PATH}/:viewId/:entityId`,
                        `${currentUrl}/:definitionId${VIEW_PATH}/:viewId`
                    ], render: (props) => {
                        const { definitionId, viewId, entityId } = props.match.params;
                        return (React.createElement(Breadcrumbs, { currentBreadcrumb: React.createElement(EditableViewTitleComponent, { definitionId: definitionId, entityId: entityId, viewId: viewId, totalItemsCount: extraCountForBreadcrumbsComponent, entityTitle: currentBreadcrumbText }), links: links }));
                    } }))),
        React.createElement("div", { className: styles.container },
            React.createElement(Switch, null,
                hasAdminPermission && (React.createElement(Route, { path: `${currentUrl}/:definitionId${SETTINGS_PATH}`, render: (props) => {
                        const { definitionId } = props.match.params;
                        const entityTitle = getEntityTitle(definitionId);
                        return (React.createElement("div", { className: styles.settingsContainer },
                            React.createElement(CmsSettingsRoutes, { definitionId: definitionId, entityTitle: entityTitle, setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, baseUrl: currentUrl, currentUrl: props.match.url })));
                    } })),
                React.createElement(Route, { path: `${currentUrl}/:definitionId`, render: (props) => {
                        const { definitionId } = props.match.params;
                        const displayItemMeta = getDisplayItemMeta(definitionId);
                        return (React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: otherHeightInPx },
                            React.createElement(HeightContext.Consumer, null, ({ contentHeight }) => (React.createElement("div", null,
                                React.createElement(CmsDataRoutes, { definitionId: definitionId, entityTitle: getEntityTitle(definitionId), canCreateItems: displayItemMeta?.canCreate || false, canDeleteItems: displayItemMeta?.canDelete || false, setCurrentBreadcrumbText: setCurrentBreadcrumbText, setLinks: setLinks, contentHeight: isDesktop ? contentHeight : undefined, isFailed: isFailed, isLoading: isLoading, baseUrl: currentUrl, setExtraCountForBreadcrumbsComponent: setExtraCountForBreadcrumbsComponent, ...props }))))));
                    } }),
                React.createElement(Redirect, { to: currentUrl })))));
};
export default redirectIfNoPermission(RestrictedEntitiesRoutes);
