import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { formatDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { Badge, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';
const MarkAsReadButton = ({ openedAt, onClick }) => {
    const { t } = useI18n();
    const [loading, setLoading] = useState(false);
    const readNotification = useCallback(async () => {
        try {
            setLoading(true);
            await onClick();
        }
        finally {
            setLoading(false);
        }
    }, [onClick]);
    return (React.createElement(Tooltip, { title: !loading &&
            (openedAt ? (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "me-2" }, t('NOTIFICATION_IS_READ')),
                formatDateTime(openedAt))) : (t('MARK_AS_READ'))), placement: "topRight" },
        React.createElement("span", { onClick: readNotification },
            React.createElement(Badge, { status: loading || openedAt ? 'default' : 'processing', className: `px-2 lh-base ${openedAt ? 'pe-none' : 'pe-auto'}` }))));
};
export default MarkAsReadButton;
