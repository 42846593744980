import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { FaBars } from 'react-icons/fa';
import { isKanbanView } from '~app/views/types';
import { usePropertyEntityItemValue } from '../../../../../cms/hooks/usePropertyEntityItemValue';
import { replaceConditionFromContextDataInFilters } from '../../../../../common/components/ConditionQuery/utils';
import FullTextSearch from '../../../../../common/components/data-table/FullTextSearch';
import { SELECTED_VIEWS_STORAGE_KEY } from '../../../../../common/components/data-table/ViewSelector';
import { useStorage } from '../../../../../common/context/StorageContext';
import SessionSubscription from '../../../../../common/session/components/SessionSubscription';
import { getEntityBrowseUrl, getEntityViewUrl } from '../../../../../common/utils/url';
import { useMyContact } from '../../../../../crm/components/use-my-contact';
import { useView } from '../../../../../views/components/ViewContext';
import { getHashForUrl, getStoredViewInLocalStorage, isDefaultViewId } from '../../../../../views/utils';
import { ALL_TASKS_DEFINITION_ID, FEATURED_PROCESSES_PATH, PROCESSES_PATH, WORKFLOWS_PATH } from '../../../../../workflows';
import { TaskDashboardPageFilters } from '../../../../../workflows/dashboard/components/dashboard/TaskDashboardPageFilters';
import { TasksKanban } from '../../../../../workflows/dashboard/components/dashboard/TasksKanban';
import { WorkflowsTasksTableList } from '../../../../../workflows/dashboard/components/dashboard/TasksTableList';
import { TasksTableWrapper } from '../../../../../workflows/dashboard/components/dashboard/TasksTableWrapper';
import styles from './WorkflowsTasksWidget.module.scss';
import { getCustomRouteHandleWorkflowsTaskClickBase } from './utils';
const baseUrl = `${FEATURED_PROCESSES_PATH}/${ALL_TASKS_DEFINITION_ID}`;
const WorkflowsTasksWidget = ({ className, settings }) => {
    const { t, language } = useI18n();
    const { contactId, contactGroups } = useMyContact();
    const [viewIdMapInStorage] = useStorage({
        key: SELECTED_VIEWS_STORAGE_KEY,
        defaultValue: {}
    });
    const { filterRequest, metrics, viewType } = useView();
    const filterRequestOpenInNewTab = useMemo(() => ({
        ...filterRequest,
        query: replaceConditionFromContextDataInFilters(filterRequest.query, filterRequest.context)
    }), [filterRequest]);
    const { getPropertyValue } = usePropertyEntityItemValue();
    const getCustomRouteHandleTaskClick = useCallback((card) => getCustomRouteHandleWorkflowsTaskClickBase(card, getPropertyValue), [getPropertyValue]);
    const viewAllLink = useMemo(() => {
        const storedViewId = getStoredViewInLocalStorage({
            definitionId: ALL_TASKS_DEFINITION_ID,
            contactId,
            viewIdMapInStorage
        });
        return storedViewId && !isDefaultViewId(storedViewId)
            ? getEntityViewUrl(ALL_TASKS_DEFINITION_ID, storedViewId, `${WORKFLOWS_PATH}${PROCESSES_PATH}`)
            : getEntityBrowseUrl(ALL_TASKS_DEFINITION_ID, `${WORKFLOWS_PATH}${PROCESSES_PATH}`);
    }, [contactId, viewIdMapInStorage]);
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''}` },
        contactId &&
            contactGroups.map((group) => (React.createElement(SessionSubscription, { key: group, subscriptionKey: `tasks--group--${group}` }))),
        React.createElement("div", { className: "widget-title drag-handler" }, getTranslation(settings.title, language, t('HYPER_BI_WORKFLOWS_TASKS'))),
        React.createElement("div", { className: "widget-body" },
            React.createElement("div", { id: "TaskDashboardWidgetHeader", className: "d-flex align-items-center mb-1 justify-content-between" },
                React.createElement("div", { className: "d-flex align-items-center" },
                    React.createElement(FullTextSearch, { hotkeyFocusSearch: true })),
                React.createElement("div", { className: "right-buttons" },
                    React.createElement(Tooltip, { title: t('HYPER_BI_WIDGET_VIEW_ALL_BTN_TOOLTIP') },
                        React.createElement(Button, { type: "button", target: "_blank", className: "ms-2 action-button", inversed: true, to: {
                                pathname: viewAllLink,
                                search: getHashForUrl({
                                    newFilters: filterRequestOpenInNewTab,
                                    newMetrics: metrics,
                                    viewType,
                                    queryParams: {}
                                })
                            } },
                            React.createElement(FaBars, null))))),
            React.createElement(TaskDashboardPageFilters, { relatedToTitleKey: "PROCESS_NAME" }),
            React.createElement("div", { className: "widget-tasks custom-scroll-styling h-100" }, isKanbanView(viewType) ? (React.createElement(TasksKanban, { baseUrl: baseUrl, getCustomRouteHandleCardClick: getCustomRouteHandleTaskClick })) : (React.createElement(TasksTableWrapper, null,
                React.createElement(WorkflowsTasksTableList, { getCustomRouteHandleTaskClick: getCustomRouteHandleTaskClick, emptyText: t('EMPTY_AVAILABLE_TASKS_TEXT') })))))));
};
export default WorkflowsTasksWidget;
