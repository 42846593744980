import { findKey } from 'lodash';
import React, { useCallback } from 'react';
import { ViewTypes, isKanbanView } from '~app/views/types';
import { MY_USER_COMPUTED } from '../../../../../cms/common/utils/constants';
import ViewSelector from '../../../../../common/components/data-table/ViewSelector';
import { useBrowserBreakpoints } from '../../../../../common/components/with-browser-breakpoints';
import { ViewActionsButton } from '../../../../../views';
import { ToggleView } from '../../../../../views/components/ToggleView';
import { useView } from '../../../../../views/components/ViewContext';
import useCustomLogicTasksKanban from '../../../hooks/useCustomLogicTasksKanban';
import { TasksFilterType, isTasksFilterType } from '../../../types';
import { useTaskDashboard } from '../TaskDashboardContext/TaskDashboardProvider';
const TaskViewActions = ({ availableOn, addEmptyAssignedOption, relatedToTitleKey, baseUrl }) => {
    const { definitionId, filterRequest, updateRouteWithView, viewType, metrics, kanban, rowGrouping } = useView();
    const { mapProperty, getNewFilterForTaskDashboard, getTaskFilterTypeConfig } = useTaskDashboard();
    const handleUpdateRouteWithView = useCallback((props) => {
        if (isKanbanView(props.viewType)) {
            const groupByKey = findKey(mapProperty, (item) => item === props.kanban?.groupBy);
            if (isTasksFilterType(groupByKey) && props.kanban?.groupBy) {
                const fieldGroupBy = getTaskFilterTypeConfig(groupByKey).fieldGroupBy;
                const newValue = props.kanban.groupBy !== mapProperty[TasksFilterType.deadline]
                    ? props.kanban.columns
                    : [];
                return updateRouteWithView({
                    ...props,
                    newFilters: getNewFilterForTaskDashboard({
                        filterRequest: props.newFilters,
                        value: newValue,
                        filterName: groupByKey,
                        field: fieldGroupBy
                    })
                });
            }
        }
        return updateRouteWithView(props);
    }, [updateRouteWithView, mapProperty, getTaskFilterTypeConfig, getNewFilterForTaskDashboard]);
    const { selectedColumns, availableOptions, customOptionsGroupBy, onChangeCustomSearch } = useCustomLogicTasksKanban({
        definitionId,
        filterRequest,
        mapProperty,
        addEmptyAssignedOption,
        relatedToTitleKey
    });
    const { isMobile } = useBrowserBreakpoints();
    const handleUpdateViewType = useCallback((newViewType) => {
        handleUpdateRouteWithView({
            newFilters: filterRequest,
            newMetrics: metrics,
            viewType: newViewType,
            ...(isKanbanView(newViewType) && {
                kanban: {
                    ...kanban,
                    groupBy: kanban?.groupBy || mapProperty[TasksFilterType.assignees],
                    columns: kanban?.columns || [MY_USER_COMPUTED]
                }
            }),
            rowGrouping
        });
    }, [filterRequest, handleUpdateRouteWithView, kanban, mapProperty, metrics, rowGrouping]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ToggleView, { value: viewType || ViewTypes.table, onChange: handleUpdateViewType }),
        React.createElement(ViewActionsButton, { className: "editViewContainer", isMobile: isMobile, disabled: false, withExportData: false, customUpdateRouteWithView: handleUpdateRouteWithView, canEditFilters: false, canEditMetrics: false, canEditSorting: false, canEditRowHeight: false, customOptionsGroupBy: customOptionsGroupBy, onChangeCustomSearch: onChangeCustomSearch, customAvailableColumns: availableOptions, customSelectedColumns: selectedColumns, showFiltersCount: false, availableOn: availableOn, noSelectFieldsOnTableView: true }),
        React.createElement("div", { className: "viewSelectorContainer" },
            React.createElement(ViewSelector, { baseUrl: baseUrl, availableOn: availableOn }))));
};
export default TaskViewActions;
