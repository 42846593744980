import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import React from 'react';
import { BsExclamationCircle } from 'react-icons/bs';
import { FaRegCircle } from 'react-icons/fa';
import { FiArrowDownCircle, FiArrowUpCircle } from 'react-icons/fi';
import EntityDisplay from '../../../../cms/data/components/item-property/display/EntityDisplay';
import { TASK_PRIORITY_DEFINITION_ID } from '../../utils/constants';
import { TaskPriority } from '../../utils/types';
const DisplayPriorityTag = ({ priority = TaskPriority.NORMAL, showLabel, tooltipPlacement = 'right', className }) => {
    const { t } = useI18n();
    const { color = 'var(--grayscaleColor-3)', icon: Icon = BsExclamationCircle } = (priority &&
        priorityDisplayMap[priority]) || {
        color: 'var(--grayscaleColor-3)',
        icon: BsExclamationCircle
    };
    return (React.createElement("div", { className: `${className || ''} d-flex align-items-center justify-content-start display-priority-wrapper class-${priority || ''}` },
        React.createElement(Tooltip, { title: !showLabel && (React.createElement("span", null,
                React.createElement("span", null,
                    t('PRIORITY'),
                    ": "),
                React.createElement("b", null,
                    React.createElement(EntityDisplay, { value: priority, meta: priorityMeta })))), placement: tooltipPlacement },
            React.createElement("div", null,
                React.createElement("span", { className: `d-flex display-priority-icon-wrapper class-${priority || ''}` },
                    React.createElement(Icon, { size: 20, className: "display-priority-icon", color: color })))),
        showLabel && React.createElement(EntityDisplay, { value: priority, meta: priorityMeta })));
};
export default DisplayPriorityTag;
const priorityMeta = {
    definitionId: TASK_PRIORITY_DEFINITION_ID
};
const priorityDisplayMap = {
    [TaskPriority.LOW]: {
        color: 'var(--successColor-6)',
        icon: FiArrowDownCircle
    },
    [TaskPriority.NORMAL]: {
        color: 'var(--neutralColor-6)',
        icon: FaRegCircle
    },
    [TaskPriority.HIGH]: {
        color: 'var(--warningColor-6)',
        icon: FiArrowUpCircle
    },
    [TaskPriority.URGENT]: {
        color: 'var(--errorColor-5)',
        icon: FiArrowUpCircle
    }
};
