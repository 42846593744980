import { required } from '@hypercharge/hyper-react-base/lib/form';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from 'config';
import { flatten } from 'flat';
import { get, isEmpty, map, pickBy } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { SubmissionError, reduxForm, touch } from 'redux-form';
import { TermsAggregationFilterOrderBy } from '../../../cms/hooks/useAggregation';
import EditMetricForm from './EditMetricForm';
const form = 'edit-metric-form';
const DEFAULT_MAX_SIZE = 10;
const mapStateToProps = (s, { aggregation }) => {
    return {
        initialValues: aggregation || {
            metric: 'value_count',
            units: {},
            titles: {},
            field: 'entityId',
            rowGrouping: undefined,
            rowGroupingOrderBy: TermsAggregationFilterOrderBy.count,
            size: DEFAULT_MAX_SIZE
        }
    };
};
const validateLabels = (formValues) => pickBy(AVAILABLE_LANGUAGES.reduce((acc, language) => {
    const value = get(formValues, ['titles', language]);
    const requiredMsg = language === DEFAULT_LANGUAGE ? required(value) : undefined;
    return requiredMsg
        ? {
            ...acc,
            titles: {
                // @ts-expect-error
                ...acc.titles,
                [language]: requiredMsg
            }
        }
        : acc;
}, {}));
const mapDispatchToProps = (dispatch, { onCancel, onSave }) => ({
    onSubmit: (formValues) => {
        const errors = validateLabels(formValues);
        if (!isEmpty(errors)) {
            dispatch(touch(form, ...map(flatten(errors), (_, key) => key)));
            throw new SubmissionError(errors);
        }
        const eventCategory = 'Custom';
        gtag('event', 'new_metrict_created', {
            event_category: eventCategory
        });
        onSave({ id: generateId(), ...formValues });
    },
    onCancel
});
export default compose(connect(mapStateToProps, mapDispatchToProps), reduxForm({
    form,
    enableReinitialize: true,
    destroyOnUnmount: true
}))(EditMetricForm);
