import { createContext, useContext } from 'react';
/**
 * @deprecated use `useContentHeight`
 */
const HeightContext = createContext({
    contentHeight: 'auto'
});
/**
 * @deprecated use `useContentHeight`
 */
export const useHeight = () => {
    const context = useContext(HeightContext);
    if (context == null) {
        throw new Error('useHeight must be used within an HeightContextProvider');
    }
    return context;
};
export default HeightContext;
