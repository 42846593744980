import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType } from '@hypercharge/portal-utils';
import { Alert, Button, Popover, Typography } from 'antd';
import cn from 'classnames';
import { findIndex, isEmpty, isString, isUndefined } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { MdCancel, MdFilterListAlt } from 'react-icons/md';
import { useDebouncedCallback } from 'use-debounce';
import { getFiltersCount } from '../../../../cms/common/utils/utils';
import { useView } from '../../../../views/components/ViewContext';
import { PropertyTypes } from '../../../types';
import { supportedFilterPropertyTypes } from '../ConditionQueryField';
import { MAX_COUNT_FILTERS, QuickFilterId } from '../constants';
import { buildStartQuickFilter, getFieldRootLevel, getFilterByPropertyId, getFilterComponentByType, getQuickFilters, isConditionQuery, isFilterType } from '../utils';
import styles from './QuickFilter.module.scss';
const DELAY_ON_SAVE = 500;
export const QuickFilter = React.memo(({ open, onClose, type, propertyId, onOpen, meta }) => {
    const { t } = useI18n();
    const { filterRequest, metrics, updateRouteWithView, viewType, rowGrouping } = useView();
    const [quickFilters, setQuickFilters] = useState(getQuickFilters(filterRequest));
    const currentFilter = useMemo(() => getFilterByPropertyId(getQuickFilters(filterRequest), propertyId, type), [filterRequest, propertyId, type]);
    const startFilter = useMemo(() => buildStartQuickFilter(propertyId, type, meta), [meta, propertyId, type]);
    const filterByPropertyId = useMemo(() => getFilterByPropertyId(quickFilters, propertyId, type), [propertyId, quickFilters, type]);
    useEffect(() => {
        setQuickFilters(getQuickFilters(filterRequest));
    }, [filterRequest]);
    const onSave = useCallback((newQuickFiltersForSave) => {
        const quickFiltersForSave = newQuickFiltersForSave || [...quickFilters];
        const hasOperatorDefined = quickFiltersForSave.filter(isFilterType);
        const newQuickFilters = !isEmpty(quickFiltersForSave) && hasOperatorDefined
            ? [
                {
                    id: QuickFilterId,
                    condition: ConditionType.and,
                    filters: hasOperatorDefined
                }
            ]
            : [];
        updateRouteWithView({
            newFilters: {
                ...filterRequest,
                query: {
                    ...filterRequest.query,
                    filters: [
                        ...filterRequest.query.filters.filter((filter) => isConditionQuery(filter) ? filter.id !== QuickFilterId : true),
                        ...newQuickFilters
                    ]
                }
            },
            newMetrics: metrics,
            shouldReplacePath: false,
            viewType,
            rowGrouping
        });
    }, [quickFilters, updateRouteWithView, filterRequest, metrics, viewType, rowGrouping]);
    const debouncedOnSave = useDebouncedCallback((filters) => onSave(filters), DELAY_ON_SAVE);
    const handleChange = useCallback((filter) => {
        const field = getFieldRootLevel(filter.field, type);
        const prevQuickFilters = quickFilters;
        let finalFilters;
        const quickFilterIndex = findIndex(prevQuickFilters, { field });
        const hasQuickFilter = quickFilterIndex !== -1;
        if ('data' in filter && isString(filter.data) && !filter.data && hasQuickFilter) {
            finalFilters = [
                ...prevQuickFilters.slice(0, quickFilterIndex),
                ...prevQuickFilters.slice(quickFilterIndex + 1)
            ];
        }
        else {
            const newFilter = { ...filter, field };
            finalFilters = hasQuickFilter
                ? [
                    ...prevQuickFilters.slice(0, quickFilterIndex),
                    newFilter,
                    ...prevQuickFilters.slice(quickFilterIndex + 1)
                ]
                : [...prevQuickFilters, newFilter];
        }
        setQuickFilters(finalFilters);
        debouncedOnSave(finalFilters);
    }, [debouncedOnSave, quickFilters, type]);
    const handleClear = useCallback((filterToDelete) => {
        if (isUndefined(filterToDelete)) {
            return onClose();
        }
        setQuickFilters((prevQuickFilters) => {
            const quickFilterIndex = findIndex(prevQuickFilters, filterToDelete);
            const hasQuickFilter = quickFilterIndex !== -1;
            const newQuickFilters = hasQuickFilter
                ? [
                    ...prevQuickFilters.slice(0, quickFilterIndex),
                    ...prevQuickFilters.slice(quickFilterIndex + 1)
                ]
                : prevQuickFilters;
            onSave(newQuickFilters);
            onClose();
            return newQuickFilters;
        });
    }, [onSave, onClose]);
    const presetFilter = useMemo(() => filterByPropertyId || startFilter, [filterByPropertyId, startFilter]);
    const FilterComponent = useMemo(() => getFilterComponentByType(type), [type]);
    const countOfFilters = useMemo(() => {
        return getFiltersCount(filterRequest.query.filters);
    }, [filterRequest.query.filters]);
    const onClickFilter = useCallback(() => {
        open ? onClose() : onOpen();
    }, [onClose, onOpen, open]);
    if (!supportedFilterPropertyTypes.includes(type)) {
        return null;
    }
    const isActiveFilter = !!getFilterByPropertyId(getQuickFilters(filterRequest), propertyId, type);
    return (React.createElement(React.Fragment, null,
        React.createElement(Popover, { destroyTooltipOnHide: true, open: open, onOpenChange: () => {
                onClose();
            }, trigger: "click", placement: "bottom", content: React.createElement(React.Fragment, null,
                React.createElement("form", { className: cn(styles.wrapper, 'd-flex align-items-center gap-2'), onClick: (event) => event.stopPropagation() },
                    React.createElement(FilterComponent, { filter: presetFilter, onChange: handleChange, meta: meta, autoFocus: true, className: styles.filterComponent, disableAddOption: type === PropertyTypes.entity, availableCountForFilters: MAX_COUNT_FILTERS - countOfFilters }),
                    React.createElement(Button, { className: "d-flex align-items-center justify-content-center", type: "text", shape: "circle", onClick: () => {
                            handleClear(currentFilter);
                        }, icon: React.createElement(MdCancel, { size: 20, color: "var(--errorColor-4)" }) })),
                MAX_COUNT_FILTERS <= countOfFilters && (React.createElement(Alert, { className: cn(styles.alert, 'mt-2'), message: React.createElement("div", { className: "d-flex align-items-center gap-2" },
                        React.createElement(FaExclamationCircle, { size: 24, color: "var(--warningColor-6)" }),
                        React.createElement("div", { className: "d-flex flex-column" },
                            React.createElement(Typography.Text, { color: "var(--neutralColor-6)" }, t('LIMIT_COUNT_FOR_FILTERS_TITLE')),
                            React.createElement(Typography.Text, { color: "var(--neutralColor-6)" }, t('LIMIT_COUNT_FOR_FILTERS_MESSAGE', {
                                maxCount: MAX_COUNT_FILTERS
                            })))), type: "warning" }))) },
            React.createElement(Button, { className: cn(styles.button, 'quick-filter safe-space p-1', { isActiveFilter, open }), icon: React.createElement(MdFilterListAlt, { color: "var(--neutralColor-6)", size: 16 }), onClick: onClickFilter }))));
});
