import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tabs } from 'antd';
import { DEFAULT_LANGUAGE } from 'config';
import { cloneDeep, get, isEmpty, map, negate, set, toNumber, toString } from 'lodash';
import React, { useCallback, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IoMdAlert } from 'react-icons/io';
import { IoReorderThree } from 'react-icons/io5';
import { PropertiesProvider } from '../../../../cms/common/context/PropertiesProvider';
import MetaPropertyListItem from './MetaPropertyListItem';
import styles from './MetaPropertyListItems.module.scss';
import MoveDrawer, { moveStateToPath } from './MoveDrawer';
import { generateReadableId } from './utils';
// a little function to help us with reordering the result
const reorderTree = (tabs = [], sourcePath, sourceIndex, destinationPath, destinationIndex) => {
    const sourceChilds = sourcePath === '' ? tabs : get(tabs, sourcePath) || [];
    const destinationChilds = destinationPath === '' ? tabs : get(tabs, destinationPath) || [];
    const [removed] = sourceChilds.splice(sourceIndex, 1);
    if (sourcePath === destinationPath) {
        sourceChilds.splice(destinationIndex, 0, removed);
    }
    else {
        destinationChilds.splice(destinationIndex, 0, removed);
    }
    return tabs;
};
const MetaPropertyListItems = ({ metaDefinition, showHiddenFields, onActiveChange, onActiveTabChange, activeId, activeTabId, parentViewGroups, submitting, value, values, onChange, error, canDeleteProperties, initialValues }) => {
    const move = useCallback((fromPath, fromIndex, toPath, toIndex) => {
        if (fromPath === toPath && fromIndex === toIndex) {
            return;
        }
        const source = get(value, `${fromPath}[${fromIndex}]`);
        const destination = get(value, `${toPath}[${toIndex}]`);
        if (source && 'properties' in source && source.id === 'system') {
            // cannot drag a internal section to another position
            toIndex = 0;
        }
        if (destination && 'properties' in destination && destination.id === 'system') {
            // cannot be dragged before the internal section
            return;
        }
        const newProperties = reorderTree(cloneDeep(value), fromPath, fromIndex, toPath, toIndex);
        onChange(newProperties);
    }, [onChange, value]);
    const onDragEnd = useCallback((result) => {
        if (!result.destination) {
            return;
        }
        if (result.source.droppableId === 'tabs') {
            move('', result.source.index, '', result.destination.index);
            onActiveTabChange(result.destination.index.toString());
        }
        else {
            move(result.source.droppableId, result.source.index, result.destination.droppableId, result.destination.index);
        }
    }, [move, onActiveTabChange]);
    const onMove = useCallback((olbPosition, newPosition) => {
        const oldKey = moveStateToPath(olbPosition);
        const newKey = moveStateToPath(newPosition);
        if (!newKey.length) {
            // moved tab
            onActiveTabChange(newPosition.position.toString());
        }
        move(oldKey.join('.'), olbPosition.position, newKey.join('.'), newPosition.position);
    }, [move, onActiveTabChange]);
    const onDeleteItem = useCallback((path, index) => {
        const newValue = cloneDeep(value);
        const child = path ? get(newValue, path) || [] : newValue;
        child.splice(index, 1);
        onChange(newValue);
    }, [onChange, value]);
    const onChangeSettings = useCallback((path, index, val) => {
        const isSection = 'properties' in val;
        const isTab = 'sections' in val;
        let newValue = cloneDeep(value);
        path = `${path}[${index}]`;
        const oldMeta = get(value, path);
        if (oldMeta) {
            if (val.isNew && !val.system) {
                if (isSection || isTab) {
                    const newLabelValue = val.titles[DEFAULT_LANGUAGE] ||
                        val.titles.en ||
                        Object.values(val.titles).filter(negate(isEmpty))[0];
                    val.id = generateReadableId(newValue, isSection ? 'section' : 'tab', newLabelValue, val.id);
                    onActiveChange(val.id);
                }
                else {
                    const newLabelValue = val.labels[DEFAULT_LANGUAGE] ||
                        val.labels.en ||
                        Object.values(val.labels).filter(negate(isEmpty))[0];
                    val.propertyId = generateReadableId(newValue, val.type, newLabelValue, val.propertyId);
                    onActiveChange(val.propertyId);
                }
            }
            // Should reset all sections to unpinned when there's a pin change event
            if (isSection && 'properties' in oldMeta && val.pinned !== oldMeta.pinned) {
                newValue = map(newValue, (tab) => ({
                    ...tab,
                    sections: map(tab.sections, (section) => ({ ...section, pinned: false }))
                }));
            }
            newValue = set(newValue, path, {
                ...oldMeta,
                ...val
            });
        }
        onChange(newValue);
    }, [onActiveChange, onChange, value]);
    if (!metaDefinition) {
        return null;
    }
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement(DragDropContext, { onDragEnd: onDragEnd },
            React.createElement(DraggableMetaPropertyList, { onChange: onChangeSettings, disabled: submitting, showHiddenFields: showHiddenFields, metaDefinitionId: metaDefinition.definitionId, items: value, values: values, activeId: activeId, activeTabId: activeTabId, onActiveChange: onActiveChange, onActiveTabChange: onActiveTabChange, onMove: onMove, onDeleteItem: onDeleteItem, canDeleteProperties: canDeleteProperties, parentViewGroups: parentViewGroups, error: error, initialValues: initialValues }))));
};
const DraggableMetaPropertyList = ({ className, metaDefinitionId, showHiddenFields, items, values, onChange, activeId, activeTabId, canDeleteProperties, parentViewGroups, onDeleteItem, onActiveTabChange, onActiveChange, onMove, disabled, error, initialValues }) => {
    const { language, t } = useI18n();
    const [moveElement, setMoveElement] = useState();
    const onCloseDrawer = useCallback(() => {
        setMoveElement(undefined);
    }, []);
    const onChangeOrder = useCallback((newValue) => {
        if (!moveElement) {
            return;
        }
        onMove(moveElement, newValue);
    }, [moveElement, onMove]);
    return (React.createElement(PropertiesProvider, null,
        React.createElement(Droppable, { direction: "horizontal", droppableId: "tabs", type: "TABS", renderClone: (provided, snapshot, rubric) => {
                return (React.createElement("div", { ...provided.draggableProps, ...provided.dragHandleProps, ref: provided.innerRef, className: "fw-600 ps-4 py-2 text-nowrap" }, getTranslation(items[rubric.source.index].titles, language) || t('NO_TITLE')));
            } }, (droppableProvided, droppableSnapshot) => (React.createElement("div", { className: `tabs-list ${droppableSnapshot.isDraggingOver ? 'dragging-over' : ''}`, ref: droppableProvided.innerRef, ...droppableProvided.droppableProps },
            React.createElement(Tabs, { activeKey: activeTabId, onChange: onActiveTabChange, renderTabBar: (tabBarProps, DefaultTabBar) => {
                    return (React.createElement(DefaultTabBar, { ...tabBarProps }, (node) => (React.createElement(Draggable, { key: node.key, draggableId: toString(node.key), index: toNumber(node.key) }, (draggableProvided, draggableSnapshot) => (React.createElement("div", { ref: draggableProvided.innerRef, className: `tab-item drag-item ${draggableSnapshot.isDragging ? 'dragging-item' : ''} ${className || ''}`, ...draggableProvided.dragHandleProps, ...draggableProvided.draggableProps },
                        React.createElement(React.Fragment, null, node)))))));
                }, items: items.map((tab, tabIndex) => {
                    // Tab
                    const tabPatch = `[${tabIndex}].sections`;
                    const tabTitle = getTranslation(tab.titles, language) || t('NO_TITLE');
                    const hasSuperSystemSection = !!tab.sections.find((section) => section.id === 'system');
                    const hasOldProperties = !!tab.sections.find((section) => !!section.properties.find((property) => !property.isNew));
                    const canDeleteTab = items.length > 1 && !hasSuperSystemSection && !hasOldProperties;
                    const isInvalid = !isEmpty(error?.[tabIndex]);
                    return {
                        label: (React.createElement("span", { className: `fw-600 ${isInvalid ? 'invalid' : ''}` },
                            tabTitle,
                            isInvalid && React.createElement(IoMdAlert, { size: 18, className: "ms-1 alertIcon" }))),
                        key: tabIndex.toString(),
                        children: (React.createElement(React.Fragment, null,
                            React.createElement(MetaPropertyListItem, { disabled: disabled, className: "mb-3", value: tab, values: values, onChange: (val) => {
                                    onChange('', tabIndex, val);
                                }, metaDefinitionId: metaDefinitionId, isActive: tab.id === activeId, onActiveChange: onActiveChange, parentViewGroups: parentViewGroups, canDelete: canDeleteTab, canMove: true, onClickDelete: () => {
                                    onDeleteItem('', tabIndex);
                                    onActiveTabChange(toString(Math.max(tabIndex - 1, 0)));
                                }, onClickMove: () => {
                                    setMoveElement({
                                        position: tabIndex
                                    });
                                }, error: get(error, tabIndex), initialValues: initialValues }),
                            React.createElement(Droppable, { droppableId: tabPatch, type: "SECTIONS" }, (droppableProvided, droppableSnapshot) => (React.createElement("div", { className: `sections-list droppable-item ${droppableSnapshot.isDraggingOver ? 'dragging-over' : ''}`, ref: droppableProvided.innerRef },
                                tab.sections.map((section, sectionIndex) => {
                                    // Section
                                    const sectionPatch = `${tabPatch}[${sectionIndex}].properties`;
                                    const isSuperSystemSection = section.id === 'system';
                                    const isDisabled = isSuperSystemSection || disabled;
                                    const hasOldProperties = section.properties.find((property) => !property.isNew);
                                    return (React.createElement(Draggable, { draggableId: sectionPatch, index: sectionIndex, key: sectionPatch, isDragDisabled: isDisabled }, (draggableProvided, draggableSnapshot) => (React.createElement("div", { ref: draggableProvided.innerRef, className: `meta-section-item drag-item ms-3 ${draggableSnapshot.isDragging ? 'dragging-item' : ''} ${className || ''}`, ...draggableProvided.draggableProps },
                                        React.createElement(MetaPropertyListItem, { initialValues: initialValues, disabled: disabled, value: section, values: values, onChange: (val) => {
                                                onChange(tabPatch, sectionIndex, val);
                                            }, metaDefinitionId: metaDefinitionId, isActive: section.id === activeId, onActiveChange: onActiveChange, parentViewGroups: parentViewGroups, canDelete: !hasOldProperties, canMove: true, onClickDelete: () => {
                                                onDeleteItem(tabPatch, sectionIndex);
                                            }, onClickMove: () => {
                                                setMoveElement({
                                                    tabIndex: tabIndex,
                                                    position: sectionIndex
                                                });
                                            }, error: get(error, `${tabPatch}[${sectionIndex}]`), dragHandler: React.createElement("div", { className: `drag-handler ${isDisabled ? 'disabled' : ''}`, ...draggableProvided.dragHandleProps },
                                                React.createElement(IoReorderThree, { size: "1.5rem" })) }),
                                        React.createElement(Droppable, { droppableId: sectionPatch, isDropDisabled: isDisabled, type: "PROPERTY" }, (droppableProvided, droppableSnapshot) => (React.createElement("div", { className: `properties-list ms-3 pb-3 droppable-item ${droppableSnapshot.isDraggingOver
                                                ? 'dragging-over'
                                                : ''}`, ref: droppableProvided.innerRef },
                                            section.properties.map((property, propertyIndex) => {
                                                // Property
                                                const isVisible = showHiddenFields || !property.meta.hidden;
                                                if (!isVisible) {
                                                    return null;
                                                }
                                                const propertyPatch = `${sectionPatch}[${propertyIndex}]`;
                                                return (React.createElement(Draggable, { draggableId: propertyPatch, index: propertyIndex, key: propertyPatch, isDragDisabled: isDisabled }, (draggableProvided, draggableSnapshot) => {
                                                    return (React.createElement("div", { ref: draggableProvided.innerRef, className: `meta-property-item drag-item ${draggableSnapshot.isDragging
                                                            ? 'dragging-item'
                                                            : ''} ${className || ''}`, ...draggableProvided.draggableProps },
                                                        React.createElement(MetaPropertyListItem, { initialValues: initialValues, disabled: disabled, value: property, values: values, onChange: (val) => {
                                                                onChange(sectionPatch, propertyIndex, val);
                                                            }, metaDefinitionId: metaDefinitionId, isActive: property.propertyId === activeId, onActiveChange: onActiveChange, parentViewGroups: parentViewGroups, canDelete: canDeleteProperties, canMove: !isDisabled, onClickDelete: () => {
                                                                onDeleteItem(sectionPatch, propertyIndex);
                                                            }, onClickMove: () => {
                                                                setMoveElement({
                                                                    tabIndex: tabIndex,
                                                                    sectionIndex: sectionIndex,
                                                                    position: propertyIndex
                                                                });
                                                            }, error: get(error, `${sectionPatch}[${propertyIndex}]`), dragHandler: React.createElement("div", { className: `drag-handler ${isDisabled ? 'disabled' : ''}`, ...draggableProvided.dragHandleProps },
                                                                React.createElement(IoReorderThree, { size: "1.5rem" })) })));
                                                }));
                                            }),
                                            React.createElement(React.Fragment, null, droppableProvided.placeholder))))))));
                                }),
                                React.createElement(React.Fragment, null, droppableProvided.placeholder))))))
                    };
                }) }),
            React.createElement(React.Fragment, null, droppableProvided.placeholder)))),
        React.createElement(MoveDrawer, { tree: items, value: moveElement, title: t('DEFINITION_FORM__MOVE__TITLE'), onClose: onCloseDrawer, open: !!moveElement, onChange: onChangeOrder })));
};
export default MetaPropertyListItems;
