import React from 'react';
import { CustomEntitySelector } from '../../../../../../common/components/entity-selectors';
import { FieldError, FieldWrapper } from '../../FieldElements';
import styles from './EntityEditor.module.scss';
const EntityEditor = ({ className, meta, value, onChange, disabled, onKeyDown, onDrawerStateChange, onModalOpen, showFilter, filterRequest, autoFocus = true, style, withoutCreateNew = false, withoutSearchResults = false, forceFetch = false, ignoreOnEnterKeyDown = true, requiredFieldsConfig, permissionFieldsConfig, input, errorMessage, simpleClear, withMaxTagCount, handlePaste, ...otherProps }) => (React.createElement(React.Fragment, null,
    React.createElement(FieldWrapper, { className: `${styles.editor} ${className || ''} ant-select-styled-outer`, disabled: disabled, disableWrapperHover: true, editing: false, error: !!errorMessage, errorPrepend: false },
        React.createElement(CustomEntitySelector, { popupClassName: "antd-dropdown-popup-styled", multiple: meta.list, 
            // @ts-expect-error
            input: input ? input : { value, onChange }, definitionId: meta.definitionId, disabled: disabled, onKeyDown: onKeyDown, autoFocus: autoFocus, onDrawerStateChange: onDrawerStateChange, onModalOpen: onModalOpen, showFilter: showFilter, filtersQuery: filterRequest?.query, sortBy: filterRequest?.sortBy, style: style, extraPayloadProps: { limit: meta?.selector?.limit, context: filterRequest?.context }, withoutCreateNew: withoutCreateNew, withoutSearchResults: withoutSearchResults || meta?.selector?.limit === 0, forceFetch: forceFetch, ignoreOnEnterKeyDown: ignoreOnEnterKeyDown, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, simpleClear: simpleClear, error: errorMessage, withMaxTagCount: withMaxTagCount, handlePaste: handlePaste, ...otherProps })),
    React.createElement(FieldError, { error: errorMessage })));
export default EntityEditor;
