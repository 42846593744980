import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { datePattern, timePattern } from '@hypercharge/hyper-react-base/lib/utils';
import { Collapse } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { FaRegClock } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { hasTenantPermission } from '~app/auth';
import { RichTextDisplay } from '~app/cms';
import ItemLabel from '~app/cms/data/components/item/ItemLabel/ItemLabel';
import { invalidateActivityCache } from '~app/common/activity/useActivities';
import { TooltipTruncate } from '~app/common/components/TooltipTruncate';
import { APP_ADMIN_PERMISSION } from '~app/common/utils/constants';
import CountdownTimer from '~app/workflows/common/components/CountdownTimer/CountdownTimer';
import { TASK_META_OVERRIDE_PERMISSION } from '~app/workflows/common/utils/constants';
import AvailableTaskActivityForm from '~app/workflows/processes/components/process/AvailableTasks/AvailableTaskActivityForm';
import { getManualTaskProperties, getTaskTitle, isManualTask } from '~app/workflows/tasks/utils';
import AssigneeSelector from '../AssigneeSelector';
import IframeUrl from '../IframeUrl';
import { useRequiredFields } from '../RequiredFieldsProvider';
import TaskFieldsContainer from '../TaskFieldsContainer';
import TaskInlineEditor from '../TaskInlineEditor';
import styles from './AvailableTask.module.scss';
const AvailableTask = ({ className, task, loading, onSubmit, onAssignTask, submitting, onTaskToggle, isActive, canEdit, requiredFieldsConfig, permissionFieldsConfig, contactId: myContactId, onChangePriority, onChangeDeadline }) => {
    const { taskRunId, description, assignedTo, taskMetaId, processRunId, processMetaId, canExecute, canExecuteGroups, resolveTime, taskTarget, parameters } = task;
    const { selectedTaskActivityMap: { [taskRunId]: currentActivity }, requiredFieldsMap, onChangeTaskActivity } = useRequiredFields();
    const { t } = useI18n();
    const canEditTasks = useSelector((s) => hasTenantPermission(s, APP_ADMIN_PERMISSION) ||
        hasTenantPermission(s, TASK_META_OVERRIDE_PERMISSION));
    const [followUp, setFollowUp] = useState();
    const isManual = isManualTask(task);
    const { iframe } = parameters || {};
    const assignedToMeTask = assignedTo === myContactId;
    const isMarkAsDoneDisabled = !assignedTo || !assignedToMeTask || loading || submitting;
    const canExecuteGroupsSorted = useMemo(() => {
        return canExecuteGroups?.sort((a, b) => {
            const aIndex = a.startsWith('ownerIs__') ? 1 : 0;
            const bIndex = b.startsWith('ownerIs__') ? 1 : 0;
            return aIndex - bIndex;
        });
    }, [canExecuteGroups]);
    const optionalProperties = useMemo(() => {
        return getManualTaskProperties(task, 'optional', currentActivity);
    }, [currentActivity, task]);
    const taskDescription = useMemo(() => {
        if (!description) {
            return;
        }
        return React.createElement(RichTextDisplay, { value: description });
    }, [description]);
    const onChangeAssign = useCallback((contactId) => {
        return onAssignTask(taskMetaId, taskRunId, contactId);
    }, [onAssignTask, taskMetaId, taskRunId]);
    const onChangeActivity = useCallback((val) => {
        onChangeTaskActivity(taskRunId, val);
        if (!assignedTo) {
            void onChangeAssign(myContactId);
        }
    }, [assignedTo, myContactId, onChangeAssign, onChangeTaskActivity, taskRunId]);
    const onChangeProperty = useCallback(async () => {
        if (!assignedTo) {
            return onChangeAssign(myContactId);
        }
    }, [assignedTo, myContactId, onChangeAssign]);
    const onMarkAsDone = useCallback(async () => {
        await onSubmit(task, {
            activityItem: currentActivity,
            followUp
        });
        if (currentActivity) {
            await invalidateActivityCache(processMetaId, processRunId);
        }
    }, [currentActivity, followUp, onSubmit, processMetaId, processRunId, task]);
    const isAvailableContent = useMemo(() => {
        return (!!optionalProperties.length ||
            requiredFieldsMap[taskRunId] ||
            taskDescription ||
            !!canExecuteGroupsSorted?.length ||
            iframe?.url ||
            currentActivity);
    }, [
        optionalProperties.length,
        requiredFieldsMap,
        taskRunId,
        taskDescription,
        canExecuteGroupsSorted?.length,
        iframe?.url,
        currentActivity
    ]);
    return (React.createElement("div", { className: `${styles.task} ${className || ''}` },
        React.createElement(Collapse, { bordered: false, activeKey: isActive && isAvailableContent ? [taskRunId] : [], items: [
                {
                    key: taskRunId,
                    collapsible: isManual ? 'header' : 'disabled',
                    label: (React.createElement("div", { className: styles.taskTitleWrapper },
                        isManual && isAvailableContent && (React.createElement("button", { className: cn('rounded-circle', styles.taskArrow), type: "button", onClick: () => onTaskToggle(taskRunId) },
                            React.createElement(FontAwesomeIcon, { icon: "angle-down" }))),
                        React.createElement("div", { className: `${styles.taskTitle} ${!isManual || !isAvailableContent ? styles.taskTitle__notCollapsible : ''}` },
                            React.createElement("span", null,
                                React.createElement(TooltipTruncate, null, getTaskTitle(task)),
                                !!resolveTime && taskTarget === 'DELAY' && (React.createElement(CountdownTimer, { resolveTime: resolveTime })))),
                        !!resolveTime && taskTarget === 'DECISION' && resolveTime > Date.now() && (React.createElement("div", { className: styles.taskContentTopBlock }, t('TASK_AWAITING_EVENT', {
                            remainingDelayTime: moment(resolveTime).format(datePattern + ' ' + timePattern),
                            interpolation: { escapeValue: false }
                        }))),
                        isManual && (React.createElement("div", { className: "task-metadata" },
                            React.createElement(TaskInlineEditor, { task: task, canEdit: canExecute && canEditTasks, onChangePriority: onChangePriority, onChangeDeadline: onChangeDeadline }))),
                        isManual && canExecute && (React.createElement(React.Fragment, null,
                            React.createElement(AssigneeSelector, { popupClassName: styles.assigneeDropdown, inlineEditorClassName: styles.assigneeInlineEditor, value: assignedTo, assignTo: onChangeAssign, taskMetaId: taskMetaId, taskRunId: taskRunId, processRunId: processRunId, processMetaId: processMetaId, canEdit: canExecute }),
                            React.createElement(Button, { className: `${styles.markDoneBtn}
                       ${!assignedToMeTask ? styles.disabledMarkDone : ''}
                      `, onClick: onMarkAsDone, disabled: isMarkAsDoneDisabled },
                                React.createElement("span", { className: "d-none d-sm-inline-block" }, t('TASK__MARK_AS_DONE')),
                                React.createElement(FontAwesomeIcon, { icon: "check-circle", className: "d-inline-block d-sm-none" })))),
                        isManual && !canExecute && (React.createElement("span", { className: `d-flex align-items-center border border-1 rounded-pill fw-500 px-2 py-2 ${styles.waitingBtn}` },
                            React.createElement(FaRegClock, { className: "me-2", size: "1.4em" }),
                            t('WAITING'))))),
                    showArrow: false,
                    children: (React.createElement("div", { className: "d-flex flex-column w-100 1" },
                        React.createElement("div", { className: styles.taskContentTopBlock },
                            !!taskDescription && (React.createElement("div", { className: "task-description" },
                                React.createElement("div", { className: "task-description-text" }, taskDescription))),
                            canExecute && currentActivity && (React.createElement(AvailableTaskActivityForm, { className: "mt-2", task: task, value: currentActivity, onChange: onChangeActivity, onChangeFollowUp: setFollowUp, canEdit: canExecute && canEditTasks }))),
                        canExecute && (React.createElement(TaskFieldsContainer, { canEdit: canEdit, processMetaId: processMetaId, processRunId: processRunId, taskMetaId: taskMetaId, taskRunId: taskRunId, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, onChange: onChangeProperty, optionalFields: optionalProperties })),
                        !canExecute && !!canExecuteGroupsSorted?.length && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "fw-500" },
                                t('WHO_CAN_EXECUTE_TASK'),
                                ":"),
                            React.createElement("div", { className: "d-flex gap-2 mt-2" }, canExecuteGroupsSorted?.map((groupId) => {
                                let definitionId = 'group';
                                let entityId = groupId;
                                if (groupId.startsWith('ownerIs__')) {
                                    definitionId = 'contact';
                                    entityId = groupId.split('ownerIs__')[1];
                                }
                                return (React.createElement(ItemLabel, { key: groupId, className: "border border-1 rounded-pill px-2 py-1", definitionId: definitionId, entityId: entityId }));
                            })))),
                        canExecute && iframe?.url && (React.createElement(IframeUrl, { url: iframe.url, height: iframe?.height }))))
                }
            ] })));
};
export default AvailableTask;
