import { ErrorMessage } from '@hypercharge/hyper-react-base/lib/form';
import { toString } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { AntInput } from '../../../../../../../../common/components/AntInput';
import { AntTextArea } from '../../../../../../../../common/components/AntTextArea';
import { ComputedInput } from '../../../../../../../../common/components/ComputedExpressionEditor';
import DateField from '../../../../../../../../common/components/DateField/DateField';
import { Option, Select } from '../../../../../../../../common/components/Select';
import Toggle from '../../../../../../../../common/components/Toggle';
import { getDependenciesValues } from '../../utils';
import CollectionField from '../CollectionField';
import FixedCollectionField from '../FixedCollectionField';
import ResourceLocatorField from '../ResourceLocatorField';
import ResourceMapperField from '../ResourceMapperField/ResourceMapperField';
import { fetchNodeParameter } from '../actions';
import styles from './ParameterInput.module.scss';
function getOptions(options) {
    return (options.map((option) => {
        return {
            value: option.value,
            label: option.name
        };
    }) || []);
}
async function loadParameterOptions(dispatch, node, loadOptionsMethod) {
    if (!loadOptionsMethod) {
        return;
    }
    const response = await dispatch(fetchNodeParameter('loadOptions', loadOptionsMethod, node));
    return response.result;
}
const ParameterInput = ({ parameter, onChange, path, disabled, value, node, propertiesTree, error, ...otherProps }) => {
    const httpDispatch = useDispatch();
    const dependencies = useMemo(() => {
        return getDependenciesValues(node, parameter);
    }, [node, parameter]);
    const loadOptionsMethod = parameter.typeOptions?.loadOptionsMethod;
    const { data, isFetching } = useQuery([parameter.name, loadOptionsMethod, dependencies], () => loadParameterOptions(httpDispatch, node, loadOptionsMethod), {
        enabled: !!loadOptionsMethod,
        cacheTime: 60 * 1000,
        refetchOnWindowFocus: false,
        retry: false
    });
    const options = data || parameter.options || [];
    switch (parameter.type) {
        case 'string':
            return (React.createElement(ComputedInput, { ...otherProps, component: AntInput, onChange: onChange, value: value, disabled: !!disabled, propertiesTree: propertiesTree, error: error }));
        case 'json':
            return (React.createElement(ComputedInput, { component: AntTextArea, className: styles.jsonParameter, ...otherProps, onChange: onChange, value: value, disabled: !!disabled, error: error }));
        case 'number':
            return (React.createElement(ComputedInput, { ...otherProps, component: AntInput, type: "number", onChange: onChange, value: value, disabled: !!disabled, propertiesTree: propertiesTree, error: error }));
        case 'boolean':
            let computedExpressionValue;
            if (value === true) {
                computedExpressionValue = 'TRUE';
            }
            else if (value === false) {
                computedExpressionValue = 'FALSE';
            }
            else {
                computedExpressionValue = toString(value);
            }
            return (React.createElement("div", { className: "mb-2" },
                React.createElement(ComputedInput, { ...otherProps, component: Toggle, onChange: onChange, value: computedExpressionValue, checked: !!value, disabled: !!disabled, propertiesTree: propertiesTree, error: error })));
        case 'options':
            return (React.createElement(React.Fragment, null,
                React.createElement(Select, { ...otherProps, options: options ? getOptions(options) : [], loading: isFetching, onChange: (selectedValue) => onChange && onChange(selectedValue), value: value, disabled: !!disabled, status: error ? 'warning' : undefined }),
                !!error && React.createElement(ErrorMessage, null, error)));
        case 'multiOptions':
            if (!parameter || !parameter.options || !parameter.options.length) {
                return React.createElement("span", null, "multiOptions type must have options property");
            }
            return (React.createElement(React.Fragment, null,
                React.createElement(Select, { ...otherProps, className: "w-100", defaultValue: value, mode: "multiple", disabled: !!disabled, onChange: (selectedValue) => onChange && onChange(selectedValue), status: error ? 'warning' : undefined }, options.map(({ name, value }) => (React.createElement(Option, { key: value.toString(), value: value }, name)))),
                !!error && React.createElement(ErrorMessage, null, error)));
        case 'dateTime':
            const isComputedExpression = typeof value === 'string' && value.startsWith('=');
            if (value === '' || value === null) {
                value = undefined;
            }
            if (!isComputedExpression && value !== undefined) {
                value = moment(value).valueOf();
            }
            return (React.createElement(ComputedInput, { ...otherProps, component: DateField, onChange: (value) => {
                    if (typeof value === 'string' && value.startsWith('=')) {
                        onChange && onChange(value);
                    }
                    else if (typeof value !== 'boolean') {
                        onChange && onChange(moment(value).utc().toISOString());
                    }
                }, value: value, withTime: true, disabled: !!disabled, propertiesTree: propertiesTree, error: error }));
        case 'collection':
            return (React.createElement(CollectionField, { ...otherProps, propertiesTree: propertiesTree, path: `${path}.${parameter.name}`, parameter: parameter, node: node, value: value, onChange: onChange, disabled: !!disabled }));
        case 'fixedCollection':
            return (React.createElement(FixedCollectionField, { ...otherProps, path: `${path}.${parameter.name}`, propertiesTree: propertiesTree, parameter: parameter, value: value, node: node, disabled: !!disabled, onChange: (v) => {
                    onChange && onChange(v);
                } }));
        case 'resourceLocator':
            return (React.createElement(ResourceLocatorField, { ...otherProps, propertiesTree: propertiesTree, path: `${path}.${parameter.name}`, parameter: parameter, node: node, value: value, onChange: onChange, disabled: !!disabled, error: error }));
        case 'resourceMapper':
            return (React.createElement(ResourceMapperField, { ...otherProps, propertiesTree: propertiesTree, path: `${path}.${parameter.name}`, parameter: parameter, node: node, value: value, onChange: onChange, disabled: !!disabled, error: error }));
        default:
            return React.createElement("span", null,
                "Unknown property type (",
                parameter.type,
                ")");
    }
};
export default ParameterInput;
