import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Input } from 'antd';
import { isObject, isString, toString } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { isComputedExpression } from '../../../utils/computedExpression';
import { ComputedInput } from '../ComputedExpressionEditor';
import BaseFilter from './BasicFilter';
import { buildFilter } from './utils';
const availableOperators = [
    { value: FilterOperatorTypes.empty },
    { value: FilterOperatorTypes.notEmpty },
    { value: FilterOperatorTypes.equals },
    { value: FilterOperatorTypes.notEquals },
    { value: FilterOperatorTypes.greaterThan },
    { value: FilterOperatorTypes.lessThan },
    { value: FilterOperatorTypes.greaterThanOrEqual },
    { value: FilterOperatorTypes.lessThanEqual }
];
const NumberFilter = ({ className, filter, onChange, disabled, allowComputed, propertiesTree, unavailableOperators, autoFocus }) => {
    const data = 'data' in filter ? toString(filter.data) : undefined;
    const onChangeInput = useCallback((value) => {
        let newValue = isObject(value) && 'target' in value ? value.target.value : value;
        if (isString(newValue) &&
            !newValue.startsWith('=') &&
            isNaN(parseFloat(toString(newValue)))) {
            newValue = '';
        }
        const newFilter = buildFilter(filter.field, filter.operator, newValue);
        onChange(newFilter);
    }, [filter, onChange]);
    const inputRef = useRef(null);
    useEffect(() => {
        if (autoFocus) {
            inputRef.current?.focus({ preventScroll: true });
        }
    }, [autoFocus]);
    return (React.createElement(BaseFilter, { onChange: onChange, operators: availableOperators, unavailableOperators: unavailableOperators, filter: filter, className: className, disabled: disabled }, allowComputed || isComputedExpression(data) ? (React.createElement(ComputedInput, { component: Input, type: "number", value: data, onChange: onChangeInput, disabled: disabled, propertiesTree: propertiesTree, ref: inputRef })) : (React.createElement(Input, { type: "number", value: data, onChange: onChangeInput, disabled: disabled, ref: inputRef }))));
};
export default NumberFilter;
