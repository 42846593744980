import React, { useEffect, useMemo } from 'react';
import { isTableView } from '~app/views/types';
import { useHeight } from '../../../../../common/components/height-context/HeightContext';
import { useView } from '../../../../../views/components/ViewContext';
import { ItemsViewKanban } from './ItemsViewKanban';
import { ItemsViewTable } from './ItemsViewTable';
export const ItemsView = ({ actions, baseUrl, definitionId, view, viewId, getTotalItemsCount }) => {
    const { contentHeight } = useHeight();
    const { viewType, totalItemsCount } = useView();
    useEffect(() => {
        !!getTotalItemsCount && getTotalItemsCount(totalItemsCount);
    }, [getTotalItemsCount, totalItemsCount]);
    const ViewComponent = useMemo(() => (isTableView(viewType) ? ItemsViewTable : ItemsViewKanban), [viewType]);
    return (React.createElement(ViewComponent, { actions: actions, baseUrl: baseUrl, contentHeight: contentHeight, definitionId: definitionId, viewId: viewId, view: view }));
};
