import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { media } from '@hypercharge/hyper-react-base/lib/styling';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoAdd } from 'react-icons/io5';
import { MdAdd } from 'react-icons/md';
import styled from 'styled-components';
import { TooltipTruncate } from '../../../../../common/components/TooltipTruncate';
import { useBrowserBreakpoints } from '../../../../../common/components/with-browser-breakpoints';
import QuickAddItem from '../QuickAddItem';
import styles from './QuickAddItemButton.module.scss';
const QuickAddItemButton = ({ entityTitle, icon: Icon, clonedItemEntityId, customButtonComponent, requiredFieldsConfig, ...otherProps }) => {
    const unmounted = useRef(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { isMobile } = useBrowserBreakpoints();
    const { t } = useI18n();
    const toggleDrawer = useCallback(() => {
        if (!unmounted.current) {
            setIsDrawerOpen(!isDrawerOpen);
        }
    }, [isDrawerOpen]);
    const handleSaved = useCallback((createdEntityId) => {
        const result = otherProps.onClick(createdEntityId);
        if (result && 'then' in result) {
            void result.then(toggleDrawer);
        }
        else {
            toggleDrawer();
        }
        return result;
    }, [otherProps, toggleDrawer]);
    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);
    const createNewEntityTitle = `${clonedItemEntityId ? `${t('CMS_CLONE')}:` : ''} ${entityTitle}`;
    const ButtonComponent = (customButtonComponent || Button);
    return (React.createElement(ErrorBoundary, null,
        React.createElement(QuickAddItem, { requiredFieldsConfig: requiredFieldsConfig, definitionId: otherProps.definitionId, onFinish: handleSaved, onCancel: toggleDrawer, clonedItemEntityId: clonedItemEntityId, isDrawerOpen: isDrawerOpen, drawerTitle: createNewEntityTitle }),
        Icon ? (React.createElement(AddItem, { ...otherProps, className: otherProps.className, onClick: toggleDrawer, disabled: otherProps.disabled || otherProps.loading },
            React.createElement(Icon, { className: "create-icon" }),
            React.createElement(StyledAdd, null))) : (React.createElement(ButtonComponent, { ...otherProps, className: otherProps.className, onClick: toggleDrawer, loading: otherProps.loading, disabled: otherProps.disabled, title: createNewEntityTitle }, otherProps.children || (React.createElement("div", { className: "cp-c-row cp-c-align-start-center" },
            React.createElement(StyledIoAdd, null),
            React.createElement(TooltipTruncate, { className: styles.buttonText }, !isMobile && createNewEntityTitle)))))));
};
export default QuickAddItemButton;
//
// Utils
//
const StyledIoAdd = styled(IoAdd) `
  ${media.sm} {
    margin-right: 0.3rem;
  }
`;
const StyledAdd = styled(MdAdd) `
  position: absolute;
  bottom: -5px;
  right: -5px;
  background: var(--grayscaleColor-1);
  border-radius: 90px;
  color: var(--neutralColor-6);
`;
const AddItem = styled.div `
  position: relative;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  svg {
    vertical-align: middle;
  }

  .create-icon {
    font-size: 1.3rem;
    color: var(--neutralColor-6);
  }

  :hover {
    .create-icon {
      color: ${(props) => (props.disabled ? null : 'var(--infoColor-5)')};
    }
    ${StyledAdd} {
      color: ${(props) => (props.disabled ? null : 'var(--infoColor-5)')};
    }
  }
`;
