import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Modal } from 'antd';
import cn from 'classnames';
import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import * as Yup from 'yup';
import AntInputField from '~app/common/components/AntInput/AntInputField';
import ConfirmationButtonModal from '~app/common/components/ConfirmationButtonModal';
import DropdownButton from '~app/common/components/DropdownButton';
import FormikField from '~app/common/components/formik/FormikField';
const confirmModal = ConfirmationButtonModal.confirm;
const validationSchema = Yup.object().shape({
    title: Yup.string().required().min(1)
});
const DEFAULT_INITIAL_VALUE = {
    title: ''
};
const modalBodyStyle = {
    padding: 0
};
const QueryItemActions = ({ className, disabled, queryItem, onSave, onReset, onDelete, children }) => {
    const { t } = useI18n();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [initialFormValue, setInitialFormValue] = useState();
    const onCloseConfirmation = (e) => {
        e.stopPropagation();
        setShowConfirmation(false);
    };
    const onSubmit = useCallback(async (value) => {
        try {
            await onSave(value);
        }
        finally {
            setShowConfirmation(false);
        }
    }, [onSave]);
    const showForm = useCallback((formValue) => {
        setInitialFormValue(formValue);
        setShowConfirmation(true);
    }, []);
    return (React.createElement(Formik, { onSubmit: onSubmit, initialValues: initialFormValue ?? DEFAULT_INITIAL_VALUE, validationSchema: validationSchema, enableReinitialize: true }, ({ isSubmitting, submitForm, setSubmitting }) => {
        return (React.createElement(React.Fragment, null,
            React.createElement(DropdownButton, { className: cn(className), disabled: disabled, loading: isSubmitting, onClick: async () => {
                    if (!queryItem) {
                        return showForm();
                    }
                    setSubmitting(true);
                    try {
                        await onSubmit({
                            entityId: queryItem.entityId
                        });
                    }
                    finally {
                        setSubmitting(false);
                    }
                }, menu: queryItem
                    ? {
                        items: [
                            {
                                key: 'save-as',
                                danger: false,
                                onClick: () => {
                                    showForm();
                                },
                                label: React.createElement(React.Fragment, null, t('COMMON__SAVE_AS'))
                            },
                            {
                                key: 'edit',
                                danger: false,
                                onClick: () => {
                                    showForm({
                                        entityId: queryItem.entityId,
                                        title: queryItem.title
                                    });
                                },
                                label: React.createElement(React.Fragment, null, t('COMMON__EDIT'))
                            },
                            {
                                key: 'reset',
                                danger: false,
                                onClick: () => {
                                    onReset();
                                },
                                label: React.createElement(React.Fragment, null, t('FORM__RESET'))
                            },
                            {
                                key: 'delete',
                                danger: true,
                                icon: React.createElement(FaTrash, null),
                                onClick: () => {
                                    confirmModal({
                                        onOk: () => {
                                            return onDelete();
                                        },
                                        title: t('COMMON__DELETE'),
                                        content: t(`DELETE_ENTITY_CONFIRMATION`),
                                        okText: t('COMMON__DELETE'),
                                        cancelText: t('COMMON_CANCEL'),
                                        okButtonProps: {
                                            danger: true
                                        }
                                    }, t);
                                },
                                label: React.createElement(React.Fragment, null, t('COMMON__DELETE'))
                            }
                        ]
                    }
                    : undefined }, children),
            React.createElement(Modal, { centered: true, open: showConfirmation, className: "ant-modal-confirmation ", title: initialFormValue?.entityId
                    ? t('LOGS_EDIT_QUERY_MODAL_TITLE')
                    : t('LOGS_SAVE_NEW_QUERY_MODAL_TITLE'), destroyOnClose: true, bodyStyle: modalBodyStyle, onCancel: onCloseConfirmation, maskClosable: !isSubmitting, keyboard: !isSubmitting, closable: !isSubmitting, footer: [
                    React.createElement(Button, { type: "text", className: "fw-600 cancel", key: "cancel", onClick: onCloseConfirmation, disabled: isSubmitting }, t('COMMON_CANCEL')),
                    React.createElement(Button, { type: "primary", key: "save", onClick: submitForm, loading: isSubmitting, className: "ms-3 fw-600 right d-inline-flex align-items-center" }, t('COMMON_SAVE'))
                ] },
                React.createElement(Form, { noValidate: true },
                    React.createElement(LabelText, null,
                        t('COMMON__TITLE'),
                        ":"),
                    React.createElement(FormikField, { name: "title", autoFocus: true, component: AntInputField, autoComplete: "off", disabled: isSubmitting })))));
    }));
};
export default QueryItemActions;
