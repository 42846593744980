import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Alert, Button as AntButton, Modal, Tooltip } from 'antd';
import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { IoIosWarning } from 'react-icons/io';
const modalBodyStyle = {
    padding: 0
};
const ConfirmationButtonModal = ({ className, tooltip, danger, disabled, title, message, warningMessage, onClick, children, confirmButtonText, okButtonProps, cancelButtonText }) => {
    const { t } = useI18n();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const onCloseConfirmation = (e) => {
        e.stopPropagation();
        setShowConfirmation(false);
    };
    const onClickConfirm = useCallback(async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);
        try {
            if (onClick) {
                await onClick();
            }
        }
        finally {
            setLoading(false);
            setShowConfirmation(false);
        }
    }, [onClick]);
    useEffect(() => {
        if (!showConfirmation) {
            return;
        }
        const handleKeyDown = async (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (loading || !showConfirmation) {
                return;
            }
            if (event.key === 'Escape') {
                setShowConfirmation(false);
            }
            if (event.key === 'Enter') {
                setLoading(true);
                try {
                    if (onClick) {
                        await onClick();
                    }
                    setLoading(false);
                    setShowConfirmation(false);
                }
                catch (error) {
                    console.error(error);
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [loading, onClick, onClickConfirm, showConfirmation]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: tooltip, placement: "topRight" },
            React.createElement(Button, { className: cn(className, { danger }), inversed: danger ? undefined : true, onClick: () => setShowConfirmation(true), disabled: disabled }, children)),
        React.createElement(Modal, { centered: true, open: showConfirmation, className: "ant-modal-confirmation", title: title, destroyOnClose: true, bodyStyle: modalBodyStyle, onOk: onClickConfirm, onCancel: onCloseConfirmation, maskClosable: !loading, keyboard: false, closable: !loading, footer: [
                React.createElement(AntButton, { type: "text", className: "fw-600 cancel", key: "cancel", onClick: onCloseConfirmation, disabled: loading }, cancelButtonText || t('COMMON__NO')),
                React.createElement(AntButton, { loading: loading, danger: danger || okButtonProps?.danger, className: "ms-3 fw-600 right d-inline-flex align-items-center", key: "start", onClick: onClickConfirm }, confirmButtonText || t('COMMON__YES'))
            ] },
            warningMessage && (React.createElement(Alert, { showIcon: true, icon: React.createElement(IoIosWarning, { size: 22 }), message: warningMessage, type: "warning", className: "mt-2 mb-3" })),
            React.createElement("div", { className: "ant-modal-confirm-content" }, message))));
};
ConfirmationButtonModal.confirm = (confirmProps, translate) => {
    let isProcessing = false;
    const modal = Modal.confirm({
        icon: null,
        wrapClassName: 'wrap-confirm-modal',
        okText: translate('COMMON__YES'),
        cancelText: translate('COMMON__NO'),
        width: 400,
        autoFocusButton: null,
        closable: true,
        centered: true,
        keyboard: false,
        afterClose: () => {
            window.removeEventListener('keydown', handleEnterKey);
        },
        okButtonProps: {
            danger: true
        },
        ...confirmProps
    });
    const updateButtonsState = (isLoading) => {
        modal.update({
            cancelButtonProps: { ...confirmProps.cancelButtonProps, disabled: isLoading },
            okButtonProps: { ...confirmProps.okButtonProps, loading: isLoading }
        });
    };
    const handleEnterKey = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.key === 'Escape' && !isProcessing) {
            modal.destroy();
        }
        if (event.key === 'Enter' && confirmProps.onOk && !isProcessing) {
            isProcessing = true;
            try {
                updateButtonsState(true);
                await confirmProps.onOk();
                modal.destroy();
            }
            catch (error) {
                console.error(error);
            }
            finally {
                isProcessing = false;
                updateButtonsState(false);
            }
        }
    };
    window.addEventListener('keydown', handleEnterKey);
};
export default ConfirmationButtonModal;
