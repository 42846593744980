import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useMemo } from 'react';
import styles from './AvailableTasks/AvailableTask.module.scss';
import { useRequiredFields } from './RequiredFieldsProvider';
import TaskFields from './TaskFields';
const TaskFieldsContainer = ({ canEdit, processMetaId, processRunId, taskMetaId, taskRunId, requiredFieldsConfig, permissionFieldsConfig, onChange, optionalFields }) => {
    const { requiredFieldsMap, requiredFieldsStatus } = useRequiredFields();
    const { t } = useI18n();
    const requiredFields = useMemo(() => requiredFieldsMap[taskRunId] || [], [requiredFieldsMap, taskRunId]);
    const additionalRequiredFields = useMemo(() => requiredFields?.reduce((obj, item) => {
        const [key, value] = item.split('.');
        if (value) {
            obj[key] = obj[key] ? [...obj[key], value] : [value];
        }
        return obj;
    }, {}), [requiredFields]);
    const additionalOptionalFields = useMemo(() => optionalFields?.reduce((obj, item) => {
        const [key, value] = item.split('.');
        if (value) {
            obj[key] = obj[key] ? [...obj[key], value] : [value];
        }
        return obj;
    }, {}), [optionalFields]);
    return (React.createElement(React.Fragment, null,
        requiredFieldsStatus.isPending && React.createElement(LoadingRectangles, null),
        Array.isArray(requiredFields) && !!requiredFields.length && (React.createElement("div", { className: styles.taskContentBottomBlock },
            React.createElement("div", { className: styles.blockTitle },
                t('TASK_REQUIRED_PROPERTIES'),
                ":"),
            React.createElement(TaskFields, { canEdit: canEdit, processMetaId: processMetaId, processRunId: processRunId, taskMetaId: taskMetaId, taskRunId: taskRunId, fields: requiredFields, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, onChange: onChange, additionalFields: additionalRequiredFields }))),
        Array.isArray(optionalFields) && !!optionalFields.length && (React.createElement("div", { className: styles.taskContentBottomBlock },
            React.createElement("div", { className: styles.blockTitle },
                t('TASK_OPTIONAL_PROPERTIES'),
                ":"),
            React.createElement(TaskFields, { canEdit: canEdit, processMetaId: processMetaId, processRunId: processRunId, taskMetaId: taskMetaId, taskRunId: taskRunId, fields: optionalFields, permissionFieldsConfig: permissionFieldsConfig, onChange: onChange, additionalFields: additionalOptionalFields })))));
};
export default TaskFieldsContainer;
