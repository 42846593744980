import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LoadingOverlay } from '../../../../../common/utils/stylingUtils';
import ActivityContainer from '../ActivityContainer/ActivityContainer';
import { useNodes } from '../activity-diagram/useNode';
import styles from './ActivityContainerWrapper.module.scss';
const ActivityContainerWrapper = ({ onSubmit, processDefinition, disabled, ...restProps }) => {
    const { data, isLoading, isError } = useNodes();
    const dispatch = useDispatch();
    const { t } = useI18n();
    useEffect(() => {
        if (isError) {
            dispatch(error({ title: t('ERROR'), message: t('PROCESS_META__NODES_FETCHING_FAILED') }));
        }
    }, [dispatch, isError, t]);
    const onFormSubmit = useCallback(async (values) => {
        if (!values.custom?.activity) {
            return processDefinition;
        }
        return onSubmit({
            custom: {
                ...processDefinition.custom,
                activity: values.custom?.activity
            }
        });
    }, [onSubmit, processDefinition]);
    return isLoading ? (React.createElement("div", { className: styles.loadingContainer },
        React.createElement(LoadingOverlay, null,
            React.createElement(LoadingRectangles, null)))) : (React.createElement(ActivityContainer, { processDefinition: processDefinition, nodes: data?.nodes, credentialTypes: data?.credentials, disabled: disabled || isError, t: t, onSubmit: onFormSubmit, initialValues: processDefinition, ...restProps }));
};
export default ActivityContainerWrapper;
