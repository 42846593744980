import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import NotFound from '@hypercharge/hyper-react-base/lib/common/not-found';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { media, Wrapper } from '@hypercharge/hyper-react-base/lib/styling';
import { Steps } from 'antd';
import { find, findIndex, get } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { MdPhonelinkErase } from 'react-icons/md';
import { Redirect, Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import styled from 'styled-components';
import { redirectIfNoPermission } from '../../../../auth';
import withBrowserBreakpoints from '../../../../common/components/with-browser-breakpoints';
import { getEntityBrowseUrl } from '../../../../common/utils/url';
import { Breadcrumbs } from '../../../common/breadcrumbs';
import { PropertiesProvider } from '../../../common/context/PropertiesProvider';
import { CMS_IMPORTER_PATH, CMS_PATH, TITLE_PROPERTY_ID } from '../../../common/utils/constants';
import useDisplayItemMeta from '../../../hooks/useDisplayItemMeta';
import ImportingStep from './ImportingStep';
import MappingStep from './MappingStep';
import UploadStep from './UploadStep';
const ImporterRoutes = ({ location: { pathname }, match: { url, params: { definitionId } }, isMobile, dispatch }) => {
    const { data: displayItemMeta, isLoading } = useDisplayItemMeta({ definitionId });
    const { t } = useI18n();
    const links = useMemo(() => [
        { text: t('CMS_NAV_LINK'), to: CMS_PATH },
        {
            text: get(displayItemMeta, TITLE_PROPERTY_ID) || (isLoading ? '...' : t('NO_TITLE')),
            to: getEntityBrowseUrl(definitionId)
        }
    ], [displayItemMeta, isLoading, definitionId, t]);
    const handleCancel = useCallback(() => {
        dispatch(push(getEntityBrowseUrl(definitionId)));
    }, [definitionId, dispatch]);
    const currentStepIndex = useMemo(() => getCurrentStepIndex(pathname), [pathname]);
    const goToNextStep = useCallback((importEntityId) => {
        if (currentStepIndex < steps.length - 1) {
            dispatch(push(`${CMS_PATH}/${definitionId}${CMS_IMPORTER_PATH}/${importEntityId}/${steps[currentStepIndex + 1].key}`));
        }
    }, [currentStepIndex, definitionId, dispatch]);
    if (isMobile) {
        return (React.createElement(NotFound, { title: React.createElement(MdPhonelinkErase, null), content: t('CMS_IMPORTER__UNAVAILABLE_FOR_SMALL_SCREENS') }));
    }
    return (React.createElement(ErrorBoundary, null,
        React.createElement(Breadcrumbs, { links: links, currentBreadcrumb: t('CMS_IMPORTER') }),
        React.createElement(ScWrapper, null,
            React.createElement(ScSteps, { current: currentStepIndex }, steps.map(({ key, titleKey }) => (React.createElement(Step, { key: key, title: t(titleKey) })))),
            React.createElement(PropertiesProvider, null,
                React.createElement(Switch, null,
                    React.createElement(Route, { path: `${url}/${uploadStepKey}`, render: (props) => {
                            return (React.createElement(UploadStep, { definitionId: definitionId, handleCancel: handleCancel, goToNextStep: goToNextStep, ...props }));
                        } }),
                    React.createElement(Route, { path: `${url}/:importEntityId/:stepKey`, render: (props) => {
                            const step = find(steps, { key: props.match.params.stepKey });
                            if (step && step.component) {
                                const StepComponent = step.component;
                                return (React.createElement(StepComponent, { definitionId: definitionId, importEntityId: props.match.params.importEntityId, handleCancel: handleCancel, goToNextStep: goToNextStep, dispatch: dispatch, ...props }));
                            }
                            return React.createElement(Redirect, { to: `${url}/${uploadStepKey}` });
                        } }),
                    React.createElement(Redirect, { to: `${url}/${uploadStepKey}` }))))));
};
export default compose(redirectIfNoPermission, withBrowserBreakpoints)(ImporterRoutes);
//
// Utils
//
const { Step } = Steps;
const uploadStepKey = 'upload';
const steps = [
    { key: uploadStepKey, titleKey: 'CMS_IMPORTER__UPLOAD_STEP', component: UploadStep },
    { key: 'mapping', titleKey: 'CMS_IMPORTER__MAPPING_STEP', component: MappingStep },
    { key: 'processing', titleKey: 'CMS_IMPORTER__PROCESSING_STEP', component: ImportingStep }
];
const getCurrentStepIndex = (locationPathname) => {
    // this is necessary because react-router's match will not include the param 'step'
    // because the route that defines it is a child of this route
    const matchStepKey = /.*\/(.*)$/.exec(locationPathname);
    const matchedIndex = findIndex(steps, { key: matchStepKey?.[1] });
    return matchedIndex > -1 ? matchedIndex : 0;
};
const ScWrapper = styled(Wrapper) `
  padding: 0 1rem;
`;
const ScSteps = styled(Steps) `
  && {
    margin: 2rem 0;

    ${media.lg} {
      margin: 3rem 0;
    }
  }
`;
