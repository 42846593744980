import { OPEN_IN_MODAL_QUILL_ACTION_NAME } from './open-in-modal-format';
export const EDITOR_CONFIG = {
    theme: 'snow',
    formats: [
        'header',
        'color',
        'bold',
        'italic',
        'strike',
        'code',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'imageBlot',
        'width',
        'code-block',
        'blockquote',
        'align',
        OPEN_IN_MODAL_QUILL_ACTION_NAME
    ]
};
export const EDITOR_DEFAULT_VALUE = { ops: [] };
