import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { formatDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { Tooltip } from 'antd';
import cn from 'classnames';
import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import styles from './DeadlineDisplay.module.scss';
const DeadlineDisplay = ({ deadline, emphasizeOverdue = true, icon: Icon = null }) => {
    const { t } = useI18n();
    if (!deadline) {
        return (React.createElement("div", { className: cn(styles.noDeadline, { 'ps-3': !Icon }, 'NoDeadlineOuter') },
            Icon && React.createElement(Icon, null),
            " ",
            t('NO_DEADLINE')));
    }
    if (emphasizeOverdue && new Date(deadline).getTime() < Date.now()) {
        return (React.createElement(Tooltip, { title: formatDateTime(deadline), placement: "right" },
            React.createElement("div", { className: cn(styles.deadlineText, 'DeadlineDisplayTextOuter') },
                React.createElement("span", { className: cn(styles.deadlineIcon, 'DeadlineIconOuter') },
                    React.createElement(FaExclamationTriangle, { size: 20 })),
                `${capitalize(moment(deadline).fromNow(true))} ${t('LATE')}`)));
    }
    return (React.createElement("div", { className: `${styles.noDeadline} ps-3 DeadlineDisplayOuter` },
        Icon && React.createElement(Icon, null),
        formatDateTime(deadline)));
};
export default DeadlineDisplay;
