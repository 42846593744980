import { PlusOutlined } from '@ant-design/icons';
import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Badge, Spin } from 'antd';
import cn from 'classnames';
import { compact, find, isPlainObject } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaEye, FaFilter, FaTimesCircle, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { ClearIcon } from '../../../../icons';
import { getParentElementOrBody } from '../../../utils/common';
import { MAX_ITEMS_COUNT_FOR_ENTITY_SELECTOR } from '../../../utils/constants';
import { ErrorLabel, getIsFormHasError } from '../../../utils/formUtils';
import { Option, Select } from '../../Select';
import styles from './GenericEntitySelector.module.scss';
const NEW_CUSTOM_ENTITY = 'NEW_CUSTOM_ENTITY';
const SEARCHING = 'SEARCHING';
const getIdsFromValue = (value) => compact(value ? (Array.isArray(value) ? value : [value]) : []);
// some services have an entity level and some don't
// for now this is the workaround
const getEntityFromResult = (result) => isPlainObject(result.entity) ? result.entity : result;
const autofillAttributeChange = (name, selectRef) => {
    if (selectRef.current) {
        const inputElement = selectRef.current.querySelector('input');
        if (name) {
            inputElement?.setAttribute('name', name);
        }
    }
};
/**
 * @description This is an **INTERNAL** component for another component - `CustomEntitySelector`. **DO NOT USE IT IN OTHER PLACES**
 */
const GenericEntitySelector = ({ name, allowClear, open, setOpen, autoFocus, className, withoutSearchResults, style, searchEntities, placeholder, input, label, disabled, multiple, defaultOptions = [], createNewEntityPlaceholder, createNewEntityPlaceholderIsLoading, onCreateNew, onModalOpen, showFilter, filtersCount, getOptionId, popupMatchSelectWidth = true, popupClassName, forceFetch, fetchEntity, ignoreOnEnterKeyDown, onKeyDown, getOptionContent, getEntity, isEntityAvailable, isEntityPending, simpleClear = false, setNeededSpaceOnBottom, hasNeededSpacesOnBottom = true, renderOptionsInParent, meta, handlePaste, withMaxTagCount = true, invalid }) => {
    const { t } = useI18n();
    const searchEntitiesDispatch = useDispatch();
    const fetchByIdDispatch = useDispatch();
    // If we use keyboard arrows to navigate in dropdown list, we have situation,
    // when Enter can be pressed, but new value is not applied yet.
    // When we catch this event in parent components, we can't save new value,
    // because Enter event triggered before value is changed.
    // In text inputs value change due input/paste and on Enter press we have correct new value
    const delayedEnterKeyboardEvent = useRef(null);
    const _searchEntities = useCallback((query, numberOfSelected) => searchEntitiesDispatch(searchEntities(query, numberOfSelected)), [searchEntities, searchEntitiesDispatch]);
    const _fetchEntity = useCallback((id) => fetchByIdDispatch(fetchEntity(id)), [fetchEntity, fetchByIdDispatch]);
    const { selectedEntities, notAvailableSelectedEntityIds, pendingSelectedEntityIds } = useSelector((s) => {
        const ids = getIdsFromValue(input.value);
        const selectedEntities = compact(ids
            .filter((val) => !find(defaultOptions, ['key', val]))
            .map((val) => getEntity(s, val)));
        return {
            selectedEntities,
            notAvailableSelectedEntityIds: ids.filter((value) => !isEntityAvailable(s, value)),
            pendingSelectedEntityIds: ids.filter((value) => isEntityPending(s, value))
        };
    });
    const [unmounted, setUnmounted] = useState(false);
    const [fieldFocused, setFieldFocused] = useState(false);
    const [results, setResults] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchingEntities, setSearchingEntities] = useState(false);
    const [query, setQuery] = useState('');
    const selectValue = useMemo(() => {
        const labels = {
            ...results.reduce((acc, cur) => {
                return { ...acc, [getOptionId(cur)]: getOptionContent(cur) };
            }, {}),
            ...selectedEntities.reduce((acc, cur) => {
                return { ...acc, [getOptionId(cur)]: getOptionContent(cur) };
            }, {})
        };
        if (Array.isArray(input?.value)) {
            return input.value.map((value) => ({ label: labels[value] || value, value })) || [];
        }
        else {
            const singleValue = { label: labels[input.value] || input.value, value: input.value };
            if (Object.values(singleValue).every((val) => typeof val !== 'number' && !val)) {
                return null;
            }
            else {
                return singleValue;
            }
        }
    }, [results, selectedEntities, input.value, getOptionId, getOptionContent]);
    const selectRef = useRef(null);
    useEffect(() => {
        setOpen(!!autoFocus);
        fetchSelectedEntities();
        // TODO: Need to implement scroll handler in the parent component (on table level) after refactoring of table
        const scrollHandler = () => {
            setOpen(false);
        };
        const tableHorizontalScrollWrapper = document.querySelector('.ReactTable')?.parentNode;
        const tableVerticalScrollWrapper = document.querySelector('.table-vertical-scroll-wrapper');
        tableHorizontalScrollWrapper?.addEventListener('scroll', scrollHandler, false);
        tableVerticalScrollWrapper?.addEventListener('scroll', scrollHandler, false);
        return () => {
            setUnmounted(true);
            tableHorizontalScrollWrapper?.removeEventListener('scroll', scrollHandler, false);
            tableVerticalScrollWrapper?.removeEventListener('scroll', scrollHandler, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const searchForEntities = useDebouncedCallback(async (queryArg) => {
        if (withoutSearchResults) {
            return false;
        }
        if (queryArg !== query || forceFetch || queryArg === undefined) {
            if (!unmounted) {
                setQuery(queryArg);
                setSearchingEntities(true);
                const response = await _searchEntities({ terms: [], ranges: [], sortBy: [], textSearchQuery: queryArg }, selectedEntities.length);
                if (response) {
                    const totalCount = 'data' in response ? response.data.totalCount : response.totalCount;
                    let filteredEntities = 'data' in response ? response.data.results : response.results;
                    if (defaultOptions) {
                        filteredEntities = filteredEntities.filter((e) => !find(defaultOptions, (fe) => getOptionId(fe) == getOptionId(e)));
                    }
                    if (!unmounted) {
                        if (!multiple &&
                            queryArg &&
                            typeof input.value === 'undefined' &&
                            filteredEntities.length === 1 &&
                            filteredEntities[0].title?.toLowerCase() === queryArg.toLowerCase()) {
                            input?.onChange(multiple
                                ? [...(input?.value || []), filteredEntities[0].entityId]
                                : filteredEntities[0].entityId);
                        }
                        setTotalCount(totalCount);
                        setResults(filteredEntities);
                        setSearchingEntities(false);
                    }
                }
            }
        }
    }, 200);
    const fetchSelectedEntities = useCallback(() => {
        notAvailableSelectedEntityIds.forEach((id) => {
            if (!pendingSelectedEntityIds.includes(id)) {
                return void _fetchEntity(id);
            }
        });
    }, [_fetchEntity, notAvailableSelectedEntityIds, pendingSelectedEntityIds]);
    const getKeyDisplayFromResult = useCallback((result) => {
        const entity = getEntityFromResult(result);
        return {
            key: getOptionId(entity),
            display: getOptionContent(entity)
        };
    }, [getOptionContent, getOptionId]);
    const getOptionJsx = useCallback(({ key, display, duplicate }) => {
        const toDisplay = `${display} ${duplicate ? '(' + key + ')' : ''}`;
        return (React.createElement(Option, { key: key, title: toDisplay, value: key }, toDisplay));
    }, []);
    const allOptions = useMemo(() => {
        let options = [
            ...defaultOptions.filter(({ key }) => !find(selectedEntities, (item) => getOptionId(item) === key)),
            ...results
                .map(getKeyDisplayFromResult)
                .filter(({ key }) => !find(selectedEntities, (item) => getOptionId(item) === key))
        ];
        // performance wise this would make more sense to populate the map
        // while transforming the results but the code would be messier
        const existingDisplaysCount = options.reduce((result, { display }) => {
            result[display] = (result[display] || 0) + 1;
            return result;
        }, {});
        options = options.map((pair) => ({
            ...pair,
            duplicate: existingDisplaysCount[pair.display] > 1
        }));
        return options;
    }, [defaultOptions, getKeyDisplayFromResult, results, selectedEntities, getOptionId]);
    const handleOnFocus = useCallback(() => {
        setFieldFocused(true);
        void searchForEntities();
        setOpen(true);
    }, [searchForEntities, setOpen]);
    const handleOnClick = useCallback((event) => {
        const eventTarget = event.target;
        if (eventTarget instanceof HTMLElement &&
            eventTarget.className.includes('ant-select-selection-item')) {
            setOpen(true);
        }
        else if (!open) {
            setOpen(true);
        }
    }, [open, setOpen]);
    const handleOnBlur = useCallback(() => {
        setOpen(false);
        setTimeout(() => {
            setFieldFocused(false);
        }, 300);
    }, [setOpen]);
    const _onKeyDown = useCallback((e) => {
        if (!onKeyDown) {
            return;
        }
        const isEnter = e.key === 'Enter';
        if (isEnter && ignoreOnEnterKeyDown) {
            return;
        }
        if (isEnter) {
            delayedEnterKeyboardEvent.current = e;
        }
        else {
            onKeyDown(e);
        }
    }, [ignoreOnEnterKeyDown, onKeyDown]);
    const isTrashButtonAvailable = useMemo(() => !!allowClear &&
        multiple &&
        !simpleClear &&
        Boolean(Array.isArray(input.value) ? input.value.length : input.value), [allowClear, multiple, simpleClear, input.value]);
    const getDropdownRenderJsx = useCallback((menu) => (React.createElement("div", { ref: (ref) => setNeededSpaceOnBottom?.(ref?.getBoundingClientRect().height || 0), className: "position-relative" },
        totalCount > results.length && (React.createElement("div", { className: `ant-select-dropdown-header ${styles.dropdownHeader}` }, t('SHOWING_TOP_RESULTS', { smart_count: totalCount }))),
        menu)), [results.length, setNeededSpaceOnBottom, t, totalCount]);
    const maxTagPlaceholderRender = useCallback((items, input) => (React.createElement(React.Fragment, null,
        React.createElement(FaEye, { className: "icon" }),
        React.createElement("span", { className: "text" },
            React.createElement("span", null,
                items.length,
                " "),
            React.createElement("span", null, t('CMS_ITEMS'))),
        !!allowClear && (React.createElement("button", { className: "clear-button", onClick: () => input.onChange([]) },
            React.createElement(FaTimesCircle, null))))), [allowClear, t]);
    const showError = useMemo(() => !!invalid || getIsFormHasError(meta), [meta, invalid]);
    const shouldDisplayValue = Boolean(!Array.isArray(selectValue) && selectValue?.value) ||
        Boolean(Array.isArray(input?.value) && input.value.length) ||
        (notAvailableSelectedEntityIds.length > 0 && !searchingEntities);
    useEffect(() => {
        autofillAttributeChange(name, selectRef);
    }, [name]);
    return (React.createElement("div", { style: style, className: cn('SelectWrapper', className, styles.inputContainer, {
            [styles.withShowFilterButton]: showFilter,
            disabledTextOverflow: notAvailableSelectedEntityIds.length,
            withMaxTagCount
        }), ref: selectRef, onPaste: handlePaste },
        label && (React.createElement(LabelText, { className: "mb-1", htmlFor: input.name }, label)),
        showFilter && onModalOpen && (React.createElement("button", { type: "button", tabIndex: -1, className: `${styles.showFilterButton} ${selectRef.current && selectRef.current.clientHeight > 40 ? styles.inverseBorder : ''} GenericEntitySelectorOuter`, onClick: () => {
                setOpen(false);
                onModalOpen(true);
            } },
            React.createElement(Badge, { count: filtersCount || 0 },
                React.createElement(FaFilter, null)))),
        isTrashButtonAvailable && (React.createElement(React.Fragment, null,
            React.createElement("button", { tabIndex: -1, className: `${styles.clearSelectorButton} clear-selector-button ${fieldFocused ? styles.clearSelectorButton__active : ''} ${hasNeededSpacesOnBottom
                    ? styles.clearSelectorButton__topButton
                    : styles.clearSelectorButton__bottomButton}`, onClick: () => {
                    input.onChange(multiple ? [] : null);
                } },
                React.createElement(FaTrash, null)))),
        React.createElement(Select, { popupClassName: popupClassName, getPopupContainer: (node) => getParentElementOrBody(node, renderOptionsInParent), showSearch: true, allowClear: !!allowClear &&
                (simpleClear || !multiple) && {
                clearIcon: React.createElement(ClearIcon, null)
            }, style: { width: '100%' }, filterOption: false, popupMatchSelectWidth: popupMatchSelectWidth, notFoundContent: searchingEntities ? React.createElement(Spin, { size: "small" }) : null, placeholder: placeholder, onSearch: searchForEntities, disabled: disabled, defaultActiveFirstOption: false, value: shouldDisplayValue ? selectValue || null : multiple ? [] : null, virtual: false, labelInValue: true, onFocus: handleOnFocus, onClick: handleOnClick, onBlur: handleOnBlur, open: open, placement: hasNeededSpacesOnBottom ? 'bottomLeft' : 'topLeft', autoFocus: autoFocus, onChange: (labeledValue) => {
                const value = Array.isArray(labeledValue)
                    ? labeledValue.map((v) => v.value)
                    : labeledValue?.value || null;
                const entityIds = getIdsFromValue(value).filter((id) => ![NEW_CUSTOM_ENTITY, SEARCHING].includes(id));
                const resultValue = Array.isArray(value) ? entityIds : entityIds[0] || null;
                input.onChange(resultValue);
                if (delayedEnterKeyboardEvent.current && value !== NEW_CUSTOM_ENTITY) {
                    if (onKeyDown) {
                        onKeyDown(delayedEnterKeyboardEvent.current, resultValue);
                    }
                    delayedEnterKeyboardEvent.current = null;
                }
            }, onSelect: (labeledValue) => {
                if (!multiple) {
                    setOpen(false);
                }
                if (onCreateNew && labeledValue.value === NEW_CUSTOM_ENTITY) {
                    onCreateNew(query || '');
                }
                if (labeledValue.value !== SEARCHING) {
                    void searchForEntities();
                }
            }, mode: multiple ? 'multiple' : undefined, onInputKeyDown: _onKeyDown, dropdownRender: getDropdownRenderJsx, maxTagCount: withMaxTagCount && selectedEntities.length > MAX_ITEMS_COUNT_FOR_ENTITY_SELECTOR
                ? 0
                : undefined, maxTagPlaceholder: (items) => maxTagPlaceholderRender(items, input), status: showError ? 'warning' : undefined },
            allOptions.length > 0 &&
                notAvailableSelectedEntityIds
                    .filter((id) => !find(results, (item) => id === getOptionId(item)))
                    .map((id, i) => (React.createElement(Option, { key: `${id}-${i}`, value: id }, id))),
            allOptions.length > 0 && allOptions.map(getOptionJsx),
            (pendingSelectedEntityIds.length ||
                (searchingEntities && !createNewEntityPlaceholderIsLoading)) && (React.createElement(Option, { key: SEARCHING, value: SEARCHING, disabled: true },
                React.createElement(Spin, { size: "small" }))),
            onCreateNew && (React.createElement(Option, { id: NEW_CUSTOM_ENTITY, key: NEW_CUSTOM_ENTITY, disabled: createNewEntityPlaceholderIsLoading, value: NEW_CUSTOM_ENTITY, className: "create-new-option position-sticky bottom-0" }, createNewEntityPlaceholderIsLoading ? (React.createElement(Spin, { size: "small" })) : (React.createElement("div", { style: { pointerEvents: 'none' }, className: "cp-c-row cp-c-align-spacebetween-center" },
                createNewEntityPlaceholder,
                React.createElement(PlusOutlined, null)))))),
        React.createElement(ErrorLabel, { position: 'absolute', show: !!showError, error: meta?.error })));
};
export default GenericEntitySelector;
