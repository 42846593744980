import { AggregationType } from '@hypercharge/portal-utils';
import { MetricsOptions } from '../../../metrics';
export const AvailableSettingsByChart = {
    pie: {
        allowSubAggregations: false,
        manyMetrics: false,
        metricUnits: false,
        metricPercent: false
    },
    donut: {
        allowSubAggregations: false,
        manyMetrics: false,
        metricUnits: false,
        metricPercent: false
    },
    funnel: {
        allowSubAggregations: false,
        manyMetrics: false,
        metricUnits: false,
        metricPercent: false
    },
    metric: {
        allowAggregations: false,
        allowSubAggregations: false,
        manyMetrics: false
    },
    bar: {
        metricUnits: false,
        metricPercent: false
    },
    step: {
        metricUnits: false,
        metricPercent: false
    },
    'area-step': {
        metricUnits: false,
        metricPercent: false
    },
    line: {
        metricUnits: false,
        metricPercent: false
    },
    spline: {
        metricUnits: false,
        metricPercent: false
    },
    area: {
        metricUnits: false,
        metricPercent: false
    },
    'area-spline': {
        metricUnits: false,
        metricPercent: false
    },
    heatmap: {
        metricUnits: false,
        metricPercent: false
    }
};
export const ChartTypesOptions = [
    {
        labels: {
            en: 'Pie',
            nl: 'Taart',
            de: 'Kuchen',
            fr: 'Tarte',
            it: 'Torta',
            pl: 'Ciasto'
        },
        value: 'pie'
    },
    {
        labels: {
            en: 'Donut',
            nl: 'Donut',
            de: 'Donut',
            fr: 'Donut',
            it: 'Donut',
            pl: 'Pączek'
        },
        value: 'donut'
    },
    {
        labels: {
            en: 'Funnel',
            nl: 'Trechter',
            de: 'Trichter',
            fr: 'Entonnoir',
            it: 'Imbuto',
            pl: 'Lejek'
        },
        value: 'funnel'
    },
    {
        labels: {
            en: 'Metric',
            nl: 'Metriek',
            de: 'Metrik',
            fr: 'Métrique',
            it: 'Metrica',
            pl: 'Miara'
        },
        value: 'metric'
    },
    {
        labels: {
            en: 'Bar',
            nl: 'Balk',
            de: 'Balken',
            fr: 'Barre',
            it: 'Barra',
            pl: 'Słup'
        },
        value: 'bar'
    },
    {
        labels: {
            en: 'Step',
            nl: 'Stap',
            de: 'Schritt',
            fr: 'Étape',
            it: 'Passo',
            pl: 'Krok'
        },
        value: 'step'
    },
    {
        labels: {
            en: 'Area step',
            nl: 'Gebied stap',
            de: 'Bereich Schritt',
            fr: 'Pas de zone',
            it: 'Passo area',
            pl: 'Krok obszaru'
        },
        value: 'area-step'
    },
    {
        labels: {
            en: 'Line',
            nl: 'Line',
            de: 'Line',
            fr: 'Line',
            it: 'Line',
            pl: 'Linea'
        },
        value: 'line'
    },
    {
        labels: {
            en: 'Spline',
            nl: 'Spline',
            de: 'Spline',
            fr: 'Spline',
            it: 'Spline',
            pl: 'Spline'
        },
        value: 'spline'
    },
    {
        labels: {
            en: 'Area spline',
            nl: 'Gebied Spline',
            de: 'Flächen-Spline',
            fr: 'Courbe de zone',
            it: 'Area spline',
            pl: 'Spline obszarowy'
        },
        value: 'area-spline'
    },
    {
        labels: {
            en: 'Area',
            nl: 'Gebied',
            de: 'Fläche',
            fr: 'Zone',
            it: 'Area',
            pl: 'Obszar'
        },
        value: 'area'
    },
    {
        labels: {
            en: 'Heatmap',
            nl: 'Heatmap',
            de: 'Wärmekarte',
            fr: 'Carte thermique',
            it: 'Mappa di calore',
            pl: 'Mapa cieplna'
        },
        value: 'heatmap'
    }
];
export const TermsOrderByOptions = [
    {
        labels: {
            en: 'Count',
            nl: 'Graaf',
            de: 'Anzahl',
            fr: 'Compter',
            it: 'Contare',
            pl: 'Hrabstwo'
        },
        value: 'count'
    },
    {
        labels: {
            en: 'Alphabetical',
            nl: 'Alfabetisch',
            de: 'Alphabetisch',
            fr: 'Alphabétique',
            it: 'Alfabetica',
            pl: 'Alfabetyczny'
        },
        value: 'field'
    }
];
export const DateHistogramIntervalOptions = [
    {
        labels: {
            en: 'Auto',
            nl: 'Auto',
            de: 'Auto',
            fr: 'Auto',
            it: 'Auto',
            pl: 'Auto'
        },
        value: 'auto'
    },
    {
        labels: {
            en: 'Hour',
            nl: 'Uur',
            de: 'Stunde',
            fr: 'Heure',
            it: 'Ora',
            pl: 'Godzina'
        },
        value: '1h'
    },
    {
        labels: {
            en: 'Day',
            nl: 'Dag',
            de: 'Tag',
            fr: 'Jour',
            it: 'Giorno',
            pl: 'Dzień'
        },
        value: '1d'
    },
    {
        labels: {
            en: 'Week',
            nl: 'Week',
            de: 'Woche',
            fr: 'La semaine',
            it: 'Settimana',
            pl: 'Tydzień'
        },
        value: '1w'
    },
    {
        labels: {
            en: 'Month',
            nl: 'Maand',
            de: 'Monat',
            fr: 'Mois',
            it: 'Mese',
            pl: 'Miesiąc'
        },
        value: '1M'
    },
    {
        labels: {
            en: 'Quarter',
            nl: 'Kwartaal',
            de: 'Quartal',
            fr: 'Trimestre',
            it: 'Trimestre',
            pl: 'Kwartał'
        },
        value: '1q'
    },
    {
        labels: {
            en: 'Year',
            nl: 'Jaar',
            de: 'Jahr',
            fr: 'An',
            it: 'Anno',
            pl: 'Rok'
        },
        value: '1y'
    }
];
function isAllowOption(chartType, option) {
    const settings = AvailableSettingsByChart[chartType];
    if (!settings) {
        return true;
    }
    if (!(option in settings)) {
        return true;
    }
    return !!settings[option];
}
export function getAvailableMetrics(chartType) {
    const settings = AvailableSettingsByChart[chartType];
    if (!settings || !settings.metrics) {
        return MetricsOptions;
    }
    const availableMetrics = MetricsOptions.filter((metric) => {
        return settings.metrics?.includes(metric.value);
    });
    return availableMetrics;
}
export function isAllowManyMetrics(chartType) {
    return isAllowOption(chartType, 'manyMetrics');
}
export function isAllowMetricUnits(chartType) {
    return isAllowOption(chartType, 'metricUnits');
}
export function isAllowMetricPercent(chartType) {
    return isAllowOption(chartType, 'metricPercent');
}
export function isAllowAggregations(chartType) {
    return isAllowOption(chartType, 'allowAggregations');
}
export function isAllowSubAggregations(chartType) {
    return isAllowOption(chartType, 'allowSubAggregations');
}
export function isAllowMetricPercentByMetric(metric) {
    return metric.type === AggregationType.count || metric.type === AggregationType.uniqCount;
}
