import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { datePattern, timePattern } from '@hypercharge/hyper-react-base/lib/utils';
import { Badge, Dropdown } from 'antd';
import Checkbox from 'antd/lib/checkbox';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { FaCodeBranch, FaDatabase, FaEllipsisV, FaFlag, FaPaperclip, FaTrash } from 'react-icons/fa';
import { MdEdit, MdUndo } from 'react-icons/md';
import ItemLink from '../../../../cms/data/components/item/ItemLink/ItemLink';
import useDisplayItemMeta from '../../../../cms/hooks/useDisplayItemMeta';
import { CONTACT_CMS_DEFINITION_ID } from '../../../../crm';
import { useMyContact } from '../../../../crm/components/use-my-contact';
import { TooltipTruncate } from '../../../components/TooltipTruncate';
import { PROCESS_META_HANDLER } from '../../../utils/constants';
import { ACTIVITY_DEFINITION_ID } from '../../constants';
import { ActivityPriority, ActivityStatus, ActivityType } from '../../types';
import styles from './ActivityHeader.module.scss';
import ActivityIcon from './ActivityIcon';
function formatRelativeDate(time, t) {
    const todayEnd = moment().endOf('day');
    const tomorrowEnd = moment().add(1, 'day').endOf('day');
    const yesterdayEnd = moment().subtract(1, 'day').endOf('day');
    const yesterdayStart = moment().subtract(1, 'day').startOf('day');
    const currentYear = moment().startOf('year');
    const date = moment(time);
    if (date > todayEnd && date < tomorrowEnd) {
        return t('ACTIVITY_TOMORROW');
    }
    if (date > yesterdayEnd && date < todayEnd) {
        return t('ACTIVITY_TODAY');
    }
    if (date > yesterdayStart && date < yesterdayEnd) {
        return t('ACTIVITY_YESTERDAY');
    }
    if (date > currentYear) {
        return moment(time).format('DD MMM');
    }
    return moment(time).format(datePattern);
}
const ActivityHeader = ({ activityType, showContact, activity, showRelatedTo, onChangeCheckBox, onClickEdit, onClickUndo, onClickDelete }) => {
    const { t } = useI18n();
    const myContact = useMyContact();
    const { data: displayItemMetaById } = useDisplayItemMeta({
        definitionId: activity?.relatedToDefinitionId
    });
    const relatedToDisplayItemMeta = useMemo(() => {
        if (!showRelatedTo || !activity.relatedToDefinitionId || !activity.relatedToEntityId) {
            return;
        }
        return displayItemMetaById;
    }, [
        activity.relatedToDefinitionId,
        activity.relatedToEntityId,
        displayItemMetaById,
        showRelatedTo
    ]);
    const shortDescription = useMemo(() => {
        let text = '';
        let user = '';
        if (myContact.contactId !== activity.assignedTo) {
            user = (React.createElement(React.Fragment, null,
                React.createElement(ItemLink, { definitionId: CONTACT_CMS_DEFINITION_ID, entityId: activity.assignedTo }),
                ' '));
        }
        switch (activity.typeText) {
            case ActivityType.CALL:
                if (myContact.contactId === activity.assignedTo) {
                    text = t('ACTIVITY_YOU_PAST_CALL_SHORT_DESCRIPTION');
                }
                else {
                    text = t('ACTIVITY_PAST_CALL_SHORT_DESCRIPTION');
                }
                break;
            case ActivityType.TASK:
                if (activity.statusItem === ActivityStatus.Completed) {
                    if (myContact.contactId === activity.assignedTo) {
                        text = t('ACTIVITY_YOU_PAST_TASK_SHORT_DESCRIPTION');
                    }
                    else {
                        text = t('ACTIVITY_PAST_TASK_SHORT_DESCRIPTION');
                    }
                }
                else {
                    if (myContact.contactId === activity.assignedTo) {
                        text = t('ACTIVITY_YOU_UPCOMING_TASK_SHORT_DESCRIPTION');
                    }
                    else {
                        text = t('ACTIVITY_UPCOMING_TASK_SHORT_DESCRIPTION');
                    }
                }
                break;
            case ActivityType.MAIL:
                if (myContact.contactId === activity.assignedTo) {
                    text = t('ACTIVITY_YOU_PAST_MAIL_SHORT_DESCRIPTION');
                }
                else {
                    text = t('ACTIVITY_PAST_MAIL_SHORT_DESCRIPTION');
                }
                break;
            case ActivityType.OTHER:
            default:
                if (myContact.contactId === activity.assignedTo) {
                    text = t('ACTIVITY_YOU_PAST_OTHER_SHORT_DESCRIPTION');
                }
                else {
                    text = t('ACTIVITY_PAST_OTHER_SHORT_DESCRIPTION');
                }
                break;
        }
        return (React.createElement(React.Fragment, null,
            user,
            text,
            activity.contact && showContact && (React.createElement(React.Fragment, null,
                ` ${t('ACTIVITY_WITH')} `,
                React.createElement(ItemLink, { definitionId: CONTACT_CMS_DEFINITION_ID, entityId: activity.contact })))));
    }, [
        activity.assignedTo,
        activity.contact,
        activity.statusItem,
        activity.typeText,
        myContact.contactId,
        showContact,
        t
    ]);
    const filedDueDate = useMemo(() => {
        if (activity.dueDate !== undefined && activity.dueDate !== null) {
            const todayStart = moment().startOf('day');
            const date = moment(activity.dueDate);
            if (date < todayStart) {
                return true;
            }
        }
        return false;
    }, [activity.dueDate]);
    const formattedDueDate = useMemo(() => {
        if (activity.dueDate !== undefined && activity.dueDate !== null) {
            return formatRelativeDate(activity.dueDate, t);
        }
        return t('ACTIVITY_NO_DUE_DATE');
    }, [activity.dueDate, t]);
    const formattedStatusUpdatedAt = useMemo(() => {
        if (activity.statusUpdatedAt !== undefined && activity.statusUpdatedAt !== null) {
            return formatRelativeDate(activity.statusUpdatedAt, t);
        }
        return null;
    }, [activity.statusUpdatedAt, t]);
    const isUpcoming = !!onChangeCheckBox;
    const onClickTitle = useCallback((event) => {
        if (onClickEdit) {
            event.stopPropagation();
            event.preventDefault();
            void onClickEdit();
        }
    }, [onClickEdit]);
    const stopPropagation = useCallback((event) => {
        event.stopPropagation();
    }, []);
    const menu = useMemo(() => ({
        onClick: (info) => {
            switch (info.key) {
                case 'edit':
                    return onClickEdit && void onClickEdit();
                case 'undo':
                    return onClickUndo && void onClickUndo();
                case 'delete':
                    return onClickDelete && void onClickDelete();
            }
        },
        items: [
            {
                key: 'edit',
                icon: React.createElement(MdEdit, { className: styles.iconEditPencil }),
                label: t('COMMON__EDIT')
            },
            ...((!isUpcoming && [
                {
                    key: 'undo',
                    icon: React.createElement(MdUndo, null),
                    label: t('ACTIVITY_UNDO')
                }
            ]) ||
                []),
            ...((onClickDelete && [
                {
                    danger: true,
                    key: 'delete',
                    icon: React.createElement(FaTrash, null),
                    label: t('COMMON__DELETE')
                }
            ]) ||
                [])
        ]
    }), [isUpcoming, onClickDelete, onClickEdit, onClickUndo, t]);
    return (React.createElement("div", { className: `${styles.wrapper} activityHeader` },
        React.createElement("div", { className: "d-flex flex-row" },
            React.createElement("div", null,
                React.createElement(ActivityIcon, { activityType: activityType, className: 'me-2' })),
            React.createElement("div", { className: "flex-grow-1 titleWrapper", onClick: stopPropagation },
                React.createElement("div", { className: "d-flex flex-row" },
                    isUpcoming && (React.createElement("div", { className: "me-2" },
                        React.createElement(Checkbox, { onChange: onChangeCheckBox }))),
                    React.createElement("div", { className: "title flex-grow-1 w-100 me-1 d-flex align-items-center" },
                        React.createElement(ItemLink, { className: "text-ellipsis", definitionId: ACTIVITY_DEFINITION_ID, entityId: activity.entityId, onClick: onClickTitle }),
                        (activity.priority === ActivityPriority.NORMAL ||
                            activity.priority == ActivityPriority.HIGH ||
                            activity.priority === ActivityPriority.URGENT) && (React.createElement(FaFlag, { className: `ms-2 priority-${activity.priority} flex-shrink-0` })),
                        !!activity.files?.length && (React.createElement(Badge, { size: "small", className: "ms-2", count: activity.files.length > 1 ? activity.files.length : 0, offset: [4, 0], showZero: false },
                            React.createElement(FaPaperclip, { className: "files-icon" })))),
                    isUpcoming ? (React.createElement("div", { className: `dueDate ${filedDueDate ? 'error' : ''}` },
                        React.createElement(TooltipTruncate, { className: "d-inline-block mw-100" }, formattedDueDate))) : (React.createElement(React.Fragment, null, formattedStatusUpdatedAt ? (React.createElement("div", { className: "completedAt d-flex align-items-center justify-content-end" },
                        React.createElement(TooltipTruncate, { className: "d-inline-block mw-100", title: moment(activity.statusUpdatedAt).format(`${datePattern} ${timePattern}`) }, formattedStatusUpdatedAt))) : null)),
                    React.createElement(Dropdown, { menu: menu, trigger: ['click'] },
                        React.createElement("div", { role: "button", className: "ms-2 safe-space" },
                            React.createElement(FaEllipsisV, { size: "16px", className: "btn-menu" })))),
                React.createElement("div", { className: "d-flex flex-row justify-content-between" },
                    React.createElement("div", { className: "shortDescription text-ellipsis" }, shortDescription),
                    showRelatedTo &&
                        relatedToDisplayItemMeta &&
                        activity.relatedToDefinitionId &&
                        activity.relatedToEntityId && (React.createElement("div", { className: "related-to ps-1 text-ellipsis d-flex align-items-center" },
                        relatedToDisplayItemMeta.handler === PROCESS_META_HANDLER ? (React.createElement(FaCodeBranch, { className: "me-2" })) : (React.createElement(FaDatabase, { className: "me-2" })),
                        React.createElement(ItemLink, { className: "flex-truncate", definitionId: activity.relatedToDefinitionId, entityId: activity.relatedToEntityId }))))))));
};
export default ActivityHeader;
