import { QueryClient } from 'react-query';
import { isAxiosError } from './request';
/**
 * for good you need to use hook useQueryClient
 * but eslint swears that utils should be used inside functional components,
 * so the queryClient was moved to a separate module and acts as a singleton
 */
const defaultRetry = (failureCount, error) => {
    if (failureCount === 3) {
        return false;
    }
    if (isAxiosError(error) && (error.response?.status === 404 || error.response?.status === 500)) {
        return false;
    }
    return true;
};
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: defaultRetry,
            retryDelay: 1000
        }
    }
});
export const oneHourInMs = 1000 * 60 * 60;
export const oneMinuteInMs = 1000 * 60;
export const fiveMinuteInMs = 1000 * 60 * 5;
export default queryClient;
