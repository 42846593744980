import { LoadingOutlined } from '@ant-design/icons';
import { transparentize } from '@hypercharge/hyper-react-base/lib/styling';
import { Spin } from 'antd';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { getKanbanColumnCountQueryKey } from '~app/cms/data/components/items/utils';
import { TooltipTruncate } from '~app/common/components/TooltipTruncate';
import { BROWSE_PATH } from '~app/common/utils/constants';
import queryClient from '~app/common/utils/react-query-client';
import { ViewTypes, getHashForUrl } from '~app/views';
import { useView } from '~app/views/components/ViewContext';
import styles from './KanbanListHeader.module.scss';
export const invalidateColumnsCountQuery = async ({ columnId, definitionId }) => queryClient.invalidateQueries({
    predicate: (query) => {
        const foundColumnKey = columnId
            ? query.queryKey.includes(getKanbanColumnCountQueryKey(columnId))
            : query.queryHash.includes(getKanbanColumnCountQueryKey());
        return foundColumnKey && query.queryKey.includes(definitionId);
    }
});
const KanbanListHeader = ({ baseUrl, columnCountIsFetching, columnId, filterRequestOpenInNewTab, hidden, onToggleList, theme, title, totalCountColumn }) => {
    const { metrics } = useView();
    const _onToggleList = useCallback(() => onToggleList(columnId), [columnId, onToggleList]);
    return (React.createElement("div", { className: cn(styles.list_header, {
            [styles.list_header__hidden]: hidden,
            'px-2': !hidden,
            'py-2 flex-column': hidden
        }, 'KanbanListHeaderOuter', 'd-flex flex-basis-100 mh-100 align-items-center justify-content-between gap-1 position-relative overflow-hidden w-100'), onClick: _onToggleList, role: "button" },
        React.createElement("div", { className: cn(styles.list_toggleButton, 'd-flex align-items-center overflow-hidden gap-2 p-0', { 'ps-1': !hidden }, 'ToggleButton'), style: {
                color: theme?.secondary
            } },
            React.createElement(MdChevronLeft, { size: 20, className: styles.list_icon, color: theme?.secondary || 'inherit' }),
            React.createElement("div", { className: cn(styles.list_title, 'mh-100 flex-truncate text-start') },
                React.createElement(TooltipTruncate, null, title))),
        React.createElement(Link, { to: {
                pathname: baseUrl + BROWSE_PATH,
                hash: getHashForUrl({
                    newFilters: filterRequestOpenInNewTab,
                    newMetrics: metrics,
                    viewType: ViewTypes.table,
                    queryParams: {}
                })
            }, target: "_blank", role: "button", className: cn(styles.list_counter, { withTheme: !!theme }, 'd-flex align-items-center justify-content-center px-2'), style: {
                backgroundColor: theme?.secondary,
                color: theme?.primary,
                ...(theme?.secondary && {
                    ['--hoverBackgroundColor']: transparentize(theme?.secondary, 0.4)
                })
            }, onClick: (event) => event.stopPropagation() },
            React.createElement("span", null, columnCountIsFetching ? (React.createElement("span", { className: "position-relative" },
                React.createElement(Spin, { className: cn(styles.countLoader, `position-absolute`), indicator: React.createElement(LoadingOutlined, { spin: true }) }))) : (totalCountColumn)))));
};
export default KanbanListHeader;
