import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { media } from '@hypercharge/hyper-react-base/lib/styling';
import React, { useCallback, useEffect } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import styled from 'styled-components';
import { CMS_PATH } from '../../utils/constants';
const Content = styled.div `
  padding: 4rem 0.5rem 0.5rem;
  position: relative;
  height: ${(props) => props.height};
  overflow-y: auto;
`;
const InfoContainer = styled.div `
  padding: 0 1rem 1rem;

  ${media.xl} {
    width: 850px;
    margin-left: auto;
    margin-right: auto;
  }
`;
const ImageContainer = styled.div `
  height: 180px;
  position: relative;
`;
const Image = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: contain;
  background: url(/assets/images/database.png) no-repeat center;
`;
const Title = styled.div `
  font-weight: 600;
  font-size: 1.1rem;
  padding: 1rem 0;
`;
const Highlights = styled(Title) `
  padding-top: 2rem;
`;
const StyledCheck = styled(IoMdCheckmark) `
  color: var(--successColor-7);
  font-size: 0.8rem;
  margin-right: 0.5rem;
`;
const LeftHighlights = styled.div `
  ${media.sm} {
    padding-right: 1rem;
  }
`;
const highlightsArray = [
    'CMS_PAGE_UNLIMITED_CONTENT_TYPES',
    'CMS_PAGE_MULTIPLE_PROPERTY_TYPES',
    'CMS_PAGE_PERMISSIONS',
    'CMS_PAGE_CROSS_REFERENCE',
    'CMS_PAGE_CALCULATED_PROPERTIES',
    'CMS_PAGE_PROPERTY_VALIDATIONS',
    'CMS_PAGE_SEARCH',
    'CMS_PAGE_MULTI_LANGUAGE'
];
const CmsPage = ({ contentHeight, setLinks, setCurrentBreadcrumbText }) => {
    const { t } = useI18n();
    useEffect(() => {
        setLinks([{ text: t('CMS_NAV_LINK'), to: CMS_PATH }]);
        setCurrentBreadcrumbText('');
    }, [setCurrentBreadcrumbText, setLinks, t]);
    const Wrapper = useCallback(({ children }) => contentHeight ? React.createElement(Content, { height: contentHeight }, children) : children, [contentHeight]);
    return (React.createElement(Wrapper, null,
        React.createElement(InfoContainer, null,
            React.createElement("div", { className: "cp-c-row cp-c-align-spacebetween-center" },
                React.createElement("div", { className: "cp-i-100 cp-i-md-70" },
                    React.createElement(Title, null, t('CMS_PAGE_TITLE')),
                    React.createElement("div", null, t('CMS_PAGE_TEXT'))),
                React.createElement(ImageContainer, { className: "cp-hide cp-md-show-block cp-i-md-30" },
                    React.createElement(Image, null))),
            React.createElement(Highlights, null, t('CMS_HIGHLIGHTS')),
            React.createElement("div", { className: "cp-c-row cp-c-align-start-start cp-c-wrap " },
                React.createElement(LeftHighlights, { className: "cp-i-100 cp-i-sm-50" }, highlightsArray.slice(0, 4).map((highlight) => (React.createElement("div", { key: highlight, className: "cp-c-row cp-c-align-start-center" },
                    React.createElement(StyledCheck, { className: "cp-i-5" }),
                    React.createElement("div", { className: "cp-i-95" }, t(highlight)))))),
                React.createElement("div", { className: "cp-i-100 cp-i-sm-50" }, highlightsArray.slice(4, 8).map((highlight) => (React.createElement("div", { key: highlight, className: "cp-c-row cp-c-align-start-center" },
                    React.createElement(StyledCheck, { className: "cp-i-5" }),
                    React.createElement("div", { className: "cp-i-95" }, t(highlight))))))))));
};
export default CmsPage;
