import { InfoCircleOutlined } from '@ant-design/icons';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Tooltip } from 'antd';
import React from 'react';
import styles from './RelatedProcessesSelector.module.scss';
export const ItemProcessDisabled = ({ onShowDisabledReason, title }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: "d-flex align-items-center" },
        title,
        React.createElement(Tooltip, { overlayClassName: styles.disabledProcessTooltip, placement: "right", title: React.createElement(React.Fragment, null,
                React.createElement("span", null, `${t('QUICKSTART_PROCESS_CANT_START')}`),
                React.createElement(Button, { className: "ms-1 p-0 h-auto", type: "link", onClick: onShowDisabledReason }, t('QUICKSTART_PROCESS_VIEW_CONDITIONS'))) },
            React.createElement(InfoCircleOutlined, { style: { marginLeft: 5 }, className: "pe-auto cursor-pointer" }))));
};
