import cn from 'classnames';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { BROWSE_PATH, VIEW_PATH } from '../../../../../../common/utils/constants';
import { isCmsDefinition, isWorkflowDefinition } from '../../../../../../common/utils/url';
import { initialFilterRequest } from '../../../../../../views/components/ViewContext';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID } from '../../../../../../workflows';
import { TASK_PROCESS_LINK_PROPERTY_ID } from '../../../../../../workflows/common/utils/constants';
import useDisplayItemMeta from '../../../../../hooks/useDisplayItemMeta';
import { usePropertyEntityItemValue } from '../../../../../hooks/usePropertyEntityItemValue';
import styles from './KanbanCard.module.scss';
import { CardFields, CardHeader } from './components';
const KanbanCard = ({ baseUrl, card, cards, definitionId, fields, provided: { innerRef, draggableProps, dragHandleProps }, viewId, getCustomRouteHandleCardClick, columnFilterRequest, totalCountColumn }) => {
    const history = useHistory();
    const { getPropertyValue } = usePropertyEntityItemValue();
    const { data: displayItemMeta } = useDisplayItemMeta({ definitionId });
    const isProcess = isWorkflowDefinition(displayItemMeta);
    const handleCardClick = useCallback(async (e) => {
        let to = '';
        const customTo = await getCustomRouteHandleCardClick?.(card);
        if (customTo) {
            to = customTo;
        }
        else if (isCmsDefinition(displayItemMeta)) {
            to = `${baseUrl}${BROWSE_PATH}/${card.entityId}`;
        }
        else if (isProcess) {
            const processRunId = card.entityId;
            to = viewId
                ? `${baseUrl}${VIEW_PATH}/${viewId}/${processRunId}`
                : `${baseUrl}${BROWSE_PATH}/${processRunId}`;
            if (definitionId === ALL_PROCESSES_DEFINITION_ID) {
                const updatedBaseUrl = baseUrl.replace(ALL_PROCESSES_DEFINITION_ID, card.definitionId || '');
                to = viewId
                    ? `${updatedBaseUrl}${VIEW_PATH}/${viewId}/${processRunId}`
                    : `${updatedBaseUrl}${BROWSE_PATH}/${processRunId}`;
            }
            else if (definitionId === ALL_TASKS_DEFINITION_ID && card.definitionId) {
                const taskLink = (card[TASK_PROCESS_LINK_PROPERTY_ID] ||
                    (await getPropertyValue(card.definitionId, card.entityId, TASK_PROCESS_LINK_PROPERTY_ID)));
                if (taskLink) {
                    to = taskLink.link;
                }
            }
        }
        if (to) {
            if (e.metaKey || e.ctrlKey) {
                window.open(to, '_blank')?.focus();
            }
            else {
                history.push(to, {
                    definitionId,
                    data: {
                        entityIds: cards.map((r) => r.taskProcessRunId ?? r.entityId),
                        definitionIds: cards.map((r) => r.definitionId || definitionId),
                        links: cards.map((r) => r.taskProcessLink?.link),
                        totalCount: totalCountColumn ?? cards.length
                    },
                    filterRequest: columnFilterRequest || initialFilterRequest,
                    isTaskDefinition: definitionId === ALL_TASKS_DEFINITION_ID
                });
            }
        }
    }, [
        baseUrl,
        card,
        cards,
        columnFilterRequest,
        definitionId,
        displayItemMeta,
        getCustomRouteHandleCardClick,
        getPropertyValue,
        history,
        isProcess,
        totalCountColumn,
        viewId
    ]);
    return (React.createElement("div", { ...draggableProps, ...dragHandleProps, className: cn(styles.card, 'd-flex flex-column align-items-stretch cursor-pointer KanbanCard', {
            [styles.card_disabledDrag]: !dragHandleProps
        }), onClick: handleCardClick, ref: innerRef },
        React.createElement("div", { className: "position-relative d-flex flex-column gap-2 p-2" },
            React.createElement("div", { className: cn(styles.card_header, 'd-flex justify-content-start align-items-center fw-500 text-break') }, card.title || card.entityId),
            React.createElement("div", { className: cn(styles.card_section, 'd-flex flex-column align-items-stretch gap-2') },
                React.createElement(CardHeader, { card: card, isProcess: isProcess }),
                React.createElement(CardFields, { card: card, fields: fields })))));
};
export default KanbanCard;
