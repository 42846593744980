import { t } from '@hypercharge/hyper-react-base/lib/i18n';
import produce from 'immer';
import { find, flatMap, get, head, isEmpty } from 'lodash';
import { CONFIGURATION_PROPERTY_ID, ORIGINAL_DATA_PROPERTY_ID, decimalSeparatorByNumberFormat, thousandSeparatorByNumberFormat, unsupportedPropertyTypes } from './constants';
import CsvPropertyConfig from './csv/CsvPropertyConfig';
import { fillDefaultValue as csvFillDefaultValue } from './csv/utils';
import JsonPropertyConfig from './json/JsonPropertyConfig';
import { fillDefaultValue as jsonFillDefaultValue } from './json/utils';
import TxtPropertyConfig from './txt/TxtPropertyConfig';
import { fillDefaultValue as txtFillDefaultValue } from './txt/utils';
import { ImportModes } from './types';
import XmlPropertyConfig from './xml/XmlPropertyConfig';
import { fillDefaultValue as xmlFillDefaultValue } from './xml/utils';
const propertyConfig = {
    csv: CsvPropertyConfig,
    xml: XmlPropertyConfig,
    json: JsonPropertyConfig,
    txt: TxtPropertyConfig
};
const fillDefaultValueMapping = {
    csv: csvFillDefaultValue,
    xml: xmlFillDefaultValue,
    json: jsonFillDefaultValue,
    txt: txtFillDefaultValue
};
export const getFileType = (importItem) => {
    const fileType = get(importItem, `${CONFIGURATION_PROPERTY_ID}.type`);
    if (!fileType) {
        const file = head(importItem[ORIGINAL_DATA_PROPERTY_ID]);
        return file?.extension || '';
    }
    return fileType;
};
export const getPropertyConfig = (importItem) => {
    if (!importItem) {
        return;
    }
    const fileType = getFileType(importItem);
    return propertyConfig[fileType];
};
export const fillDefaultValue = (importItem, definition) => {
    const fileType = getFileType(importItem);
    const defaultValuesMapping = fillDefaultValueMapping[fileType];
    if (defaultValuesMapping) {
        const newSettings = defaultValuesMapping(importItem.configuration, definition);
        return { ...importItem, configuration: newSettings };
    }
    return importItem;
};
export const pruneSettings = (importItem, definition) => {
    const settings = importItem.configuration;
    const prunedImportSettings = produce(settings, (draft) => {
        flatMap(definition.data, 'values').forEach(({ propertyId, canEdit, type }) => {
            if (settings.propertyMappings[propertyId]) {
                if (type === 'number') {
                    // TODO: remove numberFormat and replace it with thousandSeparator and decimalSeparator
                    const propertyMapping = draft.propertyMappings[propertyId];
                    const numberFormat = propertyMapping.numberFormat;
                    if (numberFormat) {
                        propertyMapping.thousandSeparator = thousandSeparatorByNumberFormat[numberFormat];
                        propertyMapping.decimalSeparator = decimalSeparatorByNumberFormat[numberFormat];
                        delete propertyMapping.numberFormat;
                    }
                }
                const fileType = getFileType(importItem);
                if (fileType == 'csv') {
                    if ((!canEdit && draft.uniquePropertyId !== propertyId) ||
                        unsupportedPropertyTypes.includes(type) ||
                        settings.propertyMappings[propertyId].csvColumnIndex == null) {
                        delete draft.propertyMappings[propertyId];
                    }
                }
                else if (fileType == 'xml') {
                    if ((!canEdit && draft.uniquePropertyId !== propertyId) ||
                        unsupportedPropertyTypes.includes(type) ||
                        isEmpty(settings.propertyMappings[propertyId].xpath)) {
                        delete draft.propertyMappings[propertyId];
                    }
                }
                else if (fileType == 'json') {
                    if ((!canEdit && draft.uniquePropertyId !== propertyId) ||
                        unsupportedPropertyTypes.includes(type) ||
                        isEmpty(settings.propertyMappings[propertyId].jsonPath)) {
                        delete draft.propertyMappings[propertyId];
                    }
                }
                else if (fileType == 'txt') {
                    if ((!canEdit && draft.uniquePropertyId !== propertyId) ||
                        unsupportedPropertyTypes.includes(type) ||
                        // @ts-expect-error
                        settings.propertyMappings[propertyId].txtColumnIndex == null) {
                        delete draft.propertyMappings[propertyId];
                    }
                }
            }
        });
    });
    return prunedImportSettings;
};
export const getEntityTitle = (definitionId, entities) => {
    const entity = find(entities, { definitionId });
    return get(entity, 'title', definitionId);
};
export const validateConfiguration = (importItem, definition) => {
    const errors = {
        propertyMappings: {}
    };
    if (definition && importItem) {
        flatMap(definition.data, 'values').forEach(({ propertyId, meta }) => {
            if (get(meta, 'validations.required') &&
                importItem.configuration.importMode !== ImportModes.UPDATE_ONLY) {
                const fileType = getFileType(importItem);
                const defaultPropertyConfig = {};
                switch (fileType) {
                    case 'csv':
                        defaultPropertyConfig.csvColumnIndex = null;
                        break;
                    case 'xml':
                        defaultPropertyConfig.xpath = null;
                        break;
                    case 'json':
                        defaultPropertyConfig.jsonPath = null;
                        break;
                    case 'txt':
                        defaultPropertyConfig.txtColumnIndex = null;
                        break;
                }
                const propertyConfig = get(importItem, `configuration.propertyMappings.${propertyId}`, defaultPropertyConfig);
                const entries = Object.entries(propertyConfig);
                for (const [fieldName, fieldValue] of entries) {
                    if (fieldValue == null) {
                        if (!errors.propertyMappings[propertyId]) {
                            errors.propertyMappings[propertyId] = {};
                        }
                        errors.propertyMappings[propertyId][fieldName] = t('VALIDATIONS__REQUIRED');
                    }
                }
            }
        });
    }
    if (isEmpty(importItem.configuration.pathToDataArray)) {
        errors.pathToDataArray = t('VALIDATIONS__REQUIRED');
    }
    if (!isEmpty(errors.propertyMappings)) {
        return { configuration: errors };
    }
    return;
};
export const getNumberFormatName = (numberFormat) => {
    if (numberFormat.thousandSeparator === ' ') {
        return numberFormat.decimalSeparator === '.' ? 'space-dot' : 'space-comma';
    }
    else {
        return numberFormat.thousandSeparator === ',' ? 'comma-dot' : 'dot-comma';
    }
};
