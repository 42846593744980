import { PUBLIC_IMAGES_SERVICE, TENANT_ID } from 'config';
import { filter, has, isEmpty, reduce } from 'lodash';
import { isTextMeta } from '../../cms/data/types';
import { TAB_STATE_LOCAL_STORAGE } from './constants';
if (window.Element && !Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        // @ts-expect-error
        const matches = (this.document || this.ownerDocument).querySelectorAll(s);
        let i;
        let el = this;
        do {
            i = matches.length;
            while (--i >= 0 && matches.item(i) !== el) { }
            // @ts-expect-error
        } while (i < 0 && (el = el.parentElement));
        return el;
    };
}
export const getScrollableAreaOrBody = (node) => {
    const closest = node && node.closest('.scrollable-area');
    return ((closest !== node && closest) || document.body);
};
export const getParentElementOrBody = (node, renderInParent) => {
    return renderInParent && node.parentElement ? node.parentElement : document.body;
};
const getTabStateFromLS = () => {
    const stateFromLS = localStorage.getItem(TAB_STATE_LOCAL_STORAGE);
    return JSON.parse(stateFromLS || '{}');
};
export const saveTabState = (handler, definitionId, activeTab) => {
    const parsedState = getTabStateFromLS();
    let newTabState = {};
    if (isEmpty(parsedState)) {
        newTabState = {
            [handler]: { [definitionId]: activeTab }
        };
    }
    else {
        newTabState = {
            ...(parsedState || {}),
            [handler]: {
                ...(parsedState?.[handler] || {}),
                [definitionId]: activeTab
            }
        };
    }
    localStorage.setItem(TAB_STATE_LOCAL_STORAGE, JSON.stringify(newTabState));
};
export const getTabState = (handler, definitionId) => {
    const parsedState = getTabStateFromLS();
    if (isEmpty(parsedState)) {
        return;
    }
    return parsedState?.[handler]?.[definitionId];
};
export const getPropsForActionsButtons = (props) => props;
export const getBadgeCount = (count, maxLimit = 9) => {
    if (!count) {
        return '0';
    }
    return count <= maxLimit ? count.toString() : `${maxLimit}+`;
};
export const getDataForSave = (ids, singleMode = false) => {
    let newValue = null;
    if (singleMode) {
        newValue = ids[0] ? ids[0] : null;
    }
    else {
        newValue = Array.from(new Set(ids));
    }
    return newValue;
};
export const getImageUrl = (imageId, sizeAttrs = {}, webpAttrs = {}, options = {}) => {
    if (!PUBLIC_IMAGES_SERVICE) {
        throw new Error('Images service url is not defined');
    }
    const resizeConfig = {
        fit: 'inside',
        withoutEnlargement: true,
        ...sizeAttrs
    };
    const webpConfig = {
        alphaQuality: 100,
        effort: 5,
        quality: 85,
        ...webpAttrs
    };
    const request = {
        key: `${TENANT_ID}/${imageId}`,
        edits: {
            resize: resizeConfig,
            webp: webpConfig,
            ...options
        },
        outputFormat: 'webp'
    };
    return `${PUBLIC_IMAGES_SERVICE}/${btoa(JSON.stringify(request))}`;
};
export const getOrderedHighlightedProperties = (displaySections, availableHighlightedPropertyIds) => {
    if (!availableHighlightedPropertyIds.length || !displaySections) {
        return [];
    }
    const allValues = [];
    for (const displayDataItem of displaySections) {
        for (const value of displayDataItem.values) {
            if (!getPropertiesForTranslation(displaySections).includes(value.propertyId)) {
                allValues.push(value);
            }
        }
    }
    const highlightedProperties = [];
    for (const availablePropertyId of availableHighlightedPropertyIds) {
        const found = allValues.find((value) => value.propertyId === availablePropertyId);
        if (found) {
            highlightedProperties.push(found);
        }
    }
    return highlightedProperties;
};
export const getPropertiesForTranslation = (data) => reduce(data, (properties, section) => [
    ...properties,
    ...reduce(filter(section.values, (value) => has(value, ['meta', 'translations'])), (acc, value) => [
        ...acc,
        ...(Object.values(isTextMeta(value.meta) && value.meta.translations ? value.meta.translations : {}) || [])
    ], [])
], []);
