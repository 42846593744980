import { DownOutlined } from '@ant-design/icons';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Dropdown } from 'antd';
import { omit } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { IoMdClose } from 'react-icons/io';
import { AntInput } from '../../../../../../../../common/components/AntInput';
import { ComputedInput } from '../../../../../../../../common/components/ComputedExpressionEditor';
import styles from './MappingsField.module.scss';
const MappingsField = ({ className, fields, value, onChange, propertiesTree, disabled }) => {
    const { t } = useI18n();
    const selectedFields = useMemo(() => {
        if (!value) {
            return [];
        }
        const result = [];
        for (const [fieldId, fieldValue] of Object.entries(value)) {
            const field = fields.find((field) => field.id === fieldId);
            if (field) {
                result.push({
                    label: field.displayName,
                    value: fieldValue,
                    id: field.id
                });
            }
        }
        return result;
    }, [fields, value]);
    const menu = useMemo(() => {
        const restFields = fields.filter((field) => {
            return !(field.id in (value ?? {}));
        });
        return {
            items: restFields.map((field, index) => ({
                key: index,
                onClick: () => {
                    onChange?.({
                        ...(value ?? {}),
                        [field.id]: null
                    });
                },
                label: React.createElement("span", null, field.displayName)
            }))
        };
    }, [fields, value, onChange]);
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''}` },
        selectedFields.length ? (React.createElement("div", null, selectedFields.map((field) => (React.createElement(Fragment, { key: field.id },
            React.createElement("div", { className: "input-label mb-1" }, field.label),
            React.createElement("div", { className: "d-flex align-items-center mb-3" },
                React.createElement(ComputedInput, { propertiesTree: propertiesTree, component: AntInput, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} `, onChange: (newValue) => {
                        if (Array.isArray(newValue)) {
                            return;
                        }
                        onChange?.({
                            ...(value ?? {}),
                            [field.id]: newValue ?? null
                        });
                    }, value: field.value, disabled: disabled }),
                React.createElement(Button, { type: "text", className: "remove-btn-outer rounded d-flex align-items-center justify-content-center p-0", onClick: () => {
                        onChange?.(omit(value ?? {}, [field.id]));
                    } },
                    React.createElement(IoMdClose, { className: "remove-btn" })))))))) : null,
        menu.items?.length ? (React.createElement(Dropdown, { className: "mb-3", arrow: false, menu: menu, trigger: ['click'] },
            React.createElement(Button, null,
                React.createElement("span", null, `+ ${t('ADD')}`),
                React.createElement(DownOutlined, null)))) : null));
};
export default MappingsField;
