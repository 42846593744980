import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Checkbox, Select as SelectBase } from 'antd';
import { isArray } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { MdExpandMore } from 'react-icons/md';
import { ClearIcon } from '../../../icons';
import { Tooltip } from '../Tooltip';
import LoadingIndicator from '../loading-indicator';
import { SearchInput } from './SearchInput';
import styles from './Select.module.scss';
export const Select = ({ className = '', prefixComponent: PrefixComponent, prefixIcon: PrefixIcon, mode, checkmarks, onSearchDropdown, totalCount, allowClear, popupClassName, additionalDropdownComponent: AdditionalDropdownComponent, notFoundContent, ...props }) => {
    const { t } = useI18n();
    const selectOuterRef = useRef(null);
    useEffect(() => {
        if (selectOuterRef.current) {
            const input = selectOuterRef.current.querySelector('.ant-select-selection-search-input');
            if (input && props.autoFocus && !props.loading) {
                input.focus({ preventScroll: true });
            }
        }
    }, [props.autoFocus, props.loading]);
    const allowTopResultMessage = useMemo(() => !!props?.options?.length && !!totalCount && props.options.length < totalCount, [props?.options?.length, totalCount]);
    const defaultMaxTagPlaceholder = useCallback((omittedValues) => (React.createElement(Tooltip, { overlayClassName: "maxTagPlaceholder", title: React.createElement("ul", { className: "ps-3 mb-0", onMouseDown: (event) => event.stopPropagation() }, omittedValues.map((value) => (React.createElement("li", { key: value.key }, value.label)))) },
        React.createElement("span", { className: "me-1" }, "+"),
        React.createElement("span", null, omittedValues.length))), []);
    const computedPopupClassName = useMemo(() => `${popupClassName ? popupClassName : ''} ${checkmarks ? 'checkmarks' : ''} ${onSearchDropdown ? 'searchInDropdown' : ' '} ${allowTopResultMessage ? 'topResultsInDropdown' : ' '}`, [allowTopResultMessage, checkmarks, onSearchDropdown, popupClassName]);
    return (React.createElement("div", { ref: selectOuterRef, className: `${styles.outer} ${mode === 'multiple' ? 'list-mode' : ''} ${PrefixComponent ? 'WithPrefixComponent' : ''} SelectOuter position-relative d-flex align-items-stretch ${className}` },
        PrefixComponent && (React.createElement("div", { className: "SelectPrefixComponentOuter d-flex align-items-center justify-content-center" }, PrefixComponent)),
        PrefixIcon && React.createElement(PrefixIcon, { size: 14, className: "prefix-icon position-absolute ms-2 h-100" }),
        React.createElement(SelectBase, { ...(notFoundContent ? { notFoundContent } : {}), popupClassName: computedPopupClassName, className: `${PrefixIcon ? 'with-prefix-icon' : ''} w-100`, mode: mode, suffixIcon: React.createElement(MdExpandMore, { color: "var(--neutralColor-6)" }), ...props, autoFocus: false, searchValue: onSearchDropdown ? undefined : props.searchValue, showSearch: onSearchDropdown ? false : props.showSearch, allowClear: !!allowClear && { clearIcon: React.createElement(ClearIcon, null) }, maxTagPlaceholder: props.maxTagPlaceholder || defaultMaxTagPlaceholder, onDropdownVisibleChange: onSearchDropdown
                ? (...args) => {
                    onSearchDropdown('');
                    props?.onDropdownVisibleChange?.(...args);
                }
                : props.onDropdownVisibleChange, dropdownRender: (menu) => (React.createElement(React.Fragment, null,
                allowTopResultMessage && (React.createElement("div", { className: "wrp-top-results" },
                    React.createElement("div", { className: "px-3 py-2 showing-top-results" }, t('SHOWING_TOP_RESULTS', { smart_count: totalCount })))),
                React.createElement(SearchInput, { onSearch: onSearchDropdown, placeholder: props?.placeholder?.toString(), searchValue: props?.searchValue }),
                props.loading && React.createElement(LoadingIndicator, null),
                menu,
                AdditionalDropdownComponent && React.createElement(AdditionalDropdownComponent, null))), options: props.options?.map((option) => ({
                ...option,
                ...(checkmarks && {
                    label: (React.createElement(React.Fragment, null,
                        React.createElement(Checkbox, { tabIndex: -1, className: "me-2 Checkbox", checked: isArray(props.value)
                                ? props.value.includes(option.value)
                                : props.value === option.value }),
                        React.createElement("span", null, option.label)))
                })
            })) })));
};
