import cn from 'classnames';
import React from 'react';
import { PropertyLabel } from '../../../../../../../common/components/PropertyLabel';
import { useDisplayTenant } from '../../../../../../../tenant/useDisplayTenant';
import { getDisplayComponentForType } from '../../../../item-property/utils';
import styles from '../KanbanCard.module.scss';
export const CardFields = ({ fields, card }) => {
    const { tenantTheme: { hidePropertyIcon } } = useDisplayTenant();
    if (!fields.length) {
        return null;
    }
    return (React.createElement("div", null, fields
        .filter(({ id }) => card[id] !== undefined && card[id] !== null)
        .map(({ type, id, label, labelDescription, meta }) => {
        const Component = type ? getDisplayComponentForType(type) : null;
        return (React.createElement("div", { className: `${styles.card_fieldWrapper} d-flex flex-column align-items-stretch`, key: id },
            React.createElement("div", { className: "mb-1 d-flex align-items-start justify-content-start" },
                React.createElement(PropertyLabel, { label: label, labelClassName: cn(styles.card_fieldLabelText, 'fw-600'), labelDescription: labelDescription })),
            React.createElement("div", { className: `${styles.card_fieldGroup} d-flex align-items-start` }, Component ? (React.createElement(Component, { className: `kanbanField ${hidePropertyIcon ? 'ps-0' : ''}`, value: card[id], meta: meta })) : (React.createElement("div", { className: styles.card_fieldValue }, card[id] ?? '—')))));
    })));
};
