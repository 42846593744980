/**
 * @deprecated use useTenant instead
 */
const getTenantState = (s) => s.tenant;
/**
 * @deprecated use useTenant instead
 */
export const getTenant = (s) => getTenantState(s).tenant;
/**
 * @deprecated use useTenant instead
 */
export const getTenantStatus = (s) => {
    const tenantState = getTenantState(s);
    return {
        isAvailable: !!tenantState.tenant,
        isPending: tenantState.tenantStatus.pending,
        isFailed: tenantState.tenantStatus.failed
    };
};
