import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Skeleton } from 'antd';
import cn from 'classnames';
import { isEqual, isString, uniqWith } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { fiveMinuteInMs, oneMinuteInMs } from '~app/common/utils/react-query-client';
import { useView } from '~app/views/components/ViewContext';
import { getFlattenedDisplayDataList } from '../../../cms';
import { useEntityDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import { useAggregation } from '../../../cms/hooks/useAggregation';
import { isMetricAggregation } from '../../../cms/hooks/useAggregation/types';
import { TooltipTruncate } from '../../../common/components/TooltipTruncate';
import { isKanbanView } from '../../types';
import { GroupedMetric } from './GroupedMetric';
import { prepareMetricGroupQuery } from './MetricsDisplayContainer';
const MetricItemDisplay = ({ metric: metricItem, definitionId, onChangeMetric, additionalMetricFilter, onChangeAggregation }) => {
    const { language, t } = useI18n();
    const { id, field, metric, units, inPercents, rowGrouping, titles, query: metricQuery, selected, activeGroupingElements } = metricItem;
    const { viewType } = useView();
    const { displayData, displayDataStatus } = useEntityDisplayData(definitionId);
    const isPropertyAvailable = useMemo(() => !displayData
        ? false
        : !!getFlattenedDisplayDataList(displayData).find((property) => {
            const metricPropertyId = field.split('.')[0];
            return property.propertyId === metricPropertyId;
        }), [displayData, field]);
    const aggregations = useMemo(() => {
        let filter;
        if (metricQuery) {
            filter = {
                query: metricQuery
            };
        }
        return [
            {
                id,
                field,
                units,
                inPercents,
                // @ts-expect-error
                type: metric.toUpperCase(),
                filter
            }
        ];
    }, [field, id, inPercents, metric, metricQuery, units]);
    const aggregationMetricFilter = useMemo(() => {
        if (selected || !rowGrouping) {
            return additionalMetricFilter;
        }
        const filtersToApply = [...additionalMetricFilter.query.filters];
        const extraMetricGroupQuery = prepareMetricGroupQuery(rowGrouping, activeGroupingElements);
        extraMetricGroupQuery && filtersToApply.push(extraMetricGroupQuery);
        return {
            ...additionalMetricFilter,
            query: {
                ...additionalMetricFilter.query,
                filters: uniqWith(filtersToApply, isEqual)
            }
        };
    }, [activeGroupingElements, additionalMetricFilter, rowGrouping, selected]);
    const { isLoading, data: aggregationData } = useAggregation({
        definitionId: definitionId,
        aggregations: aggregations,
        globalFilter: aggregationMetricFilter,
        enabled: isPropertyAvailable,
        staleTime: isKanbanView(viewType) ? fiveMinuteInMs : oneMinuteInMs
    });
    const { aggregations: aggsById, hits } = aggregationData || {};
    const metricTitle = useMemo(() => (isString(titles) ? t(titles) : getTranslation(titles, language)), [language, t, titles]);
    const clickable = useMemo(() => !!metricQuery?.filters?.length || !!rowGrouping, [metricQuery?.filters?.length, rowGrouping]);
    const aggregationValue = useMemo(() => {
        if (!activeGroupingElements && rowGrouping) {
            return 0;
        }
        let aggregationValueString = '--';
        const aggregation = aggsById?.[id];
        if (aggregation && isMetricAggregation(aggregation)) {
            let value = 0;
            if (inPercents) {
                const total = Number(hits?.total && typeof hits.total !== 'number' && hits.total.value != undefined
                    ? hits.total.value
                    : aggregation.value);
                if (total > 0) {
                    value = (aggregation.value * 100) / total;
                }
            }
            else {
                value = Number(aggregation.value);
            }
            aggregationValueString = value.toLocaleString(language, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
                useGrouping: true
            });
        }
        let prefix;
        if (inPercents) {
            prefix = '%';
        }
        else {
            prefix = units ? getTranslation(units, language) : '';
        }
        return `${aggregationValueString} ${prefix}`;
    }, [aggsById, hits?.total, id, inPercents, language, units]);
    const handleChangeMetric = useCallback(() => {
        if (!clickable) {
            return;
        }
        void onChangeMetric({
            ...metricItem,
            selected: !metricItem.selected
        });
    }, [clickable, metricItem, onChangeMetric]);
    const isMetricClickable = isPropertyAvailable && clickable;
    return (React.createElement("div", { className: cn('d-flex justify-content-center flex-column aggregation-block ', {
            selected,
            clickable: isMetricClickable
        }), role: isMetricClickable ? 'button' : undefined, onClick: isMetricClickable ? handleChangeMetric : undefined },
        isLoading || displayDataStatus.isPending ? (React.createElement(Skeleton, { paragraph: false, title: {
                className: 'aggregation-value-loading'
            } })) : isPropertyAvailable ? (React.createElement(TooltipTruncate, { className: "aggregation-value" }, aggregationValue)) : (React.createElement(React.Fragment, null, "\u2014")),
        React.createElement("div", { className: "metric-title d-flex" }, isPropertyAvailable ? (React.createElement(React.Fragment, null,
            React.createElement(TooltipTruncate, { className: "flex-truncate" }, metricTitle),
            rowGrouping && (React.createElement(GroupedMetric, { definitionId: definitionId, metricItem: metricItem, globalFilter: aggregationMetricFilter, onChangeAggregation: onChangeAggregation })))) : (React.createElement(React.Fragment, null, t('METRIC__UNAVAILABLE_PROPERTY'))))));
};
export default MetricItemDisplay;
