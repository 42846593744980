import { t } from '@hypercharge/hyper-react-base/lib/i18n';
import { some } from 'lodash';
import { FaTasks } from 'react-icons/fa';
import { GoFile } from 'react-icons/go';
import { IoIosPaper, IoIosPerson, IoMdClipboard, IoMdGitNetwork, IoMdGlobe, IoMdPeople } from 'react-icons/io';
import { MdBusiness, MdDoneAll } from 'react-icons/md';
import { TbForms } from 'react-icons/tb';
import { CMS_PATH, FEATURED_ENTITIES_PATH, REQUIRED_CMS_PERMISSION } from '../../cms';
import { APP_ADMIN_PERMISSION, REQUIRED_MAIL_TEMPLATES_PERMISSION } from '../../common/utils/constants';
import { getEntityBrowseUrl, getEntityViewUrl } from '../../common/utils/url';
import { CONTACT_CMS_DEFINITION_ID, DOMAIN_CMS_DEFINITION_ID, ORGANIZATION_CMS_DEFINITION_ID } from '../../crm';
import { DOCUMENTS_PATH, DOCUMENT_TEMPLATES_PATH } from '../../documents';
import { EMBEDDED_BI_PATH, EMBEDDED_BI_PERMISSION } from '../../embedded-bi';
import { FORMS_PATH, HYPER_FORMS_PERMISSION } from '../../forms';
import { GROUPS_PATH, GROUP_CMS_DEFINITION_ID } from '../../groups/constants';
import { FULL_HOME_PATH } from '../../home';
import { EMAIL_TEMPLATES_PATH, MARKETING_PATH } from '../../marketing';
import { GDPR_CHANGE_REQUEST_PROCESS_META_ID } from '../../settings/components/gdpr/utils';
import { getStoredViewInLocalStorage, isDefaultViewId } from '../../views/utils';
import { ACTIVITY_YOUR_DASHBOARD_PATH, ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID, FEATURED_PROCESSES_PATH, PROCESSES_PATH, REQUIRED_WORKFLOWS_PERMISSION, WORKFLOWS_PATH, WORKFLOWS_YOUR_DASHBOARD_PATH } from '../../workflows';
import { ACTIVITY_YOUR_DASHBOARD, WORKFLOWS_YOUR_DASHBOARD } from '../../workflows/common/utils/constants';
// TODO: Replace this hard-coded list with a dynamically generated way using getNestedMenuCmsReferences
// The current issue is that it requires using the getMenu, which requires other selectors to be included
export const nestedFeaturedCmsDefinitionIds = [
    ORGANIZATION_CMS_DEFINITION_ID,
    CONTACT_CMS_DEFINITION_ID,
    GROUP_CMS_DEFINITION_ID,
    DOMAIN_CMS_DEFINITION_ID,
    GDPR_CHANGE_REQUEST_PROCESS_META_ID
];
const getToLinksForRoutes = ({ contactId, viewIdMapInStorage }) => {
    const routesData = [
        {
            key: 'crmOrganizationToLink',
            definitionId: ORGANIZATION_CMS_DEFINITION_ID,
            baseUrl: FEATURED_ENTITIES_PATH
        },
        {
            key: 'crmContactToLink',
            definitionId: CONTACT_CMS_DEFINITION_ID,
            baseUrl: FEATURED_ENTITIES_PATH
        },
        {
            key: 'crmDomainToLink',
            definitionId: DOMAIN_CMS_DEFINITION_ID,
            baseUrl: FEATURED_ENTITIES_PATH
        },
        {
            key: 'crmGdprChangeRequestToLink',
            definitionId: GDPR_CHANGE_REQUEST_PROCESS_META_ID,
            baseUrl: FEATURED_PROCESSES_PATH
        },
        {
            key: 'allProcessesToLink',
            definitionId: ALL_PROCESSES_DEFINITION_ID,
            baseUrl: `${WORKFLOWS_PATH}${PROCESSES_PATH}`
        },
        {
            key: 'allTasksToLink',
            definitionId: ALL_TASKS_DEFINITION_ID,
            baseUrl: `${WORKFLOWS_PATH}${PROCESSES_PATH}`
        },
        {
            key: 'yourWorkflowsToLink',
            definitionId: WORKFLOWS_YOUR_DASHBOARD,
            pathname: WORKFLOWS_YOUR_DASHBOARD_PATH,
            baseUrl: WORKFLOWS_PATH,
            isUserDashboard: true
        },
        {
            key: 'yourActivityToLink',
            definitionId: ACTIVITY_YOUR_DASHBOARD,
            pathname: ACTIVITY_YOUR_DASHBOARD_PATH,
            baseUrl: WORKFLOWS_PATH,
            isUserDashboard: true
        }
    ];
    const toLinks = {
        allProcessesToLink: '',
        allTasksToLink: '',
        yourWorkflowsToLink: '',
        yourActivityToLink: '',
        crmOrganizationToLink: '',
        crmContactToLink: '',
        crmDomainToLink: '',
        crmGdprChangeRequestToLink: ''
    };
    for (const { baseUrl, definitionId, key, pathname, isUserDashboard } of routesData) {
        const storedViewId = getStoredViewInLocalStorage({
            definitionId,
            pathname,
            contactId,
            viewIdMapInStorage
        });
        const to = storedViewId && !isDefaultViewId(storedViewId, isUserDashboard)
            ? getEntityViewUrl(definitionId, storedViewId, baseUrl)
            : getEntityBrowseUrl(definitionId, baseUrl);
        toLinks[key] = to;
    }
    return toLinks;
};
export const getMenu = ({ availableDefinitionIds, contactId, dashboards, hasPermission, viewIdMapInStorage }) => {
    const menu = [];
    if (dashboards?.length) {
        menu.push({
            key: 'dashboards',
            title: t('HYPER_BI_DASHBOARDS_NAV_LINK'),
            permissions: [REQUIRED_CMS_PERMISSION],
            children: dashboards.map((dashboard) => {
                return {
                    key: dashboard.entityId,
                    to: `${FULL_HOME_PATH}/${dashboard.entityId}`,
                    title: dashboard.title
                };
            })
        });
    }
    else {
        menu.push({
            key: 'dashboards',
            title: t('HYPER_BI_DASHBOARDS_NAV_LINK'),
            permissions: [REQUIRED_CMS_PERMISSION],
            to: FULL_HOME_PATH
        });
    }
    const { allProcessesToLink, allTasksToLink, yourWorkflowsToLink, yourActivityToLink, crmContactToLink, crmOrganizationToLink, crmDomainToLink, crmGdprChangeRequestToLink } = getToLinksForRoutes({ contactId, viewIdMapInStorage });
    menu.push({
        key: 'crm',
        title: t('CRM_NAV_LINK'),
        permissions: [REQUIRED_CMS_PERMISSION],
        children: [
            {
                key: ORGANIZATION_CMS_DEFINITION_ID,
                to: crmOrganizationToLink,
                title: t('ORGANIZATIONS_NAV_LINK'),
                icon: MdBusiness,
                cmsDefinitionId: ORGANIZATION_CMS_DEFINITION_ID
            },
            {
                key: CONTACT_CMS_DEFINITION_ID,
                to: crmContactToLink,
                title: t('CONTACTS_NAV_LINK'),
                icon: IoIosPerson,
                cmsDefinitionId: CONTACT_CMS_DEFINITION_ID
            },
            {
                key: DOMAIN_CMS_DEFINITION_ID,
                to: crmDomainToLink,
                title: t('DOMAINS'),
                icon: IoMdGlobe,
                cmsDefinitionId: DOMAIN_CMS_DEFINITION_ID
            },
            {
                key: GROUPS_PATH,
                to: GROUPS_PATH,
                title: t('GROUP__NAV_LINK'),
                icon: IoMdPeople,
                cmsDefinitionId: GROUP_CMS_DEFINITION_ID
            },
            {
                key: GDPR_CHANGE_REQUEST_PROCESS_META_ID,
                to: crmGdprChangeRequestToLink,
                title: t('GDPR_CHANGE_REQUEST_NAV_LINK'),
                icon: IoMdGitNetwork,
                cmsDefinitionId: GDPR_CHANGE_REQUEST_PROCESS_META_ID
            }
        ]
    }, {
        key: 'templates',
        title: t('TEMPLATES_NAV_LINK'),
        permissions: [
            REQUIRED_MAIL_TEMPLATES_PERMISSION,
            APP_ADMIN_PERMISSION,
            HYPER_FORMS_PERMISSION
        ],
        children: [
            {
                key: 'templates',
                to: `${MARKETING_PATH}${EMAIL_TEMPLATES_PATH}`,
                title: t('EMAILS_NAV_LINK'),
                icon: IoIosPaper,
                permissions: [REQUIRED_MAIL_TEMPLATES_PERMISSION]
            },
            {
                key: 'document-templates',
                to: `${DOCUMENTS_PATH}${DOCUMENT_TEMPLATES_PATH}`,
                title: `${t('DOCUMENTS_TEMPLATES_NAV_LINK')}`,
                icon: GoFile,
                permissions: [APP_ADMIN_PERMISSION]
            },
            {
                key: 'forms',
                to: `${FORMS_PATH}`,
                title: `${t('FORMS_NAV_LINK')}`,
                icon: TbForms,
                permissions: [HYPER_FORMS_PERMISSION]
            }
        ]
    }, {
        key: 'workflows',
        title: t('WORKFLOWS_NAV_LINK'),
        permissions: [REQUIRED_WORKFLOWS_PERMISSION],
        children: [
            {
                key: 'your-dashboard',
                to: yourWorkflowsToLink,
                title: t('YOUR_DASHBOARD_NAV_LINK'),
                icon: MdDoneAll,
                children: [
                    {
                        key: 'your-dashboard-workflows',
                        to: yourWorkflowsToLink,
                        title: t('WORKFLOWS_NAV_LINK'),
                        icon: IoMdGitNetwork
                    },
                    {
                        key: 'your-dashboard-activity',
                        to: yourActivityToLink,
                        title: t('ACTIVITES_NAV_LINK'),
                        icon: FaTasks
                    }
                ]
            },
            {
                key: 'processes',
                to: allProcessesToLink,
                title: t('WORKFLOWS_PROCESSES_NAV_LINK'),
                icon: IoMdGitNetwork
            },
            {
                key: 'tasks',
                to: allTasksToLink,
                title: t('TASKS'),
                icon: IoMdClipboard
            }
        ]
    }, {
        key: 'cms',
        title: t('CMS_NAV_LINK'),
        permissions: [REQUIRED_CMS_PERMISSION],
        to: CMS_PATH
    }, {
        key: 'embedded',
        title: t('EMBEDDED_BI_NAV_LINK'),
        permissions: [EMBEDDED_BI_PERMISSION],
        to: EMBEDDED_BI_PATH
    });
    const permissionFilter = ({ permissions }) => permissions ? some(permissions, hasPermission) : true;
    const cmsFilter = ({ cmsDefinitionId }) => !cmsDefinitionId || availableDefinitionIds.includes(cmsDefinitionId);
    return menu
        .filter(permissionFilter)
        .filter(cmsFilter)
        .map((item) => ({
        ...item,
        children: (item.children || [])
            .filter(permissionFilter)
            .filter(cmsFilter)
            .map((child) => ({
            ...child
        }))
    }));
};
export const getNestedMenuCmsReferences = (menu) => {
    const nestedMenuReferences = [];
    menu.forEach((menuItem) => {
        if (menuItem.children) {
            menuItem.children.forEach((childItem) => {
                if (childItem.cmsDefinitionId) {
                    nestedMenuReferences.push({
                        menuTitle: menuItem.title,
                        definitionId: childItem.cmsDefinitionId
                    });
                }
            });
        }
    });
    return nestedMenuReferences;
};
