import { getAvailableLanguages, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { INTEGRATION_ADMIN_PERMISSION, MANAGE_FAVOURITES_PERMISSION } from '@hypercharge/portal-utils';
import { Dropdown } from 'antd';
import { DOCUMENTATION_URL, ROADMAP_URL } from 'config';
import { head } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { FaPeopleArrows, FaRocket } from 'react-icons/fa';
import { IoSettingsOutline } from 'react-icons/io5';
import { MdArrowDropDown, MdArrowForward, MdDns, MdFormatListBulleted, MdHelpOutline, MdLanguage, MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ADMIN_PATH, DEFINITIONS_PATH, OPTIONS_PATH, REQUIRED_ADMIN_TENANT_PERMISSION } from '../../../../../../admin';
import { getAccountId, hasTenantPermission } from '../../../../../../auth';
import { useRetrieveAccounts } from '../../../../../../auth/hooks';
import { isStateT } from '../../../../../../auth/reducer';
import { TooltipTruncate } from '../../../../../../common/components/TooltipTruncate';
import { isPersistStateT } from '../../../../../../common/types';
import { APP_ADMIN_PERMISSION, PLATFORM_ADMIN_PERMISSION } from '../../../../../../common/utils/constants';
import { ContactDisplayName } from '../../../../../../crm';
import { useMyContact } from '../../../../../../crm/components/use-my-contact';
import { SETTINGS_PATH } from '../../../../../../settings';
import { PAYMENT_SUBSCRIPTION_PERMISSION } from '../../../../../../settings/components/subscriptions/constants';
import { reloadPage } from '../../utils';
import styles from './UserDesktop.module.scss';
const UserDesktop = ({ className, changeLanguage, signOut, onSwitchUser }) => {
    const { language, t } = useI18n();
    const { contact } = useMyContact();
    const { hasRequiredAdminTenantPermission, hasAdminPermission, hasPlatformAdminPermission, hasIntegrationsAdminPermission, hasPaymentAdminPermission, hasManageFavouritesPermission } = useSelector((s) => ({
        hasRequiredAdminTenantPermission: hasTenantPermission(s, REQUIRED_ADMIN_TENANT_PERMISSION),
        hasAdminPermission: hasTenantPermission(s, APP_ADMIN_PERMISSION),
        hasPlatformAdminPermission: hasTenantPermission(s, PLATFORM_ADMIN_PERMISSION),
        hasIntegrationsAdminPermission: hasTenantPermission(s, INTEGRATION_ADMIN_PERMISSION),
        hasPaymentAdminPermission: hasTenantPermission(s, PAYMENT_SUBSCRIPTION_PERMISSION),
        hasManageFavouritesPermission: hasTenantPermission(s, MANAGE_FAVOURITES_PERMISSION)
    }));
    const currentEntityId = useSelector((s) => getAccountId(s));
    const languages = useSelector((s) => getAvailableLanguages(s.i18n));
    const { data: contacts, isPending } = useRetrieveAccounts();
    useEffect(() => {
        const onLocalStorageUpdated = (storageEvent) => {
            try {
                if (!storageEvent.oldValue || !storageEvent.newValue) {
                    return;
                }
                const oldValue = JSON.parse(storageEvent.oldValue);
                const newValue = JSON.parse(storageEvent.newValue);
                if (!isPersistStateT(oldValue) || !isPersistStateT(newValue)) {
                    return;
                }
                const oldAuth = JSON.parse(oldValue.auth);
                const newAuth = JSON.parse(newValue.auth);
                if (!isStateT(oldAuth) || !isStateT(newAuth)) {
                    return;
                }
                if (oldAuth.refreshToken !== newAuth.refreshToken) {
                    reloadPage();
                }
            }
            catch (e) {
                console.error(e);
            }
        };
        window.addEventListener('storage', onLocalStorageUpdated);
        return () => window.removeEventListener('storage', onLocalStorageUpdated);
    }, []);
    const menuItems = useMemo(() => {
        const items = [];
        if (hasRequiredAdminTenantPermission) {
            items.push({
                popupClassName: 'app-header-dropdown',
                popupOffset: [15, 0],
                key: 'admin',
                label: (React.createElement(React.Fragment, null,
                    React.createElement(IoSettingsOutline, null),
                    React.createElement("span", null, t('ADMIN_NAV_LINK')))),
                children: [
                    {
                        key: 'definitions',
                        label: (React.createElement(Link, { to: `${ADMIN_PATH}${DEFINITIONS_PATH}` },
                            React.createElement(MdDns, null),
                            React.createElement("span", null, t('SETTINGS_DEFINITIONS_NAV_LINK'))))
                    },
                    {
                        key: 'options',
                        label: (React.createElement(Link, { to: `${ADMIN_PATH}${OPTIONS_PATH}` },
                            React.createElement(MdFormatListBulleted, null),
                            React.createElement("span", null, t('SETTINGS_OPTIONS_NAV_LINK'))))
                    }
                ]
            });
        }
        if (languages?.length > 1) {
            items.push({
                key: 'language',
                popupClassName: 'app-header-dropdown',
                popupOffset: [15, 0],
                label: (React.createElement(React.Fragment, null,
                    React.createElement(MdLanguage, null),
                    React.createElement("span", null, t('COMMON__LANGUAGE')))),
                children: languages?.map((lang) => ({
                    key: lang,
                    disabled: lang === language,
                    label: (React.createElement("div", null,
                        lang === language ? React.createElement(MdRadioButtonChecked, null) : React.createElement(MdRadioButtonUnchecked, null),
                        React.createElement("span", null, t(lang))))
                }))
            });
        }
        if (!isPending && contacts && contacts.length > 1) {
            items.push({
                key: 'contact',
                popupClassName: 'app-header-dropdown',
                popupOffset: [15, 0],
                label: (React.createElement(React.Fragment, null,
                    React.createElement(FaPeopleArrows, null),
                    React.createElement("span", null, t('MAIN_MENU__SWITCH_USER')))),
                children: contacts?.map((contact) => ({
                    key: contact.entityId,
                    disabled: contact.entityId === currentEntityId,
                    label: (React.createElement("div", { className: "d-flex align-items-center" },
                        contact.entityId === currentEntityId ? (React.createElement(MdRadioButtonChecked, null)) : (React.createElement(MdRadioButtonUnchecked, null)),
                        React.createElement(TooltipTruncate, { style: { maxWidth: '200px' } }, [contact.firstName, contact.lastName].join(' '))))
                }))
            });
        }
        if (hasAdminPermission ||
            hasPlatformAdminPermission ||
            hasIntegrationsAdminPermission ||
            hasPaymentAdminPermission ||
            hasManageFavouritesPermission) {
            items.push({
                key: 'settings',
                label: (React.createElement(Link, { "data-test-id": "Link-Header-UserDesktop-Settings", to: SETTINGS_PATH },
                    React.createElement(IoSettingsOutline, null),
                    React.createElement("span", null, t('SETTINGS'))))
            });
        }
        items.push({
            key: 'help',
            label: (React.createElement("a", { href: `${DOCUMENTATION_URL}/login-process`, target: "_blank", rel: "noopener noreferrer", "data-test-id": "menu-item-user-dropdown-help" },
                React.createElement(MdHelpOutline, null),
                React.createElement("span", null, t('HELP'))))
        }, {
            key: 'roadmap',
            label: (React.createElement("a", { href: ROADMAP_URL, target: "_blank", rel: "noopener noreferrer", "data-test-id": "menu-item-user-dropdown-roadmap" },
                React.createElement(FaRocket, null),
                React.createElement("span", null, t('FEATURE_WISHLIST_AND_ROADMAP'))))
        }, {
            type: 'divider'
        }, {
            key: 'signOut',
            label: (React.createElement(React.Fragment, null,
                React.createElement(MdArrowForward, null),
                React.createElement("span", null, t('AUTH_LOG_OUT_NAV_LINK'))))
        });
        return items;
    }, [
        hasRequiredAdminTenantPermission,
        languages,
        isPending,
        contacts,
        hasAdminPermission,
        hasPlatformAdminPermission,
        hasIntegrationsAdminPermission,
        hasPaymentAdminPermission,
        hasManageFavouritesPermission,
        t,
        language,
        currentEntityId
    ]);
    const dropdownMenu = useMemo(() => {
        const dropdownMenuOnClick = ({ keyPath }) => {
            if (keyPath.includes('signOut')) {
                signOut();
            }
            else if (keyPath.includes('language')) {
                changeLanguage(head(keyPath));
            }
            else if (keyPath.includes('contact')) {
                const contactId = head(keyPath);
                if (contactId) {
                    onSwitchUser(contactId);
                }
            }
        };
        return {
            className: 'app-header-dropdown',
            onClick: dropdownMenuOnClick,
            subMenuOpenDelay: 0,
            items: menuItems
        };
    }, [changeLanguage, menuItems, onSwitchUser, signOut]);
    return (React.createElement(Dropdown, { "data-test-id": "menu-user-dropdown", className: className, trigger: ['click'], placement: "bottomRight", menu: dropdownMenu, rootClassName: "app-header-dropdown" },
        React.createElement("div", { key: "user-button", className: styles.dropdown__button, "data-test-id": "menu-item-user-dropdown-button" },
            React.createElement("div", { className: `${styles.avatar} cp-i-none` },
                React.createElement(ContactDisplayName, { contact: contact, justFirstLetter: true })),
            React.createElement("div", { className: "cp-i-none" },
                React.createElement("div", { className: `${styles.names} ${styles.names__first}` },
                    React.createElement(TooltipTruncate, null, contact?.firstName)),
                React.createElement("div", { className: `${styles.names} ${styles.names__last}` },
                    React.createElement(TooltipTruncate, null, contact?.lastName))),
            React.createElement(MdArrowDropDown, { className: `${styles.dropdown__icon} cp-i-none` }))));
};
export default UserDesktop;
