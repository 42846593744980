import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { isString } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { ENTITY_ID_PROPERTY_ID, UPDATED_AT_ID_PROPERTY_ID } from '../../../../../cms';
import { PropertyTypes } from '../../../../../common/types';
import CheckboxField from './fields/CheckboxField';
import DateField from './fields/DateField';
import EntityField from './fields/EntityField';
import FileField from './fields/FileField';
import LabelsField from './fields/LabelsField';
import NumberField from './fields/NumberField';
import SelectField from './fields/SelectField';
import TextField from './fields/TextField';
const isPartialError = (error) => {
    return (!!error &&
        typeof error === 'object' &&
        (('meta' in error && typeof error.meta === 'object') ||
            ('labels' in error && typeof error.labels === 'object')));
};
const BasicInfoTab = ({ metaDefinitionId, disabled, value, onChange, error }) => {
    const { t } = useI18n();
    const errorLabels = useMemo(() => {
        if (isPartialError(error)) {
            return error?.labels;
        }
        return;
    }, [error]);
    const currentError = useMemo(() => {
        if (!error) {
            return;
        }
        if (isPartialError(error)) {
            return error?.meta && Object.values(error?.meta)[0];
        }
        if (isString(error)) {
            return error;
        }
    }, [error]);
    const onChangeLabels = useCallback((val) => {
        onChange({ ...value, labels: val });
    }, [onChange, value]);
    const onChangeValue = useCallback((val) => {
        onChange({ ...value, ...val });
    }, [onChange, value]);
    const extraFieldNode = useMemo(() => {
        if (value.propertyId !== ENTITY_ID_PROPERTY_ID &&
            value.propertyId !== UPDATED_AT_ID_PROPERTY_ID) {
            switch (value.type) {
                case PropertyTypes.text:
                    return (React.createElement(TextField, { disabled: disabled, metaDefinitionId: metaDefinitionId, onChange: onChangeValue, value: value, error: currentError }));
                case PropertyTypes.select:
                    return (React.createElement(SelectField, { disabled: disabled, onChange: onChangeValue, value: value, error: currentError }));
                case PropertyTypes.number:
                    return (React.createElement(NumberField, { disabled: disabled, metaDefinitionId: metaDefinitionId, onChange: onChangeValue, value: value, error: currentError }));
                case PropertyTypes.date:
                    return (React.createElement(DateField, { disabled: disabled, metaDefinitionId: metaDefinitionId, onChange: onChangeValue, value: value, error: currentError }));
                case PropertyTypes.checkbox:
                    return (React.createElement(CheckboxField, { disabled: disabled, metaDefinitionId: metaDefinitionId, onChange: onChangeValue, value: value, error: currentError }));
                case PropertyTypes.file:
                    return (React.createElement(FileField, { disabled: disabled, onChange: onChangeValue, value: value, error: currentError }));
                case PropertyTypes.entity:
                    return (React.createElement(EntityField, { disabled: disabled, onChange: onChangeValue, value: value, error: currentError }));
            }
        }
        return null;
    }, [currentError, disabled, metaDefinitionId, onChangeValue, value]);
    return (React.createElement(React.Fragment, null,
        React.createElement(LabelsField, { disabled: disabled, onChange: onChangeLabels, value: value.labels, error: errorLabels }),
        extraFieldNode && (React.createElement(React.Fragment, null,
            React.createElement("label", { className: "pb-2 fw-500" }, t('EXTRAS')),
            extraFieldNode))));
};
export default BasicInfoTab;
