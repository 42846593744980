import { cloneDeep, compact, flatMap, isEmpty } from 'lodash';
import { isWorkflowDefinition } from '../../common/utils/url';
import { AvailableSystemGroups } from '../../groups/constants';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID } from '../common/utils/constants';
export const getProperties = (metaDefinition) => compact(flatMap(metaDefinition?.sections || [], 'properties'));
export const PERMISSION_GROUPS = [
    AvailableSystemGroups.viewGroups,
    AvailableSystemGroups.createGroups,
    AvailableSystemGroups.deleteGroups
];
export const PERMISSION_GROUP_LABELS = {
    [AvailableSystemGroups.viewGroups]: 'WORKFLOWS_SETTINGS_VIEW_PERMISSION',
    [AvailableSystemGroups.createGroups]: 'WORKFLOWS_SETTINGS_EXECUTE_PERMISSION',
    [AvailableSystemGroups.deleteGroups]: 'CMS_DELETE_PERMISSION'
};
export const isProcess = (itemMeta) => ![ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID].includes(itemMeta.definitionId) &&
    isWorkflowDefinition(itemMeta);
export const isProcessActivity = (value) => {
    if (value?.Start && value.End) {
        return true;
    }
    return false;
};
export const prepareNodeGroupPermissionsForSubmit = (activity) => {
    const newActivity = Object.entries(activity).reduce((acc, [nodeId, node]) => {
        if ('permissions' in node && (node.permissions?.view || node.permissions?.write)) {
            const newNode = cloneDeep(node);
            for (const permissionType in newNode.permissions) {
                const groupsPermissions = newNode.permissions[permissionType];
                if (!groupsPermissions || isEmpty(groupsPermissions)) {
                    continue;
                }
                for (const groupId in groupsPermissions) {
                    const groupPermission = groupsPermissions[groupId];
                    if (!groupPermission) {
                        continue;
                    }
                    if (!groupPermission.restrictions?.filters.length) {
                        delete groupsPermissions[groupId];
                    }
                    else {
                        groupPermission.isEnabled = true;
                        if (permissionType === 'write') {
                            groupPermission.properties = ['taskStatus', 'taskAssignedTo'];
                        }
                    }
                }
                if (permissionType === 'view' && newNode.viewGroups.length) {
                    newNode.viewGroups = newNode.viewGroups.filter((groupId) => !(groupId in (groupsPermissions ?? {})));
                }
                else if (permissionType === 'write' && newNode.executeGroups?.length) {
                    newNode.executeGroups = newNode.executeGroups.filter((groupId) => !(groupId in (groupsPermissions ?? {})));
                }
            }
            acc[nodeId] = newNode;
        }
        else {
            acc[nodeId] = node;
        }
        return acc;
    }, {});
    return newActivity;
};
