import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { replace } from '@hypercharge/hyper-react-base/lib/router';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ActionsButton from '../../../../../common/components/ActionsButton';
import { ReleaseNoteForm } from '../../../../../releaseNotes/components/ReleaseNoteForm';
import { CMS_PATH } from '../../../../common/utils/constants';
import useDefinition from '../../../../hooks/useDefinition';
import useDeleteDefinition from '../../../../hooks/useDeleteDefinition';
const EntityActions = ({ className, canDelete = true, canSave = true, save, saving, disabled, definitionId, formIsValid }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const { mutateAsync: deleteDefinition } = useDeleteDefinition();
    const { definition } = useDefinition({ definitionId });
    const [openReleaseNoteModal, setOpenReleaseNoteModal] = useState(false);
    const _save = useCallback(async () => {
        if (definition?.custom?.requiredReleaseNotes && formIsValid) {
            setOpenReleaseNoteModal(true);
            return;
        }
        await save();
    }, [definition?.custom?.requiredReleaseNotes, formIsValid, save]);
    const deleteAction = useCallback(async () => {
        if (!definitionId) {
            return;
        }
        try {
            await deleteDefinition(definitionId);
            dispatch(replace(CMS_PATH));
            dispatch(success({
                title: t('SUCCESS'),
                message: t('ENTITY_DELETE_CONFIRMATION')
            }));
        }
        catch (err) {
            dispatch(error({
                title: t('ERROR'),
                message: t('ENTITY_DELETE_FAIL')
            }));
            throw err;
        }
    }, [definitionId, deleteDefinition, dispatch, t]);
    const menuActions = useMemo(() => {
        const menu = [];
        if (!definition?.custom?.requiredReleaseNotes) {
            menu.push({
                action: () => {
                    if (formIsValid) {
                        setOpenReleaseNoteModal(true);
                    }
                    else {
                        void save();
                    }
                },
                canRun: canSave,
                buttonLabel: 'FORM__SAVE_WITH_RELEASE_NOTES'
            });
        }
        menu.push({
            action: deleteAction,
            canRun: canDelete,
            buttonLabel: 'DELETE_ENTITY',
            confirmationLabel: 'DELETE_ENTITY',
            delete: true
        });
        return menu;
    }, [
        canDelete,
        canSave,
        definition?.custom?.requiredReleaseNotes,
        deleteAction,
        formIsValid,
        save
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ActionsButton, { className: `d-inline-block ${className || ''} saveButton`, buttonAction: {
                action: _save,
                canRun: canSave,
                buttonLabel: 'FORM__SAVE'
            }, menuActions: menuActions, submitting: saving, disabled: disabled, inversed: false }),
        React.createElement(ReleaseNoteForm, { saving: saving, onSubmit: save, definitionId: definitionId, open: openReleaseNoteModal, onClose: () => setOpenReleaseNoteModal(false) })));
};
export default EntityActions;
