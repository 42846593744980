import React, { useMemo } from 'react';
import { useEntityDisplayData } from '../../../../cms/common/components/withEntityDisplayData';
import { ViewProvider } from '../../../../views';
import { useView } from '../../../../views/components/ViewContext';
import { getAdditionalFiltersForSearchInGroup, getFieldGroupingRow } from '../utils';
import { Group } from './Group';
const ReactTableGroup = ({ row, width, setExpandedMap, expandedPagination, setExpandedPagination }) => {
    const { definitionId, filterRequest, rowGrouping } = useView();
    const { displayData } = useEntityDisplayData(definitionId);
    const fieldGroupBy = useMemo(() => getFieldGroupingRow(rowGrouping, displayData), [displayData, rowGrouping]);
    const additionalFiltersForSearch = useMemo(() => {
        return getAdditionalFiltersForSearchInGroup(fieldGroupBy, rowGrouping, row);
    }, [fieldGroupBy, row, rowGrouping]);
    if (!rowGrouping) {
        return null;
    }
    return (React.createElement(ViewProvider, { initialPage: expandedPagination[row.id]?.page, initialPageSize: expandedPagination[row.id]?.limit, serializeToUrl: false, filterRequest: filterRequest, filteredItems: filterRequest?.ids?.data, additionalFiltersForSearch: additionalFiltersForSearch, definitionId: definitionId, enabledFetch: row?.getIsExpanded() },
        React.createElement(Group, { setExpandedPagination: setExpandedPagination, width: width, row: row, setExpandedMap: setExpandedMap, rowGrouping: rowGrouping })));
};
export default ReactTableGroup;
