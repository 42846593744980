import React, { useCallback, useState } from 'react';
import RichTextEditor from './RichTextEditor';
import './extendEditor';
import { RichTextModal } from './index';
const RichTextEditorWithWrapper = ({ label, ...otherProps }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalOk = useCallback((value) => {
        setIsModalOpen(false);
        if (otherProps.onChange) {
            otherProps.onChange(value);
        }
    }, [otherProps]);
    const handleModalCancel = useCallback(() => {
        setIsModalOpen(false);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(RichTextEditor, { ...otherProps, onModalOpen: setIsModalOpen, editing: false, withoutModalOpening: false }),
        isModalOpen && label ? (React.createElement(RichTextModal, { handleOk: handleModalOk, handleCancel: handleModalCancel, open: isModalOpen, value: otherProps.value, meta: {}, title: label, onChange: (value) => otherProps.onChange?.(value) })) : null));
};
export default RichTextEditorWithWrapper;
