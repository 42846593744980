import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { modalStyleMobile } from '@hypercharge/hyper-react-base/lib/styling';
import cn from 'classnames';
import React, { useState } from 'react';
import { MdMenu } from 'react-icons/md';
import ReactModal from 'react-modal';
import Modal from '../Modal';
import styles from './UserMobile.module.scss';
const UserMobile = ({ signOut, menu, onSwitchUser }) => {
    const { t } = useI18n();
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(MdMenu, { className: styles.menu, key: "Menu", onClick: () => setShowModal(true), "data-test-id": "mobile-menu-user-dropdown" }),
        React.createElement(ReactModal, { key: "Modal", ariaHideApp: false, portalClassName: "ReactModalPortal_slide", overlayClassName: "overlayAnimationDisabled", closeTimeoutMS: 250, contentLabel: "myContact", isOpen: showModal, onRequestClose: handleCloseModal, style: modalStyleMobile },
            React.createElement("div", { className: "cp-c-padding-3" },
                React.createElement(Modal, { signOut: signOut, close: handleCloseModal, menu: menu, onSwitchUser: onSwitchUser }),
                React.createElement(Button, { className: cn('px-5', styles.close), inversed: true, onClick: handleCloseModal, "data-test-id": `mobile-menu-close-button` }, t('COMMON__MODAL_CLOSE'))))));
};
export default UserMobile;
