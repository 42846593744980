import React, { useCallback, useState } from 'react';
import { DateEditor, ValueHistoryDrawer } from '../../../../cms';
import { useUpdateProperty } from '../../../../cms/hooks/useUpdateProperty';
import { InlineEditor } from '../../../../common/components/InlineEditor';
import DeadlineDisplay from './DeadlineDisplay';
const DeadlineEditor = ({ emphasizeOverdue, canEdit, value, definitionId, entityId, propertyId, className, hideActions = false, onChange, deadlineIcon }) => {
    const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
    const { mutateAsync: updateProperty } = useUpdateProperty({
        definitionId
    });
    const DeadlineDisplayComponent = useCallback(({ value }) => (React.createElement(DeadlineDisplay, { icon: deadlineIcon, deadline: value, emphasizeOverdue: emphasizeOverdue })), [emphasizeOverdue, deadlineIcon]);
    const updateDeadline = useCallback(async (newDeadline) => {
        if (!(typeof newDeadline === 'number' || newDeadline === null)) {
            return;
        }
        await updateProperty({ entityId, propertyId, value: newDeadline });
        await onChange?.(newDeadline);
    }, [updateProperty, entityId, propertyId, onChange]);
    const toggleValueHistoryDrawer = useCallback(() => {
        setHistoryDrawerOpen((prev) => !prev);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(InlineEditor, { className: `${className || ''}`, 
            // @ts-expect-error
            displayComponent: DeadlineDisplayComponent, 
            // @ts-expect-error
            editorComponent: DateEditor, meta: { withTime: true }, expand: true, value: value, canEdit: canEdit, hideActions: hideActions, onSave: updateDeadline, onHistoryOpened: toggleValueHistoryDrawer }),
        React.createElement(ValueHistoryDrawer, { open: historyDrawerOpen, definitionId: definitionId, entityId: entityId, propertyId: propertyId, onSave: (propId, value) => updateDeadline(value), onClose: toggleValueHistoryDrawer })));
};
export default DeadlineEditor;
