import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { BasicInput, FormFooter, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications/notificationActions';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { Radio, Select, Tag, Tooltip } from 'antd';
import cronstrue from 'cronstrue/i18n';
import { Formik } from 'formik';
import { get, isEmpty } from 'lodash';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import { IoAlert } from 'react-icons/io5';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import styled from 'styled-components';
import Toggle from '../../../../common/components/Toggle';
import { ComputedExpressionsDocumentationLink } from '../../../../common/components/documentation-link';
import FormikError from '../../../../common/components/formik/FormikError';
import FormikField from '../../../../common/components/formik/FormikField';
import useFiles from '../../../../common/storage/useFiles';
import { getPropertyTypeComponent } from '../../../../workflows/meta/components/meta-definition-form/utils';
import { useEntityDisplayData } from '../../../common/components/withEntityDisplayData';
import { CMS_IMPORTER_PATH, CMS_PATH } from '../../../common/utils/constants';
import { invalidateItemsQuery } from '../../../hooks/invalidateCache';
import useDisplayItemMetaList from '../../../hooks/useDisplayItemMetaList';
import { useEntityItem } from '../../../hooks/useEntityItem';
import { getFlattenedDisplayDataList } from '../../utils';
import styles from './MappingStep.module.scss';
import { fetchPreviewData, importData, saveImportItem } from './actions';
import { PropertyPreview } from './common/PropertyPreview';
import SchedulingFormDrawer from './components/SchedulingFormDrawer';
import { IMPORT_DEFINITION_ID, ORIGINAL_DATA_PROPERTY_ID, ORIGINAL_URL_PROPERTY_ID, unsupportedPropertyTypes } from './constants';
import { getFetchCsvHeadersAction } from './csv/actions';
import { ImportModes } from './types';
import { fillDefaultValue, getFileType, getPropertyConfig, pruneSettings, validateConfiguration } from './utils';
const Option = Select.Option;
const MappingStep = ({ definitionId, importEntityId, goToNextStep, dispatch }) => {
    const dispatchFetchCsvHeadersAction = useDispatch();
    const dispatchFetchPreviewData = useDispatch();
    const { t, language } = useI18n();
    const { data: entities } = useDisplayItemMetaList();
    const [previewData, setPreviewData] = useState([]);
    const [currentPreviewItemIndex, setCurrentPreviewItemIndex] = useState(0);
    const [importItem, setImportItem] = useState();
    const [csvColumns, setCsvColumns] = useState([]);
    const [schedulingFormDrawerOpen, setSchedulingFormDrawer] = useState(false);
    const [importMode, setImportMode] = useState(ImportModes.CREATE_UPDATE);
    const importNodeOptions = Object.keys(ImportModes).map((item) => ({
        value: item,
        label: t(`CMS_IMPORTER__${item}`)
    }));
    const { data: loadedImportItem, isFetching: isPendingLoadedImportItem } = useEntityItem(IMPORT_DEFINITION_ID, importEntityId, ['*']);
    const files = useFiles(loadedImportItem?.[ORIGINAL_DATA_PROPERTY_ID] || []);
    const transformedLoadedImportItem = useMemo(() => {
        if (loadedImportItem && loadedImportItem?.[ORIGINAL_DATA_PROPERTY_ID] && !isEmpty(files)) {
            return {
                ...loadedImportItem,
                [ORIGINAL_DATA_PROPERTY_ID]: files
            };
        }
        return loadedImportItem;
    }, [files, loadedImportItem]);
    const { displayData: definition, displayDataStatus: { isPending: isPendingDefinition } } = useEntityDisplayData(definitionId);
    const options = getFlattenedDisplayDataList(definition)
        .filter(({ type }) => !unsupportedPropertyTypes.includes(type))
        .map(({ propertyId, type, labels }) => {
        const Icon = getPropertyTypeComponent(type);
        return {
            value: propertyId,
            title: getTranslation(labels, language),
            label: (React.createElement(React.Fragment, null,
                Icon && React.createElement(Icon, { size: "1rem", className: "me-2" }),
                getTranslation(labels, language)))
        };
    });
    useEffect(() => {
        if (definition && transformedLoadedImportItem) {
            const newImportItem = fillDefaultValue(transformedLoadedImportItem, definition);
            setImportItem(newImportItem);
        }
    }, [definition, transformedLoadedImportItem]);
    useEffect(() => {
        if (!importItem || getFileType(importItem) != 'csv') {
            return;
        }
        dispatchFetchCsvHeadersAction(getFetchCsvHeadersAction(importItem.entityId))
            .then((columnNames) => {
            setCsvColumns(columnNames);
        })
            .catch((e) => {
            console.error(e);
        });
    }, [dispatchFetchCsvHeadersAction, importItem]);
    const handlePreviewRequest = useCallback((importItem) => {
        if (definition && importItem) {
            const prunedImportSettings = pruneSettings(importItem, definition);
            dispatchFetchPreviewData(fetchPreviewData(importItem.entityId, prunedImportSettings))
                .then((response) => {
                setPreviewData(response);
            })
                .catch((e) => {
                console.error(e);
                dispatch(error({
                    title: t('ERROR'),
                    message: t('CMS_IMPORTER__PREVIEW_DATA_ERROR')
                }));
            });
        }
    }, [definition, dispatchFetchPreviewData, dispatch, t]);
    const handleImportRequest = useCallback(async (values) => {
        try {
            if (definition) {
                const prunedImportSettings = pruneSettings(values, definition);
                values.configuration = prunedImportSettings;
                if (values.cronExpression && values.cronExpression !== '') {
                    await dispatch(saveImportItem(importEntityId, values));
                    await invalidateItemsQuery({
                        definitionId: IMPORT_DEFINITION_ID,
                        ids: [importEntityId]
                    });
                    dispatch(push(`${CMS_PATH}/${definitionId}${CMS_IMPORTER_PATH}/upload/`));
                }
                else {
                    await dispatch(importData(importEntityId, prunedImportSettings));
                    goToNextStep(importEntityId);
                }
            }
        }
        catch (e) {
            console.error(e);
            dispatch(error({
                title: t('ERROR'),
                message: t('CMS_IMPORTER__REQUEST_IMPORT_ERROR')
            }));
        }
    }, [definition, dispatch, importEntityId, definitionId, goToNextStep, t]);
    const handleCancel = useCallback(() => {
        dispatch(push(`${CMS_PATH}/${definitionId}${CMS_IMPORTER_PATH}/upload/`));
    }, [dispatch, definitionId]);
    const PropertyConfig = getPropertyConfig(importItem);
    const regExpFlags = useMemo(() => {
        return ['g', 'i', 'm', 's', 'u', 'y'].map((flag) => ({
            value: flag,
            labels: {
                [language]: t(`CMS_IMPORTER__REGEXP_FLAG_${flag.toUpperCase()}`)
            }
        }));
    }, [language, t]);
    const regExpFlagsTagRender = useCallback((props) => {
        const { value, closable, onClose } = props;
        return (React.createElement(Tag, { closable: closable, onClose: onClose }, value));
    }, []);
    return (React.createElement(Fragment, null, importItem && definition ? (React.createElement(Formik, { validate: (values) => {
            return validateConfiguration(values, definition);
        }, initialValues: importItem, enableReinitialize: true, onSubmit: handleImportRequest, validateOnBlur: true, validateOnChange: false }, ({ handleSubmit, isSubmitting, dirty, values, validateForm, isValid }) => {
        return (React.createElement("form", { noValidate: true, className: styles.mappingForm },
            React.createElement("div", { className: "cp-c-row cp-c-align-start-center mb-4" },
                React.createElement("div", { className: "cp-i-flex", style: paddingRight },
                    React.createElement(LabelText, { className: "cp-c-row cp-c-align-start-center" },
                        t('CMS_IMPORTER__IMPORT_MODE'),
                        React.createElement(Tooltip, { placement: "right", title: t('CMS_IMPORTER__IMPORT_MODE_TOOLTIP') },
                            React.createElement(ScInfoIcon, null))),
                    React.createElement(FormikField, { name: "configuration.importMode", component: Select, mode: "default", optionFilterProp: "children", popupMatchSelectWidth: true, defaultActiveFirstOption: true, onSelect: setImportMode, options: importNodeOptions })),
                importMode !== ImportModes.CREATE_ONLY && (React.createElement("div", { className: "cp-i-flex", style: paddingRight },
                    React.createElement(LabelText, { className: "cp-c-row cp-c-align-start-center" },
                        t('CMS_IMPORTER__UNIQUE_PROPERTY'),
                        React.createElement(Tooltip, { placement: "right", title: t('CMS_IMPORTER__UNIQUE_PROPERTY_TOOLTIP') },
                            React.createElement(ScInfoIcon, null))),
                    React.createElement(FormikField, { name: "configuration.uniquePropertyId", component: Select, style: { width: '100%' }, mode: "default", showSearch: true, allowClear: true, disabled: isPendingDefinition, optionFilterProp: "title", popupMatchSelectWidth: true, placeholder: t('CMS_IMPORTER__UNIQUE_PROPERTY_PLACEHOLDER'), defaultActiveFirstOption: false, options: options }))),
                importItem && getFileType(importItem) == 'xml' && (React.createElement("div", { className: "cp-i-flex", style: paddingRight },
                    React.createElement(FormikField, { name: "configuration.pathToDataArray", placeholder: "/vehicles/vehicle", label: t('CMS_IMPORTER__XML_PATH_TO_DATA_ARRAY'), component: ScBasicInput, disabled: isPendingDefinition, value: values.configuration.pathToDataArray || '' }),
                    React.createElement(FormikError, { name: 'configuration.pathToDataArray' }))),
                importItem && getFileType(importItem) == 'json' && (React.createElement("div", { className: "cp-i-flex", style: paddingRight },
                    React.createElement(FormikField, { name: "configuration.pathToDataArray", placeholder: "ProDataSet.cust.*", label: t('CMS_IMPORTER__JSON_PATH_TO_DATA_ARRAY'), component: ScBasicInput, disabled: isPendingDefinition, value: values.configuration.pathToDataArray || '' }),
                    React.createElement(FormikError, { name: 'configuration.pathToDataArray' }))),
                React.createElement("div", { className: "cp-i-flex", style: paddingRight },
                    React.createElement(LabelText, { className: "cp-c-row cp-c-align-start-center" },
                        t('CMS_IMPORTER__PUBLISH_ENTITY_EVENTS_PROPERTY'),
                        React.createElement(Tooltip, { placement: "right", title: t('CMS_IMPORTER__PUBLISH_ENTITY_EVENTS_PROPERTY_TOOLTIP') },
                            React.createElement(ScInfoIcon, null))),
                    React.createElement(FormikField, { name: "configuration.publishEntityEvents", component: Toggle, disabled: isPendingDefinition, value: values.configuration.publishEntityEvents || false })),
                importItem &&
                    importItem[ORIGINAL_URL_PROPERTY_ID] &&
                    importItem[ORIGINAL_URL_PROPERTY_ID] != '' && (React.createElement("div", { className: "cp-i-flex" },
                    React.createElement(LabelText, { className: "cp-c-row cp-c-align-start-center" },
                        t('CMS_IMPORTER__SCHEDULING'),
                        React.createElement(Tooltip, { placement: "right", title: t('CMS_IMPORTER__SCHEDULING_TOOLTIP') },
                            React.createElement(ScInfoIcon, null))),
                    React.createElement("span", { style: { cursor: 'pointer' }, onClick: () => setSchedulingFormDrawer(true) }, values.cronExpression && values.cronExpression != ''
                        ? cronstrue.toString(values.cronExpression, {
                            verbose: true,
                            locale: language
                        })
                        : t('CMS_IMPORTER__SCHEDULING_NOT_CONFUGURED')),
                    React.createElement(FormikField, { name: "cronExpression", component: SchedulingFormDrawer, value: values.cronExpression || '0 7 * * *', open: schedulingFormDrawerOpen, translate: t, language: language, clearValue: (e) => {
                            setSchedulingFormDrawer(false);
                            return e;
                        }, onClose: () => setSchedulingFormDrawer(false) })))),
            importItem && getFileType(importItem) == 'txt' && (React.createElement("div", { className: "justify-content-start align-items-top d-flex mb-4" },
                React.createElement("div", { className: "cp-i-flex", style: paddingRight },
                    React.createElement(LabelText, { className: "cp-c-row cp-c-align-start-center" },
                        t('CMS_IMPORTER__REGEXP'),
                        React.createElement(Tooltip, { placement: "right", title: t('CMS_IMPORTER__REGEXP_TOOLTIP') },
                            React.createElement(ScInfoIcon, null))),
                    React.createElement(FormikField, { name: "configuration.regExp", component: BasicInput, className: styles.regExp, value: values.configuration.regExp, disabled: isPendingDefinition }),
                    React.createElement(FormikError, { name: "configuration.regExp" })),
                React.createElement("div", { className: "cp-i-flex", style: paddingRight },
                    React.createElement(LabelText, null, t('CMS_IMPORTER__REGEXP_FLAGS')),
                    React.createElement(FormikField, { name: "configuration.regExpFlags", component: Select, mode: "multiple", language: language, className: styles.regExpFlags, value: values.configuration.regExpFlags, tagRender: regExpFlagsTagRender, optionFilterProp: "children", popupMatchSelectWidth: false, disabled: isPendingDefinition }, regExpFlags.map((flag) => (React.createElement(Option, { key: flag.value, value: flag.value }, flag.labels[language])))),
                    React.createElement(FormikError, { name: "configuration.regExpFlags" })))),
            values.configuration.uniquePropertyId &&
                values.configuration.importMode !== ImportModes.CREATE_ONLY && (React.createElement("div", { className: "cp-c-row cp-c-align-start-center mb-4" },
                React.createElement("div", { className: "cp-i-flex", style: paddingRight },
                    React.createElement(LabelText, { className: "cp-c-row cp-c-align-start-center" },
                        t('CMS_IMPORTER__CHANGE_ALL_MATCHING_ITEMS_PROPERTY'),
                        React.createElement(Tooltip, { placement: "right", title: t('CMS_IMPORTER__CHANGE_ALL_MATCHING_ITEMS_PROPERTY_TOOLTIP') },
                            React.createElement(ScInfoIcon, null))),
                    React.createElement(FormikField, { name: "configuration.changeAllMatchingItems", component: Toggle, disabled: isPendingDefinition, value: values.configuration.changeAllMatchingItems })))),
            React.createElement(SectionCard, { loading: isPendingLoadedImportItem || isPendingDefinition, withoutPadding: true },
                React.createElement(ScMappingHeader, { className: "cp-c-row" },
                    React.createElement(ScMappingTableCell, { className: "cp-i-flex" }, t('CMS_IMPORTER__CMS_PROPERTY')),
                    React.createElement(ScMappingTableCell, { className: "cp-i-flex" },
                        t('CMS_IMPORTER__MAPPING'),
                        " (",
                        React.createElement(ComputedExpressionsDocumentationLink, null),
                        ")"),
                    React.createElement(ScMappingTableCell, { className: "cp-i-flex cp-c-row cp-c-align-spacebetween-center" },
                        React.createElement("div", { className: "cp-c-flex" }, t('CMS_IMPORTER__PREVIEW')),
                        previewData.length > 0 && (React.createElement(Radio.Group, { size: "small", value: currentPreviewItemIndex, onChange: (e) => {
                                setCurrentPreviewItemIndex(e.target.value);
                            } },
                            React.createElement(Radio.Button, { disabled: currentPreviewItemIndex === 0, value: currentPreviewItemIndex - 1 },
                                React.createElement(MdChevronLeft, null)),
                            React.createElement(Radio.Button, { disabled: currentPreviewItemIndex === previewData.length - 1, value: currentPreviewItemIndex + 1 },
                                React.createElement(MdChevronRight, null)))))),
                definition &&
                    getFlattenedDisplayDataList(definition).map((property) => {
                        const { propertyId, labels, type, canEdit, meta = {} } = property;
                        const Icon = getPropertyTypeComponent(type);
                        return (React.createElement(ScMappingTableRow, { key: propertyId, className: "cp-c-row" },
                            React.createElement(ScMappingTableCell, { className: "cp-i-flex" },
                                React.createElement("div", { className: "d-flex align-items-center" },
                                    Icon && React.createElement(Icon, { className: "me-2", size: "1rem" }),
                                    getTranslation(labels, language),
                                    " (",
                                    propertyId,
                                    ")",
                                    get(meta, 'validations.required') &&
                                        values.configuration.importMode !== ImportModes.UPDATE_ONLY && (React.createElement(ScRequiredInfo, null,
                                        "(",
                                        t('CMS_IMPORTER__REQUIRED'),
                                        ")")))),
                            unsupportedPropertyTypes.includes(type) ? (React.createElement(ScMappingTableCell, { className: "cp-i-flex cp-c-row cp-c-align-start-center" },
                                React.createElement(ScDisabledPropertyText, null, t('CMS_IMPORTER__IMPORTING_NOT_SUPPORTED')))) : !canEdit && values.configuration.uniquePropertyId !== propertyId ? (React.createElement(ScMappingTableCell, { className: "cp-i-flex cp-c-row cp-c-align-start-center" },
                                React.createElement(ScDisabledPropertyText, null, t('CMS_IMPORTER__NO_PERMISSIONS')))) : (React.createElement(ScMappingTableCell, { className: "cp-i-flex" },
                                React.createElement(PropertyConfig, { propertyConfig: values.configuration &&
                                        values.configuration.propertyMappings &&
                                        values.configuration.propertyMappings[propertyId], values: values, columns: csvColumns, name: `configuration.propertyMappings.${propertyId}`, property: property, entities: entities }))),
                            React.createElement(ScMappingTableCell, { className: "cp-i-flex" },
                                React.createElement(PropertyPreview, { item: previewData[currentPreviewItemIndex]?.item, property: property }))));
                    })),
            !isValid && (React.createElement(ScErrorMessageContainer, { className: "cp-c-row cp-c-align-end-center" },
                React.createElement(ScErrorIcon, { className: "error-icon" }),
                React.createElement("div", null, t('CORRECT_INVALID_FIELDS')))),
            React.createElement(FormFooter, { showCancel: true, submitText: t(values.cronExpression && values.cronExpression != ''
                    ? 'FORM__SAVE'
                    : 'CMS_IMPORTER__IMPORT'), onSubmit: () => handleSubmit(), onCancel: handleCancel, reset: () => {
                    validateForm().then((err) => {
                        if (isEmpty(err)) {
                            handlePreviewRequest(values);
                        }
                    });
                }, resetText: t('CMS_IMPORTER__PREVIEW') }),
            React.createElement(Prompt, { when: dirty && !isSubmitting, message: t('UNSAVED_DATA_CONFIRM') })));
    })) : (React.createElement(LoadingRectangles, null))));
};
export default MappingStep;
//
// Utils
//
const paddingRight = { paddingRight: '1rem' };
const ScMappingHeader = styled.div `
  font-weight: 500;
`;
const ScMappingTableCell = styled.div `
  padding: 1rem;
  font-size: 0.9rem;
`;
const ScInfoIcon = styled(IoMdInformationCircle) `
  margin-left: 0.3rem;
  color: var(--grayscaleColor-7);
`;
const ScBasicInput = styled(BasicInput) `
  max-width: 300px;
  padding-bottom: 0 !important;
`;
const ScErrorMessageContainer = styled.div `
  padding: 1rem !important;
  color: var(--warningColor-6);
`;
const ScErrorIcon = styled(IoAlert) `
  color: var(--warningColor-6);
  font-size: 1.6rem;
  margin-right: 0.5rem;
`;
const ScMappingTableRow = styled.div `
  border-top: 1px solid var(--grayscaleColor-5);
`;
const ScDisabledPropertyText = styled.div `
  color: var(--grayscaleColor-7);
`;
const ScRequiredInfo = styled.span `
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 300;
  color: var(--neutralColor-4);
`;
