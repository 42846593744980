import { Quill } from 'react-quill';
const OPEN_IN_MODAL_QUILL_ACTION_NAME = 'openInModal';
const iconOpenInModal = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.25 9H12.75V11.25H10.5V12.75H14.25V9ZM5.25 6.75H7.5V5.25H3.75V9H5.25V6.75ZM15.75 2.25H2.25C1.425 2.25 0.75 2.925 0.75 3.75V14.25C0.75 15.075 1.425 15.75 2.25 15.75H15.75C16.575 15.75 17.25 15.075 17.25 14.25V3.75C17.25 2.925 16.575 2.25 15.75 2.25ZM15.75 14.2575H2.25V3.7425H15.75V14.2575Z" class="ql-fill"/>
</svg>`;
const Inline = Quill.import('blots/inline');
class OpenInModalFormat extends Inline {
}
OpenInModalFormat.blotName = OPEN_IN_MODAL_QUILL_ACTION_NAME;
export { OPEN_IN_MODAL_QUILL_ACTION_NAME, OpenInModalFormat, iconOpenInModal };
