import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import cn from 'classnames';
import { isEmpty, map, xor } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ViewActionsButton } from '../../../views';
import { useView } from '../../../views/components/ViewContext';
import { getPropsForActionsButtons } from '../../utils/common';
import { RelatedProcessesSelector } from '../RelatedProcesses';
import Toggle from '../Toggle';
import ActionButtons from '../data-table/ActionButtons';
import FullTextSearch from '../data-table/FullTextSearch';
import ViewSelector from '../data-table/ViewSelector';
import { ActionWhen } from '../data-table/types';
import { useBrowserBreakpoints } from '../with-browser-breakpoints';
import styles from './DataActionsContainer.module.scss';
export const DataActionsContainer = ({ title, className = '', isPending, items, idField, actions, leftActions, noFilters = false, noKanban, noSearch = false, noViewActions = false, noHotkeySearch = false, withCmsImport = false, showViewsSelector, baseUrl, showStartProcessSelector, showFilterSelected = false }) => {
    const { definitionId, selectedItems, isAllItemsSelected, setIsAllItemsSelectedOnPage, filterSelected, setFilterSelected } = useView();
    const { t } = useI18n();
    const { isMobile } = useBrowserBreakpoints();
    const isAllSelectedOnPage = useMemo(() => {
        const pageResultIds = map(items, idField);
        return !isEmpty(pageResultIds) && isEmpty(xor(pageResultIds, selectedItems));
    }, [idField, items, selectedItems]);
    useEffect(() => {
        setIsAllItemsSelectedOnPage(isAllSelectedOnPage);
    }, [setIsAllItemsSelectedOnPage, isAllSelectedOnPage]);
    const actionsButtons = useMemo(() => {
        const actionsButtonList = [];
        if (showStartProcessSelector &&
            (!!selectedItems.length || isAllItemsSelected) &&
            !!definitionId) {
            actionsButtonList.push({
                key: 'startProcess',
                when: ActionWhen.selected,
                buttonComponent: RelatedProcessesSelector,
                buttonProps: getPropsForActionsButtons({
                    metaDefinitionId: definitionId,
                    selectedIds: selectedItems
                })
            });
            return [...actionsButtonList, ...actions];
        }
        return actions;
    }, [actions, definitionId, isAllItemsSelected, selectedItems, showStartProcessSelector]);
    const itemsIds = useMemo(() => items.map((item) => item?.entityId), [items]);
    const onChangeFilterSelected = useCallback((val) => {
        if (val) {
            setFilterSelected(FilterOperatorTypes.in);
        }
        else {
            setFilterSelected(undefined);
        }
    }, [setFilterSelected]);
    return (React.createElement("div", { id: "items-data-action-block", className: "pb-2" },
        React.createElement("div", { className: cn(styles.actionsContainer, className, 'd-flex flex-wrap flex-row align-items-center align-content-center justify-content-start gap-3') },
            title && title,
            leftActions && leftActions?.length > 0 && (React.createElement(ActionButtons, { className: "d-flex flex-row flex-wrap align-items-center align-content-center justify-content-start gap-1", actions: leftActions, itemsIds: itemsIds })),
            !noFilters && (React.createElement(ViewActionsButton, { className: "editViewContainer", isMobile: isMobile, disabled: isPending, noViewActions: noViewActions, withCmsImport: withCmsImport, noKanban: noKanban })),
            showViewsSelector && (React.createElement("div", { className: "viewSelectorContainer" },
                React.createElement(ViewSelector, { baseUrl: baseUrl }))),
            !noSearch && React.createElement(FullTextSearch, { hotkeyFocusSearch: !noHotkeySearch }),
            showFilterSelected && (React.createElement(Toggle, { checked: !!filterSelected, onChange: onChangeFilterSelected, label: t('ONLY_SELECTED') })),
            React.createElement(ActionButtons, { className: "settingsActionButtons ms-auto d-flex flex-row flex-wrap align-items-center align-content-center justify-content-start", actions: actionsButtons, itemsIds: itemsIds }))));
};
