import { Button } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BsFillLightningFill, BsTextareaT } from 'react-icons/bs';
import { TooltipTruncate } from '../../TooltipTruncate';
import ComputedModal from '../ComputedModal';
import styles from './ComputedInput.module.scss';
const ComputedInput = ({ className = '', value, onChange, onComputedModeChange, propertiesTree, component: Component, disabled, clearRemoveComputedExpression, ...otherProps }) => {
    const isComputeMode = !!(value && value.toString().startsWith('='));
    const [computeMode, setComputeMode] = useState();
    useEffect(() => {
        setComputeMode((oldComputeMode) => {
            if (oldComputeMode !== isComputeMode && onComputedModeChange) {
                onComputedModeChange(isComputeMode);
            }
            return isComputeMode;
        });
    }, [isComputeMode, onComputedModeChange]);
    const computedExpression = useMemo(() => (isComputeMode && value !== undefined ? value.toString().substring(1) : value), [isComputeMode, value]);
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const onChangeComponent = useCallback((value) => {
        if (onChange) {
            if (value && value.stopPropagation) {
                onChange(value.target?.value);
            }
            else {
                onChange(value);
            }
        }
    }, [onChange]);
    const onClickComputedExpressionButton = useCallback(() => {
        setIsEditorOpen(true);
    }, []);
    const onClickRemoveComputedExpressionButton = useCallback(() => {
        if (clearRemoveComputedExpression) {
            onChange?.(undefined);
            return;
        }
        onChange?.(computedExpression);
    }, [clearRemoveComputedExpression, computedExpression, onChange]);
    const onOpenEditor = useCallback(() => {
        setIsEditorOpen(true);
    }, []);
    const onEditorOk = useCallback((value) => {
        onChange && onChange(value !== undefined && value !== '' ? `=${value.toString()}` : value);
        setIsEditorOpen(false);
    }, [onChange]);
    const onEditorCancel = useCallback(() => {
        setIsEditorOpen(false);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${className} ${styles.wrapper} ComputedInputOuter container p-0` },
            React.createElement("div", { className: "row position-relative m-0" },
                React.createElement("div", { className: "col p-0" }, isComputeMode ? (React.createElement("div", { className: `computed-expression border border-dark rounded px-2 py-1 ${disabled ? 'disabled' : ''}`, onClick: !disabled ? onOpenEditor : undefined },
                    React.createElement("div", { className: "computed-expression__inner mw-100" },
                        React.createElement(TooltipTruncate, null, computedExpression)))) : (React.createElement("div", { className: "computed-component-outer" },
                    React.createElement(Component, { ...otherProps, disabled: disabled, value: value, onChange: onChangeComponent })))),
                React.createElement(Button, { type: "primary", disabled: disabled, className: `ComputedButton btn col-auto position-absolute end-0 border-0 shadow-none`, onClick: isComputeMode
                        ? onClickRemoveComputedExpressionButton
                        : onClickComputedExpressionButton }, isComputeMode ? React.createElement(BsTextareaT, null) : React.createElement(BsFillLightningFill, null)))),
        React.createElement(ComputedModal, { value: computedExpression, propertiesTree: propertiesTree, open: isEditorOpen, onCancel: onEditorCancel, onOk: onEditorOk })));
};
export default ComputedInput;
