import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType } from '@hypercharge/portal-utils';
import { Badge, Tooltip } from 'antd';
import cn from 'classnames';
import { filter, isString } from 'lodash';
import queryString from 'query-string';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaHistory, FaRegCommentDots, FaRegStar, FaTasks } from 'react-icons/fa';
import { IoMdGitNetwork } from 'react-icons/io';
import { MdHistory } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DataListTabs from '~app/cms/data/components/item-property/DataList/DataListTabs';
import { DataHistoryList } from '../../../../../cms/data/components/item-property/DataHistoryList';
import { HighlightedPropertiesList } from '../../../../../cms/data/components/item/HighlightedPropertiesList';
import { ACTIVE_TAB_RIGHT_PANEL_KEY, AvailableTabs } from '../../../../../cms/data/components/item/InfoPanel/InfoPanel';
import useDisplayData from '../../../../../cms/hooks/useDisplayData';
import useDisplayItemMeta from '../../../../../cms/hooks/useDisplayItemMeta';
import ActivityContainer, { ACTIVITY_DEFINITION_ID, useActivity } from '../../../../../common/activity';
import ConversationContainer from '../../../../../common/conversation/components/ConversationContainer';
import { getList as getMessages } from '../../../../../common/conversation/reducer';
import { getMessagesState } from '../../../../../common/reducers/rootSelector';
import { AvailableHandlers } from '../../../../../common/types';
import { getOrderedHighlightedProperties, getTabState, saveTabState } from '../../../../../common/utils/common';
import { ArrowIcon } from '../../../../../icons';
import { WORKFLOWS_PROCESS_CONVERSATION_NAMESPACE } from '../../../../common/utils/constants';
import { StepsOverview } from '../StepsOverview';
import { getEmailSendingResult } from '../StepsOverview/utils/utils';
import styles from './ProcessSideContent.module.scss';
var ProcessAvailableTabs;
(function (ProcessAvailableTabs) {
    ProcessAvailableTabs["HISTORY"] = "HISTORY";
    ProcessAvailableTabs["PROCESS_OVERVIEW"] = "PROCESS_OVERVIEW";
})(ProcessAvailableTabs || (ProcessAvailableTabs = {}));
function isTabAvailable(tabName) {
    return !!AvailableTabs[tabName] || !!ProcessAvailableTabs[tabName];
}
const getInitActiveTab = (isLoadingActivityItemMeta, handler, definitionId, activityItemMeta, availableHighlightedPropertyIds, location) => {
    if (isLoadingActivityItemMeta) {
        return AvailableTabs.COMMENTS;
    }
    let tabFromStore = getTabState(handler, definitionId);
    const isActiveActivityTabFromSearch = queryString.parse(location.search)[ACTIVE_TAB_RIGHT_PANEL_KEY] === AvailableTabs.ACTIVITY;
    if (isActiveActivityTabFromSearch) {
        const search = queryString.parse(location.search);
        const { activeTab } = search;
        if (isString(activeTab) && isTabAvailable(activeTab)) {
            tabFromStore = activeTab;
        }
    }
    if (tabFromStore) {
        if (tabFromStore === AvailableTabs.ACTIVITY) {
            if (activityItemMeta) {
                return AvailableTabs.ACTIVITY;
            }
            else if (availableHighlightedPropertyIds?.length) {
                return AvailableTabs.HIGHLIGHTED;
            }
            else {
                return AvailableTabs.COMMENTS;
            }
        }
        if (tabFromStore === AvailableTabs.HIGHLIGHTED) {
            if (availableHighlightedPropertyIds?.length) {
                return AvailableTabs.HIGHLIGHTED;
            }
            else if (activityItemMeta) {
                return AvailableTabs.ACTIVITY;
            }
            else {
                return AvailableTabs.COMMENTS;
            }
        }
        else {
            return tabFromStore;
        }
    }
    return availableHighlightedPropertyIds?.length
        ? AvailableTabs.HIGHLIGHTED
        : activityItemMeta
            ? AvailableTabs.ACTIVITY
            : AvailableTabs.COMMENTS;
};
const ProcessSideContent = memo(({ loading, processDetails, processActivity, processMetaId, processRunId, tasks, isSidebarActive, setIsSidebarActive, setProcessOverviewVisible, showToggleButton }) => {
    const { t } = useI18n();
    const location = useLocation();
    const { data: displayData } = useDisplayData({
        definitionId: processMetaId,
        entityId: processRunId
    });
    const { data: activityItemMeta, isLoading: isLoadingActivityItemMeta } = useDisplayItemMeta({
        definitionId: ACTIVITY_DEFINITION_ID
    });
    const { data: displayItemMeta, isLoading: displayItemMetaIsPending } = useDisplayItemMeta({
        definitionId: processMetaId
    });
    const availableHighlightedPropertyIds = useMemo(() => displayItemMeta?.custom?.highlightedProperties
        ?.filter(({ active }) => active)
        .map(({ propertyId }) => propertyId) || [], [displayItemMeta?.custom?.highlightedProperties]);
    const [activeTab, setActiveTab] = useState();
    useEffect(() => {
        setActiveTab(getInitActiveTab(isLoadingActivityItemMeta, AvailableHandlers.workflows, processMetaId, activityItemMeta, availableHighlightedPropertyIds, location));
    }, [
        activityItemMeta,
        availableHighlightedPropertyIds,
        isLoadingActivityItemMeta,
        location,
        processMetaId
    ]);
    const highlightedProperties = useMemo(() => getOrderedHighlightedProperties(displayData?.data, availableHighlightedPropertyIds), [availableHighlightedPropertyIds, displayData?.data]);
    const [activities] = useActivity({
        definitionId: processMetaId,
        entityId: processRunId,
        onlyMy: false,
        filterRequest: {
            responseFields: ['entityId'],
            query: { condition: ConditionType.or, filters: [] },
            sortBy: [],
            limit: 0
        }
    });
    const allActivitiesCount = activities.data?.pages?.[0]?.totalCount;
    const onChangeTab = useCallback((activeTab) => {
        if (isTabAvailable(activeTab)) {
            saveTabState(AvailableHandlers.workflows, processMetaId, activeTab);
            setActiveTab(activeTab);
        }
    }, [processMetaId]);
    useEffect(() => {
        setActiveTab((prev) => {
            if (displayItemMetaIsPending && prev === AvailableTabs.ACTIVITY && !activityItemMeta) {
                if (highlightedProperties.length) {
                    return AvailableTabs.HIGHLIGHTED;
                }
                else {
                    return AvailableTabs.COMMENTS;
                }
            }
            return prev;
        });
    }, [activityItemMeta, displayItemMetaIsPending, highlightedProperties.length]);
    const toggleSideContent = useCallback((activeTab) => {
        setIsSidebarActive(!isSidebarActive);
        if (activeTab) {
            onChangeTab(activeTab);
        }
    }, [onChangeTab, setIsSidebarActive, isSidebarActive]);
    const conversationNamespace = `${WORKFLOWS_PROCESS_CONVERSATION_NAMESPACE}__${processMetaId}`;
    const messagesState = useSelector(getMessagesState);
    const messages = useMemo(() => getMessages(messagesState, conversationNamespace, processRunId).filter((message) => !message.hidden), [messagesState, conversationNamespace, processRunId]);
    const failedTasksCount = useMemo(() => (processDetails?.status === 'FAILED' ? filter(tasks, { status: 'FAILED' }).length : 0), [processDetails, tasks]);
    const emailTasksBadNotificationCount = useMemo(() => {
        let warningsAndFailsOfSending = 0;
        for (const { mailStatuses } of tasks) {
            const emailSendingResult = getEmailSendingResult(mailStatuses);
            if (emailSendingResult) {
                warningsAndFailsOfSending += emailSendingResult.fail;
                warningsAndFailsOfSending += emailSendingResult.warn;
            }
        }
        return warningsAndFailsOfSending;
    }, [tasks]);
    const processHistoryNotificationCount = useMemo(() => failedTasksCount + emailTasksBadNotificationCount, [emailTasksBadNotificationCount, failedTasksCount]);
    const availableButtons = useMemo(() => {
        const buttons = [];
        if (highlightedProperties.length) {
            buttons.push({
                icon: FaRegStar,
                title: 'TAB_TITLE_HIGHLIGHTED_PROPERTIES',
                id: AvailableTabs.HIGHLIGHTED
            });
        }
        if (activityItemMeta) {
            buttons.push({
                icon: FaTasks,
                badge: allActivitiesCount,
                title: 'ACTIVITY',
                id: AvailableTabs.ACTIVITY
            });
        }
        buttons.push(...[
            {
                icon: FaRegCommentDots,
                badge: messages.length,
                title: 'COMMENTS',
                id: AvailableTabs.COMMENTS
            },
            {
                icon: MdHistory,
                title: 'HISTORY',
                id: ProcessAvailableTabs.HISTORY,
                badge: processHistoryNotificationCount
            },
            {
                icon: FaHistory,
                title: 'DATA_HISTORY',
                id: AvailableTabs.DATA_HISTORY
            }
        ]);
        if (processActivity) {
            buttons.push({
                icon: IoMdGitNetwork,
                title: 'PROCESS_OVERVIEW',
                id: ProcessAvailableTabs.PROCESS_OVERVIEW,
                badge: 0
            });
        }
        return buttons;
    }, [
        highlightedProperties.length,
        activityItemMeta,
        messages.length,
        processHistoryNotificationCount,
        processActivity,
        allActivitiesCount
    ]);
    return (React.createElement("div", { className: cn(styles.sideContent, { collapsed: !isSidebarActive }) },
        showToggleButton && (React.createElement("button", { className: cn(styles.sideContentToggleBtn, {
                [styles.sideContentToggleBtn__opened]: isSidebarActive
            }), type: "button", onClick: () => toggleSideContent() },
            React.createElement(ArrowIcon, null))),
        !isSidebarActive && showToggleButton && (React.createElement("div", { className: `${styles.sideTabsButtons} ${!isSidebarActive ? styles.sideTabsButtons__opened : ''}` }, availableButtons.map(({ icon: Icon, id, title, badge }) => (React.createElement(Tooltip, { key: id, title: t(title), placement: "left", trigger: "hover" },
            React.createElement("button", { className: styles.sideTabsButtonsItem, type: "button", onClick: () => id === ProcessAvailableTabs.PROCESS_OVERVIEW
                    ? setProcessOverviewVisible(true)
                    : toggleSideContent(id) },
                React.createElement(Badge, { count: badge },
                    React.createElement(Icon, null)))))))),
        isSidebarActive && (React.createElement(DataListTabs, { type: "card", popupClassName: "info-panel", className: "tabsPanel", activeKey: activeTab, onChange: onChangeTab, destroyInactiveTabPane: true, moreIcon: React.createElement(BsThreeDotsVertical, { size: 22 }), items: [
                ...((!!highlightedProperties.length && [
                    {
                        key: AvailableTabs.HIGHLIGHTED,
                        label: (React.createElement("div", { className: "fw-400 fs-6 mw-100 tabLabel" },
                            React.createElement("div", { className: "layout-field" },
                                React.createElement("span", { className: "ant-badge" },
                                    React.createElement(FaRegStar, { size: "22px" })),
                                React.createElement("span", { className: "ps-1" }, t('TAB_TITLE_HIGHLIGHTED_PROPERTIES'))))),
                        children: (React.createElement(HighlightedPropertiesList, { definitionId: processMetaId, entityId: processRunId, highlightedProperties: highlightedProperties }))
                    }
                ]) ||
                    []),
                ...((activityItemMeta && [
                    {
                        key: AvailableTabs.ACTIVITY,
                        forceRender: true,
                        label: (React.createElement("div", { className: "fw-400 fs-6 mw-100 tabLabel" },
                            React.createElement("div", { className: "layout-field" },
                                React.createElement(Badge, { count: allActivitiesCount },
                                    React.createElement(FaTasks, { size: "24px" })),
                                React.createElement("span", { className: allActivitiesCount ? `ps-3` : 'ps-1' }, t('ACTIVITY'))))),
                        children: (React.createElement(ActivityContainer, { className: "activity-container", definitionId: processMetaId, entityId: processRunId }))
                    }
                ]) ||
                    []),
                {
                    key: AvailableTabs.COMMENTS,
                    forceRender: true,
                    label: (React.createElement("div", { className: "fw-400 fs-6 mw-100 tabLabel" },
                        React.createElement("div", { className: "layout-field" },
                            React.createElement(Badge, { count: messages.length },
                                React.createElement(FaRegCommentDots, { size: "24px" })),
                            React.createElement("span", { className: messages.length ? `ps-3` : 'ps-1' }, t('COMMENTS'))))),
                    children: (React.createElement(ConversationContainer, { conversationNamespace: [
                            WORKFLOWS_PROCESS_CONVERSATION_NAMESPACE,
                            processMetaId
                        ].join('__'), conversationId: processRunId, messagePlaceholder: t('COMMENTS_PLACEHOLDER') }))
                },
                {
                    key: ProcessAvailableTabs.HISTORY,
                    label: (React.createElement("div", { className: "fw-400 fs-6 mw-100 tabLabel" },
                        React.createElement("div", { className: "layout-field" },
                            React.createElement(Badge, { count: processHistoryNotificationCount },
                                React.createElement(MdHistory, { size: "26px" })),
                            React.createElement("span", { className: processHistoryNotificationCount ? `ps-3` : 'ps-1' }, t('HISTORY'))))),
                    children: (React.createElement("div", { className: styles.historyContentContainer },
                        React.createElement(StepsOverview, { tasks: tasks, loading: loading, processDetails: processDetails })))
                },
                {
                    key: AvailableTabs.DATA_HISTORY,
                    label: (React.createElement("div", { className: "fw-400 fs-6 mw-100 tabLabel" },
                        React.createElement("div", { className: "layout-field" },
                            React.createElement("span", { className: "ant-badge" },
                                React.createElement(FaHistory, { size: "19px" })),
                            React.createElement("span", { className: "ps-1" }, t('DATA_HISTORY'))))),
                    children: (React.createElement("div", { className: cn(styles.dataHistoryContainer, 'custom-scroll-styling d-flex flex-column gap-2') },
                        React.createElement(DataHistoryList, { definitionId: processMetaId, entityId: processRunId })))
                },
                ...((processActivity && [
                    {
                        key: ProcessAvailableTabs.PROCESS_OVERVIEW,
                        label: (React.createElement("div", { className: "fw-400 fs-6 mw-100 tabLabel" },
                            React.createElement("div", { className: "layout-field", onClick: (e) => {
                                    setProcessOverviewVisible(true);
                                    e.stopPropagation();
                                } },
                                React.createElement(Badge, null,
                                    React.createElement(IoMdGitNetwork, { size: "24px" })),
                                React.createElement("span", { className: "ps-1" }, t('PROCESS_OVERVIEW')))))
                    }
                ]) ||
                    [])
            ] }))));
});
export { ProcessSideContent };
