import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import cn from 'classnames';
import React from 'react';
import { isTableView } from '~app/views/types';
import DataActionsContainerStyles from '../../../../../common/components/DataActionsContainer/DataActionsContainer.module.scss';
import FullTextSearch from '../../../../../common/components/data-table/FullTextSearch';
import { useBrowserBreakpoints } from '../../../../../common/components/with-browser-breakpoints';
import { useView } from '../../../../../views/components/ViewContext';
import MetricsDisplayContainer from '../../../../../views/components/metrics/MetricsDisplayContainer';
import { SelectTaskType } from '../SelectTaskType';
import { TaskDashboardPageFilters } from '../TaskDashboardPageFilters';
import { TaskViewActions } from '../TaskViewActions';
import { TasksKanban } from '../TasksKanban';
import { TasksTableSize, TasksTableWrapper } from '../TasksTableWrapper';
import styles from './TaskDashboardContent.module.scss';
const TaskDashboardContent = ({ viewActionsBaseUrl, kanbanBaseUrl, relatedToTitleKey, getCustomRouteHandleTaskClick, availableOn, addEmptyAssignedOption, headerRightBlock: HeaderRightBlock, componentTasksTableList: ComponentTasksTableList }) => {
    const { t } = useI18n();
    const { viewType } = useView();
    const { isMobile } = useBrowserBreakpoints();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "DashboardContentHeader", className: "px-2 pb-2" },
            React.createElement("div", { className: cn(styles.wrapper, DataActionsContainerStyles.actionsContainer, 'p-2 d-flex align-items-center justify-content-between gap-3 flex-wrap') },
                React.createElement("div", { className: "d-flex align-items-center flex-wrap gap-3" },
                    React.createElement(TaskViewActions, { baseUrl: viewActionsBaseUrl, relatedToTitleKey: relatedToTitleKey, availableOn: availableOn, addEmptyAssignedOption: addEmptyAssignedOption }),
                    React.createElement(SelectTaskType, null),
                    React.createElement(FullTextSearch, { hotkeyFocusSearch: true })),
                HeaderRightBlock)),
        isTableView(viewType) ? (React.createElement("div", { className: "container-lg px-2 mb-2" },
            React.createElement(TaskDashboardPageFilters, { relatedToTitleKey: relatedToTitleKey, className: "pb-2" }),
            React.createElement(MetricsDisplayContainer, null),
            React.createElement(TasksTableWrapper, null,
                React.createElement(ComponentTasksTableList, { getCustomRouteHandleTaskClick: getCustomRouteHandleTaskClick, emptyText: t('EMPTY_AVAILABLE_TASKS_TEXT'), size: isMobile ? TasksTableSize.small : TasksTableSize.large })))) : (React.createElement("div", { className: "px-3" },
            React.createElement(TaskDashboardPageFilters, { relatedToTitleKey: relatedToTitleKey }),
            React.createElement(TasksKanban, { getCustomRouteHandleCardClick: getCustomRouteHandleTaskClick, baseUrl: kanbanBaseUrl })))));
};
export default TaskDashboardContent;
