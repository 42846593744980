import { i18nChangeLanguage, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { TENANT_ID } from 'config';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onlyUpdateForKeys } from 'recompose';
import { compose } from 'redux';
import { requestSwitchAccount } from '~app/auth/actions';
import ConfirmationButtonModal from '~app/common/components/ConfirmationButtonModal';
import { getRefreshTokenExpiry, signOut } from '../../../../auth';
import { getAuthRefreshToken } from '../../../../auth/selectors';
import { LoadingScreenBlocker } from '../../../../common/components/LoadingScreenBlocker';
import { useMyContact } from '../../../../crm/components/use-my-contact';
import UserSkeleton from './UserSkeleton';
import UserDesktop from './desktop/UserDesktop';
import UserMobile from './mobile/UserMobile';
import { reloadPage } from './utils';
const confirmModal = ConfirmationButtonModal.confirm;
const User = ({ menu, isMobile, className }) => {
    const { t } = useI18n();
    const { contact } = useMyContact();
    const [isSwitchUserLoading, setIsSwitchUserLoading] = useState(false);
    const refreshTokenExpiry = useSelector(getRefreshTokenExpiry);
    const refreshToken = useSelector((s) => getAuthRefreshToken(s));
    const signOutDispatch = useDispatch();
    const switchUserDispatch = useDispatch();
    const switchUserErrorDispatch = useDispatch();
    const memoizedSignOut = () => signOutDispatch(signOut(TENANT_ID, (contact && contact.entityId) || '', isNaN(refreshTokenExpiry) ? new Date(refreshTokenExpiry).getTime() : refreshTokenExpiry || 0));
    const switchAccount = useCallback(async (entityId) => {
        if (!refreshToken) {
            switchUserErrorDispatch(error({
                title: t('ERROR'),
                message: t('MAIN_MENU__SWITCH_USER_ERROR')
            }));
            return;
        }
        try {
            setIsSwitchUserLoading(true);
            await switchUserDispatch(requestSwitchAccount(entityId, refreshToken));
            // ? This timeout is set so that the redux persister has time to save the data in the storage
            // ? before the page is reloaded. Otherwise, the storage will be loaded by the previous user
            setTimeout(reloadPage, 10);
        }
        catch (e) {
            switchUserErrorDispatch(error({
                title: t('ERROR'),
                message: t('MAIN_MENU__SWITCH_USER_ERROR')
            }));
            setIsSwitchUserLoading(false);
        }
    }, [refreshToken, switchUserDispatch, switchUserErrorDispatch, t]);
    const onSwitchUser = useCallback((userIdForSwitching) => {
        confirmModal({
            zIndex: 1001,
            content: React.createElement("div", { className: "p-3" }, t('MAIN_MENU__SWITCH_USER_CONFIRM')),
            okText: t('COMMON__OK'),
            cancelText: t('COMMON_CANCEL'),
            onOk: () => switchAccount(userIdForSwitching),
            width: 520
        }, t);
    }, [switchAccount, t]);
    return (React.createElement(React.Fragment, null,
        contact &&
            ((isMobile && (React.createElement(UserMobile, { className: className || '', signOut: memoizedSignOut, menu: menu, onSwitchUser: onSwitchUser }))) || (React.createElement(UserDesktop, { className: className || '', changeLanguage: (lang) => {
                    signOutDispatch(i18nChangeLanguage(lang));
                }, signOut: memoizedSignOut, onSwitchUser: onSwitchUser }))),
        !contact && React.createElement(UserSkeleton, null),
        React.createElement(LoadingScreenBlocker, { visible: isSwitchUserLoading, message: t('MAIN_MENU__SWITCH_USER_LOADING') })));
};
export default compose(withRouter, onlyUpdateForKeys(['isMobile', 'menu']))(User);
