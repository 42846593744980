import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { media } from '@hypercharge/hyper-react-base/lib/styling';
import { find, get, isString, map, partition, sortBy, toLower, toUpper } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IoMdGitNetwork } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { getAuthJwt, getUserTenantPermissions, hasPermission } from '../../../../auth';
import { TITLE_PROPERTY_ID } from '../../../../cms';
import { Breadcrumbs } from '../../../../cms/common/breadcrumbs';
import ItemsContainer from '../../../../cms/data/components/items/ItemsContainer';
import { SELECTED_VIEWS_STORAGE_KEY } from '../../../../common/components/data-table/ViewSelector';
import HeightContext, { HeightContextProvider } from '../../../../common/components/height-context';
import { CollapsibleContainer } from '../../../../common/components/side-menu';
import { useBrowserBreakpoints } from '../../../../common/components/with-browser-breakpoints';
import { useStorage } from '../../../../common/context/StorageContext';
import { getConfigsState } from '../../../../common/reducers/rootSelector';
import { APP_ADMIN_PERMISSION, BROWSE_PATH, SETTINGS_PATH, VIEW_PATH } from '../../../../common/utils/constants';
import { getEntityBrowseUrl, getEntityViewUrl } from '../../../../common/utils/url';
import { fetchConfig, getConfig, isFetchConfigPending } from '../../../../configs';
import { useMyContact } from '../../../../crm/components/use-my-contact';
import { ParentOwnerTranslationKeyContext } from '../../../../groups';
import { getMenu, getNestedMenuCmsReferences } from '../../../../header';
import { CMS_KEY, SETTINGS_KEY } from '../../../../settings';
import EditableViewTitleComponent from '../../../../views/components/EditableViewTitleComponent';
import { getStoredViewInLocalStorage, isDefaultViewId } from '../../../../views/utils';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID } from '../../../common/utils/constants';
import useDisplayProcessMetaList from '../../../hooks/useDisplayProcessMetaList';
import ProcessMetaRoutes from './ProcessMetaRoutes';
const Routes = ({ match: { url: currentUrl } }) => {
    const { t } = useI18n();
    const { isDesktop } = useBrowserBreakpoints();
    const { contactId } = useMyContact();
    const [viewIdMapInStorage] = useStorage({
        key: SELECTED_VIEWS_STORAGE_KEY,
        defaultValue: {}
    });
    const createProcessPath = `${currentUrl}/create`;
    const configsState = useSelector(getConfigsState);
    const cmsConfig = getConfig(configsState, SETTINGS_KEY, CMS_KEY);
    const isCmsConfigPending = isFetchConfigPending(configsState, SETTINGS_KEY, CMS_KEY);
    const userPermissions = useSelector((s) => getUserTenantPermissions(getAuthJwt(s)));
    const dispatchFetchConfig = useDispatch();
    const { data: displayProcessMetaList, isPending, isFailed, isAvailable: isAvailableProcessMetaList } = useDisplayProcessMetaList();
    const [links, setLinks] = useState([]);
    const [currentBreadcrumbText, setCurrentBreadcrumbText] = useState('');
    const [extraCountForBreadcrumbsComponent, setExtraCountForBreadcrumbsComponent] = useState();
    const getProcessTitle = useCallback((processMetaId) => get(find(displayProcessMetaList, { processMetaId }), TITLE_PROPERTY_ID) ||
        (isPending ? '...' : t('NO_TITLE')), [displayProcessMetaList, isPending, t]);
    const hasAdminPermission = hasPermission(userPermissions, APP_ADMIN_PERMISSION);
    useEffect(() => {
        if (!cmsConfig && !isCmsConfigPending) {
            void dispatchFetchConfig(fetchConfig(SETTINGS_KEY, CMS_KEY));
        }
    }, [cmsConfig, isCmsConfigPending, dispatchFetchConfig]);
    const getSideMenuSectionTitle = useCallback((definitionId) => {
        const headerMenu = getMenu({
            availableDefinitionIds: map(displayProcessMetaList, 'processMetaId'),
            contactId,
            hasPermission: (permission) => hasPermission(userPermissions, permission),
            viewIdMapInStorage
        });
        const headerMenuReference = find(getNestedMenuCmsReferences(headerMenu), {
            definitionId
        });
        if (headerMenuReference) {
            return headerMenuReference.menuTitle;
        }
        if ((cmsConfig?.data?.featuredDefinitionIds || []).includes(definitionId)) {
            return t('SIDE_MENU__FEATURED_ITEMS');
        }
        return null;
    }, [
        cmsConfig?.data?.featuredDefinitionIds,
        contactId,
        displayProcessMetaList,
        t,
        userPermissions,
        viewIdMapInStorage
    ]);
    const [sideMenuItems, sectionSortOrderIndexMap] = useMemo(() => {
        let items = [];
        const sectionSortOrderIndexMap = {};
        if (!isAvailableProcessMetaList || isCmsConfigPending) {
            return [items, sectionSortOrderIndexMap];
        }
        const [aggregates, otherProcesses] = partition(displayProcessMetaList, ({ processMetaId }) => [ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID].includes(processMetaId));
        items = aggregates.map(({ processMetaId, title = '' }) => {
            const storedViewId = getStoredViewInLocalStorage({
                viewIdMapInStorage,
                contactId,
                definitionId: processMetaId
            });
            const to = storedViewId && !isDefaultViewId(storedViewId)
                ? getEntityViewUrl(processMetaId, storedViewId, currentUrl)
                : getEntityBrowseUrl(processMetaId, currentUrl);
            return {
                key: processMetaId,
                exact: false,
                to,
                title,
                sectionTitle: t('ALL_PROCESSES'),
                icon: IoMdGitNetwork
            };
        });
        items = [
            ...items,
            ...otherProcesses.map(({ processMetaId, idPrefix, title = '', style }) => {
                const storedViewId = getStoredViewInLocalStorage({
                    viewIdMapInStorage,
                    contactId,
                    definitionId: processMetaId
                });
                const to = storedViewId && !isDefaultViewId(storedViewId)
                    ? getEntityViewUrl(processMetaId, storedViewId, currentUrl)
                    : getEntityBrowseUrl(processMetaId, currentUrl);
                let sideMenuItem = {
                    key: processMetaId,
                    exact: false,
                    to,
                    title,
                    subTitle: toUpper(idPrefix),
                    icon: IoMdGitNetwork,
                    style
                };
                if (hasAdminPermission) {
                    sideMenuItem = {
                        ...sideMenuItem,
                        toSettings: `${currentUrl}/${processMetaId}${SETTINGS_PATH}`
                    };
                }
                const sectionTitle = getSideMenuSectionTitle(processMetaId);
                if (sectionTitle) {
                    sideMenuItem = { ...sideMenuItem, sectionTitle };
                }
                return sideMenuItem;
            })
        ];
        sectionSortOrderIndexMap[t('ALL_PROCESSES')] = 1;
        sectionSortOrderIndexMap[t('SIDE_MENU__FEATURED_ITEMS')] = 2;
        return [
            sortBy(items, [(o) => isString(o.title) && toLower(o.title)]),
            sectionSortOrderIndexMap
        ];
    }, [
        isAvailableProcessMetaList,
        isCmsConfigPending,
        displayProcessMetaList,
        t,
        contactId,
        viewIdMapInStorage,
        currentUrl,
        hasAdminPermission,
        getSideMenuSectionTitle
    ]);
    const getContentJsx = useCallback((contentHeight) => (React.createElement(CollapsibleContainer, { sectionName: "workflows", title: t('WORKFLOWS_PROCESSES_NAV_LINK'), createPath: hasAdminPermission ? createProcessPath : undefined, headerIcon: IoMdGitNetwork, items: sideMenuItems, sectionSortOrderIndexMap: sectionSortOrderIndexMap, isFailed: isFailed, isLoading: isPending || isCmsConfigPending, withSearch: true, withFeaturedEntitiesSwitch: true },
        React.createElement(ParentOwnerTranslationKeyContext.Provider, { value: "WORKFLOWS_SETTINGS_PROCESS_OWNER" },
            React.createElement(Container, null,
                React.createElement(Switch, null,
                    hasAdminPermission && (React.createElement(Route, { path: [createProcessPath, `${currentUrl}/:processMetaId${SETTINGS_PATH}`], render: (props) => {
                            const { processMetaId } = props.match.params;
                            return (React.createElement(ProcessMetaRoutes, { contentHeight: contentHeight, processMetaId: processMetaId, setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, baseUrl: processMetaId ? `${currentUrl}/${processMetaId}` : `${currentUrl}`, url: props.match.url }));
                        } })),
                    React.createElement(Route, { exact: true, path: [
                            `${currentUrl}/:processMetaId${BROWSE_PATH}`,
                            `${currentUrl}/:processMetaId${VIEW_PATH}/:viewId`
                        ], render: (props) => {
                            const { processMetaId } = props.match.params;
                            return (React.createElement(ItemsContainer, { baseUrl: `${currentUrl}/${processMetaId}`, canDelete: !!find(displayProcessMetaList, { processMetaId, canDelete: true }), canRun: !!find(displayProcessMetaList, { processMetaId, canExecute: true }), definitionId: processMetaId, key: processMetaId, processTitle: get(find(displayProcessMetaList, { processMetaId }), 'title'), setCurrentBreadcrumbText: setCurrentBreadcrumbText, setExtraCountForBreadcrumbsComponent: setExtraCountForBreadcrumbsComponent, setLinks: setLinks, ...props }));
                        } }),
                    React.createElement(Redirect, { to: getEntityBrowseUrl(ALL_PROCESSES_DEFINITION_ID, currentUrl) })))))), [
        t,
        hasAdminPermission,
        createProcessPath,
        sideMenuItems,
        sectionSortOrderIndexMap,
        isFailed,
        isPending,
        isCmsConfigPending,
        currentUrl,
        displayProcessMetaList
    ]);
    return (React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: "BreadcrumbsContainer", id: "breadcrumbs" },
            React.createElement(Switch, null,
                React.createElement(Route, { path: [`${currentUrl}/:definitionId${SETTINGS_PATH}`, `${currentUrl}/create`], render: () => React.createElement(Breadcrumbs, { links: links, currentBreadcrumb: currentBreadcrumbText }) }),
                React.createElement(Route, { path: [
                        `${currentUrl}/:processMetaId${BROWSE_PATH}`,
                        `${currentUrl}/:processMetaId${VIEW_PATH}/:viewId/:entityId`,
                        `${currentUrl}/:processMetaId${VIEW_PATH}/:viewId`
                    ], render: (props) => {
                        const { processMetaId, viewId, entityId } = props.match.params;
                        return (React.createElement(Breadcrumbs, { currentBreadcrumb: React.createElement(EditableViewTitleComponent, { definitionId: processMetaId, entityId: entityId, viewId: viewId, totalItemsCount: extraCountForBreadcrumbsComponent, entityTitle: getProcessTitle(processMetaId) }), links: links }));
                    } }))),
        React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: otherHeightInPx },
            React.createElement(HeightContext.Consumer, null, ({ contentHeight }) => getContentJsx(isDesktop ? contentHeight : undefined)))));
};
export default Routes;
//
// Utils
//
const Container = styled.div `
  position: relative;

  ${media.xl} {
    margin-right: 0.5rem;
  }
`;
const otherElementSelectors = ['#header', '#featured-entities', '#breadcrumbs'];
const otherHeightInPx = 0;
