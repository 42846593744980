import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { getPropertyStyles } from '../../../../../common/components/InlineEditor/utils';
import { TablePropertyField } from '../../../../../common/components/TablePropertiesFields';
import { useDisplayTenant } from '../../../../../tenant/useDisplayTenant';
import { getAllProperties } from '../../../../common/utils/utils';
import useDisplayData from '../../../../hooks/useDisplayData';
import ValueHistoryDrawer from '../ValueHistoryDrawer';
import styles from './TableField.module.scss';
export const TableField = ({ order, handleSetShowExpandedViews, showExpandedView, updatePropertyData, updateNestedPropertyData, bulkUpdateNestedItems, entityId, definitionId, field, requiredFieldsConfig, permissionFieldsConfig, canEdit, uploadFiles, doubleCol = false, fromExpandedView = false, additionalColumns, isFullWidth, fileCarouselHeight, className = '' }) => {
    const { tenantTheme: { hidePropertyIcon } } = useDisplayTenant();
    const [selectedPropertyId, setSelectedPropertyId] = useState(null);
    const { data: displayDataList } = useDisplayData({ definitionId, entityId });
    const item = useMemo(() => getAllProperties(displayDataList), [displayDataList]);
    const hideHistory = useCallback(() => setSelectedPropertyId(null), []);
    const selectProperty = useCallback((id) => {
        setSelectedPropertyId(id);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { order }, className: cn({
                [styles.wrapper]: doubleCol,
                'col-12': isFullWidth,
                'col-md-6 col-sm-12 col-xs-12': !isFullWidth
            }, className) },
            React.createElement(TablePropertyField, { uploadFiles: uploadFiles, fromExpandedView: fromExpandedView, handleSetShowExpandedViews: handleSetShowExpandedViews, showExpandedView: showExpandedView, showIcon: !hidePropertyIcon, updatePropertyData: updatePropertyData, selectProperty: selectProperty, entityId: entityId, definitionId: definitionId, field: field, style: getPropertyStyles(field.meta, item), requiredFieldsConfig: requiredFieldsConfig, canEdit: canEdit, permissionFieldsConfig: permissionFieldsConfig, updateNestedPropertyData: updateNestedPropertyData, bulkUpdateNestedItems: bulkUpdateNestedItems, additionalColumns: additionalColumns, fileCarouselHeight: fileCarouselHeight })),
        selectedPropertyId && (React.createElement(ValueHistoryDrawer, { open: !!selectedPropertyId, definitionId: definitionId, entityId: entityId, propertyId: selectedPropertyId, onSave: updatePropertyData, onClose: hideHistory }))));
};
export default TableField;
