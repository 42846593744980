import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { Body, Cell, DeleteConfirmationButton, Header, HeaderCell, InformationRow, Row, Table } from '@hypercharge/hyper-react-base/lib/common/table';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import styled, { withTheme } from 'styled-components';
import { Breadcrumbs } from '../../../cms/common/breadcrumbs';
import { StyledWrapper } from '../../../common/utils/stylingUtils';
import { ADMIN_PATH } from '../../utils/constants';
const EditLink = styled(Link) `
  display: flex;
  align-items: center;
  margin: 0 0.3rem;

  svg {
    fill: var(--primaryColor-6);
    color: var(--neutralColor-6);
  }

  :hover {
    svg {
      fill: var(--primaryColor-5);
    }
  }
`;
const ActionsContainer = styled.div `
  margin-bottom: 1rem;
`;
const DefinitionsList = ({ isPending, theme, definitions, deleteDefinition }) => {
    const { t, language } = useI18n();
    return (React.createElement(React.Fragment, null,
        React.createElement(Breadcrumbs, { links: [{ text: t('ADMIN_NAV_LINK'), to: `${ADMIN_PATH}` }], currentBreadcrumb: t('SETTINGS_DEFINITIONS_NAV_LINK') }),
        React.createElement(StyledWrapper, null,
            React.createElement("div", { className: "cp-c-row cp-c-align-end-center" },
                React.createElement(ActionsContainer, { className: "cp-c-row cp-c-align-end-center" },
                    React.createElement(Button, { to: `${ADMIN_PATH}/definitions/add` },
                        "+ ",
                        t('SETTINGS_DEFINITIONS_ADD_DEFINITION')))),
            React.createElement(Table, null,
                React.createElement(Header, null,
                    React.createElement(HeaderCell, null, t('SETTINGS__DEFINITION')),
                    React.createElement(HeaderCell, { className: "actions" })),
                React.createElement(Body, { loading: isPending, cellLength: 2 }, definitions.length === 0 && !isPending ? (React.createElement(InformationRow, null, t('SETTINGS__DEFINITIONS_NO_DEFINITIONS'))) : (definitions.map(({ id, labels }) => (React.createElement(Row, { key: id, to: `${ADMIN_PATH}/definitions/${id}/properties` },
                    React.createElement(Cell, null, getTranslation(labels, language)),
                    React.createElement(Cell, { className: "actions" },
                        React.createElement("div", null,
                            React.createElement(EditLink, { to: `${ADMIN_PATH}/definitions/${id}/edit`, onClick: (e) => e.stopPropagation() },
                                React.createElement(MdEdit, { size: 24 })),
                            React.createElement(DeleteConfirmationButton, { onClick: () => deleteDefinition(id) }))))))))))));
};
export default compose(withTheme)(DefinitionsList);
