import LoadingIndicator from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import React from 'react';
import styled from 'styled-components';
import formUtilsStyles from './formUtils.module.scss';
export const FieldsContainerTitle = styled.label `
  display: block;
  font-weight: 500;
  margin: ${({ small }) => (small ? '1rem 0 0.5rem' : '1.5rem 0 1rem')};
`;
export const FieldsContainer = styled.div `
  margin: -0.5rem;
  padding: 0 !important;
`;
const LoadingContainer = styled.div `
  height: 98px;
  width: 100%;
`;
export const LoadingBlock = () => (React.createElement(LoadingContainer, { className: "cp-c-row cp-c-align-center-center" },
    React.createElement(LoadingIndicator, null)));
export const prepareGroupPermissionsForSubmit = (definition) => {
    if (!definition?.permissions?.view) {
        return definition;
    }
    // enable view permission if present in viewGroups
    const permissions = {
        ...definition.permissions,
        view: Object.entries(definition.permissions.view).reduce((permissions, [groupId, permission]) => {
            if (permission.restrictions?.filters.length && definition.viewGroups) {
                permissions[groupId] = {
                    ...permission,
                    isEnabled: definition.viewGroups.includes(groupId)
                };
            }
            return permissions;
        }, {})
    };
    return {
        ...definition,
        permissions
    };
};
export const getIsFormHasError = (meta) => !!meta && (meta?.touched || ('submitFailed' in meta && meta?.submitFailed)) && !!meta?.error;
export const ErrorLabel = ({ position, error, show }) => show ? React.createElement("div", { className: `${formUtilsStyles.errorLabel} ${position}-error` }, error) : null;
