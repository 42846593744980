import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Tooltip } from 'antd';
import { find } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { QuickAddItemContainer, TITLE_PROPERTY_ID, fetchItemsById, getFlattenedDisplayDataList, getItem, getItemRepresentation, isItemAvailable, isItemPending, searchItems } from '../../../cms';
import { useEntityDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import { useFieldWrapper } from '../../../cms/data/components/item-property/FieldElements/FieldWrapper';
import { isTextDisplayData } from '../../../cms/data/types';
import { canCreateEntity } from '../../../cms/data/utils';
import useDisplayItemMeta from '../../../cms/hooks/useDisplayItemMeta';
import { GenericEntitySelector } from './GenericEntitySelector';
export const DEFAULT_SELECT_OPTIONS_SIZE = 6;
export const MAX_CMS_ITEMS_LIMIT = 500;
const CustomEntitySelector = ({ allowClear = true, onDrawerStateChange, onModalOpen, showFilter, filtersQuery, sortBy, definitionId, placeholder, searchEntities, autoFocus = true, disabled = false, showCreateOnInitial, withoutCreateNew = false, withoutSearchResults = false, popupMatchSelectWidth, extraPayloadProps, ignoreOnEnterKeyDown = false, requiredFieldsConfig, permissionFieldsConfig, quickAddItemForm, setNeededSpaceOnBottom, hasNeededSpacesOnBottom, simpleClear, excludeValuesFromSearch, getOptionId, getEntity, getOptionContent, isEntityAvailable, isEntityPending, fetchEntity, withMaxTagCount, handlePaste, ...otherProps }) => {
    const { t, language } = useI18n();
    const fieldWrapperCtx = useFieldWrapper();
    const [state, setState] = useState({
        isDrawerOpen: false,
        initialEntityTitle: ''
    });
    const { data: displayItemMeta, isLoading: isPending } = useDisplayItemMeta({ definitionId });
    const { displayDataWithHidden } = useEntityDisplayData(definitionId);
    const onClose = useCallback(() => {
        setState({
            isDrawerOpen: false,
            initialEntityTitle: ''
        });
        onDrawerStateChange && onDrawerStateChange(false);
    }, [onDrawerStateChange]);
    const onCreateNew = useCallback((query) => {
        setState({
            isDrawerOpen: true,
            initialEntityTitle: query
        });
        onDrawerStateChange && onDrawerStateChange(true);
    }, [onDrawerStateChange]);
    useEffect(() => {
        showCreateOnInitial && onCreateNew('');
    }, [onCreateNew, showCreateOnInitial]);
    const [open, setOpen] = useState(false);
    const onFinish = useCallback((createdEntityId) => {
        const { multiple, input } = otherProps;
        input?.onChange(multiple ? [...(input?.value || []), createdEntityId] : createdEntityId);
        onClose();
        setOpen(false);
    }, [otherProps, onClose]);
    const customEntityName = isPending
        ? '...'
        : displayItemMeta
            ? displayItemMeta.title
            : definitionId;
    const canCreate = !withoutCreateNew && canCreateEntity(displayItemMeta);
    const createNewEntityTitle = `${t('CREATE_NEW')}: ${customEntityName}`;
    const isEntityUnavailable = !displayItemMeta;
    const QuickAddItemForm = quickAddItemForm || QuickAddItemContainer;
    const titleTranslations = useMemo(() => {
        const displayData = find(getFlattenedDisplayDataList(displayDataWithHidden), {
            propertyId: TITLE_PROPERTY_ID
        });
        return isTextDisplayData(displayData)
            ? displayData.meta.translations && displayData.meta.translations
            : undefined;
    }, [displayDataWithHidden]);
    const selectorNode = useMemo(() => (
    // @ts-expect-error
    React.createElement(GenericEntitySelector, { ...otherProps, allowClear: allowClear, open: open, setOpen: setOpen, withMaxTagCount: withMaxTagCount, disabled: disabled || isEntityUnavailable, autoFocus: autoFocus, placeholder: placeholder || `${t('SEARCH')} ${customEntityName}`, getOptionId: getOptionId ? getOptionId : (e) => ('entityId' in e ? e.entityId : ''), getOptionContent: getOptionContent
            ? getOptionContent
            : (entity) => getItemRepresentation(entity, titleTranslations && titleTranslations[language]), getEntity: getEntity ? getEntity : (s, id) => getItem(s, definitionId, id), isEntityAvailable: isEntityAvailable
            ? isEntityAvailable
            : (s, id) => isItemAvailable(s, definitionId, id), isEntityPending: isEntityPending
            ? isEntityPending
            : (s, id) => isItemPending(s, definitionId, id), searchEntities: searchEntities
            ? searchEntities
            : (query, numberOfSelected) => searchItems(definitionId, {
                sortBy: sortBy ?? [],
                query: filtersQuery || {
                    condition: ConditionType.or,
                    filters: excludeValuesFromSearch
                        ? [
                            {
                                condition: ConditionType.and,
                                filters: excludeValuesFromSearch.map((value) => ({
                                    field: 'entityId',
                                    operator: FilterOperatorTypes.isNot,
                                    data: value
                                }))
                            }
                        ]
                        : []
                },
                fullText: query.textSearchQuery || '',
                offset: 0,
                languageCode: language,
                ...(extraPayloadProps || {}),
                limit: Math.min(MAX_CMS_ITEMS_LIMIT, (extraPayloadProps?.limit ?? DEFAULT_SELECT_OPTIONS_SIZE) + numberOfSelected),
                responseFields: titleTranslations && titleTranslations[language]
                    ? [TITLE_PROPERTY_ID, titleTranslations[language]]
                    : [TITLE_PROPERTY_ID],
                fullTextFields: titleTranslations && titleTranslations[language]
                    ? [TITLE_PROPERTY_ID, titleTranslations[language]]
                    : [TITLE_PROPERTY_ID]
            }), handlePaste: handlePaste, fetchEntity: fetchEntity ? fetchEntity : (id) => fetchItemsById(definitionId, [id]), onCreateNew: (canCreate && onCreateNew) || undefined, onModalOpen: onModalOpen, showFilter: showFilter, filtersCount: filtersQuery?.filters.length, createNewEntityPlaceholder: createNewEntityTitle, createNewEntityPlaceholderIsLoading: isPending, withoutSearchResults: withoutSearchResults, popupMatchSelectWidth: popupMatchSelectWidth, ignoreOnEnterKeyDown: ignoreOnEnterKeyDown, setNeededSpaceOnBottom: setNeededSpaceOnBottom, hasNeededSpacesOnBottom: hasNeededSpacesOnBottom, simpleClear: simpleClear })), [
        otherProps,
        allowClear,
        open,
        withMaxTagCount,
        disabled,
        isEntityUnavailable,
        autoFocus,
        placeholder,
        t,
        customEntityName,
        getOptionId,
        getOptionContent,
        getEntity,
        isEntityAvailable,
        isEntityPending,
        searchEntities,
        handlePaste,
        fetchEntity,
        canCreate,
        onCreateNew,
        onModalOpen,
        showFilter,
        filtersQuery,
        createNewEntityTitle,
        isPending,
        withoutSearchResults,
        popupMatchSelectWidth,
        ignoreOnEnterKeyDown,
        setNeededSpaceOnBottom,
        hasNeededSpacesOnBottom,
        simpleClear,
        titleTranslations,
        language,
        definitionId,
        sortBy,
        excludeValuesFromSearch,
        extraPayloadProps
    ]);
    useEffect(() => {
        if (fieldWrapperCtx) {
            fieldWrapperCtx.setWrapperDisabled(disabled || isEntityUnavailable);
        }
    }, [fieldWrapperCtx, disabled, isEntityUnavailable]);
    return (React.createElement(React.Fragment, null,
        canCreate && (React.createElement(QuickAddItemForm, { definitionId: definitionId, onFinish: onFinish, onCancel: onClose, requiredFieldsConfig: requiredFieldsConfig, isDrawerOpen: state.isDrawerOpen, drawerTitle: createNewEntityTitle, 
            // @ts-expect-error TODO https://trello.com/c/SShxXlB2/2333-override-permission-fields-in-task-dont-work-for-create-item
            permissionFieldsConfig: permissionFieldsConfig })),
        isEntityUnavailable ? (React.createElement(Tooltip, { title: t('CMS_ENTITY_UNAVAILABLE', {
                entity: definitionId
            }) },
            React.createElement("div", { className: "CustomEntitySelector__tooltip-inner" }, selectorNode))) : (selectorNode)));
};
export default CustomEntitySelector;
