import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { replace } from '@hypercharge/hyper-react-base/lib/router';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Select } from '../../../../../common/components/Select';
import { SELECTED_VIEWS_STORAGE_KEY } from '../../../../../common/components/data-table/ViewSelector';
import { useStorage } from '../../../../../common/context/StorageContext';
import { getEntityBrowseUrl, getEntityViewUrl } from '../../../../../common/utils/url';
import { useMyContact } from '../../../../../crm/components/use-my-contact';
import { getStoredViewInLocalStorage, isDefaultViewId } from '../../../../../views/utils';
import { ACTIVITY_YOUR_DASHBOARD, ACTIVITY_YOUR_DASHBOARD_PATH, ALL_TASKS_DEFINITION_ID, WORKFLOWS_PATH, WORKFLOWS_YOUR_DASHBOARD, WORKFLOWS_YOUR_DASHBOARD_PATH } from '../../../../common/utils/constants';
var TaskType;
(function (TaskType) {
    TaskType["workflows"] = "workflows";
    TaskType["activity"] = "activity";
})(TaskType || (TaskType = {}));
const SelectTaskType = () => {
    const { t } = useI18n();
    const { contactId } = useMyContact();
    const [viewIdMapInStorage] = useStorage({
        key: SELECTED_VIEWS_STORAGE_KEY,
        defaultValue: {}
    });
    const location = useLocation();
    const value = useMemo(() => {
        if (location.pathname.includes(WORKFLOWS_YOUR_DASHBOARD_PATH)) {
            return TaskType.workflows;
        }
        if (location.pathname.includes(ACTIVITY_YOUR_DASHBOARD_PATH)) {
            return TaskType.activity;
        }
        return TaskType.workflows;
    }, [location.pathname]);
    const dispatch = useDispatch();
    const options = useMemo(() => [
        {
            value: TaskType.workflows,
            label: t('WORKFLOWS_NAV_LINK')
        },
        { value: TaskType.activity, label: t('ACTIVITY') }
    ], [t]);
    const handleChange = (value) => {
        if (value === TaskType.workflows) {
            const storedViewId = getStoredViewInLocalStorage({
                definitionId: WORKFLOWS_YOUR_DASHBOARD,
                pathname: WORKFLOWS_YOUR_DASHBOARD_PATH,
                contactId,
                viewIdMapInStorage
            });
            const to = storedViewId && !isDefaultViewId(storedViewId, true)
                ? getEntityViewUrl(WORKFLOWS_YOUR_DASHBOARD, storedViewId, WORKFLOWS_PATH)
                : getEntityBrowseUrl(ALL_TASKS_DEFINITION_ID, WORKFLOWS_PATH);
            dispatch(replace(to));
            return;
        }
        const storedViewId = getStoredViewInLocalStorage({
            definitionId: ACTIVITY_YOUR_DASHBOARD,
            pathname: ACTIVITY_YOUR_DASHBOARD_PATH,
            contactId,
            viewIdMapInStorage
        });
        const to = storedViewId && !isDefaultViewId(storedViewId, true)
            ? getEntityViewUrl(ACTIVITY_YOUR_DASHBOARD, storedViewId, WORKFLOWS_PATH)
            : getEntityBrowseUrl(ACTIVITY_YOUR_DASHBOARD, WORKFLOWS_PATH);
        dispatch(replace(to));
    };
    return (React.createElement(Select, { className: "ant-select-transparent", options: options, value: value, onChange: handleChange, popupClassName: "antd-dropdown-popup-styled2" }));
};
export default SelectTaskType;
