import { FilterOperatorTypes, RowHeight } from '@hypercharge/portal-utils';
import { debounce, toString } from 'lodash';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TITLE_PROPERTY_ID } from '../../../cms';
import { getPropertyById } from '../../../cms/common/utils/utils';
import { isEntityDisplayData } from '../../../cms/data/types';
import { addToLocalStorage, getFromLocalStorage } from '../../utils/localStorageUtils';
export const StyledLink = styled(Link) `
  font-weight: inherit !important;
  border: 0 !important;
  transition: color 200ms ease;
  word-break: break-word;
  font-size: 14px;
  line-height: 16px;
  color: var(--primaryColor-6);

  &:hover {
    color: var(--primaryColor-5);
  }
`;
export const ScExternalLink = StyledLink.withComponent('a');
export const ScExternalLinkIcon = styled(FaExternalLinkAlt) `
  margin-left: 0.125rem;
`;
const storageKeyPrefix = 'DataTable:';
const defaultCustomizations = { columns: [] };
const getTableStorageKey = () => `${storageKeyPrefix}${window.location.pathname}`;
const actualStoreTableCustomizations = ({ columns, pageSize }) => {
    const storageKey = getTableStorageKey();
    const existingValues = getFromLocalStorage(storageKey, defaultCustomizations);
    addToLocalStorage(storageKey, {
        columns: columns || existingValues?.columns || undefined,
        pageSize: pageSize || existingValues?.pageSize || undefined // ? This stores the pageSize of the data tables
    });
};
export const storeTableCustomizations = debounce(actualStoreTableCustomizations, 200);
export const getStoredTableCustomizations = () => getFromLocalStorage(getTableStorageKey(), defaultCustomizations);
export const TABLE_EXTRA_COLS = ['select', 'open-item'];
export const ROW_HEIGHT_TYPES_LIST = [RowHeight.SMALL, RowHeight.MEDIUM, RowHeight.TALL];
export const ROW_HEIGHT_TYPES_TO_PIXELS = {
    [RowHeight.SMALL]: 48,
    [RowHeight.MEDIUM]: 72,
    [RowHeight.TALL]: 96
};
export const getFieldGroupingRow = (rowGrouping, displayData) => {
    if (!rowGrouping) {
        return;
    }
    const groupByProperty = getPropertyById(displayData, rowGrouping);
    return isEntityDisplayData(groupByProperty)
        ? `${groupByProperty.propertyId}.${TITLE_PROPERTY_ID}`
        : rowGrouping;
};
export const replaceEscapedChars = (str) => {
    const mapForReplacing = {
        gt: '>',
        amp: '&'
    };
    let newString = str;
    for (const key of Object.keys(mapForReplacing)) {
        newString = newString.replace(new RegExp(`&${key};`, 'g'), mapForReplacing[key]);
    }
    return newString;
};
export const getAdditionalFiltersForSearchInGroup = (fieldGroupBy, rowGrouping, row) => {
    if (!(fieldGroupBy && rowGrouping)) {
        return [];
    }
    if (!row) {
        return [];
    }
    const value = row.getValue(rowGrouping);
    let filter = {
        field: fieldGroupBy,
        operator: FilterOperatorTypes.empty
    };
    if (value !== undefined) {
        filter = {
            field: fieldGroupBy,
            operator: FilterOperatorTypes.is,
            data: toString(value)
        };
    }
    return [filter];
};
