import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { DatePicker } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { bytesToSize } from '~app/common/utils/converters';
import { Content } from '../../../common/utils/stylingUtils';
import { SETTINGS_PATH } from '../../constants';
import { styledSectionCardHeaderStyle } from '../common/stylingUtils';
import StatisticBox from './StatisticBox';
import styles from './Usage.module.scss';
const { RangePicker } = DatePicker;
const Usage = ({ contentHeight, setLinks, setCurrentBreadcrumbText }) => {
    const [range, setRange] = useState([
        dayjs().startOf('month').valueOf(),
        dayjs().valueOf()
    ]);
    const { t } = useI18n();
    useEffect(() => {
        setLinks([{ text: t('SETTINGS'), to: SETTINGS_PATH }]);
        setCurrentBreadcrumbText(t('STATISTICS'));
    }, [t, setLinks, setCurrentBreadcrumbText]);
    const updateRange = useCallback((dates) => {
        if (dates) {
            const from = dates[0]?.startOf('day').valueOf();
            const to = dates[1]?.endOf('day').valueOf();
            if (from && to) {
                setRange([from, to]);
            }
        }
        else {
            setRange([dayjs().startOf('month').valueOf(), dayjs().valueOf()]);
        }
    }, []);
    return (React.createElement(SectionCard, { className: cn(styles.wrapper, 'mx-2 mw-100'), title: t('STATISTICS'), headerStyle: styledSectionCardHeaderStyle, withoutPadding: true, withoutMargin: true },
        React.createElement(Content, { height: contentHeight },
            React.createElement("div", { className: "p-3" },
                React.createElement("div", { className: "header" },
                    React.createElement("h1", { className: "title text-center" }, t('STATISTICS_TITLE')),
                    React.createElement("h2", { className: "description text-center fs-6" }, t('STATISTICS_DESCRIPTION'))),
                React.createElement("h3", { className: "ms-2 mt-4" }, t('STATISTICS_VARIABLE')),
                React.createElement(RangePicker, { className: "ms-2 mb-2", value: [dayjs(range[0]), dayjs(range[1])], onChange: updateRange, disabledDate: (current) => {
                        // Can not select days after today
                        return current && current >= dayjs().endOf('day');
                    }, presets: [
                        {
                            label: t('STATISTICS_THIS_MONTH'),
                            value: [dayjs().startOf('month'), dayjs().endOf('day')]
                        }
                    ] }),
                React.createElement("div", { className: "container-fluid" },
                    React.createElement("div", { className: "row" },
                        React.createElement(StatisticBox, { className: "col", type: "active-users", range: range, img: "user-count.svg", title: t('STATISTICS_USER_COUNT'), description: t('STATISTICS_USER_COUNT_DESCRIPTION') }),
                        React.createElement(StatisticBox, { className: "col", type: "executed-processes", range: range, img: "process-count.svg", title: t('STATISTICS_PROCESS_COUNT'), description: t('STATISTICS_PROCESS_COUNT_DESCRIPTION') }),
                        React.createElement(StatisticBox, { className: "col", type: "sended-emails", range: range, img: "email-count.svg", title: t('STATISTICS_EMAILS_COUNT'), description: t('STATISTICS_EMAILS_COUNT_DESCRIPTION') }),
                        React.createElement(StatisticBox, { className: "col", type: "sended-sms", range: range, img: "sms-count.svg", title: t('STATISTICS_SMS_COUNT'), description: t('STATISTICS_SMS_COUNT_DESCRIPTION') }),
                        React.createElement(StatisticBox, { className: "col", type: "generated-documents", range: range, img: "documents-count.svg", title: t('STATISTICS_DOCUMENTS_COUNT'), description: t('STATISTICS_DOCUMENTS_COUNT_DESCRIPTION') }))),
                React.createElement("h3", { className: "ms-2 mt-4" }, t('STATISTICS_GLOBAL')),
                React.createElement("div", { className: "container-fluid" },
                    React.createElement("div", { className: "row" },
                        React.createElement(StatisticBox, { className: "col", type: "definitions-count", img: "definition-count.svg", title: t('STATISTICS_DEFINITION_COUNT'), description: t('STATISTICS_DEFINITION_COUNT_DESCRIPTION') }),
                        React.createElement(StatisticBox, { className: "col", type: "entity-count", img: "entity-count.svg", title: t('STATISTICS_ENTITY_COUNT'), description: t('STATISTICS_ENTITY_COUNT_DESCRIPTION') }),
                        React.createElement(StatisticBox, { className: "col", type: "used-storage", format: bytesToSize, img: "storage-usage.svg", title: t('STATISTICS_STORAGE_USAGE'), description: t('STATISTICS_STORAGE_USAGE_DESCRIPTION') }),
                        React.createElement(StatisticBox, { className: "col", type: "bi-users", img: "user-count.svg", title: t('STATISTICS_HYPER_BI_USERS_COUNT'), description: t('STATISTICS_HYPER_BI_USERS_COUNT_DESCRIPTION') })))))));
};
export default Usage;
