import { getTranslation } from '@hypercharge/hyper-react-base/lib/i18n';
import { getTranslationForDescription } from '@hypercharge/hyper-react-base/lib/i18n/i18nUtils';
import { RowHeight } from '@hypercharge/portal-utils';
import { cloneDeep, compact, find, flatten, isEmpty, uniq } from 'lodash';
import styled from 'styled-components';
import { isTextDisplayData } from '~app/cms/data/types';
import { getFlattenedDisplayDataList } from '~app/cms/data/utils';
import { INFINITE_ENTITY_ITEMS_QUERY_KEY } from '~app/cms/hooks/useSearchItems';
import TableInlineEditor from '~app/common/components/data-table/TableInlineEditor';
import { StyledLink } from '~app/common/components/data-table/utils';
import { PropertyTypes } from '~app/common/types';
import queryClient from '~app/common/utils/react-query-client';
import { supportedColumnPropertyTypes, supportedSortByPropertyTypes } from '~app/views';
import { GetColumnHeader } from './GetColumnHeader';
export const EditLink = styled(StyledLink) `
  padding-right: 0.5rem;

  svg {
    width: 16px;
    height: 16px;
  }
`;
export const AVAILABLE_GROUPING_ROWS = [
    PropertyTypes.text,
    PropertyTypes.entity,
    PropertyTypes.email
];
export const isColumnMeta = (meta) => {
    return typeof meta === 'object' && meta !== null && 'headerProps' in meta;
};
export const getItemsTableColumns = ({ columnPropertyIds, viewableEntityProperties, itemLinkColumn, rowGrouping, onChangeRowGrouping, onRemoveColumns, rowHeightType = RowHeight.SMALL, nestedRequiredFieldsConfig, onChangeProperty, permissionFieldsConfig, hideColumn, showGroupedBy }) => {
    const columns = compact(columnPropertyIds.map((propertyId) => find(viewableEntityProperties, { propertyId }))).map((field) => {
        const { propertyId, labels, descriptions, meta, type, canEdit = false } = field;
        const isErrorField = nestedRequiredFieldsConfig
            ? nestedRequiredFieldsConfig?.isErrorField?.(propertyId)
            : false;
        return {
            id: propertyId,
            accessorKey: propertyId,
            header: GetColumnHeader,
            enableResizing: true,
            meta: {
                headerProps: {
                    labels,
                    descriptions,
                    type,
                    meta,
                    rowGrouping,
                    onChangeRowGrouping,
                    onRemoveColumns,
                    hideColumn,
                    showGroupedBy,
                    required: nestedRequiredFieldsConfig
                        ? nestedRequiredFieldsConfig?.isRequiredField?.(propertyId)
                        : false
                },
                cellProps: {
                    meta,
                    type,
                    rowHeightType,
                    canEdit: canEdit || !!permissionFieldsConfig?.isCanEditField(field),
                    invalid: isErrorField,
                    onChangeProperty
                }
            },
            maxWidth: 400,
            minSize: 150,
            sortable: supportedSortByPropertyTypes.includes(type),
            cell: TableInlineEditor
        };
    });
    return columns.length > 0 ? [itemLinkColumn, ...columns] : [];
};
export const getItemsKanbanFields = (fieldPropertyIds, viewableEntityProperties, language) => {
    const fields = compact(fieldPropertyIds.map((propertyId) => find(viewableEntityProperties, { propertyId }))).map(({ propertyId, labels, descriptions, type, meta }) => ({
        id: propertyId,
        label: getTranslation(labels, language),
        labelDescription: getTranslationForDescription(descriptions, language),
        type: supportedColumnPropertyTypes.includes(type) ? type : undefined,
        meta
    }));
    return fields.length ? fields : [];
};
export const getPropertyIdsForConditionalFormatting = (displayDataWithHidden, responseFields) => {
    const ids = [];
    getFlattenedDisplayDataList(displayDataWithHidden).forEach((property) => {
        if (responseFields?.includes(property.propertyId) &&
            !isEmpty(property.meta.conditionalFormatting)) {
            property.meta.conditionalFormatting?.forEach((conditional) => {
                const field = conditional?.filter?.field?.replace('.entityId', '');
                if (field) {
                    ids.push(field);
                }
            });
        }
    });
    return uniq(ids);
};
export const getPropertyIdsForTranslations = (displayDataWithHidden, responseFields, language) => {
    const ids = responseFields || [];
    getFlattenedDisplayDataList(displayDataWithHidden).forEach((property) => {
        if (responseFields?.includes(property.propertyId) &&
            isTextDisplayData(property) &&
            property.meta.translations?.[language]) {
            ids.push(property.meta.translations[language]);
        }
    });
    return uniq(ids);
};
const FETCH_COLUMN_COUNT_QUERY_KEY = 'FETCH_COLUMN_COUNT_QUERY_KEY';
const FETCH_COLUMN_QUERY_KEY = 'FETCH_COLUMN_QUERY_KEY';
export const getKanbanColumnCountQueryKey = (columnId) => `${FETCH_COLUMN_COUNT_QUERY_KEY}${columnId ? `__${columnId}` : ''}`;
export const getKanbanColumnQueryKey = (columnId) => `${FETCH_COLUMN_QUERY_KEY}__${columnId}`;
export const handleKanbanDragInCache = ({ definitionId, sourceColumnId, destinationColumnId, sourceDragIndex }) => {
    const queries = queryClient.getQueryCache().getAll();
    let sourceColumnQuery = undefined;
    let destinationColumnQuery = undefined;
    for (const query of queries) {
        const isInfiniteQuery = query.queryKey.includes(INFINITE_ENTITY_ITEMS_QUERY_KEY);
        const isDefinitionQuery = query.queryKey.includes(definitionId);
        const isSourceQuery = query.queryKey.includes(getKanbanColumnQueryKey(sourceColumnId));
        const isDestinationQuery = query.queryKey.includes(getKanbanColumnQueryKey(destinationColumnId));
        if (!isInfiniteQuery || !isDefinitionQuery) {
            continue;
        }
        if (isSourceQuery) {
            sourceColumnQuery = query;
        }
        if (isDestinationQuery) {
            destinationColumnQuery = query;
        }
    }
    const sourceCachedData = sourceColumnQuery
        ? queryClient.getQueryData(sourceColumnQuery.queryKey)
        : undefined;
    const destinationCachedData = destinationColumnQuery
        ? queryClient.getQueryData(destinationColumnQuery.queryKey)
        : undefined;
    const cardsInSourceColumn = flatten(sourceCachedData?.pages.map((page) => page?.results || []));
    const item = cardsInSourceColumn[sourceDragIndex];
    const newSourceList = cloneDeep(sourceCachedData);
    const newDestinationList = cloneDeep(destinationCachedData);
    for (const newSourcePage of newSourceList?.pages || []) {
        const foundIndex = newSourcePage.results.findIndex((result) => result.entityId === item.entityId);
        if (foundIndex !== -1) {
            newSourcePage.results.splice(foundIndex, 1);
        }
    }
    const lastDestinationPageIndex = (newDestinationList?.pages || []).length - 1;
    newDestinationList?.pages[lastDestinationPageIndex].results.push(item);
    return {
        sourceCachedData,
        destinationCachedData,
        newSourceList,
        newDestinationList,
        sourceColumnQuery,
        destinationColumnQuery,
        item
    };
};
