import { flexRender, getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import { isNumber, isString } from 'lodash';
import React, { memo, startTransition, useEffect, useMemo, useRef, useState } from 'react';
import ReactTableBodyVirtualized, { ReactTableBodyVirtualizedWindow } from './ReactTableBodyVirtualized';
import { TABLE_EXTRA_COLS } from './utils';
const ReactTable = (props) => {
    const { className = '', data, columns, loading = false, LoadingComponent, NoDataComponent, rowHeight, selectable = true, selectAll, isSelected, toggleSelection, toggleAll, onSortedChange, sorted, manualPagination = true, tableContainerScrollerRef, windowScroller, pageSize, pageIndex, rowGrouping, setExpandedMap, getRowCanExpand, getRowComponent, getRowEstimateSize } = props;
    const tableContainerRef = useRef(null);
    const [isHorizontallyScrolled, setIsHorizontallyScrolled] = useState(false);
    const [isVerticallyScrolled, setIsVerticallyScrolled] = useState(false);
    useEffect(() => {
        const tableScrollWrapper = tableContainerRef?.current?.parentNode;
        const scrollHandler = (event) => {
            startTransition(() => {
                setIsVerticallyScrolled(() => {
                    if (!tableContainerRef.current) {
                        return false;
                    }
                    return event.target.scrollTop > tableContainerRef.current.offsetTop;
                });
                setIsHorizontallyScrolled(() => event.target.scrollLeft !== 0);
            });
        };
        tableScrollWrapper?.addEventListener('scroll', scrollHandler, false);
        return () => {
            tableScrollWrapper?.removeEventListener('scroll', scrollHandler, false);
        };
    }, []);
    const grouping = useMemo(() => (rowGrouping ? [rowGrouping] : []), [rowGrouping]);
    const computedColumns = useMemo(() => selectable && isSelected && toggleSelection
        ? [
            {
                id: 'select',
                header: () => React.createElement(Checkbox, { checked: selectAll, onChange: toggleAll }),
                enableResizing: false,
                maxSize: 45,
                minSize: 45,
                cell: ({ row }) => (React.createElement(Checkbox, { checked: isString(row.original.entityId) && isSelected(row.original.entityId), onChange: () => {
                        toggleSelection(row.original.entityId);
                    } }))
            },
            ...columns
        ]
        : columns, [columns, isSelected, selectAll, selectable, toggleAll, toggleSelection]);
    const [expanded, setExpanded] = useState({});
    const table = useReactTable({
        data,
        columns: computedColumns,
        state: {
            pagination: {
                // only works when manualPagination true
                pageSize: pageSize,
                pageIndex: pageIndex - 1
            },
            grouping,
            expanded,
            sorting: sorted
        },
        sortDescFirst: false,
        onSortingChange: onSortedChange,
        initialState: {
            columnSizing: {
                ...TABLE_EXTRA_COLS.reduce((acc, col) => ({ ...acc, [col]: 50 }), {})
            },
            columnPinning: {
                left: TABLE_EXTRA_COLS
            }
        },
        onExpandedChange: setExpanded,
        getRowCanExpand: getRowCanExpand
            ? getRowCanExpand
            : /**
               * @TODO move to `getRowCanExpand` of the corresponding component
               */
                (row) => '_countInGroup' in row.original &&
                    isNumber(row.original._countInGroup) &&
                    row.original._countInGroup > 0,
        getSubRows: (row) => row?.subRows,
        columnResizeMode: 'onChange',
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        manualGrouping: true,
        manualSorting: true,
        manualPagination,
        enableSortingRemoval: sorted && sorted.length > 1
    });
    const [expandedPagination, setExpandedPagination] = useState({});
    useEffect(() => {
        // reset expanded rows when rowGrouping changes
        setExpanded({});
        setExpandedMap?.({});
        setExpandedPagination({});
        // rowGrouping required in this effect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowGrouping, setExpandedMap]);
    const TableBody = windowScroller ? ReactTableBodyVirtualizedWindow : ReactTableBodyVirtualized;
    return (React.createElement("div", { className: cn(className, 'ReactTableWrapper'), ref: tableContainerRef },
        React.createElement("table", { className: cn('rt-table ReactTable', { isHorizontallyScrolled, isVerticallyScrolled }) },
            React.createElement("thead", { className: "rt-thead -header" }, table.getHeaderGroups().map((headerGroup) => {
                return (React.createElement("tr", { className: "rt-tr", key: headerGroup.id }, headerGroup.headers.map((header) => {
                    const isResizing = header.column.getIsResizing();
                    const canResize = header.column.getCanResize();
                    return (React.createElement("th", { key: header.id, className: cn('rt-th', {
                            [header.id]: TABLE_EXTRA_COLS.includes(header.id),
                            isResizing,
                            'flex-grow-0': !canResize
                        }), colSpan: header.colSpan, style: { width: header.getSize() } },
                        React.createElement("div", { className: cn('wrapper w-100 d-flex align-items-center position-relative', {
                                [header.id]: TABLE_EXTRA_COLS.includes(header.id)
                            }) },
                            React.createElement(React.Fragment, null, header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header, header.getContext())),
                            canResize ? (React.createElement("div", { onMouseDown: header.getResizeHandler(), onTouchStart: header.getResizeHandler(), onClick: (event) => event.stopPropagation(), className: cn('resizer', { isResizing }) })) : null)));
                })));
            })),
            React.createElement(TableBody, { table: table, loading: loading, rowHeight: rowHeight, tableContainerScrollerRef: tableContainerScrollerRef || tableContainerRef, expandedPagination: expandedPagination, setExpandedPagination: setExpandedPagination, setExpandedMap: setExpandedMap, getRowComponent: getRowComponent, getRowEstimateSize: getRowEstimateSize }, loading && LoadingComponent && (React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(LoadingComponent, null))))),
            !data.length && !loading && NoDataComponent && (React.createElement("tr", { className: "w-100 d-block" },
                React.createElement("td", { className: "w-100 d-block" },
                    React.createElement(NoDataComponent, null)))))));
};
const Checkbox = ({ className = '', ...otherProps }) => {
    const ref = useRef(null);
    return (React.createElement("label", { className: "p-3 safe-space", role: "button" },
        React.createElement("input", { type: "checkbox", ref: ref, className: cn(className, 'cursor-pointer'), ...otherProps })));
};
export default memo(ReactTable, (prevProps, nextProps) => {
    const prevEntries = Object.entries(prevProps);
    const nextEntries = Object.entries(nextProps);
    return (prevEntries.length === nextEntries.length &&
        prevEntries.every(([key, value]) => {
            if (key === 'sorted') {
                return value?.every(({ id, desc }, index) => {
                    const nextSorted = nextProps.sorted?.[index];
                    return nextSorted && id === nextSorted.id && desc === nextSorted.desc;
                });
            }
            return value === nextProps[key];
        }));
});
