import { ActivityStatus, ConditionType, FilterOperatorTypes, SortOrder } from '@hypercharge/portal-utils';
import { find, flatten, isArray, isEmpty, isNumber, isString } from 'lodash';
import { ENTITY_ID_PROPERTY_ID, TITLE_PROPERTY_ID } from '../../cms';
import { AggregationType, TermsAggregationFilterOrderBy, isMetricAggregationResult, isTermsAggregationResultArray } from '../../cms/hooks/useAggregation';
import { ACTIVITY_DEFINITION_ID } from '../../common/activity';
import { isConditionQuery } from '../../common/components/ConditionQuery/utils';
import { isRelativeRangeValue } from '../../common/components/DateRangeSelector/types';
import { MAP_FULL_RELATIVE_UNIT, getRelativeDateInAbsoluteValue, getRelativeOptions } from '../../common/components/DateRangeSelector/utils';
import { CONTACT_CMS_DEFINITION_ID } from '../../crm';
import { KANBAN_KEY } from '../../views/constants';
import { ALL_TASKS_DEFINITION_ID, TASK_ASSIGNED_TO_PROPERTY_ID, TASK_DEADLINE_PROPERTY_ID, TASK_STATUS_PROPERTY_ID } from '../common/utils/constants';
import { TaskStatus } from '../common/utils/types';
import { REGEX_START_WITH_ADDDATE, REGEX_START_WITH_SODATE, getEmptyFilter, getFromEndWeekToEndMonthFilter, getFromNowToEndDayFilter, getFromTomorrowToEndWeekFilter, getLaterEndMonthFilter, getLessThenNowFilter, mapPropertyActivityTaskDashboardGroupBy, mapPropertyWorkflowsTaskDashboardGroupBy, mapRevertFullRelativeUnit, uniqViewIdActivityTaskDashboard, uniqViewIdWorkflowsTaskDashboard } from './constants';
import { TasksFilterType } from './types';
export const getPermissionsUpdateFilters = (contactGroups) => [
    {
        condition: ConditionType.or,
        filters: contactGroups.map((group) => ({
            field: 'permissionsUpdate',
            operator: FilterOperatorTypes.is,
            data: group
        }))
    }
];
export const getWorkflowsTaskDashboardView = (views, viewId) => find(views, !viewId
    ? {
        entityId: `${ALL_TASKS_DEFINITION_ID}__default__${uniqViewIdWorkflowsTaskDashboard}`,
        referenceDefinitionId: ALL_TASKS_DEFINITION_ID,
        isDefault: true
    }
    : {
        entityId: viewId
    });
export const getActivityTaskDashboardView = (views, viewId) => find(views, !viewId
    ? {
        entityId: `${ACTIVITY_DEFINITION_ID}__default__${uniqViewIdActivityTaskDashboard}`,
        referenceDefinitionId: ACTIVITY_DEFINITION_ID,
        isDefault: true
    }
    : {
        entityId: viewId
    });
export const getDefaultFilterQueryWorkflowsTaskDashboardBase = (contactGroups) => ({ filters = [] }) => {
    const query = {
        condition: ConditionType.and,
        filters: [...filters, ...getPermissionsUpdateFilters(contactGroups)]
    };
    // WAITING and RUNNING status
    query.filters.push({
        condition: ConditionType.or,
        filters: [
            {
                field: `${TASK_STATUS_PROPERTY_ID}.${ENTITY_ID_PROPERTY_ID}`,
                operator: FilterOperatorTypes.is,
                data: TaskStatus.WAITING
            },
            {
                field: `${TASK_STATUS_PROPERTY_ID}.${ENTITY_ID_PROPERTY_ID}`,
                operator: FilterOperatorTypes.is,
                data: TaskStatus.RUNNING
            }
        ]
    });
    return query;
};
export const getDefaultFilterQueryActivityTaskDashboard = ({ filters = [] }) => {
    const query = {
        condition: ConditionType.and,
        filters
    };
    // WAITING and RUNNING status
    query.filters.push({
        condition: ConditionType.or,
        filters: [
            {
                field: `statusItem.${ENTITY_ID_PROPERTY_ID}`,
                operator: FilterOperatorTypes.isNot,
                data: ActivityStatus.Completed
            }
        ]
    });
    return query;
};
const TOTAL_COUNT_ID = 'TOTAL_COUNT_ID';
export const getAggregationResult = (config, response) => {
    const aggregationResult = config.aggregations
        .filter((item) => item.id !== TOTAL_COUNT_ID)
        .map((item) => response?.aggregations[item.field]);
    const aggregationTotalCountResult = response?.aggregations[TOTAL_COUNT_ID];
    const isMetricAggregationTotalCountResult = isMetricAggregationResult(aggregationTotalCountResult);
    return {
        totalCount: (isMetricAggregationTotalCountResult && aggregationTotalCountResult.value) || 0,
        aggregationResult: aggregationResult.reduce((all, item) => isArray(item) && isTermsAggregationResultArray(item) ? [...all, ...item] : all, [])
    };
};
export const isFilterForSelectedFilters = (filterName) => (filter) => isConditionQuery(filter) && filter.id === filterName;
export const filterHasOperator = (filter) => filter && 'operator' in filter;
export const getFiltersWithoutField = (filterRequest, field) => filterRequest.query.filters.filter((filter) => isConditionQuery(filter) && filter.id !== field);
export const aggregations = ({ field, filterQuery, language, addAggregationField, contactId, needTotalCount = true, size = 10 }) => {
    const aggregations = [
        {
            aggregations: (addAggregationField && [
                {
                    field: addAggregationField,
                    type: AggregationType.term,
                    orderBy: TermsAggregationFilterOrderBy.field,
                    size
                }
            ]) ||
                undefined,
            field,
            type: AggregationType.term,
            orderBy: TermsAggregationFilterOrderBy.field,
            size
        }
    ];
    if (needTotalCount) {
        aggregations.push({
            id: TOTAL_COUNT_ID,
            field,
            type: AggregationType.uniqCount
        });
    }
    return {
        aggregations,
        filter: {
            query: filterQuery,
            context: {
                definitionId: CONTACT_CMS_DEFINITION_ID,
                contactId
            },
            sortBy: [
                {
                    field: TITLE_PROPERTY_ID,
                    order: SortOrder.asc
                }
            ],
            languageCode: language
        }
    };
};
export const getDeadlineFromTaskSelectedFilters = (filterRequest) => {
    const selectedDeadline = flatten(filterRequest.query.filters
        .filter(isFilterForSelectedFilters(TasksFilterType.deadline))
        .map(({ filters }) => filters))
        .filter(filterHasOperator)
        .map((filter) => (filter.operator === FilterOperatorTypes.greaterThanOrEqual ||
        filter.operator === FilterOperatorTypes.lessThan) &&
        filter.data)
        .filter(isNumber)
        .sort();
    const computedDeadline = flatten(filterRequest.query.filters
        .filter(isFilterForSelectedFilters(TasksFilterType.deadline))
        .map(({ filters }) => filters))
        .filter(filterHasOperator)
        .map((filter) => (filter.operator === FilterOperatorTypes.greaterThanOrEqual ||
        filter.operator === FilterOperatorTypes.lessThan) &&
        filter.data)
        .filter(isString)
        .filter((item) => item.startsWith('='));
    const fromStart = computedDeadline?.[0]?.startsWith('=SODATE');
    const regex = fromStart ? REGEX_START_WITH_SODATE : REGEX_START_WITH_ADDDATE;
    const match = regex.exec(computedDeadline[0]);
    const relativeValue = (match?.[1] && Math.abs(parseInt(match[1])) + (fromStart ? 1 : 0)) || 1;
    const relativeUnit = match?.[2];
    const revertFullRelativeUnit = relativeUnit && mapRevertFullRelativeUnit[relativeUnit];
    const offset = relativeValue && revertFullRelativeUnit && `${relativeValue} ${revertFullRelativeUnit}`;
    return offset
        ? {
            offset,
            fromStart
        }
        : {
            time: selectedDeadline
        };
};
export const getAssignedFromTaskSelectedFilters = (filterRequest) => {
    return flatten(filterRequest.query.filters
        .filter(isFilterForSelectedFilters(TasksFilterType.assignees))
        .map(({ filters }) => filters))
        .filter(filterHasOperator)
        .map((filter) => (filter.operator === FilterOperatorTypes.is && filter.data) ||
        (filter.operator === FilterOperatorTypes.empty && FilterOperatorTypes.empty))
        .filter(isString);
};
export const getTaskTitlesFromTaskSelectedFilters = (filterRequest) => {
    return flatten(filterRequest.query.filters
        .filter(isFilterForSelectedFilters(TasksFilterType.taskTitles))
        .map(({ filters }) => filters))
        .filter(filterHasOperator)
        .map((filter) => filter.operator === FilterOperatorTypes.is && filter.data)
        .filter(isString);
};
export const getRelatedToFromTaskSelectedFilters = (filterRequest) => {
    return flatten(filterRequest.query.filters
        .filter(isFilterForSelectedFilters(TasksFilterType.relatedTo))
        .map(({ filters }) => filters))
        .filter(filterHasOperator)
        .map((filter) => filter.operator === FilterOperatorTypes.is && filter.data)
        .filter(isString);
};
export const getTaskSelectedFilters = (filterRequest) => {
    return {
        [TasksFilterType.relatedTo]: getRelatedToFromTaskSelectedFilters(filterRequest),
        [TasksFilterType.taskTitles]: getTaskTitlesFromTaskSelectedFilters(filterRequest),
        [TasksFilterType.assignees]: getAssignedFromTaskSelectedFilters(filterRequest),
        [TasksFilterType.deadline]: getDeadlineFromTaskSelectedFilters(filterRequest)
    };
};
export const getNewFilterForWorkflowsTaskDashboard = ({ filterRequest, filterName, value, field }) => {
    const hasFiltersFromField = filterRequest.query.filters.find(isFilterForSelectedFilters(filterName));
    const newFilterByField = {
        id: filterName,
        condition: ConditionType.or,
        filters: isArray(value)
            ? value.map((data) => data === FilterOperatorTypes.empty
                ? {
                    field: TASK_ASSIGNED_TO_PROPERTY_ID,
                    operator: FilterOperatorTypes.empty
                }
                : {
                    field,
                    operator: FilterOperatorTypes.is,
                    data
                })
            : []
    };
    if (filterName === TasksFilterType.deadline && !isArray(value)) {
        const isRelativeValue = isRelativeRangeValue(value);
        if (isRelativeValue) {
            const deadlineFilters = [];
            const { relativeValue, relativeUnit } = getRelativeOptions(value);
            const fullRelativeUnit = MAP_FULL_RELATIVE_UNIT[relativeUnit];
            if (value?.fromStart) {
                deadlineFilters.push({
                    field: TASK_DEADLINE_PROPERTY_ID,
                    operator: FilterOperatorTypes.greaterThanOrEqual,
                    data: `=SODATE(ADDDATE(NOW(), -${Number(relativeValue) - 1}, '${fullRelativeUnit}'), '${fullRelativeUnit}')`
                });
                deadlineFilters.push({
                    field: TASK_DEADLINE_PROPERTY_ID,
                    operator: FilterOperatorTypes.lessThan,
                    data: '=NOW()'
                });
            }
            else {
                deadlineFilters.push({
                    field: TASK_DEADLINE_PROPERTY_ID,
                    operator: FilterOperatorTypes.greaterThanOrEqual,
                    data: `=ADDDATE(NOW(), -${relativeValue}, '${fullRelativeUnit}')`
                });
                deadlineFilters.push({
                    field: TASK_DEADLINE_PROPERTY_ID,
                    operator: FilterOperatorTypes.lessThan,
                    data: '=NOW()'
                });
            }
            newFilterByField.condition = ConditionType.and;
            newFilterByField.filters = deadlineFilters;
        }
        else {
            const [startTime, endTime] = getRelativeDateInAbsoluteValue({
                start: value.time[0]?.valueOf(),
                end: value.time[1]?.valueOf()
            });
            const deadlineFilters = [];
            if (startTime) {
                deadlineFilters.push({
                    field: TASK_DEADLINE_PROPERTY_ID,
                    operator: FilterOperatorTypes.greaterThanOrEqual,
                    data: startTime
                });
            }
            if (endTime) {
                deadlineFilters.push({
                    field: TASK_DEADLINE_PROPERTY_ID,
                    operator: FilterOperatorTypes.lessThan,
                    data: endTime
                });
            }
            newFilterByField.condition = ConditionType.and;
            newFilterByField.filters = deadlineFilters;
        }
    }
    const newFilterRequestFilter = hasFiltersFromField
        ? filterRequest.query.filters.map((filter) => {
            if (isFilterForSelectedFilters(filterName)(filter)) {
                return newFilterByField;
            }
            return filter;
        })
        : [...filterRequest.query.filters, newFilterByField];
    const newFilterRequest = {
        ...filterRequest,
        query: {
            ...filterRequest.query,
            filters: newFilterRequestFilter
        }
    };
    return newFilterRequest;
};
export const getNewFilterForActivityTaskDashboard = ({ filterRequest, filterName, value, field }) => {
    const hasFiltersFromField = filterRequest.query.filters.find(isFilterForSelectedFilters(filterName));
    const newFilterByField = {
        id: filterName,
        condition: ConditionType.or,
        filters: isArray(value)
            ? value.map((data) => ({
                field,
                operator: FilterOperatorTypes.is,
                data
            }))
            : []
    };
    if (filterName === TasksFilterType.deadline && !isArray(value)) {
        const isRelativeValue = isRelativeRangeValue(value);
        if (isRelativeValue) {
            const deadlineFilters = [];
            const { relativeValue, relativeUnit } = getRelativeOptions(value);
            const fullRelativeUnit = MAP_FULL_RELATIVE_UNIT[relativeUnit];
            if (value?.fromStart) {
                deadlineFilters.push({
                    field: 'dueDate',
                    operator: FilterOperatorTypes.greaterThanOrEqual,
                    data: `=SODATE(ADDDATE(NOW(), -${Number(relativeValue) - 1}, '${fullRelativeUnit}'), '${fullRelativeUnit}')`
                });
                deadlineFilters.push({
                    field: 'dueDate',
                    operator: FilterOperatorTypes.lessThan,
                    data: '=NOW()'
                });
            }
            else {
                deadlineFilters.push({
                    field: 'dueDate',
                    operator: FilterOperatorTypes.greaterThanOrEqual,
                    data: `=ADDDATE(NOW(), -${relativeValue}, '${fullRelativeUnit}')`
                });
                deadlineFilters.push({
                    field: 'dueDate',
                    operator: FilterOperatorTypes.lessThan,
                    data: '=NOW()'
                });
            }
            newFilterByField.condition = ConditionType.and;
            newFilterByField.filters = deadlineFilters;
        }
        else {
            const [startTime, endTime] = getRelativeDateInAbsoluteValue({
                start: value.time[0]?.valueOf(),
                end: value.time[1]?.valueOf()
            });
            const deadlineFilters = [];
            if (startTime) {
                deadlineFilters.push({
                    field: 'dueDate',
                    operator: FilterOperatorTypes.greaterThanOrEqual,
                    data: startTime
                });
            }
            if (endTime) {
                deadlineFilters.push({
                    field: 'dueDate',
                    operator: FilterOperatorTypes.lessThan,
                    data: endTime
                });
            }
            newFilterByField.condition = ConditionType.and;
            newFilterByField.filters = deadlineFilters;
        }
    }
    const newFilterRequestFilter = hasFiltersFromField
        ? filterRequest.query.filters.map((filter) => {
            if (isFilterForSelectedFilters(filterName)(filter)) {
                return newFilterByField;
            }
            return filter;
        })
        : [...filterRequest.query.filters, newFilterByField];
    const newFilterRequest = {
        ...filterRequest,
        query: {
            ...filterRequest.query,
            filters: newFilterRequestFilter
        }
    };
    return newFilterRequest;
};
export var TasksDashboardDeadlineColumns;
(function (TasksDashboardDeadlineColumns) {
    TasksDashboardDeadlineColumns["overdue"] = "OVERDUE";
    TasksDashboardDeadlineColumns["today"] = "TODAY";
    TasksDashboardDeadlineColumns["thisWeek"] = "THIS_WEEK";
    TasksDashboardDeadlineColumns["thisMonth"] = "THIS_MONTH";
    TasksDashboardDeadlineColumns["later"] = "LATER";
})(TasksDashboardDeadlineColumns || (TasksDashboardDeadlineColumns = {}));
export const tasksDashboardDeadlineColumns = [
    ...Object.values(TasksDashboardDeadlineColumns),
    FilterOperatorTypes.empty
];
export const getCustomKanbanColumnsFilters = (mapProperty) => (columns, groupBy) => {
    if (groupBy === mapProperty[TasksFilterType.deadline]) {
        if (columns) {
            const conditionQuery = {
                id: KANBAN_KEY,
                condition: ConditionType.or,
                filters: []
            };
            columns.forEach((column) => {
                if (column === TasksDashboardDeadlineColumns.overdue) {
                    conditionQuery.filters.push(getLessThenNowFilter(mapProperty[TasksFilterType.deadline]));
                }
                if (column === TasksDashboardDeadlineColumns.today) {
                    conditionQuery.filters.push(getFromNowToEndDayFilter(mapProperty[TasksFilterType.deadline]));
                }
                if (column === TasksDashboardDeadlineColumns.thisWeek) {
                    conditionQuery.filters.push(getFromTomorrowToEndWeekFilter(mapProperty[TasksFilterType.deadline]));
                }
                if (column === TasksDashboardDeadlineColumns.thisMonth) {
                    conditionQuery.filters.push(getFromEndWeekToEndMonthFilter(mapProperty[TasksFilterType.deadline]));
                }
                if (column === TasksDashboardDeadlineColumns.later) {
                    conditionQuery.filters.push(getLaterEndMonthFilter(mapProperty[TasksFilterType.deadline]));
                }
                if (column === FilterOperatorTypes.empty) {
                    conditionQuery.filters.push(getEmptyFilter(mapProperty[TasksFilterType.deadline]));
                }
            }, []);
            return [conditionQuery];
        }
    }
    return;
};
export const getCustomWorkflowsKanbanColumnsFilters = getCustomKanbanColumnsFilters(mapPropertyWorkflowsTaskDashboardGroupBy);
export const getCustomActivityKanbanColumnsFilters = getCustomKanbanColumnsFilters(mapPropertyActivityTaskDashboardGroupBy);
export const getSearchFilters = (search, groupBy, mapProperty, entities, titleTranslatedKey) => {
    const filters = [];
    if (search && !isEmpty(search)) {
        if (groupBy === TasksFilterType.relatedTo && entities && !isEmpty(entities)) {
            filters.push({
                condition: ConditionType.or,
                filters: search
                    ? entities
                        .filter((entity) => entity.title.toLowerCase().includes(search.toLowerCase() || ''))
                        .map((entity) => ({
                        operator: FilterOperatorTypes.is,
                        data: entity.definitionId,
                        field: mapProperty[TasksFilterType.relatedTo]
                    }))
                    : []
            });
        }
        if (groupBy === TasksFilterType.taskTitles) {
            if (titleTranslatedKey) {
                filters.push({
                    condition: ConditionType.or,
                    filters: [
                        {
                            operator: FilterOperatorTypes.contains,
                            field: mapProperty[TasksFilterType.taskTitles],
                            data: search
                        },
                        {
                            operator: FilterOperatorTypes.contains,
                            field: mapProperty[TasksFilterType.taskTitles],
                            data: search
                        }
                    ]
                });
            }
            else {
                filters.push({
                    operator: FilterOperatorTypes.contains,
                    field: mapProperty[TasksFilterType.taskTitles],
                    data: search
                });
            }
        }
        if (groupBy === TasksFilterType.assignees) {
            filters.push({
                operator: FilterOperatorTypes.contains,
                field: `${mapProperty[TasksFilterType.assignees]}.${TITLE_PROPERTY_ID}`,
                data: search
            });
        }
    }
    return filters;
};
